// @flow
import React from 'react';
import { useReferrals } from '../../../../../referrals/ReferralsContext';
import { TopMenuContext } from '../../../../context/TopMenu';
import { enqueueModal, dequeueModal } from '../../../../../modals/actions';
import { enqueueNotification } from '../../../../../notifications/actions';
import { GetReferralLink } from '../../../../../referrals/modals/GetReferralLink';
import { Success } from '../../../../../notifications/components/Success';
import { useTracking } from '../../../../../tracking/TrackingContext';
import { setPendingComposeDirectReferralStatus } from '../../../../../referrals/actions';
import { useStateContainer } from '../../../../../../core/context/StateContainer';
import { useConsentDialogController } from '../../../../../../core/context/ConsentDialogController';
import { useCopyRefLinkTrail } from '../../../../../referrals/useCopyRefLinkTrail';
import { useAuth } from '../../../../../auth/talents/AuthContext';

const { useContext } = React;

function useReferralsBox() {
  const { showConsent } = useConsentDialogController();
  const { showAuthPopup } = useAuth();
  const { track, events: trackingEvents } = useTracking();
  const [
    {
      auth: { authenticated, user },
    },
    dispatch,
  ] = useStateContainer();
  const { close } = useContext(TopMenuContext);
  const { getReferralLink: getReferralLinkRequest, getCurrentUrl } =
    useReferrals();
  const { onCopyRefLink: copyRefLinkTrail } = useCopyRefLinkTrail();

  function onCopy(url: string) {
    copyRefLinkTrail();
    dispatch(dequeueModal());
    dispatch(
      enqueueNotification(
        <Success>Now you can spread the link across your network</Success>
      )
    );
    track(trackingEvents.GET_REFERRAL_LINK_COPY_BUTTON_CLICK, { url });
  }

  function onGetReferralLinkClick() {
    if (!authenticated || !user) {
      showAuthPopup('login');
      close();
    } else {
      const currentUrl = getCurrentUrl();
      track(trackingEvents.GET_INVITE_LINK_BUTTON_CLICK, {
        url: currentUrl,
      });
      getReferralLinkRequest({ url: currentUrl }).then((data) => {
        const { url } = data;

        if (typeof close === 'function') {
          close();
        }

        dispatch(
          enqueueModal(
            <GetReferralLink link={url} onCopy={() => onCopy(currentUrl)} />
          )
        );
      });
    }
  }

  function onComposeDirectReferralClick() {
    const currentUrl = getCurrentUrl();
    track(trackingEvents.SEND_PERSONAL_INVITE_LINK_BUTTON_CLICK, {
      url: currentUrl,
    });
    function show() {
      dispatch(setPendingComposeDirectReferralStatus(true));
      setTimeout(() => {
        // in order to prevent flickering when the side menu closes
        if (typeof close === 'function') {
          close();
        }
      }, 0);
    }
    showConsent({
      onNotLogged: show,
      onConsentAlreadyGiven: show,
      onAccept: show,
      onClose: () => {
        dispatch(setPendingComposeDirectReferralStatus(false));
      },
    });
  }

  return {
    onGetReferralLinkClick,
    onComposeDirectReferralClick,
  };
}

export { useReferralsBox };
