// @flow
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import Dashboard from '../components/companies/dashboard/components/Dashboard';
import { useMyCompany } from '../components/companies/common/context/MyCompanyContext';
import { FullPageLoader } from '../components/basic/FullPageLoader';
import { PageHead } from '../components/basic/PageHead';
import { paths } from '../core/constants';
import { useStateContainer } from '../core/context/StateContainer';
import { getCompanyDataRequest } from '../components/companies/common/companiesService';
import { JobDuplicateContext } from '../components/jobs/JobDuplicateContext';

function AddPageHead({
  children,
  company,
}: {
  children: Node,
  company: string,
}) {
  const title = company
    ? `${company} Dashboard | NobleHire`
    : 'Dashboard | NobleHire';
  return (
    <div>
      <PageHead title={title} />
      {children}
    </div>
  );
}

export function CompanyDashboard() {
  const [
    {
      auth: { user, authenticated, isAdmin, isCompany },
    },
  ] = useStateContainer();
  const [dashboardType, setDashboardType] = useState(null);

  useEffect(() => {
    if (authenticated && user && isAdmin(user)) {
      setDashboardType('admin');
    }

    if (authenticated && user && isCompany(user)) {
      setDashboardType('non-admin');
    }
  }, [authenticated, user, isAdmin, isCompany]);

  if (dashboardType === 'admin') {
    return (
      <JobDuplicateContext>
        <AdminCompanyDashboard />
      </JobDuplicateContext>
    );
  }

  if (dashboardType === 'non-admin') {
    return (
      <JobDuplicateContext>
        <NonAdminCompanyDashboard />
      </JobDuplicateContext>
    );
  }

  return <FullPageLoader />;
}

function AdminCompanyDashboard() {
  const [
    {
      auth: { user, authenticated, isAdmin },
    },
  ] = useStateContainer();
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState<any>(null);

  useEffect(() => {
    getCompanyDataRequest(slug).then((response) => {
      setCompany(response.data);
      setLoading(false);
    });
  }, [slug]);

  if (loading || !user || !authenticated) {
    return (
      <AddPageHead company={(company && company.brand) || ''}>
        <FullPageLoader />
      </AddPageHead>
    );
  }

  if (authenticated && user && isAdmin(user) && company && !loading) {
    return (
      <AddPageHead company={(company && company.brand) || ''}>
        <Dashboard />
      </AddPageHead>
    );
  }

  return <Redirect to={paths.root} />;
}

function NonAdminCompanyDashboard() {
  const [
    {
      auth: { user, authenticated, isAdmin },
    },
  ] = useStateContainer();
  const { slug } = useParams();
  const myCompany = useMyCompany();

  if (myCompany.loading || !user || !authenticated) {
    return (
      <AddPageHead company={(myCompany && myCompany.brand) || ''}>
        <FullPageLoader />
      </AddPageHead>
    );
  }

  if (myCompany.slug === slug || isAdmin(user)) {
    return (
      <AddPageHead company={(myCompany && myCompany.brand) || ''}>
        <Dashboard />
      </AddPageHead>
    );
  }

  return <Redirect to={paths.root} />;
}
