// @flow
import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { paths } from '../../../../core/constants';
import { createSliderGroupSliders } from '../../utils/createSliderGroupSliders';
import { slidersConfig } from '../../slidersConfig';
import { CultureGeneralRating } from '../../components/steps/CultureGeneralRating';
import { CultureTeamRating } from '../../components/steps/CultureTeamRating';
import { CultureLeadershipRating } from '../../components/steps/CultureLeadershipRating';
import { saveCulture } from '../../onboardingFormsService';
import { useStateContainer } from '../../../../core/context/StateContainer';
import { dispatchSaveError } from '../../../basic/form/utils/dispatchSaveError';
import {
  nextStep,
  nextSyntheticStep,
  completeSection,
  setOnboardingLoading,
  setInitialization,
} from '../../actions';
import { culture as cultureValidateConfig } from '../../validateConfig';
import { buildStepsValidateFunc } from '../../../../utils/buildValidateFunc';
import { buildCultureInitialValues } from '../../utils/initialValueBuilders';
import { createCultureRequestObject } from '../../utils/requestObjectsBuilders';
import { enqueueModal } from '../../../modals/actions';
import { OnboardingSuccess } from '../../modals/OnboardingSuccess';
import { obj } from '../../../../utils/obj';
import { useTracking } from '../../../tracking/TrackingContext';
import { useGoToPrevSectionOnStep } from '../../utils/useGoToPrevSectionOnStep';

const useCultureForm = () => {
  const { track, events } = useTracking();
  const history = useHistory();
  const queryString = useLocation().search;
  const query = new URLSearchParams(queryString);
  const [
    {
      onboarding: {
        currentStep,
        isTech,
        isLookingForTech,
        hydration: {
          data: { culture },
        },
      },
    },
    dispatch,
  ] = useStateContainer();
  const goToPrevSectionOnStep = useGoToPrevSectionOnStep();

  const initialValues = buildCultureInitialValues(culture);
  const validateStep = buildStepsValidateFunc(
    cultureValidateConfig,
    (values, config) => config
  );

  const goToPrevSection = React.useCallback(() => {
    // if the user is tech -> on the prev section we're showing the tech and soft skills sections (1 index).
    // if the user is non-tech -> we're showing the soft skills form only, so only one step (0 index)
    const prevSectionLastStep = isTech || isLookingForTech ? 1 : 0;
    goToPrevSectionOnStep(prevSectionLastStep);
  }, [goToPrevSectionOnStep, isTech, isLookingForTech]);

  const getSteps = () => {
    return [
      <CultureGeneralRating
        sliders={createSliderGroupSliders(
          obj.keys(slidersConfig.cultureGeneral),
          slidersConfig.cultureGeneral
        )}
        onBack={goToPrevSection}
      />,
      <CultureTeamRating
        sliders={createSliderGroupSliders(
          obj.keys(slidersConfig.cultureTeam),
          slidersConfig.cultureTeam
        )}
      />,
      <CultureLeadershipRating
        sliders={createSliderGroupSliders(
          obj.keys(slidersConfig.cultureLeadership),
          slidersConfig.cultureLeadership
        )}
      />,
    ];
  };

  function performRedirect() {
    if (!query.get('jark')) {
      // isn't direct application
      dispatch(enqueueModal(<OnboardingSuccess />));
      history.replace({ pathname: paths.root, search: queryString });
    } else {
      // since we're going to refetch the onboarding state
      // inside of the DirectApplicationFormContainer,
      // we need to reset the initializationReady flag beforehand.
      dispatch(setInitialization(false));
      history.push({
        pathname: paths.directApplication,
        search: queryString,
        state: {
          comingFromOnboarding: true,
        },
      });
    }
  }

  async function onFormSubmit(values: { [key: string]: any }) {
    const lastStep = 2;
    const isBlank = !queryString;
    const cultureOverallStepIndex = 0;
    const cultureTeamStepIndex = 1;

    const reqObj = createCultureRequestObject(values, slidersConfig);

    if (currentStep === cultureOverallStepIndex) {
      track(events.COMPLETE_OVERALL_CULTURE, { isBlank });
    }

    if (currentStep === cultureTeamStepIndex) {
      track(events.COMPLETE_TEAM_CULTURE, { isBlank });
    }

    if (currentStep === lastStep) {
      dispatch(setOnboardingLoading(true));
      saveCulture(reqObj) // eslint-disable-line
        .then(() => {
          performRedirect();
          track(events.COMPLETE_LEADERSHIP_CULTURE, { isBlank });
          dispatch(setOnboardingLoading(false));
          dispatch(completeSection(3));
        })
        .catch(() => {
          dispatch(setOnboardingLoading(false));
          dispatch(completeSection(3));
          dispatchSaveError(dispatch);
        });
    } else {
      dispatch(nextStep());
      dispatch(nextSyntheticStep());
    }
  }

  return {
    initialValues,
    onFormSubmit,
    validateStep,
    currentStep,
    getSteps,
  };
};

export { useCultureForm };
