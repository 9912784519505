// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet';
import getMatchedImg from '../../assets/images/get_matched.png';

type SocialMedia = {
  title?: string | null,
  description?: string | null,
  image?: string,
};

export function PageHead({
  title,
  description,
  socialMedia,
}: {
  title?: string,
  description?: string,
  socialMedia?: SocialMedia,
}) {
  const pageTitle = title || `Get hired for a job you'll love | NobleHire`;
  const pageDescription =
    description || `Find a job in one of the best tech companies in Bulgaria.`;
  const socialMediaTitle = (socialMedia && socialMedia.title) || pageTitle;
  const socialMediaDescription =
    (socialMedia && socialMedia.description) || pageDescription;
  const socialMediaImage = (socialMedia && socialMedia.image) || getMatchedImg;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>
      {pageDescription ? (
        <meta name="description" content={pageDescription} />
      ) : null}

      {/* social media meta tags */}
      <meta property="og:title" content={socialMediaTitle} />
      <meta property="og:description" content={socialMediaDescription} />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:site_name" content="NobleHire" />
      <meta name="twitter:image:alt" content="Image" />
      <meta name="og:image" content={socialMediaImage} />
      <meta property="og:image" content={socialMediaImage} />
    </Helmet>
  );
}
