// @flow
import * as React from 'react';
import { Step } from '../../../basic/form/Step';
import { Row } from '../../../basic/form/Row';
import { SliderGroup } from '../../../basic/form/SliderGroup';
import { BackButton, SubmitButton } from '../../../basic/form/Buttons';
import type { SliderConfig } from '../../../../core/types';

type Props = {
  sliders: SliderConfig[],
  onBack: () => void,
};

function CultureGeneralRating({ sliders, onBack }: Props) {
  const buttons = [
    <BackButton key="back" onClick={onBack} />,
    <SubmitButton key="next">Next</SubmitButton>,
  ];

  return (
    <Step
      sectionName="Culture"
      heading="Select your perfect culture - Overall"
      subhead="Rate which culture aspects are more important to you."
      buttons={buttons}
    >
      <Row noBottomSpace>
        <SliderGroup name="cultureGeneralRating" sliders={sliders} />
      </Row>
    </Step>
  );
}

export { CultureGeneralRating };
