// @flow
const LOAD = 'LOAD';
const SET_VALUE = 'SET_VALUE';

function load(data: any) {
  return { type: LOAD, data };
}

function setValue(data: { key: string, value: any }) {
  return { type: SET_VALUE, data };
}

export { LOAD, SET_VALUE, load, setValue };
