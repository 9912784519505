// @flow
import API from '../../core/rest/API';

function uploadCvService(file: File) {
  const data = new FormData();
  data.append('file', file);

  return API.postResponse('/upload/resume', data);
}

export { uploadCvService };
