// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../../../../basic/Button';
import { Form } from '../../../../basic/form/Form';
import { Input } from '../../../../basic/form/Input';
import { useForgottenPasswordForm } from './useForgottenPasswordForm';

const useStyles = makeStyles((theme) => ({
  root: { maxWidth: '280px' },
  title: { textAlign: 'center' },
  footer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  link: {
    marginTop: theme.spacing(2),
  },
  row: { width: '100%', minWidth: 250, margin: theme.spacing(1, 0) },
}));

function ForgottenPasswordForm() {
  const classes = useStyles();
  const { onFormSubmit, validate } = useForgottenPasswordForm();

  return (
    <div className={classes.root}>
      <Form onSubmit={onFormSubmit} validate={validate}>
        {({ submitting }) => (
          <React.Fragment>
            <p className={classes.title}>Forgotten password</p>

            <Input
              controlProps={{ className: classes.row }}
              type="email"
              name="email"
              id="email"
              label="Email"
              autoComplete="email"
              placeholder="john@doe.com"
              required
            />

            <div className={classes.footer}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={submitting}
              >
                {submitting
                  ? "We're Sending you an email..."
                  : 'Send reset link'}
              </Button>
            </div>
          </React.Fragment>
        )}
      </Form>
    </div>
  );
}

export { ForgottenPasswordForm };
