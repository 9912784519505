import {
  faPlus,
  faAddressCard,
  faHeart,
  faTimesCircle,
  faChartBar,
  faBars,
  faTimes,
  faUser,
  faClipboardListCheck,
  faCheck,
  faArrowRight,
  faArrowLeft,
  faLockAlt,
  faInfoCircle,
  faBadgeCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChartNetwork,
  faAward,
  faPlaneDeparture,
  faIdCard,
  faPencilAlt,
  faUserShield,
  faBell,
  faShareAlt,
  faUsers,
  faUserCircle,
  faLink,
  faEnvelope,
  faHandPeace,
  faShieldCheck,
  faUsersMedical,
  faSearch,
  faSmileWink,
  faTasks,
  faEye,
  faPencil,
  faTrash,
  faArrows,
  faCrown,
  faSave,
  faSirenOn,
} from '@fortawesome/pro-light-svg-icons';
import * as lightIcons from '@fortawesome/pro-light-svg-icons';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const ROUTE_PREFIX = '';

const buildPath = (path) => `${ROUTE_PREFIX}${path}`;

const AUTH_HEADER = 'Authorization';
const JWT_TOKEN_KEY = 'jwtToken';
const AUTH_TOKEN_KEY = 'authToken';
const REFERRAL_LINKS_KEY = 'rl';

const events = {
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  USE_SHORT_REGISTRATION: 'USE_SHORT_REGISTRATION',
};

const paths = {
  $components: buildPath('/__components__'),
  root: buildPath('/'),
  login: buildPath('/login'),
  authenticating: buildPath('/authenticating'),
  register: buildPath('/register'),
  companiesDashboard: buildPath('/:slug/dashboard'),
  companiesProfile: buildPath('/:slug/:jobSlug?'),
  companiesRegister: buildPath('/companies/register'),
  companiesRegisterSuccess: buildPath('/companies/register/success'),
  forgottenPassword: buildPath('/forgotten-password'),
  forgottenPasswordReset: buildPath('/forgotten-password/reset'),
  onboarding: buildPath('/onboarding'),
  directApplication: buildPath('/direct-application'),
  onboardingBasicInformation: buildPath('/onboarding/basic-information'),
  onboardingSkills: buildPath('/onboarding/skills'),
  onboardingCulture: buildPath('/onboarding/culture'),
  motivation: buildPath('/onboarding/motivation'),
  jobs: buildPath('/'),
  about: buildPath('/about'),
  // slug+ info: https://github.com/pillarjs/path-to-regexp/tree/v1.7.0#one-or-more
  // in essence: /jobs/:slug+ means that everything after jobs
  // will be considered part of the slug
  // e.g. -> /jobs/some-text/1/2/3/4/5...slug = /some-text/1/2/3/4/5
  jobsCreate: buildPath('/:slug+/create'),
  job: buildPath('/:slug+/view'),
  jobInCompanyProfile: buildPath('/:companySlug/:slug+'),
  jobEdit: buildPath('/:slug+/edit'),
  termsAndConditions: buildPath('/terms-conditions'),
  privacyPolicy: buildPath('/privacy-policy'),
  seo__jobsPage: buildPath('/page/:index'),
  seo__companiesPage: buildPath('/companies/page/:index'),
  feed: buildPath('/feed/:index'),
  matchMe: buildPath('/match'),
  matchMeForm: buildPath('/match/form'),
  businesses: buildPath('/businesses'),
  referrers: buildPath('/referrers'),
  companies: buildPath('/companies'),
  cookiePolicy: buildPath('/cookie-policy'),
};

const externalPaths = {
  secretNewsletter: 'https://noble-hire.notion.site/noble-hire/The-Secret-Newsletter-aa6ee075a6334e8982d17412c5170e10',
  salarySurvey2022: 'https://noblehire.io/salary-survey-2022/',
  jobBoard: 'https://noblehire.io/companies/',
  jobPositions: 'https://noblehire.io/jobs/',
  talents: 'https://noblehire.io/talents/',
  referrers: 'https://noblehire.io/referrers/',
  employers: 'https://noblehire.io/businesses/',
  about: 'https://noblehire.io/about/',
  blog: 'https://noblehire.io/bg/blog/',
  faq: 'https://noblehire.io/faq/',
  termsAndConditions: 'https://noblehire.io/terms-conditions/',
  privacyPolicy: 'https://noblehire.io/privacy-policy/',
  techEvents: 'https://noblehire.io/tech-events/',
  inviteAFriend: 'https://noblehire.io/invite/',
  devCastBg: 'http://devcast.bg',
};

const pagesWithoutMenu = [
  paths.login,
  paths.register,
  paths.companiesRegister,
  paths.forgottenPassword,
  paths.forgottenPasswordReset,
  paths.onboarding,
  paths.directApplication,
  paths.onboardingBasicInformation,
  paths.onboardingSkills,
  paths.onboardingCulture,
  paths.motivation,
];

const icons = [
  faAddressCard,
  faHeart,
  faTimesCircle,
  faChartBar,
  faBars,
  faTimes,
  faUser,
  faClipboardListCheck,
  faCheck,
  faArrowRight,
  faArrowLeft,
  faLockAlt,
  faInfoCircle,
  faBadgeCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChartNetwork,
  faAward,
  faPlaneDeparture,
  faIdCard,
  faPencilAlt,
  faUserShield,
  faBell,
  faShareAlt,
  faUsers,
  faUserCircle,
  faLink,
  faEnvelope,
  faHandPeace,
  faShieldCheck,
  faUsersMedical,
  faSearch,
  faSmileWink,
  faTasks,
  faEye,
  faPencil,
  faTrash,
  faArrows,
  faCrown,
  faPlus,
  faSave,
  faStar,
  faSirenOn,
  faFacebook,
  faLinkedin,
  ...Object.values(lightIcons).slice(2),
  ...Object.values(solidIcons).slice(2),
];

const companyIcons = Object.values(lightIcons)
  .slice(2)
  .filter((i) => i.iconName !== 'font-awesome-logo-full');

const seniority = {
  intern: 'Intern',
  junior: 'Junior',
  mid: 'Mid',
  senior: 'Senior',
  expert: 'Expert',
};
const jobTypes = {
  fullTime: 'Full Time',
  partTime: 'Part Time',
  remote: 'Remote',
};
const serverJobTypes = {
  FULL_TIME: jobTypes.fullTime,
  PART_TIME: jobTypes.partTime,
  REMOTE: jobTypes.remote,
};
const teamSizes = {
  '1-5': '1-5',
  '5-10': '5-10',
  '10-20': '10-20',
  '20-50': '20-50',
  '50-100': '50-100',
  '100-200': '100-200',
  '200-500': '200-500',
  '500-1000': '500-1000',
  '1000-2000': '1000-2000',
  '2000-5000': '2000-5000',
  '5000-10000': '5000-10000',
};
const programmingLanguages = {
  bashShellPowershell: 'Bash/Shell/PowerShell',
  C: 'C',
  cSharp: 'C#',
  cpp: 'C++',
  clojure: 'Clojure',
  dart: 'Dart',
  elixir: 'Elixir',
  erlang: 'Erlang',
  fSharp: 'F#',
  go: 'Go',
  groovy: 'Groovy',
  htmlCss: 'HTML/CSS',
  java: 'Java',
  javaScript: 'JavaScript',
  kotlin: 'Kotlin',
  objC: 'Obj. C',
  perl: 'Perl',
  php: 'PHP',
  python: 'Python',
  R: 'R',
  ruby: 'Ruby',
  rust: 'Rust',
  scala: 'Scala',
  sql: 'SQL',
  actionScript: 'ActionScript',
  swift: 'Swift',
  typeScript: 'TypeScript',
  webAssembly: 'WebAssembly',
};
const platformsAndFrameworks = {
  dotNet: '.NET Core',
  aws: 'Amazon AWS',
  android: 'Android',
  angularJs: 'Angular.js',
  angular2: 'Angular 2+',
  ansible: 'Ansible',
  apacheSpark: 'ApacheSpark',
  arduino: 'Arduino',
  aspNet: 'ASP.NET',
  chef: 'Chef',
  cordova: 'Cordova',
  cryEngine: 'CryEngine',
  django: 'Django',
  docker: 'Docker',
  drupal: 'Drupal',
  express: 'Express',
  flask: 'Flask',
  flutter: 'Flutter',
  googleCloud: 'Google Cloud',
  grails: 'Grails',
  hadoop: 'Hadoop',
  heroku: 'Heroku',
  ibmCloudOrWatson: 'IBM Cloud or Watson',
  ios: 'iOS',
  jQuery: 'jQuery',
  jsf: 'JSF',
  kubernetes: 'Kubernetes',
  laravel: 'Laravel',
  linode: 'Linode',
  linux: 'Linux',
  macOs: 'MacOS',
  microsoftAsure: 'Microsoft Azure',
  nextJS: 'Next.js',
  nodeJS: 'Node.js',
  openGL: 'OpenGL',
  pandas: 'Pandas',
  play: 'Play',
  puppet: 'Puppet',
  raspberryPi: 'Raspberry Pi',
  react: 'React.js',
  reactNative: 'React Native',
  rubyOnRails: 'Ruby on Rails',
  selenium: 'Selenium',
  spring: 'Spring',
  struts: 'Struts',
  symfony: 'Symfony',
  tensorFlow: 'TensorFlow',
  torchPyTorch: 'Torch/PyTorch',
  unity3D: 'Unity 3D',
  unrealEngine: 'Unreal Engine',
  vue: 'Vue.js',
  windows: 'Windows',
  wordpress: 'Wordpress',
  xamarin: 'Xamarin',
  postman: 'Postman',
  terraform: 'Terraform',
};
const databases = {
  mysql: 'MySQL',
  postgresql: 'PostgreSQL',
  sqlServer: 'Microsoft SQL Server',
  sqlite: 'SQLite',
  mongo: 'MongoDB',
  redis: 'Redis',
  mariadb: 'MariaDB',
  oracle: 'Oracle',
  elasticsearch: 'Elasticsearch',
  firebase: 'Firebase',
  dynamoDB: 'DynamoDB',
  cassandra: 'Cassandra',
  couchbase: 'Couchbase',
  s3: 'S3',
};
const roles = {
  mobile: 'Mobile',
  frontend: 'Frontend developer',
  backend: 'Backend developer',
  devops: 'DevOps & Sys Admin',
  qa: 'QA',
  databases: 'Databases / DBA',
  dataScienceMlAi: 'Data Science / ML / AI',
  fullStack: 'Full Stack',
  embeddedAndElectronicSystems: 'Embedded & Electronic Systems',
  engineeringOthers: 'Engineering - others',
  design: 'Design',
  marketingAndPr: 'Marketing & PR',
  salesAndAccountManagement: 'Sales & Account Management',
  financeAndAudit: 'Finance & Audit',
  legalAndCompliance: 'Legal & Compliance',
  peopleOperationsAndHr: 'People Operations & HR',
  projectAndProductManagement: 'Project & Product Management',
  executive: 'Executive',
  others: 'Others',
};
const currencies = {
  BGN: 'BGN',
  EUR: 'EUR',
  USD: 'USD',
};
const salaries = {
  '0-1000': '< 1000',
  '1000-2000': '1000-2000',
  '2000-3000': '2000-3000',
  '3000-5000': '3000-5000',
  '5000-7000': '5000-7000',
  '7000-10000': '7000-10000',
  '10000-15000': '10000-15000',
  '15000-20000': '15000-20000',
};

export {
  ROUTE_PREFIX,
  AUTH_HEADER,
  JWT_TOKEN_KEY,
  AUTH_TOKEN_KEY,
  REFERRAL_LINKS_KEY,
  events,
  paths,
  externalPaths,
  pagesWithoutMenu,
  icons,
  companyIcons,
  seniority,
  jobTypes,
  serverJobTypes,
  teamSizes,
  programmingLanguages,
  platformsAndFrameworks,
  databases,
  roles,
  currencies,
  salaries,
};
