// @flow
/* eslint-disable flowtype/space-after-type-colon */
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import type { Node } from 'react';
import { getJob } from './jobsService';
import type { ServerLocation } from '../companies/common/context/CompanyContext';
import type { Job } from './JobsContext';
import { convServerJobToJob } from './utils';

export type Ctx = {
  loading: boolean,
  load: (search?: string) => Promise<any>,
  job: Job<ServerLocation> | null,
  jobNotFound: boolean | null,
};

const Context = createContext<Ctx | null>(null);

export function JobProvider({ children }: { children: Node }) {
  const { companySlug, slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState<Job<ServerLocation> | null>(null);
  const [jobNotFound, setJobNotFound] = useState(null);

  const load = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getJob(
        companySlug ? `${companySlug}/${slug}` : slug
      );
      const _job = convServerJobToJob(response);
      setJob(_job);
      setJobNotFound(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setJobNotFound(true);
      }
    } finally {
      setLoading(false);
    }
  }, [slug, companySlug]);

  useEffect(() => {
    load();
  }, [load]);

  const value = { loading, load, job, jobNotFound };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useJob: () => Ctx = () => {
  const ctx = useContext(Context);
  if (ctx === null) throw new Error('Improper use of JobProvider.');
  return ctx;
};
