// @flow
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '../../basic/Modal';
import { Button } from '../../basic/Button';
import splashBg from '../../../assets/images/splash-bg.svg';
import { dequeueModal } from '../actions';
import { useStateContainer } from '../../../core/context/StateContainer';
import { paths } from '../../../core/constants';
import { useVisitorTrails } from '../../tracking/visitor-trails/VisitorTrailsContext';
import { useTracking } from '../../tracking/TrackingContext';

const useStyles = makeStyles((theme) => ({
  root: {
    '@media(min-width: 768px)': {
      maxWidth: 580,
    },
  },
  title: {
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4),
    color: theme.palette.grey.dark,
  },
  container: {
    textAlign: 'center',
  },
  iconContainer: {
    userSelect: 'none',
    position: 'relative',
    textAlign: 'center',
    height: 'fit-content',
    margin: theme.spacing(4, 0),
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: 42,
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.main,
  },
  introText: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(4),
  },
}));

function EditProfilePrompt() {
  const { putTrail, visitorTrails } = useVisitorTrails();
  const { track, events } = useTracking();
  const queryString = useLocation().search;
  const classes = useStyles();
  const [, dispatch] = useStateContainer();
  const titleId = 'editProfilePromptTitle';
  const contentId = 'editProfilePromptContent';

  React.useEffect(
    () => putTrail(visitorTrails.EDIT_PROFILE_BEFORE_APPLY_SHOWN, true),
    [putTrail, visitorTrails.EDIT_PROFILE_BEFORE_APPLY_SHOWN]
  );

  const close = React.useCallback(() => {
    dispatch(dequeueModal());
  }, [dispatch]);

  const onClose = React.useCallback(() => {
    close();
    track(events.EDIT_PROFILE_PROMPT_CLOSED);
  }, [close, track, events.EDIT_PROFILE_PROMPT_CLOSED]);

  const onEdit = React.useCallback(() => {
    close();
    track(events.EDIT_PROFILE_PROMPT_EDIT_BTN_CLICK);
  }, [close, track, events.EDIT_PROFILE_PROMPT_EDIT_BTN_CLICK]);

  const onContinue = React.useCallback(() => {
    close();
    track(events.EDIT_PROFILE_PROMPT_CONTINUE_BTN_CLICK);
  }, [close, track, events.EDIT_PROFILE_PROMPT_CONTINUE_BTN_CLICK]);

  return (
    <Modal
      open
      onClose={onClose}
      titleId={titleId}
      contentId={contentId}
      paperClassName={classes.root}
    >
      <div className={classes.title}>Application information</div>
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          <img src={splashBg} alt="icon-background" draggable="false" />
          <FontAwesomeIcon icon={['fal', 'tasks']} className={classes.icon} />
        </div>
        <div className={classes.introText}>
          <span>
            For this job application we will use your profile information (CV,
            skills, preferences, etc.) so if you want to update it before
            applying, you can do it from the&nbsp;
          </span>
          <Link
            to={{ pathname: paths.onboarding, search: queryString }}
            onClick={onEdit}
          >
            Edit Profile
          </Link>
          <span> page.</span>
        </div>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={onContinue}
        >
          Continue with the application
        </Button>
      </div>
    </Modal>
  );
}

export { EditProfilePrompt };
