// @flow
import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gi from '../assets/images/team/gi.png';
import kt from '../assets/images/team/kt.png';
import na from '../assets/images/team/na.png';
import am from '../assets/images/team/am.png';
import vt from '../assets/images/team/vt.png';
import sayHiBubble from '../assets/images/about-page/Group@3x.svg';
import headerImg from '../assets/images/about-page/home-img@3x.svg';
import { Footer } from '../components/Footer';
import { PageHead } from '../components/basic/PageHead';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  header: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(8, 0),

    '& img': {
      position: 'absolute',
      right: -theme.spacing(32),
      top: -theme.spacing(4),

      '@media (max-width: 980px)': {
        right: -theme.spacing(52),
      },

      '@media (max-width: 768px)': {
        opacity: 0.4,
      },
    },

    '& h1': {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 600,
      maxWidth: `24ch`,
    },
    '& h2': {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      maxWidth: `52ch`,
    },
  },
  container: {
    '& h3': {
      fontSize: theme.typography.pxToRem(36),
      fontWeight: theme.typography.fontWeightBold,
    },
    '& h4': {
      fontSize: theme.typography.pxToRem(21),
      fontWeight: 500,
      marginBottom: theme.spacing(2),
    },
  },
  teamContent: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    maxWidth: `54ch`,
  },
  row: {
    marginBottom: theme.spacing(8),
  },
  grid4: {
    display: 'grid',
    gridTemplateColumns: `repeat(4, 1fr)`,
    gridGap: theme.spacing(8),

    '@media (max-width: 768px)': {
      gridTemplateColumns: `repeat(2, 1fr)`,
    },
    '@media (max-width: 400px)': {
      gridTemplateColumns: `repeat(1, 1fr)`,
    },
  },
  icon: {
    fontSize: theme.typography.pxToRem(48),
    color: theme.palette.primary.main,
  },
  teamImages: {
    marginTop: theme.spacing(4),
    marginBottom: -theme.spacing(2),
    marginLeft: -theme.spacing(4),
    // marginRight: -theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    '& img': {
      maxWidth: 144,
      maxHeight: 144,
    },

    '@media (max-width: 640px)': {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  photo: (props) => {
    return {
      margin: theme.spacing(2, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: props.center ? 'center' : 'left',
      justifyContent: 'flex-start',
      maxWidth: 350,

      '& .photo__name': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 500,
      },

      '& .photo__title': {
        fontWeight: theme.palette.grey[500],
        color: theme.palette.grey[500],
        maxWidth: props.subtitleWidth ? props.subtitleWidth : `16ch`,
      },
    };
  },

  cta: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(6, 8),
    borderRadius: theme.shape.borderRadius * 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',

    '@media (max-width: 640px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },

    '& img': {
      position: 'absolute',
      width: 90,
      height: 56,
      top: -theme.spacing(2),
      right: theme.spacing(8),
    },

    '& p': { margin: 0 },

    '& p:nth-of-type(1)': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(27),
    },

    '& p:nth-of-type(2)': {
      fontSize: theme.typography.pxToRem(27),

      '& a': {
        fontWeight: theme.typography.fontWeightBold,
        color: '#FFEC00',
        textDecoration: 'none',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,

        '&:hover': {
          marginTop: -3,
          backgroundColor: `#ffec00`,
          color: theme.palette.primary.main,
        },
      },
    },
  },
}));

export function About() {
  const classes = useStyles();

  return (
    <>
      <PageHead
        title="About | NobleHire"
        description="Noble Hire is a Sofia-based Bulgarian startup &amp; we aim to change the way job-search works for the tech industry."
      />
      <div className={classes.root}>
        <div className={`${classes.header} ${classes.row}`}>
          <Container style={{ position: 'relative' }}>
            <img
              src={headerImg}
              alt="Abstract graphics with the N letter in the middle"
            />
            <div style={{ position: 'relative' }}>
              <h1>
                We believe everyone should fulfill their purpose in life and
                getting the right job is the first step towards it.
              </h1>
              <h2>
                Noble Hire is a Sofia-based Bulgarian startup & we aim to change
                the way job-search works for YOU. <br /> <br />
                We are developing a job board for selected companies with proven
                good practices in their business and culture. Using our Company
                Profile Framework you can learn everything you need to know
                about the company before taking a step into their office -
                project, culture, benefits, pay, you name it!
              </h2>
            </div>
          </Container>
        </div>
        <Container className={classes.container}>
          <div className={classes.row}>
            <h3>Our Values</h3>
            <div className={classes.grid4}>
              <div>
                <Icon name="handshake-alt" />
                <h4>Transparency</h4>
                <p>
                  We believe that being transparent and honest strengthens our
                  team and leads to better understanding and growth.
                </p>
              </div>

              <div>
                <Icon name="check-circle" />
                <h4>Getting Shit Done</h4>
                <p>
                  We perform our best when we have the freedom to choose how we
                  work and the ownership to deliver actual results.
                </p>
              </div>

              <div>
                <Icon name="book" />
                <h4>Constant Learning</h4>
                <p>
                  We always look to improve ourselves and are open to learning
                  from every experience that comes our way.
                </p>
              </div>

              <div>
                <Icon name="fist-raised" />
                <h4>Investing in Strengths</h4>
                <p>
                  We allow our team mates to unleash their full potential by
                  enabling them to do what they’re good at, and hiring to fill
                  up their weaknesses.
                </p>
              </div>
            </div>
          </div>

          <div className={classes.row}>
            <h3>Team</h3>

            <p className={classes.teamContent}>
              We are a team of 9 people and we have spent a lot of time and
              effort to create this platform. We are constantly improving and
              trying new things so that one day we can be choice number one when
              it comes to searching for a new job.
              <br />
              <br /> What really inspires us to keep going is our amazing team.
              At heart, Noble Hire is a company of friends. We surround
              ourselves with people we like and follow our core values which
              keeps us motivated and productive.
            </p>

            <div className={classes.teamImages}>
              <Photo src={gi} alt="Georgi Ivanov" title="Founder & CEO" />
              <Photo
                src={kt}
                alt="Kaloyan Toshev"
                title="Founder & Head of UX & Design"
              />
              <Photo
                src={na}
                alt="Nickolay Andonov"
                title="Head of Product & Partnerships"
              />
              <Photo
                src={am}
                alt="Anna Mitreva"
                title="Head of Talent & Company Success"
              />
              <Photo src={vt} alt="Vladimir Tsvetkov" title="CTO" />
            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.cta}>
              <img src={sayHiBubble} alt="A conversation bubble - Say Hi" />
              <p>Want to say hi?</p>
              <p>
                Do it at <a href="mailto:hi@noblehire.io">hi@noblehire.io</a>
              </p>
            </div>
          </div>
        </Container>

        <Footer />
      </div>
    </>
  );
}

export function Photo({
  src,
  alt,
  title,
  center = false,
  subtitleWidth = `16ch`,
}: {
  src: string,
  alt: string,
  title: string,
  center?: boolean,
  subtitleWidth?: any,
}) {
  const classes = useStyles({ center, subtitleWidth });
  return (
    <div className={classes.photo}>
      <img src={src} alt={alt} />
      <span className="photo__name">{alt}</span>
      <span className="photo__title">{title}</span>
    </div>
  );
}

function Icon({ name }: { name: string }) {
  const classes = useStyles();

  return <FontAwesomeIcon className={classes.icon} icon={['fal', name]} />;
}
