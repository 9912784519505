// @flow
import * as React from 'react';
import { ForgottenPasswordResetForm } from '../components/auth/talents/components/ForgottenPasswordResetForm';
import { AuthFrame } from '../components/auth/talents/components/AuthFrame';
import { DisableScrollOnMount } from '../components/basic/DisableScrollOnMount';
import { HideTopArrow } from '../components/wordpress/HideTopArrow';

function ForgottenPasswordReset() {
  return (
    <DisableScrollOnMount>
      <HideTopArrow>
        <AuthFrame>
          <ForgottenPasswordResetForm />
        </AuthFrame>
      </HideTopArrow>
    </DisableScrollOnMount>
  );
}

export { ForgottenPasswordReset };
