// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { paths } from '../../../core/constants';

type History = { push: (path: string) => void };
type Props = {
  onClick: () => void,
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    cursor: 'pointer',
    fontSize: 24,
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(4),
  },
}));

function Close({ onClick }: Props) {
  const classes = useStyles();

  return (
    <FontAwesomeIcon
      onClick={onClick}
      icon={['fal', 'times']}
      className={classes.closeButton}
    />
  );
}

Close.goToRoot = function goToRoot(history: History) {
  history.push(paths.root);
};

export { Close };
