// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useReferralStats } from './useReferralStats';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey.dark,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
}));

function ReferralStats() {
  const { stats, loading } = useReferralStats();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {loading ? (
        <>Loading your stats...</>
      ) : (
        <>
          <div className={classes.row}>
            <div>Invite links visits</div>
            <div>{stats.inviteLinksVisits}</div>
          </div>
          <div className={classes.row}>
            <div>Pending invitations</div>
            <div>{stats.pendingInvitations}</div>
          </div>
          <div className={classes.row}>
            <div>Registered</div>
            <div>{stats.registered}</div>
          </div>
          <div className={classes.row}>
            <div>Applied</div>
            <div>{stats.applied}</div>
          </div>
          {/* <div className={classes.row}>
            <div>Hired</div>
            <div>{stats.hired}</div> 
          </div> */}
        </>
      )}
    </div>
  );
}

export { ReferralStats };
