// @flow
import React from 'react';
import { Card, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import type { LocationObj } from './context/CompanyContext';
import { InputV2, useInputV2Styles } from '../../basic/form/InputV2';
import type { ValidateFuncBuilderConfig } from '../../../core/types';
import { validateRequired } from '../../../core/validators';
import { buildValidateFunc } from '../../../utils/buildValidateFunc';
import { Form } from '../../basic/form/Form';
import { PlacesAutocomplete } from '../../basic/form/PlacesAutocomplete';
import {
  geocodeByPlaceId,
  geocodeByAddress,
} from 'react-google-places-autocomplete';
import { Button } from '../../basic/Button';

const useStyles = makeStyles((theme) => ({
  form: {
    border: `1px solid ${theme.palette.grey[300]}`,
    overflow: 'initial',
  },
}));

type LocationFormProps = {
  editingItem: ?LocationObj,
  onCancel: () => void,
  onAddSubmit: (values: { [key: string]: any }) => void,
  onEditSubmit: (values: { [key: string]: any }) => void,
};
export function LocationForm({
  editingItem,
  onCancel,
  onAddSubmit,
  onEditSubmit,
}: LocationFormProps) {
  const c = useStyles();
  const theme = useTheme();
  const inputV2Styles = useInputV2Styles();
  const addLocationValidation: ValidateFuncBuilderConfig = {
    location: [validateRequired('address')()],
    // size: [
    //   validateRequired('teamSize')(),
    //   validateNumeric('teamSize')(),
    //   validateNonNegativeNumber('teamSize')(),
    // ],
    // yearSince: [
    //   validateRequired('founded')(),
    //   validateNumeric('founded')(),
    //   validateNumberRange('founded', { high: 2025, low: 1900 })(),
    // ],
  };
  const validate = buildValidateFunc(
    addLocationValidation,
    (values, config) => config,
    0
  );

  const internalOnAddSubmit = (...data) => {
    const [value] = data;

    geocodeByPlaceId(value.address.value.place_id).then((geocodeData) => {
      onAddSubmit({ ...value, address: geocodeData[0] });
    });
  };
  const internalOnEditSubmit = (...data) => {
    const [value] = data;

    geocodeByAddress(value.address.label).then((geocodeData) => {
      onEditSubmit({ ...value, address: geocodeData[0] });
    });
  };

  return (
    <Card elevation={0} className={c.form}>
      <Box
        boxShadow="0 1px 7px 3px rgba(0,0,0,0.05)"
        padding={2}
        borderRadius={theme.shape.borderRadius}
      >
        <Form
          initialValues={{
            ...editingItem,
            address:
              editingItem &&
              editingItem.address &&
              editingItem.address.formatted_address,
            // location:
            //   editingItem &&
            //   editingItem.address &&
            //   editingItem.address.formatted_address,
          }}
          validate={validate}
          onSubmit={editingItem ? internalOnEditSubmit : internalOnAddSubmit}
        >
          {() => {
            return (
              <Box>
                <PlacesAutocomplete id="location" name="address" />
                <Box display="flex" justifyContent="space-between">
                  <Box marginRight={1}>
                    <InputV2
                      type="number"
                      classes={({ error }) => ({
                        input: error ? inputV2Styles.error : inputV2Styles.edit,
                      })}
                      formControlProps={{
                        style: {
                          width: '100%',
                          marginBottom: theme.spacing(1),
                        },
                      }}
                      placeholder="Size"
                      name="teamSize"
                      id="teamSize"
                    />
                  </Box>
                  <InputV2
                    type="number"
                    classes={({ error }) => ({
                      input: error ? inputV2Styles.error : inputV2Styles.edit,
                    })}
                    formControlProps={{
                      style: {
                        width: '100%',
                        marginBottom: theme.spacing(1),
                      },
                    }}
                    placeholder="Since (year)"
                    name="founded"
                    id="founded"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="row-reverse"
                >
                  <Button
                    color="edit"
                    variant="outlined"
                    type="submit"
                    size="small"
                    disableElevation
                  >
                    <FontAwesomeIcon
                      icon={['fal', editingItem ? 'save' : 'plus']}
                    />
                    <Box marginLeft={1} component="span">
                      {editingItem ? 'Edit location' : 'Add location'}
                    </Box>
                  </Button>
                  <Box marginRight={1}>
                    <Button
                      color="white"
                      variant="contained"
                      type="button"
                      size="small"
                      disableElevation
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
            );
          }}
        </Form>
      </Box>
    </Card>
  );
}
