// @flow
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import type { Node } from 'react';
import { useTransition, animated } from 'react-spring';
import { useMQ } from '../../components/useMQ';

export function SlidingPageShell({
  loading,
  onHideAnimDone,
  children,
  closePredicate = () => true,
}: {
  loading: boolean,
  onHideAnimDone: () => void,
  children: ({ loading: boolean, setVisible: (boolean) => void }) => Node,
  closePredicate?: () => boolean,
}) {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    // show the page after the component is mounted, in order to
    // prevent media query glitches (useMQ bellow)
    setVisible(true);
  }, []);

  // ---------------------------------------------------- //
  // responsiveness
  const mq = useMQ();
  let width = '100%';
  let maxWidth = '100%';

  if (mq.lg) {
    width = '60%';
    maxWidth = '780px'; // don't allow it to get too big sidebar
  }
  // ---------------------------------------------------- //

  // ---------------------------------------------------- //
  // - listen for "Esc" to close
  // - disable/enable scroll on open/close
  useEffect(() => {
    const handler = ({ keyCode }) => {
      if (Number(keyCode) === 27 && !loading && closePredicate()) {
        setVisible(false);
      }
    };
    window.addEventListener('keydown', handler);

    (document.body: any).style.overflow = 'hidden';
    return () => {
      window.removeEventListener('keydown', handler);
      (document.body: any).style.overflow = 'initial';
    };
  }, [loading, closePredicate]);
  // ---------------------------------------------------- //

  // ---------------------------------------------------- //
  // animations
  const dimTransitions = useTransition(visible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 140, mass: 1, tension: 30, friction: 20 },
    trail: 140,
    onRest: (done) => {
      if (done && !visible) onHideAnimDone();
    },
  });
  const transitions = useTransition(visible, null, {
    from: { right: -5000, opacity: 0.5 },
    enter: { right: 0, opacity: 1 },
    leave: { right: -5000, opacity: 0 },
    config: { duration: 50 },
  });
  // ---------------------------------------------------- //

  // ---------------------------------------------------- //
  // rendering
  const animate = ({ item, key, props }: any) => {
    return (
      item && (
        <animated.div
          key={key}
          className="jobad-page"
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            zIndex: 4,
            width,
            maxWidth,
            height: '100%',
            background: 'white',
            ...props,
          }}
        >
          {children({ loading, setVisible })}
        </animated.div>
      )
    );
  };
  const dimAnimate = ({ item, key, props }: any) => {
    return (
      item && (
        <animated.div
          key={key}
          style={{
            background: `rgba(0,0,0,0.3)`,
            position: 'fixed',
            height: '100%',
            width: '100%',
            left: 0,
            top: 0,
            zIndex: 3,
            userSelect: 'none',
            outline: 'none',
            ...props,
          }}
          role="button"
          onKeyDown={null}
          tabIndex="0"
          onClick={() => {
            if (!loading) setVisible(false);
          }}
        />
      )
    );
  };

  return (
    <>
      {transitions.map(animate)}
      {dimTransitions.map(dimAnimate)}
    </>
  );
  // ---------------------------------------------------- //
}
