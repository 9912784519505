// @flow
import { useCallback, useState } from 'react';
import { getJobsForCompany } from './jobsService';
import type { ServerLocation } from '../companies/common/context/CompanyContext';
import type { Job } from './JobsContext';
import { convServerJobToJob } from './utils';

export default function useCompanyJobs() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  const load = useCallback((slug: string): Promise<Job<ServerLocation>[]> => {
    return new Promise((resolve) => {
      setLoading(true);
      getJobsForCompany(slug).then((data) => {
        const jj: Job<ServerLocation>[] = data.map((job) => {
          return convServerJobToJob(job);
        });
        setJobs(jj);
        setLoading(false);
        resolve(jj);
      });
    });
  }, []);

  return { load, loading, jobs };
}
