// @flow
import React from 'react';
import { useJobCreate } from './JobCreateContext';
import { JobEdit } from './JobEdit';

export function JobCreate({ asPreviewBg }: { asPreviewBg: boolean }) {
  const jobCreate = useJobCreate();
  const jc = { ...jobCreate, isEdit: jobCreate.isEdit || asPreviewBg };

  return <JobEdit job={jc} />;
}
