// @flow
import { Box, makeStyles, useTheme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePreviousValue } from '../../../utils/usePreviousValue';
import ReactTooltip from 'react-tooltip';

const useSelectStyles = makeStyles((theme) => {
  return {
    option: {
      minWidth: 70,
      maxWidth: 280,
      fontSize: theme.typography.fontSize,
      textTransform: 'initial',
      fontWeight: 'normal',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      padding: theme.spacing(1, 2),
      cursor: 'pointer',
      // whiteSpace: 'nowrap',

      '&:hover': {
        background: theme.palette.grey[100],
      },
    },
    noOptionsText: {
      fontSize: theme.typography.fontSize,
      textTransform: 'initial',
      fontWeight: theme.typography.fontWeightRegular,
      textAlign: 'center',
      color: theme.palette.grey[500],
      padding: theme.spacing(2),
      whiteSpace: 'nowrap',
    },
    tooltip: {
      maxWidth: 280,
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'initial !important',
    },
  };
});

type Option = any;
type Selected = Array<any>;

type Props = {
  defaultLabel?: string | null,
  options?: Array<Option>,
  selected: Selected,
  onChange?: (selectedOptions: Selected) => void,
  lock: boolean,
  labelProperty?: string,
  cutLength?: number,
  labelForMultipleSelected: string,
  lockedTextColor?: any,
};
export function MultipleSelect({
  defaultLabel,
  options,
  selected = [],
  onChange,
  lock = false,
  labelProperty = 'label',
  labelForMultipleSelected = 'Multiple...',
  cutLength,
  lockedTextColor,
}: Props) {
  const c = useSelectStyles();
  const theme = useTheme();
  const textColor = !lockedTextColor
    ? theme.palette.common.black
    : lockedTextColor;

  // states
  const [selectedOptions, setSelectedOptions] = useState(selected);
  const [selecting, setSelecting] = useState(false);

  useEffect(() => setSelectedOptions(selected), [selected]);

  const select = (opt: Option) => {
    setSelectedOptions((prev) => {
      if (prev.some((el) => el.id === opt.id))
        return prev.filter((el) => el.id !== opt.id); // remove
      return [...prev, opt]; // add
    });
  };

  const prevSelecting = usePreviousValue(selecting);
  useEffect(() => {
    if (prevSelecting && !selecting && typeof onChange === 'function')
      onChange(selectedOptions);
  }, [selecting, selectedOptions, onChange, prevSelecting]);

  // simulate close on blur + close on esc
  const ref = useRef();
  useEffect(() => {
    const outsideClick = ({ target }) =>
      ref.current && !ref.current.contains(target) && setSelecting(false);
    const esc = (e: KeyboardEvent) => e.keyCode === 27 && setSelecting(false);

    window.document.body.addEventListener('click', outsideClick);
    window.document.body.addEventListener('keyup', esc);
    return () => {
      window.document.body.removeEventListener('click', outsideClick);
      window.document.body.removeEventListener('keyup', esc);
    };
  }, []);

  const getFirstSelected = (so: Selected) => {
    const def = {
      [labelProperty]: lock ? <span>&mdash;</span> : defaultLabel,
    };
    if (!options || !so.length) return def;
    return options.find((o) => o.id === so[0].id) || def;
  };

  const isOptionSelected = (_selectedOptions: Selected, option: Option) => {
    return _selectedOptions.some((so) => so.id === option.id);
  };

  const areMultipleOptionsSelected = (_selectedOptions: Selected) => {
    return _selectedOptions.length > 1;
  };

  const toLabel = (str: string) => {
    if (cutLength) {
      return str.length > cutLength ? `${str.substring(0, cutLength)}...` : str;
    }
    return str;
  };

  const selectedToTitle = (_selectedOptions: Selected) => {
    return _selectedOptions.length
      ? _selectedOptions.map((o) => o.address).join(';<br />')
      : undefined;
  };

  const label =
    selectedOptions.length && selectedOptions.length > 1
      ? labelForMultipleSelected
      : toLabel(getFirstSelected(selectedOptions)[labelProperty]);

  const title = selectedOptions.length
    ? selectedToTitle(selectedOptions)
    : undefined;
  return (
    <Box ref={ref} position="relative">
      <Box
        display="flex"
        alignItems="center"
        onClick={(e: any) => {
          if (!lock) setSelecting(true);

          if (lock) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        style={{ cursor: lock ? 'auto' : 'pointer' }}
        color={lock ? textColor : theme.palette.edit.main}
        data-tip={
          areMultipleOptionsSelected(selectedOptions) ? undefined : title
        }
      >
        {label}
        {areMultipleOptionsSelected(selectedOptions) && lock ? (
          <React.Fragment>
            <ReactTooltip
              place="bottom"
              className={c.tooltip}
              multiline
              clickable
            />
            <Box
              zIndex={1}
              position="relative"
              cursor="pointer"
              marginLeft={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="100%"
            >
              <FontAwesomeIcon data-tip={title} icon={['fas', 'info-circle']} />
            </Box>
          </React.Fragment>
        ) : lock ? (
          <React.Fragment>
            <ReactTooltip
              place="bottom"
              className={c.tooltip}
              multiline
              clickable
            />
          </React.Fragment>
        ) : null}
      </Box>
      <Box>
        {selecting ? (
          <Box position="absolute" left={0} top={25} zIndex={2}>
            <Box
              style={{
                background: theme.palette.common.white,
                border: `1px solid ${theme.palette.grey[300]}`,
              }}
              boxShadow="0 1px 7px 3px rgba(0,0,0,0.05)"
              borderRadius={theme.shape.borderRadius}
              overflow="auto"
              maxHeight={300}
              width={280}
            >
              {!options || options.length < 1 ? (
                <div className={c.noOptionsText}>No available options.</div>
              ) : null}
              {options &&
                options.map((option) => {
                  const isSel = isOptionSelected(selectedOptions, option);
                  return (
                    <Box
                      key={option.id}
                      className={c.option}
                      onClick={() => select(option)}
                      display="flex"
                      alignItems="center"
                    >
                      <Box
                        style={{ width: 23, height: 23 }}
                        marginRight={1}
                        bgcolor={
                          isSel
                            ? theme.palette.edit.main
                            : theme.palette.grey[100]
                        }
                        borderRadius="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color={isSel ? 'white' : theme.palette.grey[500]}
                      >
                        <FontAwesomeIcon icon={['fal', 'check']} />
                      </Box>
                      <Box flex={1}>{option[labelProperty]}</Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
