// @flow
import React from 'react';
import { useTheme, Box } from '@material-ui/core';
import type { HiringProcess as HiringProcessType } from '../JobsContext';
import type { Editable } from '../types';
import { txtColor } from '../utils';
import { Title } from '../../basic/Title';
import { HiringProcessList } from './HiringProcessList';

export function HiringProcess({
  steps,
  isEdit,
  onItemAdd,
  onItemEdit,
  onItemRemove,
}: {
  steps: HiringProcessType[],
  onItemAdd?: (item: HiringProcessType) => void,
  onItemEdit?: (item: HiringProcessType) => void,
  onItemRemove?: (item: HiringProcessType) => void,
} & Editable) {
  const theme = useTheme();

  return (
    <Box color={txtColor(theme, isEdit)}>
      <Title variant={Title.variants.t4}>Hiring process</Title>
      <Box display="flex" alignItems="flex-start">
        <Box flex={1}>
          <HiringProcessList
            items={steps}
            onItemAdd={onItemAdd}
            onItemRemove={onItemRemove}
            onItemEdit={onItemEdit}
            isEdit={isEdit}
          />
        </Box>
      </Box>
    </Box>
  );
}
