// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Container, useTheme } from '@material-ui/core';
import { paths } from '../core/constants';
import { Footer } from '../components/Footer';
import money from '../assets/images/referrers-page/2k8k.png';
import heart from '../assets/images/referrers-page/heart.svg';
import { PageHead } from '../components/basic/PageHead';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(8, 0),
    minHeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: theme.palette.common.white,
    textAlign: 'center',

    '& h1': {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 600,
      maxWidth: `38ch`,
      marginBottom: theme.spacing(2),
      paddingBottom: 0,
    },

    '& h2': {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      maxWidth: `52ch`,
      margin: 0,
      padding: 0,
    },
  },
  step: {
    '& .num': {
      display: 'block',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(27),
      color: theme.palette.primary.main,
      margin: 0,
      padding: 0,
    },
    '& h4': {
      margin: 0,
      padding: 0,
      display: 'block',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(27),
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(4),
    },
  },
  section: {
    margin: theme.spacing(16, 0),
  },
  section1: {
    maxWidth: '52ch',
    margin: 'auto',
    textAlign: 'center',

    '& h3': {
      fontSize: theme.typography.pxToRem(27),
      fontWeight: theme.typography.fontWeightBold,
    },
  },

  section2: {
    maxWidth: 960,
    margin: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: theme.spacing(4),
  },

  section4: {
    background: theme.palette.grey[100],
    padding: theme.spacing(4, 0),

    '& .faqContainer': {
      maxWidth: '768px',
      margin: 'auto',
    },

    '& h3': {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(27),
      fontWeight: theme.typography.fontWeightBold,
    },

    '& h4': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(21),
    },

    '& .faq': {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      padding: theme.spacing(4, 0),
    },
  },

  section6: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(8, 0),
    minHeight: 500,
    width: '100%',
    marginBottom: -theme.spacing(16),

    '& .container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      color: theme.palette.common.white,
      textAlign: 'center',

      '& .white': {
        marginBottom: theme.spacing(2),
      },

      '& h2': {
        fontSize: theme.typography.pxToRem(32),
        fontWeight: 600,
        maxWidth: `38ch`,
        marginBottom: theme.spacing(2),
        paddingBottom: 0,
      },

      '& h4': {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 500,
        maxWidth: `76ch`,
        marginBottom: theme.spacing(4),
        margin: 0,
        padding: 0,
      },
    },
  },

  sectionSplit: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '@media (max-width: 768px)': {
      flexDirection: 'column',

      '& .push': {
        marginBottom: theme.spacing(4),
        marginRight: 0,
      },
    },

    '& h3': {
      fontSize: theme.typography.pxToRem(27),
      fontWeight: theme.typography.fontWeightBold,
    },

    '& .content': {
      maxWidth: '64ch',
    },

    '& .push': {
      marginRight: theme.spacing(4),
    },

    '& img': {
      maxWidth: 450,
    },
  },

  btn: {
    display: 'inline-block',
    padding: theme.spacing(2, 4),
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: theme.borderRadius,
    textAlign: 'center',
    textDecoration: 'none',

    '&.white': {
      background: theme.palette.common.white,
      color: theme.palette.common.black,

      '&:hover': {
        color: theme.palette.common.white,
        background: theme.palette.common.black,
      },
    },

    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));

export function Referrers() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <PageHead
        title="For Referrers | NobleHire"
        description="Refer people to join the Noble Hire platform and make 2,000 – 8,000 BGN, if they get hired."
      />
      <div className={`${classes.header}`}>
        <h1>
          Refer people to join the Noble Hire platform and make 2,000 – 8,000
          BGN, if they get hired.
        </h1>
        <h2>
          If the person gets hired you will get 50% of the size of their gross
          monthly salary as a referral bonus. The referral bonus ranges between
          2,000 and 8,000 BGN.
        </h2>
      </div>
      <Container className={classes.container}>
        <div className={classes.section}>
          <div className={classes.section1}>
            <h3>How do I get started?</h3>
            <p>
              You need to have a Noble Hire account which takes only a minute to
              create. Then through it, you can generate a referral link.
            </p>
          </div>
        </div>

        <div className={classes.section}>
          <div className={classes.section2}>
            <Step num={1} title="Go to a page you want to share">
              You can refer a person to any page of Noble Hire platform –
              generate a link to the job board, to a company page or to a
              specific job ad.
            </Step>
            <Step num={2} title="Open the Refer side menu">
              Click the icon with the 3 people at the top right corner to open
              the Referrer Dashboard or simply click the “Refer” button that’s
              at the right sidebar of any job ad.
            </Step>
            <Step num={3} title="Refer via link">
              You can either send it to a person or share it to your whole
              network. This way we will know that the candidates come from your
              referral.
            </Step>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: theme.spacing(4),
            }}
          >
            <Link to={paths.root} className={classes.btn}>
              See Our Job Ads
            </Link>
          </div>
        </div>

        <div className={classes.section}>
          <div className={`${classes.section3}, ${classes.sectionSplit}`}>
            <div className="content push">
              <h3>How do you make money?</h3>

              <p>
                You make money when a person joins Noble Hire through your
                referral link and later applies and gets hired for any job on
                the platform in the next 6 months. You can also send us their CV
                saving candidate’s effort. We’ll send them an email for
                confirmation of the referral.
              </p>

              <p>
                If the person gets hired you will get 50% of the size of their
                gross monthly salary as a referral bonus. The referral bonus
                ranges between 2,000 and 8,000 BGN.
              </p>
            </div>

            <div>
              <img src={money} alt="2000-8000 BGN" />
            </div>
          </div>
        </div>

        <div className={classes.section}>
          <div className={`${classes.section3}, ${classes.sectionSplit}`}>
            <div className="push">
              <img src={heart} alt="Heart" />
            </div>

            <div className="content">
              <h3>Users love us and like to get hired through us</h3>

              <p>
                Users just love us. They love our detailed job descriptions.
                They like our selection of top companies. They know that we
                care.
              </p>

              <p>You can recommend us without any hesitation.</p>
            </div>
          </div>
        </div>
      </Container>

      <div className={classes.section}>
        <div className={classes.section4}>
          <Container>
            <h3>F.A.Q.</h3>

            <div className="faqContainer">
              <div className="faq">
                <h4>
                  What’s the difference between sharing a referral link and
                  uploading a CV?
                </h4>
                <p>
                  If you’ve shared a referral link then the candidates have to
                  apply by themselves. When they click the link and later create
                  a profile on Noble Hire, you automatically get credited for
                  the referral and are eligible for a bonus. You can generate
                  that kind of link on any of our website’s pages by clicking
                  the top-right icon saying “Refer”. Share that link with your
                  network (via LinkedIn, Facebook, Twitter, blog, etc.) so you
                  can maximize your reach.
                </p>
                <p>
                  If you prefer not bothering the candidates then simply upload
                  their CV for the positions you think they’re most suitable
                  for. Just remember to remind the person to confirm your
                  referral on the automatic email they’d receive from our
                  platform.
                </p>
                <p>
                  In both cases if the person gets hired you will get 50% of the
                  size of their gross monthly salary as a referral bonus, which
                  ranges between 2,000 and 8,000 BGN.
                </p>
              </div>

              <div className="faq">
                <h4>
                  If a talent is invited by two different users, who gets the
                  bonuses?
                </h4>
                <p>
                  The referrer whose link has been clicked last before user
                  registration receives the bonuses. If the person has already
                  been referred by another person then you won’t be able to
                  refer them for a period of 6 months.
                </p>
              </div>

              <div className="faq">
                <h4>When do I get paid after a successful hire?</h4>
                <p>
                  You get paid 6 months after the person gets hired. If the
                  talent leaves their job before the 6 months mark, we don’t get
                  paid thus you don’t get your cut as well. We guarantee the
                  quality of our product by offering our companies a 6-month
                  money-back guarantee for every hire they make through our
                  platform.
                </p>
              </div>

              <div className="faq">
                <h4>
                  Can I invite people if I’m not sure they’re looking for a job?
                </h4>
                <p>
                  Sure, they don’t need to apply for a job straight away.
                  Wherever they get hired in the next 6 months we’ll split the
                  bonus. We’ve actually seen that people who are not actively
                  looking often find a good job match and apply sooner than
                  expected.
                </p>
              </div>

              <div className="faq">
                <h4>
                  Can I earn referral bonuses for inviting people who already
                  have accounts on Noble Hire?
                </h4>
                <p>
                  No, you can’t. You earn bonuses only for unique people you
                  refer to the platform.
                </p>
                <p>
                  If you’re not sure whether or not a person has an account, try
                  to refer them and we’ll let you know whether this is an
                  existing user or not.
                </p>
              </div>

              <div className="faq">
                <h4>
                  I have an IT-oriented website/newsletter. Can I refer people
                  to Noble Hire?
                </h4>
                <p>
                  Absolutely. Get your referral links and post them to your
                  website or include them in your newsletter. It’s a great
                  opportunity to monetize your audience by helping them find
                  their next challenge.
                </p>
              </div>
            </div>
          </Container>
        </div>

        <div className={classes.section6}>
          <Container>
            <div className="container">
              <h2>
                Invite people to join the Noble Hire platform and make 2,000 –
                8,000 BGN per each successful hire
              </h2>
              <h4>
                Our companies pay us per hire, only when they actually hire
                someone from the platform. We usually charge them between 1 and
                2 gross monthly salaries per hire. We’ll split equally that
                bonus with you if you help them find the person they’re looking
                for.
              </h4>
              <a className={`${classes.btn} white`} href="/register?rtype=s">
                Become a Noble Referrer
              </a>
              <small>It’s quick, easy, free, and profitable.</small>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
}

function Step({ num, title, children }: any) {
  const classes = useStyles();
  return (
    <div className={classes.step}>
      <div className="num">{num}.</div>
      <h4>{title}</h4>
      <p>{children}</p>
    </div>
  );
}
