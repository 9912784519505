// @flow
function validateLinkedInUrl(link: string) {
  const pattern = new RegExp(
    '^(http(s)?)://(www.)?linkedin.com/[a-zA-Z0-9]+(.+)?$',
    'gim'
  );
  return pattern.test(link);
}

export { validateLinkedInUrl };
