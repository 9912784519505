// @flow
import API from '../../core/rest/API';
import type { ReferralLinkObject } from '../../core/types';

type Register = {
  name?: string,
  email: string,
  password: string,
  consent: boolean,
  landingPage: ?string,
  referringPage: ?string,
  referralLinks: ?[ReferralLinkObject],
  consentVersion?: number,
};

function register({
  name,
  email,
  password,
  consent, // eslint-disable-line
  referralLinks,
  landingPage,
  referringPage,
  consentVersion = 2,
}: Register) {
  let data = {
    name,
    email,
    password,
    consent,
    landingPage,
    referringPage,
    consentVersion,
  };

  if (referralLinks) {
    data = { ...data, referralLinks };
  }

  return API.putResponse('/user', data);
}

type CompanyRegister = {
  brand: string,
  name: string,
  email: string,
  password: string,
  consent: boolean,
  consentVersion?: number,
};

function companyRegister({
  brand,
  name,
  email,
  password,
  consent, // eslint-disable-line
  consentVersion = 2,
}: CompanyRegister) {
  return API.putResponse('/company-user', {
    brand,
    name,
    email,
    password,
    consent,
    consentVersion,
  });
}

type Login = {
  email: string,
  password: string,
};

function login({ email, password }: Login) {
  return API.postResponse(
    '/auth/login',
    {
      email,
      password,
    },
    { ignoreUnauthorised: true }
  );
}

type ForgottenPassword = {
  email: string,
};

// eslint-disable-next-line
function forgottenPassword({ email }: ForgottenPassword): Promise<any> {
  return API.putResponse('/user/forgotten-pass', { email });
}

type ForgottenPasswordReset = {
  password: string,
  expiration: string,
  userId: string,
  resetToken: string,
};

function forgottenPasswordReset({
  password,
  expiration,
  userId,
  resetToken,
}: ForgottenPasswordReset): Promise<any> {
  return API.postResponse('/user/forgotten-pass', {
    token: {
      userUuid: userId,
      expiration,
      resetToken,
    },
    newPassword: password,
  });
}

type LoginAttempts = {
  email: string,
  password: string,
  maxAttempts: number,
};

/**
 * Example usage:
 * Right after registration, we're issuing a login request to obtain a token.
 * There are times, when there's a database latency. In order to successfully
 * login, we use this function to handle that case by issuing several login
 * requests one after the other.
 */
function loginAttempts({ email, password, maxAttempts }: LoginAttempts) {
  return new Promise<any>((resolve, reject) => {
    let i = 0;

    function doLogin() {
      login({ email, password })
        .then(resolve)
        .catch((error) => {
          i += 1;

          const isAuthenticationError = error.response.status === 403;

          if (i <= maxAttempts && isAuthenticationError) {
            doLogin(); // recursively try to login
          } else {
            reject(error); // if all the attempts are exhausted, reject
          }
        });
    }

    doLogin();
  });
}

function getUser() {
  return API.get('/user/me');
}

function getUserStats() {
  return API.get('/user/me/stats');
}

function consent() {
  return API.post('/user/me/consent', {
    consent: true,
    consentVersion: 2,
  });
}

function deleteAccount({ uuid }: { uuid: string }) {
  return API.delete(`/user/${uuid}`);
}

function oldUser() {
  return API.post('/old-user');
}

export {
  register,
  companyRegister,
  login,
  forgottenPassword,
  forgottenPasswordReset,
  loginAttempts,
  getUser,
  consent,
  deleteAccount,
  oldUser,
  getUserStats,
};
