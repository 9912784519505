// @flow
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { Footer } from '../components/Footer';
import { PageHead } from '../components/basic/PageHead';

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    padding: theme.spacing(4, 0),
  },
}));

export function PrivacyPolicy() {
  const classes = useStyles();

  return (
    <div>
      <PageHead title="Privacy Policy | Noble Hire" />
      <Container>
        <div className={classes.innerContainer}>
          <h1>Privacy Policy</h1>
          <p>Effective date: 01/07/2019</p>
          <p>Introduction</p>
          <p>
            Noble values the privacy and security of its users. This privacy
            policy applies to the use of 
            <a href="https://noblehire.io">https://noblehire.io</a>, as well as
            to all other websites, applications or services which are owned or
            operated by Noble Tech Ltd.
          </p>
          <p>
            Noble Hire is owned by "NOBLE TECH" LTD, UIC: 205211882, registered
            and management address in the city of Sofia 1164, Lozenets district
            area, 12 Midzhur str., floor 3.
          </p>
          <p>
            The purposes and means of data processing are determined by the
            legal person, Noble -- the "data controller" of your information,
            alone or jointly with others.
          </p>
          <p>
            If you have any questions regarding this Policy, please contact us
            at  hi@noblehire.io
          </p>
          <p>
            The Policy describes the types of information we gather from Service
            Users, including people using our Services and individual users
            ("you" or "users") interacting with the Services, and the ways in
            which we use, transfer, and secure that information. By using any of
            the Services, you agree to the practices of data handling as
            described in the Policy. This Policy does not govern the information
            we receive from third parties who are not Service Users.  If you do
            not agree to the terms of this Policy, please do not use any of our
            Services.  Each time you use our Services, the current version of
            this Policy will apply.  Accordingly, when you use any of our
            Services, you should check the date of this Policy at the top and
            review any changes since the last time you read the Policy.
          </p>
          <p>Terms</p>
          <p>
            For the purposes of this Privacy Policy, the following terms have
            meanings as stated:
          </p>
          <p>
            - 'controller' means the natural or legal person, public authority,
            agency or another body which, alone or jointly with others,
            determines the purposes and means of the processing of personal
            data; - 'personal data' means any information relating to an
            identified or identifiable natural person ('data subject'); an
            identifiable natural person is one who can be identified, directly
            or indirectly, in particular by reference to an identifier such as a
            name, an identification number, location data, an online identifier
            or to one or more factors specific to the physical, physiological,
            genetic, mental, economic, cultural or social identity of that
            natural person; - 'processing' means any operation or set of
            operations which is performed on personal data or on sets of
            personal data, whether or not by automated means, such as
            collection, recording, organization, structuring, storage,
            adaptation or alteration, retrieval, consultation, use, disclosure
            by transmission, dissemination or otherwise making available,
            alignment or combination, restriction, erasure or destruction; -
            'processor' means a natural or legal person, public authority,
            agency or another body which processes personal data on behalf of
            the controller; - 'recipient' means a natural or legal person,
            public authority, agency or another body, to which the personal data
            are disclosed, whether a third party or not. However, public
            authorities which may receive personal data in the framework of a
            particular inquiry in accordance with Union or Member State
            law shall not be regarded as recipients; the processing of those
            data by those public authorities shall be in compliance with the
            applicable data protection rules according to the purposes of the
            processing; - 'third party' means a natural or legal person, public
            authority, agency or body other than the data subject, controller,
            processor and persons who, under the direct authority of the
            controller or processor, are authorized to process personal data; -
            'consent' of the data subject means any freely given, specific,
            informed and unambiguous indication of the data subject's wishes by
            which he or she, by a statement or by clear affirmative action,
            signifies agreement to the processing of personal data relating to
            him or her; - 'personal data breach' means a breach of security
            leading to the accidental or unlawful destruction, loss, alteration,
            unauthorized disclosure of, or access to, personal data transmitted,
            stored or otherwise processed.
          </p>
          <p>####  Types of Data We Collect</p>
          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally, identifiable information
            may include, but is not limited to:
          </p>
          <p>
            - Email address - First name and last name - Phone number - Address,
            State, Province, ZIP/Postal code, City - CV and work experience and
            skills - Social network profile like LinkedIn - Cookies and Usage
            Data
          </p>
          <p>USAGE DATA</p>
          <p>
            We may also collect information on how the Service is accessed and
            used ("Usage Data"). This Usage Data may include information such as
            your computer's Internet Protocol address (e.g. IP address), browser
            type, browser version, the pages of our Service that you visit, the
            time and date of your visit, the time spent on those pages, unique
            device identifiers and other diagnostic data.
          </p>
          <p>TRACKING & COOKIES DATA</p>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and hold certain information.
          </p>
          <p>
            Cookies are files with small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Tracking technologies also used
            are beacons, tags, and scripts to collect and track information and
            to improve and analyze our Service.
          </p>
          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service. You can
            learn more about how to manage cookies in the Browser Cookies Guide.
          </p>
          <p>Examples of Cookies we use:</p>
          <p>
            - Session Cookies. We use Session Cookies to operate our Service.\
            - Preference Cookies. We use Preference Cookies to remember your
            preferences and various settings.\ - Security Cookies. We use
            Security Cookies for security purposes.
          </p>
          <p>
            The Legal Basis for Processing the Data (Lawfulness of Processing).
          </p>
          <p>
            - Processing is based on your consent and on the basis of personal
            data provided by you. You have given your consent to a referral who
            must  declare that you have given your explicit and informed consent
            to submit your personal data to Noble Hire for the purpose of
            collecting and processing your personal data for the purposes of
            matching you as a candidate for a job position and shares it with
            the third party (the employer) for the purposes of job application
            and selection. You agree to give your explicit consent to the
            Company in order to request,  collect, use, administrate and operate
            the User's personal data for the purpose of providing our Services
            in accordance with the Company's policies and its applicable laws
            and regulations; Processing is necessary for the performance of a
            contract, whose parties are Noble and You, or in order to take steps
            at your request prior to entering the contract; - Processing is
            necessary in order to protect your vital interests as a data subject
            or those of another natural person; - Processing is necessary for
            compliance with a legal obligation to which  we are subject;
          </p>
          <p>
            In case We intend to use Your personal data for a purpose other than
            the one you have agreed to, We shall provide the data subjects with
            information prior to the actions and We shall not use the data
            regarding the new purpose without Your explicit consent, regarding
            the new purpose.
          </p>
          <p>USE OF DATA</p>
          <p>Noble uses the collected data for various purposes:</p>
          <p>
            - To provide and maintain the Service - To notify you about changes
            to our Service - To allow you to participate in interactive features
            of our Service when you choose to do so - To provide customer care
            and support - To provide analysis or valuable information so that we
            can improve the Service - To monitor the usage of the Service - To
            detect, prevent and address technical issues - To monitor the usage
            of our Services and analyze emerging trends;
          </p>
          <p>Your rights:</p>
          <p>You have the right to:</p>
          <p>
            - Request access to the personal data that we store in connection to
            your account in a portable format. - Request the correction of any
            collected personal data. The information may include only the
            subject of personal data collection. - Receive a copy of your
            personal data in electronic format. - "The right to be forgotten"-
            you have the right to request the deletion of your personal data at
            any time where the retention of such data infringes relevant
            legislation. - Receive information from Us about Our activities in
            connection to your personal data, including the purposes of
            collection and storage, the period of time for storage, the methods
            of collecting, and the presence of automated processing. - Receive
            your data and transfer them to another administrator.  - Delete your
            account at any time. - Request from us to restrict processing. - You
            have a right to object to processing on any compelling legitimate
            grounds. - You have a right to object to the processing of personal
            data, where the basis for that processing is either public interest
            or a legitimate interest of the controller. - You have the right to
            object to processing for the purposes of direct marketing. - You
            have the right to file a complaint with a supervisory authority, in
            particular in the Member State of your habitual residence, place of
            work or place of the alleged infringement, if you consider that
            there is a personal data breach.
          </p>
          <p>How Can You Exercise Your Rights?</p>
          <p>
            To exercise your rights you can always contact us by sending an
            e-mail at hi@noblehire.io
          </p>
          <p>Sharing of Personal Information with Third Parties </p>
          <p>
            Your information, including Personal Data, may be transferred to ---
            and maintained on --- computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction. 
          </p>
          <p>
            Your personal data is most likely shared with a third party (the
            employer) for the purposes of job application and selection. If you
            are located outside Bulgaria and choose to provide information to
            us, please note that we transfer the data, including Personal Data,
            to EUROPEAN UNION, including Bulgaria and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            Noble will take all steps reasonably necessary to ensure that your
            data is treated securely and in accordance with this Privacy Policy
            and no transfer of your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of your data and other personal
            information.
          </p>
          <p>
            We may also select to transfer your Personal Information to third
            parties under special circumstances: 
          </p>
          <p>
            1. To comply with a legal requirement, a judicial proceeding, court
            order, or a legal process served by Noble or its affiliates; 2. To
            investigate a possible crime, such as a fraud or an identity theft; 
            3. In connection with the sale, purchase, merger, reorganization,
            liquidation or dissolution of Noble;  4. When we believe it is
            necessary to protect the rights, property, or safety of Noble or
            other people; 5. As otherwise required or permitted by law,
            including any contractual obligations of Noble.
          </p>
          <p>DATA RETENTION</p>
          <p>
            How long we keep the personal information we collect depends on the
            type of information, the purpose for which it is used, how sensitive
            it is, and other similar factors. In general, we will retain your
            personal information for the length of time reasonably needed to
            fulfill the purposes outlined in this privacy policy (including for
            as long as need to provide you or our customer with products and
            services), unless a longer retention period is required or permitted
            by law. We will also retain and use your information for as long as
            necessary to resolve disputes and/or enforce our rights and
            agreements. We retain your account information for the Noble Hire
            Services for as long as your account is active and thereafter unless
            you request deletion. Non-personally identifiable and aggregated
            information may be stored indefinitely.\ In case you would like to
            request Noble to permanently delete all your personal data which you
            have provided please click [here](https://noblehire.io/revoke/).
          </p>
          <p>SECURITY OF DATA</p>
          <p>
            The security of your data is important to us, but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
          <p>SERVICE PROVIDERS</p>
          <p>
            We may employ third party companies and individuals to facilitate
            our Service ("Service Providers"), to provide the Service on our
            behalf, to perform Service-related services or to assist us in
            analyzing how our Service is used.
          </p>
          <p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
          <p>ANALYTICS</p>
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>
          <p>- Google Analytics</p>
          <p>
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualize and personalize the ads of its own advertising
            network.
          </p>
          <p>
            You can opt-out of having made your activity on the Service
            available to Google Analytics by installing the Google Analytics
            opt-out browser add-on. The add-on prevents the Google Analytics
            JavaScript (ga.js, analytics.js, and dc.js) from sharing information
            with Google Analytics about visits activity.\ For more information
            on the privacy practices of Google, please visit the Google Privacy
            & Terms web page: https://policies.google.com/privacy?hl=en
          </p>
          <p>LINKS TO OTHER SITES</p>
          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click on a third party link, you will be directed to
            that third party's site. We strongly advise you to review the
            Privacy Policy of every site you visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <p>CHILDREN'S PRIVACY</p>
          <p>
            Our Service does not address anyone under the age of 18
            ("Children").
          </p>
          <p>
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Children has provided us with Personal Data,
            please contact us. If we become aware that we have collected
            Personal Data from children without verification of parental
            consent, we take steps to remove that information from our servers.
          </p>
          <p>CHANGES TO THIS PRIVACY POLICY</p>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            "effective date" at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <p>CONTACT US</p>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us:
          </p>
          <p>- By email: hi@noblehire.io</p>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
