// @flow
/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { JobAd } from '../../components/jobs/JobAd';
import { paths } from '../../core/constants';
import { useJob } from '../../components/jobs/JobContext';
import { SlidingPageShell } from './SlidingPageShell';
import { PageHead } from '../../components/basic/PageHead';
import { useApply } from '../../components/applications/ApplicationsContext';
import { useStateContainer } from '../../core/context/StateContainer';

export function JobAdPage({ onClose }: { onClose?: () => void }) {
  const { isApplyPopupVisible } = useApply();
  const history = useHistory();
  const loc = useLocation();
  const [
    {
      auth: { user, isAdmin, gettingUser, isCompany },
    },
  ] = useStateContainer();
  const { loading, job, jobNotFound } = useJob();
  const isLoading = gettingUser.loading || loading || !job;
  const companySlugFromJob = (job && job.company && job.company.slug) || '';

  if (!isLoading && !user && job && !job.isPublic) {
    return <Redirect to={paths.root} />;
  }

  if (!isLoading && user && job && !job.isPublic) {
    // job is not public
    const adminOrCompany =
      isAdmin(user) ||
      (isCompany(user) && user.companySlug === companySlugFromJob);

    if (!adminOrCompany) return <Redirect to={paths.root} />;
  }

  const internalOnClose = () => {
    history.push({ pathname: paths.root, search: loc.search });
  };

  // job is public, don't care about the status of the user.
  return (
    <SlidingPageShell
      loading={isLoading}
      onHideAnimDone={onClose || internalOnClose}
      closePredicate={() => !isApplyPopupVisible}
    >
      {({ setVisible }) => (
        <React.Fragment>
          <PageHead
            title={
              job ? `${job.company.brand || ''} - ${job.title}` : undefined
            }
            description={job ? job.description : undefined}
          />
          <JobAd
            job={job}
            loading={loading}
            jobNotFound={jobNotFound}
            onClose={() => {
              if (!loading) setVisible(false);
            }}
          />
        </React.Fragment>
      )}
    </SlidingPageShell>
  );
}
