// @flow
import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { paths } from '../../../core/constants';
import { useStateContainer } from '../../../core/context/StateContainer';
import { useVisitorTrails } from '../../tracking/visitor-trails/VisitorTrailsContext';

type Props = {
  path: string,
  component: React.ComponentType<any>,
  redirectTo?: ?() => React.Node,
  location?: { search: string },
};

const ProtectedRoute = ({
  path,
  component,
  redirectTo,
  location = { search: '' },
  ...routeProps
}: Props) => {
  const Component = component;
  const [
    {
      auth: { authenticated },
    },
  ] = useStateContainer();
  const { getTrail, visitorTrails } = useVisitorTrails();
  const hasAccountTrail = getTrail(visitorTrails.VISITOR_HAS_ACCOUNT);

  if (authenticated) {
    return (
      <Route
        path={path}
        {...routeProps}
        render={(props) => {
          return <Component {...props} {...routeProps} />;
        }}
      />
    );
  }

  const queryString = location.search;

  if (typeof redirectTo === 'function') {
    return redirectTo();
  }

  return (
    <Redirect
      to={{
        pathname: hasAccountTrail ? paths.login : paths.register,
        search: queryString,
      }}
    />
  );
};

ProtectedRoute.defaultProps = {
  location: { search: '' },
  redirectTo: null,
};

export { ProtectedRoute };
