// @flow
/* eslint-disable no-nested-ternary */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { paths, seniority, roles } from '../../../../core/constants';
import { Box } from '@material-ui/core';
import { Button } from '../../../basic/Button';
import { makeStyles, useTheme } from '@material-ui/styles';
import type { ServerLocation } from '../../common/context/CompanyContext';
import type { Job } from '../../../jobs/JobsContext';
import { LocationPicker } from '../../../jobs/JobAd';
import { useJobDuplicate } from '../../../jobs/JobDuplicateContext';
import { Confirm } from '../../../notifications/components/Confirm';
import { useStateContainer } from '../../../../core/context/StateContainer';
import { enqueueNotification } from '../../../notifications/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey['100']}`,
    borderRadius: theme.borderRadius,
    overflow: 'hidden',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    '&:hover $heading': {
      background: theme.palette.primary.lightest,
    },
  },
  heading: {
    background: theme.palette.grey['100'],
    borderRadius: theme.borderRadius,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '@media(max-width:1024px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  titleContainer: {
    maxWidth: 300,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize,
    margin: 0,
    padding: 0,
  },
  headingDetails: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media(max-width:1024px)': {
      marginTop: theme.spacing(2),
    },
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  controlLink: {
    textDecoration: 'none',
  },
  control: {
    marginRight: theme.spacing(4),
    fontWeight: '600',
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  jobProp: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[800],
    marginLeft: theme.spacing(2),
    width: 100,
  },
  remoteTag: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  duplicateButton: {
    padding: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}));

export function JobCard({ job }: { job: Job<ServerLocation> }) {
  const theme = useTheme();
  const classes = useStyles();
  const [, dispatch] = useStateContainer();
  const { duplicate } = useJobDuplicate();

  if (!job.slug || !job.company.slug) {
    return null;
  }

  const jobLink = paths.job.replace(
    ':slug+',
    `${job.company.slug || ''}/${job.slug || ''}`
  );

  const onDuplicate = () => {
    dispatch(
      enqueueNotification(
        <Confirm
          vertical="top"
          horizontal="center"
          disableAutoHide
          onClose={(choice) => {
            if (choice === 'OK') duplicate(job);
          }}
        >
          Are you sure you want to duplicate this job ad?
        </Confirm>
      )
    );
  };

  return (
    <Box mb={2} className={classes.root}>
      <Box p={2} className={classes.heading}>
        <div className={classes.titleContainer}>
          <Link to={jobLink} className={classes.controlLink}>
            <h2 className={classes.title}>{job.title}</h2>
          </Link>
        </div>
        <div className={classes.headingDetails}>
          {job.role ? (
            <span className={classes.jobProp}>
              {roles[job.role] || '&mdash;'}
            </span>
          ) : (
            <span className={classes.jobProp}>&mdash;</span>
          )}
          {job.fullyRemote === 'yes' ? (
            <span className={`${classes.jobProp} ${classes.remoteTag}`}>
              Remote
            </span>
          ) : job.location ? (
            <Box className={classes.jobProp}>
              <LocationPicker
                location={job.location}
                locations={job.company.locations}
                lock
                textColor={theme.palette.grey[800]}
                simpleAddress
              />
            </Box>
          ) : (
            <span className={classes.jobProp}>&mdash;</span>
          )}
          {job.seniority ? (
            <span className={classes.jobProp}>{seniority[job.seniority]}</span>
          ) : (
            <span className={classes.jobProp}>&mdash;</span>
          )}
          {job.salary && job.salary !== '0-0' ? (
            <span className={classes.jobProp}>
              {job.salary} {job.salaryCurrency}
            </span>
          ) : (
            <span className={classes.jobProp}>&mdash;</span>
          )}
        </div>
      </Box>
      <Box className={classes.controls} bgcolor="common.white" p={2}>
        <Link className={classes.controlLink} to={jobLink}>
          <div className={classes.control}>
            <FontAwesomeIcon
              icon={['fal', 'eye']}
              size="lg"
              className={classes.icon}
            />
            <span>View</span>
          </div>
        </Link>

        <Link
          className={classes.controlLink}
          to={{
            pathname: paths.jobEdit.replace(
              ':slug+',
              `${job.company.slug || ''}/${job.slug || ''}`
            ),
            search: 'edit=true',
          }}
        >
          <div className={classes.control}>
            <FontAwesomeIcon
              icon={['fal', 'pencil']}
              size="lg"
              className={classes.icon}
            />
            <span>Edit</span>
          </div>
        </Link>
        <Button
          variant="contained"
          color="default"
          type="button"
          className={`${classes.control} ${classes.duplicateButton}`}
          onClick={onDuplicate}
        >
          <FontAwesomeIcon
            icon={['fal', 'clone']}
            size="lg"
            className={classes.icon}
          />
          <span>Duplicate</span>
        </Button>

        {/* <div className={classes.control}>
          <FontAwesomeIcon
            icon={['fal', 'trash']}
            size="lg"
            className={classes.icon}
          />
          <span>Delete</span>
        </div> */}
      </Box>
    </Box>
  );
}
