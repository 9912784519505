// @flow
import { buildValidateFunc } from '../../../../../utils/buildValidateFunc';
import { validateConfig } from './validateConfig';
import { companyRegister, loginAttempts } from '../../../authService';
import { log } from '../../../../../utils/log';

function useRegistrationForm() {
  const validate = buildValidateFunc(
    validateConfig,
    (values, config) => config,
    0
  );

  function onFormSubmit(values: {
    brand: string,
    name: string,
    email: string,
    password: string,
    consentGdpr: string[],
  }) {
    const { brand, name, email, password, consentGdpr } = values;
    const consent = consentGdpr.includes('consentGdpr');

    return new Promise<mixed>((resolve, reject) => {
      companyRegister({
        brand,
        name,
        email,
        password,
        consent,
      })
        .then((registerResponse) => {
          if (registerResponse.status === 204) {
            // eslint-disable-next-line no-console
            loginAttempts({
              email,
              password,
              maxAttempts: 20,
            })
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                reject(error);
              });
            // resolve(true); // TODO: log the company user in.
          } else {
            reject(new Error('registration failed')); // TODO: show error dialog
            log('Company registration error occurred.', {
              logLevel: log.logLevels.warn,
            });
          }
        })
        .catch((error) => {
          // TODO: show error dialog
          log('Company registration error occurred.', {
            logLevel: log.logLevels.error,
            error,
          });
        });
    });
  }

  return {
    onFormSubmit,
    validate,
  };
}

export { useRegistrationForm };
