import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Form } from '../../../../basic/form/Form';
import { Checkbox } from '../../../../basic/form/Checkbox';
import { Input } from '../../../../basic/form/Input';
import { ConsentCheckboxLabel } from '../../../talents/components/RegistrationForm/ConsentCheckboxLabel';
import { Button } from '../../../../basic/Button';
import { useRegistrationForm } from './useRegistrationForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    width: '280px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: { textAlign: 'center' },
  footer: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  loginLink: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  row: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  checkboxWrapper: { margin: theme.spacing(2, 0) },
}));

export function RegistrationForm() {
  const { validate, onFormSubmit } = useRegistrationForm();
  const classes = useStyles();

  return (
    <div>
      <Form onSubmit={onFormSubmit} validate={validate}>
        {({ submitting }) => {
          return (
            <div className={classes.root}>
              <div className={classes.wrapper}>
                <Input
                  controlProps={{ className: classes.row }}
                  type="text"
                  name="brand"
                  id="brand"
                  autoComplete="brand"
                  label="Company name"
                  placeholder="Noble Hire"
                  required
                />

                <Input
                  controlProps={{ className: classes.row }}
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="name"
                  label="First & last name"
                  placeholder="John Doe"
                  required
                />

                <Input
                  controlProps={{ className: classes.row }}
                  type="email"
                  name="email"
                  id="email"
                  label="Email"
                  autoComplete="email"
                  placeholder="john@doe.com"
                  required
                />

                <Input
                  controlProps={{ className: classes.row }}
                  type="password"
                  name="password"
                  id="password"
                  label="Password"
                  autoComplete="password"
                  required
                />

                <Input
                  controlProps={{ className: classes.row }}
                  type="password"
                  name="repeatPassword"
                  id="repeatPassword"
                  label="Repeat password"
                  autoComplete="new-password"
                  required
                />

                <Checkbox
                  id="consentGdpr"
                  name="consentGdpr"
                  controlProps={{ className: classes.row }}
                  value="consentGdpr"
                  label={<ConsentCheckboxLabel />}
                />

                <div className={classes.footer}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!!submitting}
                  >
                    {submitting ? 'Just a sec...' : 'Create Account'}
                  </Button>
                </div>
              </div>
            </div>
          );
        }}
      </Form>
    </div>
  );
}
