// @flow
import type { State, Action } from '../../core/types';
import { isRoleTech } from '../../utils/isRoleTech';
import {
  SET_INITIALIZATION,
  HYDRATE_ONBOARDING_STATE_START,
  HYDRATE_ONBOARDING_STATE_END,
  START_ONBOARDING_SECTION,
  COMPLETE_SECTION,
  NEXT_STEP,
  PREV_STEP,
  SET_STEP,
  NEXT_SYNTHETIC_STEP,
  PREV_SYNTHETIC_STEP,
  SET_SYNTHETIC_STEP,
  SET_SYNTHETIC_STEPS_COUNT,
  SET_IS_TECH,
  SET_IS_LOOKING_FOR_TECH,
  SET_ONBOARDING_LOADING,
} from './actions';

function hydrateOnboardingStateStart(state) {
  return {
    ...state,
    hydration: { ...state.hydration, loading: true },
  };
}

function hydrateOnboardingStateEnd(state, action) {
  const { data } = action;
  const completedSections = [];
  let isTech;
  let isLookingForTech;

  if (data.basicInfo) {
    completedSections.push(1);
    isTech = isRoleTech(data.basicInfo.currentPosition);
    isLookingForTech = isRoleTech(data.basicInfo.jobSearchRoleType);
  }

  if (data.softSkills && data.techSkills) {
    completedSections.push(2);
  }

  if (data.culture) {
    completedSections.push(3);
  }

  return {
    ...state,
    isTech: isTech !== undefined ? isTech : state.isTech,
    isLookingForTech:
      isLookingForTech !== undefined
        ? isLookingForTech
        : state.isLookingForTech,
    hydration: { loading: false, data },
    completedSections,
  };
}

const onboarding = (state: State, action: Action) => {
  switch (action.type) {
    case SET_IS_TECH:
      return {
        ...state,
        isTech: isRoleTech(action.role),
      };
    case SET_IS_LOOKING_FOR_TECH:
      return {
        ...state,
        isLookingForTech: isRoleTech(action.role),
      };
    case SET_INITIALIZATION:
      return {
        ...state,
        initializationReady: action.data,
      };
    case HYDRATE_ONBOARDING_STATE_START:
      return hydrateOnboardingStateStart(state);
    case HYDRATE_ONBOARDING_STATE_END:
      return hydrateOnboardingStateEnd(state, action);
    case START_ONBOARDING_SECTION:
      return {
        ...state,
        currentStep: 0,
        currentSection: action.data,
      };
    case NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case PREV_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case SET_STEP:
      return {
        ...state,
        currentStep: action.data,
      };
    case COMPLETE_SECTION:
      return {
        ...state,
        currentStep: 0,
        completedSections: [
          ...new Set([...state.completedSections, action.section]),
        ],
      };
    case SET_ONBOARDING_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    case NEXT_SYNTHETIC_STEP:
      return {
        ...state,
        syntheticStep: state.syntheticStep + 1,
      };
    case PREV_SYNTHETIC_STEP:
      return {
        ...state,
        syntheticStep: state.syntheticStep - 1,
      };
    case SET_SYNTHETIC_STEP:
      return {
        ...state,
        syntheticStep: action.data,
      };
    case SET_SYNTHETIC_STEPS_COUNT:
      return {
        ...state,
        syntheticStepsCount: action.data,
      };
    default:
      return state;
  }
};

onboarding.initialState = {
  isTech: true,
  isLookingForTech: false,
  loading: false,
  initializationReady: false,
  hydration: {
    loading: true,
    data: {
      basicInfo: null,
      culture: null,
      softSkills: null,
      techSkills: null,
      jobAdApplications: null,
      onboardingComplete: null,
    },
  },
  currentStep: 0,
  syntheticStep: 0,
  syntheticStepsCount: 8,
  currentSection: 1,
  completedSections: [],
};

export { onboarding };
