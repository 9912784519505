// @flow
/* eslint-disable no-console */
import * as React from 'react';
import dayjs from 'dayjs';
import type { FormApi, FormValuesShape } from 'final-form/dist/types.js.flow';
import { useHistory } from 'react-router-dom';
import { buildValidateFunc } from '../../../../../utils/buildValidateFunc';
import type { ValidateFunc } from '../../../../../core/types';
import { validateConfig } from './validateConfig';
import { forgottenPasswordReset as forgottenPasswordResetRequest } from '../../../authService';
import { useStateContainer } from '../../../../../core/context/StateContainer';
import { Success } from '../../../../notifications/components/Success';
import { Error } from '../../../../notifications/components/Error';
import { enqueueNotification } from '../../../../notifications/actions';
import { paths } from '../../../../../core/constants';

type Params = {
  query: any,
};

function useForgottenPasswordResetForm({ query }: Params) {
  const history = useHistory();
  const [, dispatch] = useStateContainer();
  const validate: ValidateFunc = buildValidateFunc(
    validateConfig,
    (values, config) => config,
    0
  );

  const expirationTimestamp = query.get('expiration');
  const resetToken = query.get('resetToken');
  const userId = query.get('userId');

  const expiration = dayjs(Number(expirationTimestamp));
  const expirationPlus3Mins = expiration.add(3, 'minute');
  const linkExpired = expirationPlus3Mins.isBefore(dayjs());

  async function onFormSubmit(
    { password }: { password: string },
    form: FormApi<FormValuesShape>
  ) {
    return forgottenPasswordResetRequest({
      password,
      expiration: expirationTimestamp,
      userId,
      resetToken,
    })
      .then(() => {
        history.replace(paths.login);

        dispatch(
          enqueueNotification(
            <Success>
              We have successfully updated your password. Use it to sign in.
            </Success>
          )
        );

        // we use setTimeout here, because react-final-form could reset the form only
        // when the onSubmit has fully resolved.
        // That's why we put the reset at the end of the queue, using setTimeout.
        // @see: https://github.com/final-form/final-form/issues/142
        setTimeout(form.reset);
      })
      .catch((err) => {
        if (
          err.response.data.message ===
          'PASSWORD: Invalid or expired reset password token'
        ) {
          history.replace(paths.forgottenPassword);
          return dispatch(
            enqueueNotification(
              <Error>
                The reset link is no longer valid. Use the form above to get a
                new one.
              </Error>
            )
          );
        }

        return dispatch(
          enqueueNotification(
            <Error>An error has occured. Please, try again.</Error>
          )
        );
      });
  }

  return {
    onFormSubmit,
    validate,
    linkExpired,
  };
}

export { useForgottenPasswordResetForm };
