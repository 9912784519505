// @flow
import type {
  Image,
  ServerCompProp,
  ServerImage,
  ServerImageCollection,
  ServerLocation,
  ServerCompany,
} from './context/CompanyContext';
import type { Company } from './types';

export function convToServerImage(
  collection: ServerImageCollection
): (Image) => ServerImage {
  return function innerConv(image: Image): ServerImage {
    return {
      id: image.id,
      collection,
      name: image.src,
    };
  };
}

export function convToGenericCompProp(scp: ServerCompProp) {
  return {
    id: Math.random(),
    icon: scp.icon,
    title: scp.title,
    content: scp.text,
  };
}

export function convToLocation(l: ServerLocation) {
  return { ...l, address: JSON.parse(l.address) };
}

export function normServerImage(image: {
  id?: number | string,
  collection: ServerImageCollection, // eslint-disable-line
  name: string,
}) {
  return {
    id: image.id || image.name,
    src: `https://scontent.noblehire.io/${image.name}`,
    blob: null,
  };
}

export function convServerCompanyToCompany(
  company: ServerCompany
): Company<any> {
  return {
    id: company.id,
    brand: company.brand,
    slug: company.slug,
    isPublic: company.public,
    overview: company.overview,
    locations: company.locations.map(convToLocation),
    logos: company.images
      .filter((i) => i.collection === 'logo')
      .map(normServerImage),
    mainImages: company.images
      .filter((i) => i.collection === 'main')
      .map(normServerImage),
    productImages: company.images
      .filter((i) => i.collection === 'product')
      .map(normServerImage),
    photos: company.images
      .filter(
        (i) =>
          i.collection === 'photos' ||
          i.collection === 'culture' ||
          i.collection === 'office'
      )
      .map(normServerImage),
    clientImages: company.images
      .filter((i) => i.collection === 'clients')
      .map(normServerImage),
    teamLeadImages: company.images
      .filter((i) => i.collection === 'team-lead')
      .map(normServerImage),
    awards:
      company.awards && company.awards.length
        ? company.awards.map(convToGenericCompProp)
        : [],
    product: company.product,
    cultureAndPerks: company.perks.map(convToGenericCompProp),
    values: company.values.map(convToGenericCompProp),
  };
}

export const cls = (...clss: string[]) => `${clss.join(' ')}`;

export const txtColor = (theme: any, isEdit: boolean = true) =>
  isEdit ? theme.palette.common.black : theme.palette.common.black;
