// @flow
import React from 'react';
import { paths } from '../core/constants';
import { LoginForm } from '../components/auth/talents/components/LoginForm';
import { AuthFrame } from '../components/auth/talents/components/AuthFrame';
import { DisableScrollOnMount } from '../components/basic/DisableScrollOnMount';
import { HideTopArrow } from '../components/wordpress/HideTopArrow';
import { PageHead } from '../components/basic/PageHead';

function Login() {
  return (
    <DisableScrollOnMount>
      <HideTopArrow>
        <AuthFrame>
          <PageHead title="Login | NobleHire" />
          <LoginForm
            title="Login"
            buttonLink={paths.register}
            buttonText="Register"
          />
        </AuthFrame>
      </HideTopArrow>
    </DisableScrollOnMount>
  );
}

export { Login };
