// @flow
import React, { useRef, useState } from 'react';
import { useTheme, Box, TextareaAutosize } from '@material-ui/core';
import { txtColor, useStyles } from '../utils';
import { Button } from '../../basic/Button';

export function HiringProcessForm({
  value,
  onCancel,
  onSubmit,
  submitButtonLabel = 'Add',
  ...rest
}: {
  value?: string,
  onCancel: () => void,
  onSubmit: (value: string) => void,
  submitButtonLabel?: string,
} & any) {
  const c = useStyles();
  const formRef = useRef(null);
  const theme = useTheme();
  const [val, setVal] = useState(value || '');

  const submitForm = (e: any) => {
    e.preventDefault();
    if (!val.length) return;
    if (typeof onSubmit === 'function') {
      onSubmit(val);
    }
    setVal('');
  };
  const onChange = ({ target }) => setVal(target.value);
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit'));
      e.preventDefault();
    }
  };

  return (
    <form onSubmit={submitForm} ref={formRef} {...rest}>
      <Box>
        <TextareaAutosize
          style={{ width: '100%', color: txtColor(theme, true) }}
          className={c.textarea}
          rowsMin={2}
          name="newVal"
          value={val}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          type="button"
          variant="text"
          color="default"
          disableElevation
          onClick={onCancel}
          size="small"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="edit"
          size="small"
          disableElevation
        >
          {submitButtonLabel}
        </Button>
      </Box>
    </form>
  );
}
