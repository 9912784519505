// @flow
import * as React from 'react';

const ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION';
const DEQUEUE_NOTIFICATION = 'DEQUEUE_NOTIFICATION';

function enqueueNotification(data: React.Node) {
  return { type: ENQUEUE_NOTIFICATION, data };
}

function dequeueNotification() {
  return { type: DEQUEUE_NOTIFICATION };
}

export {
  ENQUEUE_NOTIFICATION,
  DEQUEUE_NOTIFICATION,
  enqueueNotification,
  dequeueNotification,
};
