// @flow
import { useMediaQuery } from '@material-ui/core';

const MEDIA_QUERIES = {
  sm: '(min-width: 420px)',
  md: '(min-width: 480px)',
  lg: '(min-width: 768px)',
  xl: '(min-width: 960px)',
  xxl: '(min-width: 1200px)',
};
export const useMQ = () => {
  const sm = useMediaQuery<boolean>(MEDIA_QUERIES.sm);
  const md = useMediaQuery<boolean>(MEDIA_QUERIES.md);
  const lg = useMediaQuery<boolean>(MEDIA_QUERIES.lg);
  const xl = useMediaQuery<boolean>(MEDIA_QUERIES.xl);
  const xxl = useMediaQuery<boolean>(MEDIA_QUERIES.xxl);

  return {
    sm,
    md,
    lg,
    xl,
    xxl,
  };
};

const MEDIA_QUERIES_DESKTOP_FIRST = {
  sm: '(max-width: 420px)',
  md: '(max-width: 480px)',
  lg: '(max-width: 768px)',
  xl: '(max-width: 960px)',
  xxl: '(max-width: 1200px)',
  '840': '(max-width: 840px)',
};
export const useMQDesktopFirst = () => {
  const sm = useMediaQuery<boolean>(MEDIA_QUERIES_DESKTOP_FIRST.sm);
  const md = useMediaQuery<boolean>(MEDIA_QUERIES_DESKTOP_FIRST.md);
  const lg = useMediaQuery<boolean>(MEDIA_QUERIES_DESKTOP_FIRST.lg);
  const xl = useMediaQuery<boolean>(MEDIA_QUERIES_DESKTOP_FIRST.xl);
  const xxl = useMediaQuery<boolean>(MEDIA_QUERIES_DESKTOP_FIRST.xxl);
  const is840 = useMediaQuery<boolean>(MEDIA_QUERIES_DESKTOP_FIRST[840]);

  return {
    sm,
    md,
    lg,
    xl,
    xxl,
    is840,
  };
};
