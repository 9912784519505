// @flow
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStateContainer } from '../../../../../core/context/StateContainer';
import { Success } from '../../../../notifications/components/Success';
import { enqueueNotification } from '../../../../notifications/actions';
import { register, loginAttempts } from '../../../authService';
import { useTracking } from '../../../../tracking/TrackingContext';
import { useReferrals } from '../../../../referrals/ReferralsContext';
import { useReferralsBox } from '../../../../menu/components/SideMenu/boxes/ReferralsBox/userReferralsBox';
import { useVisitorTrails } from '../../../../tracking/visitor-trails/VisitorTrailsContext';
import {
  queryHintResolver,
  QUERY_HINT_RESOLUTION,
} from '../../../../../utils/urlHintResolver';

export function useRegisterPopup() {
  const query = useLocation().search;
  const { track, events } = useTracking();
  const [submitError, setSubmitError] = useState<any>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [
    {
      referrals: { pending },
    },
    dispatch,
  ] = useStateContainer();
  const { visitorTrails, getTrail } = useVisitorTrails();
  const { getStoredReferralLinks, clearStoredReferralLinks } = useReferrals();
  const { onGetReferralLinkClick } = useReferralsBox();

  function handleLoginResponse(response, resolve) {
    const queryResolution = queryHintResolver(query);
    let isBlank = true;

    if (queryResolution === QUERY_HINT_RESOLUTION.DIRECT_APPLICATION) {
      isBlank = false;
    }

    if (pending.getReferralLink) {
      onGetReferralLinkClick();
    }

    track(events.CREATE_ACCOUNT, { isBlank });
    track(events.AUTO_LOGIN);
    setSubmitLoading(false);
    dispatch(enqueueNotification(<Success>Welcome onboard!</Success>));
    resolve();
  }

  function handleError(error, reject) {
    setSubmitLoading(false);
    let errorMessage =
      (error.response && error.response.data && error.response.data.message) ||
      'Unknown login error!';
    errorMessage = errorMessage.startsWith('No message available')
      ? 'An unexpected error ocurred. Please, try again in a few moments.'
      : errorMessage;

    setSubmitError(errorMessage);

    reject();
  }

  const onSubmit = (values: {
    email: string,
    password: string,
    consent: boolean,
  }) => {
    setSubmitError(null);
    setSubmitLoading(true);

    const storedReferralLinks = getStoredReferralLinks();
    const { email, password, consent } = values;

    return new Promise<mixed>((resolve, reject) => {
      register({
        email,
        password,
        referralLinks: storedReferralLinks,
        landingPage: getTrail(visitorTrails.VISITOR_FIRST_LANDING_PAGE, null),
        referringPage: getTrail(visitorTrails.VISITOR_COMING_FROM, null),
        consent,
      })
        .then((registerResponse) => {
          if (registerResponse.status === 204) {
            loginAttempts({
              email,
              password,
              maxAttempts: 20,
            })
              .then((r) => handleLoginResponse(r, resolve))
              .catch((error) => {
                reject(error);
              })
              .finally(clearStoredReferralLinks);
          }
        })
        .catch((error) => handleError(error, reject));
    });
  };

  return {
    onSubmit,
    submitError,
    submitLoading,
  };
}
