// @flow
import React from 'react';
import { seniority, roles } from '../../core/constants';
import { makeStyles } from '@material-ui/core';
import type { FiltersState } from './JobBoard';
import type { FilterType } from '../../utils/searchUtils';
import { Filter } from './Filter';

const useStyles = makeStyles((theme) => ({
  genericFilterBtn: {
    background: theme.palette.grey[200],
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  filtersIcon: {
    marginRight: theme.spacing(2),
  },

  filtersContainer: {
    display: 'flex',
    alignItems: 'center',

    '@media(max-width: 840px)': {
      flexDirection: 'column',
    },
  },
  filters: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',

    '@media(max-width: 840px)': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: theme.spacing(2),
    },
  },
  col: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    '@media(max-width: 840px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > div:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
  },
  filtersBtn: {
    position: 'relative',
  },
  filtersBtnActive: {
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: `0 4px 25px -2px ${theme.palette.primary.light}`,
  },
}));
export function Filters({
  filters,
  onFilterChange,
}: {
  filters: FiltersState,
  onFilterChange: (FilterType, string) => void,
}) {
  const classes = useStyles();

  const onChange = (e: SyntheticInputEvent<HTMLSelectElement>) => {
    const { name } = e.currentTarget;
    const { value } = e.currentTarget;
    onFilterChange((name: any), value);
  };

  return (
    <div className={classes.filtersContainer}>
      <div className={classes.filters}>
        <div className={classes.col}>
          <Filter value={filters.role} name="role" onChange={onChange}>
            <option value="">Role</option>
            {Object.keys(roles).map((roleKey) => {
              return (
                <option key={roleKey} value={roleKey}>
                  {roles[roleKey]}
                </option>
              );
            })}
          </Filter>

          <Filter
            value={filters.seniority}
            name="seniority"
            onChange={onChange}
          >
            <option value="">Seniority</option>
            {Object.keys(seniority).map((seniorityKey) => {
              return (
                <option key={seniorityKey} value={seniorityKey}>
                  {seniority[seniorityKey]}
                </option>
              );
            })}
          </Filter>
        </div>

        <div className={classes.col}>
          <Filter value={filters.location} name="location" onChange={onChange}>
            <option value="">Location</option>
            <option value="remote">Remote</option>
            <option value="sofia">Sofia</option>
            <option value="plovdiv">Plovdiv</option>
            <option value="varna">Varna</option>
            <option value="burgas">Burgas</option>
            <option value="veliko-tarnovo">Veliko Tarnovo</option>
            <option value="ruse">Ruse</option>
          </Filter>

          {/* <Filter
            value={filters.salaryMax}
            name="salaryMax"
            onChange={onChange}
          >
            <option value="">Salary</option>
            <option value="1">With Salary Range Only</option>
          </Filter> */}
        </div>
      </div>
    </div>
  );
}
