// @flow
import { useEffect } from 'react';

function JobAdPageManipulations() {
  function changeReferralBoxText() {
    const url: string = window.location.href;

    if (url.includes('/job-ads/')) {
      // change the referrals texts
      const textToBeReplaced =
        'Refer a person you know that could be a good fit for this job. If you cannot think of anyone specific share this job to your network.';
      const newText =
        'Refer friends and make 2,000 – 8,000 BGN every time they get hired through Noble Hire, even for a different position. Refer someone specific by email or share a link with your whole network.';
      const selector = '.wpb_wrapper span > p';
      const elements = document.querySelectorAll(selector);
      for (let i = 0; i < elements.length; i += 1) {
        if (elements[i].textContent === textToBeReplaced) {
          elements[i].innerText = newText;
          break;
        }
      }
    }
  }

  useEffect(changeReferralBoxText, []);
  return null;
}

export { JobAdPageManipulations };
