// @flow
// import type { Job } from './JobsContext';

import API from '../../core/rest/API';
import type { ServerJob } from './JobsContext';

export function getJobs(
  search?: string,
  options?: { page?: number }
): Promise<{
  elements: any[],
  page: number,
  totalElements: number,
  totalPages: number,
}> {
  let s = search || '?';
  if (options && options.page !== undefined) {
    s += `&page=${options.page}`;
  }
  return API.get(`/job${s}`);
}

export function getJob(slug: string) {
  return API.get(`/job/${slug}`);
}

export function getJobsForCompany(slug: string): Promise<ServerJob[]> {
  return API.get(`/job/${slug}`);
}

export function saveJob(data: any): Promise<boolean> {
  return API.put('/job/my', data);
}

export function saveJobAdmin(data: any): Promise<boolean> {
  return API.put('/job/admin', data);
}

export function updateJob(data: any): Promise<boolean> {
  return API.post('/job/my', data);
}

export function updateJobAdmin(data: any): Promise<boolean> {
  return API.post('/job/admin', data);
}
