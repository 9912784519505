// @flow
import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import { Modal } from '../../basic/Modal';
import { dequeueModal } from '../../modals/actions';
import { useStateContainer } from '../../../core/context/StateContainer';
import { ComposeDirectReferralForm } from '../components/ComposeDirectReferralForm/ComposeDirectReferralForm';
import { enqueueNotification } from '../../notifications/actions';
import { Confirm } from '../../notifications/components/Confirm';
import { Success } from '../../notifications/components/Success';
import { InfoBox } from './components/InfoBox';
import { useTracking } from '../../tracking/TrackingContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 700,
    textAlign: 'left',
    maxHeight: '95vh',
    overflow: 'auto',

    '@media (max-width: 768px)': {
      minWidth: 'auto',
      alignItems: 'flex-start',
    },
  },
  main: {
    width: '100%',
  },
  title: {
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4),
    color: theme.palette.grey.dark,
  },
}));

function ComposeDirectReferral() {
  const classes = useStyles();
  const { track, events } = useTracking();
  const [
    {
      notifications: { queue },
      referrals: { loading },
    },
    dispatch,
  ] = useStateContainer();

  const close = useCallback(() => {
    if (loading.composeDirectReferral) {
      return; // no no, cannot close the modal
    }

    dispatch(dequeueModal());
  }, [loading.composeDirectReferral, dispatch]);

  const closeWithConfirm = useCallback(() => {
    const confirmationIsPending = queue.length;
    if (confirmationIsPending || loading.composeDirectReferral) {
      return;
    }

    dispatch(
      enqueueNotification(
        <Confirm
          disableAutoHide
          vertical="top"
          horizontal="center"
          onClose={(choice: $Values<typeof Confirm.CHOICE>) => {
            if (choice === Confirm.CHOICE.OK) {
              dispatch(dequeueModal());
            }
          }}
        >
          All the changes that you made will be lost. Close anyway?
        </Confirm>
      )
    );
  }, [dispatch, loading.composeDirectReferral, queue.length]);

  const onSuccess = useCallback(() => {
    close();
    const url = window.location.href;
    track(events.SUCCESSFUL_DIRECT_INVITE_EMAIL_SEND, { url });
    dispatch(enqueueNotification(<Success>Email sent successfully</Success>));
  }, [close, dispatch, events.SUCCESSFUL_DIRECT_INVITE_EMAIL_SEND, track]);

  const onError = useCallback(
    (err: {
      response: { data: { message: string } },
    }): {
      [key: string]: string,
    } => {
      if (
        err.response.data.message.startsWith('Username') &&
        err.response.data.message.endsWith('is taken')
      ) {
        return {
          to: 'The email is already referred.',
        };
      }

      return {
        [FORM_ERROR]: 'Something went wrong, please try again later.',
      };
    },
    []
  );

  return (
    <Modal
      open
      onClose={closeWithConfirm}
      titleId="get_referral_link_modal"
      contentId="get_referral_link_modal"
      paperClassName={classes.paper}
    >
      <div className={classes.title}>Send personal invite</div>
      <InfoBox
        title="Send personal invite to a friend to join Noble Hire and make 3,000-8,000 BGN every time they get hired"
        subtitle="Let Noble Hire send personal invite on your behalf."
      />
      <div className={classes.main}>
        <ComposeDirectReferralForm
          onSuccess={onSuccess}
          onError={onError}
          disabled={loading.composeDirectReferral}
        />
      </div>
    </Modal>
  );
}

export { ComposeDirectReferral };
