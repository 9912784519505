// @flow
import type { SliderConfig } from '../../../core/types';

type CreateSliderGroupSliders = (
  items: string[],
  slidersConfig: { [key: string]: string }
) => Array<SliderConfig>;

const createSliderGroupSliders: CreateSliderGroupSliders = (
  items = [],
  slidersConfigs
) => {
  return items.reduce((acc, item) => {
    return [...acc, { name: item, label: slidersConfigs[item] || item }];
  }, []);
};

export { createSliderGroupSliders };
