// @flow
/* eslint-disable no-console */
import { obj } from './obj';
import type {
  Validator,
  ValidateFunc,
  FormErrors,
  ValidateFuncBuilderConfig,
  ValidationConfigMutator,
  StepSetterFunc,
} from '../core/types';

function buildValidateFunc(
  config: ValidateFuncBuilderConfig,
  validationConfigMutator: ValidationConfigMutator,
  step: number
): ValidateFunc {
  return function validateFunc(values: any): FormErrors {
    // If, for some reason, we need to mutate the validation config object,
    // we can hook and do it, using a [validationConfigMutator] func.
    const mutatedConfig =
      typeof validationConfigMutator === 'function'
        ? validationConfigMutator(values, config, step)
        : config;

    return obj.reduceKeys<$Keys<typeof config>, any, Object>(
      mutatedConfig,
      (acc, field) => {
        // We reverse the array of validators, in order to
        // preserve the sequence of validators execution,
        // depending on the order they're defined in the
        // field's array of validators
        // (e.g. errors from the first validator
        // overrides the error from the last.)

        // Given the array of validators:
        //    [validateRequired(...), validateEmail(...)]
        //
        // the `validateRequired(...)` error
        // will override the `validateEmail(...)` error.
        const validators: Validator[] = [...mutatedConfig[field]].reverse();
        const execValidatorAndFillErrors = (validator) => {
          const [isValid, errorMessage] = Array.from(validator(values));

          if (!isValid) {
            acc[field] = errorMessage;
          }
        };

        validators.forEach(execValidatorAndFillErrors);

        return acc;
      },
      {}
    );
  };
}

function checkStepConfiguration(stepConfiguration: ValidateFuncBuilderConfig) {
  if (!stepConfiguration) {
    throw new Error('No step validation configuration provided');
  }
}

type StepConfig = {
  [step: string]: ValidateFuncBuilderConfig,
};

type FuncConfig = (...any) => StepConfig;

type BuildStepsValidateFuncConfig = StepConfig | FuncConfig;

function buildStepsValidateFunc(
  config: BuildStepsValidateFuncConfig,
  validationConfigMutator: ValidationConfigMutator
): StepSetterFunc {
  return function setStep(step: number): ValidateFunc {
    const stepConfiguration = config[step.toString()];
    checkStepConfiguration(stepConfiguration);

    return buildValidateFunc(
      config[step.toString()],
      validationConfigMutator,
      step
    );
  };
}

export { buildStepsValidateFunc, buildValidateFunc };
