// @flow
import * as React from 'react';
import { Snackbar, useTheme } from '@material-ui/core';
import { InfoSnackbarContent } from '../../basic/StatusSnackbar';
import { useStateContainer } from '../../../core/context/StateContainer';
import { DEQUEUE_NOTIFICATION } from '../actions';
import { Button } from '../../basic/Button';

const { useEffect } = React;
const AUTOHIDE_TIMEOUT = 3000;

type Props = {
  children: React.Node,
  vertical?: 'top' | 'bottom',
  horizontal?: 'left' | 'center' | 'right',
  disableAutoHide?: boolean,
  onClose?: (choice: 'OK' | 'CANCEL') => void,
};

function Confirm({
  children,
  vertical,
  horizontal,
  disableAutoHide,
  onClose = () => {},
  ...rest
}: Props) {
  const theme = useTheme();
  const [, dispatch] = useStateContainer();

  useEffect(() => {
    if (disableAutoHide) {
      return;
    }

    const to = setTimeout(() => {
      dispatch({ type: DEQUEUE_NOTIFICATION });
    }, AUTOHIDE_TIMEOUT);

    return clearTimeout.bind(undefined, to); //eslint-disable-line
  }, [disableAutoHide, dispatch]);

  function handleClose(choice: $Values<typeof Confirm.CHOICE>) {
    dispatch({ type: DEQUEUE_NOTIFICATION });
    onClose(choice);
  }

  return (
    <Snackbar open anchorOrigin={{ vertical, horizontal }} {...rest}>
      <InfoSnackbarContent
        message={children}
        action={[
          <Button
            type="button"
            variant="text"
            key="cancel"
            color="white"
            size="small"
            onClick={() => handleClose(Confirm.CHOICE.CANCEL)}
            style={{ marginRight: theme.spacing(1) }}
          >
            Cancel
          </Button>,
          <Button
            type="button"
            variant="contained"
            key="ok"
            color="white"
            size="small"
            onClick={() => handleClose(Confirm.CHOICE.OK)}
          >
            OK
          </Button>,
        ]}
        onClose={() => handleClose(Confirm.CHOICE.CANCEL)}
      />
    </Snackbar>
  );
}

Confirm.CHOICE = {
  OK: 'OK',
  CANCEL: 'CANCEL',
};

Confirm.defaultProps = {
  vertical: 'bottom',
  horizontal: 'right',
  onClose: () => {},
  disableAutoHide: false,
};

export { Confirm };
