// @flow
import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { paths } from '../core/constants';
import { FullPageLoader } from '../components/basic/FullPageLoader';
import API from '../core/rest/API';
import { useStateContainer } from '../core/context/StateContainer';
import { SET_AUTH_TOKEN } from '../components/auth/talents/actions';
import { useAuth } from '../components/auth/talents/AuthContext';
import { enqueueNotification } from '../components/notifications/actions';
import { Success } from '../components/notifications/components/Success';

function ExistingCompanyLanding() {
  const { loadUser } = useAuth();
  const [
    {
      auth: { authenticated, user },
    },
    dispatch,
  ] = useStateContainer();
  const { token } = useParams();

  useEffect(() => {
    // eslint-disable-line
    API.updateAuthenticationToken(token);
    dispatch({ type: SET_AUTH_TOKEN, authToken: token });
    loadUser();
    dispatch(
      enqueueNotification(
        <Success>
          <span>Welcome aboard</span>
          <span role="img" aria-label="Party icon" style={{ marginLeft: 5 }}>
            🥳
          </span>
        </Success>
      )
    );
  }, [dispatch, loadUser, token]);

  return authenticated && user ? (
    <Redirect
      to={paths.companiesDashboard.replace(':slug', user.companySlug)}
    />
  ) : (
    <FullPageLoader>Setup your starship...</FullPageLoader>
  );
}

export { ExistingCompanyLanding };
