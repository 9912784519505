// @flow

import API from '../../core/rest/API';
import type { FeedEvent } from './FeedEventsContext';

export function getFeedEvents(options?: { page?: number }): Promise<{
  elements: FeedEvent[],
  page: number,
  totalElements: number,
  totalPages: number,
}> {
  let s = '';
  if (options && options.page !== undefined) {
    s += `?page=${options.page}`;
  }
  return API.get(`/feed${s}`);
}
