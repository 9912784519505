// @flow
import * as React from 'react';
import { Header } from './Header';
import { ButtonsPreview } from './previews/ButtonsPreview';
import { SliderPreview } from './previews/SliderPreview';
import { SliderGroupPreview } from './previews/SliderGroupPreview';

type Props = {
  testId?: string,
};

function ComponentsPreview({ testId = 'components-preview' }: Props) {
  return (
    <div data-testid={testId}>
      <Header title="NobleHire Components" />

      <ButtonsPreview />

      <SliderPreview />

      <SliderGroupPreview />
    </div>
  );
}

ComponentsPreview.defaultProps = {
  testId: undefined,
};

export { ComponentsPreview };
