// @flow
import React from 'react';
import { PageHead } from '../components/basic/PageHead';
import { Container, makeStyles } from '@material-ui/core';
import { Footer } from '../components/Footer';

const useStyles = makeStyles((theme) => {
  return {
    innerContainer: {
      padding: theme.spacing(4, 0),
    },
    table: {
      maxWidth: '100%',
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: theme.spacing(1),
      borderCollapse: `collapse`,
      width: '100%',
      margin: theme.spacing(3, 0),

      '& td, & th': {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        wordBreak: 'break-all',
      },

      '& th': {
        textAlign: 'left',
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  };
});

export function CookiesPolicy() {
  const classes = useStyles();

  return (
    <div>
      <PageHead title="Cookie Policy" />
      <Container>
        <div className={classes.innerContainer}>
          <h1>Cookie Policy</h1>
          <p>Effective since: 03/01/2019</p>

          <br />
          <p>
            Noble is committed to protecting and respecting your privacy. We
            want to be transparent about the technologies we use, and we’ve
            outlined below how we use cookies when you visit our website
            https://old.noblehire.io/. As the information collected using
            cookies constitutes personal data, the provisions of our Privacy
            Policy shall apply and supplement this Cookie Policy.
          </p>

          <h2>WHAT IS A COOKIE?</h2>

          <p>
            A cookie is a small snippet of text that a website asks your browser
            to store. All cookies have expiration dates in them that determine
            how long they stay in your browser. Cookies can be removed in two
            ways: automatically, when they expire, or when you manually delete
            them. We’ve included more details below to help you understand what
            kinds of cookies we use.
          </p>

          <h2>WHAT KINDS OF COOKIES DOES NOBLE USE AND WHY?</h2>

          <p>
            When you visit the website, we may automatically collect additional
            information about you, such as the type of phone or operating system
            you use and, if you came to the website, the type of Internet
            browser you use and the website from which you have come to the
            site. You cannot be identified solely from this information and it
            is only used to assist us in providing an effective service on the
            site and to collect broad demographic information for aggregate use.
          </p>

          <p>
            The website also uses cookies to store certain information about the
            website usage which we share with your agreement with website
            analytics providers (such as Google Analytics) to help us understand
            website traffic and usage. We use cookies with the primary purpose
            to make our website work more effectively and to memorize various
            information in order to facilitate your navigation on a website/a
            mobile application, to ensure the smooth functioning of these or to
            make them more effective.
          </p>

          <p>We use different kinds of cookies for various reasons:</p>

          <p>
            <strong>Analytics cookies (statistics)</strong> – Statistic cookies
            help website owners to understand how visitors interact with
            websites by collecting and reporting information anonymously
          </p>

          <CookieTable
            name="_ga"
            expiry="2 years"
            text="First found URL:
            https://old.noblehire.io/ Cookie purpose description: Registers a
            unique ID that is used to generate statistical data on how the
            visitor uses the website. Initiator: Page source line number 118-124
            Source: In line script Data is sent to: Bulgaria (adequate)"
          />

          <CookieTable
            name="_gat"
            expiry="1 day"
            text="First found URL: https://old.noblehire.io/ Cookie purpose description: Used by Google Analytics to throttle request rate Initiator: Page source line number 118-124 Source: In line script Data is sent to: Bulgaria (adequate)"
          />

          <CookieTable
            name="_gid"
            expiry="1 day"
            text="First found URL: https://old.noblehire.io/ Cookie purpose description: Registers a unique ID that is used to generate statistical data on how the visitor uses the website. Initiator: Page source line number 118-124 Source: In line script Data is sent to: Bulgaria (adequate)"
          />

          <CookieTable
            name="collect"
            provider="google-analyrics.com"
            type="Pixel"
            expiry="Session"
            text="First found URL: https://old.noblehire.io/ Cookie purpose description: Used to send data to Google Analytics about visitor’s devices and behavior. Tracks the visitor across devices and marketing channels. Initiator: Page source line number 118-124 Source: https://www.google-analytics.com/r/collect?v=1_v=j72a=39367122t=pageview_s=1dl=https%3A%2F%2Fold.noblehire.io%2Ful=en-usde=UTF-8dt=NOBLE%20%7C%20Unlocking%20the%20Power%20of%20Referralssd=24-bitsr=1024x768vp=999x740je=0_u=IEBAAUAB~jid=678526273gjid=1592616044cid=465275319.1545233363tid=UA-130112400-1_gid=2012811372.1545233363_r=1gtm=2oubc0z=1897153645 Data is sent to: United States (adequate)
            "
          />

          <p>
            <strong>Marketing cookies</strong> – Marketing cookies are used to
            track visitors across websites. The intention is to display ads that
            are relevant and engaging for the individual user and thereby more
            valuable for publishers and third party advertisers.
          </p>

          <CookieTable
            name="_fbp"
            expiry="1 day"
            text="First found URL: https://old.noblehire.io/ Cookie purpose description: Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers Initiator: Script tag, page source line number 135 Source: https://connect.facebook.net/en _US/fbevents.js Data is sent to: United States (adequate)"
          />

          <CookieTable
            name="fr"
            provider="facebook.com"
            expiry="3 months"
            text="First found URL: https://old.noblehire.io/ Cookie purpose description: Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers Initiator: Script tag, page source line number 135 Source: https://connect.facebook.net/en _US/fbevents.js Data is sent to: United States (adequate)"
          />

          <CookieTable
            name="tr"
            provider="facebook.com"
            type="Pixel"
            expiry="Session"
            text="First found URL: https://old.noblehire.io/ Cookie purpose description: Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers Initiator: Script tag, page source line number 135 Source: https://www.facebook.com/tr/?id=315749489266056ev=PageViewdl=https%3A%2F%2Fold.noblehire.io%2Frl=https%3A%2F%2Fold.noblehire.io%2Fif=falsets=1545233376457sw=1024sh=768v=2.8.35r=stableec=0o=286fbp=fb.1.1545233376456.975681039it=1545233376145coo=falsevia https://connect.facebook.net/en _US/fbevents.js Data is sent to: United States (adequate)"
          />

          <p>
            The collection and use of information by these third parties is
            governed by their relevant privacy policies and is not covered by
            our privacy or cookies policy.
          </p>

          <h2>HOW CAN I CONFIGURE, DELETE OR DISABLE COOKIES?</h2>

          <p>
            You can easily delete, disable or accept cookies from our Website at
            any time by configuring your browser settings. Each browser
            (Internet Explorer, Safari, Firefox, Google Chrome, etc.) has its
            own cookie configuration mode. To learn about the procedure to
            follow concerning your navigator, visit the site:
            http://www.allaboutcookies.org/manage-cookies/
          </p>

          <h2>WILL THE WEBSITE WORK IF I DISABLE COOKIES?</h2>

          <p>
            You can browse Noble with cookies disabled, though some interactions
            may not work. For example, ticking the “Stay logged in” box at login
            will not actually keep you logged in to the site unless you have
            enabled cookies.
          </p>

          <h2>OTHER QUESTIONS?</h2>

          <p>
            If you have other questions, contact us: by sending an email to the
            following address: hi@noblehire.io
          </p>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

function CookieTable({
  name,
  expiry,
  type = 'HTTP',
  provider = 'noblehire.io',
  text,
}: {
  name: string,
  expiry: string,
  type?: string,
  provider?: string,
  text?: string,
}) {
  const classes = useStyles();
  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Provider</th>
          <th>Type</th>
          <th>Expiry</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{name}</td>
          <td>{provider}</td>
          <td>{type}</td>
          <td>{expiry}</td>
        </tr>
        {text ? (
          <tr>
            <td colSpan={4}>{text}</td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
}
