// @flow
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { TechnicalSkillsRating } from '../../components/steps/TechnicalSkillsRating';
import { SoftSkillsRating } from '../../components/steps/SoftSkillsRating';
import { createSliderGroupSliders } from '../../utils/createSliderGroupSliders';
import { slidersConfig } from '../../slidersConfig';
import { saveSoftSkills, saveTechSkills } from '../../onboardingFormsService';
import { useStateContainer } from '../../../../core/context/StateContainer';
import { dispatchSaveError } from '../../../basic/form/utils/dispatchSaveError';
import {
  nextStep,
  nextSyntheticStep,
  completeSection,
  setOnboardingLoading,
  startOnboardingSection,
} from '../../actions';
import { buildStepsValidateFunc } from '../../../../utils/buildValidateFunc';
import { buildSkillsInitialValues } from '../../utils/initialValueBuilders';
import {
  createTechSkillsRequestObject,
  createSoftSkillsRequestObject,
} from '../../utils/requestObjectsBuilders';
import { skills, skillsNonTech } from '../../validateConfig';
import { BackButton, SubmitButton } from '../../../basic/form/Buttons';
import { useTracking } from '../../../tracking/TrackingContext';
import { obj } from '../../../../utils/obj';
import { useGoToPrevSectionOnStep } from '../../utils/useGoToPrevSectionOnStep';

const useSkillsForm = () => {
  const { track, events } = useTracking();
  const query = useLocation().search;
  const [
    {
      onboarding: {
        currentStep,
        isTech,
        isLookingForTech,
        hydration: {
          data: { softSkills, techSkills },
        },
      },
    },
    dispatch,
  ] = useStateContainer();
  const goToPrevSectionOnStep = useGoToPrevSectionOnStep();

  const initialValues = buildSkillsInitialValues(softSkills, techSkills);

  // Include technical questions only if:
  // - A user is a tech person and is looking for another technical job
  // - A user is a NON-tech person, but is looking for a new technical job
  // - A user is a tech person, but is looking for a non-tech job
  // - A user is a non-tech person and is looking for a non-tech job
  const includesTechnicalQuestions = isTech || isLookingForTech;
  // (isTech && isLookingForTech) || (!isTech && isLookingForTech);

  function validationConfigMutator(values, config, step) {
    return includesTechnicalQuestions ? config : skillsNonTech[step.toString()];
  }

  const validateStep = buildStepsValidateFunc(skills, validationConfigMutator);

  const buttonsWithBasicBack = (
    <>
      <BackButton />
      <SubmitButton completesSection={2}>Next</SubmitButton>
    </>
  );

  const buttonsWithBackToPrevSection = (
    <>
      <BackButton onClick={() => goToPrevSectionOnStep(2)} />
      <SubmitButton>Next</SubmitButton>
    </>
  );

  const getSteps = () => {
    let steps = [
      <SoftSkillsRating
        sliders={createSliderGroupSliders(
          obj.keys(slidersConfig.softSkills),
          slidersConfig.softSkills
        )}
        buttons={
          isLookingForTech || isTech
            ? buttonsWithBasicBack
            : buttonsWithBackToPrevSection
        }
      />,
    ];

    if (includesTechnicalQuestions) {
      steps = [
        <TechnicalSkillsRating
          sliders={createSliderGroupSliders(
            obj.keys(slidersConfig.technicalSkills),
            slidersConfig.technicalSkills
          )}
          buttons={buttonsWithBackToPrevSection}
        />,
        ...steps,
      ];
    }

    return steps;
  };

  async function onFormSubmit(values: { [string]: any }) {
    const isBlank = !query;
    const lastStep = includesTechnicalQuestions ? 1 : 0;
    const rateTechSkillsStepIndex = 0;

    if (includesTechnicalQuestions) {
      if (currentStep === rateTechSkillsStepIndex) {
        track(events.RATE_TECH_SKILLS, { isBlank });
      }
    }

    if (currentStep === lastStep) {
      dispatch(setOnboardingLoading(true));

      const techSkillsReq = createTechSkillsRequestObject(
        values,
        slidersConfig
      );
      const softSkillsReq = createSoftSkillsRequestObject(
        values,
        slidersConfig
      );
      // submit data to backend
      Promise.all([
        saveTechSkills({
          data: techSkillsReq,
        }), // eslint-disable-line
        saveSoftSkills({
          data: softSkillsReq,
        }), // eslint-disable-line
      ])
        .then((responses) => {
          responses.forEach((response) => {
            if (response.status !== 204) {
              dispatchSaveError(dispatch);
            }
          });

          track(events.RATE_SOFT_SKILLS, { isBlank });
          dispatch(setOnboardingLoading(false));
          dispatch(nextSyntheticStep());
          dispatch(completeSection(2));
          dispatch(startOnboardingSection(3));
        })
        .catch(() => {
          dispatch(setOnboardingLoading(false));
          dispatchSaveError(dispatch);
        });
    } else {
      dispatch(nextStep());
      dispatch(nextSyntheticStep());
    }
  }

  return {
    initialValues,
    onFormSubmit,
    validateStep,
    currentStep,
    getSteps,
  };
};

export { useSkillsForm };
