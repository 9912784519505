// @flow
import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { paths } from '../../../../core/constants';
import { useStateContainer } from '../../../../core/context/StateContainer';
import { directApplication } from '../../directApplicationService';
import { dispatchSaveError } from '../../../basic/form/utils/dispatchSaveError';
import { setOnboardingLoading } from '../../../onboarding-talents/actions';
import { directApplication as directApplicationValidationConfig } from './validateConfig';
import { buildValidateFunc } from '../../../../utils/buildValidateFunc';
import { enqueueModal } from '../../../modals/actions';
import { OnboardingSuccess } from '../../../onboarding-talents/modals/OnboardingSuccess';
import { EditProfilePrompt } from '../../../modals/components/EditProfilePrompt';
import { useVisitorTrails } from '../../../tracking/visitor-trails/VisitorTrailsContext';
import { useTracking } from '../../../tracking/TrackingContext';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useDirectApplicationForm = () => {
  const { getTrail, visitorTrails } = useVisitorTrails();
  const { track, events } = useTracking();
  const location = useLocation();
  const locationState = location.state;
  const history = useHistory();
  const query = useQuery();

  const jobAdReferenceKey = query.get('jark') ? query.get('jark') : '';

  const [, dispatch] = useStateContainer();
  const validate = buildValidateFunc(
    directApplicationValidationConfig,
    (values, config) => config,
    0
  );

  const onFormSubmit = (values: { [string]: any }) => {
    const data = {
      ...values,
      jobId: jobAdReferenceKey,
    };

    dispatch(setOnboardingLoading(true));

    directApplication({ data })
      .then((response) => {
        if (response.status !== 204) {
          dispatchSaveError(dispatch);
        }

        history.replace({ pathname: paths.root });
        dispatch(setOnboardingLoading(false));
        // dispatch(completeSection(4));
        dispatch(
          enqueueModal(
            <OnboardingSuccess
              directApplication
              jobAdReferenceKey={jobAdReferenceKey}
            />
          )
        );
      })
      .catch(() => {
        dispatch(setOnboardingLoading(false));
        // dispatch(completeSection(4));
        dispatchSaveError(dispatch);
      });
  };

  function showEditProfilePrompt() {
    const isComingFromOnboarding =
      locationState && locationState.comingFromOnboarding;
    const promptAlreadyShown = getTrail(
      visitorTrails.EDIT_PROFILE_BEFORE_APPLY_SHOWN
    );

    if (isComingFromOnboarding || promptAlreadyShown) {
      return;
    }

    dispatch(enqueueModal(<EditProfilePrompt />));
    track(events.EDIT_PROFILE_PROMPT_SHOWN);
  }

  return {
    validate,
    onFormSubmit,
    showEditProfilePrompt,
  };
};

export { useDirectApplicationForm };
