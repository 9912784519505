// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../Button';
import { useStateContainer } from '../../../core/context/StateContainer';
import { prevStep, prevSyntheticStep } from '../../onboarding-talents/actions';
import { Loader } from '../Loader';

type SubmitButtonProps = {
  children: React.Node,
  width?: string,
  completesSection?: ?number,
};

const useStyles = makeStyles((theme) => ({
  button: ({ width }: { width: string }) => ({
    width,
    height: '40px',
  }),
  gap: {
    marginRight: theme.spacing(1),
  },
}));

function SubmitButton({
  children,
  width,
  completesSection,
  ...rest
}: SubmitButtonProps) {
  const classes = useStyles({ width });
  const [
    {
      onboarding: { loading },
    },
  ] = useStateContainer();
  const style = { width: 20, height: 20 };

  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.button}
      {...rest}
      type="submit"
      disabled={loading}
    >
      {loading ? (
        <Loader
          variant="white"
          svgParams={{ style }}
          containerParams={{ style }}
        />
      ) : (
        children
      )}
    </Button>
  );
}

SubmitButton.defaultProps = {
  completesSection: null,
  width: '120px',
};

type BackButtonProps = {
  width?: string,
  onClick?: () => void,
};

function BackButton({ width, onClick, ...rest }: BackButtonProps) {
  const classes = useStyles({ width });
  const [
    {
      onboarding: { loading },
    },
    dispatch,
  ] = useStateContainer();

  return (
    <Button
      type="button"
      variant="contained"
      color="secondary"
      className={`${classes.button} ${classes.gap}`}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        } else {
          dispatch(prevStep());
        }

        dispatch(prevSyntheticStep());
      }}
      {...rest}
      disabled={loading}
    >
      Back
    </Button>
  );
}

BackButton.defaultProps = {
  width: '120px',
  onClick: undefined,
};

export { SubmitButton, BackButton };
