// @flow
import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useStateContainer } from '../../../../core/context/StateContainer';
import {
  hydrateOnboardingStateStart,
  hydrateOnboardingStateEnd,
  setInitialization,
  startOnboardingSection,
  setSyntheticStep,
} from '../../actions';
import { onboardingState } from '../../onboardingFormsService';
import { useConsentDialogController } from '../../../../core/context/ConsentDialogController';
import { paths } from '../../../../core/constants';

const usePreviousValue = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

type Props = {
  query: string,
  jobAdReferenceKey: string,
};

const useOnboardingFormsContainer = ({ query, jobAdReferenceKey }: Props) => {
  const { showConsent } = useConsentDialogController();
  const history = useHistory();
  const [
    {
      onboarding: {
        initializationReady,
        hydration: { loading },
        currentSection,
      },
    },
    dispatch,
  ] = useStateContainer();
  const prevCurrentSection = usePreviousValue(currentSection);

  useEffect(() => dispatch(setSyntheticStep(0)), [dispatch]);

  const initializeOnboardingSections = useCallback(() => {
    dispatch(hydrateOnboardingStateStart());
    showConsent({
      onClose: function redirectToRoot() {
        history.push({ pathname: paths.root });
      },
    });
    onboardingState().then((data) => {
      dispatch(hydrateOnboardingStateEnd(data));
      dispatch(setInitialization(true));
      dispatch(startOnboardingSection(1));
    });
  }, [dispatch, history, showConsent]);

  const refetchOnboardingState = () => {
    if (
      prevCurrentSection !== currentSection &&
      prevCurrentSection > currentSection
    ) {
      // going back one section
      dispatch(hydrateOnboardingStateStart());
      onboardingState().then((data) => {
        dispatch(hydrateOnboardingStateEnd(data));
      });
    }
  };

  return {
    query,
    jobAdReferenceKey,
    loading,
    initializeOnboardingSections,
    refetchOnboardingState,
    initializationReady,
    currentSection,
  };
};

export { useOnboardingFormsContainer };
