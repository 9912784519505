// @flow

export function getParams() {
  const s = document.location.search;
  return s
    .substr(1)
    .split('&')
    .map<any>((i) => decodeURIComponent(i));
}

export function parseSearchQuery(): { [key: string]: string } | null {
  const params = getParams();
  const search = params.find((p) => p.startsWith('search='));
  if (!search) return null;

  const searchData = search
    .replace('search=', '')
    .trim()
    .split(',')
    .filter((v) => v !== '');

  if (!searchData.length) return null;
  return searchData
    .map((d) => d.split(':'))
    .reduce((acc, d) => {
      const [searchParam, value] = d;
      if (acc[searchParam]) {
        // multiple type params (tech:...,tech:...) -> transform to array
        if (typeof acc[searchParam] === 'string') {
          acc[searchParam] = [acc[searchParam], value];
        } else if (Array.isArray(acc[searchParam])) {
          acc[searchParam].push(value);
        }
      } else {
        acc[searchParam] =
          value === undefined || value === null
            ? ''
            : decodeURIComponent(value);
      }
      return acc;
    }, {});
}

export function insertParam(key: string, value: string | number) {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value.toString());

  // kvp looks like ['key1=value1', 'key2=value2', ...]
  const kvp = getParams();
  let i = 0;

  // update existing key
  for (; i < kvp.length; i += 1) {
    if (kvp[i].startsWith(`${key}=`)) {
      const pair = kvp[i].split('=');
      pair[1] = value.toString();
      kvp[i] = pair.join('=');
      break;
    }
  }

  // add value
  if (i >= kvp.length) {
    kvp[kvp.length] = [key, value].join('=');
  }

  // join the params and make sure the query string starts with ?
  let paramsStr = kvp.join('&');
  if (paramsStr[0] === '&') paramsStr = `?${paramsStr.substring(1)}`;

  return paramsStr;
}

export type TagType = 'tech' | 'text' | 'role';
export type FilterType =
  | 'text'
  | 'location'
  | 'role'
  | 'tech'
  | 'seniority'
  | 'salaryMax';
export type SearchFragmentObj = {
  type: FilterType,
  val: string | string[],
};
export function appendToSearchQuery(searchFragmentObj: SearchFragmentObj) {
  const { type } = searchFragmentObj;
  const value = searchFragmentObj.val;
  const params = getParams();
  let search = '';
  params.forEach((param) => {
    if (param.startsWith('search')) {
      search = param;
    }
  });

  let fragments = search
    .replace('search=', '')
    .split(',')
    .filter((f) => f !== '');

  const buildFragmentFromArray = (t: string, arr: string[]) => {
    const appendable = arr.reduce((acc, v) => {
      acc.push(`${t}:${v}`);
      return acc;
    }, []);

    return appendable.join(',');
  };

  // update the fragment if it's already in the search string
  let fragmentSet = false;
  const updatedFragments = [];
  fragments = fragments
    .map((fragment) => {
      let frag = fragment;
      // replace the fragment with the new value
      if (frag !== null && frag.startsWith(`${type}:`)) {
        if (updatedFragments.includes(type)) {
          // will be removed. In cases where we have repeatable fragments, like tech:javascript,tech:typescript
          return null;
        }

        if (Array.isArray(value)) {
          frag = buildFragmentFromArray(type, value);
        } else {
          // eslint-disable-next-line no-param-reassign
          frag = value === '' ? null : `${type}:${value}`;
        }

        updatedFragments.push(type);
        fragmentSet = true;
      }
      return frag;
    })
    .filter((f) => f !== null);

  // new fragment to be appended to the search string
  if (!fragmentSet) {
    if (Array.isArray(value)) {
      fragments.push(buildFragmentFromArray(type, value));
    } else {
      fragments.push(`${type}:${value}`);
    }
  }

  search = fragments.join(',');

  return search;
}
