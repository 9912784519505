// @flow
import React, { Fragment, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import { Form } from '../../../basic/form/Form';
import { Input } from '../../../basic/form/Input';
import { useReferrals } from '../../ReferralsContext';
import { Button } from '../../../basic/Button';
import { composeDirectReferral as validateConfig } from './validateConfig';
import { PartiallyEditableTextArea } from './components/PartiallyEditableTextArea';
import { useStateContainer } from '../../../../core/context/StateContainer';

type Props = {
  onSuccess?: () => void,
  onError?: (err: any) => { [key: string]: string },
  disabled: boolean,
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  control: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  controlWithRightPadding: {
    marginRight: theme.spacing(2),
  },
  fromInput: {
    background: `${theme.palette.grey[100]} !important`,
    border: `1px solid ${theme.palette.grey[100]} !important`,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  nonEditableTextArea: {
    userSelect: 'none',
    cursor: 'not-allowed',
    color: theme.palette.text.disabled,
  },
  nonEditableTextAreaLink: {
    color: theme.palette.text.disabled,
    cursor: 'not-allowed',
  },
  errorMessage: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2),
    borderRadius: theme.borderRadius,
    background: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  fromToContainer: {
    display: 'flex',
    '@media(max-width: 768px)': {
      flexDirection: 'column',
    },
  },
}));

function ComposeDirectReferralForm({ onSuccess, onError, disabled }: Props) {
  const classes = useStyles();
  const [
    {
      referrals: { loading },
      auth: { user },
    },
  ] = useStateContainer();
  const {
    getCurrentUrl,
    sendComposeDirectReferral,
    validateComposeDirectReferral,
  } = useReferrals();

  const onSubmit = useCallback(
    (values) => {
      const valuesToBeSent = {
        url: getCurrentUrl(),
        ...values,
      };

      return new Promise((resolve) => {
        sendComposeDirectReferral(valuesToBeSent)
          .then(function handleThen() {
            //eslint-disable-line
            if (!onSuccess) {
              return resolve();
            }

            onSuccess();
            return resolve();
          })
          .catch(function handleCatch(err) {
            //eslint-disable-line
            if (!onError) {
              return resolve();
            }
            const errors = onError(err);
            return resolve(errors);
          });
      });
    },
    [getCurrentUrl, onError, onSuccess, sendComposeDirectReferral]
  );

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateComposeDirectReferral(validateConfig)}
      formClassNames={classes.form}
      initialValues={{
        subject: 'Покана за работа през Noble Hire',
        message: `Здравей,\n\nИзпращам ти покана за Noble Hire - нова платформа с обяви за работа в технологични компании в България.\n\nРазгледай позициите - има няколко, които могат да те заинтересуват.`,
      }}
    >
      {({ submitErrors }) => {
        return (
          <Fragment>
            <div className={classes.fromToContainer}>
              <Input
                controlProps={{
                  className: `${classes.control} ${classes.controlWithRightPadding}`,
                }}
                inputProps={{ className: classes.fromInput }}
                fullWidth
                id="from"
                name="from"
                label="From"
                value={user && user.username}
                type="email"
                required
                disabled
              />
              <Input
                controlProps={{ className: classes.control }}
                fullWidth
                id="to"
                name="to"
                label="To"
                placeholder="Email"
                type="text"
                required
                disabled={disabled}
              />
            </div>
            <Input
              controlProps={{ className: classes.control }}
              fullWidth
              id="subject"
              name="subject"
              label="Subject"
              placeholder="Тества ли Noble Hire?"
              type="text"
              required
              disabled={disabled}
            />
            <PartiallyEditableTextArea
              controlClassName={classes.control}
              disabled={disabled}
            >
              <div className={classes.nonEditableTextArea}>
                <a
                  className={classes.nonEditableTextAreaLink}
                  href={window.location.href}
                  onClick={(e) => e.preventDefault()}
                >
                  Виж тук:&nbsp;
                  {window.location.href}
                </a>
                <p>
                  <span>Поздрави</span>
                </p>
              </div>
            </PartiallyEditableTextArea>
            {submitErrors && submitErrors[FORM_ERROR] ? (
              <div className={classes.errorMessage}>
                {submitErrors[FORM_ERROR]}
              </div>
            ) : null}
            <div className={classes.buttonContainer}>
              <div />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={disabled}
              >
                {loading.composeDirectReferral ? (
                  <span>Just a sec...</span>
                ) : (
                  <span>Send invite</span>
                )}
              </Button>
            </div>
          </Fragment>
        );
      }}
    </Form>
  );
}

ComposeDirectReferralForm.defaultProps = {
  onSuccess: () => {},
  onError: () => ({}),
};

export { ComposeDirectReferralForm };
