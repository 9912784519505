// @flow
import type { State, Action } from '../../core/types';
import { ENQUEUE_MODAL, DEQUEUE_MODAL } from './actions';

const modals = (state: State, action: Action) => {
  const enq = (data) => ({ ...state, queue: [...state.queue, data] });
  const deq = () => ({ ...state, queue: [...state.queue.slice(1)] });

  if (!action) {
    return state;
  }

  switch (action.type) {
    case ENQUEUE_MODAL:
      return enq(action.data || null);
    case DEQUEUE_MODAL:
      return deq();
    default:
      return state;
  }
};

modals.initialState = {
  queue: [],
};

export { modals };
