// @flow
import * as React from 'react';
import { FormSpy } from 'react-final-form';
import { useBasicInformationForm } from './useBasicInformationForm';
import { OnboardingForm } from '../OnboardingForm';
import {
  setIsTech,
  setIsLookingForTech,
  setSyntheticStepsCount,
} from '../../actions';
import { useStateContainer } from '../../../../core/context/StateContainer';
import { isRoleTech } from '../../../../utils/isRoleTech';

const BasicInformationForm = () => {
  const [, dispatch] = useStateContainer();
  const spy = (
    <FormSpy
      subscription={{ values: true }}
      onChange={({ values: spyValues }: any) => {
        const { currentPosition } = spyValues;
        const { jobSearchRoleType } = spyValues;

        if (spyValues.currentPosition) {
          dispatch(setIsTech(currentPosition));
        }

        if (spyValues.jobSearchRoleType) {
          dispatch(setIsLookingForTech(jobSearchRoleType));

          if (!isRoleTech(currentPosition) && !isRoleTech(jobSearchRoleType)) {
            dispatch(setSyntheticStepsCount(7));
          } else {
            dispatch(setSyntheticStepsCount(8));
          }
        }
      }}
    />
  );

  return <OnboardingForm useHook={useBasicInformationForm} spy={spy} />;
};

export { BasicInformationForm };
