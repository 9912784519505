// @flow
/* eslint-disable no-use-before-define */
import React from 'react';
import type { Node } from 'react';
import { Typography, Box } from '@material-ui/core';

export function Title({
  children,
  variant = Title.variants.t1,
  ...rest
}: {
  children: Node,
  variant: $Values<typeof Title.variants>,
}) {
  const variantsMap: { [key: $Values<typeof Title.variant>]: any } = {
    [Title.variants.t1]: Variant1,
    [Title.variants.t2]: Variant2,
    [Title.variants.t3]: Variant3,
    [Title.variants.t4]: Variant4,
  };
  const ResolvedVariant = variantsMap[variant];

  return <ResolvedVariant {...rest}>{children}</ResolvedVariant>;
}

Title.variants = {
  t1: 'variant1',
  t2: 'variant2',
  t3: 'variant3',
  t4: 'variant4',
};

// --- VARIANT IMPLEMENTATIONS ---

function Variant1({ children, ...rest }: { children: Node }): Node {
  return (
    <Typography variant="h4">
      <Box fontWeight="bold" {...rest}>
        {children}
      </Box>
    </Typography>
  );
}

function Variant2({ children, ...rest }: { children: Node }): Node {
  return (
    <Typography variant="h5">
      <Box style={{ textTransform: 'uppercase' }} fontWeight="bold" {...rest}>
        {children}
      </Box>
    </Typography>
  );
}

function Variant3({ children, ...rest }: { children: Node }): Node {
  return (
    <Typography variant="h6">
      <Box fontWeight="bold" {...rest}>
        {children}
      </Box>
    </Typography>
  );
}

function Variant4({ children, ...rest }: { children: Node }): Node {
  return (
    <Typography variant="overline">
      <Box fontWeight="bold" color="grey.500" mb={1} {...rest}>
        {children}
      </Box>
    </Typography>
  );
}
