// @flow
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, {
  useCallback,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import type { Node } from 'react';
import { getCompanyDataRequest } from '../companiesService';
import { useStateContainer } from '../../../../core/context/StateContainer';
import {
  convToGenericCompProp,
  convToLocation,
  normServerImage,
} from '../utils';
import type {
  Award,
  CultureAndPerks,
  Image,
  LocationObj,
  Value,
} from './CompanyContext';
import type { Company } from '../types';

// ---- Types
type MyCompanyCtx = Company<LocationObj> & { loading: boolean };
const Context = createContext<MyCompanyCtx | null>(null);

const MyCompanyProvider = ({ children }: { children: Node }) => {
  const [
    {
      auth: { authenticated, user, isCompany, isAdmin },
    },
  ] = useStateContainer();
  const [loading, setLoading] = useState<boolean>(true);
  const [id, setId] = useState<?number>(null);
  const [brand, setBrand] = useState<?string>(null);
  const [slug, setSlug] = useState<?string>(null);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [overview, setOverview] = useState<string>('');
  const [locations, setLocations] = useState<Array<LocationObj>>([]);
  const [logos, setLogos] = useState<Image[]>([]);
  const [mainImages, setMainImages] = useState<Image[]>([]);
  const [product, setProduct] = useState<string>('');
  const [productImages, setProductImages] = useState<Image[]>([]);
  const [photos, setPhotos] = useState<Image[]>([]);
  const [clientImages, setClientImages] = useState<Image[]>([]);
  const [awards, setAwards] = useState<Award[]>([]);
  const [cultureAndPerks, setCultureAndPerks] = useState<CultureAndPerks[]>([]);
  const [values, setValues] = useState<Value[]>([]);
  const [loadStarted, setLoadStarted] = useState<boolean>(false);

  const load = useCallback(async () => {
    const response = await getCompanyDataRequest();
    setSlug(response.data.slug);

    setId(response.data.id);
    setBrand(response.data.brand);
    setSlug(response.data.slug);
    setIsPublic(response.data.public || isPublic);
    setOverview(response.data.overview);

    const rLocations = response.data.locations.map(convToLocation);
    setLocations(rLocations || locations);

    const rLogos = response.data.images
      .filter((i) => i.collection === 'logo')
      .map(normServerImage);
    setLogos(rLogos || logos);

    const rMainImages = response.data.images
      .filter((i) => i.collection === 'main')
      .map(normServerImage);
    setMainImages(rMainImages || mainImages);

    const rProduct = response.data.product;
    setProduct(rProduct || product);

    const rProductImages = response.data.images
      .filter((i) => i.collection === 'product')
      .map(normServerImage);
    setProductImages(rProductImages || productImages);

    const rPhotos = response.data.images
      .filter(
        (i) =>
          i.collection === 'photos' ||
          i.collection === 'culture' ||
          i.collection === 'office'
      )
      .map(normServerImage);
    setPhotos(rPhotos || photos);

    const rClientImages = response.data.images
      .filter((i) => i.collection === 'clients')
      .map(normServerImage);
    setClientImages(rClientImages || clientImages);

    const rAwards =
      response.data.awards && response.data.awards.length
        ? response.data.awards.map(convToGenericCompProp)
        : null;
    setAwards(rAwards || awards);

    const rPerks = response.data.perks.map(convToGenericCompProp);
    setCultureAndPerks(rPerks || cultureAndPerks);

    const rValues = response.data.values.map(convToGenericCompProp);
    setValues(rValues || values);
  }, [
    awards,
    clientImages,
    cultureAndPerks,
    isPublic,
    logos,
    mainImages,
    photos,
    productImages,
    product,
    values,
    locations,
  ]);

  useEffect(() => {
    const userIsCompany = authenticated && user && isCompany(user);
    // const userIsAdmin = authenticated && user && isAdmin(user);
    if (userIsCompany && loading && !loadStarted) {
      setLoadStarted(true);
      load().then(() => {
        setLoading(false);
        setLoadStarted(false);
      });
    }
  }, [authenticated, user, isCompany, isAdmin, load, loading, loadStarted]);

  const value = {
    id,
    brand,
    overview,
    awards,
    clientImages,
    cultureAndPerks,
    isPublic,
    logos,
    mainImages,
    photos,
    productImages,
    product,
    values,
    locations,
    slug,
    loading,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useMyCompany: () => MyCompanyCtx = () => {
  const ctx = useContext(Context);
  if (ctx === null) throw new Error('Improper use of MyCompanyContext.');
  return ctx;
};

export default MyCompanyProvider;
