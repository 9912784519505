// @flow
import { paths } from '../core/constants';

const QUERY_HINT_RESOLUTION = {
  DIRECT_APPLICATION: 'DIRECT_APPLICATION',
  SHORT_REGISTRATION: 'SHORT_REGISTRATION',
};

function queryHintResolver(
  query: string
): ?$Values<typeof QUERY_HINT_RESOLUTION> {
  const url = window.location.href;
  const directApplicationHintRegex = /\??jark=(.)+&jat=(.)+/gim;
  const shortRegistrationHintRegex = /\??rtype=s/gim;

  if (directApplicationHintRegex.test(query)) {
    return QUERY_HINT_RESOLUTION.DIRECT_APPLICATION;
  }

  if (url.includes(paths.register) && shortRegistrationHintRegex.test(query)) {
    return QUERY_HINT_RESOLUTION.SHORT_REGISTRATION;
  }

  return null;
}

export { queryHintResolver, QUERY_HINT_RESOLUTION };
