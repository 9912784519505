// @flow
import * as React from 'react';
import { Form as FinalForm } from 'react-final-form';

type Props = {
  children: (any) => React.Node,
  onSubmit: any,
  validate: ({ [key: string]: string }) => { [key: string]: string },
  formClassNames?: ?{ [key: string]: any },
};

function Form({
  children,
  validate,
  formClassNames,
  onSubmit,
  ...ffRest
}: Props) {
  return (
    <FinalForm onSubmit={onSubmit} validate={validate} {...ffRest}>
      {({ handleSubmit, ...rest }) => (
        <form className={formClassNames} onSubmit={handleSubmit}>
          {children({ ...rest, ...ffRest })}
        </form>
      )}
    </FinalForm>
  );
}

Form.defaultProps = {
  formClassNames: null,
};

export { Form };
