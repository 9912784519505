// @flow
import React from 'react';
import { FullPageLoader } from '../components/basic/FullPageLoader';
import { Redirect, useLocation } from 'react-router-dom';
import { useStateContainer } from '../core/context/StateContainer';
import { paths } from '../core/constants';
import { useMyCompany } from '../components/companies/common/context/MyCompanyContext';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// This page is used specifically to make the proper
// redirection, based on the user's role.
export function PostLogin() {
  const query = useQuery();
  const loc = useLocation();
  const [
    {
      auth: { authenticated, user, isCompany },
    },
  ] = useStateContainer();
  const myCompany = useMyCompany();

  if (authenticated && user && isCompany(user)) {
    if (!myCompany || (myCompany && myCompany.loading)) {
      return <FullPageLoader>Resolving your company details...</FullPageLoader>;
    }

    return (
      <Redirect
        to={{
          pathname: paths.companiesDashboard.replace(':slug', myCompany.slug),
          search: loc.search,
        }}
      />
    );
  }

  if (authenticated && user && query.get('jark')) {
    return (
      <Redirect
        to={{ pathname: paths.directApplication, search: loc.search }}
      />
    );
  }

  if (authenticated && user && !isCompany(user)) {
    return <Redirect to={{ pathname: paths.root, search: loc.search }} />;
  }

  return (
    <FullPageLoader>
      Constructing your profile out of dark matter...
    </FullPageLoader>
  );
}
