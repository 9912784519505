// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal as MaterialModal } from '@material-ui/core';
import { Close } from '@material-ui/icons';

type Props = {
  children: React.Node,
  open: boolean,
  titleId: string,
  contentId: string,
  onClose?: () => void,
  paperClassName?: string,
  fullScreen?: boolean,
  withCloseButton?: boolean,
};

const useStyles = makeStyles((theme) => {
  return {
    paper: ({ fullScreen }) => {
      const fullScreenStyles = fullScreen
        ? {
            width: '100%',
            height: '100%',
          }
        : {};

      return {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.common.white,
        padding: theme.spacing(4),
        borderRadius: fullScreen ? 0 : theme.borderRadius,
        outline: 'none',
        ...fullScreenStyles,

        '@media (max-width: 768px)': {
          display: 'flex',
          justifyContent: 'flex-start',
          left: 'initial',
          top: 'initial',
          bottom: 0,
          transform: 'none',
          flexDirection: 'column',
          textAlign: 'center',
          alignItems: 'center',
          height: fullScreen ? '100%' : '90%',
          width: '100%',
          borderTopLeftRadius: fullScreen ? 0 : 10,
          borderTopRightRadius: fullScreen ? 0 : 10,
          overflow: 'auto',
        },
      };
    },
    closeButton: {
      cursor: 'pointer',
      position: 'absolute',
      top: theme.spacing(4),
      right: theme.spacing(4),
    },
  };
});

function Modal({
  children,
  open,
  onClose,
  titleId,
  contentId,
  paperClassName,
  fullScreen = false,
  withCloseButton = true,
}: Props) {
  const classes = useStyles({ fullScreen });

  return (
    <MaterialModal
      open={open}
      onClose={onClose}
      aria-labelledby={titleId}
      aria-describedby={contentId}
    >
      <div className={`${classes.paper} ${paperClassName || ''}`}>
        {withCloseButton ? (
          <Close className={classes.closeButton} onClick={onClose} />
        ) : null}
        {children}
      </div>
    </MaterialModal>
  );
}

Modal.defaultProps = {
  onClose: () => {},
  paperClassName: '',
  fullScreen: false,
  withCloseButton: true,
};

export { Modal };
