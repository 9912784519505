// @flow
import * as React from 'react';

type Props = {
  children: React.Node,
};

const { useEffect } = React;

/**
 * Used to hide the "go-to-top" arrow on the wordpress in the bottom right corner
 */
function HideTopArrow({ children }: Props) {
  useEffect(() => {
    const arrowElement = document.querySelector('.mk-go-top');
    const noop = () => {};
    const showArrowElement = () => {
      if (arrowElement) {
        arrowElement.classList.remove('hide');
      }
    };

    if (arrowElement) {
      arrowElement.classList.add('hide');
    }

    return arrowElement ? showArrowElement : noop;
  }, []);

  return children;
}

export { HideTopArrow };
