// @flow
/* eslint-disable no-nested-ternary */
/* eslint-disable flowtype/generic-spacing */
/* eslint-disable react/no-danger */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  Fragment,
} from 'react';
import type { Node } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect, Link, useLocation } from 'react-router-dom';
import {
  TextareaAutosize,
  Box,
  Container,
  useTheme,
  makeStyles,
} from '@material-ui/core';
import { FullPageLoader } from '../basic/FullPageLoader';
import type { Job, CoreRequirement, Responsibility, Perk } from './JobsContext';
import {
  paths,
  seniority as seniorityLevels,
  jobTypes,
  teamSizes,
  programmingLanguages,
  platformsAndFrameworks,
  databases,
  roles,
  currencies,
} from '../../core/constants';
import { Title } from '../basic/Title';
import { Button } from '../basic/Button';
import { Modal } from '../basic/Modal';
import { useMQ } from '../useMQ';
import { HideIf } from '../basic/HideIf';
import { UnderCoverContainer, UnderCover } from '../basic/UnderCover';
import { EditControls } from '../basic/EditControls';
import { txtColor, useStyles } from './utils';
import { HiringProcess } from './common/HiringProcess';
import type { Editable, Previewable } from './types';
import { TeamLead } from './common/TeamLead';
import { TimeSpendingDetails } from './common/TimeSpendingDetails';
import { ToolsWeUse } from './common/ToolsWeUse';
import type { ServerLocation } from '../companies/common/context/CompanyContext';
import { Select } from './common/Select';
import { MultipleSelect } from './common/MultipleSelect';
import { useStateContainer } from '../../core/context/StateContainer';
import { useTracking } from '../tracking/TrackingContext';
import { TopMenuContext, SideMenuTypes } from '../menu/context/TopMenu';
import { Product } from './common/Product';
import { useApply } from '../applications/ApplicationsContext';
import { RichEditor } from '../basic/RichEditor';

const GREY_TEXT = 500;

const buildSelectOpts = (
  map: { [key: string | number]: any },
  selected: string | number | null
) => {
  return Object.keys(map).reduce((acc, key) => {
    // build obj in the form: { intern: { l: 'Intern', selected: newSeniority === 'intern' }, ... }
    acc[key] = { l: map[key], selected: selected === key };
    return acc;
  }, {});
};

export function JobAd({
  job,
  loading = false,
  jobNotFound,
  onClose,
}: {
  job: Job<any> | null,
  jobNotFound: boolean | null,
  loading?: boolean,
  onClose: () => void,
}) {
  const [
    {
      auth: { authenticated, user, isAdmin },
    },
  ] = useStateContainer();
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const ref = useRef(null);
  const onScrollTop = () => {
    if (ref.current) {
      ref.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  // ---------------------------------------------------------- //
  // rendering
  // ---------------------------------------------------------- //
  if (jobNotFound === true && !loading && mounted)
    return <Redirect to={paths.jobs} />;
  if (loading)
    return (
      <FullPageLoader>
        Fetching data from beyond the stratosphere.
        <br />
        Beep, Beep, Boop...
      </FullPageLoader>
    );

  if (!job) return null;

  return (
    <Box height="100%" overflow="auto" px={4} py={2} ref={ref}>
      <Container>
        <Box my={4}>
          <HideIf cond={() => !user || !authenticated || !isAdmin(user)}>
            <AdminControls
              editLink={{
                pathname: paths.jobEdit.replace(
                  ':slug+',
                  `${(job && job.company && job.company.slug) || ''}/${
                    job.slug || ''
                  }`
                ),
                search: 'edit=true',
              }}
              companyDashboardLink={{
                pathname: paths.companiesDashboard.replace(
                  ':slug',
                  `${(job && job.company && job.company.slug) || ''}`
                ),
                search: '',
              }}
              isPrivate={!job.isPublic}
            />
          </HideIf>
        </Box>
        <Box my={4}>
          <Heading
            isPreview={false}
            isEdit={false}
            seniority={job.seniority}
            type={job.type}
            title={job.title}
            onClose={onClose}
            location={job.location}
            locations={job.company.locations}
          />
        </Box>
        <Box my={4}>
          <CompanyInfo
            isEdit={false}
            logo={
              job.company.logos && job.company.logos.length
                ? job.company.logos[job.company.logos.length - 1].src
                : undefined
            }
            companyName={job.company.brand}
            link={job.company.slug}
          />
        </Box>
        <HideIf cond={() => !job.description}>
          <Box mt={4}>
            <JobDescription value={job.description} isEdit={false} />
          </Box>
        </HideIf>
        <Box mt={4}>
          <JobDetails
            location={job.location}
            locations={job.company.locations}
            isEdit={false}
            type={job.type}
            role={job.role}
            salary={job.salary}
            salaryCurrency={job.salaryCurrency}
            seniority={job.seniority}
            stockOptions={job.stockOptions}
            homeOffice={job.homeOffice}
            teamSize={job.teamSize}
            fullyRemote={job.fullyRemote}
            customerFacing={job.customerFacing}
            businessTravel={job.businessTravel}
          />
        </Box>
        <HideIf
          cond={() =>
            !job.primaryLanguage &&
            !job.primaryPlatformOrFramework &&
            !job.secondaryLanguage &&
            !job.secondaryPlatformOrFramework &&
            !job.mainDatabase
          }
        >
          <Box mt={4}>
            <TechRequirements
              isEdit={false}
              primaryLanguage={job.primaryLanguage}
              primaryPlatformOrFramework={job.primaryPlatformOrFramework}
              secondaryLanguage={job.secondaryLanguage}
              secondaryPlatformOrFramework={job.secondaryPlatformOrFramework}
              mainDatabase={job.mainDatabase}
            />
          </Box>
        </HideIf>
        <HideIf
          cond={() =>
            !job.coreRequirements.length && !job.responsibilities.length
          }
        >
          <Box mt={4}>
            <RequirementsResponsibilities
              isEdit={false}
              coreRequirements={job.coreRequirements}
              responsibilities={job.responsibilities}
            />
          </Box>
        </HideIf>
        <HideIf cond={() => job.tools.length === 0}>
          <Box mt={4}>
            <ToolsWeUse isEdit={false} tools={job.tools} />
          </Box>
        </HideIf>
        <HideIf
          cond={() => !job.productImages.length && !job.productDescription}
        >
          <Box mt={4}>
            <Product
              title="Projects you will be working on"
              isEdit={false}
              product={job.productDescription}
              images={job.productImages}
              allProductImages={job.company.productImages}
              companyId={job.company.id}
            />
          </Box>
        </HideIf>
        <HideIf cond={() => job.timeSpendingDetails.length === 0}>
          <Box mt={4}>
            <TimeSpendingDetails
              isEdit={false}
              items={job.timeSpendingDetails}
            />
          </Box>
        </HideIf>
        <HideIf cond={() => !job.teamLeadName}>
          <Box mt={4}>
            <TeamLead
              isEdit={false}
              image={job.teamLeadImage}
              name={job.teamLeadName}
              role={job.teamLeadRole}
              link={job.teamLeadLink}
            />
          </Box>
        </HideIf>
        <HideIf cond={() => !job.perks.length}>
          <Box mt={4}>
            <Perks isEdit={false} items={job.perks} />
          </Box>
        </HideIf>
        <HideIf cond={() => !job.hiringProcessSteps.length}>
          <Box my={4}>
            <HiringProcess steps={job.hiringProcessSteps} isEdit={false} />
          </Box>
        </HideIf>
        <Box my={4}>
          <ControlsBar isEdit={false} onScrollTop={onScrollTop} />
        </Box>
        <Box mt={4} mb={12}>
          <CompanyInfo
            isEdit={false}
            logo={
              job.company.logos && job.company.logos.length
                ? job.company.logos[job.company.logos.length - 1].src
                : undefined
            }
            companyName={job.company.brand}
            link={job.company.slug}
          />
        </Box>
        <Box position="relative">
          <ApplyControls
            jobAdId={job.id}
            jobAdTitle={job.title}
            jobAdSeniority={job.seniority}
          />
        </Box>
      </Container>
    </Box>
  );
}

function AdminControls({
  editLink,
  companyDashboardLink,
  isPrivate,
}: {
  editLink?: { pathname: string, search?: string },
  companyDashboardLink?: { pathname: string, search?: string },
  isPrivate: boolean,
}) {
  const theme = useTheme();
  if (!editLink) return null;
  return (
    <Box
      display="flex"
      alignItems="center"
      border={`1px solid ${theme.palette.grey[300]}`}
      p={1}
      borderRadius={5}
      position="relative"
    >
      <Box
        position="absolute"
        top={-14}
        right={10}
        style={{
          background: theme.palette.grey[100],
          textTransform: 'uppercase',
        }}
        border={`1px solid ${theme.palette.grey[300]}`}
        borderRadius={5}
        p={0.5}
        fontSize={11}
        fontFamily={theme.typography.fontFamily}
      >
        Admin controls
      </Box>
      <Box flex={1}>
        <Link to={editLink} style={{ marginRight: 10, textDecoration: 'none' }}>
          <Button type="button" variant="contained" color="primary">
            Edit
          </Button>
        </Link>
        <Link
          to={companyDashboardLink}
          style={{ marginRight: 10, textDecoration: 'none' }}
        >
          <Button type="button" variant="contained" color="primary">
            Company Dashboard
          </Button>
        </Link>
      </Box>
      <Box display="flex" alignItems="center">
        <span style={{ marginRight: theme.spacing(1) }}>Job status:</span>
        <Box
          p={0.3}
          style={{
            background: isPrivate ? theme.palette.edit.main : 'teal',
            color: isPrivate
              ? theme.palette.common.black
              : theme.palette.common.white,
            borderRadius: 5,
          }}
        >
          {isPrivate ? `Private` : `Public`}
        </Box>
      </Box>
    </Box>
  );
}

type JobTypePickerProps = {
  type: $Keys<typeof jobTypes> | null,
  onTypeChange?: (string) => void,
  lock?: boolean,
};
function JobTypePicker({
  type,
  onTypeChange,
  lock = false,
}: JobTypePickerProps) {
  // job type
  const [newType, setNewType] = useState(type || jobTypes.fullTime);
  useEffect(() => setNewType(type), [type]);
  const typeChange = (t: string) => {
    setNewType(t);
    if (onTypeChange) onTypeChange(t);
  };
  const typeOpts = buildSelectOpts(jobTypes, newType);

  return (
    <Select
      defaultLabel="Select Job Type"
      options={typeOpts}
      onChange={typeChange}
      lock={lock}
    />
  );
}

type RolePickerProps = {
  role: $Keys<typeof roles> | null,
  onRoleChange?: (string) => void,
  lock?: boolean,
};
function RolePicker({ role, onRoleChange, lock = false }: RolePickerProps) {
  // seniority
  const [newRole, setNewRole] = useState<$Keys<typeof roles> | null>(role);
  useEffect(() => setNewRole(role), [role]);
  const roleChange = (s: string) => {
    setNewRole(s);
    if (onRoleChange) onRoleChange(s);
  };
  const roleOptions = buildSelectOpts(roles, newRole);

  return (
    <Select
      defaultLabel="Select Role"
      options={roleOptions}
      onChange={roleChange}
      lock={lock}
    />
  );
}

type SeniorityPickerProps = {
  seniority: $Keys<typeof seniorityLevels> | null,
  onSeniorityChange?: (string) => void,
  lock?: boolean,
};
function SeniorityPicker({
  seniority,
  onSeniorityChange,
  lock = false,
}: SeniorityPickerProps) {
  // seniority
  const [newSeniority, setNewSeniority] = useState<$Keys<
    typeof seniorityLevels
  > | null>(seniority);
  useEffect(() => setNewSeniority(seniority), [seniority]);
  const seniorityChange = (s: string) => {
    setNewSeniority(s);
    if (onSeniorityChange) onSeniorityChange(s);
  };
  const seniorityOpts = buildSelectOpts(seniorityLevels, newSeniority);

  return (
    <Select
      defaultLabel="Select Seniority"
      options={seniorityOpts}
      onChange={seniorityChange}
      lock={lock}
    />
  );
}

type HeadingProps = {
  title: string | null,
  seniority: $Keys<typeof seniorityLevels> | null,
  type: $Keys<typeof jobTypes> | null,
  onTitleChange?: (newTitle: string) => void,
  onSeniorityChange?: (string) => void,
  onTypeChange?: (string) => void,
  onClose?: () => void,
  location: ServerLocation[] | null,
  locations?: ServerLocation[],
  onLocationChange?: (any[]) => void,
};
export function Heading({
  isEdit,
  isPreview,
  title,
  seniority,
  type,
  onTitleChange,
  onSeniorityChange,
  onTypeChange,
  onClose,
  location,
  locations,
  onLocationChange,
}: HeadingProps & Editable & Previewable) {
  const mq = useMQ();
  const theme = useTheme();

  // title state + events handling
  const titleInputRef = useRef(null);
  const [editingTitle, setEditingTitle] = useState(false);
  useEffect(() => {
    if (editingTitle && titleInputRef.current) titleInputRef.current.focus();
  }, [titleInputRef, editingTitle]);
  const [newTitle, setNewTitle] = useState<string>(title || '');
  useEffect(() => setNewTitle(title || ''), [title]);
  const editTitle = () => setEditingTitle(true);
  const titleSubmit = () => {
    setEditingTitle(false);
    if (onTitleChange) onTitleChange(newTitle);
  };
  const titleKeyUp = (e: KeyboardEvent) => {
    const code = Number(e.keyCode);
    if (code === 27 || code === 13) titleSubmit();
  };
  const placeholder = 'E.g. React Frontend Developer';

  return (
    <Box color={txtColor(theme, isEdit)}>
      {editingTitle ? (
        <input
          ref={titleInputRef}
          style={{
            width: '100%',
            height: 60,
            fontSize: '2.125rem',
            fontFamily: theme.typography.fontFamily,
            fontWeight: 'bold',
          }}
          placeholder={placeholder}
          type="text"
          value={newTitle}
          onBlur={titleSubmit}
          onKeyUp={titleKeyUp}
          onChange={({ target }) => setNewTitle(target.value)}
        />
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <HideIf cond={() => !isEdit && isPreview && !newTitle}>
            <Title
              variant={Title.variants.t1}
              style={{
                wordBreak: 'break-word',
                color: newTitle
                  ? txtColor(theme, isEdit)
                  : theme.palette.grey[200],
                border: isEdit
                  ? `1px solid ${theme.palette.grey[300]}`
                  : undefined,
                padding: isEdit ? theme.spacing(1) : undefined,
                borderRadius: theme.shape.borderRadius,
                cursor: 'text',
              }}
              onClick={isEdit ? editTitle : null}
            >
              {newTitle || placeholder}
            </Title>
          </HideIf>
          {!isEdit && isPreview && !newTitle && <div />}
          {onClose ? (
            <Box style={{ cursor: 'pointer' }} onClick={onClose}>
              <FontAwesomeIcon icon={['fal', 'times']} size="2x" />
            </Box>
          ) : null}
        </Box>
      )}
      <Box
        display="flex"
        alignItems={mq.md ? 'center' : 'flex-start'}
        mt={2}
        mb={4}
      >
        <Box mr={4}>
          <LocationPicker
            location={location}
            locations={locations}
            onLocationChange={onLocationChange}
            lock
          />
        </Box>
        <Box mr={4}>
          <SeniorityPicker
            seniority={seniority}
            onSeniorityChange={onSeniorityChange}
            lock
          />
        </Box>
        <Box>
          <JobTypePicker type={type} onTypeChange={onTypeChange} lock />
        </Box>
      </Box>
    </Box>
  );
}

type SalaryCurrencyPickerProps = {
  salaryCurrency: $Keys<typeof currencies> | null,
  onSalaryCurrencyChange?: (string) => void,
  lock?: boolean,
};
function SalaryCurrencyPicker({
  salaryCurrency,
  onSalaryCurrencyChange,
  lock = false,
}: SalaryCurrencyPickerProps) {
  // job type
  const [newSalaryCurrency, setNewSalaryCurrency] = useState(salaryCurrency);
  useEffect(() => setNewSalaryCurrency(salaryCurrency), [salaryCurrency]);
  const currencyChange = (t: string) => {
    setNewSalaryCurrency(t);
    if (onSalaryCurrencyChange) onSalaryCurrencyChange(t);
  };
  const currenciesOptions = buildSelectOpts(currencies, newSalaryCurrency);

  return (
    <Select
      defaultLabel="Select Currency"
      options={currenciesOptions}
      onChange={currencyChange}
      lock={lock}
    />
  );
}

type LocationPickerProps = {
  location: any,
  locations?: ServerLocation[],
  onLocationChange?: (any[]) => void,
  lock?: boolean,
  textColor?: any,
  simpleAddress?: boolean,
};
export function LocationPicker({
  location,
  locations,
  onLocationChange,
  lock = false,
  textColor,
  simpleAddress = false,
}: LocationPickerProps) {
  const ADDRESS_MAX_LEN = 30;

  const onLocationPicked = (pickedLocations: Array<ServerLocation>) => {
    if (typeof onLocationChange === 'function') {
      onLocationChange(
        pickedLocations.map((pl) => {
          const originalLocationObj =
            locations && locations.find((_location) => _location.id === pl.id);
          return {
            ...pl,
            address:
              originalLocationObj && originalLocationObj.address
                ? originalLocationObj.address
                : originalLocationObj,
          };
        })
      );
    }
  };

  const options =
    locations &&
    locations.map((l) => ({ ...l, address: l.address.formatted_address }));

  const selected =
    (location &&
      location.map((l) => ({
        ...l,
        address: l.address.formatted_address,
      }))) ||
    [];

  const formatSimpleAddress = (_location: any) => {
    const addr = _location.address;
    if (!addr || !Object.keys(addr).length) return '--';

    let city = null;
    let country = null;

    // resolve city first run + country
    addr.address_components.forEach((ac) => {
      if (ac.types.toString() === 'locality,political') city = ac.long_name;
      if (ac.types.toString() === 'country,political') country = ac.long_name;
    });
    // resolve city second run, if first run found nothing
    if (city === null)
      addr.address_components.forEach((ac) => {
        if (ac.types.toString() === 'administrative_area_level_1,political')
          city = ac.long_name;
      });

    if (city && country) return `${city}, ${country}`;
    if (!city && country) return country;
    if (!country && city) return city;
    if (!country && !city) return '--';
  };

  if (simpleAddress && location && location.length === 1) {
    return <div>{formatSimpleAddress(location[0])}</div>;
  }

  return (
    <MultipleSelect
      defaultLabel="Select Location"
      selected={selected}
      options={options}
      onChange={onLocationPicked}
      lock={lock}
      labelProperty="address"
      labelForMultipleSelected="Locations"
      cutLength={ADDRESS_MAX_LEN}
      lockedTextColor={textColor}
    />
  );
}

type JobDetailsProps = {
  location: ServerLocation[] | null,
  locations?: ServerLocation[],
  role: $Keys<typeof roles>,
  seniority: $Keys<typeof seniorityLevels>,
  salary: string,
  salaryCurrency: $Keys<typeof currencies>,
  type: $Keys<typeof jobTypes>,
  stockOptions: 'yes' | 'no',
  homeOffice: 'yes' | 'no',
  teamSize: $Keys<typeof teamSizes>,
  fullyRemote: 'yes' | 'no',
  customerFacing: 'yes' | 'no',
  businessTravel: 'yes' | 'no',
  onLocationChange?: (any[]) => void,
  onSalaryChange?: (string) => void,
  onSalaryCurrencyChange?: (string) => void,
  onRoleChange?: (string) => void,
  onSeniorityChange?: (string) => void,
  onTypeChange?: (string) => void,
  onStockOptionsChange?: ('yes' | 'no') => void,
  onHomeOfficeChange?: ('yes' | 'no') => void,
  onTeamSizeChange?: ($Keys<typeof teamSizes>) => void,
  onFullyRemoteChange?: ('yes' | 'no') => void,
  onCustomerFacingChange?: ('yes' | 'no') => void,
  onBusinessTravelChange?: ('yes' | 'no') => void,
};

export function JobDetails({
  isEdit,
  location,
  locations,
  role,
  salary,
  salaryCurrency,
  seniority,
  type,
  stockOptions,
  homeOffice,
  teamSize,
  fullyRemote,
  customerFacing,
  businessTravel,
  onLocationChange,
  onRoleChange,
  onSeniorityChange,
  onTypeChange,
  onSalaryChange,
  onSalaryCurrencyChange,
  onStockOptionsChange,
  onHomeOfficeChange,
  onTeamSizeChange,
  onFullyRemoteChange,
  onCustomerFacingChange,
  onBusinessTravelChange,
}: JobDetailsProps & Editable) {
  const mq = useMQ();
  const theme = useTheme();

  let itemFlexDir = 'column';
  if (mq.xxl) itemFlexDir = 'row';

  const Item = ({
    icon,
    text,
    value,
    last = false,
  }: {
    icon: string,
    text: string,
    value: Node,
    last?: boolean,
  }) => {
    return (
      <Box
        display="flex"
        flexDirection={itemFlexDir}
        alignItems={itemFlexDir === 'row' ? 'flex-start' : 'flex-start'}
        justifyContent={itemFlexDir ? 'space-between' : 'initial'}
        flex={1}
        mb={last ? 0 : 2}
      >
        <Box display="flex" alignItems="center" width={!mq.md ? '100%' : 160}>
          <Box mr={2}>
            <FontAwesomeIcon icon={['fal', icon]} />
          </Box>
          <Box
            mr={2}
            fontSize={theme.typography.pxToRem(12)}
            color={theme.palette.grey[GREY_TEXT]}
          >
            {text}
          </Box>
        </Box>
        <Box
          fontWeight={theme.typography.fontWeightBold}
          fontSize={theme.typography.pxToRem(12)}
          style={{ textTransform: 'uppercase', wordBreak: 'break-word' }}
          flex={1}
        >
          {value}
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Title variant={Title.variants.t4}>Job details</Title>
      <Box
        bgcolor={theme.palette.grey[100]}
        borderRadius={theme.shape.borderRadius}
        padding={2}
      >
        <Box
          display="grid"
          style={{ gridTemplateColumns: mq.md ? `1fr 1fr` : '1fr' }}
        >
          <Box display="flex" flexDirection="column" mr={4}>
            <Item
              icon="asterisk"
              text="Role"
              value={
                <RolePicker
                  role={role}
                  onRoleChange={onRoleChange}
                  lock={!isEdit}
                />
              }
            />
            <Item
              icon="user-crown"
              text="Seniority"
              value={
                <SeniorityPicker
                  seniority={seniority}
                  onSeniorityChange={onSeniorityChange}
                  lock={!isEdit}
                />
              }
            />
            <Item
              icon="address-card"
              text="Job Type"
              value={
                <JobTypePicker
                  type={type}
                  onTypeChange={onTypeChange}
                  lock={!isEdit}
                />
              }
            />
            <Item
              icon="users"
              text="Team Size"
              value={
                <Select
                  options={buildSelectOpts(teamSizes, teamSize)}
                  onChange={onTeamSizeChange}
                  lock={!isEdit}
                />
              }
            />
            <Item
              icon="chart-line"
              text="Stock Options"
              value={
                <Select
                  options={buildSelectOpts(
                    { yes: 'Yes', no: 'No' },
                    stockOptions
                  )}
                  onChange={onStockOptionsChange}
                  lock={!isEdit}
                />
              }
            />

            {isEdit ? (
              <Item
                icon="money-bill-wave"
                text="Salary currency"
                value={
                  <SalaryCurrencyPicker
                    salaryCurrency={salaryCurrency}
                    lock={!isEdit}
                    onSalaryCurrencyChange={onSalaryCurrencyChange}
                  />
                }
                last={mq.md}
              />
            ) : null}
            {!isEdit ? (
              <Box
                display="flex"
                alignItems="center"
                marginBottom={mq.md ? 0 : 2}
              >
                <Item
                  icon="money-bill-wave"
                  text="Monthly gross salary"
                  value={
                    <SalaryRangeInput
                      salary={salary}
                      salaryCurrency={salaryCurrency}
                      lock={!isEdit}
                      onSalaryChange={onSalaryChange}
                    />
                  }
                  last
                />
              </Box>
            ) : null}
          </Box>
          <Box display="flex" flexDirection="column">
            <Item
              icon="map"
              text="Location"
              value={
                <LocationPicker
                  location={location}
                  locations={locations}
                  lock={!isEdit}
                  onLocationChange={onLocationChange}
                />
              }
            />
            <Item
              icon="home-heart"
              text="Home office (days/week)"
              value={
                <Select
                  options={buildSelectOpts(
                    { '0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5 },
                    fullyRemote === 'yes' ? '*' : homeOffice.toString()
                  )}
                  onChange={onHomeOfficeChange}
                  lock={!isEdit}
                />
              }
            />
            <Item
              icon="headphones-alt"
              text="Fully remote"
              value={
                <Select
                  options={buildSelectOpts(
                    { yes: 'Yes', no: 'No' },
                    fullyRemote
                  )}
                  onChange={onFullyRemoteChange}
                  lock={!isEdit}
                />
              }
            />
            <Item
              icon="user-headset"
              text="Customer facing"
              value={
                <Select
                  options={buildSelectOpts(
                    { yes: 'Yes', no: 'No' },
                    customerFacing
                  )}
                  onChange={onCustomerFacingChange}
                  lock={!isEdit}
                />
              }
            />
            <Item
              icon="suitcase"
              text="Business travel"
              value={
                <Select
                  options={buildSelectOpts(
                    { yes: 'Yes', no: 'No' },
                    businessTravel
                  )}
                  onChange={onBusinessTravelChange}
                  lock={!isEdit}
                />
              }
              last={!isEdit}
            />

            {isEdit ? (
              <Box display="flex" alignItems="center">
                <Item
                  icon="money-bill-wave"
                  text="Monthly gross salary"
                  value={
                    <SalaryRangeInput
                      salary={salary}
                      salaryCurrency={salaryCurrency}
                      lock={!isEdit}
                      onSalaryChange={onSalaryChange}
                    />
                  }
                  last
                />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const useSalaryRangeInputStyles = makeStyles((theme) => ({
  inputsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  inputLeft: {
    borderTopLeftRadius: theme.borderRadius,
    borderBottomLeftRadius: theme.borderRadius,
    marginRight: -1,
  },
  inputRight: {
    borderTopRightRadius: theme.borderRadius,
    borderBottomRightRadius: theme.borderRadius,
  },
  input: {
    background: 'transparent',
    padding: theme.spacing(0.8),
    border: `1px solid ${theme.palette.edit.main}`,
    flex: 1,
    maxWidth: 90,
  },
}));
type SalaryRangeInputProps = {
  salary: string,
  onSalaryChange?: (string) => void,
  salaryCurrency: string,
  lock: boolean,
};

function SalaryRangeInput({
  salary,
  onSalaryChange,
  salaryCurrency,
  lock,
}: SalaryRangeInputProps) {
  function getMinMaxFromSalaryString(salaryString: string) {
    if (!salaryString) return { min: 0, max: 0 };
    if (salaryString.includes('-')) {
      const [min, max] = salaryString.split('-').map((n) => Number(n));
      return { min, max };
    }
    if (salaryString.startsWith('>')) {
      return { min: Number(salaryString.replace('>', '')), max: 0 }
    }
    if (salaryString.startsWith('<')) {
      return { min: 0, max: Number(salaryString.replace('<', '')) }
    }
    return { min: 0, max: 0 };
  }
  const classes = useSalaryRangeInputStyles();
  const extractedRange = getMinMaxFromSalaryString(salary);
  const [range, setRange] = useState({
    min: extractedRange.min || '',
    max: extractedRange.max || '',
  });

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setRange((prev) => ({ ...prev, [name]: value }));
  };

  const onBlur = () => {
    if (typeof onSalaryChange === 'function')
      onSalaryChange(`${range.min}-${range.max}`);
  };

  if (lock) {
    if (Number(range.min) === 0 && Number(range.max) === 0) {
      return <span>&mdash;</span>;
    }

    return (
      <span>
        {range.min} - {range.max} {salaryCurrency}
      </span>
    );
  }

  return (
    <div className={classes.inputsContainer}>
      <input
        className={[classes.input, classes.inputLeft].join(' ')}
        type="number"
        name="min"
        value={range.min}
        onChange={onChange}
        placeholder="Min"
        onBlur={onBlur}
      />
      <input
        className={[classes.input, classes.inputRight].join(' ')}
        type="number"
        name="max"
        value={range.max}
        onChange={onChange}
        placeholder="Max"
        onBlur={onBlur}
      />
    </div>
  );
}

type JobDescriptionProps = {
  value: string,
  onChange?: (newValue: string) => void,
};
export function JobDescription({
  isEdit,
  value,
  onChange,
}: JobDescriptionProps & Editable) {
  const theme = useTheme();
  const updateVal = (v: string) => {
    if (typeof onChange === 'function') onChange(v);
  };

  return (
    <Box color={txtColor(theme, isEdit)}>
      <Title variant={Title.variants.t4}>Description</Title>
      {isEdit ? (
        <RichEditor value={value} onChange={updateVal} />
      ) : (
        <Box
          style={{
            fontSize: theme.typography.pxToRem(16),
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: value }} />
        </Box>
      )}
    </Box>
  );
}

export function CompanyInfo({
  isEdit,
  logo,
  companyName,
  link,
}: {
  logo?: string,
  companyName?: string | null,
  link?: string | null,
} & Editable) {
  const theme = useTheme();
  const mq = useMQ();
  let buttonText = 'View';
  let logoFlexDir = 'column';
  if (mq.xl) {
    buttonText = 'View Full Profile';
    logoFlexDir = 'row';
  }
  return (
    <Box style={{ opacity: isEdit ? 0.5 : 1 }}>
      <Line />
      <Box
        my={4}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection={logoFlexDir}
          alignItems={logoFlexDir === 'row' ? 'center' : 'flex-start'}
        >
          {logo ? (
            <Box mr={4}>
              <img
                src={logo}
                alt="Company logo"
                style={{ maxHeight: '50px' }}
              />
            </Box>
          ) : null}
          <Box color={theme.palette.grey[GREY_TEXT]}>{companyName}</Box>
        </Box>
        {isEdit ? null : (
          <Link
            style={{ textDecoration: 'none' }}
            to={paths.companiesProfile
              .replace(':slug', link)
              .replace('/:jobSlug?', '')}
          >
            <Button variant="contained" color="default" type="button">
              {buttonText}
            </Button>
          </Link>
        )}
      </Box>
      <Line />
    </Box>
  );
}

type SelectModalOptions = { [key: string]: any };
type SelectModalProps = {
  value: string | null,
  options: SelectModalOptions,
  onSelect: (string) => void,
  onClose: () => void,
};
function SelectModal({ value, options, onSelect, onClose }: SelectModalProps) {
  const MAX_ITEMS_IN_LIST = 25;
  const c = useStyles();
  const theme = useTheme();
  const titleId = 'selectModal';
  const contentId = 'selectModalContent';
  const [showingInput, setShowingInput] = useState(false);
  const [filter, setFilter] = useState('');
  const [other, setOther] = useState('');
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    if (value === null) {
      setSelected(value);
      return;
    }

    if (options[value]) {
      setSelected(value);
    } else {
      setOther(value);
      setShowingInput(true);
    }
  }, [options, value]);

  const select = (o: string) => {
    if (selected === o) setSelected(null);
    else setSelected(o);

    setShowingInput(false);
    setOther('');
  };
  const submit = useCallback(() => {
    onSelect(selected || '');
    onClose();
  }, [onClose, onSelect, selected]);
  const updateFilter = ({ target }) => setFilter(target.value);
  const otherChange = ({ target }) => {
    setOther(target.value);
    const isInOptions = Object.keys(options).some(
      (i) => i === target.value.toLowerCase()
    );
    if (isInOptions) setSelected(target.value.toLowerCase());
    else setSelected(target.value);
  };

  // submit on enter
  useEffect(() => {
    function handleKeyup(e) {
      if (e.keyCode === 13 && (selected || other)) submit();
    }
    window.addEventListener('keyup', handleKeyup);
    return () => window.removeEventListener('keyup', handleKeyup);
  }, [selected, other, submit]);

  return (
    <Modal
      open
      onClose={onClose}
      titleId={titleId}
      contentId={contentId}
      withCloseButton={false}
    >
      <Box minWidth={400} maxWidth={500}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>Select Requirement</Box>

          <Box onClick={onClose} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={['fal', 'times']} size="2x" />
          </Box>
        </Box>
        <Box mt={2}>
          <input
            value={filter}
            type="text"
            className={c.input}
            placeholder="Search"
            name="filter"
            id="filter"
            style={{ color: theme.palette.grey[600] }}
            onChange={updateFilter}
          />
        </Box>
        <Box
          mt={2}
          display="flex"
          flexWrap="wrap"
          flexDirection="row"
          alignItems="center"
        >
          {Object.keys(options)
            .filter((o) => {
              if (filter === null) return o;
              if (o.toLowerCase().startsWith(filter.toLowerCase())) return o;
              return false;
            })
            .slice(0, MAX_ITEMS_IN_LIST)
            .map((o) => {
              return (
                <Box
                  key={o}
                  mr={1}
                  mb={1}
                  onClick={() => select(o)}
                  color={selected === o ? '#fff' : '#000'}
                  bgcolor={
                    selected === o
                      ? theme.palette.primary.main
                      : theme.palette.grey[200]
                  }
                  px={2}
                  py={1}
                  style={{ cursor: 'pointer' }}
                  borderRadius={20}
                >
                  {options[o]}
                </Box>
              );
            })}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* <Box mr={1}>
              <Button
                type="button"
                color={showingInput ? 'primary' : 'default'}
                variant="contained"
                disableElevation
                style={{ borderRadius: 50 }}
                onClick={() => {
                  setShowingInput(true);
                  setSelected(null);
                }}
              >
                Other
              </Button>
            </Box> */}
            {showingInput ? (
              <Box mr={2}>
                <input
                  value={other}
                  type="text"
                  className={c.input}
                  placeholder="E.g. Nim"
                  name="other"
                  id="other"
                  style={{ color: theme.palette.grey[600], width: '100%' }}
                  onChange={otherChange}
                />
              </Box>
            ) : null}
          </Box>
          <Button
            type="button"
            color="primary"
            variant="contained"
            disableElevation
            onClick={submit}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

type TechRequirementsProps = {
  primaryLanguage: string | null,
  primaryPlatformOrFramework: string | null,
  secondaryLanguage: string | null,
  secondaryPlatformOrFramework: string | null,
  mainDatabase: string | null,

  onPrimaryLanguageChange?: (string) => void,
  onPrimaryPlatformOrFrameworkChange?: (string) => void,
  onSecondaryLanguageChange?: (string) => void,
  onSecondaryPlatformOrFrameworkChange?: (string) => void,
  onMainDatabaseChange?: (string) => void,
};
export function TechRequirements({
  isEdit,
  primaryLanguage,
  primaryPlatformOrFramework,
  secondaryLanguage,
  secondaryPlatformOrFramework,
  mainDatabase,

  onPrimaryLanguageChange,
  onPrimaryPlatformOrFrameworkChange,
  onSecondaryLanguageChange,
  onSecondaryPlatformOrFrameworkChange,
  onMainDatabaseChange,
}: TechRequirementsProps & Editable) {
  const theme = useTheme();

  return (
    <Box>
      <Title variant={Title.variants.t4}>Tech Requirements</Title>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        style={{ gridGap: theme.spacing(2) }}
      >
        <RequirementItem
          isEdit={isEdit}
          label="Primary Language"
          value={primaryLanguage}
          options={programmingLanguages}
          onChange={onPrimaryLanguageChange}
        />
        <RequirementItem
          isEdit={isEdit}
          label="Primary Platform or Framework"
          value={primaryPlatformOrFramework}
          options={platformsAndFrameworks}
          onChange={onPrimaryPlatformOrFrameworkChange}
        />
        <RequirementItem
          isEdit={isEdit}
          label="Secondary Language"
          value={secondaryLanguage}
          options={programmingLanguages}
          onChange={onSecondaryLanguageChange}
        />
        <RequirementItem
          isEdit={isEdit}
          label="Secondary Platform or Framework"
          value={secondaryPlatformOrFramework}
          options={platformsAndFrameworks}
          onChange={onSecondaryPlatformOrFrameworkChange}
        />
        <RequirementItem
          isEdit={isEdit}
          label="Main Database"
          value={mainDatabase}
          options={databases}
          onChange={onMainDatabaseChange}
        />
      </Box>
    </Box>
  );
}

const useRequirementItemStyles = makeStyles((theme) => ({
  clear: {
    display: 'inline-block',
    cursor: 'pointer',
    fontSize: theme.typography.pxToRem(12),
    textDecoration: 'underline',

    '&:hover': {
      textDecoration: 'none',
    },
  },
}));
const RequirementItem = ({
  isEdit,
  label,
  value,
  options,
  onChange,
}: {
  label: string,
  value: string | null,
  options: { [key: string]: any },
  onChange?: (any) => void,
} & Editable) => {
  const classes = useRequirementItemStyles();
  const theme = useTheme();
  const [selected, setSelected] = useState(null);
  useEffect(() => setSelected(value), [value]);

  const [selecting, setSelecting] = useState(false);
  const open = () => setSelecting(true);
  const close = () => setSelecting(false);
  const select = (choice) => {
    setSelected(choice);
    if (typeof onChange === 'function') onChange(choice);
    close();
  };

  if (!isEdit && !selected) return null;

  return (
    <Box
      padding={2}
      bgcolor={theme.palette.grey[100]}
      borderRadius={theme.shape.borderRadius}
      display="flex"
      justifyContent="space-between"
    >
      <Box flex={2} mr={2}>
        <Box color={theme.palette.grey[700]}>{label}</Box>
        <Box
          onClick={isEdit ? open : null}
          color={
            selected ? theme.palette.common.black : theme.palette.grey[500]
          }
          style={{ cursor: isEdit ? 'pointer' : 'auto' }}
          fontWeight={theme.typography.fontWeightBold}
          fontSize={theme.typography.pxToRem(16)}
        >
          {selected
            ? options[selected]
              ? options[selected]
              : selected
            : isEdit
            ? 'Select'
            : null}
        </Box>
        {selected && isEdit ? (
          <div
            role="button"
            tabIndex={0}
            onKeyUp={() => {}}
            className={classes.clear}
            onClick={() => select(null)}
          >
            Clear
          </div>
        ) : null}
      </Box>
      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        {isEdit ? (
          <Button
            type="button"
            color="edit"
            variant="contained"
            disableElevation
            onClick={open}
          >
            <FontAwesomeIcon icon={['fal', 'list']} />
            <Box display="inline-block" ml={1}>
              Select
            </Box>
          </Button>
        ) : null}
        {selecting ? (
          <SelectModal
            value={selected}
            onSelect={select}
            onClose={close}
            options={options}
          />
        ) : null}
      </Box>
    </Box>
  );
};

type RequirementsResponsibilitiesProps = {
  coreRequirements: CoreRequirement[],
  responsibilities: Responsibility[],
  onCoreRequirementAdd?: (CoreRequirement) => void,
  onCoreRequirementRemove?: (CoreRequirement) => void,
  onCoreRequirementEdit?: (CoreRequirement) => void,
  onResponsibilityAdd?: (Responsibility) => void,
  onResponsibilityRemove?: (Responsibility) => void,
  onResponsibilityEdit?: (Responsibility) => void,
};
export function RequirementsResponsibilities({
  isEdit,
  coreRequirements,
  responsibilities,
  onCoreRequirementAdd,
  onCoreRequirementRemove,
  onCoreRequirementEdit,
  onResponsibilityAdd,
  onResponsibilityRemove,
  onResponsibilityEdit,
}: Editable & RequirementsResponsibilitiesProps) {
  const [addingReq, setAddingReq] = useState(false);
  const [addingResp, setAddingResp] = useState(false);
  const [newReq, setNewReq] = useState('');
  const [newResp, setNewResp] = useState('');
  const mq = useMQ();
  const theme = useTheme();
  let flexDir = 'column';
  if (mq.xl) flexDir = 'row';

  const startAddingReq = () => {
    setAddingReq(true);
    setAddingResp(false);
  };
  const startAddingResp = () => {
    setAddingResp(true);
    setAddingReq(false);
  };

  const submitReq = useCallback(
    (e) => {
      e.preventDefault();
      if (!newReq.length) return;
      if (onCoreRequirementAdd)
        onCoreRequirementAdd({ id: Math.random(), value: newReq });
      setNewReq('');
    },
    [onCoreRequirementAdd, setNewReq, newReq]
  );
  const submitResp = useCallback(
    (e) => {
      e.preventDefault();
      if (!newResp.length) return;
      if (onResponsibilityAdd)
        onResponsibilityAdd({ id: Math.random(), value: newResp });
      setNewResp('');
    },
    [setNewResp, onResponsibilityAdd, newResp]
  );

  return (
    <Box color={txtColor(theme, isEdit)}>
      <Box flexDirection={flexDir} display="flex">
        <Box
          flex={1}
          mr={flexDir === 'row' ? 2 : 0}
          mb={flexDir === 'column' ? 2 : 0}
        >
          <Title variant={Title.variants.t4}>Requirements</Title>
          <List
            isEdit={isEdit}
            items={coreRequirements}
            onItemRemove={onCoreRequirementRemove}
            onItemEdit={onCoreRequirementEdit}
          />
          {isEdit && !coreRequirements.length ? (
            <Box>
              List the top 3-5 non-technical requirements for this position
            </Box>
          ) : null}
          {isEdit && !addingReq ? (
            <Box mt={1}>
              <Button
                variant="contained"
                color="edit"
                type="button"
                disableElevation
                onClick={startAddingReq}
              >
                Add Core Requirement
              </Button>
            </Box>
          ) : null}
          {addingReq && isEdit ? (
            <ListForm
              value={newReq}
              onChange={({ target }) => setNewReq(target.value)}
              onCancel={() => setAddingReq(false)}
              onSubmit={submitReq}
            />
          ) : null}
        </Box>
        <Box flex={1}>
          <Title variant={Title.variants.t4}>Responsibilities</Title>
          <List
            isEdit={isEdit}
            items={responsibilities}
            onItemRemove={onResponsibilityRemove}
            onItemEdit={onResponsibilityEdit}
          />
          {isEdit && !responsibilities.length ? (
            <Box>List the top 3-5 responsibilities for this position</Box>
          ) : null}
          {isEdit && !addingResp ? (
            <Box mt={1}>
              <Button
                variant="contained"
                color="edit"
                type="button"
                disableElevation
                onClick={startAddingResp}
              >
                Add Responsibility
              </Button>
            </Box>
          ) : null}
          {addingResp && isEdit ? (
            <ListForm
              value={newResp}
              onChange={({ target }) => setNewResp(target.value)}
              onCancel={() => setAddingResp(false)}
              onSubmit={submitResp}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}

function List({
  isEdit,
  items,
  onItemRemove,
  onItemEdit,
}: {
  items: Array<CoreRequirement | Responsibility>,
  onItemRemove?: (item: CoreRequirement | Responsibility) => void,
  onItemEdit?: (item: CoreRequirement | Responsibility) => void,
} & Editable) {
  const c = useStyles();
  const theme = useTheme();
  const [editingValue, setEditingValue] = useState('');
  const [editingItem, setEditingItem] = useState<
    CoreRequirement | Responsibility | null
  >(null);

  useEffect(() => {
    if (editingItem) {
      setEditingValue(editingItem.value);
    }
  }, [editingItem]);

  const update = (item: CoreRequirement | Responsibility) => {
    if (onItemEdit) onItemEdit({ ...item, value: editingValue });
    setEditingValue('');
    setEditingItem(null);
  };

  return (
    <ul
      style={{
        paddingLeft: 20,
        position: 'relative',
        marginTop: isEdit ? 0 : -5,
      }}
    >
      {items.map((item, i) => (
        <UnderCoverContainer key={item.id} uncover={`ListItem--${i}`}>
          <li
            style={{
              position: 'relative',
              width: '100%',
              padding: isEdit ? '10px 5px' : 5,
              borderRadius: theme.borderRadius,
              background:
                editingItem && item.id === editingItem.id
                  ? theme.palette.grey[100]
                  : undefined,
            }}
            className={isEdit ? c.listItem : null}
          >
            {editingItem && editingItem.id === item.id ? (
              <ListForm
                value={editingValue}
                onChange={({ target }) => setEditingValue(target.value)}
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!editingValue.length) return;
                  update(item);
                }}
                onCancel={() => setEditingItem(null)}
                submitButtonLabel="Update"
              />
            ) : (
              <span>{item.value}</span>
            )}
            <UnderCover uncoveredBy={`ListItem--${i}`}>
              <Box
                color={theme.palette.common.black}
                position="absolute"
                top="50%"
                right={10}
                style={{ transform: 'translateY(-50%)' }}
              >
                {isEdit && (!editingItem || editingItem.id !== item.id) ? (
                  <EditControls
                    showMoveIcon={false}
                    onRemove={() => onItemRemove && onItemRemove(item)}
                    onEdit={() => setEditingItem(item)}
                    size={[30, 30]}
                  />
                ) : null}
              </Box>
            </UnderCover>
          </li>
        </UnderCoverContainer>
      ))}
    </ul>
  );
}

function ListForm({
  value,
  onChange,
  onSubmit,
  onCancel,
  submitButtonLabel = 'Add',
}: {
  value: any,
  onChange: (any) => void,
  onSubmit: (e: any) => void,
  onCancel: () => void,
  submitButtonLabel?: string,
}) {
  const c = useStyles();
  const formRef = useRef(null);
  const theme = useTheme();
  const onKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit'));
      e.preventDefault();
    }
  };

  return (
    <form onSubmit={onSubmit} ref={formRef}>
      <Box mt={1} mr={1}>
        <Box>
          <TextareaAutosize
            style={{ width: '100%', color: txtColor(theme, true) }}
            className={c.textarea}
            minRows={2}
            name="newVal"
            value={value}
            onChange={onChange}
            onKeyDown={onKeyUp}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Button
            type="button"
            variant="text"
            color="default"
            disableElevation
            onClick={onCancel}
            size="small"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="edit"
            disableElevation
            size="small"
          >
            {submitButtonLabel}
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export function Perks({
  items,
  isEdit,
  onItemAdd,
  onItemEdit,
  onItemRemove,
}: {
  items: Perk[],
  onItemAdd?: (Perk) => void,
  onItemEdit?: (Perk) => void,
  onItemRemove?: (Perk) => void,
} & Editable) {
  const theme = useTheme();
  const [adding, setAdding] = useState(false);
  const [newPerk, setNewPerk] = useState('');
  const onSubmit = (e: any) => {
    e.preventDefault();
    if (typeof onItemAdd === 'function')
      onItemAdd({ id: Math.random(), value: newPerk });
    setNewPerk('');
  };

  return (
    <Box>
      <Box color={txtColor(theme, isEdit)}>
        <Title variant={Title.variants.t4}>Benefits & perks</Title>
        <List
          isEdit={isEdit}
          items={items}
          onItemRemove={onItemRemove}
          onItemEdit={onItemEdit}
        />

        {isEdit && !items.length ? (
          <Box>List the benefits and perks for this position.</Box>
        ) : null}
        {isEdit && !adding ? (
          <Box mt={1}>
            <Button
              variant="contained"
              color="edit"
              type="button"
              disableElevation
              onClick={() => setAdding(true)}
            >
              Add
            </Button>
          </Box>
        ) : null}
        {adding && isEdit ? (
          <ListForm
            value={newPerk}
            onChange={({ target }) => setNewPerk(target.value)}
            onCancel={() => setAdding(false)}
            onSubmit={onSubmit}
          />
        ) : null}

        {/* <Box>
          <ul style={{ paddingLeft: 20 }}>
            <li>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </li>
            <li>
              Lorem Ipsum has been the industrys standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled
            </li>
            <li>
              Many desktop publishing packages and web page editors now use
            </li>
            <li>
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose (injected humour and the like).
            </li>
            <li>It uses a dictionary of over 200 Latin words</li>
          </ul>
        </Box> */}
      </Box>
    </Box>
  );
}

function ApplyControls({
  jobAdId,
  jobAdTitle,
  jobAdSeniority,
}: {
  jobAdId?: number,
  jobAdTitle: string,
  jobAdSeniority: string,
}) {
  const [width, setWidth] = useState(null);
  const { track, events: trackingEvents } = useTracking();
  const { startApplication } = useApply();

  const { open: openMenu } = useContext(TopMenuContext);

  useEffect(() => {
    // detect the width of the job ad page (sliding from the right) [w]
    // and set the width of ApplyControls be the same as [w].
    const getJobAdPageWidth = () => {
      const jobAdPage = window.document.body.querySelector('.jobad-page');
      if (!jobAdPage) {
        // eslint-disable-next-line
        console.log("Couldn't determine the jobad page width.");
        return null;
      }
      return jobAdPage.getBoundingClientRect().width;
    };
    const updateWidth = () => setWidth(getJobAdPageWidth());

    window.addEventListener('resize', updateWidth);

    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const onReferClick = useCallback(
    (e: SyntheticEvent<any>) => {
      e.preventDefault();
      e.stopPropagation();
      // dispatch(setPendingComposeDirectReferralStatus(true));

      const url = window.location.href;
      track(trackingEvents.REFER_CANDIDATE_FROM_JOB_AD_BUTTON_CLICK, { url });
      openMenu(SideMenuTypes.referrals);
    },
    [openMenu, track, trackingEvents.REFER_CANDIDATE_FROM_JOB_AD_BUTTON_CLICK]
  );

  if (!width || !jobAdId) return null;

  return (
    <Box
      position="fixed"
      bottom={10}
      right={30}
      px={2}
      py={2}
      width={width - 60}
      style={{ background: 'rgba(80, 0, 170, 0.4)', borderRadius: 10 }}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <span
          style={{
            textDecoration: 'none',
            display: 'block',
            width: '100%',
            marginRight: 10,
          }}
        >
          <Button
            onClick={() =>
              startApplication(jobAdId, jobAdTitle, jobAdSeniority)
            }
            type="button"
            variant="contained"
            color="primary"
            fullWidth
          >
            Apply
          </Button>
        </span>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={onReferClick}
          fullWidth
        >
          Refer
        </Button>
      </Box>
    </Box>
  );
}
export function ControlsBar({
  isEdit,
  onScrollTop,
}: { onScrollTop: () => void } & Editable) {
  // const theme = useTheme();
  const theme = useTheme();
  const loc = useLocation();
  const link = `https://noblehire.io${loc.pathname}`;
  return (
    <Box my={4} display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        {isEdit ? (
          <span style={{ color: theme.palette.grey[500] }}>
            Share buttons will appear here automatically
          </span>
        ) : null}
        {!isEdit ? (
          <Fragment>
            <Title
              variant={Title.variants.t4}
              style={{ marginBottom: 0, marginRight: theme.spacing(2) }}
            >
              Share
            </Title>
            <a
              href={`https://www.facebook.com/share.php?u=${link}`}
              target="_blank"
              rel="noreferrer noopener"
              style={{ marginRight: 10 }}
            >
              <FontAwesomeIcon icon={['fab', 'facebook']} size="2x" />
            </a>
            <a
              href={`https://www.linkedin.com/cws/share?url=${encodeURIComponent(
                link
              )}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <FontAwesomeIcon icon={['fab', 'linkedin']} size="2x" />
            </a>
          </Fragment>
        ) : null}
      </Box>
      {/* <Link to="/">Share</Link> */}
      <Box onClick={onScrollTop} style={{ cursor: 'pointer' }}>
        <FontAwesomeIcon icon={['fal', 'chevron-up']} size="2x" />
      </Box>
    </Box>
  );
}

// ---------------------------------------------------------- //
// util
function Line() {
  const theme = useTheme();
  return (
    <div
      style={{
        height: '1px',
        width: '100%',
        background: theme.palette.grey[300],
      }}
    />
  );
}
// ---------------------------------------------------------- //
// ---------------------------------------------------------- //
