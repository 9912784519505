// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStateContainer } from '../../../core/context/StateContainer';

type Props = {
  username?: ?string,
  name?: ?string,
  inline?: boolean,
  onClick?: ?() => void,
};

const useStyles = makeStyles((theme) => ({
  root: ({ inline, onClick }) => ({
    maxWidth: 230,
    display: 'flex',
    alignItems: 'center',
    marginBottom: inline ? 0 : theme.spacing(3),
    cursor: onClick ? 'pointer' : 'initial',
    outline: 'none',
    fontSize: theme.typography.pxToRem(12),

    '@media (max-width: 768px)': {
      flexDirection: 'row',
    },
  }),
  icon: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(2),
    borderRadius: 50,
    height: 40,
    minHeight: 40,
    width: 40,
    minWidth: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: `40px`,
    textAlign: 'center',
    background: theme.palette.grey[200],
    color: theme.palette.grey.dark,
    userSelect: 'none',
  },
  animating: {
    animationName: '$anim',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },
  '@keyframes anim': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0.4, transform: 'scale(1.1)' },
    '100%': { opacity: 1 },
  },
  name: {
    color: theme.palette.grey.dark,
    wordBreak: 'break-all',
    '@media (max-width: 768px)': {
      color: theme.palette.grey.dark,
    },
  },
}));

function Profile({ username, name, inline, onClick }: Props) {
  const [
    {
      auth: {
        gettingUser: { loading },
      },
    },
  ] = useStateContainer();
  const classes = useStyles({ inline, onClick });
  const UnknownUsernameIcon = () => <FontAwesomeIcon icon={['fal', 'user']} />;

  return (
    <div
      className={classes.root}
      onClick={onClick}
      role="button"
      tabIndex={-1}
      onKeyDown={null}
    >
      <div className={`${classes.icon} ${loading ? classes.animating : ''}`}>
        {username || <UnknownUsernameIcon />}
      </div>
      {name ? <div className={classes.name}>{name}</div> : null}
    </div>
  );
}

Profile.defaultProps = {
  username: null,
  name: null,
  inline: false,
  onClick: null,
};

export { Profile };
