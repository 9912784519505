// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';

const useFilterStyles = makeStyles((theme) => ({
  filter: {
    marginRight: theme.spacing(1),
    '@media (max-width: 840px)': {
      width: '100%',
      marginRight: 0,
    },
    '& > div': {
      position: 'relative',
      width: '100%',
    },
    '& select': {
      background: `url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjI2MzA2IDYuNzU4NjlMMC44MTI2NTkgMi4zMDgyN0MwLjU5ODAyIDIuMDkzNjMgMC41OTgwMiAxLjc0NTY0IDAuODEyNjU5IDEuNTMxMDNMMS4zMzE3MyAxLjAxMTk2QzEuNTQ2IDAuNzk3Njg2IDEuODkzMjcgMC43OTcyNzQgMi4xMDgwNSAxLjAxMTA0TDUuNjUxNjkgNC41MzgwOEw5LjE5NTMxIDEuMDExMDRDOS40MTAwOSAwLjc5NzI3NCA5Ljc1NzM2IDAuNzk3Njg2IDkuOTcxNjMgMS4wMTE5NkwxMC40OTA3IDEuNTMxMDNDMTAuNzA1MyAxLjc0NTY3IDEwLjcwNTMgMi4wOTM2NSAxMC40OTA3IDIuMzA4MjdMNi4wNDAzMiA2Ljc1ODY5QzUuODI1NjggNi45NzMzMSA1LjQ3NzcgNi45NzMzMSA1LjI2MzA2IDYuNzU4NjlaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K) no-repeat 95% 50%`,
      '-moz-appearance': 'none',
      '-webkit-appearance': 'none',
      appearance: 'none',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(12),
      transition: `all 0.3s`,
      cursor: 'pointer',
      outline: 'none',
      borderRadius: 50,
      height: 30,
      minWidth: 125,
      maxWidth: 130,
      padding: theme.spacing(0, 4, 0, 2),
      backgroundColor: theme.palette.grey[300],
      border: `2px solid transparent`,

      '&:hover': {
        borderColor: theme.palette.primary.main,
      },

      '@media(max-width: 840px)': {
        width: '100%',
        marginRight: 0,
        minWidth: 'initial',
        maxWidth: 'initial',
      },
    },
    '& select:focus': {
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: `0 4px 25px -2px ${theme.palette.primary.light}`,
    },
    '& span': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: theme.spacing(2),
      '@media(max-width: 840px)': {
        marginBottom: theme.spacing(2),
      },
    },
  },
  filterActive: {
    '& select': {
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: `0 4px 25px -2px ${theme.palette.primary.light}`,
    },
  },
}));
export const Filter = ({
  children,
  value,
  name,
  onChange,
}: {
  children: any,
  value: string,
  name: string,
  onChange: (e: SyntheticInputEvent<HTMLSelectElement>) => void,
}) => {
  const classes = useFilterStyles();

  return (
    <div
      className={cn({
        [classes.filter]: true,
        [classes.filterActive]: value !== '',
      })}
    >
      <div>
        <select onChange={onChange} name={name} value={value}>
          {children}
        </select>
      </div>
    </div>
  );
};
