// @flow
/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CompanyCard } from './CompanyCard';
import type { CompanyType } from './types';
import { Box, makeStyles } from '@material-ui/core';
import { Button } from '../../basic/Button';
import { useCompaniesList } from './useCompaniesList';
import {
  parseSearchQuery,
  insertParam,
  appendToSearchQuery,
} from '../../../utils/searchUtils';
import type { FilterType } from '../../../utils/searchUtils';
import { paths } from '../../../core/constants';
import { NoItemsFound } from '../../basic/NoItemsFound';
import { Loader } from '../../basic/Loader';
import { Filter } from '../../job-board/Filter';
import { SearchBar } from '../../basic/SearchBar';

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2) },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: theme.spacing(2),

    '@media (max-width: 980px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@media (max-width: 580px)': {
      gridTemplateColumns: '1fr',
    },
  },
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),

    '@media(max-width: 840px)': {
      flexDirection: 'column',
    },
  },
}));

export type FiltersState = {
  [FilterType]: string,
};

const initialFilters = {
  location: '',
};

export function CompaniesList() {
  const loc = useLocation();
  const classes = useStyles();
  const history = useHistory();

  const { data, load, loading, loadMore, loadingMore } = useCompaniesList();
  const [init, setInit] = useState(false);
  const [text, setText] = useState('');
  const [triggerSearch, setTriggerSearch] = useState<boolean>(false);
  const [filters, setFilters] = useState<FiltersState>(initialFilters);
  const [currentQuery, setCurrentQuery] = useState(loc.search);

  useEffect(() => {
    const queryData = parseSearchQuery();
    if (!queryData) {
      // search reset
      setText('');
      setCurrentQuery('');
      setFilters(initialFilters);
    } else {
      // update state
      Object.keys(queryData).forEach((key) => {
        const val = queryData[key];
        switch (key) {
          case 'text':
            setText(val);
            break;
          case 'location':
            setFilters((prev) => ({ ...prev, [key]: val }));
            break;
        }
      });
    }

    setTriggerSearch(true);
  }, [loc.search]);

  useEffect(() => {
    if (triggerSearch && !loading) {
      const search = currentQuery.startsWith('?')
        ? currentQuery
        : `?${currentQuery}`;

      load(search);
      setTriggerSearch(false);
    }
    setInit(true);
  }, [triggerSearch, filters, currentQuery, load, loading]);

  function onCompanyClick(company: CompanyType) {
    // eslint-disable-next-line
    // console.log('company clicked', company);
    history.push(`/${company.slug || ''}`);
  }

  const updateQuery = (paramName: 'search' | 'page', val: string | number) => {
    // updates the &search={..} parth of the query string
    const newQueryStr = insertParam(paramName, val);
    history.push({ pathname: paths.companies, search: newQueryStr });
    setCurrentQuery(newQueryStr);
  };

  const onSearch = (value: string) => {
    const newSearchStr = appendToSearchQuery({
      type: 'text',
      val: value.trim(),
    });
    updateQuery('search', newSearchStr);
  };

  const onFilterChange = (filter: FilterType, newValue: string) => {
    const newSearchStr = appendToSearchQuery({
      type: filter,
      val: newValue,
    });
    updateQuery('search', newSearchStr);
    setFilters((prev) => ({ ...prev, [filter]: newValue }));
  };

  const onLoadMoreClick = () => {
    const search = currentQuery.startsWith('?')
      ? currentQuery
      : `?${currentQuery}`;

    loadMore(search);
  };

  useEffect(() => {
    load();
  }, [load]);

  return (
    <div className={classes.root}>
      <SearchBar
        value={text}
        placeholder="Search companies..."
        filters={
          <Filter
            name="location"
            value={filters.location}
            onChange={(e: any) => {
              const { name } = e.currentTarget;
              const { value } = e.currentTarget;
              onFilterChange((name: any), value);
            }}
          >
            <option value="">Location</option>
            <option value="remote">Remote</option>
            <option value="sofia">Sofia</option>
            <option value="plovdiv">Plovdiv</option>
            <option value="varna">Varna</option>
            <option value="burgas">Burgas</option>
            <option value="veliko-tarnovo">Veliko Tarnovo</option>
            <option value="ruse">Ruse</option>
          </Filter>
        }
        onChange={(value) => setText(value)}
        onSearch={onSearch}
      />

      {loading || triggerSearch ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Loader containerParams={{ className: classes.loaderContainer }} />
        </Box>
      ) : (
        <>
          {data.elements.length === 0 && init && !loading && !loadingMore ? (
            <NoItemsFound
              msg="We couldn't find any companies matching your query."
              link={paths.companies}
              linkText="See all companies"
            />
          ) : null}
          <div className={classes.container}>
            {data.elements.map((company, idx) => {
              return (
                <CompanyCard
                  key={`${company.id || 'company'}__${idx}`}
                  data={company}
                  onClick={() => onCompanyClick(company)}
                />
              );
            })}
          </div>
          {(data.page || 0) + 1 < (data.totalPages || 1) ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginTop={10}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={onLoadMoreClick}
                disabled={loadingMore}
                disableElevation
              >
                {loadingMore ? 'Loading...' : 'Load more'}
              </Button>
            </Box>
          ) : null}
        </>
      )}
    </div>
  );
}
