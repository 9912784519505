// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { OnboardingModalFrame } from '../OnboardingModalFrame';
import { useTracking } from '../../../tracking/TrackingContext';
import { externalPaths } from '../../../../core/constants';

type Props = {
  directApplication?: boolean,
  jobAdReferenceKey?: ?string,
};

const { useEffect } = React;

const useStyles = makeStyles((theme) => ({
  title: { fontWeight: 400, maxWidth: 650, color: theme.palette.common.black },
  faded: {
    color: theme.palette.grey.dark,
  },
  center: {
    textAlign: 'center',
  },
  content: {
    margin: 'auto',
    maxWidth: 500,
  },
}));

function OnboardingSuccess({
  directApplication = false,
  jobAdReferenceKey,
}: Props) {
  const { track, events } = useTracking();
  const classes = useStyles();

  useEffect(() => {
    if (directApplication) {
      track(events.DIRECT_APPLICATION, {
        position: jobAdReferenceKey,
      });
    } else {
      track(events.BLANK_APPLICATION);
    }
  }, [
    events.BLANK_APPLICATION,
    events.DIRECT_APPLICATION,
    jobAdReferenceKey,
    directApplication,
    track,
  ]);

  const title = directApplication ? (
    <h2 className={classes.title}>Thank you for applying!</h2>
  ) : (
    <h2 className={classes.title}>Thank you for letting us know you better!</h2>
  );

  const content = directApplication ? (
    <span>
      The company will review your application and get back to you with an
      answer.
    </span>
  ) : (
    <span>
      While you&apos;re here, you can take a look at our&nbsp;
      <a href={externalPaths.jobPositions}>job board</a>
      &nbsp;and apply to jobs that you&apos;re interested in.
    </span>
  );

  return (
    <OnboardingModalFrame
      titleId="onboardingSuccessTitle"
      contentId="onboardingSuccessContent"
      title={title}
      icon="badge-check"
      buttonText="Done"
      heading="Success"
    >
      <div className={classes.content}>
        <p className={`${classes.faded} ${classes.center}`}>{content}</p>
      </div>
    </OnboardingModalFrame>
  );
}

OnboardingSuccess.defaultProps = {
  directApplication: false,
  jobAdReferenceKey: null,
};

export { OnboardingSuccess };
