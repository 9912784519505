// @flow
import * as React from 'react';

type Props = {
  children: React.Node,
};

const { useEffect } = React;

function DisableScrollOnMount({ children }: Props) {
  useEffect(() => {
    if (document.body) {
      document.body.classList.add('no-scroll');
    }

    return () => {
      if (document.body) {
        document.body.classList.remove('no-scroll');
      }
    };
  }, []);

  return children;
}

export { DisableScrollOnMount };
