// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  children: React.Node,
  heading?: React.Node,
  subhead?: React.Node,
  buttons: React.Node,
};

const useStyles = makeStyles((theme) => ({
  heading: ({ subhead }) => ({
    fontSize: '21px',
    fontWeight: '400',
    marginBottom: subhead ? theme.spacing(1) : theme.spacing(5),
  }),
  subhead: {
    color: theme.palette.grey.main,
    marginBottom: theme.spacing(5),
  },
  content: {
    width: 750,
    textAlign: 'center',
    margin: theme.spacing(0, 1),
    // paddingBottom: 85,

    '@media (max-width: 768px)': {
      width: '100%',
      margin: 'auto',
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      overflow: 'auto',
    },
  },
  buttonsWrapper: {
    '@media (max-width: 768px)': {
      width: '100%',
      padding: theme.spacing(2, 0),
      textAlign: 'center',
      borderTop: `1px solid ${theme.palette.grey.light}`,
    },
  },
}));

function Step({ children, heading, subhead, buttons }: Props) {
  const classes = useStyles({ subhead });

  return (
    <>
      <div className={classes.content}>
        {heading ? <h3 className={classes.heading}>{heading}</h3> : null}
        {subhead ? <div className={classes.subhead}>{subhead}</div> : null}
        {children}
      </div>
      <div className={classes.buttonsWrapper}>{buttons}</div>
    </>
  );
}

Step.defaultProps = {
  heading: null,
  subhead: null,
};

export { Step };
