// @flow
import * as React from 'react';
import { RegistrationForm } from '../components/auth/talents/components/RegistrationForm';
import { AuthFrame } from '../components/auth/talents/components/AuthFrame';
import { DisableScrollOnMount } from '../components/basic/DisableScrollOnMount';
import { HideTopArrow } from '../components/wordpress/HideTopArrow';
import { PageHead } from '../components/basic/PageHead';

const Registration = () => {
  return (
    <DisableScrollOnMount>
      <HideTopArrow>
        <PageHead title="Create account | NobleHire" />
        <AuthFrame>
          <RegistrationForm />
        </AuthFrame>
      </HideTopArrow>
    </DisableScrollOnMount>
  );
};

export { Registration };
