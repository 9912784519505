// @flow
import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, FormHelperText, useTheme } from '@material-ui/core';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

type Props = {
  id: string,
  name: string,
  selectProps?: any,
  googlePlacesAutocompleteProps?: any,
};

export function PlacesAutocomplete({
  id,
  name,
  selectProps,
  googlePlacesAutocompleteProps,
}: Props) {
  const theme = useTheme();
  return (
    <Field
      id={id}
      name={name}
      render={({ input, meta }) => {
        const error = meta.touched && (meta.error || meta.submitError);
        return (
          <FormControl
            error={!!error}
            fullWidth
            style={{ marginBottom: theme.spacing(1) }}
          >
            <GooglePlacesAutocomplete
              selectProps={{
                placeholder: 'Start typing address...',
                styles: {
                  control: (provided, state) => ({
                    ...provided,
                    /* eslint-disable */
                    borderColor: state.isFocused
                      ? theme.palette.edit.main
                      : error
                      ? theme.palette.error.main
                      : theme.palette.grey[400],
                    /* eslint-enable */
                    boxShadow: 'none',
                    cursor: 'text',
                    '&:hover': {
                      /* eslint-disable */
                      borderColor: state.isFocused
                        ? theme.palette.edit.main
                        : error
                        ? theme.palette.error.main
                        : theme.palette.grey[400],
                      /* eslint-enable */
                    },
                    '&:active': {
                      borderColor: theme.palette.edit.main,
                    },
                  }),
                  dropdownIndicator: () => ({ display: 'none' }),
                  indicatorSeparator: () => ({ display: 'none' }),
                  input: (provided) => ({
                    ...provided,
                    paddingTop: 7,
                    paddingBottom: 7,
                    cursor: 'text',
                  }),
                },
                maxMenuHeight: 120,
                defaultInputValue: input.value,
                ...selectProps,
                ...input,
              }}
              {...googlePlacesAutocompleteProps}
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            />
            {error && <FormHelperText key={error}>{error}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
}
