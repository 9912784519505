// @flow
import React from 'react';
import type { Node } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  noItemsMsgContainer: {
    border: `3px dashed ${theme.palette.primary.light}`,
    borderRadius: theme.borderRadius * 2,
    minHeight: 250,
    display: 'flex',
    margin: `0 auto`,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(21),
    color: theme.palette.primary.main,
    padding: theme.spacing(4),

    '@media screen and (max-width: 600px)': {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  noItemsIconContainer: {
    color: theme.palette.primary.main,
    width: 100,
    height: 100,
    borderRadius: 100,
    minWidth: 100,
    minHeight: 100,
    backgroundColor: theme.palette.primary.lightest,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),

    '@media screen and (max-width: 600px)': {
      marginBottom: theme.spacing(2),
    },
  },
  noItemsMsg: {
    flex: 1,
    maxWidth: '28ch',
    '@media screen and (max-width: 600px)': {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  seeAllJobsLink: {
    '@media screen and (max-width: 600px)': {
      fontSize: theme.typography.pxToRem(18),
    },
  },
}));

type Props = {
  msg: Node,
  link: string,
  linkText: string,
};

export function NoItemsFound({ msg, link, linkText }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.noItemsMsgContainer}>
      <div className={classes.noItemsIconContainer}>
        <FontAwesomeIcon icon={['fal', 'ghost']} size="2x" />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span className={classes.noItemsMsg}>{msg}</span>
        <Link to={link} className={classes.seeAllJobsLink}>
          {linkText}
        </Link>
      </div>
    </div>
  );
}
