// @flow
import React from 'react';
import type { Node } from 'react';
import { makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import splashBg from '../../../../assets/images/splash-bg.svg';
import { externalPaths } from '../../../../core/constants';
import { useTracking } from '../../../tracking/TrackingContext';

const useStyles = makeStyles((theme) => ({
  box: {
    position: 'relative',
    background: theme.palette.primary.lightest,
    borderRadius: theme.borderRadius,
    padding: theme.spacing(4, 4),
    margin: theme.spacing(6, 0, 4, 0),
    alignItems: 'center',
  },
  boxTitle: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(1),
  },
  boxContent: {
    maxWidth: `75%`,

    '@media (max-width: 350px)': {
      maxWidth: '50%',
    },
  },
  boxSubtitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
  },
  boxRight: {
    position: 'absolute',
    top: '50%',
    transform: `translateY(-50%)`,
    right: theme.spacing(2),
  },
  iconContainer: {
    userSelect: 'none',
    position: 'relative',
    textAlign: 'center',
    height: 'fit-content',
    maxWidth: 85,
    maxHeight: 85,
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: 36,
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.main,
  },
  seeHowItWorks: {
    // fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(2),
    textDecoration: 'underline !important',
  },
}));

function InfoBox({ title, subtitle }: { title: Node, subtitle: Node }) {
  const { track, events } = useTracking();
  const classes = useStyles();
  return (
    <div className={classes.box}>
      <div className={classes.boxContent}>
        <div className={classes.boxTitle}>{title}</div>
        <div className={classes.boxSubtitle}>
          {subtitle}
          &nbsp;
          <a
            href={externalPaths.referrers}
            title="Referrers page"
            className={classes.seeHowItWorks}
            onClick={() => track(events.REFERRAL_SEE_HOW_IT_WORKS_CLICK)}
            target="_blank"
            rel="noopener noreferrer"
          >
            See how it works
          </a>
        </div>
      </div>
      <div className={classes.boxRight}>
        <div className={classes.iconContainer}>
          <img
            style={{ width: '100%', height: '100%' }}
            src={splashBg}
            alt="icon-background"
            draggable="false"
          />
          <FontAwesomeIcon
            icon={['fal', 'envelope']}
            className={classes.icon}
          />
        </div>
      </div>
    </div>
  );
}

export { InfoBox };
