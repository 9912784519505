// @flow
/* eslint-disable no-console */
import * as React from 'react';
import type { FormApi, FormValuesShape } from 'final-form/dist/types.js.flow';
import { buildValidateFunc } from '../../../../../utils/buildValidateFunc';
import type { ValidateFunc } from '../../../../../core/types';
import { validateConfig } from './validateConfig';
import { forgottenPassword as forgottenPasswordRequest } from '../../../authService';
import { useStateContainer } from '../../../../../core/context/StateContainer';
import { Success } from '../../../../modals/components/Success';
import { Error } from '../../../../modals/components/Error';
import { enqueueModal } from '../../../../modals/actions';

function useForgottenPasswordForm() {
  const [, dispatch] = useStateContainer();
  const validate: ValidateFunc = buildValidateFunc(
    validateConfig,
    (values, config) => config,
    0
  );

  async function onFormSubmit(
    { email }: { email: string },
    form: FormApi<FormValuesShape>
  ) {
    return forgottenPasswordRequest({ email })
      .then(() => {
        dispatch(
          enqueueModal(
            <Success>
              We&apos;ve sent you an email with further instructions. Now
              it&apos;s your turn ;)
            </Success>
          )
        );

        // we use setTimeout here, because react-final-form could reset the form only
        // when the onSubmit has fully resolved.
        // That's why we put the reset at the end of the queue, using setTimeout.
        // @see: https://github.com/final-form/final-form/issues/142
        setTimeout(form.reset);
      })
      .catch(() => {
        dispatch(
          enqueueModal(<Error>An error occurred. Please, try again.</Error>)
        );
      });
  }

  return {
    onFormSubmit,
    validate,
  };
}

export { useForgottenPasswordForm };
