// @flow
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import { Modal } from '../../../basic/Modal';
import { useStateContainer } from '../../../../core/context/StateContainer';
import { dequeueModal } from '../../../modals/actions';
import 'react-image-crop/dist/ReactCrop.css';
import { UploadImage } from '../../../basic/UploadImage';

function UploadMainImage({
  onUpload,
  autoClose = true,
}: {
  onUpload: any,
  autoClose: boolean,
}) {
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [, dispatch] = useStateContainer();
  const titleId = 'mainImageUploadTitle';
  const contentId = 'mainImageUploadContent';

  const internalOnUpload = React.useCallback(
    ({ url, blob }: { url: string, blob: ?Blob }) => {
      if (autoClose) dispatch(dequeueModal());
      onUpload({ url, blob }, setDisabled);
    },
    [onUpload, autoClose, dispatch]
  );

  return (
    <Modal
      open
      onClose={() => dispatch(dequeueModal())}
      titleId={titleId}
      contentId={contentId}
      withCloseButton={false}
    >
      <Box
        mb={2}
        minWidth={400}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>Crop image to the right size</Box>
        <Box
          style={{ cursor: 'pointer' }}
          onClick={disabled ? null : () => dispatch(dequeueModal())}
        >
          <FontAwesomeIcon icon={['fal', 'times']} size="2x" />
        </Box>
      </Box>
      <UploadImage
        disabled={disabled}
        onAccepted={internalOnUpload}
        acceptButtonText="Use as main image"
        initialCropStateOnUpload={{
          aspect: 16 / 4,
          width: 100,
          x: 0,
          y: 0,
          unit: '%',
        }}
      />
    </Modal>
  );
}

export { UploadMainImage };
