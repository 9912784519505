// @flow
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import {
  useLocation,
  useParams,
  Redirect,
  Route,
  useHistory,
} from 'react-router-dom';
import { CompanyProfile as CP } from '../components/companies/profile/CompanyProfile';
import { useCompany } from '../components/companies/common/context/CompanyContext';
import type { CompanyCtx } from '../components/companies/common/context/CompanyContext';
import { FullPageLoader } from '../components/basic/FullPageLoader';
import { useStateContainer } from '../core/context/StateContainer';
import { paths } from '../core/constants';
import { PageHead } from '../components/basic/PageHead';
import { JobPage } from './jobs/JobsPage';

function Loading() {
  return <FullPageLoader>Preparing the starships...</FullPageLoader>;
}

function AddHead({ c, page }: { c: CompanyCtx | 'loading', page: Node }) {
  const loc = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    if (loc.search.includes('edit=true')) setIsEdit(true);
    if (loc.search.includes('preview=true')) setIsPreview(true);
  }, [loc.search]);

  // eslint-disable-next-line
  const state = isEdit ? 'Edit |' : isPreview ? 'Preview |' : '';
  const title =
    c === 'loading'
      ? 'Company Profile | NobleHire'
      : `${c.state.brand || ''} ${state ? ` | ${state}` : ''} | NobleHire`;

  const description = c === 'loading' ? '' : c.state.overview;

  const socialMedia = {};
  if (c !== 'loading' && c.state.mainImages.length) {
    socialMedia.image = c.state.mainImages[c.state.mainImages.length - 1].src;
  }

  return (
    <div>
      <PageHead
        title={title}
        description={description}
        socialMedia={socialMedia}
      />
      {page}
    </div>
  );
}

export function CompanyProfilePage() {
  const [init, setInit] = useState(false);
  const history = useHistory();
  const [{ auth }] = useStateContainer();
  const params = useParams();
  const { slug } = params;
  const c = useCompany();

  const loading = c.loading;
  const load = c.load;

  useEffect(() => {
    if (loading || init) return;
    setInit(true);
    load(slug);
  }, [load, loading, init, slug]);

  const logged = auth.authenticated;
  const isCompany = auth.isCompany(auth.user);
  const isAdmin = auth.isAdmin(auth.user);
  const loadingUser = auth.gettingUser.loading;
  const own = isCompany && auth.user.companySlug === slug;

  if (!init || loading || loadingUser) {
    return <AddHead c="loading" page={<Loading />} />;
  }

  const isPublic = c.state.isPublic;
  if (
    (!loading && init && c.companyNotFound) ||
    (!loading && init && !isPublic && !isAdmin && !own) // non-public profile
  ) {
    return <Redirect to={paths.root} />;
  }

  if (
    (!loading && init && isPublic) ||
    (!loading && init && !isPublic && logged && own) ||
    isAdmin
  ) {
    return (
      <div>
        <AddHead c={c} page={<CP comp={c} />} />
        <Route exact path={paths.jobInCompanyProfile}>
          <JobPage
            isEdit={false}
            isPreview={false}
            onClose={() => {
              history.push({
                pathname: paths.companiesProfile
                  .replace(':slug', slug)
                  .replace('/:jobSlug?', ''),
                search: '',
                state: { skipScrollTop: true },
              });
            }}
          />
        </Route>
      </div>
    );
  }

  return <Redirect to={paths.root} />;

  /*

  // ---------------------------------------------------------- //
  // We technically have a user,
  // but we're currently loading his profile info,
  // so just wait a bit...
  // ---------------------------------------------------------- //
  if (!auth.user && (auth.authToken || auth.gettingUser.loading))
    return <AddHead c="loading" page={<Loading />} />;
  // ---------------------------------------------------------- //

  // ---------------------------------------------------------- //
  // We don't have a logged user, so there's no chance that a
  // company user tries to view his company profile. So, we simply
  // show him a company profile
  // ---------------------------------------------------------- //
  if (!auth.user && c.state.isPublic)
    return <AddHead c={c} page={<CP comp={c} />} />;
  // ---------------------------------------------------------- //

  // ---------------------------------------------------------- //
  // But if we have a user,
  // check what kind of user he is...
  // ---------------------------------------------------------- //
  if (auth.isAdmin(auth.user)) {
    // we have an admin, but the data about the company is still loading
    if (c.loading) return <AddHead c="loading" page={<Loading />} />;

    // we have the data, needed to display the company...
    return <AddHead c={c} page={<CP comp={c} />} />;
  }
  if (auth.isCompany(auth.user)) {
    // we have a company user, but the data about his company is still loading
    if (myCompany.loading) return <AddHead c="loading" page={<Loading />} />;

    // we got the company user's data,
    // and if the slug == params.slug ->
    // he is trying to view his own profile
    if (myCompany.slug === params.slug)
      return <AddHead c={c} page={<CP comp={c} />} />;

    // otherwise, he's trying to view another company profile.
    return <AddHead c={c} page={<CP comp={c} />} />;
  }

  // If the user is not a company user, nor admin, but a generic one,
  // just open the company profile
  return <AddHead c={c} page={<CP comp={c} />} />;
  */
}
