// @flow
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { MenuItem } from '../../../MenuItem';
import { useReferralsBox } from './userReferralsBox';
import { useStateContainer } from '../../../../../../core/context/StateContainer';
import { Loader } from '../../../../../basic/Loader';
import { Button } from '../../../../../basic/Button';
import { ReferralStats } from '../../../../../referrals/components/ReferralStats';
import { useReferralStats } from '../../../../../referrals/components/ReferralStats/useReferralStats';

type Props = {
  menuClasses: any,
};

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey.dark,
  },
  container: {
    marginTop: theme.spacing(3),
  },
  marketingBox: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    borderRadius: 5,
    background: theme.palette.primary.lightest,
    fontSize: theme.typography.pxToRem(12),
  },
  marketingBoxTitle: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),
  },
  marketingBoxContent: {
    margin: theme.spacing(2, 0),
  },
  getReferralLink: {
    marginBottom: 0,
    flex: 1,
  },
  button: {
    boxShadow: `${theme.shadows[1]} !important`,
    background: `${theme.palette.common.white} !important`,
    width: '100%',
  },
  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'initial',
  },
  buttonText: {
    flex: 1,
  },
  getReferralLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  loaderPlaceholder: {
    width: 25,
    height: 25,
    background: 'transparent',
  },
  seeHowItWorks: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(2),
    textDecoration: 'underline !important',
  },
  noBottomMargin: {
    marginBottom: 0,
  },
}));

function ReferralsBox({ menuClasses }: Props) {
  const classes = useStyles();
  const [
    {
      referrals: { loading },
    },
  ] = useStateContainer();
  const { onGetReferralLinkClick } = useReferralsBox();
  const { stats } = useReferralStats();

  return (
    <div>
      <div className={classes.heading}>Refer</div>
      <div className={classes.marketingBox}>
        <div className={classes.marketingBoxTitle}>
          Refer people to Noble Hire and make 2,000 - 8,000 BGN if they get
          hired.
        </div>
        <div className={classes.marketingBoxContent}>
          If you successfully refer a person through Noble Hire, you will get
          50% of the size of their gross monthly salary as a referral bonus.
        </div>
        <div className={classes.container}>
          <div className={classes.getReferralLinkContainer}>
            <MenuItem
              internal
              onClick={onGetReferralLinkClick}
              className={`${menuClasses.menuItem} ${classes.getReferralLink}`}
              disabled={loading.getReferralLink}
            >
              <Button
                type="button"
                color="secondary"
                variant="contained"
                onClick={() => {}}
                className={classes.button}
                classes={{ label: classes.buttonLabel }}
                disabled={loading.getReferralLink}
              >
                {loading.getReferralLink ? (
                  <span className={classes.buttonText}>Just a sec...</span>
                ) : (
                  <span>Get a referral link</span>
                )}
                {loading.getReferralLink ? (
                  <Loader
                    containerParams={{
                      style: { display: 'flex', alignItems: 'center' },
                    }}
                    svgParams={{ width: 20, height: 20 }}
                  />
                ) : null}
              </Button>
            </MenuItem>
          </div>
        </div>
      </div>
      {stats ? (
        <div className={classes.container}>
          <ReferralStats />
        </div>
      ) : null}
    </div>
  );
}

export { ReferralsBox };
