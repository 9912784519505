// @flow
import React, { useContext } from 'react';
import { TopMenuContext } from '../../../context/TopMenu';
import { makeStyles } from '@material-ui/core';
import { MenuItem } from '../../MenuItem';
// import { SideMenuItemOptions } from '../../SideMenuItemOptions';
import { paths, externalPaths } from '../../../../../core/constants';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(21),
    },
    menuItem: {
      color: theme.palette.text.primary,

      '&:hover': {
        color: theme.palette.grey.darkest,
      },
    },
  };
});

function SiteNavigationBox() {
  const classes = useStyles();
  const { close } = useContext(TopMenuContext);

  return (
    <div className={classes.root}>
      {/* <MenuItem
        internal
        to={paths.matchMe}
        className={classes.menuItem}
        onClick={close}
      >
        Match me
      </MenuItem> */}
      <MenuItem
        internal
        to={paths.jobs}
        className={classes.menuItem}
        onClick={close}
      >
        Jobs
      </MenuItem>
      <MenuItem
        internal
        to={paths.companies}
        className={classes.menuItem}
        onClick={close}
      >
        Companies
      </MenuItem>
      <MenuItem
        internal
        to={paths.referrers}
        className={classes.menuItem}
        onClick={close}
      >
        Refer
      </MenuItem>
      <MenuItem
        internal
        to={paths.businesses}
        className={classes.menuItem}
        onClick={close}
      >
        For Employers
      </MenuItem>
      <MenuItem
        to={externalPaths.secretNewsletter}
        className={classes.menuItem}
        onClick={close}
        target="_blank"
      >
        Secret Newsletter
      </MenuItem>
      {/* <MenuItem
        to={externalPaths.salarySurvey2022}
        className={classes.menuItem}
        onClick={close}
      >
        Salary Survey
      </MenuItem> */}
      {/* <MenuItem
        internal
        to={paths.referrers}
        className={classes.menuItem}
        onClick={close}
      >
        For Referrers
      </MenuItem>
      <MenuItem
        internal
        to={paths.businesses}
        className={classes.menuItem}
        onClick={close}
      >
        For Employers
      </MenuItem> */}
    </div>
  );
}

export { SiteNavigationBox };
