// @flow
import React, {
  createContext,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import type { Node } from 'react';
import { useStateContainer } from '../../../core/context/StateContainer';
import { load as loadVisitorData, setValue } from './actions';
import { log } from '../../../utils/log';

const visitorTrailsKey = 'vt';
const visitorTrails = {
  VISITOR_HAS_ACCOUNT: '1vhaS10YuqTGzBnd',
  REF_PAGE_HITS: '2rphSiQ91qUyzlNR',
  DIRECT_REF_SENT: '3drsSqhAggqY7o3g',
  COPY_REF_LINK: '4crlSqy2phjDzzrH',
  COOKIE_CONSENT_AGREEMENT: '5ccaSu1nnBqgFo05',
  VISITOR_FIRST_LANDING_PAGE: '6vflpSt3zJqyy0Pq',
  VISITOR_COMING_FROM: '7vcfSvcfrwQp60rU',
  EDIT_PROFILE_BEFORE_APPLY_SHOWN: '8epbasSy04kqV7pZ',
};
const defaultTrails = {
  [visitorTrails.VISITOR_HAS_ACCOUNT]: false,
  [visitorTrails.REF_PAGE_HITS]: 0,
  [visitorTrails.DIRECT_REF_SENT]: 0,
  [visitorTrails.COPY_REF_LINK]: 0,
  [visitorTrails.COOKIE_CONSENT_AGREEMENT]: false,
  [visitorTrails.VISITOR_FIRST_LANDING_PAGE]: '',
  [visitorTrails.VISITOR_COMING_FROM]: '',
  [visitorTrails.EDIT_PROFILE_BEFORE_APPLY_SHOWN]: false,
};

type Ctx = {
  visitorTrails: typeof visitorTrails,
  getTrail: (key: string, defVal: any) => any,
  putTrail: (key: string, defVal: any) => void,
  removeTrails: () => void,
}; // TODO

const VisitorTrailsContext = createContext<Ctx>({});

const trailUtils = {
  parseJsonOrObject: (data) =>
    typeof data === 'string' ? JSON.parse(data) : {},
};

function VisitorTrailsProvider({ children }: { children: Node }) {
  const [
    {
      visitorTrails: { loaded, trails: visitorTrailsFromStore },
    },
    dispatch,
  ] = useStateContainer();

  const updateStorage = useCallback(
    (data) => {
      localStorage.setItem(visitorTrailsKey, JSON.stringify(data));
      dispatch(loadVisitorData(data));
    },
    [dispatch]
  );

  function loadTrails() {
    try {
      const data = trailUtils.parseJsonOrObject(
        localStorage.getItem(visitorTrailsKey)
      );
      const finalData = { ...defaultTrails, ...data };
      updateStorage(finalData);
    } catch (err) {
      updateStorage(defaultTrails);
      log(
        'Failed to load visitor trails because of corrupted VT object. Falling back to default trails.',
        {
          logLevel: log.logLevels.error,
          error: err,
        }
      );
    }
  }

  const putTrail = useCallback(
    (key: string, value: any) => {
      dispatch(setValue({ key, value }));
      try {
        const data = trailUtils.parseJsonOrObject(
          localStorage.getItem(visitorTrailsKey)
        );
        data[key] = value;
        updateStorage(data);
      } catch (err) {
        updateStorage(defaultTrails);
        log(
          'Failed to load visitor trails because of corrupted VT object. Falling back to default trails.',
          {
            logLevel: log.logLevels.error,
            error: err,
          }
        );
      }
    },
    [dispatch, updateStorage]
  );

  const getTrail = useCallback(
    (key: string, defaultValue: string) => {
      function getTrailFromLS() {
        const trailsFromLS = localStorage.getItem(visitorTrailsKey);
        if (!trailsFromLS) {
          return defaultValue;
        }

        function valueOrDefaultFromLS() {
          const data = trailUtils.parseJsonOrObject(trailsFromLS);
          const valueFromTrails = data[key];
          const noValueFromTrails =
            valueFromTrails === null || valueFromTrails === undefined;

          return noValueFromTrails ? defaultValue : valueFromTrails;
        }

        try {
          return valueOrDefaultFromLS();
        } catch (err) {
          updateStorage(defaultTrails);
          log(
            'Failed to load visitor trails because of corrupted VT object. Falling back to default trails.',
            {
              logLevel: log.logLevels.error,
              error: err,
            }
          );
          return defaultValue;
        }
      }

      function getTrailFromGlobalStore() {
        if (visitorTrailsFromStore[key] === undefined) {
          return defaultValue;
        }

        return visitorTrailsFromStore[key];
      }

      return loaded ? getTrailFromGlobalStore() : getTrailFromLS();
    },
    [loaded, updateStorage, visitorTrailsFromStore]
  );

  function removeTrails() {
    localStorage.removeItem(visitorTrailsKey);
    dispatch(loadVisitorData({}));
  }

  useEffect(loadTrails, []);

  const val = {
    visitorTrails,
    putTrail,
    getTrail,
    removeTrails,
  };

  return (
    <VisitorTrailsContext.Provider value={val}>
      {children}
    </VisitorTrailsContext.Provider>
  );
}

function useVisitorTrails() {
  return useContext(VisitorTrailsContext);
}

export {
  VisitorTrailsContext,
  VisitorTrailsProvider,
  useVisitorTrails,
  visitorTrailsKey,
  visitorTrails,
};
