// @flow
import * as React from 'react';

const ENQUEUE_MODAL = 'ENQUEUE_MODAL';
const DEQUEUE_MODAL = 'DEQUEUE_MODAL';

function enqueueModal(data: React.Node) {
  return { type: ENQUEUE_MODAL, data };
}

function dequeueModal() {
  return { type: DEQUEUE_MODAL };
}

export { ENQUEUE_MODAL, DEQUEUE_MODAL, enqueueModal, dequeueModal };
