// @flow
import React, { useState, useEffect, useRef } from 'react';
import {
  Popup,
  PopupContainer,
  PopupTitle,
  PopupMain,
} from '../../../basic/Popup';
import { Loader } from '../../../basic/Loader';
import { useApply, availabilityOptions } from '../../ApplicationsContext';
import { makeStyles } from '@material-ui/core';
import { obj } from '../../../../utils/obj';
import { Button } from '../../../basic/Button';
import { useInputV2Styles } from '../../../basic/form/InputV2';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { endpoint } from '../../../../core/rest/API';
import { useStateContainer } from '../../../../core/context/StateContainer';

const useStyles = makeStyles((theme) => {
  return {
    titleContainer: {
      marginBottom: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    closeButton: {
      fontSize: theme.typography.pxToRem(18),
      border: 0,
      background: 'transparent',
      padding: 0,
      margin: 0,
      cursor: 'pointer',
    },
    description: {
      color: theme.palette.grey[600],
      marginBottom: theme.spacing(4),
    },
    form: {
      width: '100%',
      '& *': {
        fontFamily: theme.typography.fontFamily,
      },
    },
    row: {
      display: 'block',
      marginBottom: theme.spacing(1.5),
    },
    noOutline: {
      outline: 'none',
    },
    focusable: {
      '&:focus': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    fullWidth: {
      width: '100%',
    },
    textarea: {
      border: `1px solid ${theme.palette.grey[500]}`,
      resize: 'none',
      borderRadius: theme.shape.borderRadius,
    },
    label: {
      width: '100%',
      marginBottom: theme.spacing(0.5),
    },
    cvContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.grey[100],
    },
    loaderSvg: {
      width: 20,
      height: 20,
    },
    formFooter: {
      marginTop: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    motivationHelperText: {
      margin: 0,
      padding: 0,
      color: theme.palette.grey[600],
      fontSize: theme.typography.pxToRem(12),
    },
    error: {
      color: theme.palette.error.main,
    },
    previewCvButton: {
      padding: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.grey[100],

      '&:hover': {
        background: theme.palette.grey[200],
      },
    },
    helper: {
      color: theme.palette.grey[700],
    },
    cvButton: {},
  };
});

export function ShortApplyPopup() {
  const {
    isApplyPopupVisible,
    cancelApply,
    submitApplication,
    formValues,
    setFormValues,
    errors,
    jobDetails,
    uploadCv,
    isUploadingCv,
    isApplicationInProgress,
  } = useApply();
  const [
    {
      auth: { user, gettingUser },
    },
  ] = useStateContainer();
  const motivationRef = useRef(null);
  const inputV2Styles = useInputV2Styles();
  const classes = useStyles();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [motivation, setMotivation] = useState(formValues.motivation);

  useEffect(() => {
    setMotivation(formValues.motivation);
  }, [formValues.motivation]);

  useEffect(() => {
    setIsFormDisabled(isApplicationInProgress);
  }, [isApplicationInProgress]);

  const jobAdTitle = jobDetails ? jobDetails.title : '';

  useEffect(() => {
    if (isApplyPopupVisible && motivationRef && motivationRef.current) {
      motivationRef.current.focus();
    }
  }, [isApplyPopupVisible]);

  useEffect(() => {
    const disabled = !(
      formValues.motivation &&
      formValues.availability &&
      formValues.cv
    );
    setSubmitDisabled(disabled);
  }, [formValues]);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const onCvChange = (e: any) => {
    const file = e.target.files[0];
    uploadCv(file);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    submitApplication();
  };

  // eslint-disable-next-line
  const Error = ({ error }: { error: string | null }) => {
    if (!error) return null;
    return <span className={classes.error}>{error}</span>;
  };

  const onPreviewCvClick = () => {
    if (!formValues.cv || !user.uuid) return;
    const url = `${endpoint}/user/${user.uuid}/resume/${formValues.cv}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <Popup
        maxWidth={480}
        isOpen={isApplyPopupVisible}
        onClose={cancelApply}
        onEsc={cancelApply}
      >
        <PopupContainer>
          <div className={classes.titleContainer}>
            <PopupTitle>
              <div className={classes.title}>
                <span>
                  Apply{' '}
                  <span role="img" aria-label="party">
                    🥳
                  </span>
                </span>
                <button
                  type="button"
                  className={classes.closeButton}
                  onClick={cancelApply}
                  disabled={isFormDisabled}
                >
                  <FontAwesomeIcon icon={['fal', 'times']} />
                </button>
              </div>
            </PopupTitle>
          </div>
          <PopupMain>
            <div className={classes.description}>
              You&apos;re about to apply for the <strong>{jobAdTitle}</strong>{' '}
              position. Please, provide your application details bellow.
            </div>

            <div className={classes.formContainer}>
              <form className={classes.form} onSubmit={onSubmit}>
                <label htmlFor="motivation" className={classes.row}>
                  <div className={classes.label}>Motivation</div>
                  <textarea
                    ref={motivationRef}
                    rows={4}
                    id="motivation"
                    name="motivation"
                    value={motivation}
                    onChange={(e: any) => setMotivation(e.target.value)}
                    onBlur={() =>
                      setFormValues((prev) => ({
                        ...prev,
                        motivation,
                      }))
                    }
                    placeholder="Why do you want to work here?"
                    className={cn(
                      inputV2Styles.primary,
                      classes.textarea,
                      classes.noOutline,
                      classes.focusable,
                      classes.fullWidth
                    )}
                    disabled={isFormDisabled}
                  />
                  <Error error={errors.motivation} />
                  <p className={classes.motivationHelperText}>
                    Application with motivation gets hired 17% more often.
                    <br /> Keep it informal and use your own words.
                  </p>
                </label>

                <label htmlFor="availability" className={classes.row}>
                  <div className={classes.label}>
                    Availability/Notice period
                  </div>
                  <select
                    value={formValues.availability}
                    name="availability"
                    id="availability"
                    onChange={onChange}
                    className={cn(
                      inputV2Styles.primary,
                      classes.noOutline,
                      classes.fullWidth
                    )}
                    disabled={isFormDisabled}
                  >
                    {obj.mapKeys<$Keys<typeof availabilityOptions>, any>(
                      availabilityOptions,
                      (optKey) => {
                        return (
                          <option key={optKey} value={optKey}>
                            {availabilityOptions[optKey]}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <Error error={errors.availability} />
                </label>

                <label htmlFor="cv" className={classes.row}>
                  <div className={classes.label}>Upload a CV (PDF)</div>
                  <div className={classes.cvContainer}>
                    <input
                      type="file"
                      name="cv"
                      id="cv"
                      onChange={onCvChange}
                      disabled={isUploadingCv || isFormDisabled}
                    />
                    {isUploadingCv || gettingUser.loading ? (
                      <Loader svgParams={{ className: classes.loaderSvg }} />
                    ) : null}
                  </div>

                  {formValues.cv || errors.cv ? (
                    <>
                      <div style={{ display: 'flex', alignItem: 'center' }}>
                        {formValues.cv &&
                        formValues.cvFilename &&
                        !gettingUser.loading ? (
                          <Button
                            type="button"
                            variant="text"
                            color="default"
                            onClick={onPreviewCvClick}
                            style={{ marginTop: 5, padding: 0 }}
                            className={classes.cvButton}
                            disabled={isFormDisabled}
                          >
                            <span style={{ marginRight: 5 }}>
                              <FontAwesomeIcon
                                icon={['fal', 'external-link']}
                              />
                            </span>
                            <span
                              title={formValues.cvFilename}
                              style={{
                                textAlign: 'left',
                                padding: 0,
                                width: 70,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {formValues.cvFilename}
                            </span>
                          </Button>
                        ) : null}

                        {gettingUser.loading ? (
                          <Loader
                            svgParams={{ className: classes.loaderSvg }}
                          />
                        ) : null}

                        {formValues.cv ? (
                          <span
                            style={{
                              paddingTop: 8,
                              paddingLeft: 5,
                            }}
                            className={classes.helper}
                          >
                            will be used for this application
                          </span>
                        ) : null}
                      </div>
                      <Error error={errors.cv} />
                    </>
                  ) : null}
                </label>

                <div className={classes.formFooter}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      submitDisabled || isFormDisabled || gettingUser.loading
                    }
                  >
                    Submit Application
                  </Button>
                  <Button
                    variant="text"
                    color="default"
                    type="button"
                    onClick={cancelApply}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </PopupMain>
        </PopupContainer>
      </Popup>
    </>
  );
}
