// @flow
import * as React from 'react';
import { useCultureForm } from './useCultureForm';
import { OnboardingForm } from '../OnboardingForm';

const CultureForm = () => (
  <OnboardingForm useHook={useCultureForm} initialValuesEqual={() => true} />
);

export { CultureForm };
