// @flow
import React from 'react';
import { Container } from '@material-ui/core';
import euLegalImg from '../assets/images/eu-legal.png';

export function EuLegal() {
  return (
    <Container>
      <div>
        <img
          style={{ width: '100%', height: '100%' }}
          src={euLegalImg}
          alt="EU Covid Help"
        />
      </div>
    </Container>
  );
}
