// @flow
import API from '../../core/rest/API';

type Data = { data: { [key: string]: any } };

function directApplication({ data }: Data) {
  return API.postResponse('/user/me/new-job-ad-apply', data);
}

export { directApplication };
