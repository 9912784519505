// @flow
import * as React from 'react';
import { Section } from '../Section';

type Props = {
  testId?: string,
};

function SliderPreview({ testId }: Props) {
  return (
    <Section testId={testId} title="Sliders">
      Slider...
    </Section>
  );
}

SliderPreview.defaultProps = {
  testId: undefined,
};

export { SliderPreview };
