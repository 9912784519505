// @flow
import type { State, Action } from '../../core/types';
import {
  GET_REFERRAL_LINK_START,
  GET_REFERRAL_LINK_END,
  SET_PENDING_GET_REFERRAL_LINK_STATUS,
  SET_PENDING_COMPOSE_DIRECT_REFERRAL_STATUS,
  COMPOSE_DIRECT_REFERRAL_START,
  COMPOSE_DIRECT_REFERRAL_END,
} from './actions';

const referrals = (state: State, action: Action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case GET_REFERRAL_LINK_START:
      return {
        ...state,
        loading: {
          ...state.loading,
          getReferralLink: true,
        },
      };
    case GET_REFERRAL_LINK_END:
      return {
        ...state,
        link: action.data,
        loading: {
          ...state.loading,
          getReferralLink: false,
        },
      };
    case SET_PENDING_GET_REFERRAL_LINK_STATUS:
      return {
        ...state,
        pending: {
          ...state.pending,
          getReferralLink: action.data,
        },
      };
    case SET_PENDING_COMPOSE_DIRECT_REFERRAL_STATUS:
      return {
        ...state,
        pending: {
          ...state.pending,
          composeDirectReferral: action.data,
        },
      };
    case COMPOSE_DIRECT_REFERRAL_START:
      return {
        ...state,
        loading: {
          ...state.loading,
          composeDirectReferral: true,
        },
      };
    case COMPOSE_DIRECT_REFERRAL_END:
      return {
        ...state,
        loading: {
          ...state.loading,
          composeDirectReferral: false,
        },
      };

    default:
      return state;
  }
};

referrals.initialState = {
  link: null,
  loading: {
    getReferralLink: false,
    composeDirectReferral: false,
  },
  pending: {
    getReferralLink: false,
    composeDirectReferral: false,
  },
};

export { referrals };
