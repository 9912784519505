// @flow
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichEditor.css';

export function RichEditor({
  value,
  onChange,
  onBlur,
}: {
  value: string,
  onChange: any,
  onBlur?: any,
}) {
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const internalOnChange = (v: string) => {
    setInnerValue(v);
    onChange(v);
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  return (
    <div>
      <ReactQuill
        modules={modules}
        theme="snow"
        value={innerValue}
        onChange={internalOnChange}
        onBlur={onBlur}
      />
    </div>
  );
}
