// @flow
import React, { useContext, useEffect } from 'react';
import { Route, useLocation, useHistory, useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { JobBoard } from '../../components/job-board/JobBoard';
import { JobEditProvider } from '../../components/jobs/JobEditContext';
import { JobProvider } from '../../components/jobs/JobContext';
import { paths } from '../../core/constants';
import { JobAdPage } from './JobAdPage';
import { JobEditPage } from './JobEditPage';
import { SlidingPageShell } from './SlidingPageShell';
import { Footer } from '../../components/Footer';
import { PageHead } from '../../components/basic/PageHead';
import { TopMenuContext } from '../../components/menu/context/TopMenu';

function Preview() {
  const history = useHistory();
  const params = useParams();
  return (
    <SlidingPageShell
      loading={false}
      onHideAnimDone={() => {
        history.push({
          pathname: paths.job.replace(':slug+', params.slug),
          search: '?edit=true',
        });
      }}
    >
      {() => <JobEditPage asPreviewBg={false} />}
    </SlidingPageShell>
  );
}

export function JobsPage() {
  const { setIsWithBackground } = useContext(TopMenuContext);
  const loc = useLocation();
  const isP = loc.search.includes('preview=true');
  const isE = loc.search.includes('edit=true');

  useEffect(() => {
    setIsWithBackground(!isE && !isP);
    return () => setIsWithBackground(false);
  });

  return (
    <Box>
      <PageHead />
      {!isE && !isP ? <JobBoard /> : null}
      <Route exact path={paths.job}>
        <JobPage isEdit={isE} isPreview={isP} />
      </Route>
      <Route exact path={paths.jobEdit}>
        <JobPage isEdit={isE} isPreview={isP} />
      </Route>
      <Footer />
    </Box>
  );
}

export function JobPage({
  isEdit,
  isPreview,
  onClose,
}: {
  isEdit: boolean,
  isPreview: boolean,
  onClose?: () => void,
}) {
  const { slug } = useParams();
  if (!slug) return null;

  return isEdit || isPreview ? (
    <JobEditProvider mode={isPreview ? 'preview' : 'edit'}>
      {isEdit || isPreview ? <JobEditPage asPreviewBg={isPreview} /> : null}
      {isPreview ? <Preview /> : null}
    </JobEditProvider>
  ) : (
    <JobProvider>
      <JobAdPage onClose={onClose} />
    </JobProvider>
  );
}
