// @flow
import * as React from 'react';

type Props = {
  children: React.Node,
};

const { useEffect } = React;

/**
 * Used to hide the "go-to-top" arrow on the wordpress in the bottom right corner
 */
function HideJobsNavigationArrows({ children }: Props) {
  useEffect(() => {
    const prevNav = document.querySelector('.mk-post-nav.mk-post-prev');
    const nextNav = document.querySelector('.mk-post-nav.mk-post-next');
    const noop = () => {};
    const show = () => {
      if (prevNav) {
        prevNav.classList.remove('hide');
      }

      if (nextNav) {
        nextNav.classList.remove('hide');
      }
    };

    if (prevNav || nextNav) {
      if (prevNav) {
        prevNav.classList.add('hide');
      }

      if (nextNav) {
        nextNav.classList.add('hide');
      }
    }

    return prevNav || nextNav ? show : noop;
  }, []);

  return children;
}

export { HideJobsNavigationArrows };
