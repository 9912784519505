// @flow
import React from 'react';
import { useStateContainer } from '../../../core/context/StateContainer';
import { Modal } from '../../basic/Modal';
import { closeConfirmation } from '../actions';

function Confirmations() {
  const [
    {
      confirmations: { item },
    },
    dispatch,
  ] = useStateContainer();

  if (!item) return null;

  return (
    <Modal
      open
      onClose={() => dispatch(closeConfirmation())}
      titleId="confirmations"
      contentId="confirmations_contentId"
      withCloseButton={false}
    >
      {item}
    </Modal>
  );
}

export { Confirmations };
