// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'react-final-form';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Input } from './Input';

type Props = {
  label: string,
  name: string,
  id: string,
  children: React.Node,
  label: string,
  controlProps?: { [key: string]: any },
  fieldProps?: { [key: string]: any },
  placeholder?: string,
  required?: boolean,
};

const useStyles = makeStyles({
  control: {
    width: '100%',
  },
});

function Select({
  children,
  name,
  id,
  controlProps,
  label,
  fieldProps,
  required,
}: Props) {
  const classes = useStyles();

  return (
    <Field name={name} {...fieldProps}>
      {({ input, meta }) => {
        const error = meta.touched && meta.error;
        const internalInput = (
          <Input
            label={label}
            required={required}
            name={input.name}
            id={input.name}
          />
        );

        return (
          <FormControl
            className={classes.control}
            {...controlProps}
            error={!!error}
          >
            <NativeSelect {...input} id={id} input={internalInput}>
              {children}
            </NativeSelect>
          </FormControl>
        );
      }}
    </Field>
  );
}

Select.defaultProps = {
  required: false,
  placeholder: '',
  fieldProps: {},
  controlProps: {},
};

export { Select };
