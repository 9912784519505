// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BasicInformationForm } from '../../forms/BasicInformationForm';
import { useOnboardingFormsContainer } from './useOnboardingFormsContainer';
import { Loader } from '../../../basic/Loader';
import { SkillsForm } from '../../forms/SkillsForm';
import { CultureForm } from '../../forms/CultureForm';

const { useEffect } = React;

type Props = {
  query: any,
  jobAdReferenceKey: any,
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  container: {
    height: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1, 3),
    background: theme.palette.common.white,
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function OnboardingFormsContainer({ query, jobAdReferenceKey }: Props) {
  const classes = useStyles();
  const {
    loading,
    initializeOnboardingSections,
    refetchOnboardingState,
    initializationReady,
    currentSection,
  } = useOnboardingFormsContainer({ query, jobAdReferenceKey });

  const sections = [
    null,
    <BasicInformationForm />,
    <SkillsForm />,
    <CultureForm />,
  ];

  useEffect(() => {
    initializeOnboardingSections();
  }, [initializeOnboardingSections]);

  useEffect(refetchOnboardingState, [currentSection]);

  const LoaderContainer = () => (
    <div className={classes.loaderContainer}>
      <Loader />
    </div>
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        {!initializationReady || loading ? (
          <LoaderContainer />
        ) : (
          sections[currentSection]
        )}
      </div>
    </div>
  );
}

export { OnboardingFormsContainer };
