// @flow
import React, { useState } from 'react';
import { useStateContainer } from '../../../../../core/context/StateContainer';
import { Success } from '../../../../notifications/components/Success';
import { enqueueNotification } from '../../../../notifications/actions';
import { login as loginRequest } from '../../../authService';
import { useReferrals } from '../../../../referrals/ReferralsContext';
import { useReferralsBox } from '../../../../menu/components/SideMenu/boxes/ReferralsBox/userReferralsBox';

export function useLoginPopup() {
  const [submitError, setSubmitError] = useState<any>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [
    {
      referrals: { pending },
    },
    dispatch,
  ] = useStateContainer();
  const { flushStoredReferralLinks } = useReferrals();
  const { onGetReferralLinkClick } = useReferralsBox();

  const onSubmit = ({
    email,
    password,
  }: {
    email: string,
    password: string,
  }) => {
    setSubmitError(null);
    setSubmitLoading(true);
    return loginRequest({ email, password })
      .then(() => {
        setSubmitLoading(false);
        flushStoredReferralLinks();

        if (pending.getReferralLink) {
          onGetReferralLinkClick();
        }

        dispatch(enqueueNotification(<Success>Welcome back!</Success>));
      })
      .catch((error) => {
        setSubmitLoading(false);
        let errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          'Unknown login error!';
        errorMessage = errorMessage.startsWith('No message available')
          ? 'An unexpected error ocurred. Please, try again in a few moments.'
          : errorMessage;

        if (error.response && error.response.status === 403) {
          return setSubmitError('Invalid credentials. Please, try again.');
        }

        return setSubmitError(errorMessage);
      });
  };

  return {
    onSubmit,
    submitError,
    submitLoading,
  };
}
