// @flow
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Redirect, useParams } from 'react-router-dom';
import { paths } from '../core/constants';
import { Loader } from '../components/basic/Loader';
import { oldUser } from '../components/auth/authService';
import API from '../core/rest/API';
import { useStateContainer } from '../core/context/StateContainer';
import { SET_AUTH_TOKEN } from '../components/auth/talents/actions';
import { useAuth } from '../components/auth/talents/AuthContext';

const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

function OldUserLanding() {
  const { loadUser } = useAuth();
  const [
    {
      auth: { authenticated, user },
    },
    dispatch,
  ] = useStateContainer();
  const classes = useStyles();
  const { token } = useParams();

  useEffect(() => {
    // eslint-disable-line
    API.updateAuthenticationToken(token);
    dispatch({ type: SET_AUTH_TOKEN, authToken: token });
    loadUser();
  }, [dispatch, loadUser, token]);

  useEffect(() => {
    if (authenticated) {
      oldUser();
    }
  }, [authenticated]);

  return authenticated && user ? (
    <Redirect to={paths.onboarding} />
  ) : (
    <Loader containerParams={{ className: classes.loader }} />
  );
}

export { OldUserLanding };
