// @flow
import * as React from 'react';
import type { Node } from 'react';
import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialCheckbox from '@material-ui/core/Checkbox';

type Props = {
  id: string,
  name: string,
  value: string,
  required?: boolean,
  // eslint-disable-next-line react/require-default-props
  controlProps?: { [key: string]: any },
  // eslint-disable-next-line react/require-default-props
  fieldProps?: { [key: string]: any },
  label: string | Node,
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: 0,
  },
  labelRoot: {
    alignItems: 'flex-start',
  },
  label: {
    fontSize: 14,
  },
}));

function Checkbox({
  id,
  name,
  value,
  controlProps,
  fieldProps,
  required,
  label,
}: Props) {
  const classes = useStyles();

  const control = (input: any, meta: any) => (
    <MaterialCheckbox
      {...input}
      color="primary"
      error={meta.error}
      required={required}
      disableRipple
    />
  );

  return (
    <Field name={name} type="checkbox" value={value} {...fieldProps}>
      {({ input, meta }) => {
        const error = (meta.dirty || meta.touched) && meta.error;

        return (
          <div>
            <FormControlLabel
              {...controlProps}
              classes={{ root: classes.labelRoot, label: classes.label }}
              id={id}
              control={control(input, meta)}
              labelPlacement="end"
              label={label}
            />
            {error && (
              <FormHelperText className={classes.errorMessage} key={error}>
                {error}
              </FormHelperText>
            )}
          </div>
        );
      }}
    </Field>
  );
}

Checkbox.defaultProps = {
  required: false,
};

export { Checkbox };
