import { useVisitorTrails } from '../tracking/visitor-trails/VisitorTrailsContext';

function useDirectRefSentTrail() {
  const { getTrail, putTrail, visitorTrails } = useVisitorTrails();

  function onDirectRefSent() {
    const refsSent = Number(getTrail(visitorTrails.DIRECT_REF_SENT, 0));
    putTrail(visitorTrails.DIRECT_REF_SENT, refsSent + 1);
  }

  return { onDirectRefSent };
}

export { useDirectRefSentTrail };
