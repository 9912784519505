// @flow
import * as React from 'react';
import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Slider } from './Slider';
import type { SliderType } from '../../types';

type ChangeFn = (...any) => void;
const noop = () => {};

type Props = {
  name: string,
  id?: ?string,
  sliders: SliderType[],
  onChange?: ChangeFn,
};

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',

    '@media (max-width: 768px)': {
      flexDirection: 'column-reverse',
      marginBottom: theme.spacing(2),
    },
  },
  sliderWrapper: {
    flex: 1,
  },
  labelWrapper: {
    flex: 1,
    marginLeft: theme.spacing(2),

    '@media (max-width: 768px)': {
      marginLeft: 0,
    },
  },
  error: {
    textAlign: 'left',
    color: theme.palette.error.main,
  },
}));

function SliderGroup({ name, id, sliders, onChange = noop }: Props) {
  const classes = useStyles();

  return (
    <Field
      id={id || name}
      name={name}
      subscription={{ touched: true, error: true }}
      render={
        ({ meta }) =>
          sliderGroupRender({ ...meta, name, sliders, onChange, classes }) // eslint-disable-line
      }
    />
  );
}

SliderGroup.defaultProps = {
  onChange: noop,
  id: null,
};

type SliderGroupRenderArgs = {
  error: string,
  touched: boolean,
  name: string,
  sliders: SliderType[],
  onChange: ChangeFn,
  classes: any,
};

function sliderGroupRender({
  error,
  touched,
  name,
  sliders,
  onChange,
  classes,
}: SliderGroupRenderArgs) {
  const hasError = touched && !!error;

  return (
    <div>
      <SlidersFactory
        sliders={sliders}
        name={name}
        onChange={onChange}
        classes={classes}
      />

      {hasError ? (
        <FormHelperText className={classes.error} key={error}>
          {error}
        </FormHelperText>
      ) : null}
    </div>
  );
}

type SlidersFactoryProps = {
  sliders: SliderType[],
  name: string,
  onChange: ChangeFn,
  classes: any,
};

function SlidersFactory({
  sliders,
  name,
  onChange,
  classes,
}: SlidersFactoryProps) {
  return sliders.map<React.Node>((slider) => (
    <SliderFactory
      key={slider.name}
      uniqSliderName={`${name}[${slider.name}]`}
      label={slider.label}
      onChange={() => onChange(name, sliders)}
      classes={classes}
    />
  ));
}

type SliderFactoryProps = {
  uniqSliderName: string,
  label: ?React.Node,
  onChange: ChangeFn,
  classes: any,
};

function SliderFactory({
  uniqSliderName,
  label,
  onChange,
  classes,
}: SliderFactoryProps) {
  const sliderLevels = [
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
    { value: 50 },
    { value: 60 },
    { value: 70 },
    { value: 80 },
    { value: 90 },
  ];

  return (
    <div className={classes.row} key={uniqSliderName}>
      <div className={classes.sliderWrapper}>
        <Slider
          name={uniqSliderName}
          step={10}
          showTooltip={false}
          onChange={onChange}
          marks={sliderLevels}
        />
      </div>
      {label ? <div className={classes.labelWrapper}>{label}</div> : null}
    </div>
  );
}

SliderGroup.defaultProps = {
  onChange: noop,
};

export { SliderGroup };
