// @flow
import React, {
  useCallback,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import type { Node } from 'react';
import { useStateContainer } from '../../../core/context/StateContainer';
import { getUser, getUserStats } from '../authService';
import {
  getUserStart,
  getUserEnd,
  getUserStatsStart,
  getUserStatsEnd,
  logOut,
} from './actions';

type Ctx = any;

type Props = {
  children: Node,
};

type PopupType = 'login' | 'register';

const ACTIONS = {
  SHOW_LOGIN_POPUP: 'showLoginPopup',
  HIDE_LOGIN_POPUP: 'hideLoginPopup',
  SHOW_REGISTER_POPUP: 'showRegisterPopup',
  HIDE_REGISTER_POPUP: 'hideRegisterPopup',
};
const initialState = {
  isLoginPopupVisible: false,
  isRegisterPopupVisible: false,
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SHOW_LOGIN_POPUP:
      return {
        ...state,
        isLoginPopupVisible: true,
        isRegisterPopupVisible: false,
      };
    case ACTIONS.HIDE_LOGIN_POPUP:
      return { ...state, isLoginPopupVisible: false };
    case ACTIONS.SHOW_REGISTER_POPUP:
      return {
        ...state,
        isRegisterPopupVisible: true,
        isLoginPopupVisible: false,
      };
    case ACTIONS.HIDE_REGISTER_POPUP:
      return { ...state, isRegisterPopupVisible: false };
    default:
      throw new Error('Invalid AuthContext action dispatched');
  }
}

const AuthContext = createContext<Ctx>(null);

const AuthProvider = ({ children }: Props) => {
  const [
    {
      auth: { authenticated },
    },
    dispatch,
  ] = useStateContainer();
  const [state, dispatchState] = useReducer(reducer, initialState);

  const showAuthPopup = useCallback((popupType: PopupType) => {
    const action =
      popupType === 'login'
        ? ACTIONS.SHOW_LOGIN_POPUP
        : ACTIONS.SHOW_REGISTER_POPUP;
    dispatchState({ type: action });
  }, []);
  const hideAuthPopup = (popupType: PopupType) => {
    const action =
      popupType === 'login'
        ? ACTIONS.HIDE_LOGIN_POPUP
        : ACTIONS.HIDE_REGISTER_POPUP;
    dispatchState({ type: action });
  };

  const loadUser = useCallback(
    (afterLoad) => {
      if (authenticated) {
        dispatch(getUserStart());
        getUser()
          .then((data) => {
            dispatch(getUserEnd(data));
            hideAuthPopup('login');
            hideAuthPopup('register');

            if (afterLoad && typeof afterLoad === 'function') {
              afterLoad();
            }
          })
          .catch((err) => {
            if (err.response.status.toString() === '500') {
              logOut(dispatch);
            }
          });
      }
    },
    [authenticated, dispatch]
  );

  const loadStats = () => {
    if (authenticated) {
      dispatch(getUserStatsStart());
      getUserStats().then((data) => {
        dispatch(getUserStatsEnd(data));
      });
    }
  };

  useEffect(loadUser, [authenticated]);
  useEffect(loadStats, [authenticated]);

  const value = {
    loadUser,
    showAuthPopup,
    hideAuthPopup,
    isLoginPopupVisible: state.isLoginPopupVisible,
    isRegisterPopupVisible: state.isRegisterPopupVisible,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const ctx = useContext(AuthContext);
  if (ctx === null) throw new Error('Improper use of AuthContext');
  return ctx;
}

export { useAuth, AuthContext, AuthProvider };
