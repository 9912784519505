// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStateContainer } from '../../../core/context/StateContainer';

type Props = {
  allSteps: number,
};

const useStyles = makeStyles((theme) => {
  const indicatorBase = {
    display: 'inline-block',
    margin: theme.spacing(0, 0.3),
    width: 35,
    height: 4,
    borderRadius: 4,
    background: theme.palette.grey.light,
  };

  return {
    stepIndicator: {
      ...indicatorBase,
    },
    stepIndicatorActiveCls: {
      ...indicatorBase,
      background: theme.palette.success.main,
    },
  };
});
function SyntheticStepsIndicator({ allSteps }: Props) {
  const classes = useStyles();
  const [
    {
      onboarding: { syntheticStep },
    },
  ] = useStateContainer();

  const steps = Array.from({ length: allSteps }, (v, i) => i).map((i) => {
    const isActiveStep = i <= syntheticStep;
    const stepIndicatorCls = isActiveStep
      ? classes.stepIndicatorActiveCls
      : classes.stepIndicator;

    return <div key={i} className={stepIndicatorCls} />;
  });

  return <div>{steps}</div>;
}

export { SyntheticStepsIndicator };
