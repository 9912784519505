// @flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { useStateContainer } from './StateContainer';
import { SET_AUTH_TOKEN, logOut } from '../../components/auth/talents/actions';
import { events, paths } from '../constants';
import { useVisitorTrails } from '../../components/tracking/visitor-trails/VisitorTrailsContext';

type Ctx = any;

const { createContext, useEffect } = React;

const EventsInterceptorContext = createContext<Ctx>({});

const EventsInterceptorProvider = withRouter(({ history, children }) => {
  const [, dispatch] = useStateContainer();
  const { getTrail, visitorTrails } = useVisitorTrails();
  const hasAccountTrail = getTrail(visitorTrails.VISITOR_HAS_ACCOUNT);

  const handleTokenExpired = () => {
    const handler = ({ detail }) => {
      const { useShortRegistration } = detail;
      logOut(dispatch);
      history.replace({
        pathname: hasAccountTrail ? paths.login : paths.register,
        search: useShortRegistration ? 'rtype=s' : null,
      });
    };

    window.addEventListener(events.NOT_AUTHENTICATED, handler);

    return () => window.removeEventListener(events.NOT_AUTHENTICATED, handler);
  };

  const handleSetAuthTokenEvent = () => {
    const handler = ({ detail }) => {
      dispatch({ type: SET_AUTH_TOKEN, authToken: detail.authToken });
    };

    // the SET_AUTH_TOKEN_EVENT is fired from the API,
    // when it sets the token in the local storage,
    // so we can update the react's internals
    window.addEventListener(events.SET_AUTH_TOKEN, handler);

    return () => window.removeEventListener(events.SET_AUTH_TOKEN, handler);
  };

  useEffect(() => handleTokenExpired());
  useEffect(() => handleSetAuthTokenEvent());

  return children;
});

export { EventsInterceptorContext, EventsInterceptorProvider };
