// @flow
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useDirectApplicationFormContainer } from './useDirectApplicationFormContainer';
import { useStateContainer } from '../../../../core/context/StateContainer';
import { paths } from '../../../../core/constants';
import { Loader } from '../../../basic/Loader';
import { DirectApplicationForm } from '../DirectApplicationForm';
import { useConsentDialogController } from '../../../../core/context/ConsentDialogController';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  container: {
    height: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1, 3),
    background: theme.palette.common.white,
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function DirectApplicationFormContainer() {
  const classes = useStyles();
  const history = useHistory();
  const queryString = useLocation().search;
  const { showConsent } = useConsentDialogController();
  const [
    {
      onboarding: {
        initializationReady,
        hydration: {
          loading,
          data: { onboardingComplete },
        },
      },
    },
  ] = useStateContainer();
  const { hydrateOnboardingState, startHydrationLoading } =
    useDirectApplicationFormContainer();

  useEffect(() => hydrateOnboardingState(), [hydrateOnboardingState]);
  useEffect(() => {
    showConsent({
      onClose: function redirectToRoot() {
        history.replace({ pathname: paths.root });
      },
    });
    if (!onboardingComplete && !loading && initializationReady) {
      // we're setting the onboarding loading flag in order to show the
      // loading indicator right after the redirection to the onboarding form page.
      startHydrationLoading();

      // the user has to finish his onboarding first,
      // so we perform a redirect to the onboarding page.
      history.replace({
        pathname: paths.onboarding,
        search: queryString,
      });
    }
  }, [
    history,
    queryString,
    showConsent,
    startHydrationLoading,
    onboardingComplete,
    loading,
    initializationReady,
  ]);

  const LoaderContainer = () => (
    <div className={classes.loaderContainer}>
      <Loader />
    </div>
  );

  const formOrNull = onboardingComplete ? <DirectApplicationForm /> : null;
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        {loading && !initializationReady ? <LoaderContainer /> : formOrNull}
      </div>
    </div>
  );
}

export { DirectApplicationFormContainer };
