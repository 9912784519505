// @flow
import { makeStyles } from '@material-ui/core';
import { serverJobTypes } from '../../core/constants';
import { uniqNum } from '../../utils/uniq';
import type { ServerJob } from './JobsContext';
import type { Image } from '../companies/common/context/CompanyContext';
import { convServerCompanyToCompany } from '../companies/common/utils';

export const serverJobTypeToString = (
  jobType: $Keys<typeof serverJobTypes>
) => {
  return serverJobTypes[jobType];
};

export const yesNoToBool = (value: 'yes' | 'no' | '') => value === 'yes';
export const boolToYesNo = (bool: boolean) => (bool ? 'yes' : 'no');
// const daysToYesNo = (days: number) => (days > 0 ? 'yes' : 'no');
export const extractVal = (item: { value: any }) => item.value;
export const addIconProp = (item: { value: any }) => ({
  icon: null,
  title: item.value,
});
export const jobConvUtils = {
  toServer: {
    jobType(job: any) {
      const jobType = job.type;
      if (jobType === 'partTime') return 'PART_TIME';
      if (jobType === 'remote') return 'REMOTE';
      return 'FULL_TIME';
    },
    hiringProcessSteps(job: any) {
      return job.hiringProcessSteps.map(extractVal);
    },
    tools(job: any) {
      return job.tools.map(extractVal);
    },
    timeSpendingDetails(job: any) {
      return job.timeSpendingDetails.map((t) => ({
        timePercents: t.value,
        title: t.content,
      }));
    },
    requirements(job: any) {
      return job.coreRequirements.map(addIconProp);
    },
    responsibilities(job: any) {
      return job.responsibilities.map(addIconProp);
    },
    perks(job: any) {
      return job.perks.map(addIconProp);
    },
    salary(job: any) {
      const { salary } = job;
      if (salary.includes('-')) {
        const [min, max] = salary.split(('-')).map((n) => Number(n));
        return {min, max};
      }
      if (salary.includes('<')) return {min: 0, max: Number(salary.replace('<', ''))};
      if (salary.includes('>')) return {min: Number(salary.replace('>', '')), max: 0};
      return {min: 0, max: 0};
    },
    teamLeadImage(job: any) {
      if (!job.teamLeadImage) return job.teamLeadImage;
      return {
        id: job.teamLeadImage.id,
        collection: 'team-lead',
        name: job.teamLeadImage.src,
      };
    },
    teamSize(job: any) {
      const { teamSize } = job;
      const nums = teamSize.split('-').map((n) => Number(n));
      return { min: nums[0], max: nums[1] };
    },
    homeOffice(job: any) {
      const val = job.homeOffice;
      return val === 'no' ? 0 : Number(val);
    },
    productImages(job: any) {
      if (!job.productImages || !job.productImages.length)
        return job.productImages;

      return job.productImages.map((image) => ({
        id: image.id,
        collection: 'job-product',
        name: image.src,
      }));
    },
  },
  toClient: {
    jobType(job: ServerJob) {
      if (job.jobType === 'PART_TIME') return 'partTime';
      if (job.jobType === 'REMOTE') return 'remote';
      return 'fullTime';
    },
    coreRequirements(job: ServerJob) {
      return job.requirements.map<any>((req) => ({
        id: uniqNum(),
        value: req.title,
      }));
    },
    responsibilities(job: ServerJob) {
      return job.responsibilities.map<any>((res) => ({
        id: uniqNum(),
        value: res.title,
      }));
    },
    hiringProcessSteps(job: ServerJob) {
      return job.hiringProcessSteps.map<any>((hp) => ({
        id: uniqNum(),
        value: hp,
      }));
    },
    perks(job: ServerJob) {
      return job.benefits.map<any>((b) => ({
        id: uniqNum(),
        value: b.title,
      }));
    },

    teamLeadImage(job: ServerJob) {
      if (!job.teamLeadImage) return null;

      return {
        ...job.teamLeadImage,
        blob: null,
        src: `https://scontent.noblehire.io/${job.teamLeadImage.name}`,
      };
    },

    productImages(job: ServerJob): Image[] {
      if (!job.productImages || !job.productImages.length) return [];

      return job.productImages.map((image) => ({
        ...image,
        blob: null,
        src: `https://scontent.noblehire.io/${image.name}`,
      }));
    },

    timeSpendingDetails(job: ServerJob) {
      return job.activities.map<any>((a) => ({
        id: uniqNum(),
        value: a.timePercents,
        content: a.title,
      }));
    },

    tools(job: ServerJob) {
      return job.tools.map<any>((t) => ({
        id: uniqNum(),
        value: t,
      }));
    },
  },
};

const convSalary = (job: any) => {
  const {salaryMin, salaryMax} = job;
  const min = salaryMin;
  const max = salaryMax;
  if ((min === undefined || min === null) && (max === undefined || max ===  null)) return '';
  if ((min !== undefined && min !== null && min > 0) && (max !== undefined && max !== null && max > 0)) return `${min}-${max}`;
  if ((min !== undefined && min !== null && min > 0) && (max === undefined || max === null || max === 0)) return `>${min}`;
  if ((max !== undefined && max !== null && max > 0) && (min === undefined || min === null || min === 0)) return `<${max}`;
  return '0-0';
};

export const convServerJobToJob = (job: any) => {
  const { toClient } = jobConvUtils;

  return {
    company: convServerCompanyToCompany(job.company),
    id: job.id,
    isPublic: job.public,
    slug: job.slug,
    title: job.title,
    location: job.locations.map((l) => ({
      ...l,
      address: JSON.parse(l.address),
    })),
    description: job.description,
    postedAt: job.postedAt,
    role: job.role,
    seniority: job.seniority,
    type: toClient.jobType(job),
    stockOptions: boolToYesNo(job.offeringStock),
    salary: convSalary(job), 
    salaryCurrency: job.salaryCurrency,
    homeOffice: job.homeOfficeDays,
    teamSize: `${job.teamSizeMin}-${job.teamSizeMax}`,
    fullyRemote: boolToYesNo(job.fullyRemote),
    customerFacing: boolToYesNo(job.customerFacing),
    businessTravel: boolToYesNo(job.businessTraveling),
    primaryLanguage: job.primaryLanguage,
    primaryPlatformOrFramework: job.primaryPlatform,
    secondaryLanguage: job.secondaryLanguage,
    secondaryPlatformOrFramework: job.secondaryPlatform,
    mainDatabase: job.mainDatabase,
    coreRequirements: toClient.coreRequirements(job),
    responsibilities: toClient.responsibilities(job),
    hiringProcessSteps: toClient.hiringProcessSteps(job),
    perks: toClient.perks(job),
    teamLeadImage: toClient.teamLeadImage(job),
    teamLeadName: job.teamLeadName,
    teamLeadRole: job.teamLeadRole,
    teamLeadLink: job.teamLead,
    timeSpendingDetails: toClient.timeSpendingDetails(job),
    tools: job.tools.map((t) => ({ id: Math.random(), value: t })),
    productImages: toClient.productImages(job),
    productDescription: job.productDescription,
  };
};

export const convJobToServerJob = (job: any) => {
  const { toServer } = jobConvUtils;

  const locationsArray = job.location
    ? job.location.map((l) => ({ id: l.id }))
    : null;

  return {
    id: job.id,
    slug: job.slug,
    companyId: job.companyId,
    title: job.title,
    locations: locationsArray,
    seniority: job.seniority,
    primaryLanguage: job.primaryLanguage,
    primaryPlatform: job.primaryPlatformOrFramework,
    secondaryLanguage: job.secondaryLanguage,
    secondaryPlatform: job.secondaryPlatformOrFramework,
    mainDatabase: job.mainDatabase,
    description: job.description,
    public: job.isPublic,
    jobType: toServer.jobType(job),
    jobTypeComment: job.jobTypeComment,
    customerFacing: yesNoToBool(job.customerFacing),
    businessTraveling: yesNoToBool(job.businessTravel),
    businessTravelComment: job.businessTravelComment,
    stockOptions: yesNoToBool(job.stockOptions),
    teamLead: job.teamLeadLink,
    hiringProcessSteps: toServer.hiringProcessSteps(job),
    tools: toServer.tools(job),
    activities: toServer.timeSpendingDetails(job),
    requirements: toServer.requirements(job),
    responsibilities: toServer.responsibilities(job),
    benefits: toServer.perks(job),
    offeringStock: yesNoToBool(job.stockOptions),
    role: job.role,
    salaryMin: toServer.salary(job).min,
    salaryMax: toServer.salary(job).max,
    salaryCurrency: job.salaryCurrency,
    salaryPeriod: job.salaryPeriod,
    teamLeadImage: toServer.teamLeadImage(job),
    teamSizeMin: toServer.teamSize(job).min,
    teamSizeMax: toServer.teamSize(job).max,
    homeOfficeDays: toServer.homeOffice(job),
    homeOfficePer: 'WEEK',
    fullyRemote: yesNoToBool(job.fullyRemote),
    teamLeadName: job.teamLeadName,
    teamLeadRole: job.teamLeadRole,
    productImages: toServer.productImages(job),
    productDescription: job.productDescription,
  };
};

export const txtColor = (theme: any, isEdit: boolean = true) =>
  isEdit ? theme.palette.common.black : theme.palette.common.black;

export const useStyles = makeStyles((theme) => {
  return {
    textarea: {
      fontFamily: theme.typography.fontFamily,
      width: '100%',
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.grey[300]}`,
      resize: 'none',
      borderRadius: theme.shape.borderRadius,
    },
    input: {
      fontFamily: theme.typography.fontFamily,
      width: '100%',
      padding: theme.spacing(1),
      height: 40,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    inputWithError: {
      width: '100%',
      padding: theme.spacing(1),
      height: 40,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.error.main}`,
    },
    listItem: {
      width: '100%',
      '&:hover': {
        background: theme.palette.grey[100],
      },
    },
    listItemDarkHover: {
      width: '100%',
      '&:hover': {
        background: theme.palette.grey[200],
      },
    },
  };
});
