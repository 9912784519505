// @flow
import { makeStyles } from '@material-ui/core';
import React from 'react';
import {
  Popup,
  PopupContainer,
  PopupMain,
  PopupTitle,
} from '../../basic/Popup';
import { useRequestCompanyProfile } from './RequestCompanyProfileProvider';
import { RequestProfileForm } from './RequestProfileForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    fontSize: theme.typography.pxToRem(18),
    border: 0,
    background: 'transparent',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },
}));

export function RequestProfilePopup() {
  const classes = useStyles();
  const { isOpen, closePopup } = useRequestCompanyProfile();

  return (
    <Popup isOpen={isOpen} maxWidth={600} onClose={closePopup}>
      <PopupContainer>
        <PopupTitle>
          <div className={classes.title}>
            <span>Request profile</span>
            <button
              type="button"
              className={classes.closeButton}
              onClick={closePopup}
            >
              <FontAwesomeIcon icon={['fal', 'times']} />
            </button>
          </div>
        </PopupTitle>
        <PopupMain>
          <RequestProfileForm />
        </PopupMain>
      </PopupContainer>
    </Popup>
  );
}
