// @flow
import { useStateContainer } from '../../../core/context/StateContainer';
import { startOnboardingSection, setStep } from '../actions';

function useGoToPrevSectionOnStep() {
  const [
    {
      onboarding: { currentSection },
    },
    dispatch,
  ] = useStateContainer();

  function goToPrevSectionOnStep(step: number) {
    dispatch(startOnboardingSection(currentSection - 1));
    dispatch(setStep(step));
  }

  return goToPrevSectionOnStep;
}

export { useGoToPrevSectionOnStep };
