// @flow
import { auth } from '../../components/auth/talents/reducer';
import { modals } from '../../components/modals/reducer';
import { notifications } from '../../components/notifications/reducer';
import { onboarding } from '../../components/onboarding-talents/reducer';
import { referrals } from '../../components/referrals/reducer';
import { visitorTrails } from '../../components/tracking/visitor-trails/reducer';
import type { Action, State } from '../types';
import { confirmations } from '../../components/confirmations/reducer';

export type RootState = {
  [key: string]: any,
};

const initialState: RootState = {
  auth: auth.initialState,
  modals: modals.initialState,
  notifications: notifications.initialState,
  confirmations: confirmations.initialState,
  onboarding: onboarding.initialState,
  referrals: referrals.initialState,
  visitorTrails: visitorTrails.initialState,
};

const root = (state: State & typeof initialState, action: Action) => {
  return {
    ...state,
    auth: auth(state.auth, action),
    modals: modals(state.modals, action),
    notifications: notifications(state.notifications, action),
    confirmations: confirmations(state.confirmations, action),
    onboarding: onboarding(state.onboarding, action),
    referrals: referrals(state.referrals, action),
    visitorTrails: visitorTrails(state.visitorTrails, action),
  };
};

export { root, initialState };
