// @flow
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../../../basic/form/styles/useStyles';
import { Form } from '../../../basic/form/Form';
import { Close } from '../../../basic/form/Close';
import { SyntheticStepsIndicator } from '../../components/SyntheticStepsIndicator';
import { useStateContainer } from '../../../../core/context/StateContainer';

type Props = {
  useHook: () => {
    initialValues: any,
    onFormSubmit: Function,
    validateStep: (step: number) => any,
    currentStep: number,
    getSteps: ({ values: any }) => Object,
  },
  formProps?: any,
  // eslint-disable-next-line react/require-default-props
  spy?: React.Node,
};

function OnboardingForm({ useHook, formProps, spy, ...rest }: Props) {
  const history = useHistory();
  const classes = useStyles();
  const [
    {
      onboarding: { syntheticStepsCount },
    },
  ] = useStateContainer();
  const { initialValues, onFormSubmit, validateStep, currentStep, getSteps } =
    useHook();

  return (
    <div className={classes.root}>
      <Close onClick={() => Close.goToRoot(history)} />

      <Form
        onSubmit={onFormSubmit}
        validate={validateStep(currentStep)}
        formClassNames={classes.form}
        initialValues={initialValues === null ? {} : initialValues}
        {...rest}
      >
        {({ values }) => {
          const steps = getSteps({ values });
          return (
            <>
              <div className={classes.formRoot}>
                <SyntheticStepsIndicator allSteps={syntheticStepsCount} />
                {steps[currentStep]}
              </div>

              {spy || null}
            </>
          );
        }}
      </Form>
    </div>
  );
}

OnboardingForm.defaultProps = { formProps: {} };

export { OnboardingForm };
