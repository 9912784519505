// @flow
import * as React from 'react';
import { AuthFrame } from '../../components/auth/talents/components/AuthFrame';
import { RegistrationForm } from '../../components/auth/companies/components/RegistrationForm';
import { HideTopArrow } from '../../components/wordpress/HideTopArrow';
import { DisableScrollOnMount } from '../../components/basic/DisableScrollOnMount';

const Registration = () => {
  return (
    <DisableScrollOnMount>
      <HideTopArrow>
        <AuthFrame>
          <RegistrationForm />
        </AuthFrame>
      </HideTopArrow>
    </DisableScrollOnMount>
  );
};

export { Registration };
