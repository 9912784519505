// @flow
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams, Redirect } from 'react-router-dom';
import { JobCreate } from '../../components/jobs/JobCreate';
import { JobCreateContext } from '../../components/jobs/JobCreateContext';
import { SlidingPageShell } from './SlidingPageShell';
import { paths } from '../../core/constants';
import { getCompanyDataRequest } from '../../components/companies/common/companiesService';
import { FullPageLoader } from '../../components/basic/FullPageLoader';
import { convServerCompanyToCompany } from '../../components/companies/common/utils';
import { useMyCompany } from '../../components/companies/common/context/MyCompanyContext';
import { PageHead } from '../../components/basic/PageHead';
import { useStateContainer } from '../../core/context/StateContainer';

function Preview() {
  const history = useHistory();
  const params = useParams();
  return (
    <SlidingPageShell
      loading={false}
      onHideAnimDone={() => {
        history.push({
          pathname: paths.jobsCreate.replace(':slug+', params.slug),
          search: '',
        });
      }}
    >
      {() => <JobCreate asPreviewBg={false} />}
    </SlidingPageShell>
  );
}

export function JobCreatePage() {
  const [
    {
      auth: { user, gettingUser, authenticated },
    },
  ] = useStateContainer();

  if (!authenticated && !user && !gettingUser.loading) {
    return <Redirect to={paths.root} />;
  }

  if (!authenticated || (!user && gettingUser.loading)) {
    return (
      <FullPageLoader>
        Generating your interplanetary job template...
      </FullPageLoader>
    );
  }

  return <CreateJob />;
}

function CreateJob() {
  const { slug } = useParams();
  const loc = useLocation();
  const isP = loc.search.includes('preview=true');
  const myCompany = useMyCompany();
  const [
    {
      auth: { user, isAdmin },
    },
  ] = useStateContainer();
  const [company, setCompany] = useState<any>(null);
  useEffect(() => {
    getCompanyDataRequest(slug).then((data) =>
      setCompany(convServerCompanyToCompany(data.data))
    );
  }, [slug]);

  if (!company || (!isAdmin(user) && myCompany.loading))
    return (
      <FullPageLoader>
        Generating your interplanetary job template...
      </FullPageLoader>
    );

  if (myCompany.slug !== company.slug && !isAdmin(user)) {
    return <Redirect to={paths.root} />;
  }

  return (
    <JobCreateContext company={company} mode={isP ? 'preview' : 'edit'}>
      <PageHead
        title={`${isP ? 'Preview Job Ad' : 'Create Job Ad'} | NobleHire`}
      />
      {isP ? <Preview /> : null}
      <JobCreate asPreviewBg={isP} />
    </JobCreateContext>
  );
}
