// @flow
import React from 'react';
import { SideMenuTypes } from '../../context/TopMenu';
import { UserControlsBox } from './boxes/UserControlsBox';
import { ReferralsBox } from './boxes/ReferralsBox';
import { SiteNavigationBox } from './boxes/SiteNavigationBox';
import { NotificationsBox } from './boxes/NotificationsBox';

type Props = {
  sideMenuType: ?$Values<typeof SideMenuTypes>,
  classes: any,
};

function Content({ sideMenuType, classes }: Props) {
  function NotificationsSideMenu() {
    return <NotificationsBox />;
  }

  function ReferralsSideMenu() {
    return <ReferralsBox menuClasses={classes} />;
  }

  function UserSideMenu() {
    return (
      <div>
        <UserControlsBox classes={classes} />
      </div>
    );
  }

  function SiteNavigation() {
    return <SiteNavigationBox />;
  }

  let BoxComponent;

  if (sideMenuType === SideMenuTypes.notifications) {
    BoxComponent = NotificationsSideMenu;
  } else if (sideMenuType === SideMenuTypes.referrals) {
    BoxComponent = ReferralsSideMenu;
  } else if (sideMenuType === SideMenuTypes.user) {
    BoxComponent = UserSideMenu;
  } else {
    BoxComponent = SiteNavigation;
  }

  return <BoxComponent />;
}

export { Content };
