// @flow
import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { Footer } from '../components/Footer';
import { PageHead } from '../components/basic/PageHead';

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    padding: theme.spacing(4, 0),
  },
}));

export function TermsAndConditions() {
  const classes = useStyles();

  return (
    <div>
      <PageHead title="Terms & Conditions | Noble Hire" />
      <Container>
        <div className={classes.innerContainer}>
          <h1>GENERAL TERMS AND CONDITIONS</h1>
          <p>
            The present TERMS AND CONDITIONS were last updated on 20.10.2021
          </p>
          <p>
            PLEASE NOTE THAT „NOBLE TECH“ LTD. DOES NOT IN ANY WAY FINANCE THE
            MEMBERSHIP OF USERS IN THE WEBSITE. THE REALIZATION OF THE RESULTS
            IN CONNECTION TO OUR SERVICES ARE BASED SOLELY ON THE DECISIONS OF
            USERS. THE ACCEPTANCE OF THE PRESENT TERMS AND CONDITIONS IS A
            COMPULSORY PREREQUISITE FOR THE USE OF THE WEBSITE.
          </p>
          <p>
            Noble Hire Platform is a referral-based job board connecting job
            seekers with companies looking for talents. Noble Hire Platform is
            developed and operated by „NOBLE TECH“ LTD and it is accessible on
            www.noblehire.io.
          </p>
          <p>
            „NOBLE TECH“ LTD. may modify the present General Terms and
            Conditions at any time on the website – www.noblehire.io, at its own
            discretion. At „NOBLE TECH“ LTD., We make sure you are well-posted
            about all modifications on our website and we shall try our best to
            always inform you in connection to any amendments. However, please
            make sure you check the date of the last modifications of the Terms
            and Conditions whenever you use the website. In case you do not
            agree to the new modifications of the Terms and Conditions, please
            contact the team of „NOBLE TECH“ LTD. through an email, requesting
            the deletion of your account or personal data if such are provided
            already. If you do not agree with the terms incorporated within this
            document, do not use the services, which are provided by „NOBLE
            TECH“ LTD. through the website www.noblehire.io. However, if you
            continue to use the services, we shall consider these actions as
            acceptance of the previously mentioned amendments.
          </p>
          <p>
            The present General Terms and Conditions („Terms“) apply exclusively
            and solely for this website- www.noblehire.io. The access to the
            services provided by „NOBLE TECH“ LTD. (referred to as “Noble”,
            “US”, “WE”, “the company”) through this website is a subject to
            prior acceptance to these Terms and Conditions. We consider the
            prior acceptance of the General Terms and Conditions, , the Privacy
            Policy, the Cookies Policy and the provision of personal information
            in the forms as an explicit electronic statement presented in
            digital form for the acceptance of the above-mentioned rules
            according to article 2 of the Electronic Document and Electronic
            Trust Services Act, applicable on the territory of the Republic of
            Bulgaria.
          </p>
          <p>
            „NOBLE TECH“ LTD. shall be considered an Intermediary between
            Employers and Employees with the sole purpose of recruitment of
            personnel. The submission, collection and processing of personal
            data in connection to the use of our website is a subject of our
            Privacy Policy, which is duly compliant to Regulation (EU) 2016/679
            of the European Parliament and of the Council of 27 April 2016 on
            the protection of natural persons with regard to the processing of
            personal data and on the free movement of such data (referred to as
            GDPR).
          </p>
          <p>
            Once you agree to the Terms and Conditions, we consider this an
            explicit consent of approval to the following:
          </p>
          <p>
            <strong>DEFINITIONS</strong>{' '}
          </p>
          <p>
            1.1. “NOBLE TECH“, “Noble“, “US“, “WE“, “The company” refers to
            „NOBLE TECH“ LTD, a limited liability company incorporated under the
            laws of the Republic of Bulgaria, and having its registered address
            at 1408 Sofia city, Trizditza district area, Ivan Vazov res. area, 1
            Yanko Zabunov str., fl.3, ap.5, UIC: 205211882, legally represented
            by: Georgi Ivanov Ivanov, email for communication to Noble Tech:
            ivanov@nobletech.io. Noble is duly licensed company for performing
            services for selection and activities related to selection and
            recruitment of Personnel, licensed from the Bulgarian Ministry of
            Labor and Social Policy, license № 2663/12.11.2018 („License“).
          </p>
          <p>
            1.2. “User” shall mean any individual or legal entity who in any way
            accesses the website and/or uses the company’s services, including:
          </p>
          <p>
            1.2.1. “Employer” shall mean a user- individual or legal entity-
            with a company profile who publishes job announcements for open
            positions with the purpose of recruitment of Candidates through the
            Platform. Employers afterwards at their own discretion may offer the
            Candidate a position, different than the one initiniatlly applied
            for, based on conducted selection process.
          </p>
          <p>
            1.2.2. “Referrer” shall mean any user- individual or legal entity-
            with an account on the Platform who may refer Candidates for job
            announcements made by Employers based on subjective criteria of the
            Referrer for compatibility and who receives remuneration (“Referral
            bonus”) for each successful hire upon recommendation.
          </p>
          <p>
            1.2.3. “Candidate” shall mean any individual with an account who can
            apply for open job positions on the Platform independently or with a
            referral from a Referrer.
          </p>
          <p>
            1.3. “Intermediary service” shall mean the activities of the
            Intermediary aimed at connecting Employers and Candidates, through
            the Platform, including the estimation of potential matching of
            individual Candidate with the open job position requirements of the
            specific Employer.
          </p>
          <p>
            1.4. “Noble Hire Platform”, “The Platform” shall mean the Internet
            website on which Employers, after creating a company account, have
            the possibility to publish offers for recruitment of Candidates for
            open job positions offered by the Employer; the Referrals may refer
            Candidates to the Employers, who upon their discretion, meet the job
            position criteria set by the Employers, and the Candidates have the
            possibility to self-register on an autonomous basis and apply for
            available and open job positions.{' '}
          </p>
          <p>
            1.5. “Sensitive Company Information” may be requested on the Noble
            Platform and it presents information about Employers, including but
            not limited to the legal name, address, industry, web address,
            pictures of premises and team events with employees present in them,
            company achievements, mission, vision, goals, values, job vacancies
            and related details; the details may include job descriptions, job
            roles and responsibilities, offered salary and remuneration package
            details, hiring manager and team details (names and pictures),
            culture (culture elements, engagement practices).
          </p>
          <p>
            1.6. “Hiring fee” or “Pay-per-hire fee” shall mean any remuneration
            received by Noble from Employers for the provision of Intermediary
            services based on an individual agreement, signed between the
            Parties, payable in case of successful hire of a Candidate through
            the Platform, to an open job position, announced by the Employer.
            Its amount depends on the payment option explicitly selected by the
            Employer in the concluded individual agreement.
          </p>
          <p>
            1.7. “Monthly subscription fee” shall mean a monthly remuneration
            received by Noble from Employers for the provision of Intermediary
            service. Its amount depends on the payment option explicitly
            selected by the Employer in the concluded individual agreement.{' '}
          </p>
          <p>
            1.8. “Referral bonus” shall mean any remuneration receipt by a
            Referrer under the form of percentage of the hiring fee for certain
            performed tasks on the Noble Hire platform, subject to prior to
            negotiation and signing of an individual contract with Noble,
            including:
          </p>
          <p>
            1.8.1. Referral of a Candidate who shall be hired by an Employer;
          </p>
          <p>
            1.8.2. Any other task, which explicitly promises money reward
            according to Noble’s policy.
          </p>
          <p>
            1.9. “Referral / application status update” shall incorporate the
            obligations of Noble and the Employers to submit information to the
            Referrer or the Candidate on the stage of their recommendations or
            applications.
          </p>
          <p>
            1.10. “Terms” shall collectively refer to the present Terms and
            Conditions.
          </p>
          <p>
            1.11. “Confidential Information” shall mean any and all technical
            and non-technical information including but not limited to patent,
            copyright, trademark, trade secret, and proprietary information, the
            agreements signed with Noble and the content incorporated within the
            documents, other documentation, data and information provided to
            users by Noble for the purpose of provision of the services,
            know-how, processes, apparatus, equipment, algorithms, software
            programs, software source documents, and formula related to current
            and future proposed products and services of Noble or the users,
            formulas for calculation of remuneration, respective information
            concerning research, experimental work, development, financial
            information, business forecasts, and marketing plans, reports and
            information, business plans, information for associates, trading
            documentation, business partners, project documentation, lists of
            employees or their personal data, contracts, contractual relations,
            template agreements, policy and procedures, wage formation methods,
            financial information and any other information which is not public,
            is not subject to obligatory publication provided for in the law or
            instructions by state or judicial authorities. Confidential
            information is considered the one including the disclosure of a
            user’s personal or identifiable information to another user for the
            purposes of recruitment. Confidential Information shall include any
            copies, analysis or abstracts made thereof.
          </p>
          <p>
            <strong>GENERAL PROVISIONS</strong>{' '}
          </p>
          <p>
            Please, bear in mind that Noble does not in any way independently
            and in the capacity of an employer offer any working places and job
            positions. The company does not guarantee in any way the success of
            the recruitment process. Nothing in the present Terms or the content
            of the website shall be interpreted hereinafter directly as making
            either party an employer, contractor or employee of the other. Noble
            appears an Intermediary between the Employer and the Employee by
            undertaking all necessary activities to provide the respective
            services of searching, assessing, selecting and presenting a list of
            applicants for the positions included on the website. Noble reserves
            its right to conduct the selection freely and at its own discretion.
          </p>
          <p>
            In order to use the services provided on the website, you must have
            reached the legally permitted age to enter into legally binding
            contracts in the corresponding roles as Employer, Referrer or
            Candidate. Noble shall not be held liable if a person, who is under
            the legally permitted age, uses the services of the website by
            providing untruthful information.
          </p>
          <p>
            For author of the electronic statements we shall consider the
            individual indicated in the statement as its author. Signatory of
            the electronic statements shall be the Users on whose behalf the
            electronic statements is made according to article 4 of the
            Electronic Document and Electronic Trust Services Act of Bulgaria.
            Noble is not responsible in any way for the non-compliance with the
            legislation.
          </p>
          <p>
            The contacts and information of Employers, their representatives,
            Candidates and Referrers may be used only for the intended purpose,
            namely in relation to applications for the specific job positions.
            Users explicitly agree not to use these data for any other occasion.
          </p>
          <p>
            The decisions you take as a User, in connection to the information
            you provide to other Users and of other Users, the business
            relations you would like to enter into with other Users and under
            what conditions, are entirely and solely based on your estimation
            and they are your responsibility.
          </p>
          <p>
            Each User agrees that it will not make use of, disseminate, or in
            any way circulate any Confidential Information of the company or
            other Users, which is supplied to or obtained during the use of the
            website and our Services outside the purposes and scope for which
            they have been disclosed. The restriction is not applicable to the
            extent necessary for negotiations, discussions, and consultations
            with personnel or authorized representatives of the user. Users
            further agree that they shall not disclose or publish any
            Confidential Information of other users or of the company to any
            third party and that they shall use their best efforts to prevent
            inadvertent disclosure of such Confidential Information to any third
            party.
          </p>
          <p>
            <strong>
              SECTION I. GENERAL TERMS AND CONDITIONS FOR EMPLOYERS
            </strong>
          </p>
          <p>
            A compulsory prerequisite for the provision of the intermediary
            services is the signing of an individual agreement with Noble and
            the creation of a company profile. The present Terms and Conditions
            shall be considered an inseparable part of future agreements
            concluded between Employers and Noble.{' '}
          </p>
          <p>
            <strong>1. COMPANY PROFILE AND ANNOUNCEMENTS</strong>
          </p>
          <p>
            For the purposes of recruitment, Employers create free of charge
            company profiles and fill in the information required by the
            Platform.
          </p>
          <p>
            Employers upload and announce job positions at their own discretion
            and according to their needs and capacity. Employers are solely
            responsible for the truthfulness of the information they publish.{' '}
          </p>
          <p>
            The content uploaded on the company account and announcements are
            subject of preliminary approval by Noble before their publishing on
            the Platform.{' '}
          </p>
          <p>
            In relation to the creation of the company profile, its maintenance,
            job announcements and information uploaded on the Noble Hire
            Platform, the current employees of the Employer should give explicit
            consent to the Employer for their participation in the presentation.
          </p>
          <p>
            When Employers provide pictures and videos for the property on the
            Noble Hire Platform, they should reflect the conditions and quality
            of the services adequately and accurately.
          </p>
          <p>
            By accepting the present Terms and conditions, the Employers
            explicitly agree for Noble to use objects of intellectual property
            of the Employer, including but not limited to trademarks under the
            form of name, logo, symbol, image, phrase, word or combination of
            any of these elements, photographs, videos, etc., for the purpose of
            existence of the company account on the Platform. The Employer has
            the right to restrict the use of the intellectual property objects
            by Noble by indicating which objects can be presented on the
            Platform and which objects cannot.
          </p>
          <p>
            The Employer shall present any information, which is necessary for
            the selection including but not limited to expectations, criteria
            and requirements for the positions and any other relevant
            information, which may be required by Noble. Noble shall not be held
            liable for the selection of Candidates who are not suitable
            according to the Employer.
          </p>
          <p>
            <strong>2.</strong>{' '}
            <strong>RIGHTS AND OBLIGATIONS OF THE PARTIES</strong>
          </p>
          <p>
            Upon conclusion of the individual agreement and creation of a
            company account, the Employer lists its open job positions, for
            which the Candidates may apply, together with the requirements to be
            met by the applicants.{' '}
          </p>
          <p>
            Noble notifies the Employer through notification on its dashboard
            for each new Candidate for the respective open job position. The
            notification includes the Candidate’s short CV, his/her contact
            details, as well as any information provided by the Candidate for
            the purposes of the recruitment. Subject to receipt of such details
            and information from Noble, the Employer shall be entitled to
            contact such Candidate. The obligations of Noble for the provision
            of the intermediary services shall be considered duly executed in
            the above-mentioned case that the Client himself makes a contact,
            performs an interview and concludes a contract with a Candidate from
            the Platform.
          </p>
          <p>
            Noble additionally facilitates the Employer’s recruitment procedure
            through activities of estimating the matching of the Candidates with
            the Employer’s specific requirements and notifying the Employer
            about suitable applicant/s among those who have self-registered and
            applied independently for the open job position or via reference
            from the Referrers.
          </p>
          <p>
            Within 3 business days of the receipt of every Candidate application
            (individual or through Referral), the Employer notifies Noble, in a
            written form, about its decision to proceed with the selection
            process, with the purpose of hiring the Candidate, or to refuse the
            application, due to the Candidate not meeting the requirements. The
            notification is provided through markup of the Candidate’s status in
            the Employer’s dashboard or through an explicit e-mail by the
            Employer.
          </p>
          <p>
            If the Employer decides to proceed with the Candidate’s application,
            he is required to present in a reasonable period of time status
            update in connection to the selection process stage. The requests
            for such checkups should be made through email correspondence with
            Noble. Direct connection with the Employer and request of status
            update from other Users is not allowed.
          </p>
          <p>
            The Employer notifies Noble about each successfully hired Candidate
            and the amount of the agreed gross remuneration, within 3 calendar
            days, as of conclusion of the employment contract, via a formal
            e-mail,{' '}
          </p>
          <p>
            <strong>3. REMUNERATION</strong>
          </p>
          <p>
            The Employer owes Noble remuneration for the provision of the
            Intermediary services.
          </p>
          <p>
            Noble provides different payment options, which include pay-per-hire
            fee, monthly subscription fee and a combination between them.
          </p>
          <p>
            The specific amount of the remuneration and the terms of payment
            depend on the Price terms’ payment option explicitly selected by the
            Employer upon signing of the individual agreement with Noble.
          </p>
          <p>
            If the payment option selected by the Employer includes pay-per-hire
            fee, such fee is due if the Employer hires Candidate within 12
            (twelve) months upon receipt of his/her application for open job
            position via the Platform (alone or via Referral). The amount of the
            pay-per-hire fee shall be determined based on the position actually
            occupied by the candidate and the applicable Price terms.
          </p>
          <p>
            If the Employer hires a candidate, introduced by Noble, on a
            different position than the one initially announced at the Platform,
            the intermediary services shall be considered dully provided and he
            owes Noble a pay-per-hire fee, which amount shall be determined
            based on the position actually occupied by the candidate and the
            applicable Price terms.
          </p>
          <p>
            The Employer does not owe a pay-per-hire fee in the following cases:
          </p>
          <p>
            (i) for hiring a Candidate(s) who during the past 12 months has
            applied for an Employer’s open job position through another
            electronic channel/platform other than the Platform in which case it
            is considered that the Candidate has already been present in the
            Employer’s database prior the provision of Noble’s Intermediary
            services. The receipt of the application in the above-mentioned
            12-months term via electronic means shall serve as evidence for the
            application of the present provision.
          </p>
          <p>
            (ii) for hiring a Candidate(s) who has been recommended via the
            Platform from an Employer’s current employee, effective as of the
            moment of reference and hiring. In such case the respective employee
            shall not have the right to receive any Referral bonus from Noble.
          </p>
          <p>
            4. <strong>GUARANTEE</strong>
          </p>
          <p>
            Noble provides the Employers a guarantee for good performance
            applicable in case of termination of the employment agreement with a
            hired Candidate, within 3 months as of the date of commencement of
            work, on one of the following grounds:
          </p>
          <p>
            (i) by the hired Candidate pursuant to art.326 of the Labour Code,
            or
          </p>
          <p>
            (ii) by the Employer, on the ground disciplinary dismissal (art.330,
            para 2, item 6 of the Labour Code) or absence of qualities of
            Candidate to effectively perform the assigned work (art.328, para 1,
            item 5 of the Labour Code) or during the probationary period agreed
            in the employment agreement on the grounds of art.71 of the Labour
            Code.
          </p>
          <p>
            The guarantee consists of full exemption from the pay-per-hire fee,
            upon hiring of a subsequent (s) Candidate (s), up to the amount of
            the pay-per-hire fee, paid upon hiring of the Candidate, the
            contract with whom has been terminated, on the grounds and within
            the term indicated above.
          </p>
          <p>
            In case the pay per hire fee, upon appointment of the subsequent
            Candidate is higher, the Employer shall pay the difference between
            the two fees.
          </p>
          <p>
            In case the pay-per-hire fee, upon appointment of the subsequent
            Candidate is lower, the parties shall apply the procedure for
            exemption from the pay-per-hire fee, until the amount of the
            pay-per-hire fee paid upon hiring of the Candidate whose contract is
            terminated is reached.{' '}
          </p>
          <p>
            The Employer shall notify Noble, in written form, about the
            termination of the legal relationship with the engaged applicant on
            the grounds indicated above, within 3 calendar days as of the date
            of termination of the employment agreement on one of the indicated
            legal grounds.
          </p>
          <p>
            The guarantee shall not come into effect in the event of non-payment
            or delayed payment (with more than 5 (five) days) by the Employer of
            the remuneration under the individual agreement due and payable to
            Noble for the initially selected Candidate.
          </p>
          <p>
            The guarantee shall not come into effect in the event the Employer
            concludes a service/consultant/management or a similar agreement,
            instead of an employment agreement, with the Candidate referred by
            the Intermediary.{' '}
          </p>
          <p>
            The guarantee does not cover the monthly subscription fees
            paid/payable by the Employer.{' '}
          </p>
          <p>
            In case of payment option, selected by the Employer, which includes
            a payment of the Referral’s bonus, the guarantee covers the Referral
            bonus paid by the Employer.
          </p>
          <p>
            5. <strong>CONFIDENTIAL INFORMATION</strong>
          </p>
          <p>
            Both, Noble and the Employer are obliged not to disclose or reveal
            to third party confidential information, acquired during the
            execution of their Agreement, including for unlimited term after its
            termination, unless the disclosure of information is required for
            the implementation of the Agreement or by the Law, or required by
            under the relevant court.{' '}
          </p>
          <p>
            Upon termination of the agreement, irrespective of the grounds
            therefore, Noble shall submit immediately to the Employer any and
            all originals and copies of documents, notes, reports and other
            hand-written or typed information or information in soft copies
            (like diskettes, audio and video cassettes), digitally burned data
            and anything which is at its disposal as of the moment thereof and
            which may contain confidential information.{' '}
          </p>
          <p>
            When Noble has suspended its active intermediary activity, the
            Employers are obliged to declare when requested by Noble a full
            status update on Candidates’ application stage.{' '}
          </p>
          <p>
            <strong>6. TERM AND VALIDITY OF THE AGREEMENT</strong>
          </p>
          <p>
            The Employer and Noble conclude a 1-year services agreement for
            provision of Intermediary services.
          </p>
          <p>
            The Agreement between the Employer and Noble may be terminated on
            the following legal grounds:{' '}
          </p>
          <p>
            (i) By elapsing of the contractual term, unless neither of the
            parties notifies the other party for its intention to terminate the
            contract, not later than 1 (one) month prior expiration of the
            contractual term, in which case, the contract term shall be
            automatically extended with 1 (one) year. The automatic extension
            shall apply for any consecutive year, until termination of the
            Agreement with 1-month written notice or on the other ground
            provided herein.
          </p>
          <p>(ii) By mutual written agreement of the parties;</p>
          <p>
            (iii) Unilaterally with 1 (one) month, written notice sent by one
            party to the other.{' '}
          </p>
          <p>
            (iv) Upon opening of insolvency or bankruptcy procedure against any
            of the parties.{' '}
          </p>
          <p>
            (v) Upon expiry/revocation of Noble’s license for personnel
            selection agency activities
          </p>
          <p>The contract can be rescinded:</p>
          <p>
            (i) Unilaterally with 30 (thirty) days written notification, by each
            party, if the other party is in failure to perform a contractual
            obligations, in case the respective failure has not been redeemed
            within the notice term from the negligent party.{' '}
          </p>
          <p>
            (ii) Unilaterally, without notification term, in cases of systematic
            breaches of obligations of the party pursuant to the present Terms
            and conditions and/or the individual agreement, as well as in case
            of not paying or delayed payment (with more than 5 calendar days) of
            the due remuneration, by the Employer.
          </p>
          <p>
            In case the Employer terminates the Agreement after provision of a
            respective service by Noble, he shall pay remuneration for the
            provided services up to the date of termination, pursuant to the
            provisions of the present General terms and conditions and the
            individual agreement signed with the Employer.{' '}
          </p>
          <p>
            In case of signed individual agreement providing monthly
            subscription payment option, the respective overall annual amount
            paid upfront by the Employer shall not be subject to a refund in
            case of termination of the agreement before the 1-year term elapses
            (including in case it has been automatically extended).{' '}
          </p>
          <p>
            <code> </code>
            <strong>
              SECTION II. GENERAL TERMS AND CONDITIONS FOR CANDIDATES
            </strong>
          </p>
          <p>
            <strong>1. GENERAL PROVISIONS</strong>
          </p>
          <p>
            Candidates are free to decide the job offers for which they would
            like to apply. We recommend the prior search of information about
            the Employer via the Noble Hire Platform or other sources.
          </p>
          <p>
            Noble is not responsible and shall not be held liable in any way
            for: the unsuccessful recruitment; failure to participate in
            selection for job announcements due to the subjective consideration
            for incompatibility with the job requirements; termination of any
            labor contract regardless of the grounds.
          </p>
          <p>
            Noble communicates actively with all Candidates via email and phone
            (when necessary) on the Platform regardless of whether Candidates
            have already applied or have been recommended. The communication is
            due to the purpose of insurance of high-quality service for
            Candidates.
          </p>
          <p>
            Candidates have four options for application on the Noble Hire
            Platform- they can (i) self-register without referral and apply for
            different position; (ii) they can use the Blank application
            according to item 2.of the present Section; they can (iii) accept a
            specific referral by a Referrer made through the Refer via CV option
            as described below, or (iv) they can click on a shareable referral
            link (“Invite link”) posted on social sites by Referrers and then
            register on the Platform.{' '}
          </p>
          <p>
            When a Referrer has made a referral of a specific Candidate on the
            Platform (also referred as “Refer via CV”), the latter is contacted
            by Noble via email informing about the referral and introducing them
            to the next steps and requirements of the procedure for application.
            In case the Candidate accepts the referral, he/she is then required
            to follow the generated link provided in the email for agreeing to
            the referral. Noble considers the referral approved if the Candidate
            has clicked on the active hyperlink. For the options involving
            referral by a Referrer, please check Section III. General terms and
            conditions for referrers, items 3.1. and 3.2.
          </p>
          <p>
            The Candidate is free to apply for any open job position, upon
            his/her own discretion. The acceptance of a referral does not
            preclude the option to apply independently for open job positions at
            other Employers. Noble prioritize the decision of the Candidate to
            apply via referral and continues sending recommendations for
            optional applying for newly opened job positions upon resolution on
            the referral by the respective Employer/s.
          </p>
          <p>
            <strong>2. BLANK APPLICATION</strong>
          </p>
          <p>
            Candidates can use the website function- “blank application”- they
            fill in information about themselves and send it to Noble’s team
            without application for a precise job position. The Candidate in
            this case assigns to Noble the task to search for an adequate job
            position for them. The blank application option is accessible by
            clicking the “Send Your Application” button and filling in the
            information needed. The time needed for search is subject to
            personal estimation by Noble. In case Noble has selected one or more
            options for recommendation, Candidates shall be contacted via email
            before approaching Employers. Candidates are given the possibility
            to preliminary confirm their interest for the job positions
            suggested by Noble. After approval by the Candidates, Noble contacts
            Employers with information about them.
          </p>
          <p>
            <strong>3. MARKETING ACTIVITY</strong>{' '}
          </p>
          <p>
            Noble encourages and believes that Candidates have all the right
            qualities to be called Talents. This is why Noble organizes
            different marketing campaigns in which Candidates have the
            possibility to participate freely. Such is the free gift boxes
            giveaway to all Candidates after successful recruitment by
            Employers, without significance if they have applied independently
            or via a referral.{' '}
          </p>
          <p>
            Noble conducts such campaigns at its own discretion. Please bear in
            mind that in case the Employer has received the specific Candidate’s
            application through a different platform, Agency or the Candidate
            has applied independently without Noble Hire Platform within 12
            (twelve) months preceding the job application through Noble Hire
            Platform, the Candidate shall not have the opportunity to
            participate in ANY of the marketing campaigns. This is due to the
            reason that the Employer already has the Candidate’s profile present
            in his database and Noble has not contributed to the process of
            recruitment. If Employers have received such prior applications,
            they have the obligation to inform Noble. Candidates also are given
            the opportunity to confirm or reject the statement of Employers.
            Employers are duly obliged to provide verification of such prior
            application which is not received through the Platform whenever
            needed to prove the truthiness of the statement. Noble is not
            responsible for the provision of untruthful information by the
            Employer which may be forged or deliberately manipulated.
          </p>
          <p>
            As mentioned above, all Candidates who have been successfully
            recruited by Employers will receive a free-gift-box giveaway without
            significance if they have applied independently or via a referral.
            The only conditions they have to comply with to receive such gift
            box are:
          </p>
          <p>
            They should be successfully recruited by an Employer; The
            recruitment should be made by Noble Hire’s Platform; Noble Hire
            should be the only platform via which the Candidate has been
            introduced to the Employer within 12 (twelve) months prior to the
            job application through Noble Hire Platform. Additionally to the
            gift boxes, only Candidates, who have applied independently without
            referral from a Referrer, have the opportunity to participate in an
            additional marketing campaign conducted by Noble during which they
            can receive additional prizes or marketing products- digital or
            printed. Noble at its own discretion decides the type of product or
            prize to offer. Additional information for the participation, time
            period of the marketing campaign, etc. shall be announced on the
            website or through other source of communication with the consent of
            Candidates, granted previously by them in connection to the
            provision of the services- including but not limited to email
            correspondence. Noble reserves its right to conduct such campaigns
            whenever the company decides.
          </p>
          <p>
            The conditions for the additional marketing campaign for prizes or
            marketing products are as it follows:
          </p>
          <p>
            Candidates should have applied independently for a job position
            without referral; and The Candidate should have been successfully
            recruited; and Noble should have been the only platform via which
            the Candidate has been introduced to the Employer within 12 (twelve)
            months prior to the job application through Noble Hire Platform. Any
            other marketing campaigns conducted by Noble in the future shall be
            explicitly announced on the website. Noble reserves its right to
            modify the rules and criteria for participation according to each
            particular campaign. In case any of the rules for participation in
            the announcement on the website and in the present Terms and
            Conditions differ, the announcement shall overrule.
          </p>
          <p>
            If you have any questions regarding the participation and the rules
            for the conducting of the marketing campaigns, do not hesitate to
            contact Noble’s team at hi@noblehire.io .
          </p>
          <p>
            Please bear in mind that Candidates can also refer others for job
            positions. Therefore, when they conduct this activity, Noble shall
            consider them as Referrers and the General Terms and Conditions for
            Referrers shall be fully applicable for them. For more information
            read Section III. GENERAL TERMS AND CONDITIONS FOR REFERRERS.
          </p>
          <p>
            <strong>
              SECTION III.GENERAL TERMS AND CONDITIONS FOR REFERRERS
            </strong>
          </p>
          <p>
            A COMPULSORY PREREQUISITE FOR THE RECEIPT OF REFERRAL BONUS IS THE
            SIGNING OF AN INDIVIDUAL CONTRACT WITH NOBLE AND THE CREATION OF
            REFERRER’S ACCOUNT AT Noble Hire PLATFORM. For more information
            about the individual contracts and the terms for receipt of referral
            bonuses, please read the present terms and conditions or contact
            Noble team on email: ivanov@nobletech.io for more details.
          </p>
          <p>
            The present Terms and Conditions apply to all individual agreements
            concluded with Referrers.
          </p>
          <p>
            <strong>1. GENERAL PROVISIONS</strong>
          </p>
          <p>
            The Noble Hire Platform provides two referral options- “Invite Link”
            and “Refer via CV”. Refer via CV require the referral of a specific
            Candidate to a specific Company’s open job position on the Platform
            whereas the Invite Link is non-personal and the shareable referral
            links for available job positions on the Platform can be posted on
            social media platforms, the Referrer’s website, in the Referrer’s
            newsletter, etc.
          </p>
          <p>
            Noble does not guarantee the receipt of referral bonus in any way.
            The referral bonus is dependent on the occurrence of certain events
            including:
          </p>
          <p>
            (i). Referral of a Candidate who is afterwards hired pursuant to the
            provisions of items 2.1. or respectively 2.2.
          </p>
          <p>
            <strong>2. REFERRAL BONUS</strong>
          </p>
          <p>
            <strong>2</strong>.
            <strong>
              1. AMOUNT OF REFERRAL BONUS WITH THE OPTION “REFER VIA CV”
            </strong>
          </p>
          <p>
            The amount of the referral bonus is equal to 50% (fifty percent) of
            one monthly gross remuneration of the hired Candidate, agreed
            between the Candidate and the Employer, when Referrers refer a
            specific person according to point 3.1. of the present Terms with
            the option “Refer via CV”. The below-mentioned criteria should be
            met for the receipt of the bonus:
          </p>
          <p>
            The Candidate should accept the referral and the Terms and
            Conditions on the Noble Hire Platform through a link (sent via
            email) with the purpose of agreeing to the referral;
          </p>
          <p>
            The Candidate should be recruited by the respective Employer he/she
            was referred to, through Noble Hire Platform;
          </p>
          <p>
            The recruitment by the respective Employer shall occur within 6
            months as of the application of the Candidate for the open job
            position of the respective Employer through acceptance of the
            referral made via “Refer via CV”. This prerequisite shall be present
            in case of recruitment of the Candidate by the respective Employer
            on a different position, than the one he was referred to, within the
            said 6-months term.
          </p>
          <p>
            The Candidate should continue to perform his/her labor obligations
            for a period of 3 (three) months from the date of signing of the
            employment agreement.
          </p>
          <p>
            <strong>
              2.2. AMOUNT OF REFERRAL BONUS WITH THE OPTION “INVITE LINK”
            </strong>
          </p>
          <p>
            The amount of the referral bonus is equal to 50% (fifty percent) of
            one monthly gross remuneration of the hired Candidate, agreed
            between the Candidate and the Employer when Referrers use the option
            “Invite Link” according to the steps in point 3.2. of the present
            Terms. The below-mentioned criteria should be met for the receipt of
            the bonus:
          </p>
          <p>
            The Candidate should create their account on the Noble Hire Platform
            through a specially generated link (the shareable referral link)
            with the purpose of Referrer Identification;
          </p>
          <p>
            The Candidate should apply for one or more than one open job
            positions available on the Noble Hire Platform;{' '}
          </p>
          <p>
            The Candidate shall be recruited by an Employer, for whose open job
            positions he has applied for, through Noble Hire Platform{' '}
          </p>
          <p>
            The recruitment shall occur within 6 months as of the creation of
            the Candidate’s account on the Noble Hire Platform through the
            specially generated link (the shareable referral link). This
            prerequisite shall be present in case of recruitment of the
            Candidate by the respective Employer on a different position, than
            the one he was applied for, within the said 6-months term.
          </p>
          <p>
            The Candidate should continue to perform his/her labor obligations
            for a period of 3 (three) months from the date of signing of the
            employment agreement.
          </p>
          <p>
            <strong>2.3. PAYMENT OF REFERRAL BONUS</strong>
          </p>
          <p>
            Additional requirements for the receipt of the remuneration is the
            signing of an individual contract with Noble and the declaration of
            certain tax, labour and insurance facts requested by Noble. For more
            information about the individual contracts and the declarations,
            please read carefully point 6 of the present Terms or contact
            Noble’s team via email. Prior payment is strictly forbidden.
          </p>
          <p>
            The terms and conditions of referral bonus payments are subject to
            arrangement in the individual agreements signed with Noble.
          </p>
          <p>
            <strong>2.4. REFERRAL BONUS DISCLAIMERS</strong>
          </p>
          <p>
            In the case of referral according to point 3.2. of the present
            Terms, if Candidates decide to enter into the website after seeing
            the Referrer’s post/ comment, etc. with the incorporated hyperlink
            in their Network but do not use the hyperlink, Noble cannot identify
            the referral activity and the identity of the Referrer. Noble shall
            not be held liable in any way for these circumstances and shall not
            owe the Referrer remuneration due to the lack of proof for
            recommendation.
          </p>
          <p>
            The Referral shall not be entitled to a referral bonus if at the
            time of the reference the referred Candidate has already been
            present in Noble’s database and cumulatively has already applied via
            the Platform for an open position at the same Employer/s he has been
            referred to.{' '}
          </p>
          <p>
            The Referral shall not be entitled to a referral bonus if a 6-months
            term has elapsed from: (i) the application of the Candidate through
            “Refer via CV” or respectively (ii) the registration of a Candidate
            at the Platform through “Refer via Link”.
          </p>
          <p>
            Referrers shall not have any objections or claims in connection to
            the amount of the Referral fee as it depend on the amount of the
            gross monthly remuneration agreed between the referred Candidate and
            the Employer.
          </p>
          <p>
            The final amount may be different than the expected full 50% of the
            one monthly gross remuneration of the hired Candidate, agreed
            between the Candidate and the Employer, due to tax, insurance and
            labour regulations and requirements for payment of taxes and
            insurance by Noble (for more information, please read point 6- Types
            of individual contracts and legal requirements).
          </p>
          <p>
            Noble is obliged to keep accountancy for the precise period in which
            the Candidate concluded his/her employment contract with the
            Employer. The information Noble obtains from every Employer. Noble
            shall not be held liable in any way if the Employer fails to perform
            their obligations to submit information about the signing of the
            labour contract with the Candidate
          </p>
          <p>
            In case of any discrepancy related to the bonus, please contact
            Noble team. The team of Noble may need additional information with
            the purpose of determining the claim.
          </p>
          <p>
            <strong>3. STEPS FOR REGISTRATION AND RECOMMENDATION</strong>{' '}
          </p>
          <p>
            <strong>3.1. OPTION “REFER VIA CV”</strong>
          </p>
          <p>
            When the Referrer wants to recommend a specific person for a job
            position on the Platform, they should follow the below-mentioned
            steps:
          </p>
          <p>
            The Referrer should register and create an account on the Noble Hire
            Platform.
          </p>
          <p>
            The Referrer should talk to the person they think might be a good
            fit for this position and see if they are interested.
          </p>
          <p>
            The Referrer shall then click/tap on the Referrer dashboard (the
            icon with the 3 people on the top right corner on the Noble Hire
            Platform) or click the “Refer via CV” button.
          </p>
          <p>
            Finally, the Referrer should fill in the referral form by providing
            the Candidate’s name, contacts, CV and the job position of the
            specific Employer they are referring the Candidate for.
          </p>
          <p>
            After the sending of the referral, the Candidate will be contacted
            by Noble Hire’s team via email on the Referrer’s behalf for the
            purpose of informing them for the Referral and introducing them to
            the next steps and requirements of the procedure for application.
          </p>
          <p>
            The Candidate will be required to accept the referral via a link
            provided in the email for agreeing to the referral,
          </p>
          <p>
            The Candidate might be contacted and interviewed by the specific
            Employer to whom the referral was directed.
          </p>
          <p>
            If the recruitment by the specific Employer is successful and made
            within 6 months as of the acceptance of the referral by the
            Candidate and the Candidate has retained his/her working position on
            a precise workplace for at least 3 (three) months as of signing of
            the employment agreement, the Referrer receives a referral bonus as
            mentioned above in point 2 of the present Terms.{' '}
          </p>
          <p>
            <strong>3.2. OPTION “INVITE LINK”</strong>
          </p>
          <p>
            When the Referrer wants to conduct Referral activity on the Platform
            but is not sure who to recommend, they should follow the
            below-mentioned steps:
          </p>
          <p>
            The Referrer should register and create an account on the Noble Hire
            Platform,
          </p>
          <p>
            The Referrer shall then open the page for which they would like to
            create an Invite Link and then click/ tap on the Referrer dashboard
            (the icon with the 3 people on the top right corner on the Noble
            Hire Platform) or click the “Refer Candidate” button if the Referrer
            is on a job position page.
          </p>
          <p>
            Finally, the Referrer gets an Invite Link which the latter can
            email, share on social media platforms or post on their website.
          </p>
          <p>
            If a Candidate is interested and they click on the shared Invite
            Link, they need to register on the Noble Hire Platform and start
            applying.
          </p>
          <p>
            The Candidate might be contacted and interviewed by an Employer on
            the Platform.
          </p>
          <p>
            If the recruitment by the specific Employer/s is successful and made
            within 6 months as of the registration of the Candidate at the
            Platform and the Candidate has retained his/her working position on
            a precise workplace for at least 3 (three) months as of signing of
            the employment agreement, the Referrer receives a referral bonus as
            mentioned above in point 2 of the present Terms.
          </p>
          <p>
            <strong>4. IDENTIFICATION OF A REFERRER</strong>{' '}
          </p>
          <p>
            The identification of Referrers is established by Candidates who use
            the specially generated links for both options. For the “Refer via
            CV” option the Candidate should follow the generated hyperlink
            incorporated in the email sent by Noble- in this case the
            establishment is proven automatically due to the unique hyperlink,
            which is generated. For the “Invite Link” option the Candidate
            should use the shareable referral link available on other platforms,
            access the Noble Hire Platform through it and create an account.
            Please bear in mind that regarding the “Invite Link” option, the
            Referrer who receives the bonus is only the one whose link was used
            last by the Candidate and via which the Candidate has registered on
            the Platform.
          </p>
          <p>
            Please bear in mind that Candidates are solely responsible for the
            due and proper establishment of the Referrer’s identity. If a
            Candidate self-registers on the Platform without the hyperlink,
            Noble cannot identify the Referrer. In this case, the Referrer
            agrees that Noble is not responsible in any way for the lack of
            information about the Referrer and does not owe the Referrer any
            remuneration. The above-mentioned applies when a Candidate has
            received a specific referral (through “Refer via CV” option)
            connected to one email address of the Candidate but the latter
            self-registers with another email address on the Platform. Any
            claims in the present cases should be directed solely to the
            Candidate who has not provided such information about the referral
            activity. Noble may pay remuneration to the Referrer at its own
            discretion if the Candidate after the registration contacts Noble’s
            team and in writing confirms the Referrer’s participation. The
            verification of the Candidate related to the written confirmation
            may be subject to additional requirements by Noble, which the
            Candidate should execute and any expenses related to the actions of
            proof shall be paid by the Candidate.
          </p>
          <p>
            <strong>5. RESTRICTIONS</strong>{' '}
          </p>
          <p>
            <strong>5.1. CREATING MULTIPLE ACCOUNTS</strong>
          </p>
          <p>
            The Referral bonus may not be earned by creating multiple accounts
            on Noble. If your account has been deleted or your rights for
            referrals have been restricted due to violation of the rules of
            Noble’s website, you may not register and refer anymore. You shall
            be held liable if you use untruthful information for the
            registration with the purpose of making a new account or referral in
            the above-mentioned case. Noble reserves its right to delete a
            Referrer’s account or restrict his/her referral rights at its own
            discretion whenever Noble considers the behaviour of the Referrer is
            inadequate or inappropriate for the website.
          </p>
          <p>
            <strong>5.2. SPAMMING</strong>
          </p>
          <p>
            Referrers shall not in any way “spam” any Candidate with referral
            requests. Spamming shall be considered any repeatedly sent messages
            with invitations to people to become Candidates through diverse
            social media platforms, applications, email, etc. with or without
            using automated systems or bots to distribute the hyperlink.
          </p>
          <p>
            <strong>5.3. THREATS AND MANIPULATIONS</strong>
          </p>
          <p>
            Referrers are forbidden to use physical force, manipulation or in
            any way to threaten the well-being of future Candidates or their
            relatives in order to persuade them into applying for a job
            position. In the present case, Referrers shall be held liable.
          </p>
          <p>
            <strong>
              5.4. NEGOTIATIONS BETWEEN REFERRALS AND CANDIDATES AND/ OR
              EMPLOYERS
            </strong>
          </p>
          <p>
            Any direct negotiations between Referrers and Candidates and/ or
            Employers excluding or aiming to circumvent Noble are strictly
            forbidden. The negotiations include any agreements- in writing or
            oral- with subject the recruitment and/ or intermediation of
            Candidates and arrangements for receipt of referral bonuses directly
            from a Candidate or an Employer without the previous knowledge and
            written consent by Noble. The latter is applicable in any case when
            the parties would not have been informed about the job position if
            they have not used the Noble Hire platform. The Employers and the
            Referrers (if any) involved bear joint and several liability for
            paying Noble a penalty equal to the double amount of the
            pay-per-hire fee that Noble would have been paid if there were no
            such negotiations and the Platform had been used correctly according
            to the rules of Noble. Any negotiations of Referrers with third
            persons, apart from Candidates or Employers- with the same purpose
            of circumventing Noble, is also forbidden.
          </p>
          <p>
            <strong>
              6. TYPES OF INDIVIDUAL CONTRACTS AND LEGAL REQUIREMENTS
            </strong>{' '}
          </p>
          <p>
            As mentioned above, the receipt of the referral bonus is dependent
            on the identification of a Referrer, successful recruitment of the
            Candidate, the employment of the Candidate for more than three
            months, the conclusion of an individual contract between the
            Referrer and Noble and declaration of legally requested facts
            according to point 6.2 for physical persons/ issue of invoice
            according to point 6.1. for legal entities. The signing of the
            individual contracts is legal requirement for the payment of the
            remuneration.
          </p>
          <p>
            Please bear in mind that the final amount may be different from the
            full 50 % of the monthly gross remuneration of the Candidate. The
            reason is the legal requirements for additional payment of tax and
            insurance according to every individual case. Referrers should be
            aware of the rights and obligations they have in accordance with the
            Bulgarian labour, tax and insurance legislation.
          </p>
          <p>
            <strong>6.1. LEGAL ENTITIES</strong>
          </p>
          <p>
            When legal entities are Referrers, they shall issue an invoice,
            which is duly sent to Noble on the address mentioned in the
            individual contract. Noble pays the referral bonus based on the
            invoice.
          </p>
          <p>
            <strong>6.2. PHYSICAL PERSONS</strong>
          </p>
          <p>
            <strong>6.2.1. LOCAL PHYSICAL PERSONS</strong>
          </p>
          <p>
            Local physical person is the one who meets the criteria provided in
            art.4 of the Bulgarian Personal Incomes Tax Act.
          </p>
          <p>
            Please bear in mind that the income you have received as a Referrer
            on the website based on the individual contract is subject to tax
            and/or insurance payments according to the applicable tax and
            insurance legislation for declaring incomes for civil contracts.
          </p>
          <p>
            If the physical person is self-insuring, they should declare this
            circumstance to Noble. In this case, Noble pays the bonus in the
            arranged amount without withholding taxes and insurances. If the
            physical person is not self-insuring, Noble has the obligation to
            withhold the due tax and insurances in an amount according to the
            Bulgarian legislation. The withholding is due after the declaration
            of the facts stated below by the physical person for the month for
            which the remuneration is due: – presence of other labor and
            non-labor relations with income (the declaration is type YES/ NO);
          </p>
          <p>
            – amount of the income subject to insurance from the above-mentioned
            relations;
          </p>
          <p>
            – other facts, the knowledge of which, are necessary prerequisite
            for the calculation and payment of taxes and/ or insurance;
          </p>
          <p>
            If the physical person is retired, Noble has the obligation to
            withhold money for taxes according to the Bulgarian legislation. The
            withholding is due after the declaration of the retirement. Noble is
            not responsible for the declaration of untruthful information by the
            physical person or the lack of any declaration. If Noble is impeded
            from paying the due taxes and/or insurance due to fault by the
            physical person, the latter shall be responsible for all material
            sanctions applied by the state to Noble for non-compliance with the
            legislation.
          </p>
          <p>
            <strong>6.2.2. FOREIGN PHYSICAL PERSONS</strong>
          </p>
          <p>
            Foreign physical persons is the one who does not meet the criteria
            provided in art.4 of the Bulgarian Personal Incomes Tax Act.
          </p>
          <p>
            If you are a foreign physical person, the rules for withholding a
            personal income tax depend on the provisions of the respective
            Treaty for avoidance of double taxation signed between Bulgaria and
            the Referrer’s tax residence country or other applicable
            international Treaty (if any). The Referrer shall bear
            responsibility to check whether such Treaty exists and to provide
            the Bulgarian National Revenue Agency with the required documents
            evidencing the specific ground for tax exemption in Bulgaria.{' '}
          </p>
          <p>
            In case the personal income tax of such foreign physical person
            shall be paid in Bulgaria or the prerequisites for tax exemption
            have not been met by the Referrer, the Referrer shall make a
            registration at the Bulgarian BULSTAT Register for the purposes of
            obtaining a tax number and payment of the due tax by Noble. In such
            cases Noble withholds and pays to the Bulgarian National Revenue
            Agency the due amount of the personal income tax pursuant to the
            Bulgarian legislation.
          </p>
          <p>
            The foreign physical are solely responsible for declaring the income
            in a tax statement according to the applicable law in their country
            of tax residence and the procedure of tax exmption in Bulgaria or
            respectively for making a BULSTAT registration in Bulgaria for the
            purposes of payment of the taxes in Bulgaria. Noble is not in any
            way responsible for the non-execution of the present obligation, the
            failure of the Referrer to submit the documents in time and any
            consequences that may occur due to the non-compliance with the
            foreign legislation.
          </p>
          <p>
            <strong>7. MISCELLANEOUS</strong>
          </p>
          <p>
            Noble communicates actively with all Referrers via email and phone
            (when necessary) on the Platform regardless of whether Referrers
            have already referred a Candidate. The communication is due to the
            purpose of insurance of high-quality service for Referrers.
          </p>
          <p>
            When Noble has suspended its active intermediary activity according
            to Section IV of the present Terms and conditions, the Referrer
            shall be duly informed by Noble. In such case, Noble does not
            guarantee the payment of the referral bonus to the Referrer.
          </p>
          <p>
            <strong>
              SECTION IV. GENERAL RULES APPLICABLE TO THE PLATFORM USERS
            </strong>
          </p>
          <p>
            The acceptance of the present Terms and Conditions is a compulsory
            prerequisite for the use of our Platform.
          </p>
          <p>
            Noble has the responsibility to respond to status update requests in
            a reasonable time period. Noble has the responsibility to submit and
            forward the requests to Employers. Noble shall not be responsible in
            case Employers do not submit such information at all or in time.
          </p>
          <p>
            Noble shall suspend its active role of providing intermediary
            services for all Candidates for which the Employer proves that they
            appear in his database as recommendations or nominations in the last
            12 months. The application of the Candidate is not suspended in any
            way and it lasts. However, Noble does not intervene by further
            referring.
          </p>
          <p>
            <strong>GENERAL DISCLAIMERS</strong>{' '}
          </p>
          <p>
            <strong>1. AGREEMENTS AND RESPONSIBILITY</strong>
          </p>
          <p>
            It is forbidden for Referrers, Candidates and Employers to secretly
            discuss and negotiate the recruitment of personnel or any
            distribution of referral bonuses between the parties with the
            purpose of avoiding or circumventing any undertaken obligation
            regarding Noble. In case Noble acquires information relating such
            arrangements, Noble has the right to restrict the access to the
            website and delete the accounts of the parties, participating in the
            agreements. This is also applicable when Candidates and Employers
            discuss and negotiate the recruitment without the knowledge of
            Noble. The Employers and the Referrers (if any) bear joint and
            several liability for paying Noble a penalty equal to the double
            amount of the pay-per-hire fee, that Noble would have been paid if
            there were no such negotiations and the Platform had been used
            correctly according to the rules of Noble.
          </p>
          <p>
            Employers are independent contractors and Candidates sign labor
            contracts directly with them. Noble does not in any way sign labor
            contracts with Candidates – it only acts as an Intermediary.
          </p>
          <p>
            The parties in the following employment contracts shall act in good
            faith by fulfilling their obligations accordingly. Any
            non-performance, partial performance, material injury and any other
            obligation arising from the labor agreements are responsibility
            solely to the Employers and Candidates. Noble shall not be held
            liable for any infringement of the contracts between the
            above-mentioned parties.
          </p>
          <p>
            In connection to the services provided, Noble shall not be held
            liable for any claims relating tort, delict or quasi- delict caused
            by either Employers, or Candidates during the exercising of the
            obligations under the employment contracts.
          </p>
          <p>
            <strong>2. TERRITORY COMPATIBILITY</strong>
          </p>
          <p>
            The Bulgarian law applies with regard to the activity of Noble and
            the legal and factual actions performed through the Platform.
          </p>
          <p>
            The use of our Services shall be in compliance with all applicable
            legislation according to the territory you access from. Users should
            check the compatibility of the website with the legal systems,
            legislation and restrictions in the country they access from. Noble
            does not allow the access of the website in case the content is
            found illegal according to the rules applicable in the certain
            access point country. However, we are not liable in case you access
            regardless of the violation of the legislation of the country or
            region. Users are solely responsible for the use of our Services in
            accordance with the local or regional laws, regulations, directives,
            etc.
          </p>
          <p>
            <strong>3. INAPPROPRIATE BEHAVIOUR</strong>
          </p>
          <p>
            Users agree that they shall not upload any content referring to
            advertising and promoting the activity of competitive companies and
            websites or for other commercial purposes or offer participation in
            illegal activities. The content of the website shall not be used for
            commercial purposes but only for private ones. Users who use the
            website for commercial purposes shall be held liable.
          </p>
          <p>
            The website should not be accessed from an electronic device, which
            is known to contain viruses, Trojan horses, worms and other cyber
            programs, which may cause damage to the website.
          </p>
          <p>
            Users are forbidden to interfere in any way by any electronic means
            in the activity of the website. In case such behavior is
            established, the company shall be entitled to undertake every
            possible measure to prevent the negative consequences and shall hold
            the user responsible.
          </p>
          <p>
            Any messages, actions, comments, documents, photographs, etc., which
            in an implicit or explicit way refer to terrorism, discrimination,
            racism, or any other form of inappropriate behavior are strictly
            forbidden and will lead to the immediate deletion of the account and
            restriction to the services on the Noble Hire Platform. We have no
            responsibility to monitor the behavior of users and shall not be
            liable for these actions. We request in case other users see such an
            inappropriate behavior, to inform us as soon as possible. Noble
            shall make everything possible to restrict, prevent and delete the
            provocative content.
          </p>
          <p>
            <strong>4. THIRD PARTIES</strong>
          </p>
          <p>
            The Noble Hire Platform may incorporate hyperlinks, forwarding to
            websites hosted and supported by third parties. Users are given the
            opportunity to upload information for their websites and profiles in
            third-party applications, provided they do not contain commercial
            agitation or they do not provide services, which are in any way
            similar or identical to the ones offered by Noble.
          </p>
          <p>
            These third parties are not connected in any way to us and we do not
            control or review the content presented there. Users may access the
            platforms of third parties at their own risk and they should comply
            with their Terms and Conditions, Privacy Policies and other relevant
            to the websites or applications rules. We are not liable for the
            content represented on the websites or applications of third
            parties.
          </p>
          <p>
            <strong>5. ACCOUNT MAINTENANCE</strong>{' '}
          </p>
          <p>
            Users are entitled to update their accounts, job announcements, CV-s
            and any other information, which can be used in any way for the
            purpose of recruitment, at all times. We are not liable for using
            outdated or faulty information during the process of job application
            by Candidates/ referral by Referrers / Employer’s criteria matching
            activities by Noble.
          </p>
          <p>
            We recommend that you do not leave your account signed in without
            your supervision when an account is generated. Users should not
            grant access to their accounts to third persons.
          </p>
          <p>
            Users should connect to Noble via email hi@noblehire.io in the
            following cases: they have any detected security breaches or issues;
            their access point has been under a cyber-attack; their account when
            one is generated has been modified without their authorization;
            their account when one is generated has been accessed without their
            authorization; any other case, which is related to the proper use of
            the Platform.
          </p>
          <p>
            <strong>6. INTELLECTUAL PROPERTY RIGHTS</strong>{' '}
          </p>
          <p>
            Users guarantee that they have full and exclusive right, title and
            interest in and to all uploaded on the Platform from their behalf
            intellectual property, including but not limited to trademarks,
            slogans, messages, phrases; documents; photographs or any other
            materials.
          </p>
          <p>
            When sensitive company information is uploaded on the Noble Hire
            Platform, Users explicitly confirm the following:
          </p>
          <p>
            (I) any third person on the photographs have given explicit consent
            for the uploading, transmitting and sharing of the photographs for
            the purposes of the website;{' '}
          </p>
          <p>
            (ii) any third person whose personal information is uploaded,
            transmitted and shared on the module has given its consent.{' '}
          </p>
          <p>
            Users hereunder declare that they are the sole proprietors of all
            objects of intellectual property rights, which they present in any
            way to Noble for the purposes of the Platform functionalities use.
          </p>
          <p>
            In the case of any material and/or non-pecuniary damage suffered by
            Noble as a result of claims by third parties in respect of
            violations of their rights and legitimate interests (copyright,
            trademark, etc.) made by Users in the fulfillment of the obligations
            of the latter under the present Terms and Conditions and any other
            compulsory to the website document thereto, the User undertakes to
            compensate Noble for the full amount of damages suffered by Noble.
          </p>
          <p>
            This site belongs to and is operated by Noble. Each component of the
            website and the layout itself, including but not limited to logos,
            trademarks, domain names, text etc., which appear in any way on the
            website www.noblehire.io are protected by the current laws with
            subject intellectual property. Therefore, they belong strictly and
            solely to Noble and the use of any component is a subject to prior
            authorization.
          </p>
          <p>
            None of the above-mentioned components included in this website can
            be reproduced, copied, edited, transmitted, downloaded or
            distributed in any way whatsoever partially or in whole, on any
            medium whatsoever, without the prior written consent of Noble. Apart
            from this, the use for press requirements is allowed solely in
            compliance with intellectual property rights that are mentioned.
          </p>
          <p>
            Noble reserves its right to take every necessary legal action
            against any breach or infringement of its intellectual property
            rights.
          </p>
          <p>
            <strong>7. RESTRICTION OR DELETION OF CONTENT</strong>{' '}
          </p>
          <p>
            Please, bear in mind that the website could be periodically
            modified, updated, maintained or technically supported. Therefore,
            the access to the website may be restricted for a certain period of
            time. Noble shall not be liable for any indirect consequences
            resulting from the limited access to the website or the lack of
            possibility to access for this period of time.
          </p>
          <p>
            During the modifications, updates and maintenance, some of the
            offered job announcements or information from the profiles/ accounts
            of Users may be replaced or deleted by accident. Users are requested
            to contact Noble if they notice such changes. Noble shall not be
            responsible and liable for such undesired consequences but the
            company shall assist in every possible way to regenerate the lost
            and/or deleted data after receiving such information by users.
          </p>
          <p>
            Additionally, after the request for deletion of the profile/ account
            by any User or in case of deletion of the same due to breach of our
            rules, any relevant to the User information may be replaced or
            deleted. You agree that we are not liable for the consequences
            arising from the above-mentioned cases.
          </p>
          <p>
            We have the right to undertake any necessary actions to restrict
            inappropriate or inaccurate content or to delete it if we consider
            the latter a violation of the website applicable rules. The company
            may restrict or delete a profile/ account, delete announcements and
            publications, photographs, comments, etc. We can take action without
            your prior consent and knowledge. However, please bear in mind that
            it is responsibility of the Users to upload truthful, accurate and
            non- offensive content on the website. Therefore, they are solely
            responsible and liable for any content, which is considered to
            violate any third person’s rights.
          </p>
          <p>
            In case the company profile of an Employer is restricted or deleted
            due to any of the above-mentioned reasons, incorporated in the
            present Terms and Conditions, any payments made for the provision of
            the services by Noble shall not be refundable. The behavioral
            compliance with the obligations during the use of our website is the
            users’ responsibility and the payment is made at their own risk.
          </p>
          <p>
            <strong>8. DELETION OF AN ACCOUNT</strong>{' '}
          </p>
          <p>
            Your account when one is generated can be deleted in the following
            cases:
          </p>
          <p>
            When the behavior of the User is not compliant to the rules
            incorporated in the Terms and Conditions; When the User requests the
            deletion of his profile/ account and/or exercises his right “to be
            forgotten”; Does not use the services of the website for more than 2
            (two) years; In any other case based on the sole discretion of Noble
            with a prior electronic written notice to the User. The access to
            the account may be restricted in the following cases:
          </p>
          <p>
            In case of doubt for account theft, breach and any other
            troubleshoots during the use of the account; When the behavior of
            the User is not compliant to the rules incorporated in the Terms and
            Conditions but the non-compliance does not require the deletion of
            the profile; Noble shall not be liable in any case for the
            undertaking of the above-mentioned actions- deletion and
            restrictions of profiles, and the consequences thereof. These
            actions are an object of the company’s sole estimation and judgement
            and cannot be held liable for them.
          </p>
          <p>
            <strong>9. COMMUNICATION WITH USERS</strong>{' '}
          </p>
          <p>
            Noble may approach Users via a variety of communication means,
            including telephone or email. The above-mentioned means shall be
            available after the provision of the respective information from
            Users. Users are free to choose the communication method they
            prefer, unless the method is not strictly established by Noble in
            connection to a certain procedure. Please, bear in mind that we
            include different alternatives for communication with Users in case
            we would like to additionally confirm information related to a given
            referral or application for a given position. You, as a User, give
            your consent for Noble to contact you via email or telephone, once
            You provide the information prior to the use of the Noble Hire
            Platform. In this case Users shall be considered addressees of
            electronic statements relating the use of our services. We shall
            consider the agreement to the present Terms and Conditions as
            unambiguous circumstances that you have agreed to receive the
            statement in electronic form via email or telephone according to
            article 5 of the Bulgarian Electronic Document and Electronic Trust
            Services Act.
          </p>
          <p>
            For example, we may communicate with Users via the email address or
            telephone number, provided at the registration or subsequently, in
            connection to confirmation of a profile, privacy problems, security
            breaches, technical or administrative issues and legal breaches
            regarding the use of our services.
          </p>
          <p>
            <strong>10. GDPR COMPLIANCE</strong>{' '}
          </p>
          <p>
            The GDPR compliance of the website is subject to our Privacy Policy.
            For more information, please visit:{' '}
            <a href="https://noblehire.io/privacy-policy/">
              https://noblehire.io/privacy-policy/
            </a>{' '}
            .
          </p>
          <p>
            When a User provides information for another person or entity, they
            declare that they have the latter one’s explicit consent for the
            provision of personal data. Noble is not liable if the information
            is provided without the prior consent of the data subject.
          </p>
          <p>
            <strong>11. APPLICABLE LAW AND COMPETENT COURT</strong>
          </p>
          <p>
            The Bulgarian law applies to all issues which are not settled with
            the present Terms and conditions. If any term of these Terms and
            conditions is found to be illegal, void or unenforceable, this will
            not affect the validity, applicability and enforceability of the
            other terms.
          </p>
          <p>
            Any disputes arising from the present Terms and Conditions or
            concerning it, including disputes arising from or concerning its
            interpretation, invalidity, non-performance, as well as disputes
            regarding filling gaps in the individual agreements concluded with
            Noble and/or legal and factual actions when using the Platform,
            shall be settled by the involved Parties through understanding and
            mutual concessions, and when understanding is impossible, the
            dispute shall be bring to the competent Bulgarian court
          </p>
          <p>
            We are expecting your questions for the terms of use or any other
            request via email to the following address: hi@noblehire.io.
          </p>
          <p>
            FOR MORE INFORMATION ABOUT THE PROPER USAGE OF THE WEBSITE, PLEASE
            CONTACT NOBLE’S TEAM.
          </p>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
