// @flow
import * as React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../../../../basic/Button';
import { Form } from '../../../../basic/form/Form';
import { Input } from '../../../../basic/form/Input';
import { useForgottenPasswordResetForm } from './useForgottenPasswordResetForm';
import { paths } from '../../../../../core/constants';

const useStyles = makeStyles((theme) => ({
  root: { maxWidth: '280px', textAlign: 'center' },
  title: { textAlign: 'center' },
  footer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  link: {
    marginTop: theme.spacing(2),
  },
  row: { width: '100%', minWidth: 250, margin: theme.spacing(1, 0) },
}));

function ForgottenPasswordResetForm() {
  const queryString = useLocation().search;
  const query = new URLSearchParams(queryString);

  const classes = useStyles();
  const { onFormSubmit, validate, linkExpired } = useForgottenPasswordResetForm(
    { query }
  );

  if (linkExpired) {
    return (
      <div className={classes.root}>
        <p>This reset link is no longer valid.</p>
        <p>
          <Link replace to={paths.forgottenPassword}>
            Request another one.
          </Link>
        </p>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Form onSubmit={onFormSubmit} validate={validate}>
        {({ submitting }) => (
          <React.Fragment>
            <p className={classes.title}>Reset your password</p>

            <Input
              controlProps={{ className: classes.row }}
              type="password"
              name="password"
              id="password"
              label="New password"
              autoComplete="password"
              required
            />

            <Input
              controlProps={{ className: classes.row }}
              type="password"
              name="repeatPassword"
              id="repeatPassword"
              label="Repeat new password"
              autoComplete="new-password"
              required
            />

            <div className={classes.footer}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={submitting}
              >
                {submitting ? 'Just a sec...' : 'Reset'}
              </Button>
            </div>
          </React.Fragment>
        )}
      </Form>
    </div>
  );
}

export { ForgottenPasswordResetForm };
