// @flow
/* eslint-disable no-console */
/* eslint-disable consistent-return */
import React, { useRef } from 'react';
import { Box, Container } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { EditHeader } from '../basic/EditHeader';
import { FullPageLoader } from '../basic/FullPageLoader';
import { ErrorsList } from '../modals/components/ErrorsList';
import {
  Heading,
  CompanyInfo,
  JobDescription,
  ControlsBar,
  JobDetails,
  TechRequirements,
  RequirementsResponsibilities,
  Perks,
} from './JobAd';
import { HideIf } from '../basic/HideIf';
import { HiringProcess } from './common/HiringProcess';
import { TeamLead } from './common/TeamLead';
import { TimeSpendingDetails } from './common/TimeSpendingDetails';
import { ToolsWeUse } from './common/ToolsWeUse';
import { Product } from './common/Product';
import type { EditableJobCtx } from './types';

export function JobEdit({ job }: { job: EditableJobCtx<any> }) {
  const je = job;

  const ref = useRef(null);
  const onScrollTop = () => {
    if (ref.current) {
      const el = je.isEdit ? window : ref.current;
      el.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  // ---- render ----------------------------------------------- //
  // if (je.jobNotFound && !je.job) {
  if (je.jobNotFound) {
    return <Redirect to={je.jobUrl()} />;
  }

  return (
    <Box position="relative" height="100%" overflow="auto" ref={ref}>
      {je.errors && Object.keys(je.errors).length ? (
        <ErrorsList errors={je.errors} onClose={() => je.setErrors({})} />
      ) : null}

      {je.saving ? (
        <Box
          zIndex={2}
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          style={{ background: `rgba(255,255,255,0.8)` }}
        >
          <FullPageLoader>We&apos;re updating your job ad...</FullPageLoader>
        </Box>
      ) : null}

      <Box height="100%" minHeight="100vh">
        {je.isEdit ? (
          <EditHeader
            saving={je.saving}
            exiting={je.exiting}
            isPublic={je.isPublic}
            onPreview={je.preview}
            onExit={je.exitEdit}
            onUnpublish={je.unpublish}
            onPublish={je.publish}
            onSave={je.save}
          />
        ) : null}
        {je.loading ? (
          <Box
            zIndex={2}
            position="fixed"
            top={0}
            left={0}
            width="100vw"
            height="100vh"
            style={{ background: `rgba(255,255,255,0.8)` }}
          >
            <FullPageLoader>Simulating the Big Bang...</FullPageLoader>
          </Box>
        ) : (
          <Box pt={8} pb={4} maxWidth={960} style={{ margin: 'auto' }}>
            <Box mx={je.isPreview ? 4 : 0}>
              <Container>
                <Box>
                  <Box>
                    <Heading
                      isPreview={je.isPreview}
                      isEdit={je.isEdit}
                      onTitleChange={(t) => je.setTitle(t)}
                      onSeniorityChange={(s) => je.setSeniority(s)}
                      onTypeChange={(t) => je.setType(t)}
                      onClose={
                        je.isPreview && !je.isEdit
                          ? je.continueEditing
                          : undefined
                      }
                      title={je.title}
                      seniority={je.seniority}
                      type={je.type}
                      location={je.location}
                      locations={je.company.locations}
                      onLocationChange={(s) => je.setLocation(s)}
                    />
                    <Box my={4}>
                      <CompanyInfo
                        isEdit={je.isEdit}
                        logo={
                          je.company.logos && je.company.logos.length
                            ? je.company.logos[je.company.logos.length - 1].src
                            : undefined
                        }
                        companyName={je.company.brand}
                        link={je.company.slug}
                      />
                    </Box>
                    <HideIf cond={() => !je.isEdit && !je.description}>
                      <Box mt={4}>
                        <JobDescription
                          value={je.description}
                          isEdit={je.isEdit}
                          onChange={je.setDescription}
                        />
                      </Box>
                    </HideIf>
                    <Box mt={4}>
                      <JobDetails
                        location={je.location}
                        locations={je.company.locations}
                        isEdit={je.isEdit}
                        role={je.role}
                        salary={je.salary}
                        salaryCurrency={je.salaryCurrency}
                        seniority={je.seniority}
                        type={je.type}
                        stockOptions={je.stockOptions}
                        homeOffice={je.homeOffice}
                        teamSize={je.teamSize}
                        fullyRemote={je.fullyRemote}
                        customerFacing={je.customerFacing}
                        businessTravel={je.businessTravel}
                        onLocationChange={(s) => je.setLocation(s)}
                        onSalaryChange={(s) => je.setSalary(s)}
                        onSalaryCurrencyChange={(s) => je.setSalaryCurrency(s)}
                        onRoleChange={(s) => je.setRole(s)}
                        onSeniorityChange={(s) => je.setSeniority(s)}
                        onTypeChange={(t) => je.setType(t)}
                        onStockOptionsChange={(so) => je.setStockOptions(so)}
                        onHomeOfficeChange={(ho) => je.setHomeOffice(ho)}
                        onTeamSizeChange={(ts) => je.setTeamSize(ts)}
                        onFullyRemoteChange={(fr) => je.setFullyRemote(fr)}
                        onCustomerFacingChange={(cf) =>
                          je.setCustomerFacing(cf)
                        }
                        onBusinessTravelChange={(bt) =>
                          je.setBusinessTravel(bt)
                        }
                      />
                    </Box>
                    <HideIf
                      cond={() =>
                        (!je.isEdit &&
                          !je.primaryLanguage &&
                          !je.secondaryLanguage &&
                          !je.primaryPlatformOrFramework &&
                          !je.secondaryPlatformOrFramework &&
                          !je.mainDatabase) ||
                        !je.isTech
                      }
                    >
                      <Box mt={4}>
                        <TechRequirements
                          isEdit={je.isEdit}
                          primaryLanguage={je.primaryLanguage}
                          primaryPlatformOrFramework={
                            je.primaryPlatformOrFramework
                          }
                          secondaryLanguage={je.secondaryLanguage}
                          secondaryPlatformOrFramework={
                            je.secondaryPlatformOrFramework
                          }
                          onPrimaryLanguageChange={je.setPrimaryLanguage}
                          onPrimaryPlatformOrFrameworkChange={
                            je.setPrimaryPlatformOrFramework
                          }
                          onSecondaryLanguageChange={je.setSecondaryLanguage}
                          onSecondaryPlatformOrFrameworkChange={
                            je.setSecondaryPlatformOrFramework
                          }
                          mainDatabase={je.mainDatabase}
                          onMainDatabaseChange={je.setMainDatabase}
                        />
                      </Box>
                    </HideIf>
                    <Box my={4}>
                      <HideIf
                        cond={() =>
                          !je.isEdit &&
                          !je.coreRequirements.length &&
                          !je.responsibilities.length
                        }
                      >
                        <RequirementsResponsibilities
                          isEdit={je.isEdit}
                          coreRequirements={je.coreRequirements}
                          responsibilities={je.responsibilities}
                          onCoreRequirementAdd={je.addCoreRequirement}
                          onCoreRequirementRemove={je.removeCoreRequirement}
                          onCoreRequirementEdit={je.editCoreRequirement}
                          onResponsibilityAdd={je.addResponsibility}
                          onResponsibilityRemove={je.removeResponsibility}
                          onResponsibilityEdit={je.editResponsibility}
                        />
                      </HideIf>
                    </Box>
                    <HideIf cond={() => !je.isEdit && je.tools.length === 0}>
                      <Box mt={4}>
                        <ToolsWeUse
                          isEdit={je.isEdit}
                          tools={je.tools}
                          onAdd={je.addTool}
                          onUpdate={je.updateTool}
                          onRemove={je.removeTool}
                        />
                      </Box>
                    </HideIf>
                    <HideIf
                      cond={() =>
                        !je.isEdit &&
                        !je.productDescription &&
                        !je.productImages.length
                      }
                    >
                      <Box mt={4} position="relative">
                        <Product
                          isEdit={je.isEdit}
                          product={je.productDescription}
                          onAdd={je.uploadProductImage}
                          onPick={je.pickProductImages}
                          onRemove={je.removeProductImage}
                          onMove={je.moveProductImage}
                          updateProduct={je.setProductDescription}
                          images={je.productImages}
                          allProductImages={je.company.productImages}
                          companyId={je.company.id}
                          companyProductDescription={je.company.product}
                        />
                      </Box>
                    </HideIf>
                    <HideIf
                      cond={() => !je.isEdit && !je.timeSpendingDetails.length}
                    >
                      <Box mt={4}>
                        <TimeSpendingDetails
                          isEdit={je.isEdit}
                          items={je.timeSpendingDetails}
                          onAdd={je.addTimeSpendingDetails}
                          onRemove={je.removeTimeSpendingDetails}
                          onEdit={je.updateTimeSpendingDetails}
                        />
                      </Box>
                    </HideIf>
                    <HideIf
                      cond={() =>
                        !je.isEdit &&
                        !(je.teamLeadName || je.teamLeadRole || je.teamLeadLink)
                      }
                    >
                      <Box mt={4}>
                        <TeamLead
                          isEdit={je.isEdit}
                          companyId={je.company.id}
                          teamLeadImages={je.company.teamLeadImages}
                          image={je.teamLeadImage}
                          name={je.teamLeadName}
                          role={je.teamLeadRole}
                          link={je.teamLeadLink}
                          onPick={je.setTeamLeadImage}
                          onUpload={je.uploadTeamLeadImage}
                          onNameChange={je.setTeamLeadName}
                          onRoleChange={je.setTeamLeadRole}
                          onLinkChange={je.setTeamLeadLink}
                        />
                      </Box>
                    </HideIf>
                    <HideIf cond={() => !je.isEdit && !je.perks.length}>
                      <Box mt={4}>
                        <Perks
                          isEdit={je.isEdit}
                          items={je.perks}
                          onItemAdd={je.addPerk}
                          onItemEdit={je.editPerk}
                          onItemRemove={je.removePerk}
                        />
                      </Box>
                    </HideIf>

                    <HideIf
                      cond={() => !je.isEdit && !je.hiringProcessSteps.length}
                    >
                      <Box my={4}>
                        <HiringProcess
                          steps={je.hiringProcessSteps}
                          isEdit={je.isEdit}
                          onItemAdd={je.addHiringProcessStep}
                          onItemRemove={je.removeHiringProcessStep}
                          onItemEdit={je.editHiringProcessStep}
                        />
                      </Box>
                    </HideIf>

                    <Box my={4}>
                      <ControlsBar
                        isEdit={je.isEdit}
                        onScrollTop={onScrollTop}
                      />
                    </Box>
                    <Box my={4}>
                      <CompanyInfo
                        isEdit={je.isEdit}
                        logo={
                          je.company.logos && je.company.logos.length
                            ? je.company.logos[je.company.logos.length - 1].src
                            : undefined
                        }
                        companyName={je.company.brand}
                        link={je.company.slug}
                      />
                    </Box>
                    {/* ----------------------------------------------------------- */}
                    {/* ----------------------- /right column --------------------- */}
                    {/* ----------------------------------------------------------- */}
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
  // ------------------------------------------------------------ //
}
