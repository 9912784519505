// @flow
import React, { useState } from 'react';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import type { LocationObj } from './context/CompanyContext';
import { useMQ } from '../../useMQ';
import { Title } from '../../basic/Title';
import { Button } from '../../basic/Button';
import type { EditableProps, PreviewProps } from './types';
import { Hint } from './Hint';
import { cls } from './utils';
import { LocationForm } from './LocationForm';

const useStyles = makeStyles((theme) => ({
  loc_table: {
    textAlign: 'left',
    borderCollapse: 'collapse',
    width: '100%',
    boxSizing: 'border-box',
  },
  loc_head: {
    '& th': {
      margin: theme.spacing(1),
    },
    '& > th:first-child': {
      marginLeft: 0,
    },
  },
  loc_item: ({ editing }: { editing: boolean }) => ({
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    background: editing ? theme.palette.grey[100] : '',
    '& > td': {
      margin: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '& > td:first-child': {
      marginLeft: 0,
      paddingLeft: 0,
    },
  }),
}));

type LocationsProps = {
  locations: LocationObj[],
  lock?: boolean,
  add?: (LocationObj) => void,
  update?: (LocationObj) => void,
  remove?: (id: number) => void,
};
export function LocationsList({
  isEdit,
  isPreview,
  lock,
  locations,
  add,
  update,
  remove,
}: LocationsProps & EditableProps & PreviewProps) {
  const mq = useMQ();
  const theme = useTheme();
  const c = useStyles();
  const [adding, setAdding] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  const onAdd = () => setAdding(true);

  const onCancel = () => {
    setAdding(false);
    setEditingItem(null);
  };

  const onAddSubmit = (location: LocationObj) => {
    if (typeof add === 'function')
      add({
        id: Math.floor(Math.random() * (999999 - 1 + 1)) + 1,
        ...location,
        // address: location.address,
      });
    setAdding(false);
  };

  const onEditSubmit = (locationItem: LocationObj) => {
    if (typeof update === 'function')
      update({
        ...locationItem,
      });
    setEditingItem(null);
  };

  const onItemDelete = (locationItem: LocationObj) => {
    if (typeof remove === 'function' && locationItem.id)
      remove(locationItem.id);
    if (editingItem && editingItem.id === locationItem.id) {
      // We've deleted the item that we're currently editing,
      // so close the editing form.
      setEditingItem(null);
    }
  };

  const onItemUpdate = (locationItem: LocationObj) => {
    setEditingItem(() => null);
    // It's a bit hacky, but we need it as a fix for the PlacesAutocomplete rerendering issues,
    // when editing one item and clicking edit on another (the location is not rerendered).
    setTimeout(() => setEditingItem(locationItem), 0);
  };

  return (
    <Box>
      <Box marginBottom={2}>
        {isEdit ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            mt={mq.lg ? -3 : 2}
          >
            <Box color={theme.palette.edit.main} mr={1}>
              *At least one required
            </Box>
            <Hint
              render={
                <div>
                  <Box>
                    <strong>Location</strong>: Vertigo, Boulevard
                    &ldquo;Bulgaria&rdquo;, Sofia
                  </Box>
                  <Box>
                    <strong>Employees:</strong> 25
                  </Box>
                  <Box>
                    <strong>Since</strong>: 2010
                  </Box>
                </div>
              }
            />
          </Box>
        ) : null}
        <Box marginBottom={isEdit ? 2 : 0}>
          <table className={cls(c.loc_table)}>
            <thead className={cls(c.loc_head)}>
              <tr>
                <th>
                  <Title
                    style={{ marginBottom: 0 }}
                    variant={Title.variants.t4}
                  >
                    Locations
                  </Title>
                </th>
                <th>
                  <Title
                    style={{ marginBottom: 0 }}
                    variant={Title.variants.t4}
                  >
                    Employees
                  </Title>
                </th>
                <th>
                  <Title
                    style={{ marginBottom: 0 }}
                    variant={Title.variants.t4}
                  >
                    Since
                  </Title>
                </th>
              </tr>
            </thead>
            <tbody>
              {locations.map((loc) => {
                return (
                  <tr key={loc.id} className={cls(c.loc_item)}>
                    <td style={{ maxWidth: 150 }}>
                      {loc.address && loc.address.formatted_address
                        ? loc.address.formatted_address
                        : ''}
                    </td>
                    <td>{loc.teamSize}</td>
                    <td>{loc.founded}</td>
                    {isEdit && !lock ? (
                      <td>
                        <Box display="flex" alignItems="center">
                          <Box
                            style={{ cursor: 'pointer' }}
                            onClick={() => onItemDelete(loc)}
                            marginRight={2}
                          >
                            <FontAwesomeIcon icon={['fal', 'trash']} />
                          </Box>
                          <Box
                            style={{ cursor: 'pointer' }}
                            onClick={() => onItemUpdate(loc)}
                          >
                            <FontAwesomeIcon icon={['fal', 'pencil']} />
                          </Box>
                        </Box>
                      </td>
                    ) : null}
                  </tr>
                );
              })}
              {locations.length === 0 && !adding && !isPreview ? (
                <tr className={cls(c.loc_item)}>
                  <td style={{ maxWidth: 150 }}>
                    <span>&mdash;</span>
                  </td>
                  <td>
                    <span>&mdash;</span>
                  </td>
                  <td>
                    <span>&mdash;</span>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </Box>
      </Box>
      {lock ? (
        <Box
          border={`1px solid ${theme.palette.edit.main}`}
          bgcolor={theme.palette.edit.light}
          padding={1}
          borderRadius={5}
          marginBottom={1}
        >
          Your locations are currently locked due to some maintenance. Sorry for
          the inconvenience.
        </Box>
      ) : null}
      {isEdit && !lock ? (
        <Box>
          <Box>
            {!adding && !editingItem && !lock ? (
              <Button
                color="edit"
                variant="contained"
                type="button"
                onClick={onAdd}
                disableElevation
              >
                <FontAwesomeIcon icon={['fal', 'plus']} />

                <Box component="span" marginLeft={1}>
                  Add
                </Box>
              </Button>
            ) : (
              <LocationForm
                editingItem={editingItem}
                onCancel={onCancel}
                onAddSubmit={onAddSubmit}
                onEditSubmit={onEditSubmit}
              />
            )}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
