// @flow
import React, { useState } from 'react';
import { TextareaAutosize, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import type {
  CompProp,
  OnAddCompProp,
  OnEditCompProp,
} from './context/CompanyContext';
import { Button } from '../../basic/Button';
import IconPicker from '../../basic/IconPicker';
import { txtColor } from './utils';

const useStyles = makeStyles((theme) => ({
  addInfoBoxContainer: {
    background: 'white',
    boxShadow: `0 1px 7px 3px rgba(0,0,0,0.05)`,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  input: {
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    padding: theme.spacing(1),
    height: 40,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  inputWithError: {
    width: '100%',
    padding: theme.spacing(1),
    height: 40,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.error.main}`,
  },
  textarea: {
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[300]}`,
    resize: 'none',
    borderRadius: theme.shape.borderRadius,
  },
  textareaWithError: {
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    resize: 'none',
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

type InfoBoxFormProps = {
  onSubmit: OnAddCompProp | OnEditCompProp,
  onClose?: () => void,
  editingItem?: CompProp,
  initEditing?: boolean,
  showContentField?: boolean,
  showIconsPicker?: boolean,
  contentFieldRequired?: boolean,
  minHeight?: number,
  width?: number,
};
export function InfoBoxForm({
  onSubmit,
  editingItem,
  onClose = () => {},
  initEditing = false,
  showContentField = true,
  showIconsPicker = true,
  contentFieldRequired = true,
  minHeight = 150,
  width = 270,
}: InfoBoxFormProps) {
  const initValues = editingItem || {
    id: -9999,
    icon: 'heart',
    title: '',
    content: '',
  };
  const c = useStyles();
  const theme = useTheme();
  const [chosenIcon, setChosenIcon] = useState({
    iconName: initValues.icon || 'heart',
  });
  const [form, setForm] = useState({
    title: initValues.title,
    content: initValues.content,
  });
  const [errors, setErrors] = useState({ title: '', content: '' });
  const [editing, setEditing] = useState(initEditing);
  const startEditing = () => setEditing(true);
  const cancelEditing = (e: any) => {
    if (e) e.stopPropagation();
    onClose();
    setEditing(false);
  };
  const submit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors(() => ({ title: '', content: '' }));

    let hasErrors = false;
    if (form.title.length < 1) {
      hasErrors = true;
      setErrors((prev) => ({ ...prev, title: 'This field is required' }));
    }

    if (showContentField && contentFieldRequired) {
      if (form.content && form.content.length > 600) {
        hasErrors = true;
        setErrors((prev) => ({
          ...prev,
          content: 'Maximum length exceeded: 600 symbols',
        }));
      }
      if (!form.content || !form.content.length) {
        hasErrors = true;
        setErrors((prev) => ({ ...prev, content: 'This field is required' }));
      }
    }

    if (!hasErrors) {
      onSubmit({ ...form, icon: chosenIcon.iconName, id: initValues.id });
      setForm({ title: '', content: '' });
      setErrors({ title: '', content: '' });
      cancelEditing();
    }
  };
  const onIconChange = (_chosenIcon: { iconName: string }) =>
    setChosenIcon(_chosenIcon);
  const onInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <Box position="relative">
      <Box
        minHeight={minHeight}
        border={editing ? '' : `1px solid ${theme.palette.grey[300]}`}
        borderRadius={theme.shape.borderRadius}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {!editing ? (
          <Box padding={2}>
            <Button
              disableElevation
              color="edit"
              onClick={startEditing}
              type="button"
              variant="contained"
            >
              Click to add
            </Button>
          </Box>
        ) : null}
        {editing ? (
          <Box
            padding={2}
            top={0}
            left={0}
            position="absolute"
            zIndex={1}
            borderRadius={theme.shape.borderRadius}
            height="auto"
            width={width}
            className={c.addInfoBoxContainer}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              {showIconsPicker ? (
                <React.Fragment>
                  <Box style={{ cursor: 'pointer' }}>
                    <IconPicker
                      defaultIcon={['fal', chosenIcon.iconName]}
                      onChange={onIconChange}
                    />
                  </Box>
                  <Box style={{ cursor: 'pointer' }} onClick={cancelEditing}>
                    <FontAwesomeIcon size="lg" icon={['fal', 'times']} />
                  </Box>
                </React.Fragment>
              ) : null}
            </Box>
            <form onSubmit={submit}>
              <Box className={c.title}>
                <input
                  value={form.title}
                  onChange={onInputChange}
                  type="text"
                  className={errors.title ? c.inputWithError : c.input}
                  placeholder="Title"
                  name="title"
                  id="addInfoBox_title"
                  style={{ color: txtColor(theme, true) }}
                />
                {errors.title ? (
                  <Box
                    fontSize={theme.typography.pxToRem(12)}
                    color="error.main"
                  >
                    {errors.title}
                  </Box>
                ) : null}
              </Box>
              {showContentField ? (
                <Box className={c.u_mbSmall}>
                  <TextareaAutosize
                    rowsMin={2}
                    className={
                      errors.content ? c.textareaWithError : c.textarea
                    }
                    name="content"
                    value={form.content}
                    onChange={onInputChange}
                    placeholder="Short description"
                    style={{ color: txtColor(theme, true) }}
                  />
                  {errors.content ? (
                    <Box
                      fontSize={theme.typography.pxToRem(12)}
                      color="error.main"
                    >
                      {errors.content}
                    </Box>
                  ) : null}
                </Box>
              ) : null}

              <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection="row-reverse"
              >
                <Button
                  color="edit"
                  variant="outlined"
                  type="submit"
                  size="small"
                  disableElevation
                >
                  <FontAwesomeIcon
                    icon={['fal', editingItem ? 'save' : 'plus']}
                  />
                  <Box marginLeft={1} component="span">
                    {editingItem ? 'Update' : 'Add'}
                  </Box>
                </Button>
                <Box marginRight={1}>
                  <Button
                    color="white"
                    variant="contained"
                    type="button"
                    size="small"
                    disableElevation
                    onClick={cancelEditing}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
