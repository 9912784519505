import React from 'react';
import { Error } from '../../../modals/components/Error';
import { enqueueModal } from '../../../modals/actions';

function dispatchSaveError(dispatch) {
  dispatch(
    enqueueModal(
      <Error>
        <span>We were not able to save your input. Please, try again.</span>
      </Error>
    )
  );
}

export { dispatchSaveError };
