// @flow
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, {
  useCallback,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import type { Node } from 'react';
import {
  getCompanyDataRequest,
  uploadCompanyImageRequest,
  updateCompanyDataRequest,
} from '../companiesService';
import { enqueueNotification } from '../../../notifications/actions';
import { Confirm } from '../../../notifications/components/Confirm';
import { useStateContainer } from '../../../../core/context/StateContainer';
import { paths } from '../../../../core/constants';
import { useMyCompany } from './MyCompanyContext';
import {
  convToGenericCompProp,
  convToLocation,
  convToServerImage,
  normServerImage,
} from '../utils';
import type { Company } from '../types';

// ---- Types

export type ServerImageCollection =
  | 'logo'
  | 'product'
  | 'main'
  | 'office'
  | 'culture'
  | 'clients'
  | 'job-product'
  | 'job-tooling'
  | 'photos'
  | 'team-lead';
export type ServerImage = {
  id?: number | string,
  collection: ServerImageCollection,
  name: string,
};
export type ServerCompProp = { icon: string, title: string, text?: string };
export type Image = { id: number | string, src: string, blob: any };
export type ServerLocation = any; /* {
  address: {
    address_components: Array<{
      long_name: string,
      short_name: string,
      types: Array<string>,
    }>,
    formatted_address: string,
    geometry: {
      bounds: any,
      location: { lat: any, lng: any },
      location_type: string,
      viewport: any,
    },
    place_id: string,
    types: Array<string>,
  },
  comment: string | null,
  founded: any,
  id: number,
  teamSize: number | null,
}; */
export const CompDndPropType = {
  highlights: 'highlights',
  awards: 'awards',
  products: 'products',
  photos: 'photos',
  cultureAndPerks: 'cultureAndPerks',
  values: 'values',
};
export type CarouselItem = {
  id: number | string,
  dndType: $Values<typeof CompDndPropType>,
  data: { src: string, [key: string]: any },
};

type GenericCompProp = {
  id: number,
  icon?: string,
  title: string,
  content?: string,
  dndType?: $Values<typeof CompDndPropType>,
};
export type Highlight = GenericCompProp;
export type Award = GenericCompProp;
export type CultureAndPerks = GenericCompProp;
export type Value = GenericCompProp;

export type CompProp = Highlight | Award | Value | CultureAndPerks;
export type OnAddCompProp = (CompProp) => void;
export type OnEditCompProp = (CompProp) => void;
export type OnRemoveComProp = (CompProp) => void;
export type OnMoveCompProp = ({ drag: CompProp, drop: CompProp }) => void;

export type LocationObj = ServerLocation; /* {
  id: number,
  location?: {
    label: string,
    value: {
      description?: string,
      place_id?: string,
      reference?: string,
      matched_substrings?: any[],
      structured_formatting?: { [key: string]: any },
      terms?: any[],
      types?: any[],
    },
  },
  size?: string | number,
  yearSince?: string | number,
}; */
export type Errors = {
  overview?: string,
  logo?: string,
  locations?: string,
  mainImage?: string,
  awards?: string,
  photos?: string,
  productImages?: string,
  cultureAndPerks?: string,
  values?: string,
};
export type ServerCompany = {
  id: ?number,
  brand: ?string,
  slug: ?string,
  public: boolean,
  overview: string,
  images: ServerImage[],
  locations: Array<ServerLocation>,
  product: string,
  awards: ServerCompProp[],
  perks: ServerCompProp[],
  values: ServerCompProp[],
};
export type EditableState = {
  canBeEdited: boolean,
  companyNotFound: boolean,
  errors: Errors,
  showWizard: boolean,
  onWizardEnd: () => void,
  isPreview: boolean,
  setIsPreview: (boolean) => void,
  isEdit: boolean,
  setIsEdit: (boolean) => void,
  setIsPublic: (boolean) => void,
  addLogo: (Image) => Promise<Image>,
  setOverview: (string) => void,
  addMainImage: (Image) => Promise<Image>,
  addLocation: (LocationObj) => void,
  updateLocation: (LocationObj) => void,
  removeLocation: (id: number) => void,
  addProductImage: (Image) => Promise<Image>,
  removeProductImage: (CarouselItem) => void,
  moveProductImage: ({ drag: CarouselItem, drop: CarouselItem }) => void,
  publish: () => Promise<void>,
  save: (overrides?: {
    id?: number,
    brand?: string,
    slug?: string,
    public?: boolean,
    overview?: string,
    images?: Array<ServerImage>,
    locations?: Array<ServerLocation>,
    product?: string,
    awards?: Array<ServerCompProp>,
    perks?: Array<ServerCompProp>,
    values?: Array<ServerCompProp>,
    initialLocations?: Array<any>,
  }) => Promise<void>,
  setProduct: (string) => void,
  addAward: (Award) => void,
  removeAward: (Award) => void,
  moveAward: ({ drag: Award, drop: Award }) => void,
  editAward: (Award) => void,
  removeClientImage: (Image) => void,
  addClientImage: (Image) => Promise<Image>,
  addPhoto: (Image) => Promise<Image>,
  movePhoto: ({ drag: Image, drop: Image }) => void,
  removePhoto: (Image) => void,
  addCultureAndPerks: (CultureAndPerks) => void,
  moveCultureAndPerks: ({
    drag: CultureAndPerks,
    drop: CultureAndPerks,
  }) => void,
  removeCultureAndPerks: (CultureAndPerks) => void,
  editCultureAndPerks: (CultureAndPerks) => void,
  addValue: (Value) => void,
  removeValue: (Value) => void,
  editValue: (Value) => void,
  moveValue: ({ drag: Value, drop: Value }) => void,
  exitEdit: ((choice: 'OK' | 'CANCEL') => void) => void,
};
export type CompanyCtx = {
  loading: boolean,
  load: (slug: string) => any,
  state: Company<LocationObj>,
} & EditableState;

// ---- Utils

const { useState } = React;
const Context = createContext<CompanyCtx | null>(null);

const CompanyProvider = ({ children }: { children: Node }) => {
  const history = useHistory();
  const location = useLocation();
  const [
    {
      auth: { user, isCompany, isAdmin },
    },
    dispatch,
  ] = useStateContainer();

  // --------------------------------------------------------------------- //
  // resolve if the loaded company can be edited
  const [canBeEdited, setCanBeEdited] = useState(false);
  // --------------------------------------------------------------------- //

  const [companyNotFound, setCompanyNotFound] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  // --------------------------------------------------------------------- //
  // based on the preview=true query param - set isPreview
  const [isPreview, setIsPreview] = useState<boolean>(false);
  useEffect(() => {
    setIsPreview(
      canBeEdited && location.search && location.search.includes('preview=true')
    );
  }, [location, canBeEdited]);
  // --------------------------------------------------------------------- //

  // --------------------------------------------------------------------- //
  // based on the edit=true query param - set isEdit
  const [isEdit, setIsEdit] = useState<boolean>(false);
  useEffect(() => {
    setIsEdit(
      canBeEdited && location.search && location.search.includes('edit=true')
    );
  }, [location, canBeEdited]);
  // --------------------------------------------------------------------- //

  // --------------------------------------------------------------------- //
  // based on the wiz=true query param - set showWizard
  const [showWizard, setShowWizard] = useState<boolean>(false);
  useEffect(() => {
    setShowWizard(
      canBeEdited && location.search && location.search.includes('wiz=true')
    );
  }, [location, canBeEdited]);
  // --------------------------------------------------------------------- //

  const [responseProcessed, setResponseProcessed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [id, setId] = useState<?number>(null);
  const [brand, setBrand] = useState<?string>(null);
  const [slug, setSlug] = useState<?string>(null);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [editIsPublic, setEditIsPublic] = useState<boolean>(false);
  const [overview, setOverview] = useState<string>('');
  const [editOverview, setEditOverview] = useState<string>('');
  const [locations, setLocations] = useState<Array<LocationObj>>([]);
  const [editLocations, setEditLocations] = useState<Array<LocationObj>>([]);
  const [logos, setLogos] = useState<Image[]>([]);
  const [editLogos, setEditLogos] = useState<Image[]>([]);
  const [mainImages, setMainImages] = useState<Image[]>([]);
  const [editMainImages, setEditMainImages] = useState<Image[]>([]);
  const [teamLeadImages, setTeamLeadImages] = useState<Image[]>([]);
  const [product, setProduct] = useState<string>('');
  const [editProduct, setEditProduct] = useState<string>('');
  const [productImages, setProductImages] = useState<Image[]>([]);
  const [editProductImages, setEditProductImages] = useState<Image[]>([]);
  const [photos, setPhotos] = useState<Image[]>([]);
  const [editPhotos, setEditPhotos] = useState<Image[]>([]);
  const [clientImages, setClientImages] = useState<Image[]>([]);
  const [editClientImages, setEditClientImages] = useState<Image[]>([]);
  const [awards, setAwards] = useState<Award[]>([]);
  const [editAwards, setEditAwards] = useState<Award[]>([]);
  const [cultureAndPerks, setCultureAndPerks] = useState<CultureAndPerks[]>([]);
  const [editCultureAndPerks, setEditCultureAndPerks] = useState<
    CultureAndPerks[]
  >([]);
  const [values, setValues] = useState<Value[]>([]);
  const [editValues, setEditValues] = useState<Value[]>([]);
  const [initialLocations, setInitialLocations] = useState<any>([]);

  const uploadImageRequest = useCallback(
    (image: Image, type: ServerImageCollection) => {
      return uploadCompanyImageRequest(
        image,
        type,
        user && isAdmin(user) ? id : undefined
      );
    },
    [user, isAdmin, id]
  );

  useEffect(() => {
    if (responseProcessed) {
      setLoading(false);
      setResponseProcessed(false);
    }

    const userIsCompany = user && isCompany(user);
    const userIsAdmin = user && isAdmin(user);

    if (userIsAdmin || (userIsCompany && slug === user.companySlug)) {
      setCanBeEdited(true);
    } else {
      setCanBeEdited(false);
    }
  }, [responseProcessed, isCompany, isAdmin, user, slug]);

  const load = useCallback(
    async (urlCompanySlug: string) => {
      setLoading(true);

      getCompanyDataRequest(urlCompanySlug)
        .then((response) => {
          setId(response.data.id);
          setBrand(response.data.brand);
          setSlug(response.data.slug);
          setIsPublic(response.data.public || isPublic);
          setEditIsPublic(response.data.public || editIsPublic);
          setOverview(response.data.overview);
          setEditOverview(response.data.overview || editOverview);

          const rInitialLocations = response.data.locations;
          setInitialLocations(rInitialLocations || []);

          const rLocations = response.data.locations.map(convToLocation);
          setLocations(rLocations || locations);
          setEditLocations(rLocations || editLocations);

          const rLogos = response.data.images
            .filter((i) => i.collection === 'logo')
            .map(normServerImage);
          setLogos(rLogos || logos);
          setEditLogos(rLogos || editLogos);

          const rMainImages = response.data.images
            .filter((i) => i.collection === 'main')
            .map(normServerImage);
          setMainImages(rMainImages || mainImages);
          setEditMainImages(rMainImages || editMainImages);

          const rProduct = response.data.product;
          setProduct(rProduct || product);
          setEditProduct(rProduct || editProduct);

          const rProductImages = response.data.images
            .filter((i) => i.collection === 'product')
            .map(normServerImage);
          setProductImages(rProductImages || productImages);
          setEditProductImages(rProductImages || editProductImages);

          const rPhotos = response.data.images
            .filter(
              (i) =>
                i.collection === 'photos' ||
                i.collection === 'culture' ||
                i.collection === 'office'
            )
            .map(normServerImage);
          setPhotos(rPhotos || photos);
          setEditPhotos(rPhotos || editPhotos);

          const rClientImages = response.data.images
            .filter((i) => i.collection === 'clients')
            .map(normServerImage);
          setClientImages(rClientImages || clientImages);
          setEditClientImages(rClientImages || editClientImages);

          const rTeamLeadImages = response.data.images
            .filter((i) => i.collection === 'team-lead')
            .map(normServerImage);
          setTeamLeadImages(rTeamLeadImages);

          const rAwards =
            response.data.awards && response.data.awards.length
              ? response.data.awards.map(convToGenericCompProp)
              : null;
          setAwards(rAwards || []);
          setEditAwards(rAwards || []);

          const rPerks = response.data.perks.map(convToGenericCompProp);
          setCultureAndPerks(rPerks || cultureAndPerks);
          setEditCultureAndPerks(rPerks || editCultureAndPerks);

          const rValues = response.data.values.map(convToGenericCompProp);
          setValues(rValues || values);
          setEditValues(rValues || editValues);
        })
        .then(() => setResponseProcessed(true))
        .catch((err) => {
          if (err.response && err.response.status === 404)
            setCompanyNotFound(true);
          setResponseProcessed(true);
        });
    },
    [
      clientImages,
      cultureAndPerks,
      editClientImages,
      editCultureAndPerks,
      editIsPublic,
      editLogos,
      editMainImages,
      editOverview,
      editPhotos,
      editProductImages,
      editProduct,
      editValues,
      editLocations,
      isPublic,
      logos,
      mainImages,
      photos,
      productImages,
      product,
      values,
      locations,
    ]
  );
  // --------------------------------------------------------------------- //
  // initial company load
  // useEffect(() => {
  //   const companyNotLoaded = loading && !loadReqFired;
  //   if (companyNotLoaded) {
  //     const urlCompanySlug = match && match.params && match.params.slug;
  //     if (!urlCompanySlug) return;
  //     setLoadReqFired(true);

  //     load(urlCompanySlug)
  //       .then(() => {
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         if (err.response && err.response.status === 404)
  //           setCompanyNotFound(true);
  //       });
  //   }
  // }, [load, loading, match, loadReqFired]);
  // --------------------------------------------------------------------- //

  const copyEditValues = () => {
    setIsPublic(editIsPublic);
    setOverview(editOverview);
    setLogos(editLogos);
    setLocations(editLocations);
    setMainImages(editMainImages);
    setProductImages(editProductImages);
    setProduct(editProduct);
    setPhotos(editPhotos);
    setClientImages(editClientImages);
    setAwards(editAwards);
    setCultureAndPerks(editCultureAndPerks);
    setValues(editValues);
  };

  function request(onDone?: () => void, overrides = {}) {
    const reqData = {
      id,
      brand,
      slug,
      public: editIsPublic,
      overview: editOverview,
      images: (function buildServerImages(): ServerImage[] {
        const images: ServerImage[] = [];

        if (editMainImages.length)
          images.push(...editMainImages.map(convToServerImage('main')));
        if (editLogos.length)
          images.push(...editLogos.map(convToServerImage('logo')));
        if (editProductImages.length)
          images.push(...editProductImages.map(convToServerImage('product')));
        if (editClientImages.length)
          images.push(...editClientImages.map(convToServerImage('clients')));
        if (editPhotos.length)
          images.push(...editPhotos.map(convToServerImage('photos')));
        if (teamLeadImages.length)
          images.push(...teamLeadImages.map(convToServerImage('team-lead')));

        return images;
      })(),
      locations: editLocations.map((l) => ({
        id: l.id,
        address: JSON.stringify(l.address),
        founded: l.founded,
        teamSize: l.teamSize,
        comment: null,
      })),
      product: editProduct,
      awards: editAwards.map((a) => ({
        icon: a.icon || 'heart',
        title: a.title,
      })),
      perks: editCultureAndPerks.map((cp) => ({
        icon: cp.icon || 'heart',
        title: cp.title,
        text: cp.content,
      })),
      values: editValues.map((v) => ({
        icon: v.icon || 'heart',
        title: v.title,
        text: v.content,
      })),
      ...overrides,
    };

    // submit a request
    // we submit only if:
    // - the profile is going to be public & there are no errors
    // - the profile is not going to be public (just saving changes in draft - without considering errors at all)
    updateCompanyDataRequest(reqData, { admin: isAdmin(user) }).then(() => {
      // fake delay for the loader
      setInitialLocations(editLocations);
      setTimeout(() => {
        if (onDone) onDone();
      }, 1000);
    });
  }

  const save = (overrides = {}) => {
    return new Promise((resolve) => {
      copyEditValues();
      request(resolve, overrides);
    });
  };

  const publish = (overrides = {}) => {
    const promiseHandler = (resolve, reject) => {
      const err: Errors = {};
      // validate

      if (!isAdmin(user)) {
        if (!editOverview.length) err.overview = 'Company overview';
        if (!editLogos.length) err.logo = 'Logo';
        if (!editLocations.length)
          err.locations = 'At least one office location';
        if (!editMainImages.length) err.mainImage = 'Cover image';
        // if (!editAwards.length) err.awards = 'At least one achievement';
        // if (editProductImages.length < 3)
        //   err.productImages = 'At least 3 product images';
        if (editPhotos.length < 4) err.photos = 'At least 4 photos';
        if (editCultureAndPerks.length < 3)
          err.cultureAndPerks = 'At least 3 culture and perks';
        // if (!editValues.length) err.values = 'At least one company value';
      }

      setErrors(err);

      const hasErrors = Object.keys(err).length;

      // set edit* props to their corresponding public props
      if (!hasErrors) copyEditValues();

      // request
      if (!hasErrors) {
        request(resolve, { public: true, ...overrides });
      } else {
        reject();
      }
    };

    return new Promise(promiseHandler);
  };

  const value = {
    canBeEdited,
    companyNotFound,
    load,
    loading,
    errors,
    state: {
      id,
      brand,
      slug,
      isPublic: isEdit || isPreview ? editIsPublic : isPublic,
      overview: isEdit || isPreview ? editOverview : overview,
      logos: isEdit || isPreview ? editLogos : logos,
      initialLocations,
      locations: isEdit || isPreview ? editLocations : locations,
      mainImages: isEdit || isPreview ? editMainImages : mainImages,
      product: isEdit || isPreview ? editProduct : product,
      productImages: isEdit || isPreview ? editProductImages : productImages,
      photos: isEdit || isPreview ? editPhotos : photos,
      clientImages: isEdit || isPreview ? editClientImages : clientImages,
      awards: isEdit || isPreview ? editAwards : awards,
      cultureAndPerks:
        isEdit || isPreview ? editCultureAndPerks : cultureAndPerks,
      values: isEdit || isPreview ? editValues : values,
    },
    showWizard,
    onWizardEnd: () => {
      history.replace({ pathname: location.pathname, search: '?edit=true' });
    },
    isPreview,
    setIsPreview: () => {
      history.push({
        pathname: location.pathname,
        search: '?preview=true',
      });
      // setIsEdit(false);
      // setIsPreview(p);
    },
    isEdit,
    setIsEdit: () => {
      history.push({ pathname: location.pathname, search: '?edit=true' });
      // setIsPreview(false);
      // setIsEdit(e);
    },
    setIsPublic: setEditIsPublic,
    addLogo: (image: Image) => {
      return new Promise((resolve, reject) => {
        uploadImageRequest(image, 'logo')
          .then((response) => {
            if (response.status === 200) {
              const rImage = normServerImage({ ...image, ...response.data });
              setEditLogos((p) => [...p, rImage]);
              resolve(rImage);
            } else {
              // @Todo: show a warning or error
              reject(response);
            }
          })
          .catch((error) => {
            if (error.response.status === 500) {
              reject(
                new Error(
                  'Image upload failed. Please, try again or contact our support team.'
                )
              );
            }
          });
      });
    },
    setOverview: setEditOverview,
    addMainImage: (image: Image) => {
      return new Promise((resolve, reject) => {
        uploadImageRequest(image, 'main')
          .then((response) => {
            if (response.status === 200) {
              const rImage = normServerImage({ ...image, ...response.data });
              setEditMainImages((p) => [...p, rImage]);
              resolve(rImage);
            } else {
              // @Todo: show a warning or error
            }
          })
          .catch(() => {
            reject(
              new Error(
                'Image upload failed. Please, try again or contact our support team.'
              )
            );
          });
      });
    },
    addLocation: (l) => setEditLocations((prev) => [...prev, l]),
    updateLocation: (l) =>
      setEditLocations((prev) =>
        prev.map((item) => {
          if (item.id === l.id) {
            return l;
          }
          return item;
        })
      ),
    removeLocation: (locId) =>
      setEditLocations((prev) => prev.filter((item) => item.id !== locId)),
    addProductImage: (image: Image) => {
      return new Promise((resolve, reject) => {
        uploadImageRequest(image, 'product')
          .then((response) => {
            if (response.status === 200) {
              const rImage = normServerImage({ ...image, ...response.data });
              setEditProductImages(() => [...editProductImages, rImage]);
              resolve(rImage);
            } else {
              // @Todo: show a warning or error
            }
          })
          .catch((error) => {
            if (error.response.status === 500) {
              reject(
                new Error(
                  'Image upload failed. Please, try again or contact our support team.'
                )
              );
            }
          });
      });
    },
    removeProductImage: (item: CarouselItem) => {
      setEditProductImages((prev) => prev.filter((i) => i.id !== item.id));
    },
    moveProductImage: ({
      drag,
      drop,
    }: {
      drag: CarouselItem,
      drop: CarouselItem,
    }) => {
      const dragIdx = editProductImages.findIndex(
        (i) => i.src === drag.data.src
      );
      const dropIdx = editProductImages.findIndex(
        (i) => i.src === drop.data.src
      );
      setEditProductImages((prev) => {
        const newState = [...prev];
        newState.splice(dropIdx, 1, prev[dragIdx]);
        newState.splice(dragIdx, 1, prev[dropIdx]);
        return newState;
      });
    },
    setProduct: setEditProduct,
    publish,
    save,
    addAward: (award: Award) => {
      setEditAwards((prev) => [...prev, award]);
    },
    moveAward: ({ drag, drop }: { drag: Award, drop: Award }) => {
      if (drag.id === drop.id) return;

      const dragIdx = editAwards.findIndex((i) => i.id === drag.id);
      const dropIdx = editAwards.findIndex((i) => i.id === drop.id);
      setEditAwards((prev) => {
        const newState = [...prev];
        newState.splice(dropIdx, 1, prev[dragIdx]);
        newState.splice(dragIdx, 1, prev[dropIdx]);
        return newState;
      });
    },
    editAward: (a: Award) =>
      setEditAwards((prev) => {
        return prev.map((i) => (i.id === a.id ? { ...i, ...a } : i));
      }),
    removeAward: (a: Award) => {
      setEditAwards((prev) => prev.filter((i) => i.id !== a.id));
    },
    removeClientImage: (i: Image) =>
      setEditClientImages((prev) => prev.filter((pi) => pi !== i)),
    addClientImage: (i: Image) => {
      return new Promise((resolve, reject) => {
        uploadImageRequest(i, 'clients')
          .then((response) => {
            if (response.status === 200) {
              const rImage = normServerImage({ ...i, ...response.data });
              setEditClientImages((prev) => [...prev, rImage]);
              resolve(rImage);
            } else {
              // @Todo: show a warning or error
            }
          })
          .catch((error) => {
            if (error.response.status === 500) {
              reject(
                new Error(
                  'Image upload failed. Please, try again or contact our support team.'
                )
              );
            }
          });
      });
    },
    movePhoto: ({ drag, drop }: { drag: Image, drop: Image }) => {
      if (drag.id === drop.id) return;

      const dragIdx = editPhotos.findIndex((i) => i.id === drag.id);
      const dropIdx = editPhotos.findIndex((i) => i.id === drop.id);
      setEditPhotos((prev) => {
        const newState = [...prev];
        newState.splice(dropIdx, 1, prev[dragIdx]);
        newState.splice(dragIdx, 1, prev[dropIdx]);
        return newState;
      });
    },
    removePhoto: (p: Image) =>
      setEditPhotos((prev) => prev.filter((i) => i.id !== p.id)),
    addPhoto: (image: Image) => {
      return new Promise((resolve, reject) => {
        uploadImageRequest(image, 'photos')
          .then((response) => {
            if (response.status === 200) {
              const rImage = normServerImage({ ...image, ...response.data });
              setEditPhotos((prev) => [...prev, rImage]);
              resolve(rImage);
            } else {
              // @Todo: show a warning or error
            }
          })
          .catch((error) => {
            if (error.response.status === 500) {
              reject(
                new Error(
                  'Image upload failed. Please, try again or contact our support team.'
                )
              );
            }
          });
      });
    },
    addCultureAndPerks: (cp: CultureAndPerks) =>
      setEditCultureAndPerks((prev) => [...prev, cp]),
    moveCultureAndPerks: ({
      drag,
      drop,
    }: {
      drag: CultureAndPerks,
      drop: CultureAndPerks,
    }) => {
      if (drag.id === drop.id) return;

      const dragIdx = editCultureAndPerks.findIndex((i) => i.id === drag.id);
      const dropIdx = editCultureAndPerks.findIndex((i) => i.id === drop.id);
      setEditCultureAndPerks((prev) => {
        const newState = [...prev];
        newState.splice(dropIdx, 1, prev[dragIdx]);
        newState.splice(dragIdx, 1, prev[dropIdx]);
        return newState;
      });
    },
    removeCultureAndPerks: (cp: CultureAndPerks) =>
      setEditCultureAndPerks((prev) => prev.filter((i) => i.id !== cp.id)),
    editCultureAndPerks: (cp: CultureAndPerks) => {
      setEditCultureAndPerks((prev) => {
        return prev.map((i) => {
          return i.id === cp.id ? { ...i, ...cp } : i;
        });
      });
    },
    addValue: (v) => setEditValues((prev) => [...prev, v]),
    removeValue: (v) =>
      setEditValues((prev) => prev.filter((i) => i.id !== v.id)),
    editValue: (v) =>
      setEditValues((prev) => {
        return prev.map((i) => (i.id === v.id ? { ...i, ...v } : i));
      }),
    moveValue: ({ drag, drop }: { drag: Value, drop: Value }) => {
      if (drag.id === drop.id) return;

      const dragIdx = editValues.findIndex((i) => i.id === drag.id);
      const dropIdx = editValues.findIndex((i) => i.id === drop.id);
      setEditValues((prev) => {
        const newState = [...prev];
        newState.splice(dropIdx, 1, prev[dragIdx]);
        newState.splice(dragIdx, 1, prev[dropIdx]);
        return newState;
      });
    },
    exitEdit: (onConfirmation?: (choice: 'OK' | 'CANCEL') => void) => {
      dispatch(
        enqueueNotification(
          <Confirm
            vertical="top"
            horizontal="center"
            disableAutoHide
            onClose={(choice) => {
              if (choice === 'OK')
                history.push({ pathname: location.pathname, search: '' });
              if (typeof onConfirmation === 'function') onConfirmation(choice);
            }}
          >
            All unsaved changes will be lost. Exit anyway?
          </Confirm>
        )
      );
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCompany: () => CompanyCtx = () => {
  const ctx = useContext(Context);
  if (ctx === null) throw new Error('Improper use of CompanyProvider.');
  return ctx;
};

export default CompanyProvider;
