/* eslint-disable no-console */
import React from 'react';
import { render } from 'react-dom';
import './index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { icons } from './core/constants';
import { loadComponents, getState } from 'loadable-components';

// Load FontAwesome icons
library.add(...icons);

const app = (isSnap) => <App isSnap={isSnap} />;
const rootElement = document.getElementById('react-app');

loadComponents()
  .then(() => {
    console.log('BROWSER-render');
    render(app(false), rootElement, () => {
      Array.from(document.querySelectorAll('[data-jss-snap]')).forEach((elem) =>
        elem.parentNode.removeChild(elem)
      );
    });
  })
  .catch(() => {
    console.log('SNAP-render');
    render(app(true), rootElement);
  });

window.snapSaveState = () => {
  Array.from(document.querySelectorAll('[data-jss]')).forEach((elem) =>
    elem.setAttribute('data-jss-snap', '')
  );
  return getState();
};

/*
if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
