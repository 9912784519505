// @flow
/* eslint-disable react/require-default-props */
import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { Section } from '../Section';
import { Button } from '../../../components/basic/Button';

type Props = {
  testId?: string,
};

const useRowStyles = (last: boolean) =>
  makeStyles((theme) => ({
    root: {
      paddingBottom: last ? 0 : theme.spacing(2),
    },
  }));

const Row = ({
  children,
  last = false,
}: {
  children: React.Node,
  last?: boolean,
}) => {
  const classes = useRowStyles(last)();
  return <div className={classes.root}>{children}</div>;
};

function ButtonsPreview({ testId }: Props) {
  return (
    <Section testId={testId} title="Buttons">
      <Row>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => {}}
        >
          Primary Button
        </Button>
      </Row>

      <Row>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => {}}
          disabled
        >
          Primary Disabled
        </Button>
      </Row>

      <Row>
        <Button
          type="button"
          color="secondary"
          variant="contained"
          onClick={() => {}}
        >
          Secondary Button
        </Button>
      </Row>

      <Row last>
        <Button
          type="button"
          color="secondary"
          variant="contained"
          onClick={() => {}}
          disabled
        >
          Secondary Disabled
        </Button>
      </Row>
    </Section>
  );
}

ButtonsPreview.defaultProps = {
  testId: undefined,
};

export { ButtonsPreview };
