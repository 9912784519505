// @flow
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, useTheme } from '@material-ui/core';
import { Button } from '../../basic/Button';
import { Modal } from '../../basic/Modal';
import { useStateContainer } from '../../../core/context/StateContainer';
import { dequeueModal } from '../../modals/actions';
import type { Image } from '../../companies/common/context/CompanyContext';

export function PickProductImages({
  images,
  onPick,
  onUpload,
}: {
  images: Image[],
  onUpload: () => void,
  onPick: (choice: Image[]) => void,
}) {
  const theme = useTheme();
  const [, dispatch] = useStateContainer();
  const titleId = 'pickProductImages';
  const contentId = 'pickProductImagesContent';
  const [pickedImages, setPickedImages] = React.useState([]);

  function onSinglePick(image: Image) {
    setPickedImages((prev) => {
      if (prev.find((i) => i.src === image.src)) {
        return prev.filter((i) => i.src !== image.src);
      }
      return [...prev, image];
    });
  }

  return (
    <Modal
      open
      onClose={() => dispatch(dequeueModal())}
      titleId={titleId}
      contentId={contentId}
      withCloseButton={false}
    >
      <Box
        mb={2}
        minWidth={400}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>Pick product images</Box>
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() => dispatch(dequeueModal())}
        >
          <FontAwesomeIcon icon={['fal', 'times']} size="2x" />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" margin="-10px" mb={2}>
        {images.map((image) => {
          const isPicked = pickedImages.find((i) => i.src === image.src);
          return (
            <Box
              key={image.id}
              style={{
                borderRadius: 5,
                margin: 10,
                width: 150,
                height: 150,
                border: isPicked
                  ? `2px solid ${theme.palette.edit.main}`
                  : `2px solid ${theme.palette.grey[300]}`,
                boxShadow: isPicked && `0 5px 8px -2px rgba(0,0,0,0.2)`,
                backgroundImage: `url("${image.src}")`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                cursor: 'pointer',
              }}
              onClick={() => onSinglePick(image)}
            />
          );
        })}
      </Box>
      <Box
        display="flex"
        alignItems="space-between"
        justifyContent="space-between"
      >
        <Button
          type="button"
          color="edit"
          variant="contained"
          onClick={onUpload}
          disableElevation
        >
          Upload new
        </Button>
        <Button
          type="button"
          color="edit"
          variant="contained"
          onClick={() => onPick(pickedImages)}
          disableElevation
        >
          OK
        </Button>
      </Box>
    </Modal>
  );
}
