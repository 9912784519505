// @flow
import * as React from 'react';
import { Step } from '../../../basic/form/Step';
import { Row } from '../../../basic/form/Row';
import { SliderGroup } from '../../../basic/form/SliderGroup';
import type { SliderConfig } from '../../../../core/types';

type Props = {
  sliders: Array<SliderConfig>,
  buttons: React.Node,
};

function TechnicalSkillsRating({ sliders, buttons }: Props) {
  const heading = 'Rate your technical skills';
  const subhead =
    'Move the sliders on the skills below to mark which of them you are best at.';

  return (
    <Step
      sectionName="Skills"
      heading={heading}
      subhead={subhead}
      buttons={buttons}
    >
      <Row noBottomSpace>
        <SliderGroup name="technicalSkillsRating" sliders={sliders} />
      </Row>
    </Step>
  );
}

export { TechnicalSkillsRating };
