// @flow
import * as React from 'react';
import {
  composeDirectReferral as composeDirectReferralRequest,
  getReferralLink as getReferralLinkRequest,
  trackReferralLinks as trackReferralLinksRequest,
} from './referralsService';
import { useStateContainer } from '../../core/context/StateContainer';
import {
  composeDirectReferralEnd,
  composeDirectReferralStart,
  getReferralLinkEnd,
  getReferralLinkStart,
  setPendingGetReferralLinkStatus,
} from './actions';
import { REFERRAL_LINKS_KEY } from '../../core/constants';
import { buildReferralLinkObject } from '../../utils/buildReferralLinkObject';
import type { ReferralLinkObject, ValidateFunc } from '../../core/types';
import { enqueueModal } from '../modals/actions';
import { Error } from '../modals/components/Error';
import { sanitizeStagingAndLocalhostUrls } from '../../utils/sanitizeStagingAndLocalhostUrls';
import { buildValidateFunc } from '../../utils/buildValidateFunc';
import { useConsentDialogController } from '../../core/context/ConsentDialogController';
import { useDirectRefSentTrail } from './useDirectRefSentTrail';
import { log } from '../../utils/log';

// TODO
type Ctx = {
  getCurrentUrl: () => string,
  getReferralLink: ({ url: string }) => Promise<any>,
  storeReferralLink: ({ link: string }) => void,
  getStoredReferralLinks: () => ?[ReferralLinkObject],
  clearStoredReferralLinks: () => void,
  trackReferralLinks: ({
    links: [ReferralLinkObject],
  }) => Promise<any>,
  flushStoredReferralLinks: () => void,
  sendComposeDirectReferral: ({
    url: string,
    to: string,
    subject: string,
    message: string,
  }) => Promise<any>,
  validateComposeDirectReferral: (validateConfig: {
    [key: string]: any,
  }) => ValidateFunc,
};

type Props = {
  children: React.Node,
};

const { useContext, createContext } = React;

const ReferralsContext = createContext<Ctx>({});

function ReferralsProvider({ children }: Props) {
  const { showConsent } = useConsentDialogController();
  const [
    {
      auth: { authenticated },
    },
    dispatch,
  ] = useStateContainer();
  const { onDirectRefSent: directRefSentTrail } = useDirectRefSentTrail();
  function getCurrentUrl() {
    return sanitizeStagingAndLocalhostUrls(window.location.href);
  }

  function getReferralLink({ url }) {
    dispatch(getReferralLinkStart());
    return new Promise(function getReferralLinkIntermediateFunc(
      resolve,
      reject
    ) {
      dispatch(setPendingGetReferralLinkStatus(true));
      getReferralLinkRequest({ url, ignoreUnauthorized: authenticated })
        .then((response) => {
          dispatch(setPendingGetReferralLinkStatus(false));
          dispatch(getReferralLinkEnd(response.url));
          resolve(response);
        })
        .catch((err) => {
          dispatch(getReferralLinkEnd());

          if (err.response.data.message === 'Consent not given yet!') {
            showConsent({
              onAccept: () => {
                dispatch(setPendingGetReferralLinkStatus(false));
                // setTimeout(() => getReferralLink({ url }), 0);
              },
              onClose: () => {
                dispatch(setPendingGetReferralLinkStatus(false));
              },
            });
          }

          if (err.response.data.message === 'Rate exceeded') {
            dispatch(
              enqueueModal(
                <Error>
                  You have exceeded your links generation limit. Try again
                  later.
                </Error>
              )
            );
          }

          reject(err);
        });
    });
  }

  function storeReferralLink({ link }): void {
    try {
      const defaultStoredReferralLinks = JSON.stringify([]);
      const storedReferralLinks = JSON.parse(
        localStorage.getItem(REFERRAL_LINKS_KEY) || defaultStoredReferralLinks
      );

      const newReferralLinksToBeStored = [
        ...storedReferralLinks,
        buildReferralLinkObject({ link }),
      ];

      localStorage.setItem(
        REFERRAL_LINKS_KEY,
        JSON.stringify(newReferralLinksToBeStored)
      );
    } catch (error) {
      log(
        `Couldn't store the referral link to the user's localStorage. [link: ${link}]`,
        {
          logLevel: log.logLevels.error,
          error,
        }
      );
    }
  }

  function getStoredReferralLinks(): ?[ReferralLinkObject] {
    let storedReferralLinks = null;
    const storedReferralLinksJsonString: string =
      localStorage.getItem(REFERRAL_LINKS_KEY) || '';
    if (storedReferralLinksJsonString.length) {
      storedReferralLinks = JSON.parse(storedReferralLinksJsonString);
    }

    return storedReferralLinks;
  }

  function clearStoredReferralLinks() {
    localStorage.removeItem(REFERRAL_LINKS_KEY);
  }

  function trackReferralLinks({ links }): Promise<any> {
    return trackReferralLinksRequest({ links });
  }

  function flushStoredReferralLinks() {
    const storedReferralLinks = getStoredReferralLinks();
    if (storedReferralLinks) {
      trackReferralLinks({ links: storedReferralLinks }).then(
        clearStoredReferralLinks
      );
    }
  }

  function processValuesBeforeSend({ message, ...rest }) {
    return {
      ...rest,
      message: message.replace(/(?:\r\n|\r|\n)/g, '<br>'),
    };
  }

  function sendComposeDirectReferral(values) {
    const processedValues = processValuesBeforeSend(values);
    dispatch(composeDirectReferralStart());
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        composeDirectReferralRequest({ ...processedValues })
          .then((response) => {
            directRefSentTrail();
            dispatch(composeDirectReferralEnd());
            resolve(response);
          })
          .catch((err) => {
            dispatch(composeDirectReferralEnd());
            reject(err);
          });
      }, 2000);
    });
  }

  const validateComposeDirectReferral = (validateConfig: {
    [key: string]: any,
  }) => buildValidateFunc(validateConfig, (values, config) => config, 0);

  const defaultValue = {
    getCurrentUrl,
    getReferralLink,
    storeReferralLink,
    getStoredReferralLinks,
    clearStoredReferralLinks,
    trackReferralLinks,
    flushStoredReferralLinks,
    sendComposeDirectReferral,
    validateComposeDirectReferral,
  };

  return (
    <ReferralsContext.Provider value={defaultValue}>
      {children}
    </ReferralsContext.Provider>
  );
}

function useReferrals() {
  return useContext(ReferralsContext);
}

export { ReferralsContext, ReferralsProvider, useReferrals };
