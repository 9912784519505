// @flow
/* eslint-disable no-unused-expressions */
import React, { useRef, useEffect } from 'react';
import type { Node } from 'react';
import { makeStyles } from '@material-ui/core';
import { useOutsideClick } from '../../utils/useOutsideClick';
import { useOnEsc } from '../../utils/useOnEsc';
import { CSSTransition } from 'react-transition-group';

const useStyles = (props: { maxWidth?: number }) =>
  makeStyles((theme) => {
    return {
      root: {
        position: 'fixed',
        top: 0,
        left: 0,
        background: 'rgba(0,0,0,0.5)',
        width: '100%',
        height: '100%',
        zIndex: 10,
      },
      container: {
        zIndex: 20,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: theme.boxShadow,
        padding: theme.spacing(2),
      },
      innerContainer: {
        maxWidth: props.maxWidth || undefined,
        maxHeight: '90vh',
        overflow: 'auto',
        background: 'white',
        borderRadius: theme.shape.borderRadius,
      },

      tenterOpacity: {
        opacity: 0,
      },
      tenterActiveOpacity: {
        opacity: 1,
        transition: `opacity 0.1s`,
      },
      texitOpacity: {
        opacity: 1,
      },
      texitActiveOpacity: {
        opacity: 0,
        transition: `opacity 0.1s`,
      },

      tenter: {
        opacity: 0,
        transform: `translateY(-5%)`,
      },
      tenterActive: {
        opacity: 1,
        transform: `translateY(0)`,
        transition: `opacity 0.3s, transform 0.3s`,
      },
      texit: {
        opacity: 1,
      },
      texitActive: {
        opacity: 0,
        transform: `translateY(-5%)`,
        transition: `opacity 0.3s, transform 0.3s`,
      },
    };
  });

type Props = {
  children: Node,
  isOpen?: boolean,
  onClose: () => void,
  onEsc?: () => void,
  onOutsideClick?: () => void,
  maxWidth?: number,
};

export function Popup({
  children,
  onOutsideClick,
  onEsc,
  onClose,
  maxWidth,
  isOpen = false,
}: Props) {
  const innerContainerRef = useRef(null);
  const containerRef = useRef(null);
  const classes = useStyles({ maxWidth })();
  const transitionClassNames = {
    enter: classes.tenter,
    enterActive: classes.tenterActive,
    exit: classes.texit,
    exitActive: classes.texitActive,
  };
  const transitionClassNamesOpacity = {
    enter: classes.tenterOpacity,
    enterActive: classes.tenterActiveOpacity,
    exit: classes.texitOpacity,
    exitActive: classes.texitActiveOpacity,
  };

  useOnEsc(() => {
    if (typeof onEsc === 'function') {
      onEsc();
    } else {
      onClose();
    }
  });

  useOutsideClick(innerContainerRef, () => {
    if (typeof onOutsideClick === 'function') onOutsideClick();
    else onClose();
  });

  useEffect(() => {
    const { body } = document;

    if (isOpen) {
      body && body.classList.add('hidden');
    } else {
      body && body.classList.remove('hidden');
    }
  }, [isOpen]);

  return (
    <div>
      <CSSTransition
        in={isOpen}
        timeout={600}
        classNames={transitionClassNamesOpacity}
        unmountOnExit
      >
        <div
          ref={containerRef}
          role="dialog"
          aria-modal="true"
          tabIndex={-1}
          className={classes.root}
        />
      </CSSTransition>

      <CSSTransition
        in={isOpen}
        timeout={200}
        classNames={transitionClassNames}
        unmountOnExit
      >
        <div className={classes.container}>
          <div ref={innerContainerRef} className={classes.innerContainer}>
            {children}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

const usePopupTitleStyles = makeStyles((theme) => {
  return {
    root: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
});
export function PopupTitle({ children }: { children: Node }) {
  const classes = usePopupTitleStyles();
  return <div className={classes.root}>{children}</div>;
}

const usePopupContainerStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(4),
    },
  };
});
export function PopupContainer({ children }: { children: Node }) {
  const classes = usePopupContainerStyles();
  return <div className={classes.root}>{children}</div>;
}

const usePopupMainStyles = makeStyles(() => {
  return {
    root: {},
  };
});
export function PopupMain({ children }: { children: Node }) {
  const classes = usePopupMainStyles();
  return <div className={classes.root}>{children}</div>;
}

const usePopupErrorPanelStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.error.main,
      color: theme.palette.common.white,
    },
  };
});
export function PopupErrorPanel({ error }: { error?: string | null }) {
  const classes = usePopupErrorPanelStyles();
  if (!error) return null;
  return <div className={classes.root}>{error}</div>;
}
