// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  children: React.Node,
};

const useStyles = makeStyles((theme) => ({
  greyBg: {
    background: theme.palette.grey.light,
    padding: theme.spacing(3),
    borderRadius: '5px',
    marginBottom: theme.spacing(2),
  },
}));

function GreyContainer({ children }: Props) {
  const classes = useStyles();

  return <div className={classes.greyBg}>{children}</div>;
}

export { GreyContainer };
