// @flow
import * as React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Base64 } from 'js-base64';
import { Input } from '../../../../basic/form/Input';
import { Row } from '../../../../basic/form/Row';
import { Step } from '../../../../basic/form/Step';
import { SubmitButton } from '../../../../basic/form/Buttons';
import iconBg from '../../../../../assets/images/grey-bg.svg';
import { paths } from '../../../../../core/constants';
import { obj } from '../../../../../utils/obj';
import { Select } from '../../../../basic/form/Select';

const useStyles = makeStyles((theme) => ({
  inputControl: {
    width: '100%',
  },
  input: {
    width: '100%',
    minHeight: 100,
  },
  greyBg: {
    background: theme.palette.grey.light,
    padding: theme.spacing(3),
    borderRadius: '5px',
    marginBottom: theme.spacing(2),
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  iconContainer: {
    userSelect: 'none',
    position: 'relative',
    textAlign: 'center',
    marginRight: theme.spacing(1),
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: 26,
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.main,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const MotivationLetter = () => {
  const classes = useStyles();
  const query = useQuery();
  const jobAdTitle = Base64.decode(query.get('jat') || '');
  const jobAdReferenceKey = query.get('jark');
  const availabilityOptions = {
    immediately: 'Immediately available',
    '1_week': '1 week',
    '2_weeks': '2 weeks',
    '1_month': '1 month',
    '2_months': '2 months',
    '3_months': '3 months',
  };
  const buttons = (
    <>
      <SubmitButton>Done</SubmitButton>
    </>
  );

  const { search } = useLocation();
  if (!jobAdTitle || !jobAdReferenceKey) {
    return <Redirect to={paths.onboarding} query={search} />;
  }

  return (
    <Step sectionName={null} heading={jobAdTitle} buttons={buttons}>
      <Row>
        <Input
          multiline
          name="motivation"
          id="motivation"
          controlProps={{ className: classes.inputControl }}
          inputProps={{ className: classes.input }}
          label="Why do you want to work here?"
          placeholder="Explain in 2-3 sentences"
          required
        />
      </Row>
      <Row>
        <Select
          id="availability"
          name="availability"
          label="Availability/Notice period"
          required
        >
          <option value="" />
          {obj.mapKeys<$Keys<typeof availabilityOptions>, any>(
            availabilityOptions,
            (optKey) => {
              return (
                <option key={optKey} value={optKey}>
                  {availabilityOptions[optKey]}
                </option>
              );
            }
          )}
        </Select>
      </Row>
      <Row noBottomSpace>
        <div className={classes.footerContainer}>
          <div className={classes.iconContainer}>
            <img src={iconBg} alt="icon-background" draggable="false" />
            <FontAwesomeIcon
              icon={['fal', 'chart-bar']}
              className={classes.icon}
            />
          </div>
          <div>
            <p>
              Application with motivation gets hired 17% more often, so consider
              adding a few sentences why you want to work here? Keep it informal
              and say it with your own words.
            </p>
          </div>
        </div>
      </Row>
    </Step>
  );
};

export { MotivationLetter };
