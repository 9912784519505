// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

type Props = {
  children: React.Node,
  to?: ?string | ?{ pathname: string, search: string },
  internal?: boolean,
  light?: boolean,
  onClick?: ?() => void,
  className?: ?string,
  disabled?: boolean,
};

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: `${theme.palette.grey.dark} !important`,
  },
  root: {
    marginBottom: theme.spacing(2),
    display: 'block',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  light: {
    color: theme.palette.grey.main,
  },
}));

function MenuItem({
  children,
  onClick,
  to,
  internal,
  light,
  className,
  disabled,
  ...rest
}: Props) {
  const classes = useStyles();
  let classNames = `${classes.root} ${light ? classes.light : ''} ${
    className || ''
  }`;
  classNames = `${classNames} ${disabled ? classes.disabled : ''}`;
  let interceptedOnClick = onClick;

  if (disabled) {
    interceptedOnClick = () => {};
  }

  const InternalLink = () => {
    const Component = to ? Link : 'div';
    return (
      <Component
        to={to}
        className={classNames}
        onClick={interceptedOnClick}
        {...rest}
      >
        {children}
      </Component>
    );
  };

  const ExternalLink = () => (
    <a href={to} className={classNames} onClick={interceptedOnClick} {...rest}>
      {children}
    </a>
  );

  return internal ? <InternalLink /> : <ExternalLink />;
}

MenuItem.defaultProps = {
  to: null,
  internal: false,
  light: false,
  onClick: null,
  className: null,
  disabled: false,
};

export { MenuItem };
