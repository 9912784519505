// @flow
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { paths } from '../../../../core/constants';
import { useDirectReferralLinkVisit } from './useDirectReferralLinkVisit';

function DirectReferralLinkVisit() {
  const { handleDirectReferralLinkVisit } = useDirectReferralLinkVisit();

  useEffect(handleDirectReferralLinkVisit, []);

  const utm =
    'utm_source=direct-invite-link&utm_medium=referral&utm_campaign=refinfo';
  return <Redirect to={{ pathname: paths.root, search: utm }} />;
}

export { DirectReferralLinkVisit };
