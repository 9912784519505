// @flow
import * as React from 'react';
import { Field } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import { Slider as MaterialSlider } from '@material-ui/core';

type ChangeFn = (e: any, newValue: number, offset: number) => void;
type Props = {
  name: string,
  showTooltip: boolean,
  onChange?: ChangeFn,
};

type SliderAdapterProps = {
  input: { onChange: any, value: any },
  meta: any,
  showTooltip: boolean,
  customOnChange?: ChangeFn,
};

const { useRef, useEffect } = React;

const CustomSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.grey.main,
    height: 6,
  },
  thumb: ({ dirty }) => {
    return {
      color:
        dirty === 'false'
          ? theme.palette.grey.main
          : theme.palette.common.black,
      height: 16,
      width: 16,
      marginTop: -5,
      marginLeft: -5,
      '&:focus,&:hover': {
        boxShadow: 'inherit',
        color: theme.palette.primary.main,
      },
    };
  },
  mark: {
    width: '1px',
    height: '6px',
    background: theme.palette.common.white,
  },
  valueLabel: {
    left: 'calc(-50%)',
  },
  track: {
    height: 6,
    borderRadius: 5,
  },
  rail: {
    height: 6,
    borderRadius: 5,
  },
}))(MaterialSlider);

function SliderAdapter({
  input,
  meta,
  showTooltip,
  customOnChange = () => {},
  ...rest
}: SliderAdapterProps) {
  const { onChange, value } = input;
  const prev: { current: any } = useRef(value);

  useEffect(() => {
    prev.current = value;
  }, [value]);

  return (
    <CustomSlider
      {...input}
      dirty={
        meta.dirty.toString() /* we do .toString(), because of the "Received `false` for a non-boolean attribute `dirty`" error */
      }
      valueLabelDisplay={showTooltip ? 'auto' : 'off'}
      aria-label="slider"
      {...rest}
      value={value === '' ? 0 : value}
      onChange={(e, newValue) => {
        const offset = newValue - prev.current;
        onChange(newValue);
        customOnChange(e, newValue, offset);
      }}
    />
  );
}

SliderAdapter.defaultProps = {
  customOnChange: () => {},
};

function Slider({ name, showTooltip, onChange, ...rest }: Props) {
  return (
    <Field
      name={name}
      component={SliderAdapter}
      showTooltip={showTooltip}
      customOnChange={onChange}
      {...rest}
    />
  );
}

Slider.defaultProps = {
  onChange: () => {},
};

export { Slider };
