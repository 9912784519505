const GET_REFERRAL_LINK_START = 'GET_REFERRAL_LINK_START';
const GET_REFERRAL_LINK_END = 'GET_REFERRAL_LINK_END';
const SET_PENDING_GET_REFERRAL_LINK_STATUS =
  'SET_PENDING_GET_REFERRAL_LINK_STATUS';
const SET_PENDING_COMPOSE_DIRECT_REFERRAL_STATUS =
  'SET_PENDING_COMPOSE_DIRECT_REFERRAL_STATUS';
const COMPOSE_DIRECT_REFERRAL_START = 'COMPOSE_DIRECT_REFERRAL_START';
const COMPOSE_DIRECT_REFERRAL_END = 'COMPOSE_DIRECT_REFERRAL_END';

function getReferralLinkStart() {
  return { type: GET_REFERRAL_LINK_START };
}

function getReferralLinkEnd(link = null) {
  return { type: GET_REFERRAL_LINK_END, data: link };
}

function setPendingGetReferralLinkStatus(pending = true) {
  return { type: SET_PENDING_GET_REFERRAL_LINK_STATUS, data: pending };
}

function setPendingComposeDirectReferralStatus(pending = true) {
  return { type: SET_PENDING_COMPOSE_DIRECT_REFERRAL_STATUS, data: pending };
}

function composeDirectReferralStart() {
  return { type: COMPOSE_DIRECT_REFERRAL_START };
}

function composeDirectReferralEnd() {
  return { type: COMPOSE_DIRECT_REFERRAL_END };
}

export {
  GET_REFERRAL_LINK_START,
  GET_REFERRAL_LINK_END,
  SET_PENDING_GET_REFERRAL_LINK_STATUS,
  SET_PENDING_COMPOSE_DIRECT_REFERRAL_STATUS,
  COMPOSE_DIRECT_REFERRAL_START,
  COMPOSE_DIRECT_REFERRAL_END,
  getReferralLinkStart,
  getReferralLinkEnd,
  setPendingGetReferralLinkStatus,
  setPendingComposeDirectReferralStatus,
  composeDirectReferralStart,
  composeDirectReferralEnd,
};
