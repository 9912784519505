// @flow
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Slider,
  TextareaAutosize,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { Title } from '../../basic/Title';
import type { TimeSpendingDetails as TimeSpendingDetailsType } from '../JobsContext';
import type { Editable } from '../types';
import { UnderCover, UnderCoverContainer } from '../../basic/UnderCover';
import { EditControls } from '../../basic/EditControls';
import { txtColor, useStyles } from '../utils';
import { Button } from '../../basic/Button';

const PrettoSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.edit.main,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.common.white,
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider);

type OnRemove = (id: $ElementType<TimeSpendingDetailsType, 'id'>) => void;
type OnEdit = (data: TimeSpendingDetailsType) => void;

const Item = ({
  isEdit,
  item,
  onRemove,
  onEdit,
}: {
  item: TimeSpendingDetailsType,
  onRemove?: OnRemove,
  onEdit?: OnEdit,
} & Editable) => {
  const { id, value, content } = item;
  const [editing, setEditing] = useState(false);
  const [val, setVal] = useState(value);
  useEffect(() => setVal(value), [value]);
  const [cont, setCont] = useState(content);
  useEffect(() => setCont(content), [content]);
  const onChangeVal = (e: any, v: number) => {
    setVal(v);
    if (onEdit) onEdit({ ...item, value: v });
  };
  const onChangeCont = (c: string) => {
    setCont(c);
    if (onEdit) onEdit({ ...item, content: c });
  };
  // const theme = useTheme();

  const onStartEditing = () => setEditing(true);

  return (
    <UnderCoverContainer uncover={`TimeSpendingDetailsItem--${id}`}>
      <Box m={2} display="flex" alignItems="center">
        {isEdit ? (
          <Box width={50}>
            <strong>{val}</strong>
            <span>%</span>
          </Box>
        ) : null}
        <Box
          width={160}
          style={{ marginTop: isEdit ? 5 : null }}
          position="relative"
          mr={6}
        >
          {isEdit ? (
            <PrettoSlider
              value={val}
              onChange={onChangeVal}
              aria-labelledby="continuous-slider"
              valueLabelDisplay="auto"
            />
          ) : (
            <Box
              height={8}
              bgcolor="grey.300"
              borderRadius={10}
              overflow="hidden"
              position="relative"
            >
              <Box
                width={`${val}%`}
                borderRadius={10}
                bgcolor="common.black"
                position="absolute"
                top={0}
                left={0}
                height="100%"
              />
            </Box>
          )}
        </Box>
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flex={1}
        >
          {isEdit && editing ? (
            <TimeSpendingDetailsForm
              value={cont}
              onSubmit={({ value: v }: { value: string }) => {
                onChangeCont(v);
                setEditing(false);
              }}
              onCancel={() => setEditing(false)}
              submitButtonLabel="Update"
            />
          ) : (
            <Box mr={1}>{cont}</Box>
          )}
          {isEdit ? (
            <UnderCover uncoveredBy={`TimeSpendingDetailsItem--${id}`}>
              {editing ? null : (
                <Box
                  position="absolute"
                  top="50%"
                  right={2}
                  style={{ transform: `translateY(-50%)` }}
                >
                  <EditControls
                    showMoveIcon={false}
                    onRemove={() => {
                      if (onRemove) onRemove(id);
                    }}
                    onEdit={onStartEditing}
                  />
                </Box>
              )}
            </UnderCover>
          ) : null}
        </Box>
      </Box>
    </UnderCoverContainer>
  );
};

function TimeSpendingDetailsForm({
  value,
  onSubmit,
  onCancel,
  submitButtonLabel,
  clearOnSubmit = true,
}: {
  value?: string,
  onSubmit: ({ value: string }) => void,
  onCancel: () => void,
  submitButtonLabel: string,
  clearOnSubmit?: boolean,
}) {
  const c = useStyles();
  const theme = useTheme();
  const formRef = useRef(null);
  const [val, setVal] = useState(value || '');

  const onChange = (e: any) => setVal(e.target.value);
  const onKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit'));
      e.preventDefault();
    }
  };
  const internalOnSubmit = (e: any) => {
    e.preventDefault();
    if (onSubmit) onSubmit({ value: val });
    if (clearOnSubmit) setVal('');
  };

  return (
    <form style={{ width: '100%' }} onSubmit={internalOnSubmit} ref={formRef}>
      <Box mt={1} mr={1}>
        <Box>
          <TextareaAutosize
            style={{ width: '100%', color: txtColor(theme, true) }}
            className={c.textarea}
            rowsMin={2}
            name="newVal"
            value={val}
            onChange={onChange}
            onKeyDown={onKeyUp}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Button
            type="button"
            variant="text"
            color="default"
            disableElevation
            onClick={onCancel}
            size="small"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="edit"
            disableElevation
            size="small"
          >
            {submitButtonLabel}
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export function TimeSpendingDetails({
  // eslint-disable-next-line react/prop-types
  isEdit,
  items,
  onAdd,
  onRemove,
  onEdit,
}: {
  items: TimeSpendingDetailsType[],
  onAdd?: ({
    content: $ElementType<TimeSpendingDetailsType, 'content'>,
  }) => void,
  onRemove?: OnRemove,
  onEdit?: OnEdit,
} & Editable) {
  const theme = useTheme();
  const [isAdding, setIsAdding] = useState(false);
  const internalOnAdd = ({ value }: { value: string }) => {
    if (onAdd) onAdd({ content: value });
  };
  const onCancel = () => setIsAdding(false);

  return (
    <Box>
      <Title variant={Title.variants.t4}>How will you spend your time</Title>
      <Box
        bgcolor={theme.palette.grey[100]}
        borderRadius={theme.shape.borderRadius}
        py={1}
      >
        <Box m={2}>
          {!items.length ? (
            <Box mb={2} color="grey.500">
              List the 3-5 main activities for this position.
            </Box>
          ) : null}
          {items.map((item) => {
            return (
              <Item
                key={item.id}
                isEdit={isEdit}
                item={item}
                onRemove={onRemove}
                onEdit={onEdit}
              />
            );
          })}
          {isAdding ? (
            <TimeSpendingDetailsForm
              onSubmit={internalOnAdd}
              submitButtonLabel="Add"
              onCancel={onCancel}
            />
          ) : null}
          {isEdit ? (
            <Button
              variant="contained"
              color="edit"
              type="button"
              disableElevation
              onClick={() => setIsAdding(true)}
            >
              Add
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
