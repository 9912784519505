// @flow
import * as React from 'react';
import { DisableScrollOnMount } from '../components/basic/DisableScrollOnMount';
import { HideTopArrow } from '../components/wordpress/HideTopArrow';
import { DirectApplicationFormContainer } from '../components/applications/components/DirectApplicationFormContainer';
import { PageHead } from '../components/basic/PageHead';

function DirectApplication() {
  return (
    <DisableScrollOnMount>
      <HideTopArrow>
        <PageHead title="Job application" />
        <DirectApplicationFormContainer />
      </HideTopArrow>
    </DisableScrollOnMount>
  );
}

export { DirectApplication };
