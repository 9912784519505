// @flow
import React, {
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useStateContainer } from '../../core/context/StateContainer';
import { useAuth } from '../auth/talents/AuthContext';
import type { Node } from 'react';
import { uploadCvService } from '../onboarding-talents/uploadCvService';
import { directApplication } from './directApplicationService';
import { useHistory } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { paths } from '../../core/constants';

type Errors = {
  motivation: string | null,
  availability: string | null,
  cv: string | null,
};

export const availabilityOptions = {
  immediately: 'Immediately available',
  '1_week': '1 week',
  '2_weeks': '2 weeks',
  '1_month': '1 month',
  '2_months': '2 months',
  '3_months': '3 months',
};

export type SubmitApplicationData = {
  jobAdId?: number,
  motivation: string,
  availability: $Keys<typeof availabilityOptions>,
  cv: string | null,
  cvFilename: string | null,
};

type Ctx = {
  startApplication: (id: number, title: string, seniority: string) => void,
  cancelApply: () => void,
  submitApplication: () => void,
  uploadCv: (file: File) => void,
  isUploadingCv: boolean,

  showSuccess: () => void,
  hideSuccess: () => void,

  errors: Errors,
  formValues: SubmitApplicationData,
  setFormValues: (
    (formValues: SubmitApplicationData) => SubmitApplicationData
  ) => void,

  jobDetails: {
    id: number,
    title: string,
    seniority: string,
  } | null,
  isApplyPopupVisible: boolean,
  isApplySuccessPopupVisible: boolean,
  isApplicationInProgress: boolean,
};

const ApplicationsContext = createContext<Ctx | null>(null);

const ACTIONS = {
  SHOW_APPLY_POPUP: 'showApplyPopup',
  HIDE_APPLY_POPUP: 'hideApplyPopup',
  SHOW_APPLY_SUCCESS_POPUP: 'showApplySuccessPopup',
  HIDE_APPLY_SUCCESS_POPUP: 'hideApplySuccessPopup',
  SET_IS_APPLICATION_IN_AUTH_PROGRESS: 'setApplicationInProgress',
  START_APPLICATION: 'startApplication',
  SUBMIT_APPLICATION: 'submitApplication',
  FINISH_SUBMIT_APPLICATION: 'finishSubmitApplication',
  SET_IS_UPLOADING_CV: 'setIsUploadingCv',
};

const initialState = {
  jobDetails: null,
  isApplyPopupVisible: false,
  isApplySuccessPopupVisible: false,
  isApplicationInAuthProgress: false,
  isApplicationInProgress: false,
  isUploadingCv: false,
};

const initialFormValues = {
  motivation: '',
  availability: 'immediately',
  cv: null,
  cvFilename: null,
};

const initialErrors = {
  motivation: null,
  availability: null,
  cv: null,
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_IS_UPLOADING_CV:
      return {
        ...state,
        isUploadingCv: action.payload,
      };
    case ACTIONS.SET_IS_APPLICATION_IN_AUTH_PROGRESS:
      return {
        ...state,
        isApplicationInAuthProgress: action.payload,
      };
    case ACTIONS.SHOW_APPLY_POPUP:
      return {
        ...state,
        isApplyPopupVisible: true,
      };
    case ACTIONS.HIDE_APPLY_POPUP:
      return {
        ...state,
        isApplyPopupVisible: false,
      };
    case ACTIONS.SHOW_APPLY_SUCCESS_POPUP:
      return {
        ...state,
        isApplyPopupVisible: false,
        isApplySuccessPopupVisible: true,
      };
    case ACTIONS.HIDE_APPLY_SUCCESS_POPUP:
      return {
        ...state,
        isApplySuccessPopupVisible: false,
      };
    case ACTIONS.START_APPLICATION:
      return {
        ...state,
        jobDetails: {
          id: action.payload.id,
          title: action.payload.title,
          seniority: action.payload.seniority,
        },
      };
    case ACTIONS.SUBMIT_APPLICATION:
      return {
        ...state,
        isApplicationInProgress: true,
      };
    case ACTIONS.FINISH_SUBMIT_APPLICATION:
      return {
        ...state,
        jobDetails: initialState.jobDetails,
        isApplicationInProgress: false,
      };
    default:
      throw new Error('Invalid AuthContext action dispatched');
  }
}

export function ApplicationsProvider({ children }: { children: Node }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const {
    showAuthPopup,
    isLoginPopupVisible,
    isRegisterPopupVisible,
    loadUser,
  } = useAuth();
  const [
    {
      auth: { user, authenticated },
    },
  ] = useStateContainer();
  const [errors, setErrors] = useState<Errors>(initialErrors);
  const [formValues, setFormValues] =
    useState<SubmitApplicationData>(initialFormValues);

  useEffect(() => {
    if (user && user.resumeUuid && user.resumeFilename) {
      setFormValues((prev) => ({
        ...prev,
        cv: user.resumeUuid,
        cvFilename: user.resumeFilename,
      }));
    }
  }, [user]);

  const showApplyPopup = useCallback(() => {
    if (authenticated && user) {
      dispatch({ type: ACTIONS.SHOW_APPLY_POPUP });
      dispatch({
        type: ACTIONS.SET_IS_APPLICATION_IN_AUTH_PROGRESS,
        payload: false,
      });
    }
  }, [authenticated, user]);

  const goThroughFullOnboardingApplication = (seniority?: string) =>
    seniority === 'intern' || seniority === 'junior';

  const redirectToApplicationWithOnboarding = useCallback(
    (id: number, title: string) => {
      history.push(
        `${paths.directApplication}?jark=${id}&jat=${Base64.encode(title)}`
      );
    },
    [history]
  );

  const startApplication = (id: number, title: string, seniority: string) => {
    dispatch({
      type: ACTIONS.START_APPLICATION,
      payload: { id, title, seniority },
    });

    if (!authenticated && !user) {
      showAuthPopup('login');

      dispatch({
        type: ACTIONS.SET_IS_APPLICATION_IN_AUTH_PROGRESS,
        payload: true,
      });
    } else {
      if (goThroughFullOnboardingApplication(seniority)) {
        return redirectToApplicationWithOnboarding(id, title);
      }

      showApplyPopup();
    }
  };

  useEffect(() => {
    // as soon as we have an authenticated user and closed auth modal,
    // the user may freely apply for the job ad.
    // if there's no user and no auth popup,
    // terminate the application auth process

    if (
      user &&
      authenticated &&
      !isLoginPopupVisible &&
      !isRegisterPopupVisible &&
      state.isApplicationInAuthProgress
    ) {
      if (goThroughFullOnboardingApplication(state.jobDetails?.seniority)) {
        const title = state.jobDetails?.title;
        const id = state.jobDetails?.id;

        if (!title || !id) return;

        redirectToApplicationWithOnboarding(id, title);
      } else showApplyPopup();
    }

    if (
      !user &&
      !authenticated &&
      !isLoginPopupVisible &&
      !isRegisterPopupVisible &&
      state.isApplicationInAuthProgress
    ) {
      dispatch({
        type: ACTIONS.SET_IS_APPLICATION_IN_AUTH_PROGRESS,
        payload: false,
      });
    }
  }, [
    user,
    authenticated,
    isLoginPopupVisible,
    isRegisterPopupVisible,
    showApplyPopup,
    state.isApplicationInAuthProgress,
    redirectToApplicationWithOnboarding,
    state.jobDetails,
  ]);

  const cancelApply = () => {
    dispatch({ type: ACTIONS.HIDE_APPLY_POPUP });
    setFormValues({
      ...initialFormValues,
      cv: formValues.cv,
      cvFilename: formValues.cvFilename,
    });
    setErrors(initialErrors);
  };

  const validate: () => Errors = () => {
    const errs: Errors = {};

    if (formValues.motivation.length < 100) {
      errs.motivation = 'Too short (100 symbols minimum).';
    }
    if (!formValues.availability) {
      errs.availability = 'Invalid availability.';
    }
    if (!formValues.cv) {
      errs.cv = 'You cannot apply to this job ad without any CV.';
    }

    return errs;
  };

  const uploadCv = (file: File) => {
    if (!file) return;

    setErrors((prev) => ({
      ...prev,
      cv: null,
    }));

    if (file.type !== 'application/pdf') {
      return setErrors((prev) => ({
        ...prev,
        cv: 'Invalid file format. Allowed formats: PDF.',
      }));
    }

    dispatch({ type: ACTIONS.SET_IS_UPLOADING_CV, payload: true });

    uploadCvService(file)
      .then(() => {
        dispatch({ type: ACTIONS.SET_IS_UPLOADING_CV, payload: false });
        // setFormValues((prev) => ({
        //   ...prev,
        //   cv: response.data,
        //   cvFilename: null,
        // }));
        loadUser(); // the cv is in the user object.
      })
      .catch((err) => {
        // @Todo: handler errors
        throw err;
      });
  };

  const showSuccess = () => {
    dispatch({ type: ACTIONS.SHOW_APPLY_SUCCESS_POPUP });
  };
  const hideSuccess = () => {
    dispatch({ type: ACTIONS.HIDE_APPLY_SUCCESS_POPUP });
  };

  const submitApplication = () => {
    const errs = validate();
    if (Object.values(errs).some((error) => error !== null)) {
      setErrors(errs);
      return;
    }

    if (!state.jobDetails) {
      // eslint-disable-next-line
      console.warn(
        '[ApplicationContext] Could not proceed with the application - unknown job details.'
      );
      return;
    }

    dispatch({ type: ACTIONS.SUBMIT_APPLICATION });

    directApplication({
      data: {
        jobId: state.jobDetails?.id,
        motivation: formValues.motivation,
        availability: formValues.availability,
        resumeUuid: formValues.cv,
      },
    })
      .then((response) => {
        if (response.status === 204) {
          cancelApply();
          showSuccess();
        }
        dispatch({ type: ACTIONS.FINISH_SUBMIT_APPLICATION });
      })
      .catch((err) => {
        // @Todo: handle errors
        dispatch({ type: ACTIONS.FINISH_SUBMIT_APPLICATION });
        throw err;
      });
  };

  const value = {
    jobDetails: state.jobDetails,
    startApplication,
    cancelApply,
    submitApplication,
    uploadCv,
    isUploadingCv: state.isUploadingCv,

    showSuccess,
    hideSuccess,

    errors,
    formValues,
    setFormValues,

    isApplyPopupVisible: state.isApplyPopupVisible,
    isApplySuccessPopupVisible: state.isApplySuccessPopupVisible,
    isApplicationInProgress: state.isApplicationInProgress,
  };

  return (
    <ApplicationsContext.Provider value={value}>
      {children}
    </ApplicationsContext.Provider>
  );
}

export function useApply() {
  const ctx = useContext(ApplicationsContext);
  if (!ctx) throw new Error('Improper useage of ApplicationContext');
  return ctx;
}
