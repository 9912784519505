// @flow
import type { State, Action } from '../../../core/types';
import { LOAD, SET_VALUE } from './actions';

const visitorTrails = (state: State, action: Action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case LOAD:
      return {
        loaded: true,
        trails: action.data,
      };

    case SET_VALUE:
      return {
        ...state,
        trails: {
          ...state.trails,
          [action.data.key]: action.data.value,
        },
      };

    default:
      return state;
  }
};

visitorTrails.initialState = {
  loaded: false,
  trails: {},
};

export { visitorTrails };
