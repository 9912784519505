// @flow
import React, { useState } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { Button } from '../../../basic/Button';
import { Modal } from '../../../basic/Modal';
import yellow from '../../../../assets/images/nha2/yellow.png';
import hint from '../../../../assets/images/nha2/hint.png';

export function Wizard({ onStart }: { onStart: () => void }) {
  const theme = useTheme();
  const [step, setStep] = useState(0);
  const prev = () => setStep((p) => p - 1);
  const next = () => setStep((p) => p + 1);

  return (
    <Modal
      open
      titleId="companyWizardTitle"
      contentId="companyWizardContent"
      withCloseButton={false}
    >
      <Box style={{ maxWidth: '64ch' }} textAlign="center">
        {step === 0 ? (
          <Box>
            <Box
              fontSize={theme.typography.pxToRem(36)}
              lineHeight={1.2}
              fontWeight={theme.typography.fontWeightBold}
              mb={2}
            >
              Let&apos;s create your shiny new company profile
            </Box>
            <Box>
              The company profiles are the essence of the Noble Hire&apos;s
              format. It provides the most valuable information to the
              candidates in a fresh and modern layout, carefully designed by us.
              All you have to do is fill in the gaps.
            </Box>
          </Box>
        ) : null}

        {step === 1 ? (
          <Box>
            <img
              src={yellow}
              alt="Yellow means edit"
              style={{
                width: '100%',
                marginBottom: theme.spacing(4),
              }}
            />
            <Box
              fontSize={theme.typography.pxToRem(36)}
              lineHeight={1.2}
              fontWeight={theme.typography.fontWeightBold}
              mb={2}
            >
              Yellow means edit.
            </Box>
            <Box>
              We made sure that our profile editor is as easy as possible to
              use. Hover over the elements in the profile edit page and use the
              yellow controls to edit/move/delete whatever you wish to build the
              perfect profile for your company.
            </Box>
          </Box>
        ) : null}

        {step === 2 ? (
          <Box>
            <img
              src={hint}
              alt="Hints"
              style={{
                marginBottom: theme.spacing(4),
              }}
            />
            <Box
              fontSize={theme.typography.pxToRem(36)}
              lineHeight={1.2}
              fontWeight={theme.typography.fontWeightBold}
              mb={2}
            >
              Hints are here to help you.
            </Box>
            <Box>
              We have put useful hints throughout the edit profile editor. Hover
              over them to get some extra tips on how to make your profile
              stunning.
            </Box>
          </Box>
        ) : null}

        {step === 3 ? (
          <Box>
            <Box
              fontSize={theme.typography.pxToRem(36)}
              lineHeight={1.2}
              fontWeight={theme.typography.fontWeightBold}
              mb={2}
            >
              Turn the &quot;Public&quot; toggle on!
            </Box>
            <Box>
              Once you&apos;re done with your profile, you can preview it and
              save it as a draft. If you want to make your profile public,
              though, you have to explicitly turn the &quot;Public&quot; toggle
              on in the upper-left corner of the control panel.
            </Box>
            <Box>That&apos;s it. Now it&apos;s your turn!</Box>
          </Box>
        ) : null}
        {/* indicator */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          my={4}
        >
          <WizardDot active={step === 0} />
          <WizardDot active={step === 1} />
          <WizardDot active={step === 2} />
          <WizardDot active={step === 3} />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
        >
          {step > 0 ? (
            <Box mr={2}>
              <Button
                type="button"
                color="default"
                variant="contained"
                disableElevation
                onClick={prev}
              >
                Back
              </Button>
            </Box>
          ) : null}
          <Button
            type="button"
            color="primary"
            variant="contained"
            disableElevation
            onClick={step === 3 ? onStart : next}
          >
            {step === 3 ? 'Start' : 'Next'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

function WizardDot({ active = false }: { active?: boolean }) {
  const theme = useTheme();
  return (
    <Box
      width="8px"
      height="8px"
      borderRadius="50%"
      mx={1}
      bgcolor={active ? theme.palette.grey[800] : theme.palette.grey[500]}
    />
  );
}
