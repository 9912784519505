/* eslint-disable no-console */
// @flow
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useJobs } from '../../components/jobs/JobsContext';
import { paths } from '../../core/constants';

export function SeoJobsPage() {
  const { index } = useParams();
  const { load, jobs } = useJobs();

  console.log('in seojobspage', { jobs });

  useEffect(() => {
    const page = Number.isNaN(Number(index)) ? undefined : Number(index);
    load('', page);
  }, [index, load]);

  return jobs && jobs.length
    ? jobs.map<any>((job) => {
        const companySlug = (job.company && job.company.slug) || '';
        const jobSlug = job.slug || '';
        const postedDate = new Date(job.postedAt).toISOString().slice(0, 10);
        return (
          <React.Fragment key={`${companySlug}/${jobSlug}`}>
            <a
              key={job.title}
              href={paths.job.replace(':slug+', `${companySlug}/${jobSlug}`)}
            >
              {`${postedDate}: ${companySlug}/${jobSlug}`}
            </a>
            <br />
          </React.Fragment>
        );
      })
    : null;
}
