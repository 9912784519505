// @flow
import { useStateContainer } from '../../../../core/context/StateContainer';
import { useReferrals } from '../../ReferralsContext';

function useReferralLinkVisit() {
  const { storeReferralLink, flushStoredReferralLinks } = useReferrals();
  const query = window.location.search;
  const link = window.location.href;
  const [
    {
      auth: { authenticated },
    },
  ] = useStateContainer();

  function handleReferralLinkVisit() {
    if (!query || !query.includes('from=')) {
      return; // not comming from a ref link, bail out
    }

    if (authenticated) {
      storeReferralLink({ link });
      flushStoredReferralLinks();
    } else {
      storeReferralLink({ link });
      // sending the stored links (flushing) is handled on create account / login.
    }
  }

  return { handleReferralLinkVisit };
}

export { useReferralLinkVisit };
