// @flow
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem } from '../../MenuItem';
import { Profile } from '../../Profile';
import { paths } from '../../../../../core/constants';
import { useStateContainer } from '../../../../../core/context/StateContainer';
import { TopMenuContext } from '../../../context/TopMenu';
import { logOut } from '../../../../auth/talents/actions';
import { DeleteAccount } from '../../../../modals/components/DeleteAccount';
import { enqueueModal } from '../../../../modals/actions';
import { useMyCompany } from '../../../../companies/common/context/MyCompanyContext';
import { Loader } from '../../../../basic/Loader';
import { useAuth } from '../../../../auth/talents/AuthContext';

type Props = {
  classes: any,
};

const { useContext } = React;

function UserControlsBox({ classes }: Props) {
  const [
    {
      auth: { authenticated, user, isCompany },
    },
    dispatch,
  ] = useStateContainer();
  const company = useMyCompany();
  const { showAuthPopup } = useAuth();

  const history = useHistory();
  const { close } = useContext(TopMenuContext);

  const dashboard = () => {
    history.push({
      pathname: paths.companiesDashboard.replace(':slug', company.slug),
      search: '',
    });
    close();
  };

  const editPreferences = () => {
    if (isCompany(user) && !company.loading)
      history.push({
        pathname: paths.companiesProfile
          .replace(':slug', company.slug)
          .replace('/:jobSlug?', ''),
        search: '?edit=true',
      });
    else history.push(paths.onboarding);
    close();
  };

  const closeAndLogout = () => {
    logOut(dispatch);
    close();
  };

  const onCreateAccountClick = useCallback(() => {
    showAuthPopup('register');
    close();
  }, [showAuthPopup, close]);

  const onLoginClick = useCallback(() => {
    showAuthPopup('login');
    close();
  }, [showAuthPopup, close]);

  const closeAndDeleteAccount = useCallback(() => {
    dispatch(enqueueModal(<DeleteAccount />));
    close();
  }, [dispatch, close]);

  const changePassword = useCallback(() => {
    logOut(dispatch, { forceReload: false });
    history.replace(paths.forgottenPassword);
  }, [dispatch, history]);

  function Authenticated() {
    return (
      <div className={classes.topSection}>
        <div>
          <Profile name={user ? user.username : null} />
          {isCompany(user) ? (
            <>
              <MenuItem
                internal
                onClick={dashboard}
                className={classes.menuItem}
              >
                {company.loading ? (
                  <Loader svgParams={{ width: 15, height: 15 }} />
                ) : (
                  'Dashboard'
                )}
              </MenuItem>
            </>
          ) : null}
          <MenuItem
            internal
            onClick={editPreferences}
            className={classes.menuItem}
          >
            {isCompany(user) && company.loading ? (
              <Loader svgParams={{ width: 15, height: 15 }} />
            ) : (
              'Edit profile'
            )}
          </MenuItem>
          <MenuItem
            internal
            onClick={changePassword}
            className={classes.menuItem}
          >
            Change password
          </MenuItem>
          <MenuItem
            internal
            onClick={closeAndLogout}
            className={classes.menuItem}
          >
            Log Out
          </MenuItem>
          <MenuItem
            internal
            onClick={closeAndDeleteAccount}
            className={classes.menuItem}
          >
            Delete account
          </MenuItem>
        </div>
      </div>
    );
  }

  function Unauthenticated() {
    return (
      <div className={classes.topSection}>
        <div>
          <MenuItem
            internal
            onClick={onCreateAccountClick}
            className={classes.menuItem}
          >
            Create Account
          </MenuItem>
          <MenuItem
            internal
            onClick={onLoginClick}
            className={classes.menuItem}
          >
            Login
          </MenuItem>
        </div>
      </div>
    );
  }

  return authenticated ? <Authenticated /> : <Unauthenticated />;
}

export { UserControlsBox };
