// @flow
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { buildValidateFunc } from '../../../../../utils/buildValidateFunc';
import type { ValidateFunc } from '../../../../../core/types';
import { validateConfig } from './validateConfig';
import { register, loginAttempts } from '../../../authService';
import { Error } from '../../../../modals/components/Error';
import { useStateContainer } from '../../../../../core/context/StateContainer';
import { enqueueModal } from '../../../../modals/actions';
import { useTracking } from '../../../../tracking/TrackingContext';
import { useReferrals } from '../../../../referrals/ReferralsContext';
import {
  queryHintResolver,
  QUERY_HINT_RESOLUTION,
} from '../../../../../utils/urlHintResolver';
import { useReferralsBox } from '../../../../menu/components/SideMenu/boxes/ReferralsBox/userReferralsBox';
import { useVisitorTrails } from '../../../../tracking/visitor-trails/VisitorTrailsContext';

function useRegistrationForm() {
  const query = useLocation().search;
  const [
    {
      referrals: { pending },
    },
    dispatch,
  ] = useStateContainer();
  const { track, events } = useTracking();
  const { visitorTrails, getTrail } = useVisitorTrails();
  const { getStoredReferralLinks, clearStoredReferralLinks } = useReferrals();
  const { onGetReferralLinkClick: getReferralLinkRequest } = useReferralsBox();
  const validate: ValidateFunc = buildValidateFunc(
    validateConfig,
    (values, config) => config,
    0
  );
  const trackAlreadyHaveAccountClick = () =>
    track(events.ALREADY_HAVE_ACCOUNT_CLICK);

  function handleLoginResponse(response, resolve) {
    const queryResolution = queryHintResolver(query);
    let isBlank = true;

    if (queryResolution === QUERY_HINT_RESOLUTION.DIRECT_APPLICATION) {
      isBlank = false;
    }

    if (pending.getReferralLink) {
      getReferralLinkRequest();
    }

    track(events.CREATE_ACCOUNT, { isBlank });
    track(events.AUTO_LOGIN);
    resolve();
  }

  function handleError(error, reject) {
    const errorMessage =
      error.response && error.response.data && error.response.data.message;

    if (errorMessage) {
      dispatch(enqueueModal(<Error>{errorMessage}</Error>));
    }

    reject();
    // TODO: Log the error in a centralized log (sentry, rollbar etc)
  }

  function onFormSubmit(values: {
    name: string,
    email: string,
    password: string,
    consentGdpr: string[],
  }) {
    const storedReferralLinks = getStoredReferralLinks();
    const { name, email, password, consentGdpr } = values;
    const consent = consentGdpr.includes('consentGdpr');

    return new Promise<mixed>((resolve, reject) => {
      register({
        name,
        email,
        password,
        referralLinks: storedReferralLinks,
        landingPage: getTrail(visitorTrails.VISITOR_FIRST_LANDING_PAGE, null),
        referringPage: getTrail(visitorTrails.VISITOR_COMING_FROM, null),
        consent,
      })
        .then((registerResponse) => {
          if (registerResponse.status === 204) {
            loginAttempts({
              email,
              password,
              maxAttempts: 20,
            })
              .then((r) => handleLoginResponse(r, resolve))
              .catch((error) => {
                reject(error);
              })
              .finally(clearStoredReferralLinks);
          }
        })
        .catch((error) => handleError(error, reject));
    });
  }

  return {
    onFormSubmit,
    trackAlreadyHaveAccountClick,
    validate,
  };
}

export { useRegistrationForm };
