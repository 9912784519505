// @flow
import { upcase } from '../../../utils/upcase';
import { obj } from '../../../utils/obj';

type Data = {
  [key: string]: { [key: string]: number },
};

function buildCultureInitialValues(data: Data = {}) {
  const cultureData = data || {};

  return obj.reduceKeys<$Keys<typeof cultureData>, any, Object>(
    cultureData,
    (acc, cultureSubset) => {
      const buildKey = `culture${upcase(cultureSubset)}Rating`;
      acc[buildKey] = { ...cultureData[cultureSubset] };
      return acc;
    },
    {}
  );
}

function buildSkillsInitialValues(
  softSkills: { [key: string]: any },
  techSkills: { [key: string]: any }
) {
  function builder(sliderName, data) {
    const { selected } = data || { selected: [] };
    const sliderRatingKeyName = `${sliderName}Rating`;

    return selected.reduce(
      (acc, name) => {
        acc[sliderName] = [...acc[sliderName], name];
        acc[sliderRatingKeyName] = {
          ...acc[sliderRatingKeyName],
          [name]: data[name],
        };
        return acc;
      },
      { [sliderName]: [], [sliderRatingKeyName]: {} }
    );
  }

  return {
    ...builder('softSkills', softSkills),
    ...builder('technicalSkills', techSkills),
  };
}

export { buildCultureInitialValues, buildSkillsInitialValues };
