// @flow
import React, { useState } from 'react';
import { useTheme, Box } from '@material-ui/core';
import type { Editable } from '../types';
import type { HiringProcess as HiringProcessType } from '../JobsContext';
import { UnderCover, UnderCoverContainer } from '../../basic/UnderCover';
import { EditControls } from '../../basic/EditControls';
import { HiringProcessForm } from './HiringProcessForm';
import { Button } from '../../basic/Button';
import { useStyles } from '../utils';

export function HiringProcessList({
  isEdit,
  items,
  onItemAdd,
  onItemRemove,
  onItemEdit,
}: {
  items: Array<HiringProcessType>,
  onItemAdd?: (item: HiringProcessType) => void,
  onItemRemove?: (item: HiringProcessType) => void,
  onItemEdit?: (item: HiringProcessType) => void,
} & Editable) {
  const c = useStyles();
  const theme = useTheme();
  const [adding, setAdding] = useState(false);
  const [editingItem, setEditingItem] = useState<HiringProcessType | null>(
    null
  );

  const update = (item: HiringProcessType) => {
    if (onItemEdit) onItemEdit(item);
    setEditingItem(null);
  };

  const onAdd = (value: string) => {
    if (typeof onItemAdd === 'function')
      onItemAdd({ id: Math.random(), value });
  };

  return (
    <Box
      padding={2}
      bgcolor={theme.palette.grey[100]}
      borderRadius={theme.shape.borderRadius}
    >
      {items.length === 0 ? (
        <Box>List all the steps of the hiring process for this position</Box>
      ) : null}
      {(items: any).map((item, i) => (
        <UnderCoverContainer key={item.id} uncover={`HiringProcessList--${i}`}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            position="relative"
            className={isEdit ? c.listItemDarkHover : undefined}
            style={{
              padding: isEdit ? '10px 5px' : 5,
              borderRadius: theme.borderRadius,
              background:
                editingItem && item.id === editingItem.id
                  ? theme.palette.grey[200]
                  : undefined,
            }}
          >
            <Box
              key={item.id}
              display="flex"
              alignItems="flex-start"
              width="100%"
            >
              <Box mr={1} width={20} textAlign="center" display="inline-block">
                <strong>{i + 1}</strong>
                <span>. </span>
              </Box>
              {editingItem && editingItem.id === item.id ? (
                <HiringProcessForm
                  style={{ flex: 1 }}
                  value={editingItem.value}
                  submitButtonLabel="Update"
                  onCancel={() => setEditingItem(null)}
                  onSubmit={(value: string) => update({ ...item, value })}
                />
              ) : (
                <span style={{ flex: 1, display: 'inline-block' }}>
                  {item.value}
                </span>
              )}
            </Box>
            <UnderCover uncoveredBy={`HiringProcessList--${i}`}>
              <Box
                color={theme.palette.common.black}
                position="absolute"
                top="50%"
                right={10}
                style={{ transform: 'translateY(-50%)' }}
              >
                {isEdit && (!editingItem || editingItem.id !== item.id) ? (
                  <EditControls
                    showMoveIcon={false}
                    onRemove={() => onItemRemove && onItemRemove(item)}
                    onEdit={() => setEditingItem(item)}
                    size={[30, 30]}
                  />
                ) : null}
              </Box>
            </UnderCover>
          </Box>
        </UnderCoverContainer>
      ))}
      {isEdit ? (
        <Box mt={2}>
          {adding ? (
            <HiringProcessForm
              onCancel={() => setAdding(false)}
              onSubmit={onAdd}
            />
          ) : (
            <Button
              type="button"
              variant="contained"
              color="edit"
              disableElevation
              onClick={() => setAdding(true)}
            >
              Add
            </Button>
          )}
        </Box>
      ) : null}
    </Box>
  );
}
