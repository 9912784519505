// @flow
import React, { useCallback } from 'react';
import type { Node } from 'react';
import { pipe } from 'ramda';
import { Consent } from '../../components/modals/components/Consent';
import { enqueueModal, dequeueModal } from '../../components/modals/actions';
import { useStateContainer } from './StateContainer';

// TODO
type Ctx = {
  consentActive: boolean,
  showConsent: (
    | {
        onClose?: (...any) => void,
        onAccept?: (...any) => void,
        onConsentAlreadyGiven?: (...any) => void,
        onNotLogged?: (...any) => void,
      }
    | typeof undefined
  ) => void,
};

type Props = {
  children: Node,
};

const { useEffect, createContext, useContext, useState } = React;

const ConsentDialogControllerContext = createContext<Ctx>({});

function ConsentDialogControllerProvider({ children }: Props) {
  const [consentActive, setConsentActive] = useState(false);
  const [
    {
      auth: { user },
    },
    dispatch,
  ] = useStateContainer();

  useEffect(() => {
    if (!consentActive) {
      dispatch(dequeueModal());
    }
  }, [consentActive, dispatch]);

  function open() {
    setConsentActive(true);
  }

  function close() {
    setConsentActive(false);
  }

  const showConsent = useCallback(
    (
      {
        onClose = () => {},
        onAccept = () => {},
        onConsentAlreadyGiven = () => {},
        onNotLogged = () => {},
      } = {
        onClose: () => {},
        onAccept: () => {},
        onConsentAlreadyGiven: () => {},
        onNotLogged: () => {},
      }
    ) => {
      if (!user) {
        onNotLogged();
        return;
      }

      if (user && user.consent) {
        onConsentAlreadyGiven();
        return;
      }

      if (!user.consent && !consentActive) {
        open();
        dispatch(
          enqueueModal(
            <Consent
              onClose={pipe(onClose, close)}
              onAccept={pipe(onAccept, close)}
            />
          )
        );
      }
    },
    [consentActive, dispatch, user]
  );

  const value = {
    consentActive,
    showConsent,
  };

  return (
    <ConsentDialogControllerContext.Provider value={value}>
      {children}
    </ConsentDialogControllerContext.Provider>
  );
}

function useConsentDialogController() {
  return useContext(ConsentDialogControllerContext);
}

export {
  useConsentDialogController,
  ConsentDialogControllerContext,
  ConsentDialogControllerProvider,
};
