// @flow
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useMyCompany } from '../../components/companies/common/context/MyCompanyContext';
import { paths } from '../../core/constants';
import { FullPageLoader } from '../../components/basic/FullPageLoader';

export function RegistrationSuccess() {
  const company = useMyCompany();
  if (company.loading || !company.slug) {
    return (
      <FullPageLoader>Let us prepare your company account...</FullPageLoader>
    );
  }

  return (
    <Redirect
      replace
      to={{
        pathname: paths.companiesProfile
          .replace(':slug', company.slug)
          .replace('/:jobSlug?', ''),
        search: '?wiz=true&edit=true',
      }}
    />
  );
}
