// @flow
import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

type Props = {
  variant?: 'primary' | 'white',
  containerParams?: any,
  svgParams?: any,
};

function Loader({
  variant = 'primary',
  containerParams = {},
  svgParams = {},
}: Props) {
  const theme = useTheme();
  const variantToColorMap: { [key: 'primary' | 'white']: string } = {
    primary: theme.palette.primary.main,
    white: theme.palette.common.white,
  };

  return (
    <div id="noblehire-loader" {...containerParams}>
      <svg
        version="1.1"
        id="noblehire-loader"
        x="0px"
        y="0px"
        width="40px"
        height="40px"
        viewBox="0 0 50 50"
        style={{ enableBackground: `new 0 0 50 50` }}
        {...svgParams}
      >
        <path
          fill={variantToColorMap[variant]}
          d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  );
}

Loader.defaultProps = {
  variant: 'primary',
  containerParams: {},
  svgParams: {},
};

export { Loader };
