// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';
import { Input } from '../../../basic/form/Input';
import { Row } from '../../../basic/form/Row';
import { SubmitButton } from '../../../basic/form/Buttons';
import { Step } from '../../../basic/form/Step';
import { uploadCvService } from '../../uploadCvService';
import { GreyContainer } from '../GreyContainer';

const useStyles = makeStyles((theme) => ({
  inputControl: {
    width: '100%',
  },
  label: {
    textAlign: 'left',
    color: theme.palette.common.black,
    paddingBottom: theme.spacing(1),
    fontSize: 18,
    whiteSpace: 'nowrap',

    '@media (max-width: 768px)': {
      whiteSpace: 'initial',
    },
  },
  input: {
    width: '100%',
  },
}));

function BasicPersonalInformation() {
  const classes = useStyles();
  const buttons = <SubmitButton>Next</SubmitButton>;

  return (
    <Step
      sectionName="Basic information"
      heading="Basic personal information"
      buttons={buttons}
    >
      <>
        <Row>
          <Input
            controlProps={{ className: classes.inputControl }}
            className={classes.input}
            id="name"
            name="name"
            label="First & last name"
            placeholder="Your full name"
            required
          />
        </Row>

        <Row>
          <Input
            controlProps={{ className: classes.inputControl }}
            className={classes.input}
            id="phone"
            name="phone"
            label="Phone number"
            placeholder="*also add your country code"
            required
          />
          <Input
            controlProps={{ className: classes.inputControl }}
            className={classes.input}
            id="city"
            name="city"
            label="Current residence (city)"
            placeholder="City"
            required
          />
        </Row>

        <div>
          <InputLabel className={classes.label} shrink htmlFor="cv" required>
            Attach CV or provide LinkedIn profile (we need at least one of
            these)
          </InputLabel>
          <GreyContainer>
            <Row noBottomSpace>
              <Input
                controlProps={{ className: classes.inputControl }}
                className={classes.input}
                id="cv"
                name="cv"
                type="file"
                label="Upload your CV"
                onInput={(e) => {
                  const file = e.target.files[0];
                  uploadCvService(file);
                }}
              />
              <Input
                controlProps={{ className: classes.inputControl }}
                className={classes.input}
                id="linkedin"
                name="linkedin"
                label="Or paste your LinkedIn profile"
                placeholder="Public LinkedIn link"
              />
            </Row>
          </GreyContainer>
        </div>
      </>
    </Step>
  );
}

export { BasicPersonalInformation };
