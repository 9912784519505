// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTransition, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TopMenuContext } from '../../context/TopMenu';
import { Content } from './Content';
import { useStateContainer } from '../../../../core/context/StateContainer';
// import { TOP_MENU_HEIGHT } from '../../constants';

const { useContext } = React;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    height: '100%',
    position: 'fixed',
    fontWeight: theme.typography.fontWeightMedium,
    top: 0,
    background: theme.palette.common.white,
    zIndex: 6,
    color: theme.palette.text.primary,
    padding: theme.spacing(3, 5),

    '@media (max-width: 768px)': {
      width: '100%',
      background: theme.palette.common.white,
    },
  },
  topSeparator: {
    display: 'none',
    '@media (max-width: 768px)': {
      display: 'block',
    },
  },
  topSection: () => ({
    display: 'flex',
    flexDirection: 'column',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  }),
  mainSection: {
    display: 'flex',
    flexDirection: 'column',

    '@media (max-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  col1: {},
  col2: {
    '@media (max-width: 768px)': {
      textAlign: 'right',
    },
  },
  menuItem: {
    color: theme.palette.text.primary,

    '&:hover': {
      color: theme.palette.grey.darkest,
    },
  },
  menuItemFaded: {
    '@media (max-width: 768px)': {
      color: theme.palette.grey.darkest,
    },
  },
  close: {
    fontSize: 20,
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(5),
    cursor: 'pointer',
  },
  dim: {
    background: `rgba(0,0,0,0.3)`,
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 5,
    userSelect: 'none',
    outline: 'none',
  },
}));

type AnimateParams = {
  item: any,
  key: any,
  props: any,
};

function SideMenu() {
  const [
    {
      auth: { authenticated },
    },
  ] = useStateContainer();
  const { isOpen, close, activeSideMenuType } = useContext(TopMenuContext);
  const classes = useStyles({ authenticated });

  const dimTransitions = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 150, mass: 1, tension: 50, friction: 20 },
    trail: 150,
  });

  const transitions = useTransition(isOpen, null, {
    from: { right: -350, opacity: 0.5 },
    enter: { right: 0, opacity: 1 },
    leave: { right: -350, opacity: 0 },
    config: { duration: 50 },
  });

  function animateSideMenu({ item, key, props }: AnimateParams) {
    return (
      item && (
        <animated.div key={key} style={props} className={classes.root}>
          <FontAwesomeIcon
            icon={['fal', 'times']}
            className={classes.close}
            onClick={close}
          />

          <Content classes={classes} sideMenuType={activeSideMenuType} />
        </animated.div>
      )
    );
  }

  function animateDim({ item, key, props }: AnimateParams) {
    return (
      item && (
        <animated.div
          key={key}
          style={props}
          role="button"
          className={classes.dim}
          onClick={close}
          onKeyDown={null}
          tabIndex="0"
        />
      )
    );
  }

  return (
    <>
      {transitions.map(animateSideMenu)}
      {dimTransitions.map(animateDim)}
    </>
  );
}

export { SideMenu };
