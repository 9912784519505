// @flow
import { AUTH_TOKEN_KEY, paths } from '../../../core/constants';

const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
const GET_USER_START = 'GET_USER_START';
const GET_USER_END = 'GET_USER_END';
const RESET_USER = 'RESET_USER';
const GET_USER_STATS_START = 'GET_USER_STATS_START';
const GET_USER_STATS_END = 'GET_USER_STATS_END';
const SET_USER_STATS = 'SET_USER_STATS';

function logOut(
  dispatch: (any) => void,
  { forceReload }: { forceReload: boolean } = { forceReload: true }
) {
  dispatch({ type: SET_AUTH_TOKEN, authToken: null });
  dispatch({ type: RESET_USER });
  dispatch({ type: SET_USER_STATS, data: null });
  localStorage.removeItem(AUTH_TOKEN_KEY);
  if (forceReload) window.location.href = paths.root;
}

function getUserStart() {
  return { type: GET_USER_START };
}

function getUserEnd(data: any) {
  return { type: GET_USER_END, data };
}

function getUserStatsStart() {
  return { type: GET_USER_STATS_START };
}

function setUserStats(data: any) {
  return { type: SET_USER_STATS, data };
}

function getUserStatsEnd(data: any) {
  return { type: GET_USER_STATS_END, data };
}

export {
  SET_AUTH_TOKEN,
  GET_USER_START,
  GET_USER_END,
  RESET_USER,
  GET_USER_STATS_START,
  GET_USER_STATS_END,
  SET_USER_STATS,
  logOut,
  getUserStart,
  getUserEnd,
  getUserStatsStart,
  getUserStatsEnd,
  setUserStats,
};
