import us from 'unique-string';

export function uniqString() {
  return us();
}

export function uniqNum(min = 0, max = 100000) {
  const minmin = Math.ceil(min);
  const maxmax = Math.floor(max);
  return Math.floor(Math.random() * (maxmax - minmin) + minmin);
}
