// @flow
import { useEffect } from 'react';

export function useOnEsc(handler: () => void) {
  useEffect(() => {
    const handle = (e: any) => {
      if (e.key === 'Escape') {
        handler();
      }
    };

    window.addEventListener('keyup', handle);

    return () => window.removeEventListener('keyup', handle);
  }, [handler]);
}
