// @flow
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Flip from 'react-reveal/Flip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../basic/Button';
import { externalPaths } from '../../../core/constants';
import { useTracking } from '../../tracking/TrackingContext';
import { useVisitorTrails } from '../../tracking/visitor-trails/VisitorTrailsContext';

const useStyles = makeStyles((theme) => ({
  root: {
    right: 0,
    bottom: 0,
    maxWidth: 350,
    position: 'fixed',
    margin: theme.spacing(6),
    padding: theme.spacing(3),
    boxShadow: `0 3px 4px 1px rgba(0,0,0,0.15)`,
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    borderRadius: theme.borderRadius,
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  title: {
    margin: 0,
    paddingRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(21),
  },
  close: {
    cursor: 'pointer',
    margin: theme.spacing(0.8, 0, 0.8, 0.8),
    fontSize: theme.typography.pxToRem(21),
  },
  content: {
    margin: theme.spacing(3, 0),
    color: theme.palette.primary.light,
  },
  learnMoreContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

function ReferralCtaPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const { events, track } = useTracking();
  const { getTrail, visitorTrails } = useVisitorTrails();
  const classes = useStyles();

  /* eslint-disable */
  function calcRefScore({
    refPageHits,
    directRefSent,
    copyRefLinkClicks,
  }: {
    refPageHits: number,
    directRefSent: number,
    copyRefLinkClicks: number,
  }) {
    return refPageHits + directRefSent + copyRefLinkClicks;
  }
  /* eslint-enable */

  const open = useCallback(() => {
    if (!localStorage.getItem('rctapopped')) {
      const refPageHits = Number(getTrail(visitorTrails.REF_PAGE_HITS, 0));
      const directRefSent = Number(getTrail(visitorTrails.DIRECT_REF_SENT, 0));
      const copyRefLinkClicks = Number(
        getTrail(visitorTrails.COPY_REF_LINK, 0)
      );

      const refScore = calcRefScore({
        refPageHits,
        directRefSent,
        copyRefLinkClicks,
      });

      // refScore = 0 - we assume that the user hasn't heard of our ref program
      if (refScore === 0) {
        track(events.REFERRAL_CTA_POPUP_OPENED, { refScore });
        setIsOpen(true);
      }
    }
  }, [
    events.REFERRAL_CTA_POPUP_OPENED,
    getTrail,
    track,
    visitorTrails.COPY_REF_LINK,
    visitorTrails.DIRECT_REF_SENT,
    visitorTrails.REF_PAGE_HITS,
  ]);

  const close = useCallback(
    (trackCloseEvent = true) => {
      setIsOpen(false);

      if (trackCloseEvent) {
        track(events.REFERRAL_CTA_POPUP_CLOSED);
      }

      localStorage.setItem('rctapopped', 'true');
    },
    [setIsOpen, track, events.REFERRAL_CTA_POPUP_CLOSED]
  );

  useEffect(() => {
    const wait = 40000;
    const url = window.location.href;
    const isJobsOrCompaniesPage =
      url.includes('/jobs/') || url.includes('/companies/');

    if (!isJobsOrCompaniesPage) {
      return undefined;
    }

    const to = setTimeout(open, wait);
    return () => clearTimeout(to);
  }, [open]);

  return isOpen ? (
    <Flip bottom duration={300} opposite>
      <div className={classes.root}>
        <div className={classes.head}>
          <h2 className={classes.title}>
            Invite a friend and get 3000-8000 BGN
          </h2>
          <FontAwesomeIcon
            icon={['fal', 'times']}
            className={classes.close}
            onClick={close}
          />
        </div>
        <div className={classes.content}>
          When your friend joins Noble Hire, you&apos;ll get a bonus when they
          get hired - be it in a month, a year or three.
        </div>
        <div className={classes.learnMoreContainer}>
          <Button
            variant="contained"
            color="white"
            type="button"
            onClick={() => {
              const trackCloseEvent = false;
              close(trackCloseEvent);
              track(events.REFERRAL_CTA_POPUP_LEARN_MORE_BUTTON_CLICKED);
              window.location.href = externalPaths.referrers;
            }}
          >
            Learn more
          </Button>
        </div>
      </div>
    </Flip>
  ) : null;
}

export { ReferralCtaPopup };
