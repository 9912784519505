// @flow

import React, { useContext } from 'react';
import { useTheme, Box, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import foo from '../assets/images/nha3/footer-trans@2x.png';
import logo from '../assets/images/nha3/logo-footer-Horizontal.svg';
import tag from '../assets/images/nha3/logo-footer-bg.svg';
import { useMQ } from './useMQ';
import { paths, externalPaths } from '../core/constants';
import { TopMenuContext, SideMenuTypes } from './menu/context/TopMenu';
import { useRequestCompanyProfile } from './companies/request-profile/RequestCompanyProfileProvider';

type Item = {
  to?: string,
  href?: string,
  onClick?: () => void,
  text: string,
};

export function Footer() {
  const { open: openMenu } = useContext(TopMenuContext);
  const { openPopup } = useRequestCompanyProfile();
  const theme = useTheme();
  const mq = useMQ();

  const col = (title: string, items: Item[], mediaQuery: any) => {
    return (
      <Box mr={mediaQuery.xl ? 6 : 0} mb={mediaQuery.xl ? 0 : 4}>
        <Box
          fontSize={theme.typography.pxToRem(12)}
          color={theme.palette.grey[500]}
          style={{ textTransform: 'uppercase' }}
          mb={2}
        >
          {title}
        </Box>
        <Box>
          {items.map((i) => {
            if (i.to) {
              return (
                <Link
                  key={i.text}
                  style={{
                    display: 'block',
                    color: theme.palette.common.white,
                    fontSize: theme.typography.pxToRem(14),
                    marginBottom: theme.spacing(2),
                    textDecoration: 'none',
                  }}
                  to={i.to}
                >
                  {i.text}
                </Link>
              );
            }

            if (i.onClick && typeof i.onClick === 'function') {
              return (
                <div
                  onKeyUp={() => {}}
                  tabIndex="0"
                  role="button"
                  onClick={i.onClick}
                  key={i.text}
                  style={{
                    display: 'block',
                    color: theme.palette.common.white,
                    fontSize: theme.typography.pxToRem(14),
                    marginBottom: theme.spacing(2),
                    textDecoration: 'none',
                    outline: 'none',
                    cursor: 'pointer',
                  }}
                >
                  {i.text}
                </div>
              );
            }

            return (
              <a
                href={i.href}
                key={i.text}
                rel="noreferrer noopener"
                target="_blank"
                style={{
                  display: 'block',
                  color: theme.palette.common.white,
                  fontSize: theme.typography.pxToRem(14),
                  marginBottom: theme.spacing(2),
                  textDecoration: 'none',
                  outline: 'none',
                  cursor: 'pointer',
                }}
              >
                {i.text}
              </a>
            );
          })}
        </Box>
      </Box>
    );
  };

  function openReferralsMenu() {
    openMenu(SideMenuTypes.referrals);
  }

  return (
    <Box bgcolor={theme.palette.grey[900]} mt={4}>
      <Container>
        <Box minHeight={600} position="relative">
          <Box position="relative" zIndex={1}>
            <Box pt={8}>
              <Box
                fontSize={theme.typography.pxToRem(36)}
                fontWeight={theme.typography.fontWeightBold}
                color={theme.palette.common.white}
                style={{ maxWidth: '32ch' }}
                textAlign={mq.xl ? 'left' : 'center'}
              >
                Find a job in one of the best tech companies in Bulgaria
              </Box>
            </Box>
            <Box my={12}>
              <Box
                display="flex"
                flexDirection={mq.xl ? 'row' : 'column'}
                justifyContent={mq.xl ? '' : 'center'}
                alignItems={mq.xl ? 'flex-start' : 'center'}
                textAlign={mq.xl ? 'left' : 'center'}
              >
                {col(
                  'Apply',
                  [
                    { to: paths.jobs, text: 'Jobs' },
                    { to: paths.companies, text: 'Companies' },
                    { href: externalPaths.secretNewsletter, text: 'Secret Newsletter' },
                  ],
                  mq
                )}
                {col(
                  'Refer',
                  [
                    { to: paths.referrers, text: 'For Referrers' },
                    {
                      onClick: openReferralsMenu,
                      text: 'Refer via Link',
                    },
                  ],
                  mq
                )}
                {col(
                  'Employers',
                  [
                    { to: paths.businesses, text: 'For Employers' },
                    { onClick: openPopup, text: 'Request Profile' },
                  ],
                  mq
                )}
                {col(
                  'Company',
                  [
                    { to: paths.about, text: 'About' },
                    { to: '/terms-conditions', text: 'Terms & Conditions' },
                    { to: '/privacy-policy', text: 'Privacy Policy' },
                  ],
                  mq
                )}
                {col(
                  'Find us',
                  [
                    {
                      href: 'https://www.facebook.com/noblehire/',
                      text: 'Facebook',
                    },
                    {
                      href: 'https://www.linkedin.com/company/noblehire/',
                      text: 'LinkedIn',
                    },
                    {
                      href: 'https://instagram.com/noblehire',
                      text: 'Instagram',
                    },
                  ],
                  mq
                )}
                {col(
                  'Surveys',
                  [
                    {
                      href: 'https://noblehire.io/job-ad-survey/',
                      text: 'Job Ad Survey',
                    },
                    {
                      href: 'https://noblehire.io/salary-survey-2022/',
                      text: 'Salary Survey 2022',
                    },
                    {
                      href: 'https://noblehire.io/salary-survey-2021/',
                      text: 'Salary Survey 2021',
                    },
                  ],
                  mq
                )}
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={mq.md ? 'space-between' : 'center'}
              pb={4}
              color={theme.palette.grey[500]}
              fontSize={theme.typography.pxToRem(12)}
            >
              <Box mr={mq.md ? 4 : 0} textAlign={mq.md ? 'left' : 'center'}>
                Copyright © {new Date().getFullYear()} Noble Hire. All rights reserved.
              </Box>
              {mq.md ? (
                <Box display="flex" alignItems="center">
                  <img
                    alt="logo"
                    src={logo}
                    draggable={false}
                    style={{
                      marginRight: theme.spacing(2),
                      userDrag: 'none',
                      userSelect: 'none',
                    }}
                  />
                  <img
                    alt="Crafted with love and passion"
                    src={tag}
                    style={{ userDrag: 'none', userSelect: 'none' }}
                  />
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box position="absolute" top={-20} right={0}>
            <img
              alt="shapes background"
              src={foo}
              style={{ maxHeight: 500, maxWidth: '100%' }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
