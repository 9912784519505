// @flow
import * as React from 'react';
import { Field } from 'react-final-form';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

type Props = {
  id: string,
  name: string,
  classes?: ({ error: boolean }) => { [key: string]: any },
  label?: string,
  required?: boolean,
  formControlProps?: { [key: string]: any },
  fullWidth?: boolean,
};

const InputV2 = React.forwardRef<Props, any>(
  (
    {
      id,
      label,
      classes,
      name,
      required,
      formControlProps,
      fullWidth = false,
      ...rest
    }: Props,
    ref: any
  ) => {
    const theme = useTheme();
    return (
      <Field name={name}>
        {({ input, meta }) => {
          const error = meta.touched && (meta.error || meta.submitError);

          return (
            <FormControl
              error={!!error}
              style={{
                width: fullWidth ? '100%' : '',
                marginBottom: theme.spacing(0.5),
              }}
              {...formControlProps}
            >
              <InputLabel htmlFor={id} shrink required={required}>
                {label}
              </InputLabel>

              <InputBase
                ref={ref}
                fullWidth
                id={id}
                {...input}
                {...rest}
                classes={
                  typeof classes === 'function' ? classes({ error }) : undefined
                }
              />

              {error && <FormHelperText key={error}>{error}</FormHelperText>}
            </FormControl>
          );
        }}
      </Field>
    );
  }
);

const stylesObj = (theme) => {
  const baseStyle = {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5, 2),
    fontSize: theme.typography.fontSize,
  };

  return {
    edit: {
      ...baseStyle,
      '&:focus': {
        border: `1px solid ${theme.palette.edit.main}`,
      },
    },
    primary: {
      ...baseStyle,
      '&:focus': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    error: {
      ...baseStyle,
      border: `1px solid ${theme.palette.error.main}`,
      '&:focus': {
        border: `1px solid ${theme.palette.edit.main}`,
      },
    },
  };
};
const useStyles = makeStyles(stylesObj);

function useInputV2Styles() {
  const classes = useStyles();

  return { ...classes };
}

export { InputV2, useInputV2Styles };
