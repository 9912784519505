// @flow
/* eslint-disable flowtype/space-after-type-colon */
import React, { createContext, useState, useContext, useCallback } from 'react';
import type { Node } from 'react';
import { getJobs } from './jobsService';
import {
  seniority,
  jobTypes,
  teamSizes,
  programmingLanguages,
  platformsAndFrameworks,
  databases,
  roles,
  currencies,
} from '../../core/constants';
import type {
  Image,
  ServerImage,
  ServerLocation,
  ServerCompany,
} from '../companies/common/context/CompanyContext';
import type { Company } from '../companies/common/types';
import { convServerJobToJob } from './utils';

export type CoreRequirement = { id: number, value: string };
export type Responsibility = { id: number, value: string };
export type HiringProcess = { id: number, value: string };
export type Perk = { id: number, value: string };
export type TimeSpendingDetails = {
  id: number,
  value: number,
  content: string,
};
export type Tool = { id: number, value: string };

export type ServerJob = {
  company?: ServerCompany,
  id?: number,
  slug?: string,
  title: string,
  location: ServerLocation,
  seniority: $Keys<typeof seniority>,
  primaryLanguage: $Keys<typeof programmingLanguages>,
  primaryPlatform: $Keys<typeof platformsAndFrameworks>,
  secondaryLanguage: $Keys<typeof programmingLanguages>,
  secondaryPlatform: $Keys<typeof platformsAndFrameworks>,
  mainDatabase: $Keys<typeof databases>,
  description: string,
  postedAt: Date,
  public: boolean,
  jobType: $Keys<typeof jobTypes>,
  jobTypeComment: ?string,
  customerFacing: boolean,
  businessTraveling: boolean,
  businessTravelComment: ?string,
  stockOptions: boolean,
  teamLead: string,
  hiringProcessSteps: string[],
  tools: string[],
  activities: { timePercents: number, title: string }[],
  requirements: { icon: ?string, title: string }[],
  responsibilities: { icon: ?string, title: string }[],
  benefits: { icon: ?string, title: string }[],
  role: $Keys<typeof roles>,
  salaryMin: ?number,
  salaryMax: ?number,
  salaryCurrency: $Keys<typeof currencies>,
  salaryPeriod: 'MONTH',
  teamSizeMin: number,
  teamSizeMax: number,
  homeOfficeDays: number,
  homeOfficePer: 'WEEK',
  fullyRemote: boolean,
  teamLeadImage: ServerImage,
  teamLeadName: string,
  teamLeadRole: string,
  teamLeadLink: string,
  productImages: ServerImage[],
  productDescription: string,
};

export type Job<L> = {
  company: Company<any>,
  id?: number,
  isPublic: boolean,
  slug?: string,
  title: string,
  location: L,
  description: string,
  postedAt: Date,
  role: $Keys<typeof roles>,
  seniority: $Keys<typeof seniority>,
  type: $Keys<typeof jobTypes>,
  stockOptions: 'yes' | 'no',
  salary: string,
  salaryCurrency: $Keys<typeof currencies>,
  homeOffice: 'yes' | 'no',
  teamSize: $Keys<typeof teamSizes>,
  fullyRemote: 'yes' | 'no',
  customerFacing: 'yes' | 'no',
  businessTravel: 'yes' | 'no',
  primaryLanguage: $Keys<typeof programmingLanguages> | string | null,
  primaryPlatformOrFramework:
    | $Keys<typeof platformsAndFrameworks>
    | string
    | null,
  secondaryLanguage: $Keys<typeof programmingLanguages> | string | null,
  secondaryPlatformOrFramework:
    | $Keys<typeof platformsAndFrameworks>
    | string
    | null,
  mainDatabase: $Keys<typeof databases> | string | null,
  coreRequirements: CoreRequirement[],
  responsibilities: Responsibility[],
  hiringProcessSteps: HiringProcess[],
  perks: Perk[],
  teamLeadImage: Image | null,
  teamLeadName: string,
  teamLeadRole: string,
  teamLeadLink: string,
  timeSpendingDetails: TimeSpendingDetails[],
  tools: Tool[],
  productImages: Image[],
  productDescription: string,
};

type PaginationData = {
  page: number | null,
  totalElements: number | null,
  totalPages: number | null,
};

export type Ctx = {
  loading: boolean,
  load: (search?: string, page?: number) => Promise<any>,
  loadingMore: boolean,
  loadMore: (search?: string) => Promise<any>,
  jobs: Job<ServerLocation>[],
  paginationData: PaginationData,
};

const Context = createContext<Ctx | null>(null);

export function JobsProvider({ children }: { children: Node }) {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [jobs, setJobs] = useState<Job<any>[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationData>({
    page: null,
    totalElements: null,
    totalPages: null,
  });

  const load = useCallback(async (search?: string, page?: number) => {
    setLoading(true);
    const pageNumber = Number.isNaN(Number(page)) ? undefined : Number(page);
    const response = await getJobs(search, { page: pageNumber });
    const rJobs = response.elements.map((job) => {
      return convServerJobToJob(job);
    });

    setPaginationData((prev) => ({
      ...prev,
      page: response.page,
      totalElements: response.totalElements,
      totalPages: response.totalPages,
    }));
    setLoading(false);
    setJobs(rJobs);
  }, []);

  const loadMore = useCallback(
    async (search?: string) => {
      setLoadingMore(true);

      const response = await getJobs(search, { page: paginationData.page + 1 });
      const rJobs = response.elements.map((job) => {
        return convServerJobToJob(job);
      });

      setPaginationData((prev) => ({
        ...prev,
        page: response.page,
        totalElements: response.totalElements,
        totalPages: response.totalPages,
      }));
      setLoadingMore(false);
      setJobs((prev) => [...prev, ...rJobs]);
    },
    [paginationData]
  );

  const value = { loading, load, loadingMore, loadMore, jobs, paginationData };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useJobs: () => Ctx = () => {
  const ctx = useContext(Context);
  if (ctx === null) throw new Error('Improper use of JobsProvider.');
  return ctx;
};
