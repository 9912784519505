// @flow
import React, { useRef, useEffect, useReducer } from 'react';
import type { Node } from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../../../../core/constants';
import { makeStyles } from '@material-ui/core';
import { useInputV2Styles } from '../../../../basic/form/InputV2';
import {
  Popup,
  PopupTitle,
  PopupContainer,
  PopupMain,
  PopupErrorPanel,
} from '../../../../basic/Popup';
import { Button } from '../../../../basic/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { basicValidators as validators } from '../../../../../core/validators';

type SubmitFormData = {
  email: string,
  password: string,
  repeatPassword?: string,
  consent?: boolean,
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minWidth: 300,
      maxWidth: 300,

      '& input': {
        fontFamily: theme.typography.fontFamily,
      },
    },
    errorContainer: {
      marginTop: theme.spacing(1),
    },
    title: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    input: {
      width: '100%',
      fontFamily: theme.typography.fontFamily,
    },
    label: {
      color: theme.palette.grey[800],
      marginBottom: theme.spacing(0.5),
    },
    labelError: {
      color: theme.palette.error.main,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    formRow: {
      marginBottom: theme.spacing(1),
    },
    formControls: {
      margin: theme.spacing(2, 0),
    },
    error: {
      color: theme.palette.error.main,
    },
    closeButton: {
      fontSize: theme.typography.pxToRem(18),
      border: 0,
      background: 'transparent',
      padding: 0,
      margin: 0,
      cursor: 'pointer',
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    linkBtn: {
      border: 'none',
      background: 'transparent',
      padding: 0,
      margin: 0,
      textDecoration: 'underline',
      color: theme.palette.grey[700],
      cursor: 'pointer',
      textAlign: 'center',
      fontFamily: theme.typography.fontFamily,

      '&:hover': {
        textDecoration: 'none',
      },
    },
    consentContainer: {
      margin: theme.spacing(1.5, 0),
      fontSize: theme.typography.pxToRem(14),
    },
    consent: {
      display: 'flex',
      alignItem: 'center',
    },
    consentInput: {
      marginRight: 15,
    },
    consentContent: {
      flex: 1,
    },
    forgottenPassBtn: {
      marginBottom: theme.spacing(1),
    },
  };
});

type Action = { type: 'change' | 'errors', payload: any };
type State = {
  email: string,
  password: string,
  repeatPassword: string,
  consent: boolean,
  errors: {
    email: string | null,
    password: string | null,
    repeatPassword: string | null,
    consent: string | null,
  },
};

const initialState: State = {
  email: '',
  password: '',
  repeatPassword: '',
  consent: false,
  errors: {
    email: null,
    password: null,
    repeatPassword: null,
    consent: null,
  },
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'change': {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case 'errors': {
      return {
        ...state,
        errors: action.payload.errors,
      };
    }
    default:
      return state;
  }
};

export function AuthPopup({
  isVisible,
  onClose,
  onSubmit,
  error,
  loading = false,
  title = 'Login',
  repeatPassword = false,
  consent = false,
  btnLabel = 'Login',
  footer,
}: {
  consent?: boolean,
  isVisible: boolean,
  onClose: () => void,
  error: string,
  loading?: boolean,
  onSubmit: (data: SubmitFormData) => void,
  title?: string,
  repeatPassword?: boolean,
  btnLabel?: string,
  footer?: Node,
}) {
  const classes = useStyles();
  const inputStyles = useInputV2Styles();
  const emailInputRef = useRef();
  const [state, dispatch] = useReducer(reducer, initialState);
  let disableSubmit = repeatPassword
    ? !(state.email && state.password && state.repeatPassword)
    : !(state.email && state.password);
  disableSubmit = consent ? disableSubmit || !state.consent : disableSubmit;

  useEffect(() => {
    if (error && emailInputRef && emailInputRef.current)
      emailInputRef.current.focus();
  }, [error]);

  const validate = () => {
    const { errors } = state;

    if (!validators.email(state.email)) errors.email = 'Invlaid email';
    else errors.email = null;

    if (!validators.password(state.password))
      errors.password = 'Password is too short';
    else errors.password = null;

    if (repeatPassword) {
      if (state.password !== state.repeatPassword)
        errors.repeatPassword = "Passwords don't match";
      else errors.repeatPassword = null;
    }

    if (consent) {
      if (!state.consent) {
        errors.consent = 'Must be checked in order to proceed.';
      } else {
        errors.consent = null;
      }
    }

    return errors;
  };

  const internalOnSubmit = (e: any) => {
    e.preventDefault();

    const errors = validate();

    dispatch({ type: 'errors', payload: { errors } });
    if (Object.values(errors).some((err: any) => err !== null)) {
      return;
    }

    const data: SubmitFormData = {
      email: state.email,
      password: state.password,
    };
    if (repeatPassword) data.repeatPassword = state.repeatPassword;
    if (consent) data.consent = state.consent;

    onSubmit(data);
  };

  const onChange = (name: string) => (e: any) => {
    const { value } = e.target;
    dispatch({ type: 'change', payload: { name, value } });
  };

  const onConsentChange = () => {
    dispatch({
      type: 'change',
      payload: { name: 'consent', value: !state.consent },
    });
  };

  const internalOnClose = () => {
    onClose();
  };

  const onLinkClick = () => {
    internalOnClose();
  };

  useEffect(() => {
    if (isVisible && emailInputRef && emailInputRef.current)
      emailInputRef.current.focus();
  }, [isVisible, emailInputRef]);

  // eslint-disable-next-line
  const Error = ({ error }: { error: string | null }) => {
    if (!error) return null;
    return <span className={classes.error}>{error}</span>;
  };

  return (
    <Popup isOpen={isVisible} onClose={internalOnClose}>
      <div className={classes.root}>
        <PopupContainer>
          <PopupTitle>
            <div className={classes.title}>
              <span>{title}</span>
              <button
                type="button"
                className={classes.closeButton}
                onClick={internalOnClose}
              >
                <FontAwesomeIcon icon={['fal', 'times']} />
              </button>
            </div>
          </PopupTitle>
          <PopupMain>
            <form onSubmit={internalOnSubmit} className={classes.form}>
              <div className={classes.formRow}>
                <label htmlFor="email">
                  <div
                    className={cn(classes.label, {
                      [classes.labelError]: state.errors.email,
                    })}
                  >
                    Email*
                  </div>
                  <input
                    ref={emailInputRef}
                    type="text"
                    id="email"
                    name="email"
                    placeholder="john@doe.com"
                    value={state.email}
                    onChange={onChange('email')}
                    className={cn(
                      inputStyles.primary,
                      { [inputStyles.error]: state.errors.email },
                      classes.input
                    )}
                    disabled={loading}
                  />
                  <Error error={state.errors.email} />
                </label>
              </div>
              <div className={classes.formRow}>
                <label htmlFor="password">
                  <div
                    className={cn(classes.label, {
                      [classes.labelError]: state.errors.password,
                    })}
                  >
                    Password*
                  </div>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="**********"
                    value={state.password}
                    onChange={onChange('password')}
                    className={cn(
                      inputStyles.primary,
                      { [inputStyles.error]: state.errors.password },
                      classes.input
                    )}
                    disabled={loading}
                  />
                  <Error error={state.errors.password} />
                </label>
              </div>
              {repeatPassword ? (
                <div className={classes.formRow}>
                  <label htmlFor="repeatPassword">
                    <div
                      className={cn(classes.label, {
                        [classes.labelError]: state.errors.repeatPassword,
                      })}
                    >
                      Repeat Password*
                    </div>
                    <input
                      type="password"
                      id="repeatPassword"
                      name="repeatPassword"
                      placeholder="**********"
                      value={state.repeatPassword}
                      onChange={onChange('repeatPassword')}
                      className={cn(
                        inputStyles.primary,
                        { [inputStyles.error]: state.errors.repeatPassword },
                        classes.input
                      )}
                      disabled={loading}
                    />
                    <Error error={state.errors.repeatPassword} />
                  </label>
                </div>
              ) : null}
              {consent ? (
                <div className={classes.consentContainer}>
                  <label htmlFor="consent" className={classes.consent}>
                    <input
                      id="consent"
                      type="checkbox"
                      checked={state.consent}
                      onChange={onConsentChange}
                      className={classes.consentInput}
                    />
                    <span className={classes.consentContent}>
                      I agree with Noble Hire&apos;s{' '}
                      <Link
                        to={paths.termsAndConditions}
                        title="Terms and conditions"
                        onClick={onLinkClick}
                      >
                        Terms & Conditions
                      </Link>
                      . Learn how we collect, use and share your data in our{' '}
                      <Link
                        to={paths.privacyPolicy}
                        title="Data policy"
                        onClick={onLinkClick}
                      >
                        Data Policy
                      </Link>
                      .
                    </span>
                  </label>
                  <Error error={state.errors.consent} />
                </div>
              ) : null}
              {error && error !== null ? (
                <div className={classes.errorContainer}>
                  <PopupErrorPanel error={error} />
                </div>
              ) : null}
              <div className={classes.formControls}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disableSubmit || loading}
                >
                  {loading ? 'Just a sec...' : btnLabel}
                </Button>
              </div>
              {footer ? <div className={classes.footer}>{footer}</div> : null}
            </form>
          </PopupMain>
        </PopupContainer>
      </div>
    </Popup>
  );
}
