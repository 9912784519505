// @flow
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import { Button } from '../../basic/Button';
import { Modal } from '../../basic/Modal';
import { useStateContainer } from '../../../core/context/StateContainer';
import { dequeueModal } from '../../modals/actions';
import type { Image } from '../../companies/common/context/CompanyContext';

function PickTeamLeadImage({
  images,
  onUpload,
  onPick,
}: {
  images: Image[],
  onUpload: () => void,
  onPick: (choice: Image) => void,
}) {
  const [, dispatch] = useStateContainer();
  const titleId = 'pickTeamLeadImage';
  const contentId = 'pickTeamLeadImageContent';

  return (
    <Modal
      open
      onClose={() => dispatch(dequeueModal())}
      titleId={titleId}
      contentId={contentId}
      withCloseButton={false}
    >
      <Box
        mb={2}
        minWidth={400}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>Pick team lead image</Box>
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() => dispatch(dequeueModal())}
        >
          <FontAwesomeIcon icon={['fal', 'times']} size="2x" />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" margin="-10px" mb={2}>
        {images.map((image) => {
          return (
            <Box
              key={image.id}
              style={{
                borderRadius: 80,
                margin: 10,
                width: 80,
                height: 80,
                backgroundImage: `url("${image.src}")`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                cursor: 'pointer',
              }}
              onClick={() => onPick(image)}
            />
          );
        })}
      </Box>
      <Button
        type="button"
        color="edit"
        variant="contained"
        onClick={onUpload}
        disableElevation
      >
        Upload new
      </Button>
    </Modal>
  );
}

export { PickTeamLeadImage };
