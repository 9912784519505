// @flow
import type { Node } from 'react';

const OPEN_CONFIRMATION = 'OPEN_CONFIRMATION';
const CLOSE_CONFIRMATION = 'CLOSE_CONFIRMATION';

function openConfirmation(confirmation: Node) {
  return { type: OPEN_CONFIRMATION, data: confirmation };
}

function closeConfirmation() {
  return { type: CLOSE_CONFIRMATION };
}

export {
  OPEN_CONFIRMATION,
  CLOSE_CONFIRMATION,
  openConfirmation,
  closeConfirmation,
};
