/* eslint-disable no-console */
// @flow
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCompaniesList } from '../components/companies/list/useCompaniesList';

export function SeoCompanies() {
  const { index } = useParams();
  const { load, data } = useCompaniesList();

  useEffect(() => {
    const page = Number.isNaN(Number(index)) ? undefined : Number(index);
    load('', page);
  }, [index, load]);

  return data.elements && data.elements.length
    ? data.elements.map<any>((company) => {
        const companySlug = company.slug || '';
        return (
          <React.Fragment key={`${companySlug}`}>
            <a key={company.brand} href={`/${companySlug}`}>
              {company.brand}
            </a>
            <br />
          </React.Fragment>
        );
      })
    : null;
}
