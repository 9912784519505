import { useVisitorTrails } from '../tracking/visitor-trails/VisitorTrailsContext';

function useCopyRefLinkTrail() {
  const { getTrail, putTrail, visitorTrails } = useVisitorTrails();

  function onCopyRefLink() {
    const copies = Number(getTrail(visitorTrails.COPY_REF_LINK, 0));
    putTrail(visitorTrails.COPY_REF_LINK, copies + 1);
  }

  return { onCopyRefLink };
}

export { useCopyRefLinkTrail };
