// @flow

import React, { createContext, useContext, useState } from 'react';
import type { Node } from 'react';

type Ctx = {
  isOpen: boolean,
  openPopup: () => void,
  closePopup: () => void,
};

const RequestCompanyProfileContext = createContext<Ctx | null>(null);

export function RequestCompanyProfileProvider({
  children,
}: {
  children: Node,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => setIsOpen(true);

  const closePopup = () => setIsOpen(false);

  return (
    <RequestCompanyProfileContext.Provider
      value={{ isOpen, openPopup, closePopup }}
    >
      {children}
    </RequestCompanyProfileContext.Provider>
  );
}

export function useRequestCompanyProfile() {
  const ctx = useContext(RequestCompanyProfileContext);
  if (!ctx) throw new Error('Improper use of RequestCompanyProfileProvider');
  return ctx;
}
