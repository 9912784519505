// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useModalsQueue } from './useModalsQueue';

const useStyles = makeStyles({
  modalsQueue: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

function ModalsQueue() {
  const classes = useStyles();
  const { getFromQueue } = useModalsQueue();

  return (
    <div id="modalsQueue" className={classes.modalsQueue}>
      {getFromQueue()}
    </div>
  );
}

export { ModalsQueue };
