// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@material-ui/core';
import ReactCrop from 'react-image-crop';
import { useDropzone } from 'react-dropzone';
import { Button } from './Button';

type Crop =
  | {|
      aspect: number,
      height: number,
      keepSelection: boolean,
      unit: string,
      width: number,
      x: number,
      y: number,
    |}
  | {|
      aspect: number,
      keepSelection: boolean,
      unit: string,
      width: number,
      height: number,
      x: number,
      y: number,
    |};

const INITIAL_CROP_DATA = {
  unit: '%',
  width: 100,
  height: 100,
  aspect: undefined,
};

function extractImageExtension(name: string) {
  const parts = name.split('.');
  return parts[parts.length - 1];
}

export function UploadImage({
  acceptButtonText,
  onAccepted,
  onChooseAnotherImageClick,
  disabled = false,
  initialCropStateOnUpload = INITIAL_CROP_DATA,
  cropSettings = {},
}: {
  acceptButtonText: string,
  onAccepted: ({ url: string, blob: ?Blob }) => void,
  initialCropStateOnUpload?: {
    aspect?: number,
    keepSelection?: boolean,
    unit?: string,
    width?: number,
    height?: number,
    x?: number,
    y?: number,
  },
  disabled: boolean,
  onChooseAnotherImageClick?: () => void,
  cropSettings?: {
    aspect?: number,
    keepSelection?: boolean,
    unit?: string,
    width?: number,
    height?: number,
    x?: number,
    y?: number,
  },
}) {
  const initialCropData = {
    ...INITIAL_CROP_DATA,
    ...cropSettings,
  };
  const theme = useTheme();
  const imageRef = useRef(null);
  const [state, setState] = useState({
    ext: 'png',
    mime: 'image/png',
    crop: initialCropData,
    cropUrl: '',
    cropBlob: null,
    preview: null,
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const preview = URL.createObjectURL(file);
        setState((prev) => ({
          ...prev,
          ext: extractImageExtension(file.name),
          mime: file.type,
          crop: {
            ...prev.crop,
            // ...initialCropData,
            ...initialCropStateOnUpload,
          },
          preview,
        }));
      });
    },
    [initialCropStateOnUpload]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onImageLoadedInCropFrame = useCallback((image: HTMLImageElement) => {
    imageRef.current = image;
  }, []);

  const getResizedCanvas = (image: HTMLImageElement, _crop: Crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(_crop.width * scaleX);
    canvas.height = Math.ceil(_crop.height * scaleY);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      _crop.x * scaleX,
      _crop.y * scaleY,
      _crop.width * scaleX,
      _crop.height * scaleY,
      0,
      0,
      _crop.width * scaleX,
      _crop.height * scaleY
    );
    return canvas;
  };

  function getCroppedImage(image, fileName) {
    // $FlowFixMe
    const canvas = getResizedCanvas(image, state.crop);
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          // eslint-disable-next-line no-console
          console.error('Canvas is empty');
          return;
        }

        // eslint-disable-next-line no-param-reassign
        blob.name = fileName;
        window.URL.revokeObjectURL(state.cropUrl);
        const imageUrl = window.URL.createObjectURL(blob);
        setState((prev) => ({ ...prev, cropUrl: imageUrl }));
        resolve({ imageUrl, blob });
      }, state.mime);
    });
  }

  function cropImage() {
    const { crop } = state;
    if (imageRef && imageRef.current && crop.width && crop.height) {
      getCroppedImage(imageRef.current, `new-file.${state.ext}`).then(
        ({ blob }) => {
          setState((prev) => ({ ...prev, cropBlob: blob }));
        }
      );
    }
  }

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      crop: { ...prev.crop, x: 0, y: 0, width: null, height: null },
      preview: state.cropUrl,
    }));
  }, [state.cropUrl]);

  function internalOnCropAccepted() {
    onAccepted({ url: state.cropUrl, blob: state.cropBlob });
  }

  function internalOnChooseAnotherImageClick() {
    if (typeof onChooseAnotherImageClick === 'function') {
      onChooseAnotherImageClick();
    }

    setState((prev) => ({
      ...prev,
      crop: { ...prev.crop, ...initialCropData },
      preview: null,
      cropUrl: '',
      cropBlob: null,
    }));
  }

  return (
    <Box>
      {state.preview ? (
        <Box
          maxWidth={480}
          // maxHeight={480}
          position="relative"
          bgcolor={theme.palette.grey[100]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          overflow="auto"
        >
          <ReactCrop
            src={state.preview}
            crop={state.crop}
            onImageLoaded={onImageLoadedInCropFrame}
            onChange={(newCrop) =>
              setState((prev) => ({
                ...prev,
                crop: { ...prev.crop, ...newCrop },
              }))
            }
            imageStyle={{ width: '100%', height: '100%' }}
            disabled={state.cropUrl}
          />
        </Box>
      ) : (
        <Box>
          <Box
            {...getRootProps()}
            bgcolor={theme.palette.grey[100]}
            border={`3px dashed ${theme.palette.grey[300]}`}
            padding={2}
            borderRadius={theme.shape.borderRadius}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop here ...</p>
            ) : (
              <p>Click to browse files or drag and drop here</p>
            )}
          </Box>
        </Box>
      )}
      {state.preview ? (
        <Box
          mt={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            color="primary"
            type="button"
            variant="text"
            disabled={disabled}
            onClick={disabled ? () => {} : internalOnChooseAnotherImageClick}
          >
            Choose another image
          </Button>
          {state.cropUrl ? (
            <Button
              color="edit"
              variant="contained"
              type="button"
              disabled={disabled}
              onClick={disabled ? () => {} : internalOnCropAccepted}
            >
              {acceptButtonText}
            </Button>
          ) : (
            <Button
              color="primary"
              type="button"
              variant="contained"
              disabled={disabled}
              onClick={disabled ? () => {} : cropImage}
            >
              Crop
            </Button>
          )}
        </Box>
      ) : null}
    </Box>
  );
}
