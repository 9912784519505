// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal } from '../../basic/Modal';
import { useStateContainer } from '../../../core/context/StateContainer';
import { dequeueModal } from '../actions';

type Props = {
  children: React.Node,
};

const useStyles = makeStyles((theme) => ({
  root: { borderTop: `5px solid ${theme.palette.success.main}` },
  heading: { marginTop: 0 },
}));

function Success({ children }: Props) {
  const classes = useStyles();
  const [, dispatch] = useStateContainer();
  const titleId = 'success';
  const contentId = 'successContent';

  return (
    <Modal
      open
      onClose={() => dispatch(dequeueModal())}
      titleId={titleId}
      contentId={contentId}
      paperClassName={classes.root}
    >
      <h3 className={classes.heading}>Success!</h3>
      <div>{children}</div>
    </Modal>
  );
}

export { Success };
