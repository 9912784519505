// @flow
import { makeStyles, useTheme } from '@material-ui/core';
import type { Node } from 'react';
import React from 'react';
import type { Job } from '../jobs/JobsContext';
import { Link, useLocation } from 'react-router-dom';
import { LocationPicker } from '../jobs/JobAd';
import {
  paths,
  programmingLanguages,
  platformsAndFrameworks,
  databases,
} from '../../core/constants';
import cn from 'classnames';
import { useStateContainer } from '../../core/context/StateContainer';

const NOT_MOBILE = '@media (min-width: 600px)';

const useStyles = makeStyles((theme) => {
  const common = {
    title: {
      fontWeight: theme.typography.fontWeightBold,
    },
    details: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 600,
      color: theme.palette.grey[600],
    },
    location: {
      fontWeight: `600 !important`,
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.grey[700],
    },
  };
  return {
    rootLink: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    root: {
      color: `${theme.palette.common.black} !important`,
      background: theme.palette.grey[100],
      padding: theme.spacing(2),
      border: `1px solid transparent`,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      marginBottom: theme.spacing(1.5),
      cursor: 'pointer',

      '&:hover': {
        background: theme.palette.primary.lightest,
        border: `1px solid ${theme.palette.primary.light}`,
      },
    },
    main: ({ authenticatedAsAdmin }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(2),

      [NOT_MOBILE]: {
        justifyContent: 'flex-start',
        paddingBottom: authenticatedAsAdmin ? theme.spacing(2) : 0,
      },
    }),
    x_left: {
      [NOT_MOBILE]: {
        flex: 1,
        paddingRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
      },
    },
    x_right: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      '& img': {
        maxHeight: 40,
        maxWidth: 80,
      },
    },
    location: {
      ...common.location,

      [NOT_MOBILE]: {
        display: 'none',
      },
    },
    x_location: {
      ...common.location,
      display: 'none',
      [NOT_MOBILE]: {
        display: 'block',
        width: 100,
      },
    },
    x_titleAndDetails: {
      paddingLeft: theme.spacing(2),
    },
    title: {
      ...common.title,
      margin: 0,
      paddingBottom: 0,

      [NOT_MOBILE]: {
        display: 'none',
      },
    },
    x_title: {
      ...common.title,
      display: 'none',
      margin: 0,
      [NOT_MOBILE]: {
        display: 'block',
      },
    },
    details: {
      ...common.details,
      [NOT_MOBILE]: {
        display: 'none',
      },
    },
    x_details: {
      ...common.details,
      display: 'none',
      [NOT_MOBILE]: {
        display: 'block',
      },
    },
    x_salary: {
      display: 'none',
      [NOT_MOBILE]: {
        display: 'flex',
        alignItems: 'center',
        width: 150,
        justifyContent: 'flex-end',
        whiteSpace: 'nowrap',
      },
    },
    salary: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
    row: {
      '&.space': {
        paddingBottom: theme.spacing(1),
      },
      [NOT_MOBILE]: {
        display: 'none',
      },
    },
    adminLink: {
      display: 'inline-block',
      padding: theme.spacing(0.5, 1),
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(1),
      textDecoration: 'none',
      '&:hover': {
        background: theme.palette.primary.dark,
        textDecoration: 'none',
      },
    },
    remoteTag: {
      fontWeight: theme.typography.fontWeightBold,
      color: 'rgb(234	51 143)',
    },
  };
});

export function JobCard({
  job,
  jobLink,
}: {
  job: Job<any>,
  jobLink?: { pathname: string, search: string },
}) {
  const loc = useLocation();
  const [
    {
      auth: { authenticated, user, isAdmin },
    },
  ] = useStateContainer();
  const hasLogo = job.company.logos && job.company.logos.length;
  const logoSrc = hasLogo
    ? job.company.logos[job.company.logos.length - 1].src
    : null;
  const link = jobLink || {
    pathname: paths.job.replace(
      ':slug+',
      `${job.company.slug || ''}/${job.slug || ''}`
    ),
    search: loc.search,
    state: { skipScrollTop: true },
  };
  const authenticatedAsAdmin = authenticated && user && isAdmin(user);
  const classes = useStyles({ authenticatedAsAdmin });
  const jobDetails = [];
  if (job.primaryLanguage)
    jobDetails.push(programmingLanguages[job.primaryLanguage]);
  if (job.primaryPlatformOrFramework)
    jobDetails.push(platformsAndFrameworks[job.primaryPlatformOrFramework]);
  if (job.mainDatabase) jobDetails.push(databases[job.mainDatabase]);
  return (
    <Link className={classes.rootLink} to={link}>
      <div className={classes.root}>
        {/* --- Heading --- */}
        <div className={classes.main}>
          <div className={classes.x_left}>
            {hasLogo ? (
              <div className={classes.logo}>
                <img src={logoSrc} alt="Company logo" />
              </div>
            ) : null}

            <div className={classes.x_titleAndDetails}>
              <h3 className={classes.x_title}>{job.title}</h3>
              <div className={classes.x_details}>{jobDetails.join(', ')}</div>
            </div>
          </div>

          <div className={classes.x_right}>
            <div className={classes.x_location}>
              <Location job={job} />
            </div>
            <div className={classes.x_salary}>
              <Salary job={job} />
            </div>
          </div>

          <div className={classes.location}>
            <Location job={job} />
          </div>
        </div>

        {/* --- Heading --- */}
        <Row>
          <h3 className={classes.title}>{job.title}</h3>
        </Row>

        {/* Details */}
        <Row>
          <div className={classes.details}>{jobDetails.join(', ')}</div>
        </Row>
        <Row bottomSpacing={authenticatedAsAdmin}>
          <div className={classes.salary}>
            <Salary job={job} />
          </div>
        </Row>

        {authenticatedAsAdmin ? <AdminControls job={job} /> : null}
      </div>
    </Link>
  );
}

function Location({ job }: { job: Job<any> }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {job.fullyRemote === 'yes' ? (
        <span className={classes.remoteTag}>Remote</span>
      ) : job.location && job.location.length ? (
        <span className={classes.mobileLocation}>
          <LocationPicker
            location={job.location}
            locations={job.company.locations}
            textColor={theme.palette.grey[700]}
            lock
            simpleAddress
          />
        </span>
      ) : (
        <span className={classes.loc}>&mdash;</span>
      )}
    </>
  );
}

function Salary({ job }: { job: Job<any> }) {
  const classes = useStyles();

  return (
    <>
      {job.salary && job.salary !== '0-0' && job.salary !== '' ? (
        <span className={classes.salary}>
          {job.salary} {job.salaryCurrency}
        </span>
      ) : (
        <span className={classes.salary}>&mdash;</span>
      )}
    </>
  );
}

function Row({
  children,
  bottomSpacing = true,
}: {
  children: Node,
  bottomSpacing?: boolean,
}) {
  const classes = useStyles();

  return (
    <div className={cn(classes.row, { space: bottomSpacing })}>{children}</div>
  );
}

function AdminControls({ job }: { job: Job<any> }) {
  const classes = useStyles();
  const jobEditLink = {
    pathname: paths.jobEdit.replace(
      ':slug+',
      `${job.company.slug || ''}/${job.slug || ''}`
    ),
    search: '?edit=true',
  };
  const companyDashboardLink = {
    pathname: paths.companiesDashboard.replace(
      ':slug',
      `${job.company.slug || ''}`
    ),
    search: '',
  };

  return (
    <>
      <Link className={classes.adminLink} to={jobEditLink}>
        Edit
      </Link>
      <Link className={classes.adminLink} to={companyDashboardLink}>
        Company Dashboard
      </Link>
      ({!job.isPublic ? `Private` : `Public`})
    </>
  );
}
