// @flow
import { Box, makeStyles, useTheme } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const useSelectStyles = makeStyles((theme) => {
  return {
    option: {
      minWidth: 70,
      fontSize: theme.typography.fontSize,
      textTransform: 'initial',
      fontWeight: 'normal',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      padding: theme.spacing(1, 2),
      cursor: 'pointer',
      whiteSpace: 'nowrap',

      '&:hover': {
        background: theme.palette.grey[100],
      },
    },
    noOptionsText: {
      fontSize: theme.typography.fontSize,
      textTransform: 'initial',
      fontWeight: theme.typography.fontWeightRegular,
      textAlign: 'center',
      color: theme.palette.grey[500],
      padding: theme.spacing(2),
      whiteSpace: 'nowrap',
    },
  };
});
type Option = { selected: boolean, l: string };
type SelectProps = {
  defaultLabel?: string | null,
  hintOnHover?: string,
  options: { [optionValue: string]: Option },
  onChange?: (val: any, o?: Option) => void,
  lock: boolean,
};
export function Select({
  defaultLabel,
  hintOnHover,
  options,
  onChange,
  lock = false,
}: SelectProps) {
  // resolve the selected option
  const c = useSelectStyles();
  const theme = useTheme();
  const resolveSelected = useCallback(() => {
    const oKeys = Object.keys(options);
    const selectedKey = oKeys.find((o) => options[o].selected) || null;
    const selected = selectedKey ? options[selectedKey] : null;
    return selectedKey && selected
      ? [selectedKey, selected]
      : [
          '*',
          {
            v: '*',
            l: defaultLabel || 'Select',
          },
        ];
  }, [options, defaultLabel]);

  // states
  const [selected, setSelected] = useState(resolveSelected());
  useEffect(() => setSelected(resolveSelected()), [resolveSelected]);

  const [selecting, setSelecting] = useState(false);
  const select = (val: string, opt: Option) => {
    if (onChange) onChange(val);
    setSelected([val, opt]);
  };

  // simulate close on blur + close on esc
  const ref = useRef();
  useEffect(() => {
    const outsideClick = ({ target }) =>
      ref.current && !ref.current.contains(target) && setSelecting(false);
    const esc = (e: KeyboardEvent) => e.keyCode === 27 && setSelecting(false);

    window.document.body.addEventListener('click', outsideClick);
    window.document.body.addEventListener('keyup', esc);
    return () => {
      window.document.body.removeEventListener('click', outsideClick);
      window.document.body.removeEventListener('keyup', esc);
    };
  }, []);

  if (lock && selected[0] === '*') return `—`;

  return (
    <Box ref={ref} position="relative">
      <Box
        onClick={() => !lock && setSelecting(true)}
        style={{ cursor: lock ? 'auto' : 'pointer' }}
        color={lock ? theme.palette.common.black : theme.palette.edit.main}
        title={hintOnHover}
      >
        {selected[1].l}
      </Box>
      <Box>
        {selecting ? (
          <Box position="absolute" left={0} top={25} zIndex={2}>
            <Box
              style={{
                background: theme.palette.common.white,
                border: `1px solid ${theme.palette.grey[300]}`,
              }}
              boxShadow="0 1px 7px 3px rgba(0,0,0,0.05)"
              borderRadius={theme.shape.borderRadius}
              overflow="auto"
              maxHeight={300}
            >
              {Object.keys(options).length < 1 ? (
                <div className={c.noOptionsText}>No available options.</div>
              ) : null}
              {Object.keys(options).map((k) => {
                return (
                  <Box
                    key={k}
                    className={c.option}
                    onClick={() => {
                      select(k, options[k]);
                      setSelecting(false);
                    }}
                  >
                    {options[k].l}
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
