// @flow
import * as React from 'react';
import { Field } from 'react-final-form';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  label: string,
  name: string,
  id: string,
  controlProps?: { [key: string]: any },
  fieldProps?: { [key: string]: any },
  placeholder?: string,
  required?: boolean,
  type?: string,
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.common.black,
    fontSize: 18,
    whiteSpace: 'nowrap',
  },
  errorSpacing: {
    height: '20px',
  },
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    boxSizing: 'content-box !important',
    marginBottom: '0 !important',
    outline: 'none',
    fontSize: `14px !important`,
    borderRadius: 5,
    position: 'relative',
    backgroundColor: `${theme.palette.common.white} !important`,
    padding: '10px 12px !important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      borderRadius: 5,
    },
  },
  inputRegular: {
    border: `1px solid ${theme.palette.grey.dark} !important`,
  },
  inputError: {
    border: `1px solid ${theme.palette.error.main} !important`,
  },
  filesList: {
    marginTop: theme.spacing(1),
  },
  fileCheck: {
    marginRight: theme.spacing(1),
  },
}));

function Input({
  label,
  name,
  id,
  controlProps,
  fieldProps,
  required,
  placeholder,
  type,
  ...rest
}: Props) {
  const classes = useStyles();

  return (
    <Field name={name} {...fieldProps}>
      {({ input, meta }) => {
        const error = meta.touched && (meta.error || meta.submitError);

        const inputProps = { ...input };
        if (type === 'file') {
          delete inputProps.value;
        }

        return (
          <FormControl {...controlProps} error={!!error}>
            <InputLabel
              className={classes.label}
              shrink
              htmlFor={id}
              required={required}
            >
              {label}
            </InputLabel>
            <InputBase
              id={id}
              placeholder={placeholder}
              {...inputProps}
              {...rest}
              type={type}
              classes={{
                root: classes.root,
                input: `${classes.input} ${
                  error ? classes.inputError : classes.inputRegular
                }`,
              }}
            />
            {type === 'file' && input.value ? (
              <div className={classes.filesList}>
                <FontAwesomeIcon
                  icon={['fal', 'check']}
                  className={classes.fileCheck}
                />
                {input.value.replace('C:\\fakepath\\', '')}
              </div>
            ) : null}
            {error && <FormHelperText key={error}>{error}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
}

Input.defaultProps = {
  required: false,
  placeholder: '',
  fieldProps: {},
  controlProps: {},
  type: 'text',
};

export { Input };
