// @flow
import React from 'react';
import type { Node } from 'react';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import type { EditableProps } from './types';
import { txtColor } from './utils';

type TextColorModeProps = {
  children: Node,
};
export function TextColorMode({
  isEdit,
  children,
}: EditableProps & TextColorModeProps) {
  const theme = useTheme();
  return (
    <Box
      style={{
        color: txtColor(theme, isEdit),
      }}
    >
      {children}
    </Box>
  );
}
