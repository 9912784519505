// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useNotificationsQueue } from './useNotificationsQueue';

const useStyles = makeStyles({
  notificationsQueue: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

function NotificationsQueue() {
  const classes = useStyles();
  const { getQueue } = useNotificationsQueue();

  return (
    <div id="notificationsQueue" className={classes.notificationsQueue}>
      {getQueue()}
    </div>
  );
}

export { NotificationsQueue };
