// @flow
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-no-bind */
import type { Node } from 'react';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useParams, useLocation, Link, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { Element as ScrollElement, Link as ScrollLink } from 'react-scroll';
import {
  Container,
  makeStyles,
  TextareaAutosize,
  useTheme,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDrag, useDrop } from 'react-dnd';
import { useStateContainer } from '../../../core/context/StateContainer';
import type {
  CompanyCtx,
  CompProp,
  Highlight,
  Image,
  Value,
  OnAddCompProp,
  OnEditCompProp,
  OnMoveCompProp,
  OnRemoveComProp,
  CarouselItem,
  CultureAndPerks,
} from '../common/context/CompanyContext';
import { CompDndPropType } from '../common/context/CompanyContext';
import { Button } from '../../basic/Button';
import { Error } from '../../notifications/components/Error';
import { Success } from '../../notifications/components/Success';
import { enqueueNotification } from '../../notifications/actions';
import { enqueueModal, dequeueModal } from '../../modals/actions';
import { UploadMainImage } from './modals/UploadMainImage';
import { UploadProductGalleryImage } from './modals/UploadProductGalleryImage';
import { Title } from '../../basic/Title';
import { Form } from '../../basic/form/Form';
import { Modal } from '../../basic/Modal';
// $FlowFixMe
import { ReactComponent as PublishSuccessIcon } from '../../../assets/images/nha/Group 5.svg';
import { FullPageLoader } from '../../basic/FullPageLoader';
import { Loader } from '../../basic/Loader';
import { Wizard } from './modals/Wizard';
import { paths } from '../../../core/constants';
import { useMQ } from '../../useMQ';
import { Footer } from '../../Footer';
import { EditHeader } from '../../basic/EditHeader';
import { ErrorsList } from '../../modals/components/ErrorsList';
import { HideIf } from '../../basic/HideIf';
import { UnderCover, UnderCoverContainer } from '../../basic/UnderCover';
import { EditControls } from '../../basic/EditControls';
import { Hint } from '../common/Hint';
import { LocationsList } from '../common/LocationsList';
import { cls, txtColor } from '../common/utils';
import type { EditableProps, PreviewProps } from '../common/types';
import { TextColorMode } from '../common/TextColorMode';
import { Highlights } from '../common/Highlights';
import { InfoBoxForm } from '../common/InfoBoxForm';
import useCompanyJobs from '../../jobs/useCompanyJobs';
import { JobCard } from '../../job-board/JobCardV2';
import type { Job } from '../../jobs/JobsContext';
import { Carousel, CarouselEditor } from '../common/Carousel';
import { RichEditor } from '../../basic/RichEditor';

// --- Helpers

export const ITEM_WIDTH = 420;
export const ITEM_HEIGHT = 300;

// --- Styles

const useStyles = makeStyles((theme) => {
  const fontFamily = { fontFamily: theme.typography.fontFamily };
  return {
    nav_link: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: 'none',
      textTransform: 'uppercase',
      cursor: 'pointer',
    },
    link: {
      textDecoration: 'none',
    },
    textarea: {
      ...fontFamily,
      width: '100%',
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.grey[300]}`,
      resize: 'none',
      borderRadius: theme.shape.borderRadius,
    },
    textareaWithError: {
      ...fontFamily,
      width: '100%',
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      resize: 'none',
      border: `1px solid ${theme.palette.error.main}`,
    },
    input: {
      ...fontFamily,
      width: '100%',
      padding: theme.spacing(1),
      height: 40,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    inputWithError: {
      width: '100%',
      padding: theme.spacing(1),
      height: 40,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.error.main}`,
    },
    editBorder: {
      border: `1px solid transparent`,
      background: theme.palette.grey[100],

      '&:hover': {
        border: `1px solid ${theme.palette.edit.main}`,
        boxShadow: theme.boxShadow,
      },
    },

    // utils
    u_mb: { marginBottom: theme.spacing(4) },
    u_mbSmall: { marginBottom: theme.spacing(1) },
    u_mr: { marginRight: theme.spacing(4) },
    u_mrSmall: { marginRight: theme.spacing(1) },
  };
});

export function CompanyProfile({ comp }: { comp: CompanyCtx }) {
  const c = useStyles();
  const mq = useMQ();
  const theme = useTheme();
  const { load: loadJobs, loading: jobsLoading, jobs } = useCompanyJobs();
  const [, dispatch] = useStateContainer();
  const [exiting, setExiting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [showingErrors, setShowingErrors] = useState<boolean>(false);
  const { canBeEdited } = comp;

  useEffect(() => {
    if (comp.state.slug) {
      loadJobs(comp.state.slug);
    }
  }, [loadJobs, comp.state.slug]);

  // --------------------------------------------------------------------------- //
  // Redirect, if the company is not mine and is not public
  // --------------------------------------------------------------------------- //
  // if (!comp.loading && !canBeEdited && !comp.state.isPublic) {
  //   return <Redirect to={paths.root} />;
  // }
  // --------------------------------------------------------------------------- //

  return comp.loading ? (
    <FullPageLoader>Preparing the starship...</FullPageLoader>
  ) : (
    <Box>
      <Box mb={4}>
        {canBeEdited && comp.showWizard ? (
          <Wizard loading={false} onStart={comp.onWizardEnd} />
        ) : null}
        {canBeEdited &&
        comp.isEdit &&
        comp.errors &&
        Object.keys(comp.errors).length &&
        showingErrors ? (
          <ErrorsList
            errors={comp.errors}
            onClose={() => setShowingErrors(false)}
          />
        ) : null}
        {canBeEdited && comp.isEdit && publishSuccess ? (
          <PublishSuccess onClose={() => setPublishSuccess(false)} />
        ) : null}
        {canBeEdited && comp.isEdit ? (
          <EditHeader
            saving={saving}
            exiting={exiting}
            isPublic={comp.state.isPublic}
            onPreview={() => comp.setIsPreview(true)}
            onExit={() => {
              setExiting(true);
              comp.exitEdit(() => setExiting(false));
            }}
            onUnpublish={() => {
              comp.setIsPublic(false);
              setShowingErrors(false);
              setSaving(true);
              comp.save({ public: false }).then(() => {
                setSaving(false);
                dispatch(
                  enqueueNotification(
                    <Success>
                      <span>Company profile (</span>
                      <strong>{comp.state.brand}</strong>
                      <span>) updated successfully.</span>
                    </Success>
                  )
                );
              });
            }}
            onPublish={() => {
              comp.setIsPublic(true);
              setSaving(true);
              setShowingErrors(true);
              comp
                .publish()
                .then(() => {
                  setPublishSuccess(true);
                })
                .catch(() => {
                  comp.setIsPublic(false);
                })
                .finally(() => setSaving(false));
            }}
            onSave={() => {
              setShowingErrors(false);
              setSaving(true);
              comp.save().then(() => {
                setSaving(false);
                // history.push({ pathname: location.pathname, search: '' });
              });
            }}
          />
        ) : null}
        {canBeEdited && saving ? (
          <Box
            zIndex={2}
            position="fixed"
            top={0}
            left={0}
            width="100vw"
            height="100vh"
            style={{ background: `rgba(255,255,255,0.8)` }}
          >
            <FullPageLoader>
              We&apos;re updating your profile. Thank you for the patience.
            </FullPageLoader>
          </Box>
        ) : null}
        <CoverImage
          canBeEdited={canBeEdited}
          isPublic={comp.state.isPublic}
          onEditClick={() => comp.setIsEdit(true)}
          image={
            comp.state.mainImages.length
              ? comp.state.mainImages[comp.state.mainImages.length - 1]
              : null
          }
          onUpload={comp.addMainImage}
          isEdit={comp.isEdit}
          isPreview={comp.isPreview}
          companySlug={comp.state.slug}
        />

        <Container>
          <Navigation
            isEdit={comp.isEdit}
            hasMainImage={!!comp.state.mainImages.length}
            logo={
              comp.state.logos.length
                ? comp.state.logos[comp.state.logos.length - 1]
                : null
            }
            brand={comp.state.brand}
            onUpload={comp.addLogo}
            showProduct={
              !!(
                comp.isEdit ||
                comp.state.product ||
                comp.state.productImages.length
              )
            }
            showCompany={
              !!(
                comp.isEdit ||
                comp.state.awards.length ||
                comp.state.clientImages.length
              )
            }
            showPhotos={!!(comp.isEdit || comp.state.photos.length)}
            showCultureAndPerks={
              !!(
                comp.isEdit ||
                comp.state.cultureAndPerks.length ||
                comp.state.values.length
              )
            }
            jobs={jobs}
            jobsLoading={jobsLoading}
            showJobs
          />
          {/* Overview + Locations */}
          <Box display="flex" flexDirection={mq.lg ? 'row' : 'column'} mb={8}>
            <Box flex={5} mr={mq.lg ? 4 : 0}>
              <HideIf cond={() => !comp.isEdit && !comp.state.overview}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1}
                >
                  <Box display="flex" alignItems="center">
                    <Title mr={2} mb={0} variant={Title.variants.t4}>
                      Overview
                    </Title>
                  </Box>
                  <Box display="flex" alignItems="center">
                    {comp.isEdit ? (
                      <Box color={theme.palette.edit.main} mr={1}>
                        *Required
                      </Box>
                    ) : null}
                    {comp.isEdit ? (
                      <Hint text="Noble Hire is creating a new way to source talent. Companies can post referral bonuses for any of their job openings and our network of recruiters, industry experts, and business leaders help find the right talent." />
                    ) : null}
                  </Box>
                </Box>

                <Overview
                  isEdit={comp.isEdit}
                  value={comp.state.overview}
                  update={comp.setOverview}
                />
              </HideIf>
            </Box>
            <HideIf cond={() => !comp.isEdit && !comp.state.locations.length}>
              <Box flex={3}>
                <TextColorMode isEdit={comp.isEdit}>
                  <LocationsList
                    locations={comp.state.locations}
                    locak={false}
                    add={comp.addLocation}
                    remove={comp.removeLocation}
                    update={comp.updateLocation}
                    isEdit={comp.isEdit}
                    isPreview={comp.isPreview}
                  />
                </TextColorMode>
              </Box>
            </HideIf>
          </Box>

          {/* Product */}
          <HideIf
            cond={() =>
              !comp.isEdit &&
              !comp.state.productImages.length &&
              !comp.state.product
            }
          >
            <ScrollElement name="product">
              <Box mb={8}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Title className={c.u_mbSmall} variant={Title.variants.t2}>
                    Product
                  </Title>
                  <Box display="flex" alignItems="center">
                    {comp.isEdit ? (
                      <Box color={theme.palette.edit.main} mr={1}>
                        *Required
                      </Box>
                    ) : null}
                    {comp.isEdit ? (
                      <Hint
                        title="Hint"
                        text="Upload some images highlighting your company's product/service. Provide a brief description bellow."
                      />
                    ) : null}
                  </Box>
                </Box>
                <ProductOverview
                  isEdit={comp.isEdit}
                  images={comp.state.productImages}
                  product={comp.state.product}
                  onAdd={comp.addProductImage}
                  onRemove={comp.removeProductImage}
                  onMove={comp.moveProductImage}
                  updateProduct={comp.setProduct}
                />
              </Box>
            </ScrollElement>
          </HideIf>

          {/* Company */}
          <HideIf
            cond={() =>
              !comp.isEdit &&
              !comp.state.awards.length &&
              !comp.state.clientImages.length
            }
          >
            <ScrollElement name="company">
              <Box mb={8}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Title className={c.u_mbSmall} variant={Title.variants.t2}>
                    Achievements
                  </Title>

                  <Box display="flex" alignItems="center">
                    {comp.isEdit ? (
                      <Box color={theme.palette.edit.main} mr={1}>
                        *Required
                      </Box>
                    ) : null}

                    {comp.isEdit ? (
                      <Hint
                        render={
                          <Box>
                            <div>- Startup veterans</div>
                            <div>- VC-Funded</div>
                            <div>
                              - Winner in Soft Uni Conf Blockchain Competition
                              2018
                            </div>
                          </Box>
                        }
                      />
                    ) : null}
                  </Box>
                </Box>

                <HideIf cond={() => !comp.isEdit && !comp.state.awards.length}>
                  <Box className={c.u_mb}>
                    <Highlights
                      isEdit={comp.isEdit}
                      highlights={comp.state.awards}
                      onEdit={comp.editAward}
                      onAdd={comp.addAward}
                      onMove={comp.moveAward}
                      onRemove={comp.removeAward}
                    />
                  </Box>
                </HideIf>

                <HideIf
                  cond={() => !comp.isEdit && !comp.state.clientImages.length}
                >
                  <Box mt={8}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={1}
                    >
                      <Title mb={0} variant={Title.variants.t4}>
                        Clients
                      </Title>
                      {comp.isEdit ? (
                        <Hint
                          title="Hint"
                          text="Upload your clients' logos, if any. Prefer logos with transparent background and high resolution."
                        />
                      ) : null}
                    </Box>

                    <Clients
                      isEdit={comp.isEdit}
                      clientImages={comp.state.clientImages}
                      onRemove={comp.removeClientImage}
                      onAdd={comp.addClientImage}
                    />
                  </Box>
                </HideIf>
              </Box>
            </ScrollElement>
          </HideIf>

          {/* Photos */}
          <HideIf cond={() => !comp.isEdit && !comp.state.photos.length}>
            <ScrollElement name="photos">
              <Box mb={8}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Title className={c.u_mbSmall} variant={Title.variants.t2}>
                    Photos
                  </Title>

                  <Box display="flex" alignItems="center">
                    {comp.isEdit ? (
                      <Box color={theme.palette.edit.main} mr={1}>
                        *Required
                      </Box>
                    ) : null}

                    {comp.isEdit ? (
                      <Hint
                        title="Hint"
                        render={
                          <Box>
                            This is the place to present your company in the
                            best light possible, using photos from your office,
                            team-gatherings, etc. We recommend not to use stock
                            images, but images that showcase your company&apos;s
                            culture and values.
                          </Box>
                        }
                      />
                    ) : null}
                  </Box>
                </Box>

                <Photos
                  isEdit={comp.isEdit}
                  photos={comp.state.photos}
                  onAdd={comp.addPhoto}
                  onMove={comp.movePhoto}
                  onRemove={comp.removePhoto}
                />
              </Box>
            </ScrollElement>
          </HideIf>

          {/* Culture and perks */}
          <HideIf
            cond={() =>
              !comp.isEdit &&
              !comp.state.cultureAndPerks.length &&
              !comp.state.values.length
            }
          >
            <ScrollElement name="perks">
              <Box mb={8}>
                <Title className={c.u_mbSmall} variant={Title.variants.t2}>
                  Culture and perks
                </Title>
                <Box display="flex" flexDirection={mq.lg ? 'row' : 'column'}>
                  <Box flex={5} mr={mq.lg ? 4 : 0}>
                    <HideIf
                      cond={() =>
                        !comp.isEdit && !comp.state.cultureAndPerks.length
                      }
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={1}
                      >
                        <Title mb={0} variant={Title.variants.t4}>
                          Culture
                        </Title>

                        <Box display="flex" alignItems="center">
                          {comp.isEdit ? (
                            <Box color={theme.palette.edit.main} mr={1}>
                              *Required
                            </Box>
                          ) : null}

                          {comp.isEdit ? (
                            <Hint
                              title="Hint"
                              render={
                                <Box>
                                  List some activities that you do as part of
                                  your culture that makes your company unique
                                  with a couple of sentences describing each of
                                  them.
                                  <Box mt={2}>
                                    <strong>Example:</strong>
                                  </Box>
                                  <strong>A Fun Place to Work</strong>
                                  <div>
                                    You get to work in a fun environment with a
                                    team of passionate techies (with a sense of
                                    humour)!
                                  </div>
                                </Box>
                              }
                            />
                          ) : null}
                        </Box>
                      </Box>

                      <CulturePerks
                        isEdit={comp.isEdit}
                        items={comp.state.cultureAndPerks}
                        onAdd={comp.addCultureAndPerks}
                        onMove={comp.moveCultureAndPerks}
                        onRemove={comp.removeCultureAndPerks}
                        onEdit={comp.editCultureAndPerks}
                      />
                    </HideIf>
                  </Box>
                  <Box flex={3}>
                    <HideIf
                      cond={() => !comp.isEdit && !comp.state.values.length}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={1}
                      >
                        <Title mb={0} variant={Title.variants.t4}>
                          Values
                        </Title>

                        <Box display="flex" alignItems="center">
                          {comp.isEdit ? (
                            <Box color={theme.palette.edit.main} mr={1}>
                              *Required
                            </Box>
                          ) : null}

                          {comp.isEdit ? (
                            <Hint
                              render={
                                <Box>
                                  <div>- Full Transparency</div>
                                  <div>- Getting Things Done</div>
                                  <div>- Company of Friends</div>
                                  <div>- Constant Learning</div>
                                  <div>- Investing in Strengths</div>
                                </Box>
                              }
                            />
                          ) : null}
                        </Box>
                      </Box>

                      <Values
                        isEdit={comp.isEdit}
                        items={comp.state.values}
                        onAdd={comp.addValue}
                        onRemove={comp.removeValue}
                        onEdit={comp.editValue}
                        onMove={comp.moveValue}
                      />
                    </HideIf>
                  </Box>
                </Box>
              </Box>
            </ScrollElement>
          </HideIf>
          <Box id="culture-and-perks" mb={8}>
            <Title className={c.u_mbSmall} variant={Title.variants.t2}>
              Jobs
            </Title>
            <Jobs
              brand={comp.state.brand}
              jobs={jobs}
              jobsLoading={jobsLoading}
              isEdit={comp.isEdit}
            />
          </Box>
          <ScrollTop />
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

// --- Temp area
// --- Components
function ScrollTop() {
  const theme = useTheme();
  return (
    <Box my={4}>
      <div
        style={{
          height: '1px',
          width: '100%',
          background: theme.palette.grey[300],
        }}
      />
      <Box my={4} display="flex" justifyContent="space-between">
        <div>
          <a
            href={`https://www.facebook.com/share.php?u=${window.location.href}`}
            target="_blank"
            rel="noreferrer noopener"
            style={{ marginRight: 10 }}
          >
            <FontAwesomeIcon icon={['fab', 'facebook']} size="2x" />
          </a>

          <a
            href={`https://www.linkedin.com/cws/share?url=${window.location.href}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon icon={['fab', 'linkedin']} size="2x" />
          </a>
        </div>
        <Box
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          }
          style={{ cursor: 'pointer' }}
        >
          <FontAwesomeIcon icon={['fal', 'chevron-up']} size="2x" />
        </Box>
      </Box>
    </Box>
  );
}

function PublishSuccess({ onClose }: { onClose: () => void }) {
  const classes = useStyles();
  const loc = useLocation();
  const params = useParams();
  const [copied, setCopied] = useState(false);
  const link = `https://noblehire.io${loc.pathname}`;
  const titleId = 'publishSuccessTitle';
  const contentId = 'publishSuccessContent';
  const theme = useTheme();
  const onClick = () => {
    if (!copied) {
      setCopied(true);
    }
  };

  return (
    <Modal open onClose={onClose} titleId={titleId} contentId={contentId}>
      <Box style={{ maxWidth: '64ch' }} textAlign="center">
        <Box mt={4}>
          <Box>
            <PublishSuccessIcon />
          </Box>
          <Box
            fontSize={theme.typography.pxToRem(36)}
            fontWeight={theme.typography.fontWeightBold}
            mb={2}
          >
            <Box>
              Success!
              <br />
              Your company is live now!
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            {copied ? (
              <Box
                position="absolute"
                top={theme.spacing(4)}
                left={theme.spacing(4)}
              >
                <Box
                  bgcolor={theme.palette.success.main}
                  color={theme.palette.common.white}
                  padding={1}
                  borderRadius={theme.shape.borderRadius}
                >
                  Link copied to clipboard
                </Box>
              </Box>
            ) : null}

            <Box mr={2}>
              <Box
                padding={1}
                bgcolor={theme.palette.grey[100]}
                borderRadius={theme.shape.borderRadius}
              >
                <a href={link}>{link}</a>
              </Box>
            </Box>
            <CopyToClipboard text={link}>
              <Box onClick={onClick}>
                <FontAwesomeIcon
                  icon={['fal', 'link']}
                  size="lg"
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </CopyToClipboard>
          </Box>
          <Box my={4}>
            Even though our profile format is really appealing to the talents,
            merely having a great looking profile is not enough. Make it more
            useful by adding some job ads.
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box mr={2}>
          <Link
            to={paths.companiesDashboard.replace(':slug', params.slug)}
            className={classes.link}
          >
            <Button
              disableElevation
              type="button"
              onClick={onClose}
              variant="contained"
              color="default"
            >
              Dashboard
            </Button>
          </Link>
        </Box>
        <Link
          to={paths.jobsCreate.replace(':slug+', params.slug)}
          className={classes.link}
        >
          <Button
            disableElevation
            type="button"
            onClick={onClose}
            variant="contained"
            color="primary"
          >
            Create job ad
          </Button>
        </Link>
      </Box>
    </Modal>
  );
}

type ValuesProps = {
  items: Value[],
  onAdd: OnAddCompProp,
  onRemove: OnRemoveComProp,
  onEdit: OnEditCompProp,
  onMove: OnMoveCompProp,
};

type CompanyLogoProps = {
  brand: ?string,
  logo: ?Image,
  onUpload: (Image) => Promise<Image>,
};
function CompanyLogo({
  isEdit,
  brand,
  logo,
  onUpload,
}: CompanyLogoProps & EditableProps) {
  const [, dispatch] = useStateContainer();
  function onClick() {
    dispatch(
      enqueueModal(
        <UploadProductGalleryImage
          autoClose={false}
          onUpload={({ url, blob }, setDisabled) => {
            setDisabled(true);
            onUpload({ id: Math.random(), src: url, blob })
              .catch((error) => {
                dispatch(enqueueNotification(<Error>{error.message}</Error>));
              })
              .finally(() => {
                setDisabled(false);
                dispatch(dequeueModal());
              });
          }}
        />
      )
    );
  }
  return isEdit ? (
    <UnderCoverContainer uncover="CompanyLogo">
      <Box position="relative">
        {logo ? (
          <img
            style={{ maxWidth: 130, maxHeight: 80 }}
            src={logo.src}
            alt={brand}
          />
        ) : (
          <Button
            onClick={onClick}
            color="edit"
            variant="contained"
            type="button"
            disableElevation
          >
            Upload logo
          </Button>
        )}
        <UnderCover uncoveredBy="CompanyLogo">
          {logo ? (
            <Box
              position="absolute"
              top="50%"
              left="50%"
              style={{ transform: `translate(-50%, -50%)` }}
            >
              <Button
                onClick={onClick}
                color="edit"
                variant="contained"
                type="button"
                disableElevation
              >
                Update
              </Button>
            </Box>
          ) : null}
        </UnderCover>
      </Box>
    </UnderCoverContainer>
  ) : (
    <Box>
      {logo ? (
        <img
          style={{ maxWidth: 130, maxHeight: 80 }}
          src={logo.src}
          alt={brand}
        />
      ) : null}
    </Box>
  );
}

function Navigation({
  logo,
  onUpload,
  hasMainImage,
  brand,
  isEdit,
  showProduct,
  showCompany,
  showPhotos,
  showCultureAndPerks,
  jobs,
  jobsLoading,
  showJobs,
}: CompanyLogoProps &
  EditableProps & {
    hasMainImage: boolean,
    showProduct: boolean,
    showCompany: boolean,
    showPhotos: boolean,
    showCultureAndPerks: boolean,
    jobs: any[],
    jobsLoading: boolean,
    showJobs: boolean,
  }) {
  const theme = useTheme();
  const mq = useMQ();
  const c = useStyles();

  return (
    <Box
      display="flex"
      flexDirection={mq.xl ? 'row' : 'column'}
      alignItems={mq.xl ? 'center' : 'flex-start'}
      justifyContent="space-between"
      my={!mq.md ? 4 : 8}
    >
      <Box
        display="flex"
        alignItems="center"
        flex={2}
        mb={mq.xl || !mq.md ? 0 : 2}
      >
        <CompanyLogo
          isEdit={isEdit}
          logo={logo}
          brand={brand}
          onUpload={onUpload}
        />
      </Box>
      <HideIf cond={() => !mq.md || (!isEdit && !hasMainImage)}>
        <Box flex={3}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {showProduct ? (
              <ScrollLink
                to="product"
                className={cls(c.nav_link, c.u_mr)}
                smooth
                offset={-100}
                duration={500}
              >
                Product
              </ScrollLink>
            ) : null}
            {showCompany ? (
              <ScrollLink
                to="company"
                className={cls(c.nav_link, c.u_mr)}
                smooth
                offset={-100}
                duration={500}
              >
                Company
              </ScrollLink>
            ) : null}
            {showPhotos ? (
              <ScrollLink
                to="photos"
                className={cls(c.nav_link, c.u_mr)}
                smooth
                offset={-100}
                duration={500}
              >
                Photos
              </ScrollLink>
            ) : null}
            {showCultureAndPerks ? (
              <ScrollLink
                to="perks"
                className={cls(c.nav_link, c.u_mr)}
                smooth
                offset={-100}
                duration={500}
              >
                {!mq.lg ? 'Culture' : 'Culture & Perks'}
              </ScrollLink>
            ) : null}
            {showJobs ? (
              <ScrollLink
                to="jobs"
                className={cls(c.nav_link)}
                smooth
                offset={-120}
                duration={500}
              >
                {jobsLoading ? (
                  <div>
                    <Loader />
                  </div>
                ) : (
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        marginRight: 5,
                        fontWeight: theme.typography.fontWeightBold,
                      }}
                    >
                      Jobs
                    </span>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 25,
                        height: 25,
                        background: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        borderRadius: '50%',
                        fontWeight: theme.typography.fontWeightBold,
                      }}
                    >
                      {jobs.filter((j) => j.isPublic).length}
                    </span>
                  </span>
                )}
              </ScrollLink>
            ) : null}
          </Box>
        </Box>
      </HideIf>
    </Box>
  );
}

type OverviewProps = { value: string, update: (value: string) => void };
function Overview({ value, update, isEdit }: OverviewProps & EditableProps) {
  const mq = useMQ();
  const theme = useTheme();
  const internalOnChange2 = (v: string) => update(v);

  return (
    <Box mb={mq.lg ? 0 : 2}>
      {isEdit ? (
        <RichEditor value={value} onChange={internalOnChange2} />
      ) : (
        <Box
          style={{ fontSize: theme.typography.pxToRem(16) }}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      )}
    </Box>
  );
}

type CoverImageProps = {
  canBeEdited: boolean,
  image: ?Image,
  onEditClick: () => void,
  onUpload: (Image) => Promise<Image>,
  companySlug?: string | null,
};
function CoverImage({
  canBeEdited,
  image,
  isEdit,
  isPreview,
  onEditClick,
  onUpload,
  companySlug,
}: CoverImageProps & EditableProps & PreviewProps) {
  const history = useHistory();
  const HEIGHT = 440;
  const theme = useTheme();
  const [, dispatch] = useStateContainer();
  const openUploadImageDialog = () =>
    dispatch(
      enqueueModal(
        <UploadMainImage
          autoClose={false}
          onUpload={({ url, blob }, setDisabled) => {
            setDisabled(true);
            onUpload({ id: Math.random(), src: url, blob })
              .catch((error) => {
                dispatch(enqueueNotification(<Error>{error.message}</Error>));
              })
              .finally(() => {
                setDisabled(false);
                dispatch(dequeueModal());
              });
          }}
        />
      )
    );
  const hide = !isEdit && !image;
  const onDashboardClick = () => {
    history.push({
      pathname: paths.companiesDashboard.replace(':slug', companySlug),
      search: '',
    });
  };
  return (
    <UnderCoverContainer uncover="CoverImage">
      <Box height={hide ? 0 : HEIGHT} position="relative">
        <Box
          position="fixed"
          display="flex"
          alignItems="center"
          top={100}
          right={theme.spacing(5)}
          zIndex={1}
        >
          {canBeEdited && !isEdit && companySlug ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              mr={1}
            >
              <Button
                color="default"
                variant="contained"
                type="button"
                onClick={onDashboardClick}
              >
                Company Dashboard
              </Button>
            </Box>
          ) : null}
          {canBeEdited && !isEdit ? (
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Button
                color="edit"
                variant="contained"
                type="button"
                onClick={onEditClick}
              >
                {isPreview ? 'Continue editing' : 'Edit Profile'}
              </Button>
            </Box>
          ) : null}
        </Box>
        <HideIf cond={() => hide}>
          <Box
            position="relative"
            width="100%"
            height={HEIGHT}
            bgcolor={theme.palette.grey[300]}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              backgroundImage: `url("${(image && image.src) || ''}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            {isEdit ? (
              <UnderCover uncoveredBy="CoverImage">
                <Button
                  color="edit"
                  variant="contained"
                  type="button"
                  size="large"
                  disableElevation
                  onClick={openUploadImageDialog}
                >
                  Upload Cover Photo
                </Button>
              </UnderCover>
            ) : null}
          </Box>
        </HideIf>
      </Box>
    </UnderCoverContainer>
  );
}

type ProductOverviewProps = {
  images: Image[],
  product: string,
  onAdd: (Image) => Promise<Image>,
  onRemove: (CarouselItem) => void,
  onMove: ({ drag: CarouselItem, drop: CarouselItem }) => void,
  updateProduct: (string) => void,
};
function ProductOverview({
  isEdit,
  images,
  product,
  onAdd,
  onRemove,
  onMove,
  updateProduct,
}: ProductOverviewProps & EditableProps) {
  const theme = useTheme();
  const productOnChange = (v: string) => updateProduct(v);

  const [, dispatch] = useStateContainer();
  const addImage = () => {
    dispatch(
      enqueueModal(
        <UploadProductGalleryImage
          autoClose={false}
          initialCropStateOnUpload={{
            aspect: 4 / 3,
            unit: '%',
            width: 100,
            x: 0,
            y: 0,
          }}
          onUpload={({ url, blob }, setDisabled) => {
            setDisabled(true);
            onAdd({ id: Math.random(), src: url, blob })
              .catch((error) => {
                dispatch(enqueueNotification(<Error>{error.message}</Error>));
              })
              .finally(() => {
                setDisabled(false);
                dispatch(dequeueModal());
              });
          }}
        />
      )
    );
  };
  const carouselItems = useMemo(() => {
    return images.map((image) => ({
      id: image.id,
      dndType: CompDndPropType.products,
      data: { src: image.src },
    }));
  }, [images]);

  return (
    <Box>
      <Box>
        {/* eslint-disable-next-line */}
        {isEdit ? (
          <CarouselEditor
            items={carouselItems}
            onAdd={addImage}
            onDelete={onRemove}
            onMove={onMove}
          />
        ) : (
          <Carousel items={carouselItems} showArrows={carouselItems.length > 2}>
            {carouselItems.map((item) => {
              return (
                <div key={item.data.src} style={{ position: 'relative' }}>
                  <div
                    style={{
                      flexShrink: 0,
                      width: ITEM_WIDTH,
                      height: ITEM_HEIGHT,
                      backgroundImage: `url("${item.data.src}")`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      border: 0,
                      borderRadius: theme.shape.borderRadius,
                    }}
                  />
                </div>
              );
            })}
          </Carousel>
        )}
      </Box>
      {isEdit ? (
        <Box mt={2}>
          <Form validate={() => ({})} onSubmit={() => ({})}>
            {() => (
              <RichEditor value={product} onChange={productOnChange} />
              // <TextareaAutosize
              //   style={{ width: '100%', color: txtColor(theme, isEdit) }}
              //   className={c.textarea}
              //   placeholder="Product description. Use this field to describe your product."
              //   name="product-overview"
              //   onChange={productOnChange}
              //   onBlur={onBlur}
              //   value={__value}
              //   rowsMin={3}
              // />
            )}
          </Form>
        </Box>
      ) : (
        <Box mt={2} dangerouslySetInnerHTML={{ __html: product }} />
      )}
    </Box>
  );
}

type ClientsProps = {
  clientImages: Image[],
  onRemove: (Image) => void,
  onAdd: (Image) => Promise<Image>,
};
function Clients({
  isEdit,
  clientImages,
  onRemove,
  onAdd,
}: ClientsProps & EditableProps) {
  const mq = useMQ();
  const theme = useTheme();
  let cols = 2;
  if (mq.md) cols = 2;
  if (mq.lg) cols = 4;
  if (mq.xl) cols = 6;
  if (mq.xxl) cols = 8;
  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(${cols}, 1fr)`}
      style={{
        gridRowGap: theme.spacing(2),
        columnGap: theme.spacing(2),
      }}
    >
      {clientImages.map((img) => {
        return (
          <InfoBoxImage
            key={img.id}
            isEdit={isEdit}
            image={img}
            onRemove={onRemove}
          />
        );
      })}
      {isEdit ? <InfoBoxImageUpload onUpload={onAdd} /> : null}
    </Box>
  );
}

type ImageInfoBoxProps = {
  image: Image,
  onRemove: (Image) => void,
};
function InfoBoxImage({
  isEdit,
  image,
  onRemove,
}: ImageInfoBoxProps & EditableProps) {
  const mq = useMQ();
  const theme = useTheme();
  let MAX_WIDTH = null;
  if (mq.xl) MAX_WIDTH = 140;

  return (
    <UnderCoverContainer uncover="InfoBoxImage">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        minWidth={140}
        maxWidth={MAX_WIDTH}
        height={100}
        borderRadius={theme.shape.borderRadius}
        border={`1px solid ${theme.palette.grey[200]}`}
        overflow="hidden"
        mr={2}
      >
        {/* <Box
          position="absolute"
          top="0"
          left="0"
          // bgcolor="rgba(0,0,0,0.1)"
          width="100%"
          height="100%"
        /> */}
        <img
          alt="Client"
          src={image.src}
          height="80%"
          style={{ borderRadius: theme.shape.borderRadius }}
        />
        <UnderCover uncoveredBy="InfoBoxImage">
          <Box
            position="absolute"
            bottom={theme.spacing(2)}
            right={theme.spacing(2)}
          >
            {isEdit ? (
              <EditControls
                showMoveIcon={false}
                onRemove={() => onRemove(image)}
              />
            ) : null}
          </Box>
        </UnderCover>
      </Box>
    </UnderCoverContainer>
  );
}

type InfoBoxImageUploadProps = {
  onUpload: (Image) => Promise<Image>,
};
function InfoBoxImageUpload({ onUpload }: InfoBoxImageUploadProps) {
  const theme = useTheme();
  const [, dispatch] = useStateContainer();

  const uploadDialog = () => {
    dispatch(
      enqueueModal(
        <UploadProductGalleryImage
          autoClose={false}
          initialCropStateOnUpload={{
            aspect: 1,
            width: 100,
            x: 0,
            y: 0,
            unit: '%',
          }}
          onUpload={({ url, blob }, setDisabled) => {
            setDisabled(true);
            onUpload({
              id: Math.random(),
              src: url,
              blob,
            })
              .catch((error) => {
                dispatch(enqueueNotification(<Error>{error.message}</Error>));
              })
              .finally(() => {
                setDisabled(false);
                dispatch(dequeueModal());
              });
          }}
        />
      )
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ border: `1px solid ${theme.palette.grey[300]}` }}
      minWidth={140}
      height={100}
      mr={2}
      borderRadius={theme.shape.borderRadius}
    >
      <Button
        type="button"
        variant="contained"
        color="edit"
        disableElevation
        onClick={uploadDialog}
      >
        Upload
      </Button>
    </Box>
  );
}

type PhotosProps = {
  onAdd: (Image) => Promise<Image>,
  onMove: ({ drag: Image, drop: Image }) => void,
  onRemove: (Image) => void,
  photos: Image[],
};
function Photos({
  isEdit,
  onAdd,
  onMove,
  onRemove,
  photos,
}: PhotosProps & EditableProps) {
  const mq = useMQ();
  const classes = useStyles();
  const [, dispatch] = useStateContainer();
  const theme = useTheme();
  const [lightbox, setLightbox] = useState({ open: false, idx: 0 });
  const open = (idx?: number = 0) => {
    if (!isEdit) setLightbox({ open: true, idx });
  };
  const close = () => setLightbox((p) => ({ ...p, open: false }));
  const prev = useCallback(() => {
    setLightbox((p) => ({
      ...p,
      idx: p.idx - 1 < 0 ? photos.length - 1 : p.idx - 1,
    }));
  }, [photos]);
  const next = useCallback(() => {
    setLightbox((p) => ({
      ...p,
      idx: p.idx + 1 > photos.length - 1 ? 0 : p.idx + 1,
    }));
  }, [photos]);
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.keyCode === 39) next();
      if (e.keyCode === 37) prev();
      if (e.keyCode === 27) close();
    };
    window.addEventListener('keyup', handler);
    return () => window.removeEventListener('keyup', handler);
  }, [next, prev]);
  const upload = () => {
    dispatch(
      enqueueModal(
        <UploadProductGalleryImage
          autoClose={false}
          initialCropStateOnUpload={{
            aspect: undefined,
            width: 100,
            height: 100,
            x: 0,
            y: 0,
            unit: '%',
          }}
          onUpload={({ url, blob }, setDisabled) => {
            setDisabled(true);
            onAdd({ id: Math.random(), src: url, blob })
              .catch((error) => {
                dispatch(enqueueNotification(<Error>{error.message}</Error>));
              })
              .finally(() => {
                setDisabled(false);
                dispatch(dequeueModal());
              });
          }}
        />
      )
    );
  };
  const gap = theme.spacing(2);
  const fillCell = (pos: number, overlay?: boolean = false) => {
    if (photos[pos] !== undefined)
      return (
        <Photo
          isEdit={isEdit}
          image={photos[pos]}
          onMove={onMove}
          onClick={() => open(pos)}
          onRemove={onRemove}
          overlay={overlay ? 'View all' : undefined}
        />
      );
    if (photos.length === pos && isEdit)
      return (
        <Box
          height="100%"
          onClick={upload}
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            cursor: 'pointer',
            // backgroundColor: theme.palette.grey[200],
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.grey[500],
            fontWeight: theme.typography.fontWeightBold,
          }}
          className={classes.editBorder}
        >
          Add new image
        </Box>
      );
    return null;
  };

  return (
    <Box>
      {mq.lg || isEdit ? (
        <Box
          height={500}
          display="grid"
          gridTemplateColumns="3fr 2fr"
          gridColumnGap={gap}
        >
          <Box>{fillCell(0)}</Box>
          <Box display="grid" gridTemplateRows="4fr 2fr" gridRowGap={gap}>
            <Box>{fillCell(1)}</Box>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gridColumnGap={gap}
            >
              <Box>{fillCell(2)}</Box>
              <Box position="relative">
                {fillCell(3, !isEdit && photos.length > 4 /* overlay */)}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display="grid"
          gridColumnGap={gap}
          gridRowGap={gap}
          gridTemplateColumns="1fr 1fr"
        >
          {photos.slice(0, 4).map((photo, pos) => (
            <Box key={photo.id}>
              <Photo
                isEdit={false}
                image={photo}
                onMove={onMove}
                onClick={() => open(pos)}
                onRemove={onRemove}
              />
            </Box>
          ))}
        </Box>
      )}
      {isEdit && photos.length >= 4 ? (
        <Box>
          <Box my={2}>Following images will be shown only in gallery view.</Box>
          <Box
            display="grid"
            gridTemplateColumns="repeat(6, 1fr)"
            gridRowGap={theme.spacing(2)}
            gridColumnGap={theme.spacing(2)}
          >
            {photos.slice(4).map((photo) => {
              return (
                <Box
                  maxWidth={300}
                  minWidth={150}
                  maxHeight={300}
                  minHeight={150}
                  key={photo.id}
                >
                  <Photo
                    key={photo.id}
                    isEdit={isEdit}
                    image={photo}
                    onMove={onMove}
                    onRemove={onRemove}
                  />
                </Box>
              );
            })}
            <Box
              minHeight={150}
              height="100%"
              onClick={upload}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                cursor: 'pointer',
                backgroundColor: theme.palette.grey[200],
                borderRadius: theme.shape.borderRadius,
                color: theme.palette.grey[500],
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              Add new image
            </Box>
          </Box>
        </Box>
      ) : null}
      {lightbox.open ? (
        <Box
          zIndex={10}
          position="fixed"
          top={0}
          left={0}
          height="100vh"
          width="100vw"
          style={{
            backgroundColor: `rgba(255,255,255,0.9)`,
            userSelect: 'none',
          }}
          py={8}
          px={6}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            height="100%"
            width="100%"
          >
            <Box
              position="fixed"
              top={theme.spacing(4)}
              right={theme.spacing(4)}
              onClick={close}
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon
                icon={['fal', 'times']}
                style={{ fontSize: 32, color: '#000' }}
              />
            </Box>
            <Box
              position="fixed"
              top="50%"
              left={theme.spacing(4)}
              style={{ transform: `translateY(-50%)`, cursor: 'pointer' }}
              onClick={prev}
            >
              <FontAwesomeIcon
                icon={['fal', 'arrow-left']}
                style={{ fontSize: 32, color: '#000' }}
              />
            </Box>
            <Box
              position="fixed"
              top="50%"
              right={theme.spacing(4)}
              style={{ transform: `translateY(-50%)`, cursor: 'pointer' }}
              onClick={next}
            >
              <FontAwesomeIcon
                icon={['fal', 'arrow-right']}
                style={{ fontSize: 32, color: '#000' }}
              />
            </Box>
            <Box
              position="fixed"
              top={theme.spacing(4)}
              left={theme.spacing(4)}
              fontWeight={theme.typography.fontWeightBold}
              fontSize={theme.typography.pxToRem(26)}
            >
              <span>
                <span>{lightbox.idx + 1}</span>
                <span> / </span>
                <span>{photos.length}</span>
              </span>
            </Box>
            <img
              src={photos[lightbox.idx].src}
              alt="item"
              style={{
                borderRadius: theme.shape.borderRadius,
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}

type PhotoProps = {
  image: Image,
  onMove: ({ drag: Image, drop: Image }) => void,
  onRemove: (Image) => void,
  onClick?: (Image) => void,
  overlay?: Node,
};
function Photo({
  isEdit,
  image,
  onMove,
  onRemove,
  onClick,
  overlay,
}: PhotoProps & EditableProps) {
  const theme = useTheme();

  const [, dragRef] = useDrag({
    item: { type: CompDndPropType.photos, ...image },
    end: (drag, monitor) => {
      const drop = monitor.getDropResult();
      if (drag && drop) onMove({ drag, drop });
    },
  });

  // eslint-disable-next-line no-unused-vars
  const [dropProps, dropRef] = useDrop({
    accept: CompDndPropType.photos,
    drop: () => image,
    collect: (monitor) => {
      const drag = monitor.getItem();
      const overSelf = drag && drag.id === image.id;
      return {
        isHovered: !overSelf && monitor.isOver({ shallow: true }),
      };
    },
  });

  return (
    <Box ref={isEdit ? dropRef : null} height="100%">
      <Box
        style={{
          height: '100%',
          border: `3px solid ${
            dropProps.isHovered ? theme.palette.edit.main : 'transparent'
          }`,
        }}
        position="relative"
        ref={isEdit ? dragRef : null}
      >
        <UnderCoverContainer uncover="Photo" style={{ height: '100%' }}>
          {overlay ? (
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                backgroundColor: `rgba(0,0,0,0.7)`,
                borderRadius: theme.shape.borderRadius,
                color: theme.palette.common.white,
                fontWeight: theme.typography.fontWeightMedium,
                cursor: 'pointer',
              }}
              onClick={onClick}
            >
              {overlay}
            </Box>
          ) : null}

          <Box
            onClick={onClick}
            style={{
              cursor: isEdit ? '' : 'pointer',
              backgroundImage: `url("${image.src}")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              minHeight: 150,
              width: '100%',
              height: '100%',
              borderRadius: theme.shape.borderRadius,
            }}
          />

          {isEdit ? (
            <UnderCover uncoveredBy="Photo">
              <Box
                position="absolute"
                bottom={theme.spacing(2)}
                right={theme.spacing(2)}
              >
                <EditControls onRemove={() => onRemove(image)} />
              </Box>
            </UnderCover>
          ) : null}
        </UnderCoverContainer>
      </Box>
    </Box>
  );
}

type CultureAndPerksProps = {
  items: CultureAndPerks[],
  onEdit: OnEditCompProp,
  onAdd: OnAddCompProp,
  onRemove: OnRemoveComProp,
  onMove: OnMoveCompProp,
};
function CulturePerks({
  isEdit,
  items,
  onEdit,
  onAdd,
  onRemove,
  onMove,
}: CultureAndPerksProps & EditableProps) {
  const mq = useMQ();
  const theme = useTheme();
  const add: OnAddCompProp = (values) =>
    onAdd({
      ...values,
      id: Math.random(),
      dndType: CompDndPropType.cultureAndPerks,
    });

  let cols = 1;
  if (mq.md) cols = 2;
  if (mq.lg) cols = 3;

  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(${cols}, 1fr)`}
      style={{
        gridRowGap: theme.spacing(2),
        columnGap: theme.spacing(2),
      }}
    >
      {items.map((cap) => {
        return (
          <CulturePerksInfoBox
            key={cap.id}
            isEdit={isEdit}
            item={cap}
            onEdit={onEdit}
            onMove={onMove}
            onRemove={() => onRemove(cap)}
          />
        );
      })}
      {isEdit ? <InfoBoxForm onSubmit={add} /> : null}
    </Box>
  );
}

type CulturePerksInfoBoxProps = {
  item: Highlight,
  onEdit: OnEditCompProp,
  onRemove: OnRemoveComProp,
  onMove: OnMoveCompProp,
};
function CulturePerksInfoBox({
  isEdit,
  item,
  onEdit,
  onRemove,
  onMove,
}: CulturePerksInfoBoxProps & EditableProps) {
  const dndType = CompDndPropType.cultureAndPerks;
  const [editing, setEditing] = useState(false);
  const theme = useTheme();

  const [, dragRef] = useDrag({
    item: { type: dndType, ...item },
    end: (drag, monitor) => {
      const drop = monitor.getDropResult();
      if (drag && drop) onMove({ drag, drop });
    },
  });

  const [dropProps, dropRef] = useDrop({
    accept: dndType,
    drop: () => item,
    collect: (monitor) => {
      const drag = monitor.getItem();
      const overSelf = drag && drag.id === item.id;
      return {
        isHovered: !overSelf && monitor.isOver({ shallow: true }),
      };
    },
  });

  const edit = () => setEditing(true);
  const cancelEditing = () => setEditing(false);

  return editing ? (
    <InfoBoxForm
      onSubmit={onEdit}
      onClose={cancelEditing}
      editingItem={item}
      initEditing
    />
  ) : (
    <UnderCoverContainer uncover="InfoBox">
      <Box ref={isEdit ? dropRef : null}>
        <Box ref={isEdit ? dragRef : null}>
          <Box
            overflow="hidden"
            minHeight={150}
            borderRadius={theme.shape.borderRadius}
            style={{
              border: `3px solid ${
                dropProps.isHovered ? theme.palette.edit.main : 'transparent'
              }`,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
              px={1}
              height={40}
            >
              <TextColorMode isEdit={isEdit}>
                <Box display="inline-block">
                  <FontAwesomeIcon
                    icon={['fal', item.icon]}
                    style={{ fontSize: theme.typography.pxToRem(21) }}
                  />
                </Box>
              </TextColorMode>
              <UnderCover uncoveredBy="InfoBox">
                {isEdit ? (
                  <EditControls onEdit={edit} onRemove={onRemove} />
                ) : null}
              </UnderCover>
            </Box>
            <TextColorMode isEdit={isEdit}>
              <Box
                fontWeight="fontWeightBold"
                fontSize={theme.typography.pxToRem(18)}
              >
                {item.title}
              </Box>
              <Box>{item.content}</Box>
            </TextColorMode>
          </Box>
        </Box>
      </Box>
    </UnderCoverContainer>
  );
}

function Values({
  isEdit,
  items,
  onAdd,
  onRemove,
  onEdit,
  onMove,
}: ValuesProps & EditableProps) {
  const [adding, setAdding] = useState(false);
  const theme = useTheme();

  return (
    <Box>
      {/* Show value placeholder */}
      {adding || items.length > 0 || !isEdit ? null : (
        <Box
          color="grey.500"
          bgcolor="grey.100"
          padding={3}
          borderRadius={theme.shape.borderRadius}
          mb={2}
        >
          <Box
            fontWeight="fontWeightBold"
            fontSize={theme.typography.pxToRem(18)}
          >
            <Box>Write the 3-7 core company values</Box>
          </Box>
          <Box mt={1}>(optionally) describe each value with one sentence.</Box>
        </Box>
      )}

      {/* List the values */}
      {items.map((v) => (
        <ValueItem
          key={v.id}
          item={v}
          isEdit={isEdit}
          onEdit={onEdit}
          onRemove={onRemove}
          onMove={onMove}
        />
      ))}

      {!adding && isEdit ? (
        <Box
          border={`1px solid ${theme.palette.grey[300]}`}
          borderRadius={theme.shape.borderRadius}
          padding={3}
          mt={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            disableElevation
            color="edit"
            variant="contained"
            type="button"
            onClick={() => setAdding(true)}
          >
            Add
          </Button>
        </Box>
      ) : null}

      {adding && isEdit ? (
        <ValuesForm onSubmit={onAdd} onClose={() => setAdding(false)} />
      ) : null}
    </Box>
  );
}

type ValueItemProps = {
  item: Value,
  onEdit: OnEditCompProp,
  onRemove: OnRemoveComProp,
  onMove: OnMoveCompProp,
};
function ValueItem({
  isEdit,
  item,
  onEdit,
  onRemove,
  onMove,
}: ValueItemProps & EditableProps) {
  const theme = useTheme();
  const [editing, setEditing] = useState(false);
  const dndType = CompDndPropType.values;

  const [, dragRef] = useDrag({
    item: { type: dndType, ...item },
    end: (drag, monitor) => {
      const drop = monitor.getDropResult();
      if (drag && drop) onMove({ drag, drop });
    },
  });

  const [dropProps, dropRef] = useDrop({
    accept: dndType,
    drop: () => item,
    collect: (monitor) => {
      const drag = monitor.getItem();
      const overSelf = drag && drag.id === item.id;
      return {
        isHovered: !overSelf && monitor.isOver({ shallow: true }),
      };
    },
  });

  if (editing)
    return (
      <ValuesForm
        key={item.id}
        onSubmit={onEdit}
        onClose={() => setEditing(false)}
        editingItem={item}
      />
    );
  return (
    <UnderCoverContainer key={item.id} uncover="Values">
      <Box ref={isEdit ? dropRef : null}>
        <Box ref={isEdit ? dragRef : null}>
          <Box
            position="relative"
            bgcolor="grey.100"
            padding={3}
            borderRadius={theme.shape.borderRadius}
            mb={2}
            style={{
              border: `3px solid ${
                dropProps.isHovered ? theme.palette.edit.main : 'transparent'
              }`,
            }}
          >
            <UnderCover uncoveredBy="Values">
              {isEdit ? (
                <Box
                  position="absolute"
                  top={theme.spacing(2)}
                  right={theme.spacing(2)}
                >
                  <EditControls
                    onEdit={() => setEditing(true)}
                    onRemove={() => onRemove(item)}
                  />
                </Box>
              ) : null}
            </UnderCover>

            <TextColorMode isEdit={isEdit}>
              <Box
                fontWeight="fontWeightBold"
                fontSize={theme.typography.pxToRem(18)}
              >
                <Box>{item.title}</Box>
              </Box>
              {item.content ? <Box mt={1}>{item.content}</Box> : null}
            </TextColorMode>
          </Box>
        </Box>
      </Box>
    </UnderCoverContainer>
  );
}

type ValuesFormProps = {
  onSubmit: OnAddCompProp | OnEditCompProp,
  onClose?: () => void,
  editingItem?: CompProp,
};
function ValuesForm({ onSubmit, onClose, editingItem }: ValuesFormProps) {
  const initValues = editingItem || {
    id: Math.random(),
    title: '',
    content: '',
  };
  const [title, setTitle] = useState(initValues.title);
  const [content, setContent] = useState(initValues.content || '');
  const [errors, setErrors] = useState<{ title: string }>({
    title: '',
    content: '',
  });
  const c = useStyles();
  const theme = useTheme();
  const onCancel = () => {
    if (typeof onClose === 'function') onClose();
  };
  const submit = (e: SyntheticEvent<HTMLFormElement>) => {
    setErrors({ title: '' });
    e.preventDefault();
    // validate
    const err = { title: '', content: '' };
    let hasErrors = false;

    if (!title.length) {
      err.title = 'This field is required';
      hasErrors = true;
    }

    if (hasErrors) {
      setErrors(err);
    } else {
      // submit
      onSubmit({ id: initValues.id, title, content });
      setTitle('');
      setContent('');
      if (editingItem) onCancel();
    }
  };
  const update = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name } = e.target;
    if (name === 'title') setTitle(e.target.value);
    if (name === 'content') setContent(e.target.value);
  };

  return (
    <Box
      border={`1px solid ${theme.palette.grey[300]}`}
      padding={2}
      borderRadius={theme.shape.borderRadius}
      boxShadow="0 1px 7px 3px rgba(0,0,0,0.05)"
    >
      <form onSubmit={submit}>
        <Box className={c.u_mbSmall}>
          <Box className={c.u_mbSmall}>
            <input
              value={title}
              onChange={update}
              type="text"
              className={errors.title ? c.inputWithError : c.input}
              style={{ color: txtColor(theme, true) }}
              placeholder="Title"
              name="title"
              id="values_title"
            />
            {errors.title ? (
              <Box fontSize={theme.typography.pxToRem(12)} color="error.main">
                {errors.title}
              </Box>
            ) : null}
          </Box>
          <TextareaAutosize
            id="values_content"
            rowsMin={2}
            className={c.textarea}
            style={{ color: txtColor(theme, true) }}
            name="content"
            value={content}
            onChange={update}
            placeholder="(optionally) describe each value with one sentence."
          />
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="row-reverse"
        >
          <Button
            color="edit"
            variant="outlined"
            type="submit"
            size="small"
            disableElevation
          >
            <FontAwesomeIcon icon={['fal', editingItem ? 'save' : 'plus']} />
            <Box marginLeft={1} component="span">
              {editingItem ? 'Update' : 'Add'}
            </Box>
          </Button>
          <Box marginRight={1}>
            <Button
              color="white"
              variant="contained"
              type="button"
              size="small"
              disableElevation
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

function Jobs({
  brand,
  isEdit,
  jobs,
  jobsLoading,
}: {
  brand?: string | null,
  jobs: Array<Job<any>>,
  jobsLoading: boolean,
} & EditableProps) {
  const theme = useTheme();
  const [
    {
      auth: { user, isAdmin },
    },
  ] = useStateContainer();

  const sortedJobs = jobs.reduce(
    (acc, job) => {
      if (job.isPublic) acc.public.push(job);
      else acc.private.push(job);

      return acc;
    },
    { public: [], private: [] }
  );

  if (isEdit)
    return (
      <ScrollElement name="jobs">
        <Box
          width="100%"
          height="200px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="edit.main"
          bgcolor="grey.100"
          borderRadius={theme.shape.borderRadius}
        >
          Add job ads from your dashboard
        </Box>
      </ScrollElement>
    );

  if (jobsLoading) {
    return <Loader />;
  }

  if (
    (isAdmin(user) && !jobs.length) ||
    (!isAdmin(user) && !sortedJobs.public.length)
  ) {
    return (
      <ScrollElement name="jobs">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={8}
          style={{
            border: `3px dashed ${theme.palette.primary.light}`,
            borderRadius: 5,
            color: theme.palette.primary.main,
          }}
          fontSize={18}
        >
          <span
            style={{
              fontWeight: theme.typography.fontWeightBold,
              display: 'inline-block',
              marginRight: 5,
              background: theme.palette.primary.lightest,
              padding: theme.spacing(1, 2),
              borderRadius: 10,
            }}
          >
            {brand}
          </span>
          <span>has no public jobs.</span>
        </Box>
      </ScrollElement>
    );
  }

  const renderableJobs = isAdmin(user) ? jobs : sortedJobs.public;

  return (
    <ScrollElement name="jobs">
      {renderableJobs.map((job) => {
        const jobLink = {
          pathname: paths.jobInCompanyProfile
            .replace(':companySlug', job.company.slug)
            .replace(':slug+', job.slug),
          search: '',
          state: { skipScrollTop: true },
        };
        return <JobCard key={job.id} job={job} jobLink={jobLink} />;
      })}
    </ScrollElement>
  );
}
