// @flow
import React, { useCallback, useEffect } from 'react';
import type { Node } from 'react';
import { Snackbar } from '@material-ui/core';
import { ErrorSnackbarContent } from '../../basic/StatusSnackbar';
import { useStateContainer } from '../../../core/context/StateContainer';
import { DEQUEUE_NOTIFICATION } from '../actions';

const AUTOHIDE_TIMEOUT = 3000;

type Props = {
  children: Node,
  vertical?: 'top' | 'bottom',
  horizontal?: 'left' | 'center' | 'right',
  disableAutoHide?: boolean,
  onClose?: () => void,
};

function Error({
  children,
  vertical,
  horizontal,
  disableAutoHide,
  onClose = () => {},
  ...rest
}: Props) {
  const [, dispatch] = useStateContainer();

  useEffect(() => {
    if (disableAutoHide) {
      return;
    }

    const to = setTimeout(() => {
      dispatch({ type: DEQUEUE_NOTIFICATION });
    }, AUTOHIDE_TIMEOUT);

    return clearTimeout.bind(undefined, to); //eslint-disable-line
  }, [disableAutoHide, dispatch]);

  const handleClose = useCallback(() => {
    dispatch({ type: DEQUEUE_NOTIFICATION });
    onClose();
  }, [dispatch, onClose]);

  return (
    <Snackbar open anchorOrigin={{ vertical, horizontal }} {...rest}>
      <ErrorSnackbarContent message={children} onClose={handleClose} />
    </Snackbar>
  );
}

Error.defaultProps = {
  vertical: 'bottom',
  horizontal: 'right',
  onClose: () => {},
  disableAutoHide: false,
};

export { Error };
