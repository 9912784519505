// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useAuth } from '../../AuthContext';
import { AuthPopup } from '../AuthPopup';
import { useRegisterPopup } from './useRegisterPopup';

const useStyles = makeStyles((theme) => {
  return {
    linkBtn: {
      border: 'none',
      background: 'transparent',
      padding: 0,
      margin: 0,
      textDecoration: 'underline',
      color: theme.palette.grey[700],
      cursor: 'pointer',
      textAlign: 'center',

      '&:hover': {
        textDecoration: 'none',
      },
    },
    forgottenPassBtn: {
      marginBottom: theme.spacing(1),
    },
  };
});

export function RegisterPopup() {
  const { isRegisterPopupVisible, hideAuthPopup, showAuthPopup } = useAuth();
  const { onSubmit, submitError, submitLoading } = useRegisterPopup();
  const classes = useStyles();

  const onClose = () => hideAuthPopup('register');

  const onLoginClick = () => {
    hideAuthPopup('register');
    showAuthPopup('login');
  };

  return (
    <AuthPopup
      consent
      btnLabel="Register"
      error={submitError}
      isVisible={isRegisterPopupVisible}
      onClose={onClose}
      onSubmit={({ email, password, repeatPassword, consent }) =>
        onSubmit({ email, password, repeatPassword, consent })
      }
      title="Register"
      repeatPassword
      loading={submitLoading}
      footer={
        <>
          <button
            type="button"
            className={classes.linkBtn}
            disabled={submitLoading}
            onClick={onLoginClick}
          >
            Already have an account? Login!
          </button>
        </>
      }
    />
  );
}
