// @flow

import React from 'react';
import type { Node } from 'react';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStatusStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1em',
    lineHeight: '1.4em',
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

type Props = {|
  className?: string, // eslint-disable-line react/require-default-props
  message: Node,
  onClose: () => void,
  action?: Node[], // eslint-disable-line react/require-default-props
  variant?: 'success' | 'warning' | 'error' | 'info', // eslint-disable-line react/require-default-props
|};

export function StatusSnackbarContent(props: Props) {
  const classes = useStatusStyles();
  const { className, message, onClose, variant, ...other } = props;
  // $FlowFixMe
  const Icon = variantIcon[variant];
  const messageContent = (
    <span id="client-snackbar" className={classes.message}>
      <Icon className={classNames(classes.icon, classes.iconVariant)} />
      {message}
    </span>
  );

  return (
    <SnackbarContent
      className={classNames(classes[variant], className, classes.margin)}
      aria-describedby="client-snackbar"
      message={messageContent}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

StatusSnackbarContent.defaultProps = {
  className: 'margin',
  variant: 'info',
};

export function SuccessSnackbarContent(props: Props) {
  return <StatusSnackbarContent {...props} variant="success" />;
}

export function ErrorSnackbarContent(props: Props) {
  return <StatusSnackbarContent {...props} variant="error" />;
}

export function WarningSnackbarContent(props: Props) {
  return <StatusSnackbarContent {...props} variant="warning" />;
}

export function InfoSnackbarContent(props: Props) {
  return <StatusSnackbarContent {...props} variant="info" />;
}
