/* eslint-disable no-console */
// @flow
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFeedEvents } from '../../components/feed/FeedEventsContext';
import { paths } from '../../core/constants';

export function FeedEventsPage() {
  const { index } = useParams();
  const { load, feedEvents } = useFeedEvents();

  console.log('in feedeventspage', { feedEvents });

  useEffect(() => {
    const page = Number.isNaN(Number(index)) ? undefined : Number(index);
    load(page);
  }, [index, load]);

  return feedEvents && feedEvents.length
    ? feedEvents.map<any>((feed) => {
        const companySlug = feed.companySlug || '';
        const jobSlug = feed.jobSlug || '';
        const postedDate = new Date(feed.postedAt).toISOString().slice(0, 19);
        const eventType = feed.eventType.toUpperCase();
        const oldValue = feed.oldValue || 'null';
        const newValue = feed.newValue || 'null';
        return (
          <React.Fragment key={`${companySlug}/${jobSlug}`}>
            <a
              key={`${companySlug}/${jobSlug}`}
              href={paths.job.replace(':slug+', `${companySlug}/${jobSlug}`)}
            >
              {oldValue !== newValue
                ? `${postedDate}: ${eventType} ${companySlug}/${jobSlug} | ${oldValue} -> ${newValue}`
                : `${postedDate}: ${eventType} ${companySlug}/${jobSlug}`}
            </a>
            <br />
          </React.Fragment>
        );
      })
    : null;
}
