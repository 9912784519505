// @flow
import dayjs from 'dayjs';
import type { ReferralLinkObject } from '../core/types';
import { sanitizeStagingAndLocalhostUrls } from './sanitizeStagingAndLocalhostUrls';

function buildReferralLinkObject({
  link,
}: {
  link: string,
}): ReferralLinkObject {
  return {
    timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss.SSS ZZ'),
    value: sanitizeStagingAndLocalhostUrls(link),
  };
}

export { buildReferralLinkObject };
