// @flow
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { buildValidateFunc } from '../../../../../utils/buildValidateFunc';
import type { ValidateFunc } from '../../../../../core/types';
import { Error } from '../../../../modals/components/Error';
import { validateConfig } from './validateConfig';
import { login as loginRequest } from '../../../authService';
import { useStateContainer } from '../../../../../core/context/StateContainer';
import { enqueueModal } from '../../../../modals/actions';
import { enqueueNotification } from '../../../../notifications/actions';
import { Error as ErrorNotification } from '../../../../notifications/components/Error';
import { useReferrals } from '../../../../referrals/ReferralsContext';
import { useReferralsBox } from '../../../../menu/components/SideMenu/boxes/ReferralsBox/userReferralsBox';
import { useTracking } from '../../../../tracking/TrackingContext';
import { log } from '../../../../../utils/log';

function useLoginForm() {
  const [
    {
      referrals: { pending },
    },
    dispatch,
  ] = useStateContainer();
  const { track, events } = useTracking();
  const { flushStoredReferralLinks } = useReferrals();
  const { onGetReferralLinkClick } = useReferralsBox();
  const validate: ValidateFunc = buildValidateFunc(
    validateConfig,
    (values, config) => config,
    0
  );
  const trackDontHaveAccountClick = () => track(events.DONT_HAVE_ACCOUNT_CLICK);

  useEffect(
    () => track(events.OPEN_LOGIN_SCREEN),
    [track, events.OPEN_LOGIN_SCREEN]
  );

  async function onFormSubmit({
    email,
    password,
  }: {
    email: string,
    password: string,
  }) {
    // Here we don't need to do anything in .then(), because the Login page is
    // a GuestRoute.
    //
    // When the user logs in, an API.interceptor is firing an event
    // (SET_AUTH_TOKEN), which updates the global state container's
    // [authenticated] property.
    //
    // When this happens, react router does the check for the Login page again
    // if the user is a guest (because the update of the container is
    // rerendering the app).
    //
    // Now the user is not a guest anymore, so the GuestRoute does the redirect
    // for us.
    //
    // No need to redirect anywhere by hand.
    return loginRequest({ email, password })
      .then(() => {
        track(events.LOGIN);
        flushStoredReferralLinks();

        if (pending.getReferralLink) {
          onGetReferralLinkClick();
        }
      })
      .catch((error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          'Unknown login error!';

        if (error.response && error.response.status === 403) {
          return dispatch(
            enqueueNotification(
              <ErrorNotification>
                Invalid credentials. Please, try again.
              </ErrorNotification>
            )
          );
        }

        log(errorMessage, { logLevel: log.logLevels.error, error });

        return dispatch(enqueueModal(<Error>{errorMessage}</Error>));
      });
  }

  return {
    onFormSubmit,
    validate,
    trackDontHaveAccountClick,
  };
}

export { useLoginForm };
