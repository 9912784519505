// @flow
import API from '../../core/rest/API';
import type { ReferralLinkObject } from '../../core/types';

function getReferralLink({
  url,
  ignoreUnauthorized = false,
}: {
  url: string,
  ignoreUnauthorized: boolean,
}) {
  return API.put(
    '/ref/link',
    { url },
    { ignoreUnauthorized, useShortRegistration: true }
  );
}

function trackReferralLinks({ links }: { links: [ReferralLinkObject] }) {
  return API.put('/ref/visit', links);
}

type ComposeDirectReferralRequest = {
  message: string,
  subject: string,
  to: string,
  url: string,
};

function composeDirectReferral({
  message,
  subject,
  to,
  url,
}: ComposeDirectReferralRequest) {
  return API.put('/ref/direct', { message, subject, to, url });
}

function directReferralLinkVisit() {
  return API.post('/ref/direct');
}

export {
  getReferralLink,
  trackReferralLinks,
  composeDirectReferral,
  directReferralLinkVisit,
};
