// @flow
import React, { useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DEBOUNCE_TIME = 500; // ms

type Props = {
  onSearch: (value: string) => void,
  filters: any,
  value: string,
  onChange: (value: string) => void,
  placeholder?: string,
};

const useStyles = makeStyles((theme) => ({
  form: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  searchBar: {
    width: '100%',
    background: 'white',
    borderRadius: 50,
    border: `3px solid ${theme.palette.primary.main}`,
    boxShadow: `0 0 25px -3px rgba(0,0,0,0.2)`,
    fontWeight: 500,
    outline: 'none',
    transition: 'all 0.3s',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',

    '@media screen and (max-width: 840px)': {
      marginBottom: theme.spacing(2),
    },
  },
  input: ({ filters }) => ({
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.fontFamily,
    flex: 1,
    width: filters ? 'auto' : '100%',
    padding: theme.spacing(2, 2, 2, 7),
    height: '100%',
    border: 0,
    outline: 0,
  }),
  filters: {
    display: 'block',
    '@media screen and (max-width: 840px)': {
      display: 'none',
    },
  },
  filtersMobile: {
    display: 'none',
    '@media screen and (max-width: 840px)': {
      display: 'block',
    },
  },
}));

export function SearchBar({
  value,
  filters = null,
  onChange,
  onSearch,
  placeholder,
}: Props) {
  const classes = useStyles({ filters });

  const debouncedSearch = useRef(
    debounce((t: string) => onSearch(t), DEBOUNCE_TIME)
  ).current;

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
        onSearch(value);
      }}
      className={classes.form}
    >
      <Box
        zIndex={1}
        position="absolute"
        top={18}
        left={20}
        color="primary.main"
        fontSize={18}
      >
        <FontAwesomeIcon icon={['fa', 'search']} />
      </Box>
      <div className={classes.searchBar}>
        <input
          type="text"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            debouncedSearch(e.target.value);
          }}
          className={classes.input}
          placeholder={placeholder || 'Type anything...'}
        />
        <div className={classes.filters}>{filters}</div>
      </div>

      <div className={classes.filtersMobile}>{filters}</div>
    </form>
  );
}
