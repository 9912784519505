// @flow
import { useEffect } from 'react';
import { useReferralLinkVisit } from './useReferralLinkVisit';

function ReferralLinkVisit() {
  const { handleReferralLinkVisit } = useReferralLinkVisit();

  useEffect(handleReferralLinkVisit, []);

  return null;
}

export { ReferralLinkVisit };
