// @flow
import { partial } from 'ramda';
import { useStateContainer } from '../../../../core/context/StateContainer';
import { dequeueModal } from '../../../modals/actions';

const useOnboardingModalFrame = () => {
  const [, dispatch] = useStateContainer();
  const onClose = partial(dispatch, [dequeueModal()]);

  return {
    onClose,
  };
};

export { useOnboardingModalFrame };
