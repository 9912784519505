import React, { useEffect } from 'react';

export function MatchMeForm() {
  useEffect(() => {
    document.body.classList.add('hidden');
    return () => document.body.classList.remove('hidden');
  }, []);
  return (
    <div
      style={{
        height: '100vh',
        paddingTop: 40,
        paddingBottom: 70,
        overflow: 'hidden',
      }}
    >
      <iframe
        id="tally-iframe"
        src="https://tally.so/r/mRGyMj?transparentBackground=1"
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Match Me"
      />
    </div>
  );
}
