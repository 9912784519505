// @flow
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '../../../basic/form/Form';
import { MotivationLetter } from './components/MotivationLetter';
import { useDirectApplicationForm } from './useDirectApplicationForm';
import { useStyles } from '../../../basic/form/styles/useStyles';
import { Close } from '../../../basic/form/Close';

const DirectApplicationForm = () => {
  const history = useHistory();
  const classes = useStyles();
  const { onFormSubmit, validate, showEditProfilePrompt } =
    useDirectApplicationForm();

  React.useEffect(showEditProfilePrompt, []);

  return (
    <div className={classes.root}>
      <Close onClick={() => Close.goToRoot(history)} />

      <Form
        onSubmit={onFormSubmit}
        validate={validate}
        formClassNames={classes.form}
      >
        {() => {
          return (
            <div className={classes.formRoot}>
              <div />
              <MotivationLetter />
            </div>
          );
        }}
      </Form>
    </div>
  );
};

export { DirectApplicationForm };
