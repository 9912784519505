// @flow
import {
  validateRequired,
  validateEmail,
} from '../../../../../core/validators';
import type { ValidateFuncBuilderConfig } from '../../../../../core/types';

const validateConfig: ValidateFuncBuilderConfig = {
  email: [validateRequired('email')(), validateEmail('email')()],
};

export { validateConfig };
