import React, { useContext, useEffect } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { PageHead } from '../components/basic/PageHead';
import { CompaniesList } from '../components/companies/list/CompaniesList';
import { Footer } from '../components/Footer';
import { TopMenuContext } from '../components/menu/context/TopMenu';

const useStyles = makeStyles((theme) => ({
  root: { marginTop: 121 },
  title: {
    fontSize: theme.typography.pxToRem(36),
    fontWeight: theme.typography.fontWeightBold,
    paddingTop: 48,
  },
}));

export function Companies() {
  const { setIsWithBackground } = useContext(TopMenuContext);
  const classes = useStyles();

  useEffect(() => {
    setIsWithBackground(true);
    return () => setIsWithBackground(false);
  }, [setIsWithBackground]);

  return (
    <>
      <PageHead
        title="Companies | NobleHire"
        description="Browse companies on NobleHire"
      />
      <Container>
        <div className={classes.root}>
          <CompaniesList />
        </div>
      </Container>
      <Footer />
    </>
  );
}
