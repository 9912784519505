// @flow
import React, { useState } from 'react';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useDrag, useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type {
  Highlight,
  OnAddCompProp,
  OnEditCompProp,
  OnMoveCompProp,
  OnRemoveComProp,
} from './context/CompanyContext';
import type { EditableProps } from './types';
import { useMQ } from '../../useMQ';
import { CompDndPropType } from './context/CompanyContext';
import { UnderCover, UnderCoverContainer } from '../../basic/UnderCover';
import { EditControls } from '../../basic/EditControls';
import { InfoBoxForm } from './InfoBoxForm';

type HighlightsIconSize = 36 | 24;
type HighlightsTitleSize = 18 | 14;
type HighlightsProps = {
  highlights: Highlight[],
  onEdit?: OnEditCompProp,
  onAdd?: OnAddCompProp,
  onRemove?: OnRemoveComProp,
  onMove?: OnMoveCompProp,
  size?: HighlightsIconSize,
  titleSize?: HighlightsTitleSize,
  maxCols?: number,
};
export function Highlights({
  isEdit,
  highlights,
  onAdd,
  onEdit,
  onMove,
  onRemove,
  size = 36,
  titleSize = 18,
  maxCols = 5,
}: HighlightsProps & EditableProps) {
  const mq = useMQ();
  const theme = useTheme();
  let cols = 1;
  if (mq.md) cols = 2;
  if (mq.lg) cols = 3;
  if (mq.xl) cols = 4;
  if (mq.xxl) cols = 5;

  if (cols > maxCols) cols = maxCols;

  const add: OnAddCompProp = (values) => {
    if (typeof onAdd === 'function')
      onAdd({
        ...values,
        id: Math.random(),
        dndType: CompDndPropType.highlights,
      });
  };
  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(${cols}, 1fr)`}
      style={{
        gridRowGap: theme.spacing(2),
        columnGap: theme.spacing(2),
      }}
    >
      {highlights.map((h) => {
        return (
          <HighlightInfoBox
            size={size}
            titleSize={titleSize}
            isEdit={isEdit}
            key={h.id}
            item={h}
            onEdit={(newValues) => {
              if (typeof onEdit === 'function') onEdit(newValues);
            }}
            onRemove={() => {
              if (typeof onRemove === 'function') onRemove(h);
            }}
            onMove={(...data) => {
              if (typeof onMove === 'function') onMove(...data);
            }}
          />
        );
      })}
      {isEdit && highlights.length < 5 ? (
        <InfoBoxForm onSubmit={add} showContentField={false} />
      ) : null}
    </Box>
  );
}

type HighlightInfoBoxProps = {
  item: Highlight,
  onEdit: OnEditCompProp,
  onRemove: OnRemoveComProp,
  onMove: OnMoveCompProp,
  size?: HighlightsIconSize,
  titleSize?: HighlightsTitleSize,
};
function HighlightInfoBox({
  isEdit,
  item,
  onEdit,
  onMove,
  onRemove,
  size,
  titleSize,
}: HighlightInfoBoxProps & EditableProps) {
  const dndType = CompDndPropType.highlights;
  const [editing, setEditing] = useState(false);
  const theme = useTheme();

  const [, dragRef] = useDrag({
    item: { type: dndType, ...item },
    end: (drag, monitor) => {
      const drop = monitor.getDropResult();
      if (drag && drop) onMove({ drag, drop });
    },
  });

  const [dropProps, dropRef] = useDrop({
    accept: dndType,
    drop: () => item,
    collect: (monitor) => {
      const drag = monitor.getItem();
      const overSelf = drag && drag.id === item.id;
      return {
        isHovered: !overSelf && monitor.isOver({ shallow: true }),
      };
    },
  });

  const edit = () => setEditing(true);
  const cancelEditing = () => setEditing(false);

  return editing ? (
    <InfoBoxForm
      onSubmit={onEdit}
      onClose={cancelEditing}
      editingItem={item}
      showContentField={false}
      initEditing
    />
  ) : (
    <UnderCoverContainer uncover="InfoBox">
      <Box ref={isEdit ? dropRef : null}>
        <Box ref={isEdit ? dragRef : null}>
          <Box
            overflow="hidden"
            // minHeight={100}
            style={{
              border: `3px solid ${
                dropProps.isHovered ? theme.palette.edit.main : 'transparent'
              }`,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
              height={40}
            >
              <Box py={1} display="inline-block">
                <FontAwesomeIcon
                  icon={['fal', item.icon]}
                  style={{ fontSize: theme.typography.pxToRem(size) }}
                />
              </Box>
              <UnderCover uncoveredBy="InfoBox">
                {isEdit ? (
                  <EditControls onEdit={edit} onRemove={onRemove} />
                ) : null}
              </UnderCover>
            </Box>
            <Box
              fontWeight="fontWeightBold"
              fontSize={theme.typography.pxToRem(titleSize)}
            >
              {item.title}
            </Box>
            <Box>{item.content}</Box>
          </Box>
        </Box>
      </Box>
    </UnderCoverContainer>
  );
}
