// @flow
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../core/constants';
import { makeStyles } from '@material-ui/core';
import type { Tag } from './tags';
import { TAG_SHADOWS } from './tags';
import { useMQDesktopFirst } from '../useMQ';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { useOutsideClick } from '../../utils/useOutsideClick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { TagType } from '../../utils/searchUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  tagBtn: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    transition: `all 0.3s`,
    outline: 'none',
    cursor: 'pointer',
    padding: theme.spacing(1, 1.5),
    border: 0,
    borderRadius: 20,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),

    '&:hover': {
      opacity: `0.8 !important`,
    },
    '&:focus': {
      opacity: `0.8 !important`,
    },
  },
  invisibleTagsContainer: {
    background: 'white',
    padding: theme.spacing(2, 2, 1, 2),
    borderRadius: theme.borderRadius,
    position: 'absolute',
    top: theme.spacing(3),
    zIndex: 100,
    right: 0,
    width: 200,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  invisibleTagBtn: {
    marginBottom: theme.spacing(1),
  },
  active: {
    width: 10,
    height: 10,
    background: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 50,
  },
  toggleInvisibleBtn: {
    cursor: 'pointer',
    background: theme.palette.grey[300],
    border: 0,
    borderRadius: 50,
    width: 30,
    height: 30,
    marginBottom: theme.spacing(1),

    '&:active': {
      transform: `scale(0.95)`,
    },
  },
  tenter: {
    opacity: 0,
    transform: `translateY(-5%)`,
  },
  tenterActive: {
    opacity: 1,
    transform: `translateY(0)`,
    transition: `opacity 0.3s, transform 0.3s`,
  },
  texit: {
    opacity: 1,
  },
  texitActive: {
    opacity: 0,
    transform: `translateY(-5%)`,
    transition: `opacity 0.3s, transform 0.3s`,
  },
  clearFilters: {
    color: theme.palette.grey[700],
    textDecoration: 'none',
    marginLeft: theme.spacing(1),
    display: 'none',

    '&:hover': {
      color: theme.palette.common.black,
    },

    '@media screen and (max-width: 480px)': {
      display: 'block',
    },
  },
}));

export type TagsState = {
  [TagType]: string | string[],
};

export function TagsList({
  activeTag,
  onTagCancel,
  onTagClick,
  tags,
}: {
  activeTag: TagsState,
  onTagClick: (tag: Tag) => void,
  onTagCancel: (tag: Tag) => void,
  tags: any,
}) {
  const mq = useMQDesktopFirst();
  const classes = useStyles();
  const [isInvisibleOpen, setIsInvisibleOpen] = useState(false);
  const hasActiveTag = !!Object.keys(activeTag).length;
  const invisibleTagsContainerRef = useRef(null);
  useOutsideClick(invisibleTagsContainerRef, () => setIsInvisibleOpen(false));
  const openInvisibleClick = () => setIsInvisibleOpen((prev) => !prev);

  const transitionClassNames = {
    enter: classes.tenter,
    enterActive: classes.tenterActive,
    exit: classes.texit,
    exitActive: classes.texitActive,
  };

  const isTagActive = (aTag: TagsState, tagToCheck: Tag) => {
    const tag = tagToCheck;
    const activeVals = Object.values(aTag);
    const activeKeys = Object.keys(aTag);

    if (!activeVals.length && !activeKeys.length) return false;

    if (activeKeys.includes(tag.type)) {
      return aTag[tag.type].toString() === tag.value.toString();
    }

    return false;
  };

  let visibleTags = tags;
  let invisibleTags: Tag[] = [];
  if (mq.is840) {
    visibleTags = tags.slice(0, 7);
    invisibleTags = tags.slice(7);
  }

  const isActiveTagInvisible = invisibleTags.some((tag) =>
    isTagActive(activeTag, tag)
  );

  return (
    <div className={classes.container}>
      {visibleTags.map((tag) => {
        const isActive = isTagActive(activeTag, tag);

        return (
          <button
            key={tag.id}
            type="button"
            style={{
              background: tag.color,
              color: tag.textColor,
              opacity: (hasActiveTag && isActive) || !hasActiveTag ? 1 : 0.2,
              boxShadow: isActive ? `0 4px 12px -3px ${tag.shadow}` : 'none',
              transform: isActive ? `scale(1.1)` : 'none',
            }}
            className={classes.tagBtn}
            onClick={isActive ? () => onTagCancel(tag) : () => onTagClick(tag)}
          >
            {tag.label}
          </button>
        );
      })}
      {invisibleTags.length ? (
        <div style={{ position: 'relative' }}>
          {isActiveTagInvisible ? <div className={classes.active} /> : null}
          <button
            className={cn(classes.toggleInvisibleBtn)}
            type="button"
            onClick={openInvisibleClick}
          >
            <FontAwesomeIcon
              icon={['fal', 'ellipsis-h']}
              size="2x"
              className={classes.invisibleBtnIcon}
            />
          </button>
        </div>
      ) : null}
      <Link to={paths.jobs} className={cn([classes.clearFilters])}>
        Clear
      </Link>
      <CSSTransition
        in={isInvisibleOpen && invisibleTags.length > 0}
        timeout={600}
        classNames={transitionClassNames}
        unmountOnExit
      >
        <div
          ref={invisibleTagsContainerRef}
          className={classes.invisibleTagsContainer}
        >
          {invisibleTags.map((tag) => {
            const isActive = isTagActive(activeTag, tag);

            return (
              <button
                key={tag.id}
                type="button"
                style={{
                  background: tag.color,
                  color: tag.textColor,
                  opacity:
                    (hasActiveTag && isActive) || !hasActiveTag ? 1 : 0.2,
                  boxShadow: isActive
                    ? `0 4px 12px -3px ${TAG_SHADOWS[tag.value.toString()]}`
                    : 'none',
                  transform: isActive ? `scale(1.1)` : 'none',
                }}
                className={cn(classes.tagBtn, classes.invisibleTagBtn)}
                onClick={
                  isActive ? () => onTagCancel(tag) : () => onTagClick(tag)
                }
              >
                {tag.label}
              </button>
            );
          })}
        </div>
      </CSSTransition>
    </div>
  );
}
