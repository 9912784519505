// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useAuth } from '../../AuthContext';
import cn from 'classnames';
import { AuthPopup } from '../AuthPopup';
import { useLoginPopup } from './useLoginPopup';
import { paths } from '../../../../../core/constants';

const useStyles = makeStyles((theme) => {
  return {
    linkBtn: {
      border: 'none',
      background: 'transparent',
      padding: 0,
      margin: 0,
      textDecoration: 'underline',
      color: theme.palette.grey[700],
      cursor: 'pointer',
      textAlign: 'center',

      '&:hover': {
        textDecoration: 'none',
      },
    },
    forgottenPassBtn: {
      marginBottom: theme.spacing(1),
    },
  };
});

export function LoginPopup() {
  const history = useHistory();
  const { isLoginPopupVisible, hideAuthPopup, showAuthPopup } = useAuth();
  const { onSubmit, submitError, submitLoading } = useLoginPopup();
  const classes = useStyles();

  const onClose = () => hideAuthPopup('login');

  const onRegisterClick = () => {
    showAuthPopup('register');
  };

  const onFPclick = () => {
    history.push(paths.forgottenPassword);
    hideAuthPopup('login');
  };

  return (
    <AuthPopup
      error={submitError}
      isVisible={isLoginPopupVisible}
      onClose={onClose}
      onSubmit={({ email, password }) => onSubmit({ email, password })}
      title="Login"
      loading={submitLoading}
      footer={
        <>
          <button
            type="button"
            disabled={submitLoading}
            onClick={onFPclick}
            className={cn(classes.linkBtn, classes.forgottenPassBtn)}
          >
            Forgotten password?
          </button>
          <button
            type="button"
            className={classes.linkBtn}
            disabled={submitLoading}
            onClick={onRegisterClick}
          >
            Don&apos;t have an account? Register!
          </button>
        </>
      }
    />
  );
}
