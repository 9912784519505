import {
  programmingLanguages,
  platformsAndFrameworks as corePlatformsAndFrameworks,
  databases as coreDatabases,
  roles as coreRoles,
} from '../../core/constants';

const roles = coreRoles;
const techRoles = [
  'mobile',
  'frontend',
  'backend',
  'devops',
  'qa',
  'databases',
  'dataScienceMlAi',
  'fullStack',
  'embeddedAndElectronicSystems',
  'engineeringOthers',
];

const languages = programmingLanguages;
const platformsAndFrameworks = corePlatformsAndFrameworks;
const databases = coreDatabases;

export { roles, techRoles, languages, platformsAndFrameworks, databases };
