// @flow
import API from '../../core/rest/API';

type Data = { data: { [key: string]: any } };

function saveBasicInformation({ data }: Data) {
  return API.postResponse('/user/me/basic-info', data);
}

function saveTechSkills({ data }: Data) {
  return API.postResponse('/user/me/tech-skills', data);
}

function saveSoftSkills({ data }: Data) {
  return API.postResponse('/user/me/soft-skills', data);
}

function saveCulture({ data }: Data) {
  return API.postResponse('/user/me/culture', data);
}

function onboardingState() {
  return API.get('/user/me/onboarding');
}

export {
  saveBasicInformation,
  saveTechSkills,
  saveSoftSkills,
  saveCulture,
  onboardingState,
};
