// @flow
const slidersConfig = {
  technicalSkills: {
    sysAdmin: 'Sys Admin',
    devOps: 'DevOps',
    databasesAndStorage: 'Databases & Storage',
    frontEnd: 'Front-end',
    mobile: 'Mobile',
    backendAndWebServers: 'Back-end & Web & Servers',
    embedded: 'Embeded / Systems programming',
    htmlCss: 'HTML & CSS',
    automationQA: 'Automation QA',
    manualQA: 'Manual QA',
    webmaster: 'Webmaster / CMS',
    dataScienceML: 'Data Science / Machine Learning',
    softwareArchitecture: 'Software Architecture',
    otherTechSkills: 'Other Tech. Skills',
  },
  softSkills: {
    clientFacingCommunication: 'Client Facing Communication',
    projectManagementAndLeadership: 'Project Management / Leadership',
    planningAndEstimation: 'Planning / Estimation',
    writing: 'Writing (e.g. documentation / tutorials)',
    teaching: 'Teaching others / Knowledge sharing',
    timeManagement: 'Time Management',
    takingResponsibility: 'Take Responsibility',
    requirements: 'Requirements Elicitation / Business Analysis',
    creativity: 'Creativity',
    teamwork: 'Teamwork',
  },
  cultureGeneral: {
    participationAndCollaboration: 'Participation & collaboration',
    communicationAndConsensus: 'Communication & Consensus',
    energyAndCreativity: 'Energy & Creativity',
    innovationAndChange: 'Innovation & Change',
    resultsAndPerformance: 'Results-Orientation & Performance',
    competitionAndRivalry: 'Competition & Rivalry',
    structureAndStability: 'Structure & Stability',
    clarityAndGuidance: 'Clarity & Guidance',
  },
  cultureTeam: {
    sharingCommonValuesAndPractices: 'Sharing common values & practices',
    partOfBigFamily: 'Feel like part of one big family - great teamwork',
    riskTakingAndExperimentation:
      'Risk-taking & experimentation are encouraged - freedom to act',
    individualTalentIsRecognized:
      "Individual's talents, ingenuity & efforts are recognized",
    unitedByCommonGoalAndBeatingTheCompetition:
      'United by a common goal of success & beating the competition',
    performanceIsRewarded: 'Performance is rewarded',
    drivenByEfficiencyAndProcess: 'Driven by efficiency & Strong Process',
    coordinatedAndPredictable: 'Highly-coordinated & Predictable',
  },
  cultureLeadership: {
    mentorship: 'Takes the form of mentorship',
    knowledgeAndSkillsTransfer: 'Bounded by knowledge & skills transfer',
    leadersSeenAsInnovatorsAndEntrepreneurs:
      'Leaders are seen as innovators or entrepreneurs',
    dynamicAndInformal: 'Dynamic & informal',
    toughAndDemanding: 'Tough & demanding',
    focusedOnResultsAndProfitability:
      'Focused on results, market share & profitability',
    moderationAndGuidance: 'Takes the form of moderation & guidance',
    stableAndFormal: 'Stable & formal',
  },
};

export { slidersConfig };
