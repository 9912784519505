// @flow
import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function EditControls({
  onRemove,
  onEdit,
  showMoveIcon = true,
  size = [40, 40],
}: {
  onRemove: any,
  onEdit?: () => void,
  showMoveIcon?: boolean,
  size?: [number, number],
}) {
  const theme = useTheme();
  const [width, height] = size;
  return (
    <Box display="flex" alignItems="center">
      {showMoveIcon ? (
        <Box
          bgcolor={theme.palette.edit.main}
          width={width}
          height={height}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={theme.shape.borderRadius}
          mr={1}
          style={{ cursor: 'grab' }}
        >
          <FontAwesomeIcon icon={['fal', 'arrows']} />
        </Box>
      ) : null}
      {onEdit ? (
        <Box
          bgcolor={theme.palette.edit.main}
          width={width}
          height={height}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={theme.shape.borderRadius}
          mr={1}
          style={{ cursor: 'pointer' }}
          onClick={onEdit}
        >
          <FontAwesomeIcon icon={['fal', 'pencil']} />
        </Box>
      ) : null}
      <Box
        style={{ cursor: 'pointer' }}
        onClick={onRemove}
        bgcolor={theme.palette.edit.main}
        width={width}
        height={height}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius={theme.shape.borderRadius}
      >
        <FontAwesomeIcon icon={['fal', 'trash']} />
      </Box>
    </Box>
  );
}
