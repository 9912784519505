import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { TOP_MENU_HEIGHT } from '../menu/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: TOP_MENU_HEIGHT,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.common.white,
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    margin: 'auto',
    '@media (min-width: 768px)': {
      display: 'none',
    },
  },
  menuItem: {
    padding: theme.spacing(1),
    borderRadius: theme.borderRadius,
  },
  menuItemActive: {
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    borderRadius: theme.borderRadius,
  },
}));

export function CompanyProfileMobileNavigation() {
  const classes = useStyles();
  const [links, setLinks] = useState([]);
  const wpMenuSelector = '#menu-secondary-menu-1';
  const menuItemSelector = '.menu-item';
  const isActiveItemSelector = 'current-menu-item';

  useEffect(() => {
    const wpMenu = document.querySelector(wpMenuSelector);
    if (wpMenu) {
      const menuItems = wpMenu.querySelectorAll(menuItemSelector);
      const collectedLinks = [];
      menuItems.forEach((menuItem) => {
        const linkElement = menuItem.firstChild;
        const link = linkElement.getAttribute('href');
        const linkContent = linkElement.innerText;
        const active = menuItem.classList.contains(isActiveItemSelector);
        const linkObj = {
          link,
          linkContent,
          active,
        };
        collectedLinks.push(linkObj);
      });
      setLinks(collectedLinks);
    }
  }, [wpMenuSelector]);

  return links.length ? (
    <div className={classes.container}>
      {links.map(({ link, linkContent, active }) => (
        <a
          key={link}
          className={`${classes.menuItem} ${
            active ? classes.menuItemActive : ''
          }`}
          href={link}
        >
          {linkContent}
        </a>
      ))}
    </div>
  ) : null;
}
