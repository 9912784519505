// @flow
import * as React from 'react';
import { ForgottenPasswordForm } from '../components/auth/talents/components/ForgottenPasswordForm';
import { AuthFrame } from '../components/auth/talents/components/AuthFrame';
import { DisableScrollOnMount } from '../components/basic/DisableScrollOnMount';
import { HideTopArrow } from '../components/wordpress/HideTopArrow';
import { PageHead } from '../components/basic/PageHead';

function ForgottenPassword() {
  return (
    <DisableScrollOnMount>
      <HideTopArrow>
        <PageHead title="Forgotten password | NobleHire" />
        <AuthFrame>
          <ForgottenPasswordForm />
        </AuthFrame>
      </HideTopArrow>
    </DisableScrollOnMount>
  );
}

export { ForgottenPassword };
