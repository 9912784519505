// @flow
/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Box, Button, Container, makeStyles } from '@material-ui/core';
import { useJobs } from '../jobs/JobsContext';
import { Loader } from '../basic/Loader';
import { paths } from '../../core/constants';
import type { Tag } from './tags';
import { Filters } from './Filters';
import { JobCard } from './JobCardV2';
import { NoItemsFound } from '../basic/NoItemsFound';
import { SearchBar } from '../basic/SearchBar';
import type { FilterType } from '../../utils/searchUtils';
import {
  parseSearchQuery,
  insertParam,
  appendToSearchQuery,
} from '../../utils/searchUtils';
import { tags } from './tags';
import { TagsList } from './TagsList';
import type { TagsState } from './TagsList';

const initialFilters = {
  location: '',
  role: '',
  seniority: '',
  salaryMax: '',
};
const initialTags = {};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 35,
  },
  // ---------- search bar styles ----------
  form: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  searchBar: {
    width: '100%',
    borderRadius: theme.shape.borderRadius * 3,
    border: `5px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(3, 2),
    paddingLeft: theme.spacing(8),
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.fontFamily,
    boxShadow: `0 0 25px -3px rgba(0,0,0,0.2)`,
    fontWeight: 500,
    outline: 'none',
    transition: 'all 0.3s',
    '&:focus': {
      transform: `scale(1.01)`,
    },
  },
  searchIcon: {
    position: 'absolute',
    zIndex: 10,
    top: theme.spacing(3),
    left: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  submitBtnContainer: {
    position: 'absolute',
    top: '50%',
    transform: `translateY(-50%)`,
    right: theme.spacing(2),
  },
  submitBtn: {
    textTransform: 'initial',
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),

    '& > div:first-child': {
      flex: 1,
    },
  },
  clearFilters: {
    color: theme.palette.grey[700],
    textDecoration: 'none',
    marginLeft: theme.spacing(1),

    '&:hover': {
      color: theme.palette.common.black,
    },

    '@media screen and (max-width: 480px)': {
      display: 'none',
    },
  },

  // ---------- loader ----------
  loaderContainer: {
    width: '100%',
    height: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  // ---------- jobs styles ----------
  listContainer: {
    position: 'relative',
    minHeight: 250,
  },
  seeAllJobsLink: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(18),
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export type FiltersState = {
  [FilterType]: string,
};

export function JobBoard() {
  const loc = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const [init, setInit] = useState(false);
  const { jobs, load, paginationData, loadMore, loadingMore, loading } =
    useJobs();
  const [text, setText] = useState('');
  const [tag, setTag] = useState<TagsState>({});
  const [filters, setFilters] = useState<FiltersState>(initialFilters);
  const [triggerSearch, setTriggerSearch] = useState<boolean>(false);
  const [currentQuery, setCurrentQuery] = useState(loc.search);

  useEffect(() => {
    const data = parseSearchQuery();
    if (!data) {
      // search reset
      setText('');
      setTag(initialTags);
      setFilters(initialFilters);
      setCurrentQuery('');
    } else {
      // update state
      setTag(initialTags);
      Object.keys(data).forEach((key) => {
        const val = data[key];
        switch (key) {
          case 'text':
            setText(val);
            setTag((prev) => ({ ...prev, [key]: val }));
            break;
          case 'tech':
            setTag((prev) => ({ ...prev, [key]: val }));
            break;
          case 'location':
          case 'role':
          case 'seniority':
          case 'salaryMax':
            setFilters((prev) => ({ ...prev, [key]: val }));
            setTag((prev) => ({ ...prev, [key]: val }));
            break;
        }
      });
    }

    setTriggerSearch(true);
  }, [loc.search]);

  useEffect(() => {
    if (triggerSearch && !loading) {
      const search = currentQuery.startsWith('?')
        ? currentQuery
        : `?${currentQuery}`;

      load(search);
      setTriggerSearch(false);
    }
    setInit(true);
  }, [triggerSearch, filters, tag, text, currentQuery, load, loading]);

  const updateQuery = (paramName: 'search' | 'page', val: string | number) => {
    // updates the &search={..} parth of the query string
    const newQueryStr = insertParam(paramName, val);
    history.push({ pathname: paths.jobs, search: newQueryStr });
    setCurrentQuery(newQueryStr);
  };
  const onSearch = (t: string) => {
    const newSearchStr = appendToSearchQuery({
      type: 'text',
      val: t.trim(),
    });
    updateQuery('search', newSearchStr);
  };

  const onTagClick = (t: Tag) => {
    const newSearchStr = appendToSearchQuery({
      type: t.type,
      val: t.value,
    });
    updateQuery('search', newSearchStr);
    setTag({ [t.type]: t.value });
  };
  const onTagCancel = (t: Tag) => {
    const newSearchStr = appendToSearchQuery({
      type: t.type,
      val: '',
    });
    updateQuery('search', newSearchStr);
    setTag(initialTags);
  };
  const onFilterChange = (filter: FilterType, newValue: string) => {
    const newSearchStr = appendToSearchQuery({
      type: filter,
      val: newValue,
    });
    updateQuery('search', newSearchStr);
    setFilters((prev) => ({ ...prev, [filter]: newValue }));
  };
  const onLoadMoreClick = () => {
    const search = currentQuery.startsWith('?')
      ? currentQuery
      : `?${currentQuery}`;

    loadMore(search);
  };

  return (
    <Box>
      <Box py={2}>
        <Container maxWidth="lg">
          <div className={classes.root}>
            {/* ---------- Search bar ---------- */}
            <SearchBar
              value={text}
              filters={
                <Filters filters={filters} onFilterChange={onFilterChange} />
              }
              placeholder="Search jobs..."
              onSearch={(value: string) => onSearch(value)}
              onChange={(value: string) => setText(value)}
            />

            {/* ---------- Filters ---------- */}

            {/* ---------- Tags list -------- */}

            <div className={classes.tags}>
              <TagsList
                activeTag={tag}
                onTagClick={onTagClick}
                onTagCancel={onTagCancel}
                tags={tags}
              />

              <Link to={paths.jobs} className={cn([classes.clearFilters])}>
                Clear
              </Link>
            </div>

            {/* ---------- SEO pages ---------- */}
            {paginationData && paginationData.totalPages
              ? new Array(paginationData.totalPages)
                  .fill(0)
                  .map((_, pageIndex) => {
                    return (
                      <a
                        style={{ display: 'none' }}
                        key={pageIndex}
                        href={paths.seo__jobsPage.replace(':index', pageIndex)}
                      >
                        Page {pageIndex}
                      </a>
                    );
                  })
              : null}

            {/* ---------- Jobs ---------- */}
            <div className={classes.listContainer}>
              {loading || triggerSearch ? (
                <Loader
                  containerParams={{ className: classes.loaderContainer }}
                />
              ) : (
                <div>
                  {jobs.length === 0 && init ? (
                    <NoItemsFound
                      msg="We couldn't find any jobs matching your query."
                      link={paths.jobs}
                      linkText="See all jobs"
                    />
                  ) : null}
                  {jobs.map((job) => {
                    if (!job.slug || !job.company.slug) return null;

                    return <JobCard key={job.id} job={job} />;
                  })}

                  {(paginationData.page || 0) + 1 <
                  (paginationData.totalPages || 1) ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={onLoadMoreClick}
                        disabled={loadingMore}
                        disableElevation
                        style={{ textTransform: 'initial' }}
                      >
                        {loadingMore ? 'Loading...' : 'Load More'}
                      </Button>
                    </Box>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </Container>
      </Box>
    </Box>
  );
}
