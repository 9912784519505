// @flow
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { paths } from '../core/constants';
import { useVisitorTrails } from '../components/tracking/visitor-trails/VisitorTrailsContext';

function DirectApplicationGuest({ queryString }: { queryString: string }) {
  const { getTrail, visitorTrails } = useVisitorTrails();
  const hasAccountTrail = getTrail(visitorTrails.VISITOR_HAS_ACCOUNT);
  return (
    <Redirect
      to={{
        pathname: hasAccountTrail ? paths.login : paths.register,
        search: queryString,
      }}
    />
  );
}

export { DirectApplicationGuest };
