// @flow
import type { Node } from 'react';
import type { Action, State } from '../../core/types';
import { CLOSE_CONFIRMATION, OPEN_CONFIRMATION } from './actions';

const initialState: { item: Node } = {
  item: null,
};

const confirmations = (state: State & typeof initialState, action: Action) => {
  switch (action.type) {
    case OPEN_CONFIRMATION:
      return {
        ...state,
        item: action.data,
      };
    case CLOSE_CONFIRMATION:
      return {
        ...state,
        item: null,
      };
    default:
      return state;
  }
};

confirmations.initialState = initialState;

export { confirmations };
