// @flow
import {
  validateRequired,
  validateEitherOr,
  validateAtLeastOneSliderIsMoved,
  validateLinkedInUrl,
  validatePhoneNumber,
  validateFirstAndLastName,
} from '../../core/validators';
import type { ValidateFuncBuilderConfig } from '../../core/types';
import { isRoleTech } from '../../utils/isRoleTech';

type Config = { [key: string]: ValidateFuncBuilderConfig };
type ConfigFn = (...any) => { [key: string]: ValidateFuncBuilderConfig };

const basicInformation: ConfigFn = (showDevSpecificOptions = true) => {
  const step2Base = {
    currentPosition: [validateRequired('currentPosition')()],
    seniority: [
      validateRequired('seniority')('Please, select your current seniority.'),
    ],
  };
  const step2DevSpecificOptions = {
    primaryProgrammingLanguage: [
      validateRequired('primaryProgrammingLanguage')(),
    ],
    primaryPlatformAndFramework: [
      validateRequired('primaryPlatformAndFramework')(),
    ],
    secondaryProgrammingLanguage: [
      validateRequired('secondaryProgrammingLanguage')(),
    ],
    secondaryPlatformAndFramework: [
      validateRequired('secondaryPlatformAndFramework')(),
    ],
    mainDatabase: [validateRequired('mainDatabase')()],
  };

  return {
    '0': {
      name: [validateFirstAndLastName('name')()],
      phone: [validateRequired('phone')(), validatePhoneNumber('phone')()],
      cv: [
        validateEitherOr(
          'cv',
          'linkedin'
        )(
          'In order to match you, we need either your CV or your LinkedIN profile.'
        ),
      ],
      linkedin: [
        validateEitherOr(
          'cv',
          'linkedin'
        )(
          'In order to match you, we need either your LinkedIN profile or your CV.'
        ),
        validateLinkedInUrl('linkedin', (values, field) => {
          return !values[field] || !values[field].length;
        })(),
      ],
      city: [validateRequired('city')()],
    },
    '1': {
      jobSearchStatus: [validateRequired('jobSearchStatus')()],
      jobSearchRoleType: [validateRequired('jobSearchRoleType')()],
      preferredLanguages: [
        validateRequired(
          'preferredLanguages',
          (values) => !isRoleTech(values.jobSearchRoleType)
        )(),
      ],
    },
    '2': {
      ...step2Base,
      ...(showDevSpecificOptions ? step2DevSpecificOptions : {}),
    },
  };
};

const basicInformationNonTech: ConfigFn = (showDevSpecificOptions = true) => ({
  '0': { ...basicInformation(showDevSpecificOptions)['0'] },
  '1': {
    jobSearchStatus: [validateRequired('jobSearchStatus')()],
    jobSearchRoleType: [validateRequired('jobSearchRoleType')()],
  },
  '2': { ...basicInformation(showDevSpecificOptions)['2'] },
});

const skillsNonTech: Config = {
  '0': {
    softSkillsRating: [
      validateAtLeastOneSliderIsMoved('softSkillsRating')(
        'Please, rate at least one of the listed skills'
      ),
    ],
  },
};

const skills: Config = {
  '0': {
    technicalSkillsRating: [
      validateAtLeastOneSliderIsMoved('technicalSkillsRating')(
        'Please, rate at least one of the listed skills'
      ),
    ],
  },
  '1': skillsNonTech['0'],
};

const culture: Config = {
  '0': {
    cultureGeneralRating: [
      validateAtLeastOneSliderIsMoved('cultureGeneralRating')(
        'Please, rate your culture preferences'
      ),
    ],
  },
  '1': {
    cultureTeamRating: [
      validateAtLeastOneSliderIsMoved('cultureTeamRating')(
        'Please, rate your culture preferences'
      ),
    ],
  },
  '2': {
    cultureLeadershipRating: [
      validateAtLeastOneSliderIsMoved('cultureLeadershipRating')(
        'Please, rate your culture preferences'
      ),
    ],
  },
};

export {
  basicInformation,
  basicInformationNonTech,
  skills,
  skillsNonTech,
  culture,
};
