import { createTheme, darken } from '@material-ui/core/styles';

const theme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          color: '#000',
        },
      },
    },
  },
  typography: {
    useNextVariants: true,
    fontWeight: 500,
    fontFamily: 'Montserrat, sans-serif',
  },
  palette: {
    text: {
      primary: '#000',
    },
    primary: {
      lightest: '#EDE6F6',
      light: '#DCCDED',
      light2: darken('#DCCDED', 0.2),
      main: '#5000AA', // NobleHire's primary color
      dark: darken('#5000AA', 0.2),
    },
    grey: {
      light: '#E7E7E7',
      main: '#C6C6C6',
      dark: '#B3B3B3',
      darkest: '#4A4A4A',
    },
    success: {
      main: '#10D100',
    },
    edit: {
      main: '#F89309',
      dark: darken('#F89309', 0.1),
    },
  },
  borderRadius: 5,
});

// eslint-disable-next-line
theme.shadows[4] = theme.shadows[2];

export { theme };
