// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal } from '../../basic/Modal';
import { Button } from '../../basic/Button';
import { dequeueModal } from '../actions';
import { useStateContainer } from '../../../core/context/StateContainer';
import { deleteAccount } from '../../auth/authService';
import { enqueueNotification } from '../../notifications/actions';
import { Error } from '../../notifications/components/Error';
import { Success } from '../../notifications/components/Success';
import { logOut } from '../../auth/talents/actions';
import { useVisitorTrails } from '../../tracking/visitor-trails/VisitorTrailsContext';
import { log } from '../../../utils/log';

const { useState } = React;
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 580,
  },
  title: {
    minWidth: 310,
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4),
    color: theme.palette.grey.dark,
  },
  container: {
    marginTop: theme.spacing(6),
    // textAlign: 'center'
  },
  containerTitle: {
    margin: 0,
    fontSize: theme.typography.pxToRem(28),
    fontWeight: theme.typography.fontWeightMedium,
  },
  iconContainer: {
    userSelect: 'none',
    position: 'relative',
    textAlign: 'center',
    height: 'fit-content',
    margin: theme.spacing(4, 0),
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: 42,
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.main,
  },
  introText: {
    margin: theme.spacing(3, 0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function DeleteAccount() {
  const [loading, setLoading] = useState(false);
  const [
    {
      auth: { user },
    },
    dispatch,
  ] = useStateContainer();
  const { removeTrails } = useVisitorTrails();
  const classes = useStyles();
  const titleId = 'confirm';
  const contentId = 'confirmContent';

  const close = React.useCallback(() => {
    dispatch(dequeueModal());
  }, [dispatch]);

  const closeAndDelete = React.useCallback(() => {
    const { uuid } = user;
    setLoading(true);
    deleteAccount({ uuid })
      .then(() => {
        close();
        removeTrails();
        logOut(dispatch);
        dispatch(
          enqueueNotification(
            <Success>Your account has been deleted successfully</Success>
          )
        );
        setLoading(false);
      })
      .catch((err) => {
        log(err, { logLevel: log.logLevels.error, error: err });
        close();
        dispatch(
          enqueueNotification(
            <Error>An error occurred. Please, try again.</Error>
          )
        );
        setLoading(false);
      });
  }, [close, dispatch, removeTrails, user]);

  return (
    <Modal
      open
      withCloseButton={false}
      onClose={close}
      titleId={titleId}
      contentId={contentId}
      paperClassName={classes.root}
    >
      <div className={classes.title}>Confirm deletion</div>
      <div className={classes.container}>
        <div className={classes.introText}>
          Are you sure you want to delete your account?
        </div>
        <div className={classes.buttons}>
          <Button
            style={{ marginRight: 10 }}
            type="button"
            variant="text"
            color="primary"
            onClick={loading ? () => {} : close}
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={closeAndDelete}
            disabled={loading}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export { DeleteAccount };
