// @flow
import {
  validatePassword,
  validateRequired,
  validateRepeatPassword,
} from '../../../../../core/validators';
import type { ValidateFuncBuilderConfig } from '../../../../../core/types';

const validateConfig: ValidateFuncBuilderConfig = {
  password: [
    validateRequired('password')(),
    validatePassword('password')(
      'The password should be at least 3 symbols long.'
    ),
  ],
  repeatPassword: [
    validateRequired('repeatPassword')(),
    validateRepeatPassword('password', 'repeatPassword')(),
  ],
};

export { validateConfig };
