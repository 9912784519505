// @flow
import { obj } from '../../../utils/obj';

function buildRatesObjectOutOfSlidersValues(
  config: { [key: string]: string },
  values: { [key: string]: number }
) {
  return obj.reduceKeys<$Keys<typeof config>, string, Object>(
    config,
    (acc, skill) => {
      if (!values) {
        return acc;
      }

      acc[skill] = values[skill] || 0;
      return acc;
    },
    {}
  );
}

export { buildRatesObjectOutOfSlidersValues };
