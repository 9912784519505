// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { externalPaths } from '../../../../../core/constants';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',

    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

function ConsentCheckboxLabel() {
  const classes = useStyles();

  return (
    <div>
      <span>I agree with Noble Hire’s </span>
      <div>
        <a href={externalPaths.termsAndConditions} className={classes.link}>
          Terms & Conditions
        </a>
        <span>. Learn how we collect, use and share your data in our</span>
        &nbsp;
        <a href={externalPaths.privacyPolicy} className={classes.link}>
          Data Policy
        </a>
      </div>
    </div>
  );
}

export { ConsentCheckboxLabel };
