// @flow
import * as React from 'react';
import { useSkillsForm } from './useSkillsForm';
import { OnboardingForm } from '../OnboardingForm';

const SkillsForm = () => (
  <OnboardingForm
    useHook={useSkillsForm}
    initialValuesEqual={() =>
      true
    } /* to prevent form reinitialization on update, because every time the initialValues is a new object, built from the useSkillsForm->buildInitialValuesForSliders func */
  />
);

export { SkillsForm };
