// @flow
import React from 'react';
import { useParams } from 'react-router-dom';
import { events } from '../../../../core/constants';
import API from '../../../../core/rest/API';
import { getUser } from '../../../auth/authService';
import { useStateContainer } from '../../../../core/context/StateContainer';
import { enqueueModal } from '../../../modals/actions';
import { DirectReferralIntroduction } from '../../modals/DirectReferralIntroduction';
import { directReferralLinkVisit } from '../../referralsService';
import { useTracking } from '../../../tracking/TrackingContext';
import { log } from '../../../../utils/log';

function useDirectReferralLinkVisit() {
  const params = useParams();
  const { track, events: trackingEvents } = useTracking();
  const [
    {
      auth: { authenticated },
    },
    dispatch,
  ] = useStateContainer();
  function handleDirectReferralLinkVisit() {
    if (params.token) {
      const { token } = params;

      track(trackingEvents.DIRECT_REFERRAL_HIT);

      if (authenticated) {
        // do nothing if there's a logged in user
        return;
      }

      API.updateAuthenticationToken(token);

      getUser()
        .then((user) => {
          const details = { detail: { authToken: token } };
          window.dispatchEvent(new CustomEvent(events.SET_AUTH_TOKEN, details));

          if (!user.consent) {
            dispatch(
              enqueueModal(
                <DirectReferralIntroduction>
                  Hi,&nbsp;
                  {user.username}
                </DirectReferralIntroduction>
              )
            );
          }

          // TODO: send request to activate the referral user
          directReferralLinkVisit().catch((err: any) => {
            log('Direct referral link visit request failed.', {
              logLevel: log.logLevels.error,
              error: err,
            });
          });
        })
        .catch(() => {
          API.removeAuthenticationToken();
          window.dispatchEvent(
            new CustomEvent(events.NOT_AUTHENTICATED, {
              detail: {
                useShortRegistration: false,
              },
            })
          );
        });
    } else {
      log('Hit a direct referral link without token.', {
        logLevel: log.logLevels.warn,
      });
    }
  }

  return { handleDirectReferralLinkVisit };
}

export { useDirectReferralLinkVisit };
