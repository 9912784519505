// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  children: React.Node,
  testId?: string,
  title: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(10, 10),
    borderBottom: `1px solid ${theme.palette.grey.light}`,
  },
}));

function Section({ testId, children, title }: Props) {
  const classes = useStyles();
  return (
    <div data-testid={testId} className={classes.root}>
      <h2 style={{ marginTop: 0 }}>{title}</h2>

      {children}
    </div>
  );
}

Section.defaultProps = {
  testId: undefined,
};

export { Section };
