// @flow
import React from 'react';
import { Box, Switch, withStyles, useTheme } from '@material-ui/core';
import { Button } from './Button';
import { useMQ } from '../useMQ';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 20,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.success.main,
        borderColor: theme.palette.success.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

type EditHeaderProps = {
  saving: boolean,
  exiting: boolean,
  isPublic: boolean,
  onPreview: () => void,
  onPublish: () => void,
  onUnpublish: () => void,
  onSave: () => void,
  onExit: () => void,
};
export function EditHeader({
  saving,
  exiting,
  isPublic,
  onPreview,
  onPublish,
  onUnpublish,
  onSave,
  onExit,
}: EditHeaderProps) {
  const mq = useMQ();
  const theme = useTheme();
  const commonStyles = {
    marginRight: 3,
  };

  const flexStyles = {
    display: 'flex',
    alignItems: 'center',
  };

  const toggle = () => {
    if (isPublic) {
      // the toggle is public, transitioning to private
      onUnpublish();
    } else {
      // the toggle is private, transitioning to public
      onPublish();
    }
  };

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      bgcolor={theme.palette.edit.main}
      width="100%"
      height={70}
      zIndex={3}
      padding={theme.spacing(0, 5)}
    >
      <Box {...flexStyles} justifyContent="space-between" height="100%">
        <Box {...flexStyles}>
          {mq.lg ? (
            <Box color={theme.palette.grey[800]} {...commonStyles}>
              You are in Edit Mode
            </Box>
          ) : null}
          <Box display="flex" alignItems="center" {...commonStyles}>
            <Box display="inline-block" marginRight={1}>
              Public
            </Box>
            <AntSwitch
              checked={isPublic}
              onChange={toggle}
              name="check"
              disabled={saving || exiting}
            />
          </Box>
        </Box>
        <Box {...flexStyles}>
          <Box {...commonStyles}>
            <Button
              underlinedText
              color="default"
              variant="text"
              type="button"
              onClick={onPreview}
              disabled={saving || exiting}
            >
              Preview
            </Button>
          </Box>
          <Box {...commonStyles}>
            <Button
              color="white"
              variant="contained"
              type="button"
              onClick={onSave}
              disabled={saving || exiting}
            >
              {saving ? 'Saving...' : 'Save'}
            </Button>
          </Box>
          <Button
            underlinedText
            color="default"
            variant="text"
            type="button"
            onClick={onExit}
            disabled={saving || exiting}
          >
            Exit
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
