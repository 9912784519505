// @flow
import * as React from 'react';
import { Row } from '../../../basic/form/Row';
import { Select } from '../../../basic/form/Select';
import { Step } from '../../../basic/form/Step';
import { BackButton, SubmitButton } from '../../../basic/form/Buttons';
import { roles, languages } from '../../dropdownsConfig';
import { obj } from '../../../../utils/obj';
import { GreyContainer } from '../GreyContainer';

type Props = {
  lookingForTechRole: boolean,
};

function CurrentJobSearchStatus({ lookingForTechRole }: Props) {
  const buttons = (
    <>
      <BackButton />
      <SubmitButton>Next</SubmitButton>
    </>
  );

  return (
    <Step
      sectionName="Basic information"
      heading="What are you looking for?"
      buttons={buttons}
    >
      <>
        <Row>
          <GreyContainer>
            <Select
              id="jobSearchStatus"
              name="jobSearchStatus"
              required
              label="What's the status of your job search?"
            >
              <option value="" />
              <option value="justStartedLookingForANewJob">
                Just started looking for a new job
              </option>
              <option value="activelyLookingAndAttendingJobInterviews">
                Actively looking and already attending interviews
              </option>
              <option value="notLookingButOpen">
                Not looking but open to receive matching jobs
              </option>
            </Select>
          </GreyContainer>
        </Row>
        <Row>
          <Select
            id="jobSearchRoleType"
            name="jobSearchRoleType"
            required
            label="Type of role are you looking for?"
          >
            <option value="" />
            {obj.mapKeys<string, any>(roles, (role) => {
              return (
                <option key={role} value={role}>
                  {roles[role]}
                </option>
              );
            })}
          </Select>

          {lookingForTechRole ? (
            <Select
              id="preferredLanguages"
              name="preferredLanguages"
              required
              label="Preferred programming language?"
            >
              <option value="" />
              {obj.mapKeys<$Keys<typeof languages>, any>(languages, (lang) => {
                return (
                  <option key={lang} value={lang}>
                    {languages[lang]}
                  </option>
                );
              })}
            </Select>
          ) : null}
        </Row>
        {/* <Row>
          <Input
            controlProps={{ className: classes.inputControl }}
            inputProps={{ className: classes.input }}
            multiline
            label="Anything else that's important for your job search"
            id="jobSearchUserInputDetails"
            name="jobSearchUserInputDetails"
            placeholder="Explain in 2-3 sentances"
          />
          </Row> */}
      </>
    </Step>
  );
}

export { CurrentJobSearchStatus };
