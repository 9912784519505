// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  children: React.Node,
  noBottomSpace?: boolean,
};

const useStyles = makeStyles((theme) => ({
  root: ({ noBottomSpace }) => ({
    display: 'flex',
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    marginBottom: noBottomSpace ? 0 : theme.spacing(2),
    textAlign: 'initial',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
      margin: 'initial',
    },
  }),
  col: {
    flex: 1,
    margin: theme.spacing(0, 1),

    '@media (max-width: 768px)': {
      margin: 'initial',
      marginBottom: theme.spacing(2),
    },
  },
}));

function Row({ children, noBottomSpace, ...rest }: Props) {
  const classes = useStyles({ noBottomSpace });
  return (
    <div className={classes.root} {...rest}>
      {React.Children.map(children, (child) => {
        if (!child) {
          return null;
        }

        return <div className={classes.col}>{child}</div>;
      })}
    </div>
  );
}

Row.defaultProps = {
  noBottomSpace: false,
};

export { Row };
