// @flow

import API from '../../core/rest/API';

export function getCompanies(
  search: string = '',
  { page = 0 }: { page?: number }
) {
  const url = '/company';
  let s = search || '?';
  if (page) {
    s += `&page=${page}`;
  }

  return API.get(`${url}${s}`);
}
