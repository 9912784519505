// @flow
import * as React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { paths } from '../../../../../core/constants';
import { Button } from '../../../../basic/Button';
import { Form } from '../../../../basic/form/Form';
import { Input } from '../../../../basic/form/Input';
import { useLoginForm } from './useLoginForm';

const useStyles = makeStyles((theme) => ({
  root: { maxWidth: '280px' },
  title: { textAlign: 'center' },
  footer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  link: {
    marginTop: theme.spacing(2),
  },
  row: { width: '100%', margin: theme.spacing(1, 0) },
}));

const LoginForm = () => {
  const classes = useStyles();
  const query = useLocation().search;
  const { onFormSubmit, trackDontHaveAccountClick, validate } = useLoginForm();

  return (
    <div className={classes.root}>
      <Form onSubmit={onFormSubmit} validate={validate}>
        {({ submitting }) => (
          <React.Fragment>
            <p className={classes.title}>Login</p>

            <Input
              controlProps={{ className: classes.row }}
              type="email"
              name="email"
              id="email"
              label="Email"
              autoComplete="email"
              placeholder="john@doe.com"
              required
            />

            <Input
              controlProps={{ className: classes.row }}
              type="password"
              name="password"
              id="password"
              label="Password"
              autoComplete="password"
              required
            />

            <div className={classes.footer}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={submitting}
              >
                {submitting ? 'Logging you in...' : 'Login'}
              </Button>

              <Link
                className={classes.link}
                to={{ pathname: paths.forgottenPassword, search: query }}
              >
                Forgotten password?
              </Link>
              <Link
                onClick={trackDontHaveAccountClick}
                className={classes.link}
                to={{ pathname: paths.register, search: query }}
              >
                Don&#39;t have an account? Register!
              </Link>
            </div>
          </React.Fragment>
        )}
      </Form>
    </div>
  );
};

export { LoginForm };
