// @flow
import React from 'react';
import type { Node } from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

const StyledUnderCoverContainer = styled.div`
  & .UnderCover--uncoveredBy-${(props) => props.uncover} {
    display: none;
  }
  &:hover .UnderCover--uncoveredBy-${(props) => props.uncover} {
    display: block;
  }
`;

type UnderCoverContainerProps = {
  children: Node,
  uncover: string,
};
export function UnderCoverContainer({
  children,
  uncover,
  ...rest
}: UnderCoverContainerProps) {
  return (
    <StyledUnderCoverContainer uncover={uncover} {...rest}>
      {children}
    </StyledUnderCoverContainer>
  );
}

type UnderCoverProps = {
  children: Node,
  uncoveredBy: string,
};
export function UnderCover({ uncoveredBy, children }: UnderCoverProps) {
  return (
    <Box className={`UnderCover--uncoveredBy-${uncoveredBy}`}>{children}</Box>
  );
}
