// @flow
import React from 'react';
import type { Node } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { Loader } from './Loader';

export function FullPageLoader({ children }: { children?: Node }) {
  const theme = useTheme();
  return (
    <Box
      position="absolute"
      top="50%"
      left="50%"
      style={{
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Loader />
        {children ? (
          <Box
            display="block"
            textAlign="center"
            color={theme.palette.primary.main}
            fontWeight={theme.typography.fontWeightBold}
            style={{ width: '28ch' }}
            mt={2}
          >
            {children}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
