// @flow
import {
  validateRequired,
  validateRepeatPassword,
  validateChecked,
  validateFirstAndLastName,
} from '../../../../../core/validators';
import { validateConfig as loginValidateConfig } from '../LoginForm/validateConfig';
import type { ValidateFuncBuilderConfig } from '../../../../../core/types';

const validateConfig: ValidateFuncBuilderConfig = {
  ...loginValidateConfig,
  name: [validateFirstAndLastName('name')()],
  repeatPassword: [
    validateRequired('repeatPassword')(),
    validateRepeatPassword('password', 'repeatPassword')(),
  ],
  consentGdpr: [
    validateChecked(
      'consentGdpr',
      'consentGdpr'
    )('You must agree with our terms.'),
  ],
};

export { validateConfig };
