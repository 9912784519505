// @flow
import { useStateContainer } from '../../../../core/context/StateContainer';

const useNotificationsQueue = () => {
  const [
    {
      notifications: { queue },
    },
  ] = useStateContainer();
  const getQueue = () => queue;

  return {
    getQueue,
  };
};

export { useNotificationsQueue };
