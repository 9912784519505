// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '../../basic/Modal';
import { useStateContainer } from '../../../core/context/StateContainer';
import { dequeueModal } from '../../modals/actions';
import { Button } from '../../basic/Button';
import splashBg from '../../../assets/images/splash-bg.svg';
import { externalPaths } from '../../../core/constants';
import { consent } from '../../auth/authService';
import { useAuth } from '../../auth/talents/AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    minWidth: 310,
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4),
    color: theme.palette.grey.dark,
  },
  container: {
    textAlign: 'center',
  },
  containerTitle: {
    margin: 0,
    fontSize: theme.typography.pxToRem(28),
    fontWeight: theme.typography.fontWeightMedium,
  },
  iconContainer: {
    userSelect: 'none',
    position: 'relative',
    textAlign: 'center',
    height: 'fit-content',
    margin: theme.spacing(4, 0),
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: 42,
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.main,
  },
  introText: {
    margin: theme.spacing(3, 0),
  },
  tcppLink: {
    display: 'inline-block',
    textDecoration: 'underline',
    marginBottom: theme.spacing(3),
  },
}));

function DirectReferralIntroduction() {
  const { loadUser } = useAuth();
  const classes = useStyles();
  const [, dispatch] = useStateContainer();
  const titleId = 'success';
  const contentId = 'successContent';

  const consentAndClose = () => {
    consent().then(loadUser);
    dispatch(dequeueModal());
  };

  return (
    <Modal
      open
      onClose={() => dispatch(dequeueModal())}
      titleId={titleId}
      contentId={contentId}
      paperClassName={classes.root}
    >
      <div className={classes.title}>Welcome</div>
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          <img src={splashBg} alt="icon-background" draggable="false" />
          <FontAwesomeIcon
            icon={['fal', 'hand-peace']}
            className={classes.icon}
          />
        </div>
        <h2 className={classes.containerTitle}>Glad you joined us!</h2>
        <h2 className={classes.containerTitle}>Now find your next job.</h2>
        <div className={classes.introText}>
          You&apos;ve been invited by someone who thinks you may find this
          interesting. We&apos;ve already made you an account with your email
          address, which you can delete at any time if you don&&apos;t find it
          useful.
        </div>
        <div>
          <a
            href={externalPaths.privacyPolicy}
            className={classes.tcppLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Read our Terms & Conditions and Privacy Policy
          </a>
        </div>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={consentAndClose}
        >
          Accept Terms and Conditions
        </Button>
      </div>
    </Modal>
  );
}

export { DirectReferralIntroduction };
