// @flow
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/images/logo.svg';
import logoPrimary from '../../assets/images/logo-primary.svg';
import logoSmall from '../../assets/images/n-logo.svg';
import logoSmallPrimary from '../../assets/images/n-logo-primary.svg';

type Props = {
  primary?: boolean,
  small?: boolean,
  className?: string,
  onClick?: ?() => void,
};

const useStyles = makeStyles({
  root: ({ onClick }) => ({
    cursor: onClick ? 'pointer' : 'initial',
  }),
});

function Logo({ primary, small = false, className = '', onClick }: Props) {
  const classes = useStyles({ onClick });
  let src = logo;
  if (primary && !small) {
    src = logoPrimary;
  } else if (small) {
    if (primary) {
      src = logoSmallPrimary;
    } else {
      src = logoSmall;
    }
  }

  return (
    <img
      src={src}
      onClick={onClick}
      className={`${classes.root} ${className}`}
      alt="logo"
    />
  );
}

Logo.defaultProps = {
  primary: false,
  small: false,
  className: '',
  onClick: null,
};

export { Logo };
