// @flow
import * as React from 'react';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { SideMenu } from './SideMenu/SideMenu';
import { MainMenu } from './MainMenu';
import { TopMenuContext } from '../context/TopMenu';
import { Logo } from '../../basic/Logo';
import { TOP_MENU_HEIGHT } from '../constants';
import { paths } from '../../../core/constants';
import bg from '../../../assets/images/home-img.png';

const { useContext } = React;

const useStyles = makeStyles((theme) => ({
  root: {
    position: ({ isWithBackground }) =>
      isWithBackground ? 'absolute' : 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 3,
    height: TOP_MENU_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    background: ({ isWithBackground }) => {
      return isWithBackground ? 'transparent' : theme.palette.common.white;
    },
    color: theme.palette.text.primary,
    padding: theme.spacing(0, 5),
    borderBottom: `1px solid rgba(255, 255, 255, 15%)`,
  },
  jobsLinkMobile: {
    display: 'none',
    '@media (max-width: 480px)': {
      display: 'block',
    },
  },
  logo: {
    display: 'block',
    '@media (max-width: 680px)': {
      display: 'none',
    },
  },
  logoSmall: {
    display: 'none',

    '@media (max-width: 680px)': {
      display: 'block',
    },
  },
  bg: {
    overflow: 'hidden',
  },
  bgImg: {
    position: 'absolute',
    top: 0,
    right: 50,
    maxHeight: 300,
    maxWidth: '100%',
  },
}));

function TopMenu() {
  const { visible, isWithBackground } = useContext(TopMenuContext);
  const history = useHistory();
  const classes = useStyles({ isWithBackground });

  if (!visible) {
    return null;
  }

  return (
    <React.Fragment>
      {isWithBackground ? (
        <Box
          position="absolute"
          top={-10}
          left={0}
          width="100%"
          height={150}
          bgcolor="primary.main"
          className={classes.bg}
        >
          <img src={bg} alt="Abstract shapes" className={classes.bgImg} />
        </Box>
      ) : null}
      <div className={classes.root}>
        <Logo
          primary={!isWithBackground}
          small
          className={classes.logoSmall}
          onClick={() => {
            history.push(paths.root);
          }}
        />
        <Logo
          primary={!isWithBackground}
          className={classes.logo}
          onClick={() => {
            history.push(paths.root);
          }}
        />
        <MainMenu />
      </div>
      <SideMenu />
    </React.Fragment>
  );
}

export { TopMenu };
