import { useStateContainer } from '../../../../core/context/StateContainer';

function useReferralStats() {
  const [
    {
      auth: { stats, gettingUserStats },
    },
  ] = useStateContainer();

  return {
    loading: gettingUserStats.loading,
    stats:
      gettingUserStats.loading || !stats
        ? null
        : {
            inviteLinksVisits: stats.linkVisits || 0,
            pendingInvitations: stats.directReferrals || 0,
            registered: stats.activatedReferrals || 0,
            applied: stats.applications || 0,
            hired: stats.hires || 0,
          },
  };
}

export { useReferralStats };
