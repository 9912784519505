// @flow
import * as React from 'react';
import { Select } from '../../../basic/form/Select';
import { Row } from '../../../basic/form/Row';
import { Step } from '../../../basic/form/Step';
import { SubmitButton, BackButton } from '../../../basic/form/Buttons';
import { obj } from '../../../../utils/obj';
import {
  roles,
  languages,
  databases,
  platformsAndFrameworks,
} from '../../dropdownsConfig';
import { GreyContainer } from '../GreyContainer';
import { seniority } from '../../../../core/constants';

type Props = {
  includesTechnicalQuestions: boolean,
};

function CandidateCurrentState({ includesTechnicalQuestions }: Props) {
  const buttons = (
    <>
      <BackButton />
      <SubmitButton>Next</SubmitButton>
    </>
  );

  return (
    <Step
      sectionName="Basic information"
      heading="Your latest position"
      buttons={buttons}
    >
      <>
        <Row>
          <Select
            id="currentPosition"
            name="currentPosition"
            required
            label="Your current position/specialization"
          >
            <option value="" />
            {obj.mapKeys<$Keys<typeof roles>, any>(roles, (role) => {
              return (
                <option key={role} value={role}>
                  {roles[role]}
                </option>
              );
            })}
          </Select>
        </Row>

        <Row>
          <Select
            id="seniority"
            name="seniority"
            required
            label="Your current seniority level"
          >
            <option value="" />
            {obj.mapKeys<$Keys<typeof seniority>, string>(seniority, (s) => {
              return (
                <option key={s} value={s}>
                  {seniority[s]}
                </option>
              );
            })}
          </Select>
        </Row>

        {includesTechnicalQuestions ? (
          <GreyContainer>
            <Row>
              <Select
                id="primaryProgrammingLanguage"
                name="primaryProgrammingLanguage"
                required
                label="Primary language"
              >
                <option value="" />
                {obj.mapKeys<$Keys<typeof languages>, string>(
                  languages,
                  (lang) => {
                    return (
                      <option key={lang} value={lang}>
                        {languages[lang]}
                      </option>
                    );
                  }
                )}
              </Select>

              <Select
                id="primaryPlatformAndFramework"
                name="primaryPlatformAndFramework"
                label="Primary platform"
                required
              >
                <option value="" />
                {obj.mapKeys<$Keys<typeof platformsAndFrameworks>, string>(
                  platformsAndFrameworks,
                  (platform) => {
                    return (
                      <option key={platform} value={platform}>
                        {platformsAndFrameworks[platform]}
                      </option>
                    );
                  }
                )}
              </Select>
            </Row>

            <Row>
              <Select
                id="secondaryProgrammingLanguage"
                name="secondaryProgrammingLanguage"
                required
                label="Secondary language"
              >
                <option value="" />
                {obj.mapKeys<$Keys<typeof languages>, string>(
                  languages,
                  (lang) => {
                    return (
                      <option key={lang} value={lang}>
                        {languages[lang]}
                      </option>
                    );
                  }
                )}
              </Select>

              <Select
                id="secondaryPlatformAndFramework"
                name="secondaryPlatformAndFramework"
                label="Secondary platform"
                required
              >
                <option value="" />
                {obj.mapKeys<$Keys<typeof platformsAndFrameworks>, string>(
                  platformsAndFrameworks,
                  (platform) => {
                    return (
                      <option key={platform} value={platform}>
                        {platformsAndFrameworks[platform]}
                      </option>
                    );
                  }
                )}
              </Select>
            </Row>

            <Row noBottomSpace>
              <Select
                id="mainDatabase"
                name="mainDatabase"
                required
                label="Your main database/storage technology"
              >
                <option value="" />
                {obj.mapKeys<$Keys<typeof databases>, string>(
                  databases,
                  (db) => {
                    return (
                      <option key={db} value={db}>
                        {databases[db]}
                      </option>
                    );
                  }
                )}
              </Select>
            </Row>
          </GreyContainer>
        ) : null}
      </>
    </Step>
  );
}

export { CandidateCurrentState };
