// @flow
import * as React from 'react';
import { Step } from '../../../basic/form/Step';
import { Row } from '../../../basic/form/Row';
import { SliderGroup } from '../../../basic/form/SliderGroup';
import { BackButton, SubmitButton } from '../../../basic/form/Buttons';
import type { SliderConfig } from '../../../../core/types';

type Props = {
  sliders: SliderConfig[],
};

function CultureLeadershipRating({ sliders }: Props) {
  const buttons = (
    <>
      <BackButton />
      <SubmitButton completesSection={3}>Done</SubmitButton>
    </>
  );

  return (
    <Step
      sectionName="Culture"
      heading="Select your perfect culture - Leadership"
      subhead="Rate which culture aspects are more important to you."
      buttons={buttons}
    >
      <Row noBottomSpace>
        <SliderGroup name="cultureLeadershipRating" sliders={sliders} />
      </Row>
    </Step>
  );
}

export { CultureLeadershipRating };
