// @flow
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { OnboardingFormsContainer } from '../../components/onboarding-talents/components/OnboardingFormsContainer';
import { DisableScrollOnMount } from '../../components/basic/DisableScrollOnMount';
import { HideTopArrow } from '../../components/wordpress/HideTopArrow';
import { HideJobsNavigationArrows } from '../../components/wordpress/HideJobsNavigationArrows';
import { PageHead } from '../../components/basic/PageHead';

function OnboardingRoot() {
  const query = useLocation().search;
  const params = new URLSearchParams(query);
  const jobAdReferenceKey = params.get('jark');

  return (
    <DisableScrollOnMount>
      <HideJobsNavigationArrows>
        <HideTopArrow>
          <PageHead title="Edit profile | NobleHire" />
          <OnboardingFormsContainer
            query={query}
            jobAdReferenceKey={jobAdReferenceKey}
          />
        </HideTopArrow>
      </HideJobsNavigationArrows>
    </DisableScrollOnMount>
  );
}

export { OnboardingRoot };
