// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.h4,
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.primary.main,
    padding: theme.spacing(10),
    borderBottom: `1px solid ${theme.palette.grey.light}`,
  },
}));

type Props = {
  title: string,
  testId?: string,
};

function Header({ testId, title }: Props) {
  const classes = useStyles();
  return (
    <div data-testid={testId} className={classes.root}>
      {title}
    </div>
  );
}

Header.defaultProps = {
  testId: undefined,
};

export { Header };
