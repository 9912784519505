// @flow
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import { Modal } from '../../../basic/Modal';
import { useStateContainer } from '../../../../core/context/StateContainer';
import { dequeueModal } from '../../../modals/actions';
import 'react-image-crop/dist/ReactCrop.css';
import { UploadImage } from '../../../basic/UploadImage';

function UploadProductGalleryImage({
  onUpload,
  autoClose = true,
  cropSettings = { aspect: undefined },
  initialCropStateOnUpload = {},
}: {
  onUpload: ({ url: string, blob: any }, (boolean) => void) => void,
  autoClose?: boolean,
  cropSettings?: {
    aspect?: number,
    keepSelection?: boolean,
    unit?: string,
    width?: number,
    height?: number,
    x?: number,
    y?: number,
  },
  initialCropStateOnUpload?: {
    aspect?: number,
    keepSelection?: boolean,
    unit?: string,
    width?: number,
    height?: number,
    x?: number,
    y?: number,
  },
}) {
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [, dispatch] = useStateContainer();
  const titleId = 'uploadProductGalleryImageTitle';
  const contentId = 'uploadProductGalleryImageContent';

  const internalOnUpload = React.useCallback(
    ({ url, blob }: { url: string, blob: ?Blob }) => {
      if (autoClose) dispatch(dequeueModal());
      onUpload({ url, blob }, setDisabled);
    },
    [onUpload, autoClose, dispatch]
  );

  return (
    <Modal
      open
      onClose={() => dispatch(dequeueModal())}
      titleId={titleId}
      contentId={contentId}
      withCloseButton={false}
    >
      <Box
        mb={2}
        minWidth={400}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>Crop image to the right size</Box>
        <Box
          style={{ cursor: 'pointer' }}
          onClick={disabled ? null : () => dispatch(dequeueModal())}
        >
          <FontAwesomeIcon icon={['fal', 'times']} size="2x" />
        </Box>
      </Box>
      <UploadImage
        acceptButtonText="Upload"
        disabled={disabled}
        onAccepted={internalOnUpload}
        cropSettings={{ aspect: undefined, ...cropSettings }}
        initialCropStateOnUpload={initialCropStateOnUpload}
      />
    </Modal>
  );
}

export { UploadProductGalleryImage };
