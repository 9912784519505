// @flow
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import type { EditableJobCtx, EditableJobErrors } from './types';
import { useEditableJob } from './useEditableJob';
import { paths } from '../../core/constants';
import { enqueueNotification } from '../notifications/actions';
import { Confirm } from '../notifications/components/Confirm';
import { saveJobAdmin, saveJob } from './jobsService';
import { useStateContainer } from '../../core/context/StateContainer';
import type { Company } from '../companies/common/types';
import { Error as ErrorNotification } from '../notifications/components/Error';
import { Success } from '../notifications/components/Success';
import { convJobToServerJob } from './utils';

type Ctx = EditableJobCtx<any>;

const Context = createContext<Ctx | null>(null);

export function JobCreateContext({
  children,
  company,
  mode,
}: {
  children: React$Node,
  company: Company<any>,
  mode: 'preview' | 'edit',
}) {
  const loc = useLocation();
  const history = useHistory();
  const [
    {
      auth: { user, isAdmin },
    },
    dispatch,
  ] = useStateContainer();
  const loading = false;
  const isPreview = mode === 'preview';
  const isEdit = mode === 'edit';
  const { state, actions, validate } = useEditableJob({
    editProductDescription: company.product,
  });
  const [alreadyPopulatedProductImages, setAlreadyPopulatedProductImages] =
    useState(false);

  useEffect(() => {
    if (
      (!state.editProductImages || !state.editProductImages.length) &&
      !alreadyPopulatedProductImages
    ) {
      actions.setEditProductImages(company.productImages);
      setAlreadyPopulatedProductImages(true);
    }
  }, [
    company,
    actions,
    state.editProductImages,
    alreadyPopulatedProductImages,
  ]);

  function validateSave() {
    const err: EditableJobErrors = {};
    if (!state.editTitle) err.title = 'Title is required';
    if (!state.editRole) err.role = 'Role';
    return err;
  }

  // ---- editing states helpers -------------------------------- //
  function jobUrl() {
    return paths.companiesDashboard;
  }

  function redirToDashboard() {
    history.push({
      pathname: paths.companiesDashboard.replace(':slug', company.slug),
    });
  }

  function redirToPreview() {
    history.push({ pathname: loc.pathname, search: '?preview=true' });
  }

  function redirToEdit() {
    history.push({ pathname: loc.pathname, search: '?edit=true' });
  }

  function exitEdit() {
    actions.setExiting(true);
    dispatch(
      enqueueNotification(
        <Confirm
          vertical="top"
          horizontal="center"
          disableAutoHide
          onClose={(choice) => {
            if (choice === 'OK') redirToDashboard();
            actions.setExiting(false);
          }}
        >
          All unsaved changes will be lost. Exit anyway?
        </Confirm>
      )
    );
  }

  function continueEditing() {
    redirToEdit();
  }

  function request(
    publishing: boolean = false,
    { onSuccess }: { onSuccess?: (any) => void } = {}
  ) {
    const reqData = convJobToServerJob({
      title: state.editTitle,
      location: state.editLocation,
      seniority: state.editSeniority,
      primaryLanguage: state.editPrimaryLanguage,
      primaryPlatformOrFramework: state.editPrimaryPlatformOrFramework,
      secondaryLanguage: state.editSecondaryLanguage,
      secondaryPlatformOrFramework: state.editSecondaryPlatformOrFramework,
      mainDatabase: state.editMainDatabase,
      description: state.editDescription,
      isPublic: publishing,
      type: state.editType,
      jobTypeComment: null,
      customerFacing: state.editCustomerFacing,
      businessTraveling: state.editBusinessTravel,
      businessTravelComment: null,
      stockOptions: state.editStockOptions,
      teamLeadLink: state.editTeamLeadLink,
      hiringProcessSteps: state.editHiringProcessSteps,
      tools: state.editTools,
      timeSpendingDetails: state.editTimeSpendingDetails,
      coreRequirements: state.editCoreRequirements,
      responsibilities: state.editResponsibilities,
      perks: state.editPerks,
      role: state.editRole,
      salary: state.editSalary,
      salaryCurrency: state.editSalaryCurrency,
      salaryPeriod: 'MONTH',
      teamSize: state.editTeamSize,
      homeOffice: state.editHomeOffice,
      homeOfficePer: 'WEEK',
      fullyRemote: state.editFullyRemote,
      teamLeadImage: state.editTeamLeadImage,
      teamLeadName: state.editTeamLeadName,
      teamLeadRole: state.editTeamLeadRole,
      productImages: state.editProductImages,
      productDescription: state.editProductDescription,
      companyId: company.id,
    });

    const saveFn = isAdmin(user) ? saveJobAdmin : saveJob;
    saveFn(reqData)
      .then((response) => {
        actions.setSaving(false);
        if (typeof onSuccess === 'function') onSuccess(response);
      })
      .catch((err) => {
        const dupTitleMessage = 'is not unique for company';
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message.includes(dupTitleMessage)
        ) {
          dispatch(
            enqueueNotification(
              <ErrorNotification>
                Job ad with the same title already exists.
              </ErrorNotification>
            )
          );
        } else {
          dispatch(
            enqueueNotification(
              <ErrorNotification>
                We couldn&apos;t save your job ad. Please, try again.
              </ErrorNotification>
            )
          );
        }

        actions.setSaving(false);
      });
  }

  function save() {
    const e: EditableJobErrors = validateSave();

    if (Object.keys(e).length) {
      // show errors
      actions.setErrors(e);
      return;
    }

    actions.setSaving(true);
    request(false, {
      onSuccess: (data) => {
        const { companySlug, slug } = data;
        const pathname = paths.jobEdit.replace(
          ':slug+',
          `${companySlug}/${slug}`
        );

        dispatch(enqueueNotification(<Success>Saved successfully!</Success>));
        history.replace({
          pathname,
          search: '?edit=true',
        });
      },
    });
  }

  function publish() {
    // do validation of the changes
    const e: EditableJobErrors = validate();

    if (Object.keys(e).length) {
      // show errors
      actions.setErrors(e);
    } else {
      request(true, {
        onSuccess: () => {
          dispatch(enqueueNotification(<Success>Saved successfully!</Success>));
          actions.setEditIsPublic(true);
          redirToDashboard();
        },
      });
    }
  }

  function unpublish() {
    actions.setEditIsPublic(false);
    save();
  }

  function preview() {
    redirToPreview();
  }

  // ------------------------------------------------------------ //

  const value = {
    company,
    loading,
    jobNotFound: false,
    isPreview,
    isEdit,

    errors: state.errors,
    setErrors: actions.setErrors,
    saving: state.saving,
    exiting: state.exiting,
    isTech: state.isTech,
    isPublic: state.editIsPublic,
    location: state.editLocation,
    setLocation: actions.setEditLocation,
    title: state.editTitle,
    setTitle: actions.setEditTitle,
    description: state.editDescription,
    setDescription: actions.setEditDescription,
    role: state.editRole,
    setRole: actions.setEditRole,
    seniority: state.editSeniority,
    setSeniority: actions.setEditSeniority,

    type: state.editType,
    setType: actions.setEditType,
    salary: state.editSalary,
    setSalary: actions.setEditSalary,
    salaryCurrency: state.editSalaryCurrency,
    setSalaryCurrency: actions.setEditSalaryCurrency,
    stockOptions: state.editStockOptions,
    setStockOptions: actions.setEditStockOptions,
    homeOffice: state.editHomeOffice,
    setHomeOffice: actions.setEditHomeOffice,
    teamSize: state.editTeamSize,
    setTeamSize: actions.setEditTeamSize,
    fullyRemote: state.editFullyRemote,
    setFullyRemote: actions.setEditFullyRemote,
    customerFacing: state.editCustomerFacing,
    setCustomerFacing: actions.setEditCustomerFacing,
    businessTravel: state.editBusinessTravel,
    setBusinessTravel: actions.setEditBusinessTravel,
    primaryLanguage: state.editPrimaryLanguage,
    setPrimaryLanguage: actions.setEditPrimaryLanguage,
    primaryPlatformOrFramework: state.editPrimaryPlatformOrFramework,
    setPrimaryPlatformOrFramework: actions.setEditPrimaryPlatformOrFramework,
    secondaryLanguage: state.editSecondaryLanguage,
    setSecondaryLanguage: actions.setEditSecondaryLanguage,
    secondaryPlatformOrFramework: state.editSecondaryPlatformOrFramework,
    setSecondaryPlatformOrFramework:
      actions.setEditSecondaryPlatformOrFramework,
    mainDatabase: state.editMainDatabase,
    setMainDatabase: actions.setEditMainDatabase,

    coreRequirements: state.editCoreRequirements,
    addCoreRequirement: actions.addCoreRequirement,
    removeCoreRequirement: actions.removeCoreRequirement,
    editCoreRequirement: actions.editCoreRequirement,

    responsibilities: state.editResponsibilities,
    addResponsibility: actions.addResponsibility,
    removeResponsibility: actions.removeResponsibility,
    editResponsibility: actions.editResponsibility,

    hiringProcessSteps: state.editHiringProcessSteps,
    addHiringProcessStep: actions.addHiringProcessStep,
    editHiringProcessStep: actions.editHiringProcessStep,
    removeHiringProcessStep: actions.removeHiringProcessStep,

    perks: state.editPerks,
    addPerk: actions.addPerk,
    editPerk: actions.editPerk,
    removePerk: actions.removePerk,

    teamLeadImage: state.editTeamLeadImage,
    uploadTeamLeadImage: actions.uploadTeamLeadImage,
    setTeamLeadImage: actions.setEditTeamLeadImage,
    teamLeadName: state.editTeamLeadName,
    setTeamLeadName: actions.setEditTeamLeadName,
    teamLeadRole: state.editTeamLeadRole,
    setTeamLeadRole: actions.setEditTeamLeadRole,
    teamLeadLink: state.editTeamLeadLink,
    setTeamLeadLink: actions.setEditTeamLeadLink,

    timeSpendingDetails: state.editTimeSpendingDetails,
    addTimeSpendingDetails: actions.addTimeSpendingDetails,
    removeTimeSpendingDetails: actions.removeTimeSpendingDetails,
    updateTimeSpendingDetails: actions.updateTimeSpendingDetails,

    tools: state.editTools,
    addTool: actions.addTool,
    removeTool: actions.removeTool,
    updateTool: actions.updateTool,

    productImages: state.editProductImages,
    pickProductImages: actions.pickProductImages,
    uploadProductImage: actions.uploadProductImage,
    removeProductImage: actions.removeProductImage,
    moveProductImage: actions.moveProductImage,

    productDescription: state.editProductDescription,
    setProductDescription: actions.setEditProductDescription,

    exitEdit,
    continueEditing,
    save,
    publish,
    unpublish,
    preview,
    jobUrl,
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useJobCreate() {
  const c = useContext(Context);
  if (c === null) throw Error('Improper use of JobCreateContext');
  return c;
}
