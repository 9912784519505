import React from 'react';
import { paths } from '../core/constants';
import { useHistory } from 'react-router-dom';
import { makeStyles, Container } from '@material-ui/core';
import { PageHead } from '../components/basic/PageHead';
import { Button } from '../components/basic/Button';
import { Footer } from '../components/Footer';
import { TOP_MENU_HEIGHT } from '../components/menu/constants';
import nobleMatchTextImg from '../assets/images/noble-match-text.svg';
import nobleMatchVisualImg from '../assets/images/noble-match-visual.svg';
import featuredImg from '../assets/images/matching-featured-image.png';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    minHeight: `calc(90vh - ${TOP_MENU_HEIGHT}px)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '@media (max-width: 748px)': {
      padding: theme.spacing(4, 0),
      minHeight: '100%',
      flexDirection: 'column-reverse',
    },
  },

  left: {
    flex: 1,
    maxWidth: '368px',
    '& img': {
      maxWidth: '100%',
    },
    '& h1': {
      display: 'none',
    },
    '& h2': {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& .helperText': {
      display: 'block',
      marginTop: theme.spacing(2),
      color: theme.palette.grey[500],
      fontSize: theme.typography.pxToRem(14),
    },
    '@media (max-width: 748px)': {
      textAlign: 'center',
    },
  },
  right: {
    '& img': {
      width: '100%',
      maxWidth: '100%',

      '@media (max-width: 1120px)': {
        maxWidth: 600,
      },
      '@media (max-width: 980px)': {
        maxWidth: 400,
      },
    },
    '@media (max-width: 748px)': {
      marginBottom: theme.spacing(4),
    },
  },
}));

export function MatchMe() {
  const history = useHistory();
  const classes = useStyles();

  // eslint-disable-next-line
  const onMatchMeClick = () => history.push(paths.matchMeForm);

  return (
    <div className={classes.root}>
      <PageHead
        title="Noble Match | NobleHire"
        description="Get matched to a job you'll love! Just leave us your specific work preferences and we'll take care of the rest."
        socialMedia={{
          image: featuredImg,
        }}
      />
      <Container>
        <div className={classes.container}>
          <div className={classes.left}>
            <h1>Noble Match</h1>
            <img src={nobleMatchTextImg} alt="Noble match" />
            <h2>
              Get matched to a job you&apos;ll love! Just leave us your specific
              work preferences and we&apos;ll take care of the rest.
            </h2>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={onMatchMeClick}
            >
              Match me!
            </Button>
            <span className="helperText">
              Your personal information will never be shared with any companies,
              unless you explicitly would like it to be shared with them.
            </span>
          </div>
          <div className={classes.right}>
            <img src={nobleMatchVisualImg} alt="Noble match visual" />
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
