import { makeStyles } from '@material-ui/styles';

const styles = (theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: theme.palette.common.white,
  },
  form: { width: '100%', height: '100%' },
  formRoot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(5, 0),
    overflow: 'auto',

    '@media (max-width: 768px)': {
      padding: 0,
    },
  },
  title: { textAlign: 'center' },
});

const useStyles = makeStyles(styles);

export { styles, useStyles };
