// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '../../../basic/Modal';
import { Button } from '../../../basic/Button';
import iconBg from '../../../../assets/images/icon-bg.svg';
import { useOnboardingModalFrame } from './useOnboardingModalFrame';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    '@media (max-width: 768px)': {
      justifyContent: 'flex-start',
      height: 'initial',
    },
  },
  upperSection: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  headingContainer: { position: 'absolute', top: 15, left: 40 },
  heading: { margin: 0, fontWeight: 300 },
  iconContainer: {
    userSelect: 'none',
    position: 'relative',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: 46,
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.main,
  },
  titleContainer: {
    textAlign: 'center',
    width: 560,
    margin: 'auto',

    '@media (max-width: 768px)': {
      width: 'auto',
    },
  },
  closeButton: {
    cursor: 'pointer',
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  button: { width: 120 },
}));

type Props = {
  children: React.Node,
  heading: React.Node,
  icon: string | string[],
  title: React.Node,
  buttonText: React.Node,
  titleId: string,
  contentId: string,
  onClose?: () => void,
};

function OnboardingModalFrame({
  children,
  icon,
  title,
  buttonText,
  titleId,
  contentId,
  onClose,
}: Props) {
  const classes = useStyles();
  const { onClose: onModalClose } = useOnboardingModalFrame();

  const close = React.useCallback(() => {
    onModalClose();

    if (onClose) {
      onClose();
    }
  }, [onModalClose, onClose]);

  return (
    <Modal
      open
      onClose={close}
      titleId={titleId}
      contentId={contentId}
      fullScreen
      withCloseButton={false}
    >
      <div className={classes.container}>
        <div className={classes.upperSection}>
          <div>
            <div className={classes.iconContainer}>
              <img src={iconBg} alt="icon-background" draggable="false" />
              <FontAwesomeIcon icon={['fal', icon]} className={classes.icon} />
            </div>
            <div className={classes.titleContainer}>{title}</div>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            type="button"
            color="primary"
            className={classes.button}
            onClick={close}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

OnboardingModalFrame.defaultProps = {
  onClose: () => {},
};

export { OnboardingModalFrame };
