// @flow

import React from 'react';
import type { CompanyType } from './types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[100],
    borderRadius: theme.borderRadius,
    overflow: 'hidden',
    border: `1px solid transparent`,
    cursor: 'pointer',
    margin: 0,
    padding: 0,
    outline: 'none',

    '&:hover': {
      background: theme.palette.primary.lightest,
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  cover: {
    userSelect: 'none',
    width: '100%',
    height: 200,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(2, 4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    maxWidth: 80,
    maxHeight: 40,
  },
  jobsCounter: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
}));

export function CompanyCard({
  data,
  onClick,
}: {
  data: CompanyType,
  onClick: () => void,
}) {
  const classes = useStyles();
  return (
    <button type="button" className={classes.root} onClick={onClick}>
      {data.mainImages && data.mainImages.length ? (
        <div
          className={classes.cover}
          style={{
            backgroundImage: `url("${
              data.mainImages[data.mainImages.length - 1].src
            }")`,
          }}
        />
      ) : null}
      <div className={classes.infoBox}>
        {data.logos && data.logos.length ? (
          <div>
            <img
              src={data.logos[data.logos.length - 1].src}
              alt={`${data.brand || 'Company'} logo`}
              className={classes.logo}
            />
          </div>
        ) : null}
        <div className={classes.jobsCounter}>{data.brand}</div>
      </div>
    </button>
  );
}
