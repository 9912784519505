// @flow
type O<K, V> = { [key: K]: V };
type FnKey<K> = (k: K) => any;
type FnVal<V> = (v: V) => any;
type FnReduce<A, V> = (acc: A, v: V, ...any) => A;

function keys<K, V>(o: O<K, V>): K[] {
  return Object.keys(o);
}
function vals<K, V>(o: O<K, V>): V[] {
  return keys<K, V>(o).map(function extractVal(k) {
    return o[k];
  });
}
function mapKeys<K, V>(o: O<K, V>, fn: FnKey<K>): any[] {
  return keys(o).map((k) => fn(k));
}

function mapVals<K, V>(o: O<K, V>, fn: FnVal<V>): any[] {
  return vals(o).map((v) => fn(v));
}

function reduceKeys<K, V, I>(o: O<K, V>, fn: FnReduce<I, K>, initial: I): any {
  return keys(o).reduce(fn, initial);
}

function empty(o: O<any, any>): boolean {
  return keys(o).length < 1;
}

const obj = {
  keys,
  vals,
  mapKeys,
  mapVals,
  reduceKeys,
  empty,
};

export { obj };
