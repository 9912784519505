// @flow
import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { JobEdit } from '../../components/jobs/JobEdit';
import { useJobEdit } from '../../components/jobs/JobEditContext';
import { FullPageLoader } from '../../components/basic/FullPageLoader';
import { useMyCompany } from '../../components/companies/common/context/MyCompanyContext';
import { useStateContainer } from '../../core/context/StateContainer';
import { paths } from '../../core/constants';

export function JobEditPage({ asPreviewBg }: { asPreviewBg: boolean }) {
  const { slug } = useParams();
  const myCompany = useMyCompany();
  const [
    {
      auth: { user, isAdmin },
    },
  ] = useStateContainer();
  const jobEdit = useJobEdit();
  const je = { ...jobEdit, isEdit: jobEdit.isEdit || asPreviewBg };

  if (myCompany.loading && !isAdmin(user)) {
    return (
      <div style={{ height: '100vh' }}>
        <FullPageLoader>
          Unpacking your interplanetary job template...
        </FullPageLoader>
      </div>
    );
  }

  if (
    myCompany.slug !== slug.substring(0, slug.indexOf('/')) &&
    user &&
    !isAdmin(user)
  ) {
    return <Redirect to={paths.root} />;
  }

  return <JobEdit job={je} />;
}
