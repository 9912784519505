// @flow
import {
  validateRequired,
  validateRepeatPassword,
  validateChecked,
  validateEmail,
  validatePassword,
} from '../../../../../core/validators';
import type { ValidateFuncBuilderConfig } from '../../../../../core/types';

const validateConfig: ValidateFuncBuilderConfig = {
  brand: [validateRequired('brand')()],
  name: [validateRequired('name')()],
  email: [validateRequired('email')(), validateEmail('email')()],
  password: [
    validateRequired('password')(),
    validatePassword('password')(
      'The password should be at least 3 symbols long.'
    ),
  ],
  repeatPassword: [
    validateRequired('repeatPassword')(),
    validateRepeatPassword('password', 'repeatPassword')(),
  ],
  consentGdpr: [
    validateChecked(
      'consentGdpr',
      'consentGdpr'
    )('You must agree with our terms.'),
  ],
};

export { validateConfig };
