// @flow
import React, { useRef } from 'react';
import type { Node } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, useTheme, Box, Card } from '@material-ui/core';
import { useMQ } from '../../useMQ';
import { useDrag, useDrop } from 'react-dnd';
import type { CarouselItem } from './context/CompanyContext';
import { UnderCoverContainer, UnderCover } from '../../basic/UnderCover';
import { EditControls } from '../../basic/EditControls';
import TinySlider from 'tiny-slider-react';

export const ITEM_WIDTH = 420;
export const ITEM_HEIGHT = 300;

const useStyles = makeStyles((theme) => ({
  editBorder: {
    border: `1px solid transparent`,
    background: theme.palette.grey[100],

    '&:hover': {
      border: `1px solid ${theme.palette.edit.main}`,
      boxShadow: theme.boxShadow,
    },
  },
}));

export type CarouselEditorProps = {
  items: CarouselItem[],
  onAdd: (Image) => void,
  onDelete: (CarouselItem) => void,
  onMove: ({ drag: CarouselItem, drop: CarouselItem }) => void,
};
export function CarouselEditor({
  items,
  onAdd,
  onDelete,
  onMove,
}: CarouselEditorProps) {
  const mq = useMQ();
  const classes = useStyles();
  const theme = useTheme();
  let cols = 1;
  if (mq.md) cols = 2;
  if (mq.xxl) cols = 3;
  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(${cols}, 1fr)`}
      style={{
        gridRowGap: theme.spacing(2),
        columnGap: theme.spacing(2),
      }}
    >
      {items.map((item) => {
        return (
          <CarouselEditorItem
            key={item.id}
            item={item}
            onDelete={onDelete}
            onMove={onMove}
          />
        );
      })}
      <Card
        elevation={0}
        style={{
          cursor: 'pointer',
          borderRadius: theme.shape.borderRadius,
          color: theme.palette.grey[500],
          fontWeight: theme.typography.fontWeightBold,
        }}
        className={classes.editBorder}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={ITEM_HEIGHT}
          onClick={onAdd}
        >
          <Box fontWeight={theme.typography.fontWeightBold}>
            Click to add image
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export type CarouselEditorItemProps = {
  item: CarouselItem,
  onDelete: (CarouselItem) => void,
  onMove: ({ drag: CarouselItem, drop: CarouselItem }) => void,
  styleProps?: { [key: string]: any },
};
export function CarouselEditorItem({
  item,
  onDelete,
  onMove,
  styleProps = {},
}: CarouselEditorItemProps) {
  const theme = useTheme();
  const [, dragRef] = useDrag({
    item: { type: item.dndType, ...item },
    end: (drag, monitor) => {
      const drop = monitor.getDropResult();
      if (drag && drop) onMove({ drag, drop });
    },
    collect: (monitor) => ({
      dragging: monitor.isDragging(),
    }),
  });

  const [dropProps, dropRef] = useDrop({
    accept: item.dndType,
    drop: () => item,
    collect: (monitor) => {
      const drag = monitor.getItem();
      const overSelf = drag && drag.id === item.id;
      return {
        isHovered: !overSelf && monitor.isOver({ shallow: true }),
      };
    },
  });
  return (
    <UnderCoverContainer uncover="CarouselEditorItem">
      <Box ref={dropRef}>
        <Box key={item.data.src} ref={dragRef} position="relative">
          <Box
            style={{
              cursor: 'grab',
              border: `3px solid ${
                dropProps.isHovered ? theme.palette.edit.main : 'transparent'
              }`,
            }}
          >
            <div key={item.data.src} style={{ position: 'relative' }}>
              <div
                style={{
                  flexShrink: 0,
                  height: ITEM_HEIGHT,
                  backgroundImage: `url("${item.data.src}")`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  border: 0,
                  borderRadius: theme.shape.borderRadius,
                  ...styleProps,
                }}
              />
            </div>
            <UnderCover uncoveredBy="CarouselEditorItem">
              <Box
                position="absolute"
                bottom={theme.spacing(2)}
                right={theme.spacing(2)}
              >
                <EditControls onRemove={() => onDelete(item)} />
              </Box>
            </UnderCover>
          </Box>
        </Box>
      </Box>
    </UnderCoverContainer>
  );
}

export function Carousel({
  children,
  showArrows = true,
  tinySliderSettings,
}: {
  children: Node,
  tinySliderSettings?: any, // https://github.com/ganlanyuan/tiny-slider#options
  showArrows?: boolean,
}) {
  const tslRef = useRef(null);
  const theme = useTheme();
  const styles = {
    arrow: {
      userSelect: 'none',
      padding: theme.spacing(1.5, 1.9),
      cursor: 'pointer',
      color: '#fff',
      background: theme.palette.common.black,
      borderRadius: 5,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    offset: (leftOrRight: 'left' | 'right') => ({ [leftOrRight]: 20 }),
  };

  function onNavigation(dir: 'prev' | 'next') {
    if (tslRef.current) {
      tslRef.current.slider.goTo(dir);
    }
  }

  return (
    <div id="wrapper" style={{ position: 'relative ' }}>
      <TinySlider
        ref={tslRef}
        settings={{
          items: React.Children.count(children),
          gutter: theme.spacing(2),
          // lazyload: true,
          nav: false,
          mouseDrag: true,
          fixedWidth: ITEM_WIDTH,
          controls: false,
          ...tinySliderSettings,
        }}
      >
        {children}
      </TinySlider>
      {showArrows ? (
        <Box
          onClick={() => onNavigation('prev')}
          style={{ ...styles.arrow, ...styles.offset('left') }}
        >
          <FontAwesomeIcon icon={['fal', 'arrow-left']} />
        </Box>
      ) : null}
      {showArrows ? (
        <Box
          onClick={() => onNavigation('next')}
          style={{ ...styles.arrow, ...styles.offset('right') }}
        >
          <FontAwesomeIcon icon={['fal', 'arrow-right']} />
        </Box>
      ) : null}
    </div>
  );
}
