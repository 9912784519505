// @flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { Logo } from '../../../basic/Logo';
import { paths } from '../../../../core/constants';
import sidePanelBg from '../../../../assets/images/auth-img2.jpg';
import { useStateContainer } from '../../../../core/context/StateContainer';
import {
  setPendingGetReferralLinkStatus,
  setPendingComposeDirectReferralStatus,
} from '../../../referrals/actions';
import { TOP_MENU_HEIGHT } from '../../../menu/constants';

type Props = {
  history: { push: (string) => void },
  reversed: boolean,
  children: React.Node,
};

const MENU_OFFSET = TOP_MENU_HEIGHT;
const useStyles = makeStyles((theme) => {
  const perfectCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const fullWidthHeight = {
    width: '100%',
    height: '100%',
  };
  const pos = (type) => ({
    top: 0,
    left: 0,
    position: type,
  });

  return {
    root: (props) => ({
      ...pos('fixed'),
      ...fullWidthHeight,
      ...perfectCenter,
      background: '#fff',
      flexDirection: props.reversed ? 'row-reverse' : 'row',

      '@media (max-width: 768px)': {
        flexDirection: 'column',
      },
    }),
    sidePanel: {
      ...fullWidthHeight,
      position: 'relative',
      backgroundColor: theme.palette.primary.main,
      backgroundImage: `url(${sidePanelBg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'bottom',
      height: '100%',
      width: '100%',
      flex: 1,

      '@media (max-width: 768px)': {
        position: 'fixed',
        top: 0,
        zIndex: 1,
        flex: 'initial',
        height: MENU_OFFSET,
        background: theme.palette.common.white,
      },
    },
    sidePanelHeader: (props) => ({
      height: MENU_OFFSET,
      padding: theme.spacing(0, 5),
      display: 'flex',
      justifyContent: props.reversed ? 'flex-end' : 'flex-start',
      alignItems: 'center',

      '@media (max-width: 768px)': {
        height: MENU_OFFSET,
      },
    }),
    sidePanelContent: {
      position: 'absolute',
      maxWidth: 350,
      bottom: 0,
      left: 0,
      margin: theme.spacing(8, 6),
      color: theme.palette.common.white,
      fontSize: theme.typography.pxToRem(36),
      fontWeight: 600,
    },
    logo: {
      display: 'block',
      '@media (max-width: 768px)': {
        display: 'none',
      },
    },
    logoPrimary: {
      display: 'none',
      '@media (max-width: 768px)': {
        display: 'block',
        maxWidth: 200,
      },
    },
    content: {
      ...fullWidthHeight,
      position: 'relative',
      padding: theme.spacing(0, 3),
      flex: 2,
      height: '100%',
      width: '100%',
      flexDirection: 'column',

      '@media (max-width: 768px)': {
        marginTop: MENU_OFFSET,
        padding: theme.spacing(3, 3),
        overflow: 'auto',
      },
    },
    header: {
      ...pos('absolute'),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: MENU_OFFSET,
      boxSizing: 'border-box', // TODO: global style
      width: '100%',
      padding: theme.spacing(0, 5),

      '@media (max-width: 768px)': {
        position: 'fixed',
        height: MENU_OFFSET,
        background: 'transparent',
        zIndex: 1,
      },
    },
    closeButton: {
      cursor: 'pointer',
      zIndex: 1,
    },
    body: {
      ...fullWidthHeight,
      ...perfectCenter,
    },
  };
});

const AuthFrame = withRouter((props: Props) => {
  const { history, children, reversed } = props;
  const [
    {
      referrals: { pending },
    },
    dispatch,
  ] = useStateContainer();
  const classes = useStyles(props);
  const onClose = () => {
    if (pending.getReferralLink) {
      dispatch(setPendingGetReferralLinkStatus(false));
    }

    if (pending.composeDirectReferral) {
      dispatch(setPendingComposeDirectReferralStatus(false));
    }

    history.push(paths.root);
  };

  return (
    <div className={classes.root}>
      <div className={classes.sidePanel}>
        <div className={classes.sidePanelHeader}>
          <Logo className={classes.logo} />
          <Logo className={classes.logoPrimary} primary />
        </div>
        <div className={classes.sidePanelContent}>
          Find a job in one of the best tech companies in Bulgaria.
        </div>
      </div>

      <div className={classes.header}>
        {reversed ? null : <div />}
        <Close className={classes.closeButton} onClick={onClose} />
        {reversed ? <div /> : null}
      </div>

      <div className={classes.content}>
        <div className={classes.body}>{children}</div>
      </div>
    </div>
  );
});

export { AuthFrame };
