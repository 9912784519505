// @flow
/* eslint-disable no-useless-escape */
import type { Validator, FormValues, TupleValidatorResult } from './types';
import { validateUrl as validateUrlUtil } from '../utils/validateUrl';
import { validateLinkedInUrl as validateLinkedInUrlUtil } from '../utils/validateLinkedInUrl';
import { obj } from '../utils/obj';

function validateRequired(
  fieldName: string,
  skipValidation?: (values: FormValues, field: string) => boolean
) {
  return function withMessage(
    errorMessage: string = 'This field is required.'
  ): Validator {
    return function validator(values: FormValues): TupleValidatorResult {
      if (skipValidation && skipValidation(values, fieldName)) {
        return [true, errorMessage];
      }

      const fieldValue = values[fieldName];
      let isValid = !!fieldValue;

      if (Array.isArray(fieldValue) && !fieldValue.length) {
        isValid = false;
      }

      return [isValid, errorMessage];
    };
  };
}

function validateEmail(fieldName: string) {
  return function withMessage(
    errorMessage: string = 'Invalid email address.'
  ): Validator {
    return function validator(values: FormValues): TupleValidatorResult {
      const value = values[fieldName];
      const isValid = !!(value && value.match(/\S+@\S+\.\S+/gi));

      return [isValid, errorMessage];
    };
  };
}

function validatePassword(fieldName: string) {
  return function withMessage(
    errorMessage: string = 'Invalid password format.'
  ): Validator {
    return function validator(values: FormValues): TupleValidatorResult {
      const value = values[fieldName];
      const isValid: boolean = !!(value && value.length > 3);

      return [isValid, errorMessage];
    };
  };
}

function validateRepeatPassword(
  passwordFieldName: string,
  repeatPasswordFieldName: string
) {
  return function withMessage(
    errorMessage: string = 'Passwords must match.'
  ): Validator {
    return function validator(values: FormValues): TupleValidatorResult {
      const passwordValue = values[passwordFieldName];
      const repeatPasswordValue = values[repeatPasswordFieldName];
      const isValid = passwordValue === repeatPasswordValue;

      return [isValid, errorMessage];
    };
  };
}

function validateChecked(heystack: string, needle: string) {
  return function withMessage(
    errorMessage: string = 'This checkbox is required.'
  ): Validator {
    return function validator(values: FormValues): TupleValidatorResult {
      const arrayOfChecked: string[] = values[heystack];
      const isValid = !!(arrayOfChecked && arrayOfChecked.includes(needle));

      return [isValid, errorMessage];
    };
  };
}

function validateEitherOr(field: string, orField: string) {
  return function withMessage(errorMessage: string = 'Required.'): Validator {
    return function validator(values: FormValues): TupleValidatorResult {
      const fieldValue = values[field];
      const orFieldValue = values[orField];
      const isValid =
        (fieldValue && fieldValue.length) ||
        (orFieldValue && orFieldValue.length);

      return [isValid, errorMessage];
    };
  };
}

function validateUrl(field: string) {
  return function withMessage(
    errorMessage: string = 'Please, provide a valid URL'
  ): Validator {
    return function validator(values: FormValues): TupleValidatorResult {
      const fieldValue = values[field];
      const isValid = fieldValue && validateUrlUtil(fieldValue);

      return [isValid, errorMessage];
    };
  };
}

function validateLinkedInUrl(
  field: string,
  skipValidation?: (values: FormValues, field: string) => boolean
) {
  return function withMessage(
    errorMessage: string = 'Please, provide a valid LinkedIn profile url'
  ): Validator {
    return function validator(values: FormValues): TupleValidatorResult {
      if (skipValidation && skipValidation(values, field)) {
        return [true, errorMessage];
      }

      const fieldValue = values[field];
      const isValid = fieldValue && validateLinkedInUrlUtil(fieldValue);

      return [isValid, errorMessage];
    };
  };
}

function validateAllSlidersAreMoved(
  field: string,
  requiredSlidersToBeMoved: string
) {
  return function withMessage(
    errorMessage: string = 'Plase, rate each of the possible options'
  ): Validator {
    return function validator(values: FormValues): TupleValidatorResult {
      const sliders = values[requiredSlidersToBeMoved];
      const movedSliders = values[field];

      if (!movedSliders) {
        return [false, errorMessage];
      }

      const sliderIsAtZero = (slider) =>
        movedSliders[slider] === undefined || movedSliders[slider] === 0;
      const allSlidersAreNonZero = !sliders.filter(sliderIsAtZero).length;
      const isValid = allSlidersAreNonZero;

      return [isValid, errorMessage];
    };
  };
}

function validateAtLeastOneSliderIsMoved(field: string) {
  return function withMessage(
    errorMessage: string = 'Plase, rate each of the possible options'
  ): Validator {
    return function validator(values: FormValues): TupleValidatorResult {
      const fieldValue = values[field];
      const hasMovedSliders =
        fieldValue !== undefined && !obj.empty(fieldValue);
      const movedSlidersValues: any[] = hasMovedSliders
        ? obj.vals(fieldValue)
        : [];
      const sliderIsGreaterThanZero = (sliderValue) => sliderValue > 0;
      const atLeastOneSliderIsGreaterThanZero = movedSlidersValues.some(
        sliderIsGreaterThanZero
      );

      const isValid = hasMovedSliders && atLeastOneSliderIsGreaterThanZero;

      return [isValid, errorMessage];
    };
  };
}

function validatePhoneNumber(field: string) {
  return function withMessage(
    errorMessage: string = 'Please, provide a valid phone number'
  ): Validator {
    return function validator(values: FormValues): TupleValidatorResult {
      if (!values[field]) {
        return [false, errorMessage];
      }

      const symbols = '^([ 0-9+]){5,25}$'; // eslint-disable-line;
      const phoneRegex = new RegExp(symbols, 'gim');
      const isValid = values[field] && phoneRegex.test(values[field]);

      return [isValid, errorMessage];
    };
  };
}

function validateFirstAndLastName(field: string) {
  return function withMessage(
    errorMessage: string = 'Invalid name format. Please, provide a valid name (e.g. John Doe)'
  ): Validator {
    return function validator(values: FormValues): TupleValidatorResult {
      // eslint-disable-next-line
      const regex =
        /^[^0-9.,';!@#$%^&*()_+=\\\/<>~|\s`]+\s+[^0-9.,';!@#$%^&*()_+=\\\/<>~|\s`]+$/gim;
      const value = values[field];
      const isValid = regex.test(value);

      return [isValid, errorMessage];
    };
  };
}

function validateNumeric(field: string) {
  return function withMessage(
    errorMessage: string = 'Please, provide a valid numeric value'
  ) {
    return function validator(values: FormValues): TupleValidatorResult {
      const value = values[field];
      const isValid = !Number.isNaN(value);
      return [isValid, errorMessage];
    };
  };
}

function validateNumberRange(
  field: string,
  opts: { high: number, low: number }
) {
  return function withMessage(
    errorMessage: string = `Value should be in the range ${opts.low}-${opts.high}`
  ) {
    return function validator(values: FormValues): TupleValidatorResult {
      const value = values[field];
      const isValid = Number(value) >= opts.low && Number(value) <= opts.high;
      return [isValid, errorMessage];
    };
  };
}

function validateNonNegativeNumber(field: string) {
  return function withMessage(
    errorMessage: string = 'Negative numbers are not allowed'
  ) {
    return function validator(values: FormValues): TupleValidatorResult {
      const value = values[field];
      const isValid = Number(value) >= 0;
      return [isValid, errorMessage];
    };
  };
}

const basicValidators = {
  email: (value: string) => !!(value && value.match(/\S+@\S+\.\S+/gi)),
  password: (value: string) => !!(value && value.length > 3),
};

export {
  validateEmail,
  validateRequired,
  validatePassword,
  validateRepeatPassword,
  validateChecked,
  validateEitherOr,
  validateUrl,
  validatePhoneNumber,
  validateLinkedInUrl,
  validateAllSlidersAreMoved,
  validateAtLeastOneSliderIsMoved,
  validateFirstAndLastName,
  validateNumeric,
  validateNumberRange,
  validateNonNegativeNumber,
  basicValidators,
};

export type { Validator };
