// @flow
import React from 'react';
import type { Node } from 'react';
import { makeStyles, Container } from '@material-ui/core';
import { Button } from '../components/basic/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Footer } from '../components/Footer';
import businessesBg from '../assets/images/businesses-page/businesses-1.png';
import companyProfileInputs from '../assets/images/businesses-page/company-profile-inputs.png';
import c3 from '../assets/images/businesses-page/c3.png';
import janetTodorova from '../assets/images/businesses-page/janet-todorova.png';
import alexanderKitov from '../assets/images/businesses-page/alexander-kitov.png';
import stefaniaPopova from '../assets/images/businesses-page/stefania-popova.png';
import profile from '../assets/images/businesses-page/profile.png';
import jobAd from '../assets/images/businesses-page/job-ad.png';
import people from '../assets/images/businesses-page/people.png';
import { Photo } from './About';
import { PageHead } from '../components/basic/PageHead';
import { useRequestCompanyProfile } from '../components/companies/request-profile/RequestCompanyProfileProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    '& img': {
      width: '100%',
    },
  },
  header: {
    backgroundImage: `url("${businessesBg}")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: theme.spacing(8, 0),
    color: theme.palette.common.white,

    '& h1': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(42),
    },

    '& h2': {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: theme.spacing(8),
      maxWidth: `64ch`,
    },
  },

  applyBtn: {
    padding: theme.spacing(2, 4),
    transition: 'none',
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    textDecoration: 'none',
    borderRadius: theme.borderRadius,

    '&:hover': {
      background: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  },

  section: {
    margin: theme.spacing(16, 0),
  },

  twoColsSection: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(4),

    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr',
    },
  },

  section1: {
    '& > div:nth-of-type(1)': {
      color: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(42),
      fontWeight: theme.typography.fontWeightMedium,

      '& span': {
        fontWeight: theme.typography.fontWeightBold,
      },
    },

    '& > div': {
      flex: 1,
    },
  },

  section2: {
    '& > div:nth-of-type(2)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.primary.lightest,
      borderRadius: theme.borderRadius,
    },
  },

  section3: {
    '& > div:nth-of-type(1)': {
      padding: theme.spacing(8),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.grey[100],
      borderRadius: theme.borderRadius,

      '& img': {
        borderRadius: theme.borderRadius,
      },
    },
  },

  section4: {
    '& h3': {
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(42),
      textAlign: 'center',
    },
  },

  section5: {
    '& > div:nth-of-type(1)': {
      padding: theme.spacing(8),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.grey[100],
      borderRadius: theme.borderRadius,

      '& img': {
        borderRadius: theme.borderRadius,
      },
    },
  },

  section6: {
    '& > div:nth-of-type(2)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.primary.lightest,
      borderRadius: theme.borderRadius,
      padding: theme.spacing(8),

      '& img': {
        borderRadius: theme.borderRadius,
      },
    },
  },

  section7: {
    '& h3': {
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(42),
      textAlign: 'center',
      maxWidth: `18ch`,
      margin: `0 auto`,
      marginBottom: theme.spacing(8),
    },
    '& p': {
      marginBottom: theme.spacing(4),
    },

    '& img': {
      marginBottom: theme.spacing(8),
    },

    '& .text': {
      margin: 'auto',
      textAlign: 'center',
      maxWidth: `96ch`,
    },
  },

  section8: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: -theme.spacing(8),
    height: 500,

    '& h2': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(42),
      margin: 0,
      padding: 0,
    },

    '& h3': {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(32),
      margin: 0,
      padding: 0,
    },

    '& .btnContainer': {
      marginTop: theme.spacing(4),
    },
  },

  list: {
    '& li': {
      listStyle: 'none',
    },

    '& li h4': {
      display: 'flex',
      alignItems: 'center',

      '& span': {
        fontWeight: theme.typography.fontWeightBold,
        marginLeft: theme.spacing(1),
      },
    },
  },

  listHeading: {
    fontSize: theme.typography.pxToRem(32),
    marginTop: 0,
    fontWeight: theme.typography.fontWeightLight,
  },

  mobileFirst: {
    '@media (max-width: 768px)': {
      gridColumnStart: 1,
      gridRowStart: 1,
    },
  },

  referrers: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(4),
    textAlign: 'center',
    margin: theme.spacing(0, 16),

    '@media (max-width: 640px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '@media (max-width: 1200px)': {
      margin: 0,
    },
  },
}));

export function Businesses() {
  const classes = useStyles();
  const { openPopup } = useRequestCompanyProfile();

  return (
    <>
      <PageHead
        title="For Employers | NobleHire"
        description="Noble Hire is a full-service staffing solution. We help you source &amp; hire the right talent, every step of the way."
      />
      <div className={classes.root}>
        <div className={classes.header}>
          <Container>
            <h1>Hire quality tech talent in Bulgaria, fast.</h1>
            <h2>
              Noble Hire is a new type of job board. We showcase your company
              strengths and job positions in an engaging way that converts more
              talents into applications.
              <br />
              <br />
              Our large network of referrers gives you access to quality tech
              talent that is hard to reach.
            </h2>
            <Button
              color="default"
              variant="contained"
              type="button"
              onClick={openPopup}
              className={classes.applyBtn}
            >
              Apply for company profile
            </Button>
          </Container>
        </div>
        <Container>
          <div className={classes.section}>
            <div className={`${classes.section1} ${classes.twoColsSection}`}>
              <div>
                <span>70%+</span> employers worldwide have trouble finding
                high-skilled workers
              </div>
              <div>
                <h3 className={classes.listHeading}>
                  Hiring tech talents is...
                </h3>

                <ul className={classes.list}>
                  <Bullet title="Expensive">
                    A tech hire, on average, can cost anything between 5,000 to
                    20,000 BGN
                  </Bullet>
                  <Bullet title="Time-Consuming">
                    It can take you up to 6 months to find the right tech talent
                  </Bullet>
                  <Bullet title="High-Effort">
                    Most companies today use 5+ different hiring sources,
                    including internal teams & external staffing agencies
                  </Bullet>
                </ul>

                <div>But we can change that!</div>
              </div>
            </div>
          </div>

          <div className={classes.section}>
            <div className={`${classes.section2} ${classes.twoColsSection}`}>
              <div>
                <h3 className={classes.listHeading}>
                  Take your hiring to the next level.
                </h3>

                <p>
                  Noble Hire is an innovative job board that gives you access
                  to:
                </p>

                <ul className={classes.list}>
                  <Bullet title="Direct Applicants">
                    Interested tech candidates from our large pool of Noble Hire
                    users directly applying to your company.
                  </Bullet>
                  <Bullet title="Referred Applicants">
                    All Noble Hire users can refer talents to your open
                    positions and get a referral bonus from our fee for every
                    successful hire.
                  </Bullet>
                  <Bullet title="Employer Branding">
                    Your Noble Hire company profile highlights your company
                    strengths and culture, providing potential candidates with
                    insights about the company.
                  </Bullet>
                </ul>
              </div>

              <div className={classes.mobileFirst}>
                <img
                  src={companyProfileInputs}
                  alt="Company profile - inputs"
                />
              </div>
            </div>
          </div>

          <div className={classes.section}>
            <div className={`${classes.section3} ${classes.twoColsSection}`}>
              <div>
                <img src={c3} alt="Refer condidates form" />
              </div>

              <div>
                <h3 className={classes.listHeading}>
                  Maximize Your Reach with the Noble Network
                </h3>

                <p>
                  When you create a job ad on Noble Hire, your reach isn’t only
                  limited to job-seekers. For each open position, we announce a
                  bounty – whoever helps find the right talent wins a referral
                  bonus. Our network of referrers includes professionals from
                  different fields, such as…
                </p>

                <ul className={classes.list}>
                  <Bullet title="Experienced Recruiters" />
                  <Bullet title="Domain Experts" />
                  <Bullet title="Company Founders" />
                  <Bullet title="Influencers" />
                </ul>
              </div>
            </div>
          </div>

          <div className={classes.section}>
            <div className={`${classes.section4}`}>
              <h3>
                Noble Hire referrers are some of the most well-connected
                professionals in Sofia…
              </h3>
            </div>

            <div className={classes.referrers}>
              <Photo
                center
                subtitleWidth="32ch"
                src={janetTodorova}
                alt="Janet Todorova"
                title="Co-Founder at React.NotAConf, Expansion Manager at Founder Institute, Speaker Relations at DigitalK"
              />
              <Photo
                center
                subtitleWidth="32ch"
                src={alexanderKitov}
                alt="Alexander Kitov"
                title="Co-Founder at Perpetto, Co-founder at betahaus | Sofia, Product Manager Crypto at Paysafe"
              />
              <Photo
                center
                subtitleWidth="32ch"
                src={stefaniaPopova}
                alt="Stefania Popova"
                title="IT Recruiter"
              />
            </div>
          </div>

          <div className={classes.section}>
            <div className={`${classes.section5} ${classes.twoColsSection}`}>
              <div>
                <img src={profile} alt="Company profile on NobleHire" />
              </div>

              <div>
                <h3 className={classes.listHeading}>
                  Re-Create Your Company Brand
                </h3>

                <p>
                  To really source the best talent, you want to stand out. Noble
                  Profile framework allows you to really express what your
                  company is all about. Some of the profile highlights include…
                </p>

                <ul className={classes.list}>
                  <Bullet title="Company Benefits" />
                  <Bullet title="Office Pictures &amp; Perks" />
                  <Bullet title="Product &amp; Service" />
                  <Bullet title="...And many more" />
                </ul>
              </div>
            </div>
          </div>

          <div className={classes.section}>
            <div className={`${classes.section6} ${classes.twoColsSection}`}>
              <div>
                <h3 className={classes.listHeading}>
                  Find the right candidates
                </h3>

                <p>
                  With Job Ad 2.0, you can convey what any open position is all
                  about. Some of the page sections include…:
                </p>

                <ul className={classes.list}>
                  <Bullet title="Project Details" />
                  <Bullet title="Team Lead" />
                  <Bullet title="Core Requirements" />
                </ul>

                <p>
                  The new job ad format allows you to attract candidates that
                  are really interested in the position, and not just spamming
                  their CV.
                </p>
              </div>

              <div className={classes.mobileFirst}>
                <img src={jobAd} alt="Job ad on NobleHire" />
              </div>
            </div>
          </div>

          <div className={classes.section}>
            <div className={`${classes.section7}`}>
              <h3>Only the TOP talent, straight to your inbox</h3>

              <img src={people} alt="People figures" />

              <div className="text">
                <p>
                  Want to declutter your hiring pipeline? We only send you
                  relevant candidates for every position, allowing you to hire
                  both better & faster.
                </p>

                <p>
                  With Noble Hire we aim to give greater visibility to companies
                  adding true value to the society and our planet. Companies
                  which are places people would want to work in and where they
                  would feel appreciated and inspired. We support a wide variety
                  of companies ranging from small startups to big established
                  organisations, as well as companies in different industries.
                </p>

                <p>
                  mportant factors to us for the companies we partner with are:
                  – having a healthy organisational culture promoting growth,
                  wellbeing and collaboration – interesting product or service
                  that adds real value to society or our planet
                </p>

                <p>
                  We give our best not to promote harmful industries such as
                  defence, gambling, tobacco, etc, but if you’re in this space
                  in a positive way, please let us know.
                </p>
              </div>
            </div>
          </div>
        </Container>

        <div className={classes.section8}>
          <Container>
            <h2>Want to get involved?</h2>
            <h3>
              Simply drop us a line &amp; we&apos;ll get in touch with you.
            </h3>
            <div className="btnContainer">
              <Button
                color="default"
                variant="contained"
                type="button"
                onClick={openPopup}
                className={classes.applyBtn}
              >
                Apply for company profile
              </Button>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

function Bullet({ title, children }: { title: string, children?: Node }) {
  return (
    <li>
      <h4>
        <Icon name="chevron-right" />
        <span>{title}</span>
      </h4>
      {children ? <div>{children}</div> : null}
    </li>
  );
}

function Icon({ name }: { name: string }) {
  const classes = useStyles();

  return <FontAwesomeIcon className={classes.icon} icon={['fal', name]} />;
}
