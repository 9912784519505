// @flow

const SET_INITIALIZATION = 'SET_INITIALIZATION';
const NEXT_STEP = 'NEXT_STEP';
const PREV_STEP = 'PREV_STEP';
const NEXT_SYNTHETIC_STEP = 'NEXT_SYNTHETIC_STEP';
const PREV_SYNTHETIC_STEP = 'PREV_SYNTHETIC_STEP';
const SET_SYNTHETIC_STEP = 'SET_SYNTHETIC_STEP';
const SET_SYNTHETIC_STEPS_COUNT = 'SET_SYNTHETIC_STEPS_COUNT';
const SET_STEP = 'SET_STEP';
const COMPLETE_SECTION = 'COMPLETE_SECTION';
const START_ONBOARDING_SECTION = 'START_ONBOARDING_SECTION';
const HYDRATE_ONBOARDING_STATE_START = 'HYDRATE_ONBOARDING_STATE_START';
const HYDRATE_ONBOARDING_STATE_END = 'HYDRATE_ONBOARDING_STATE_END';
const SET_IS_TECH = 'SET_IS_TECH';
const SET_IS_LOOKING_FOR_TECH = 'SET_IS_LOOKING_FOR_TECH';
const SET_ONBOARDING_LOADING = 'SET_ONBOARDING_LOADING';

function setInitialization(data: boolean) {
  return { type: SET_INITIALIZATION, data };
}

function nextStep() {
  return { type: NEXT_STEP };
}

function prevStep() {
  return { type: PREV_STEP };
}

function setStep(step: number) {
  return { type: SET_STEP, data: step };
}

function prevSyntheticStep() {
  return { type: PREV_SYNTHETIC_STEP };
}

function nextSyntheticStep() {
  return { type: NEXT_SYNTHETIC_STEP };
}

function setSyntheticStepsCount(count: number) {
  return { type: SET_SYNTHETIC_STEPS_COUNT, data: count };
}

function completeSection(section: number) {
  return { type: COMPLETE_SECTION, section };
}

function startOnboardingSection(section: number) {
  return { type: START_ONBOARDING_SECTION, data: section };
}

function hydrateOnboardingStateStart() {
  return { type: HYDRATE_ONBOARDING_STATE_START };
}

function hydrateOnboardingStateEnd(data: any) {
  return { type: HYDRATE_ONBOARDING_STATE_END, data };
}

function setIsTech(role: string) {
  return { type: SET_IS_TECH, role };
}

function setIsLookingForTech(role: string) {
  return { type: SET_IS_LOOKING_FOR_TECH, role };
}

function setOnboardingLoading(data: boolean) {
  return { type: SET_ONBOARDING_LOADING, data };
}

function setSyntheticStep(step: number) {
  return { type: SET_SYNTHETIC_STEP, data: step };
}

export {
  SET_INITIALIZATION,
  NEXT_STEP,
  PREV_STEP,
  SET_STEP,
  PREV_SYNTHETIC_STEP,
  NEXT_SYNTHETIC_STEP,
  SET_SYNTHETIC_STEP,
  SET_SYNTHETIC_STEPS_COUNT,
  COMPLETE_SECTION,
  START_ONBOARDING_SECTION,
  HYDRATE_ONBOARDING_STATE_START,
  HYDRATE_ONBOARDING_STATE_END,
  SET_IS_TECH,
  SET_IS_LOOKING_FOR_TECH,
  SET_ONBOARDING_LOADING,
  setInitialization,
  nextStep,
  prevStep,
  prevSyntheticStep,
  nextSyntheticStep,
  setSyntheticStep,
  setSyntheticStepsCount,
  setStep,
  completeSection,
  startOnboardingSection,
  hydrateOnboardingStateStart,
  hydrateOnboardingStateEnd,
  setIsTech,
  setIsLookingForTech,
  setOnboardingLoading,
};
