// @flow

import { useCallback, useState } from 'react';
import { convServerCompanyToCompany } from '../common/utils';
import { getCompanies } from '../companiesService';
import type { CompanyType } from './types';

type Data = {
  elements: CompanyType[],
  page: number,
  totalElements: number,
  totalPages: number,
};

function parseCompanies(companies: any) {
  const parsedCompanies = companies.map(convServerCompanyToCompany);
  return parsedCompanies;
}

export function useCompaniesList() {
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [data, setData] = useState<Data>({
    elements: [],
    page: 0,
    totalElements: 0,
    totalPages: 0,
  });

  const load = useCallback(async (search?: string, page?: number) => {
    setLoading(true);
    const pageNumber = Number.isNaN(Number(page)) ? undefined : Number(page);
    const response = await getCompanies(search, { page: pageNumber });
    const companies = parseCompanies(response.elements);

    setTimeout(() => {
      setData((prev) => ({
        ...prev,
        ...response,
        elements: companies,
      }));
      setLoading(false);
    }, 200);
  }, []);

  const loadMore = useCallback(
    async (search?: string) => {
      setLoadingMore(true);

      const response = await getCompanies(search, { page: data.page + 1 });
      const rCompanies = parseCompanies(response.elements);

      setData((prev) => ({
        ...prev,
        page: response.page,
        elements: [...prev.elements, ...rCompanies],
        totalElements: response.totalElements,
        totalPages: response.totalPages,
      }));
      setLoadingMore(false);
    },
    [data]
  );

  return {
    data,
    loading,
    loadingMore,
    load,
    loadMore,
  };
}
