// @flow
import React, { useCallback, useContext, createContext } from 'react';
import type { Node } from 'react';
import { log } from '../../utils/log';

type Trackers = { [key: string]: Function };

type Props = {
  children: Node,
  trackers: Trackers,
};

const TrackingContext = createContext<any>({});

const events: { [key: string]: string } = {
  // internal event name -> event handler function from 'trackers'
  BLANK_APPLICATION: 'blankApplicationEvent',
  DIRECT_APPLICATION: 'directApplicationEvent',
  INITIATE_BLANK_APPLICATION: 'initiateBlankApplication',
  INITIATE_DIRECT_APPLICATION: 'initiateDirectApplication',
  CREATE_ACCOUNT: 'createAccount',
  COMPLETE_BASIC_PERSONAL_INFORMATION: 'completeBasicPersonalInformation',
  QUALIFIED_LEAD: 'qualifiedLead',
  COMPLETE_CURRENT_POSITION_STEP: 'completeCurrentPositionStep',
  COMPLETE_JOB_SEARCH_STATUS: 'completeJobSearchStatus',
  RATE_TECH_SKILLS: 'rateTechSkills',
  RATE_SOFT_SKILLS: 'rateSoftSkills',
  COMPLETE_OVERALL_CULTURE: 'completeOverallCulture',
  COMPLETE_TEAM_CULTURE: 'completeTeamCulture',
  COMPLETE_LEADERSHIP_CULTURE: 'completeLeadershipCulture',
  GET_REFERRAL_LINK_COPY_BUTTON_CLICK: 'getReferralLinkCopyButtonClick',
  MENU_PROFILE_ICON_CLICK: 'menuProfileIconClick',
  MENU_REFERRALS_ICON_CLICK: 'menuReferralsIconClick',
  GET_INVITE_LINK_BUTTON_CLICK: 'getInviteLinkButtonClick',
  SEND_PERSONAL_INVITE_LINK_BUTTON_CLICK: 'sendPersonalInviteLinkButtonClick',
  SUCCESSFUL_DIRECT_INVITE_EMAIL_SEND: 'successfulDirectInviteEmailSend',
  DIRECT_REFERRAL_HIT: 'directReferralHit',
  REFER_CANDIDATE_FROM_JOB_AD_BUTTON_CLICK:
    'referCandidateFromJobAdButtonClick',
  SHARE_TO_YOUR_NETWORK_FROM_JOB_AD_BUTTON_CLICK:
    'shareToYourNetworkFromJobAdButtonClick',
  REFERRAL_CTA_POPUP_CLOSED: 'referralCtaPopupClosed',
  REFERRAL_CTA_POPUP_OPENED: 'referralCtaPopupOpened',
  REFERRAL_CTA_POPUP_LEARN_MORE_BUTTON_CLICKED:
    'referralCtaPopupLearnMoreButtonClicked',
  REFERRAL_SEE_HOW_IT_WORKS_CLICK: 'referralSeeHowItWorksClick',
  AUTO_LOGIN: 'autoLogin',
  OPEN_LOGIN_SCREEN: 'openLoginScreen',
  ALREADY_HAVE_ACCOUNT_CLICK: 'alreadyHaveAccountClick',
  DONT_HAVE_ACCOUNT_CLICK: 'dontHaveAccountClick',
  LOGIN: 'login',
  EDIT_PROFILE_PROMPT_SHOWN: 'editProfilePromptShown',
  EDIT_PROFILE_PROMPT_CLOSED: 'editProfilePromptClosed',
  EDIT_PROFILE_PROMPT_CONTINUE_BTN_CLICK:
    'editProfilePromptContinueButtonClick',
  EDIT_PROFILE_PROMPT_EDIT_BTN_CLICK: 'editProfilePromptEditProfileClick',
};

type TrackingEvent = $Keys<typeof events>;

function TrackingProvider({ children, trackers }: Props) {
  const track = useCallback(
    (eventName: TrackingEvent, data?: any): void => {
      if (trackers[eventName] && typeof trackers[eventName] === 'function') {
        trackers[eventName](data);
      } else {
        log(
          `Trying to execute unknown tracker function for tracking event: [${eventName}]`,
          { logLevel: log.logLevels.warn }
        );
      }
    },
    [trackers]
  );

  const defaultValue = { track, events };

  return (
    <TrackingContext.Provider value={defaultValue}>
      {children}
    </TrackingContext.Provider>
  );
}

function useTracking() {
  return useContext(TrackingContext);
}

export { TrackingContext, TrackingProvider, useTracking };
