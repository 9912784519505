// @flow
import axios from 'axios';
import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  JWT_TOKEN_KEY,
  events,
} from '../constants';

export const endpoint =
  process.env.REACT_APP_API_URL || 'http://localhost:8080';

const client = axios.create({
  baseURL: endpoint,
});
const API = Object.create(client);

API.defaults.headers.common['Content-Type'] = 'application/json';
// API.defaults.headers.common['Access-Control-Request-Headers'] = AUTH_HEADER;

['get', 'delete', 'head', 'options', 'post', 'put', 'patch'].forEach(
  (method) => {
    API[method] = (...args) =>
      client[method].apply(API, args).then((res) => res.data);

    API[`${method}Response`] = client[method].bind(API);
  }
);

type Response = Object;

const updateAuthenticationToken = (token: string) => {
  // Since not all headers are being exposed when there's CORS, we could not
  // rely on the Authorization header, so we're going to get the token passed
  // from outside
  // @see: https://stackoverflow.com/questions/43344819/reading-response-headers-with-fetch-api/44816592#44816592
  // const token = response.headers[AUTH_HEADER.toLowerCase()];
  API.defaults.headers.common[AUTH_HEADER] = `Basic ${token}`;
  localStorage.setItem(AUTH_TOKEN_KEY, token);
};

API.updateAuthenticationToken = updateAuthenticationToken;

API.removeAuthenticationToken = () => {
  API.defaults.headers.common[AUTH_HEADER] = null;
  localStorage.removeItem(AUTH_TOKEN_KEY);
};

API.interceptors.request.use((config) => {
  const authToken = localStorage.getItem(AUTH_TOKEN_KEY);

  // We need this, because axios doesn't set the "Content-Type: application/json"
  // -> read more about this: https://github.com/axios/axios/issues/86
  // eslint-disable-next-line
  if (config.data === undefined) config.data = {};
  if (authToken) {
    config.headers[AUTH_HEADER] = `Bearer ${authToken}`; // eslint-disable-line
  }
  return config;
});

API.interceptors.response.use(
  (response) => {
    const fromRequestUrl = response.request.responseURL;
    const isResponseFromRefDirect = fromRequestUrl.includes('/ref/direct');
    const token =
      response.headers[AUTH_HEADER.toLowerCase()] ||
      response.data[JWT_TOKEN_KEY] ||
      null;

    // The /ref/direct response contains a 'jwtToken' prop inside.
    // It's the jwtToken for the referred user.
    // We don't want it to replace the currently logged in user's token.
    if (token && !isResponseFromRefDirect) {
      updateAuthenticationToken(token);
      const details = { detail: { authToken: token } };
      window.dispatchEvent(new CustomEvent(events.SET_AUTH_TOKEN, details));
    }

    return response;
  },
  (error) => {
    const { status } = error.request;
    const { message } = error.response.data;
    const isConsentNotGiven = message === 'Consent not given yet!';
    const { ignoreUnauthorised = false, useShortRegistration = false } =
      error.config;

    if (
      !ignoreUnauthorised &&
      (status === 401 || status === 403) &&
      !isConsentNotGiven
    ) {
      window.dispatchEvent(
        new CustomEvent(events.NOT_AUTHENTICATED, {
          detail: {
            useShortRegistration,
          },
        })
      );
    }

    return Promise.reject(error);
  }
);

/**
 * Our API object is slightly modified axios instance.
 *
 * In most cases we are interested only in the response body and not in the
 * headers information. That is why the original request methods are overridden
 * to resolve the promise with response data instead of full response
 * information.
 *
 * If you want to have the original response, use the methods ending with
 * Response (e.g. getResponse).
 *
 * Provides flowtype for the REST client.
 */
type APIType = {
  // methods resolving only to response body
  get: (url: string, config?: Object) => Promise<*>,
  delete: (url: string, config?: Object) => Promise<*>,
  options: (url: string, config?: Object) => Promise<*>,
  post: (url: string, data?: *, config?: Object) => Promise<*>,
  put: (url: string, data?: *, config?: Object) => Promise<*>,
  patch: (string, data?: *, config?: Object) => Promise<*>,

  // original axios methods, resolving with full HTTP response
  getResponse: (url: string, config?: Object) => Promise<Response>,
  deleteResponse: (url: string, config?: Object) => Promise<Response>,
  optionsResponse: (url: string, config?: Object) => Promise<Response>,
  postResponse: (url: string, data?: *, config?: Object) => Promise<Response>,
  putResponse: (url: string, data?: *, config?: Object) => Promise<Response>,
  patchResponse: (string, data?: Object, config?: Object) => Promise<Response>,

  removeAuthenticationToken: () => void,
  updateAuthenticationToken: (token: string) => void,
  history: Object,
};

export default (API: APIType);
