// @flow
import React, { useCallback, useRef, useState } from 'react';
import { Box, useTheme, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { companyIcons } from '../../core/constants';
import { useOutsideClick } from '../../utils/useOutsideClick';

const useStyles = makeStyles((theme) => ({
  iconHover: {
    border: `1px solid transparent`,
    '&:hover': {
      border: `1px solid ${theme.palette.edit.main}`,
    },
  },
  chosenIconBox: {
    border: `1px solid ${theme.palette.grey.light}`,
  },
  changeText: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[500],

    '&:hover': {
      color: theme.palette.edit.main,
    },
  },
}));

export default function IconPicker({
  onChange,
  defaultIcon = ['fal', 'crown'],
}: {
  onChange: (icon: any) => void,
  defaultIcon?: [string, string],
}) {
  const classes = useStyles();
  const pickerRef = useRef(null);
  const [open, setOpen] = useState();
  const [filter, setFilter] = useState('');
  const [chosenIcon, setChosenIcon] = useState(defaultIcon);
  const theme = useTheme();

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  function onClose() {
    setFilter('');
    setOpen(false);
  }

  function updateIcon(icon: any) {
    return function internalSetIcon() {
      setChosenIcon(icon);
      onClose();
      onChange(icon);
    };
  }

  useOutsideClick(pickerRef, onClose);

  return (
    <Box position="relative">
      <Box display="flex" alignItems="center">
        <Box
          width={35}
          height={35}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={theme.shape.borderRadius}
          onClick={onOpen}
          className={`${classes.iconHover} ${classes.chosenIconBox}`}
        >
          <FontAwesomeIcon size="lg" icon={chosenIcon} />
        </Box>
        <Box onClick={onOpen} className={classes.changeText}>
          Change icon
        </Box>
      </Box>
      {open ? (
        <Box
          ref={pickerRef}
          position="absolute"
          top={40}
          left={0}
          zIndex={1}
          border={`1px solid ${theme.palette.grey[300]}`}
          bgcolor={theme.palette.common.white}
          borderRadius={theme.shape.borderRadius}
        >
          <Box display="flex" flexDirection="column">
            <Box width="100%" padding={2}>
              <input
                style={{
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: theme.shape.borderRadius,
                  height: 40,
                  padding: theme.spacing(1),
                  width: '100%',
                }}
                placeholder="Search icons (e.g. robot)"
                name="filter"
                onChange={({ target }) => setFilter(target.value)}
              />
            </Box>
            <Box px={2} pb={2} overflow="auto" height={200}>
              <Box
                minWidth={250}
                display="grid"
                gridTemplateColumns="repeat(5, 1fr)"
                gridColumnGap={10}
                gridRowGap={10}
              >
                {companyIcons
                  .filter((i) => i.iconName.includes(filter))
                  .slice(0, 100)
                  .map((icon: { iconName: string }) => {
                    return (
                      <Box
                        key={icon.iconName}
                        borderRadius={theme.shape.borderRadius}
                        bgcolor={theme.palette.grey[100]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        padding={1}
                        onClick={updateIcon(icon)}
                        className={classes.iconHover}
                      >
                        <FontAwesomeIcon size="lg" icon={icon} />
                      </Box>
                    );
                  })}
              </Box>

              <Box
                style={{ cursor: 'auto' }}
                mt={2}
                padding={1}
                bgcolor={theme.palette.grey[100]}
                borderRadius={theme.shape.borderRadius}
              >
                Use the search box if you can&apos;t find what you are looking
                for.
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
