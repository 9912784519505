// @flow
import React, { useEffect, useRef, useState } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const StyledHint = styled.div`
  & {
    color: ${(props) => props.color};
    position: relative;
    border: 1px solid transparent;
    padding: 2px 4px;
  }
  & .hint-text {
    display: none;
  }
  &:hover {
    border: 1px solid ${(props) => props.color};
    border-radius: 5px;
    cursor: default;
  }
  &:hover .hint-text {
    display: block;
    position: absolute;
    bottom: ${(p) => (p.bellow ? undefined : '30px')};
    top: ${(p) => (p.above ? undefined : '30px')};
    right: 0;
    min-width: 350px;
    max-width: 450px;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    z-index: 3;
  }
`;
export function Hint({
  title = 'Hint',
  text,
  render,
}: {
  title?: string,
  text?: string,
  render?: Node,
}) {
  const c = useStyles();
  const theme = useTheme();
  const ref = useRef(null);
  const hintContainerRef = useRef(null);
  const [topOffset, setTopOffset] = useState(50000);
  useEffect(() => {
    const handle = () => {
      if (ref && ref.current) {
        const { top } = ref.current.getBoundingClientRect();
        setTopOffset(top);
      }
    };
    window.addEventListener('scroll', handle);
    return () => window.removeEventListener('scroll', handle);
  }, []);
  const threshold = 400;
  return (
    <StyledHint
      color={theme.palette.edit.main}
      ref={ref}
      bellow={topOffset < threshold}
      above={topOffset > threshold}
    >
      <FontAwesomeIcon icon={['fal', 'info-circle']} className={c.icon} />
      <span>{title}</span>
      <div className="hint-text" ref={hintContainerRef}>
        {text || render}
      </div>
    </StyledHint>
  );
}
