// @flow
import { useStateContainer } from '../../../../core/context/StateContainer';

const useModalsQueue = () => {
  const [
    {
      modals: { queue },
    },
  ] = useStateContainer();
  const getFromQueue = () => queue[0];

  return {
    getFromQueue,
  };
};

export { useModalsQueue };
