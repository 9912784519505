// @flow

import { useMemo, useState, useCallback } from 'react';
import type {
  CoreRequirement,
  HiringProcess as HiringProcessType,
  Job,
  Perk,
  Responsibility,
  TimeSpendingDetails,
  Tool,
} from './JobsContext';
import {
  currencies,
  databases,
  jobTypes,
  platformsAndFrameworks,
  programmingLanguages,
  roles,
  salaries,
  seniority as seniorityLevels,
  teamSizes,
} from '../../core/constants';
import type {
  CarouselItem,
  Image,
  ServerLocation,
} from '../companies/common/context/CompanyContext';
import type { AddTimeSpendingDetailsData } from './types';
import { uniqNum } from '../../utils/uniq';
import { uploadCompanyImageRequest } from '../companies/common/companiesService';
import { normServerImage } from '../companies/common/utils';
import { isRoleTech } from '../../utils/isRoleTech';
import { useStateContainer } from '../../core/context/StateContainer';

export function useEditableJob(
  defaults: { editProductDescription?: string } = {}
) {
  const [
    {
      auth: { user, isAdmin },
    },
  ] = useStateContainer();
  const [errors, setErrors] = useState<any>({});
  const [saving, setSaving] = useState<boolean>(false);
  const [exiting, setExiting] = useState<boolean>(false);
  const [isTech, setIsTech] = useState<boolean>(false);
  const [editTitle, setEditTitle] =
    useState<$ElementType<Job<ServerLocation>, 'title'>>('');
  const [editDescription, setEditDescription] =
    useState<$ElementType<Job<ServerLocation>, 'description'>>('');
  const [editLocation, setEditLocation] = useState<any[] | null>(null);
  const [editIsPublic, setEditIsPublic] =
    useState<$ElementType<Job<ServerLocation>, 'isPublic'>>(false);
  const [editRole, setEditRole] = useState<$Keys<typeof roles> | ''>('');
  const [editSalary, setEditSalary] = useState<$Keys<typeof salaries> | ''>('');
  const [editSalaryCurrency, setEditSalaryCurrency] = useState<
    $Keys<typeof currencies> | ''
  >('');
  const [editSeniority, setEditSeniority] = useState<
    $Keys<typeof seniorityLevels> | ''
  >('');
  const [editType, setEditType] = useState<$Keys<typeof jobTypes> | ''>('');
  const [editStockOptions, setEditStockOptions] =
    useState<$ElementType<Job<ServerLocation>, 'stockOptions'>>('no');
  const [editHomeOffice, setEditHomeOffice] =
    useState<$ElementType<Job<ServerLocation>, 'homeOffice'>>('no');
  const [editTeamSize, setEditTeamSize] = useState<$Keys<typeof teamSizes>>(
    teamSizes['1-5']
  );
  const [editFullyRemote, setEditFullyRemote] =
    useState<$ElementType<Job<ServerLocation>, 'fullyRemote'>>('no');
  const [editCustomerFacing, setEditCustomerFacing] =
    useState<$ElementType<Job<ServerLocation>, 'customerFacing'>>('no');
  const [editBusinessTravel, setEditBusinessTravel] =
    useState<$ElementType<Job<ServerLocation>, 'businessTravel'>>('no');
  const [editPrimaryLanguage, setEditPrimaryLanguage] = useState<
    $Keys<typeof programmingLanguages> | string | null
  >(null);
  const [editPrimaryPlatformOrFramework, setEditPrimaryPlatformOrFramework] =
    useState<$Keys<typeof platformsAndFrameworks> | string | null>(null);
  const [editSecondaryLanguage, setEditSecondaryLanguage] = useState<
    $Keys<typeof programmingLanguages> | string | null
  >(null);
  const [
    editSecondaryPlatformOrFramework,
    setEditSecondaryPlatformOrFramework,
  ] = useState<$Keys<typeof platformsAndFrameworks> | string | null>(null);
  const [editMainDatabase, setEditMainDatabase] = useState<
    $Keys<typeof databases> | string | null
  >(null);
  const [editCoreRequirements, setEditCoreRequirements] = useState<
    CoreRequirement[]
  >([]);
  const [editResponsibilities, setEditResponsibilities] = useState<
    Responsibility[]
  >([]);
  const [editHiringProcessSteps, setEditHiringProcessSteps] = useState<
    HiringProcessType[]
  >([]);
  const [editPerks, setEditPerks] = useState<Perk[]>([]);
  const [editTeamLeadImage, setEditTeamLeadImage] = useState<Image | null>(
    null
  );
  const [editTeamLeadName, setEditTeamLeadName] = useState<string>('');
  const [editTeamLeadRole, setEditTeamLeadRole] = useState<string>('');
  const [editTeamLeadLink, setEditTeamLeadLink] = useState<string>('');
  const [editTimeSpendingDetails, setEditTimeSpendingDetails] = useState<
    TimeSpendingDetails[]
  >([]);
  const [editTools, setEditTools] = useState<Tool[]>([]);
  const [editProductImages, setEditProductImages] = useState<Image[]>([]);
  const [editProductDescription, setEditProductDescription] = useState<string>(
    defaults.editProductDescription || ''
  );

  const validate = useCallback(() => {
    const err = {};

    if (editTitle && editTitle.startsWith('[DUPLICATE]')) {
      err.title = 'Job Title starts with "[DUPLICATE]"';
    }
    if (isAdmin(user)) {
      // admin validations
      if (!editTitle) err.title = 'Job Title';
      if (!editRole) err.role = 'Role';
    } else {
      // company user validations
      if (editFullyRemote === 'no') {
        if (
          !editLocation ||
          (Array.isArray(editLocation) && !editLocation.length)
        )
          err.locations = 'Pick a location (or set "Fully Remote" to "Yes")';
      }
      if (!editTitle) err.title = 'Job Title';
      if (!editDescription || !editDescription.length)
        err.description = 'Job Description';
      if (!editRole) err.role = 'Role';
      if (!editSeniority) err.seniority = 'Seniority';
      if (!editType) err.type = 'Job Type';
      if (isTech) {
        if (
          !editPrimaryLanguage &&
          !editPrimaryPlatformOrFramework &&
          !editSecondaryLanguage &&
          !editPrimaryPlatformOrFramework &&
          !editMainDatabase
        ) {
          err.tech = 'At least 1 tech requirement';
        }
      }
      if (!editCoreRequirements.length) err.coreRequirements = 'Requirements';
      if (!editResponsibilities.length)
        err.responsibilities = 'Responsibilities';
      // if (!editHiringProcessSteps.length)
      //   err.hiringProcessSteps = 'Hiring Process';
      // if (!editPerks.length) err.perks = 'Benefits & Perks';
      // if (!editTeamLeadName) err.teamLeadName = 'Team Lead Name';
      // if (!editTeamLeadLink) err.teamLeadLink = 'Team Lead Link';
      // if (!editTeamLeadRole) err.teamLeadRole = 'Team Lead Role';
      // if (!editTimeSpendingDetails.length) err.timeSpendingDetails = 'Activities';
      // if (!editTools) err.tools = 'Tools';
      // if (!editProductImages.length)
      //   err.productImages = 'At least 1 Product Image';
      // if (!editProductDescription) err.productDescription = 'Product Description';
    }

    return err;
  }, [
    isAdmin,
    user,
    editTitle,
    editDescription,
    editRole,
    editSeniority,
    editType,
    isTech,
    editPrimaryLanguage,
    editPrimaryPlatformOrFramework,
    editSecondaryLanguage,
    editMainDatabase,
    editCoreRequirements,
    editResponsibilities,
    editLocation,
    editFullyRemote,
    // editHiringProcessSteps,
    // editPerks,
    // editTeamLeadName,
    // editTeamLeadLink,
    // editTeamLeadRole,
    // editTimeSpendingDetails,
    // editTools,
    // editProductDescription,
  ]);

  const actions = useMemo(() => {
    return {
      setErrors,
      setSaving,
      setExiting,
      setEditTitle,
      setEditDescription,
      setEditLocation,
      setEditIsPublic,
      setEditRole: (role: $Keys<typeof roles> | '') => {
        if (isRoleTech(role)) {
          setEditPrimaryLanguage(null);
          setEditPrimaryPlatformOrFramework(null);
          setEditSecondaryLanguage(null);
          setEditSecondaryPlatformOrFramework(null);
          setEditMainDatabase(null);
          setIsTech(true);
        } else {
          setIsTech(false);
        }
        setEditRole(role);
      },
      setEditSalary,
      setEditSalaryCurrency,
      setEditSeniority,
      setEditType,
      setEditStockOptions,
      setEditHomeOffice,
      setEditTeamSize,
      setEditFullyRemote,
      setEditCustomerFacing,
      setEditBusinessTravel,
      setEditPrimaryLanguage,
      setEditPrimaryPlatformOrFramework,
      setEditSecondaryLanguage,
      setEditSecondaryPlatformOrFramework,
      setEditMainDatabase,
      setEditCoreRequirements,
      setEditResponsibilities,
      setEditHiringProcessSteps,
      setEditPerks,
      setEditTeamLeadImage,
      setEditTeamLeadName,
      setEditTeamLeadRole,
      setEditTeamLeadLink,
      setEditTimeSpendingDetails,
      setEditTools,
      setEditProductImages,
      setEditProductDescription,
      // customized actions
      addCoreRequirement: (cr: CoreRequirement) =>
        setEditCoreRequirements((prev) => [...prev, cr]),
      removeCoreRequirement: (req: CoreRequirement) =>
        setEditCoreRequirements((prev) => prev.filter((c) => c.id !== req.id)),
      editCoreRequirement: (req: CoreRequirement) => {
        setEditCoreRequirements((prev) =>
          prev.map((cr) => (cr.id === req.id ? req : cr))
        );
      },

      addResponsibility: (resp: Responsibility) =>
        setEditResponsibilities((prev) => [...prev, resp]),
      removeResponsibility: (resp: Responsibility) =>
        setEditResponsibilities((prev) => prev.filter((r) => r.id !== resp.id)),
      editResponsibility: (resp: Responsibility) => {
        setEditResponsibilities((prev) =>
          prev.map((r) => (r.id === resp.id ? resp : r))
        );
      },

      addHiringProcessStep: (item: HiringProcessType) =>
        setEditHiringProcessSteps((prev) => [...prev, item]),
      editHiringProcessStep: (item: HiringProcessType) => {
        setEditHiringProcessSteps((prev) =>
          prev.map((hps) => (hps.id === item.id ? item : hps))
        );
      },
      removeHiringProcessStep: (item: HiringProcessType) =>
        setEditHiringProcessSteps((prev) =>
          prev.filter((hps) => hps.id !== item.id)
        ),

      addPerk: (item: Perk) => setEditPerks((prev) => [...prev, item]),
      editPerk: (item: Perk) => {
        setEditPerks((prev) => prev.map((p) => (p.id === item.id ? item : p)));
      },
      removePerk: (item: Perk) =>
        setEditPerks((prev) => prev.filter((p) => p.id !== item.id)),

      addTimeSpendingDetails: (data: AddTimeSpendingDetailsData) => {
        const newItem = { ...data, id: uniqNum(), value: 50 };
        setEditTimeSpendingDetails((prev) => [...prev, newItem]);
      },
      removeTimeSpendingDetails: (
        id: $ElementType<TimeSpendingDetails, 'id'>
      ) => {
        setEditTimeSpendingDetails((prev) => {
          return prev.filter((i) => i.id !== id);
        });
      },
      updateTimeSpendingDetails: (data: TimeSpendingDetails) => {
        setEditTimeSpendingDetails((prev) =>
          prev.map((i) => {
            if (i.id === data.id) return data;
            return i;
          })
        );
      },

      addTool: (data: $Diff<Tool, { id: $ElementType<Tool, 'id'> }>) => {
        setEditTools((prev) => [...prev, { id: uniqNum(), ...data }]);
      },
      removeTool: (id: $ElementType<Tool, 'id'>) => {
        setEditTools((prev) => {
          return prev.filter((i) => i.id !== id);
        });
      },
      updateTool: (tool: Tool) => {
        setEditTools((prev) =>
          prev.map((i) => {
            if (i.id === tool.id) return tool;
            return i;
          })
        );
      },

      pickProductImages: (images: Image[]) =>
        actions.setEditProductImages((prev) => [...prev, ...images]),
      uploadProductImage: (image: Image, companyId?: ?number): Promise<any> => {
        return new Promise((resolve, reject) => {
          uploadCompanyImageRequest(image, 'product', companyId)
            .then((response) => {
              if (response.status === 200) {
                const rImage = normServerImage({ ...image, ...response.data });
                setEditProductImages(() => [...editProductImages, rImage]);
                resolve(rImage);
              } else {
                // @Todo: show a warning or error
              }
            })
            .catch((error) => {
              if (error.response.status === 500) {
                reject(
                  new Error(
                    'Image upload failed. Please, try again or contact our support team.'
                  )
                );
              }
            });
        });
      },
      uploadTeamLeadImage: (
        image: Image,
        companyId?: ?number
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          uploadCompanyImageRequest(image, 'team-lead', companyId)
            .then((response) => {
              if (response.status === 200) {
                const rImage = normServerImage({ ...image, ...response.data });
                setEditTeamLeadImage(rImage);
                resolve(rImage);
              } else {
                // @Todo: show a warning or error
              }
            })
            .catch((error) => {
              if (error.response.status === 500) {
                reject(
                  new Error(
                    'Image upload failed. Please, try again or contact our support team.'
                  )
                );
              }
            });
        });
      },
      removeProductImage: (item: CarouselItem) => {
        setEditProductImages((prev) => prev.filter((i) => i.id !== item.id));
      },
      moveProductImage: ({
        drag,
        drop,
      }: {
        drag: CarouselItem,
        drop: CarouselItem,
      }) => {
        const dragIdx = editProductImages.findIndex(
          (i) => i.src === drag.data.src
        );
        const dropIdx = editProductImages.findIndex(
          (i) => i.src === drop.data.src
        );
        setEditProductImages((prev) => {
          const newState = [...prev];
          newState.splice(dropIdx, 1, prev[dragIdx]);
          newState.splice(dragIdx, 1, prev[dropIdx]);
          return newState;
        });
      },
    };
  }, [editProductImages]);

  const state = useMemo(
    () => ({
      errors,
      saving,
      exiting,
      isTech,
      editTitle,
      editDescription,
      editLocation,
      editIsPublic,
      editRole,
      editSeniority,
      editType,
      editSalary,
      editSalaryCurrency,
      editStockOptions,
      editHomeOffice,
      editTeamSize,
      editFullyRemote,
      editCustomerFacing,
      editBusinessTravel,
      editPrimaryLanguage,
      editPrimaryPlatformOrFramework,
      editSecondaryLanguage,
      editSecondaryPlatformOrFramework,
      editMainDatabase,
      editCoreRequirements,
      editResponsibilities,
      editHiringProcessSteps,
      editPerks,
      editTeamLeadImage,
      editTeamLeadName,
      editTeamLeadRole,
      editTeamLeadLink,
      editTimeSpendingDetails,
      editTools,
      editProductImages,
      editProductDescription,
    }),
    [
      isTech,
      editBusinessTravel,
      editCoreRequirements,
      editCustomerFacing,
      editDescription,
      editLocation,
      editFullyRemote,
      editHiringProcessSteps,
      editHomeOffice,
      editIsPublic,
      editMainDatabase,
      editPerks,
      editSalary,
      editSalaryCurrency,
      editPrimaryLanguage,
      editPrimaryPlatformOrFramework,
      editProductDescription,
      editProductImages,
      editResponsibilities,
      editSecondaryLanguage,
      editSecondaryPlatformOrFramework,
      editRole,
      editSeniority,
      editStockOptions,
      editTeamLeadImage,
      editTeamLeadLink,
      editTeamLeadName,
      editTeamLeadRole,
      editTeamSize,
      editTimeSpendingDetails,
      editTitle,
      editTools,
      editType,
      errors,
      exiting,
      saving,
    ]
  );

  return {
    validate,
    state,
    actions,
  };
}
