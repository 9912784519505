// @flow
import type { State, Action } from '../../../core/types';
import { AUTH_TOKEN_KEY } from '../../../core/constants';
import {
  SET_AUTH_TOKEN,
  GET_USER_START,
  GET_USER_END,
  RESET_USER,
  GET_USER_STATS_START,
  GET_USER_STATS_END,
  SET_USER_STATS,
} from './actions';

const auth = (state: State, action: Action) => {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.authToken,
        authenticated: !!action.authToken,
      };
    case GET_USER_START:
      return {
        ...state,
        gettingUser: { loading: true },
      };
    case GET_USER_END:
      return {
        ...state,
        // quick test for admins
        // user: { ...action.data, roles: ['USER', 'ADMIN'] },
        user: action.data,
        gettingUser: { loading: false },
      };
    case GET_USER_STATS_START:
      return {
        ...state,
        gettingUserStats: { loading: true },
      };
    case GET_USER_STATS_END:
      return {
        ...state,
        stats: action.data,
        gettingUserStats: { loading: false },
      };
    case SET_USER_STATS:
      return {
        ...state,
        stats: action.data,
      };
    case RESET_USER:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

const currentAuthToken = window.localStorage.getItem(AUTH_TOKEN_KEY) || null;

auth.initialState = {
  authToken: currentAuthToken,
  authenticated: !!currentAuthToken,
  user: null,
  stats: null,
  gettingUser: { loading: false },
  gettingUserStats: { loading: false },
  isCompany: (user) =>
    user && user.roles && user.roles.some((role) => role === 'COMPANY'),
  isAdmin: (user) =>
    user && user.roles && user.roles.some((role) => role === 'ADMIN'),
};

export { auth };
