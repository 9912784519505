// @flow
/* eslint-disable no-console */
const logLevels = {
  info: 'info',
  warn: 'warn',
  error: 'error',
};

const logHandlers = {
  [logLevels.info]: console.log,
  [logLevels.warn]: console.warn,
  [logLevels.error]: console.error,
};

type Message = string | { [key: string]: any };
type Details = { logLevel: $Values<typeof logLevels>, error?: Message };

export function log(message: Message, details: Details) {
  const defaultHandler = logHandlers[logLevels.info];
  const resolvedHandler = logHandlers[details.logLevel];

  let handler = defaultHandler;
  if (typeof resolvedHandler === 'function') {
    handler = resolvedHandler;
  }

  const logMessage = `Log [${details.logLevel}]: \n\t ${message.toString()}`;
  handler(logMessage, details);
}

log.logLevels = logLevels;
