// @flow
/* eslint-disable no-nested-ternary */
import * as React from 'react';
// import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TopMenuContext, SideMenuTypes } from '../context/TopMenu';
import { MenuItem } from './MenuItem';
import { useTracking } from '../../tracking/TrackingContext';
import { useStateContainer } from '../../../core/context/StateContainer';
import { Loader } from '../../basic/Loader';
import { useMyCompany } from '../../companies/common/context/MyCompanyContext';
import { paths, externalPaths } from '../../../core/constants';
import cn from 'classnames';

const { useContext } = React;

const useStyles = makeStyles((theme) => ({
  navigation: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  links: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  linksMobile: {
    display: 'none',

    '@media (max-width: 480px)': {
      flex: 1,
      fontSize: `${theme.typography.pxToRem(14)} !important`,
      marginRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
  menuItem2: {
    color: ({ isWithBackground }) =>
      isWithBackground
        ? theme.palette.common.white
        : theme.palette.text.primary,
    textDecoration: 'none',
    marginLeft: theme.spacing(3),
    marginBottom: 0,
  },
  menuItem: {
    color: ({ isWithBackground }) =>
      isWithBackground
        ? theme.palette.common.white
        : theme.palette.text.primary,
    textDecoration: 'none',
    marginRight: theme.spacing(5),
    marginBottom: 0,
    display: 'inline-block',
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: `2px solid transparent`,

    '&:hover': {
      color: ({ isWithBackground }) =>
        isWithBackground
          ? theme.palette.common.white
          : theme.palette.grey.darkest,
      opacity: ({ isWithBackground }) => (isWithBackground ? '0.8' : 1),
    },
  },
  menuItemAccent: {
    fontWeight: theme.typography.fontWeightBold,
  },
  userIcons: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 480px)': {
      display: 'flex',
    },
  },
  icon: {
    fontSize: 20,
    cursor: 'pointer',
  },
  iconActive: {
    color: theme.palette.grey.dark,
  },
  login: ({ isWithBackground }) => ({
    color: isWithBackground ? 'white' : theme.palette.text.primary,
    cursor: 'pointer',

    '&:hover': {
      opacity: isWithBackground ? '0.8' : 1,
    },
  }),
  userTagWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& #user-chevron-down': {
      transition: 'all 0.2s',
    },

    '&:hover #user-chevron-down': {
      transform: `rotate(90deg)`,
    },
  },
  userTag: {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    // padding: theme.spacing(2),
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[200],
    cursor: 'pointer',
    transition: 'all 0.2s',

    '&:hover': {
      background: theme.palette.grey[300],
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(4),
    '@media (max-width: 500px)': {
      marginLeft: theme.spacing(2),
    },
    '@media (max-width: 350px)': {
      marginLeft: theme.spacing(1.5),
    },
  },
  hamburger: ({ isWithBackground }) => ({
    fontSize: 20,
    cursor: 'pointer',
    display: 'none',
    color: isWithBackground
      ? theme.palette.common.white
      : theme.palette.text.primary,

    '@media (max-width: 1200px)': {
      display: 'inline-block',
    },
  }),
  applyButton: {
    marginRight: theme.spacing(2),
    '@media (max-width: 500px)': {
      display: 'none',
    },
  },
  inviteButtonContainer: ({ isWithBackground }) => ({
    color: isWithBackground
      ? theme.palette.common.white
      : theme.palette.text.primary,
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      opacity: isWithBackground ? '0.8' : 1,
    },
  }),
  pointer: {
    cursor: 'pointer',
  },
  inviteText: {
    marginRight: theme.spacing(1),
  },
}));

function MainMenu() {
  const [
    {
      auth: { authenticated, user, isCompany, isAdmin },
    },
  ] = useStateContainer();
  const myCompany = useMyCompany();
  const { activeSideMenuType, open, isWithBackground } =
    useContext(TopMenuContext);
  const { track, events } = useTracking();
  const classes = useStyles({ isWithBackground });

  // function openNotificationsMenu() {
  //   open(SideMenuTypes.notifications);
  // }

  function openReferralsMenu() {
    open(SideMenuTypes.referrals);
    track(events.MENU_REFERRALS_ICON_CLICK);
  }

  function openUserMenu() {
    open(SideMenuTypes.user);
    track(events.MENU_PROFILE_ICON_CLICK);
  }

  const openSiteNavigation = React.useCallback(() => {
    open(SideMenuTypes.siteNavigation);
  }, [open]);

  function activeOrEmptyClass({
    opens,
  }: {
    opens: $Values<typeof SideMenuTypes>, // eslint-disable-line
  }) {
    return opens === activeSideMenuType ? classes.iconActive : '';
  }

  return (
    <div className={classes.navigation}>
      <div className={classes.linksMobile} />
      <div className={classes.links}>
        {/* <MenuItem
          internal
          to={paths.matchMe}
          className={cn(classes.menuItem, classes.menuItemAccent)}
        >
          Match Me
        </MenuItem> */}
        <MenuItem internal to={paths.jobs} className={classes.menuItem}>
          Jobs
        </MenuItem>
        <MenuItem internal to={paths.companies} className={classes.menuItem}>
          Companies
        </MenuItem>
        {/* <MenuItem internal to={paths.referrers} className={classes.menuItem}>
          Refer
        </MenuItem>i */}
        <MenuItem internal to={paths.businesses} className={classes.menuItem}>
          For Employers
        </MenuItem>
        <MenuItem
          to={externalPaths.secretNewsletter}
          className={classes.menuItem}
          target="_blank"
        >
          Secret Newsletter
        </MenuItem>

        {/* <MenuItem
          to={externalPaths.salarySurvey2022}
          className={classes.menuItem}
        >
          Salary Survey
        </MenuItem> */}

        {/* <MenuItem internal to={paths.jobs} className={classes.menuItem}>
          Find a Job
        </MenuItem>

        <MenuItem to={externalPaths.jobBoard} className={classes.menuItem}>
          Discover Companies
        </MenuItem>

        <MenuItem to={externalPaths.employers} className={classes.menuItem}>
          For Employers
        </MenuItem>

        <MenuItem to={externalPaths.about} className={classes.menuItem}>
          About
        </MenuItem> */}
      </div>
      <div />
      <div className={classes.userIcons}>
        {/* <FontAwesomeIcon
          className={`${classes.icon} ${activeOrEmptyClass({
            opens: SideMenuTypes.notifications
          })}`}
          icon={['fal', 'bell']}
          onClick={openNotificationsMenu}
        /> */}

        <div
          role="button"
          tabIndex={0}
          onKeyPress={() => { }}
          onClick={openReferralsMenu}
          className={`${classes.inviteButtonContainer} ${
            classes.pointer
            } ${activeOrEmptyClass({
              opens: SideMenuTypes.referrals,
            })}`}
        >
          <span className={classes.inviteText}>Refer</span>
          <FontAwesomeIcon
            className={`${classes.icon}`}
            icon={['fal', 'users-medical']}
          />
        </div>

        {authenticated &&
          user &&
          isCompany(user) &&
          !myCompany.loading &&
          myCompany.slug ? (
            <MenuItem
              internal
              to={paths.companiesDashboard.replace(':slug', myCompany.slug)}
              className={classes.menuItem2}
            >
              Dashboard
            </MenuItem>
          ) : authenticated && isCompany(user) ? (
            <Loader />
          ) : null}

        {authenticated ? (
          <div
            tabIndex={0}
            role="button"
            onKeyUp={() => { }}
            onClick={openUserMenu}
            className={classes.userTagWrapper}
          >
            <div
              className={`${classes.userTag} ${classes.marginLeft}`}
              style={{
                border: isAdmin(user) ? '3px solid orange' : '',
              }}
            >
              {!user ? (
                <Loader />
              ) : (
                  <div>{user.username.substring(0, 1).toUpperCase()}</div>
                )}
            </div>
            <FontAwesomeIcon
              icon={['fal', 'chevron-down']}
              id="user-chevron-down"
            />
          </div>
        ) : (
            /* <FontAwesomeIcon
              className={`${classes.icon} ${
                classes.marginLeft
              } ${activeOrEmptyClass({ opens: SideMenuTypes.user })}`}
              icon={['fal', 'user-circle']}
              onClick={openUserMenu}
            /> */
            <div
              role="button"
              onKeyUp={() => { }}
              tabIndex={0}
              onClick={openUserMenu}
              className={`${classes.login} ${classes.marginLeft}`}
            >
              Login
            </div>
          )}

        <FontAwesomeIcon
          icon={['fal', 'bars']}
          onClick={openSiteNavigation}
          className={`${classes.hamburger} ${classes.icon} ${
            classes.marginLeft
            } ${activeOrEmptyClass({ opens: SideMenuTypes.siteNavigation })}`}
        />
      </div>
    </div>
  );
}

export { MainMenu };
