// @flow
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { BasicPersonalInformation } from '../../components/steps/BasicPersonalInformation';
import { CandidateCurrentState } from '../../components/steps/CandidateCurrentState';
import { CurrentJobSearchStatus } from '../../components/steps/CurrentJobSearchStatus';
import { saveBasicInformation } from '../../onboardingFormsService';
import { useStateContainer } from '../../../../core/context/StateContainer';
import {
  completeSection,
  nextStep,
  nextSyntheticStep,
  setOnboardingLoading,
  startOnboardingSection,
} from '../../actions';
import { buildStepsValidateFunc } from '../../../../utils/buildValidateFunc';
import {
  basicInformation,
  basicInformationNonTech,
} from '../../validateConfig';
import { dispatchSaveError } from '../../../basic/form/utils/dispatchSaveError';
import { isRoleTech } from '../../../../utils/isRoleTech';
import { useTracking } from '../../../tracking/TrackingContext';

const useBasicInformationForm = () => {
  const { track, events } = useTracking();
  const query = useLocation().search;
  const [
    {
      onboarding: {
        currentStep,
        isTech,
        isLookingForTech,
        hydration: {
          data: { basicInfo },
        },
      },
    },
    dispatch,
  ] = useStateContainer();
  const initialValues = basicInfo;
  const includesTechnicalQuestions = isTech || isLookingForTech;

  function validationConfigMutator(values, config, step) {
    let cfg =
      typeof config === 'function'
        ? config(includesTechnicalQuestions)
        : config;

    if (isLookingForTech) {
      return cfg;
    }

    cfg = basicInformationNonTech(includesTechnicalQuestions);

    return { ...cfg[step.toString()] };
  }

  const validateStep = buildStepsValidateFunc(
    basicInformation(includesTechnicalQuestions),
    validationConfigMutator
  );

  const getSteps = () => {
    return [
      <BasicPersonalInformation key="basic-personal-information" />,
      <CurrentJobSearchStatus
        key="current-job-search-status"
        lookingForTechRole={isLookingForTech}
      />,
      <CandidateCurrentState
        key="candidate-current-state"
        includesTechnicalQuestions={includesTechnicalQuestions}
      />,
    ];
  };

  async function onFormSubmit(values: { [key: string]: any }) {
    const lastStep = 2;
    const basicPersonalInformationStepIndex = 0;
    const jobSearchStatusStepIndex = 1;
    const isBlank = !query;

    // track complete basic personal information
    if (currentStep === basicPersonalInformationStepIndex) {
      track(events.COMPLETE_BASIC_PERSONAL_INFORMATION, { isBlank });
    }

    // track job search status
    if (currentStep === jobSearchStatusStepIndex) {
      track(events.COMPLETE_JOB_SEARCH_STATUS, { isBlank });
    }

    if (currentStep === lastStep) {
      const valuesToBeSubmitted = {
        ...values,
        tech: isTech || isLookingForTech,
      };
      dispatch(setOnboardingLoading(true));
      saveBasicInformation({
        data: { ...valuesToBeSubmitted },
      })
        .then((response) => {
          if (response.status === 204) {
            // track complete current position step -> if mid/senior/exper dev-related -> fire qualified, otherwise -> fire complete current position step
            const qualifiedLeadSeniorityLevels = ['mid', 'senior', 'expert'];
            const isQualifiedLead =
              isRoleTech(values.currentPosition) &&
              qualifiedLeadSeniorityLevels.includes(values.seniority);

            if (isQualifiedLead) {
              track(events.QUALIFIED_LEAD, {
                position: values.currentPosition,
                seniority: values.seniority,
              });
            } else {
              track(events.COMPLETE_CURRENT_POSITION_STEP, { isBlank });
            }

            dispatch(completeSection(1));
            dispatch(nextSyntheticStep());
            dispatch(setOnboardingLoading(false));
            dispatch(startOnboardingSection(2));
          }
        })
        .catch(() => {
          dispatchSaveError(dispatch);
          dispatch(setOnboardingLoading(false));
        });
    } else {
      dispatch(nextStep());
      dispatch(nextSyntheticStep());
    }
  }

  return {
    initialValues,
    onFormSubmit,
    validateStep,
    currentStep,
    getSteps,
  };
};

export { useBasicInformationForm };
