// @flow
import * as React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { ProtectedRoute } from './components/basic/routes/ProtectedRoute';
import { GuestRoute } from './components/basic/routes/GuestRoute';
import { Registration } from './pages/Registration';
import { Registration as CompanyRegistration } from './pages/auth-company/Registration';
import { Login } from './pages/Login';
import { ForgottenPassword } from './pages/ForgottenPassword';
import { ForgottenPasswordReset } from './pages/ForgottenPasswordReset';
import { ComponentsPreview } from './pages/components-preview/ComponentsPreview';
import { paths } from './core/constants';
import { OnboardingRoot } from './pages/onboarding/OnboardingRoot';
import { DirectApplication } from './pages/DirectApplication';
import { ReferralLinkLanding } from './pages/ReferralLinkLanding';
import {
  QUERY_HINT_RESOLUTION,
  queryHintResolver,
} from './utils/urlHintResolver';
import { DirectReferralLanding } from './pages/DirectReferralLanding';
import { OldUserLanding } from './pages/OldUserLanding';
import { DirectApplicationGuest } from './pages/DirectApplicationGuest';
import { CompanyDashboard } from './pages/CompanyDashboard';
import { CompanyProfilePage } from './pages/CompanyProfilePage';
import { RegistrationSuccess } from './pages/auth-company/RegistrationSuccess';
import { JobsPage } from './pages/jobs/JobsPage';
import { JobCreatePage } from './pages/jobs/JobCreatePage';
import { TermsAndConditions } from './pages/TermsAndConditions';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { PostLogin } from './pages/PostLogin';
import { ExistingCompanyLanding } from './pages/ExistingCompanyLanding';
import { SeoJobsPage } from './pages/jobs/SeoJobsPage';
import { FeedEventsPage } from './pages/feed/FeedEventsPage';
import { EuLegal } from './pages/EuLegal';
import { About } from './pages/About';
import { Referrers } from './pages/Referrers';
import { Businesses } from './pages/Businesses';
import { MatchMe } from './pages/MatchMe';
import { MatchMeForm } from './pages/MatchMeForm';
import { Companies } from './pages/Companies';
import { SeoCompanies } from './pages/SeoCompanies';
import { CookiesPolicy } from './pages/CookiesPolicy';

function AppRoutes() {
  const queryString = useLocation().search;

  return (
    <React.Fragment>
      {/* A route rendered on every page, which fires up a referrals check hook. */}
      <Route path="*" component={ReferralLinkLanding} />

      <Switch>
        <Route exact path={paths.$components} component={ComponentsPreview} />

        <Route
          exact
          path={paths.termsAndConditions}
          component={TermsAndConditions}
        />
        <Route exact path={paths.privacyPolicy} component={PrivacyPolicy} />
        <Route path={paths.cookiePolicy} component={CookiesPolicy} />
        <Route exact path="/token/:token" component={DirectReferralLanding} />
        <Route exact path="/old-user/:token" component={OldUserLanding} />
        <Route
          exact
          path="/company-user/:token"
          component={ExistingCompanyLanding}
        />
        <GuestRoute
          exact
          path={paths.login}
          component={Login}
          redirectTo={paths.authenticating}
          push={false}
        />
        <Route exact path={paths.authenticating} component={PostLogin} />
        <GuestRoute
          exact
          path={paths.register}
          component={Registration}
          redirectTo={() => {
            const queryResolution = queryHintResolver(queryString);

            if (queryResolution === QUERY_HINT_RESOLUTION.SHORT_REGISTRATION) {
              return { pathname: paths.root, search: '' };
            }

            if (queryResolution === QUERY_HINT_RESOLUTION.DIRECT_APPLICATION) {
              return paths.directApplication;
            }

            return paths.onboarding;
          }}
          push={false}
        />
        <ProtectedRoute
          exact
          path={paths.companiesRegisterSuccess}
          component={RegistrationSuccess}
        />
        <GuestRoute
          exact
          component={CompanyRegistration}
          path={paths.companiesRegister}
          redirectTo={paths.companiesRegisterSuccess}
          push={false}
        />
        <GuestRoute
          exact
          path={paths.forgottenPassword}
          component={ForgottenPassword}
          redirectTo={paths.onboarding}
          push={false}
        />
        <GuestRoute
          exact
          path={paths.forgottenPasswordReset}
          component={ForgottenPasswordReset}
          redirectTo={paths.onboarding}
          push={false}
        />
        <ProtectedRoute
          exact
          path={paths.directApplication}
          component={DirectApplication}
          redirectTo={() => (
            <DirectApplicationGuest queryString={queryString} />
          )}
        />

        <Route exact path="/eu" component={EuLegal} />
        <Route exact path={paths.about} component={About} />
        <Route exact path={paths.businesses} component={Businesses} />
        <Route exact path={paths.referrers} component={Referrers} />
        <Route exact path={paths.matchMe} component={MatchMe} />
        <Route exact path={paths.matchMeForm} component={MatchMeForm} />
        <Route exact path={paths.companies} component={Companies} />

        <Route path={paths.seo__jobsPage} component={SeoJobsPage} />
        <Route path={paths.seo__companiesPage} component={SeoCompanies} />

        <Route path={paths.feed} component={FeedEventsPage} />

        <ProtectedRoute
          exact
          path={paths.onboarding}
          component={OnboardingRoot}
        />

        <ProtectedRoute
          exact
          path={paths.companiesDashboard}
          redirectTo={paths.root}
          component={CompanyDashboard}
        />

        <Route path={paths.jobsCreate} component={JobCreatePage} />

        <Route
          exact
          path={paths.companiesProfile}
          component={CompanyProfilePage}
        />

        <Route path={paths.root} component={JobsPage} />
      </Switch>
    </React.Fragment>
  );
}

export { AppRoutes };
