// @flow
/* eslint-disable flowtype/space-after-type-colon */
import React, { createContext, useState, useContext, useCallback } from 'react';
import type { Node } from 'react';
import { getFeedEvents } from './feedEventsService';

export type FeedEvent = {
  id: number,
  companySlug?: string,
  jobSlug?: string,
  eventType: string,
  postedAt: Date,
  oldValue?: string,
  newValue?: string,
};

type PaginationData = {
  page: number | null,
  totalElements: number | null,
  totalPages: number | null,
};

export type Ctx = {
  loading: boolean,
  load: (page?: number) => Promise<any>,
  loadingMore: boolean,
  loadMore: () => Promise<any>,
  feedEvents: FeedEvent[],
  paginationData: PaginationData,
};

const Context = createContext<Ctx | null>(null);

export function FeedEventsProvider({ children }: { children: Node }) {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [feedEvents, setFeedEvents] = useState<FeedEvent[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationData>({
    page: null,
    totalElements: null,
    totalPages: null,
  });

  const load = useCallback(async (page?: number) => {
    setLoading(true);
    const pageNumber = Number.isNaN(Number(page)) ? undefined : Number(page);
    const response = await getFeedEvents({ page: pageNumber });
    const rFeedEvents = response.elements;

    setPaginationData((prev) => ({
      ...prev,
      page: response.page,
      totalElements: response.totalElements,
      totalPages: response.totalPages,
    }));
    setLoading(false);
    setFeedEvents(rFeedEvents);
  }, []);

  const loadMore = useCallback(async () => {
    setLoadingMore(true);

    const response = await getFeedEvents({ page: paginationData.page + 1 });
    const rFeedEvents = response.elements;

    setPaginationData((prev) => ({
      ...prev,
      page: response.page,
      totalElements: response.totalElements,
      totalPages: response.totalPages,
    }));
    setLoadingMore(false);
    setFeedEvents((prev) => [...prev, ...rFeedEvents]);
  }, [paginationData]);

  const value = {
    loading,
    load,
    loadingMore,
    loadMore,
    feedEvents,
    paginationData,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useFeedEvents: () => Ctx = () => {
  const ctx = useContext(Context);
  if (ctx === null) throw new Error('Improper use of FeedEventsProvider.');
  return ctx;
};
