// @flow
import React, { useCallback } from 'react';
import ClickToSelect from '@mapbox/react-click-to-select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { makeStyles } from '@material-ui/core';
import { Modal } from '../../basic/Modal';
import { Button } from '../../basic/Button';
import { dequeueModal } from '../../modals/actions';
import { useStateContainer } from '../../../core/context/StateContainer';
import { Loader } from '../../basic/Loader';
import { InfoBox } from './components/InfoBox';

type Props = {
  link: string,
  onCopy?: () => void,
  loading?: boolean,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 700,
    textAlign: 'left',

    '@media (max-width: 768px)': {
      minWidth: 'auto',
      alignItems: 'flex-start',
    },
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',

    '@media (max-width: 480px)': {
      flexDirection: 'column',
    },
  },
  title: {
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4),
    color: theme.palette.grey.dark,
  },
  linkContainer: {
    width: '100%',
    flex: 1,
    padding: `7px 12px`,
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey.dark}`,
  },
  copyButtonContainer1: {
    display: 'block',
    '@media (max-width: 480px)': {
      display: 'none',
    },
  },
  copyButtonContainer2: {
    display: 'none',
    '@media (max-width: 480px)': {
      display: 'block',
    },
  },
  copyButton: {
    marginLeft: -5,
    boxShadow: `${theme.shadows[1]} !important`,

    '@media (max-width: 480px)': {
      marginLeft: 0,
      width: '100%',
    },
  },
  closeButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  closeButton: {
    boxShadow: `${theme.shadows[1]} !important`,
  },
}));

function GetReferralLink({ link, onCopy = () => {}, loading = false }: Props) {
  const classes = useStyles();
  const [, dispatch] = useStateContainer();

  const close = useCallback(() => {
    dispatch(dequeueModal());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Modal
      open
      onClose={close}
      titleId="get_referral_link_modal"
      contentId="get_referral_link_modal"
      paperClassName={classes.paper}
    >
      <div className={classes.title}>Get invite link</div>
      <InfoBox
        title="Invite friends and earn a bonus every time they get hired."
        subtitle="Look at your network and think of people you know who might be a
      good fit for this job. Ask the most potential candidates if they
      are."
      />
      <div className={classes.main}>
        <div className={classes.linkContainer}>
          <ClickToSelect>{link}</ClickToSelect>
        </div>
        <div className={classes.copyButtonContainer1}>
          <CopyToClipboard text={link} onCopy={onCopy}>
            <Button
              className={classes.copyButton}
              color="primary"
              variant="contained"
              type="button"
              onClick={() => {}}
            >
              Copy link
            </Button>
          </CopyToClipboard>
        </div>
      </div>
      <div className={classes.closeButtonContainer}>
        <Button
          type="button"
          color="secondary"
          variant="outlined"
          onClick={close}
          className={classes.closeButton}
        >
          Close
        </Button>
        <div className={classes.copyButtonContainer2}>
          <CopyToClipboard text={link} onCopy={onCopy}>
            <Button
              className={classes.copyButton}
              color="primary"
              variant="contained"
              type="button"
              onClick={() => {}}
            >
              Copy link
            </Button>
          </CopyToClipboard>
        </div>
      </div>
    </Modal>
  );
}

GetReferralLink.defaultProps = {
  loading: false,
  onCopy: () => {},
};

export { GetReferralLink };
