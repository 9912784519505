import { useCallback } from 'react';
import { useStateContainer } from '../../../../core/context/StateContainer';
import {
  hydrateOnboardingStateStart,
  hydrateOnboardingStateEnd,
  setInitialization,
} from '../../../onboarding-talents/actions';
import { onboardingState } from '../../../onboarding-talents/onboardingFormsService';

function useDirectApplicationFormContainer() {
  const [, dispatch] = useStateContainer();
  const hydrateOnboardingState = useCallback(() => {
    dispatch(hydrateOnboardingStateStart());
    onboardingState().then((data) => {
      dispatch(hydrateOnboardingStateEnd(data));
      dispatch(setInitialization(true));
    });
  }, [dispatch]);

  function startHydrationLoading() {
    dispatch(hydrateOnboardingStateStart(true));
  }

  return { hydrateOnboardingState, startHydrationLoading };
}

export { useDirectApplicationFormContainer };
