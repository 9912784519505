// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup, PopupContainer, PopupMain } from '../../../basic/Popup';
import { Button } from '../../../basic/Button';
import { useApply } from '../../ApplicationsContext';
import bg from '../../../../assets/images/survey-description-icon-bg.svg';

const useStyles = makeStyles((theme) => {
  return {
    titleContainer: {
      marginBottom: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    closeButton: {
      fontSize: theme.typography.pxToRem(18),
      border: 0,
      background: 'transparent',
      padding: 0,
      margin: 0,
      cursor: 'pointer',
    },
    success: {
      fontSize: theme.typography.pxToRem(21),
      marginBottom: theme.spacing(1),
    },
    p: {
      marginBottom: theme.spacing(4),
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    shapeContainer: {
      position: `relative`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
    },
    check: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%)`,
    },
  };
});

export function ShortApplySuccessPopup() {
  const { isApplySuccessPopupVisible: isOpen, hideSuccess } = useApply();
  const classes = useStyles();

  return (
    <Popup maxWidth={420} isOpen={isOpen} onClose={hideSuccess}>
      <PopupContainer>
        <PopupMain>
          <div className={classes.container}>
            <div className={classes.shapeContainer}>
              <img src={bg} alt="Purple shape" className={classes.shape} />
              <FontAwesomeIcon
                icon={['fal', 'check']}
                size="2x"
                className={classes.check}
              />
            </div>
            <p className={classes.success}>You have applied successfully.</p>

            <p className={classes.p}>
              The company will review your application and get back to you as
              soon as possible.
            </p>

            <Button
              variant="contained"
              color="primary"
              disableElevation
              type="button"
              onClick={hideSuccess}
            >
              Close
            </Button>
          </div>
        </PopupMain>
      </PopupContainer>
    </Popup>
  );
}
