// @flow
import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useStateContainer } from '../../../core/context/StateContainer';

type Props = {
  path: string,
  component: React.ComponentType<any>,
  // eslint-disable-next-line
  redirectTo?:
    | ?string
    | ?(() => string | { pathname: string, search: string } | null),
  externalRedirectTo?: ?string,
  push?: boolean,
  location?: { search: string },
};

const GuestRoute = ({
  path,
  component,
  redirectTo,
  externalRedirectTo,
  push,
  location = { search: '' },
  ...routeProps
}: Props) => {
  const Component = component;
  const [
    {
      auth: { authenticated },
    },
  ] = useStateContainer();

  if (!authenticated) {
    return (
      <Route
        path={path}
        {...routeProps}
        render={(props) => <Component {...props} />}
      />
    );
  }

  if (externalRedirectTo) {
    window.location.href = externalRedirectTo;
    return null;
  }

  let redirectLocation = null;
  if (typeof redirectTo === 'function') {
    redirectLocation = redirectTo();
  } else if (typeof redirectTo === 'string') {
    redirectLocation = redirectTo;
  }

  if (redirectLocation !== null) {
    return (
      <Redirect
        to={
          typeof redirectLocation === 'string'
            ? { pathname: redirectLocation, search: location.search }
            : redirectLocation
        }
        push={push}
      />
    );
  }
  return null;
};

GuestRoute.defaultProps = {
  push: true,
  location: { search: '' },
  redirectTo: null,
  externalRedirectTo: null,
};

export { GuestRoute };
