// @flow
import API from '../../../core/rest/API';
import type {
  ServerCompany,
  ServerImageCollection,
} from './context/CompanyContext';

function getCompanyDataRequest(slug?: string): Promise<{ data: any }> {
  if (slug) {
    return API.getResponse(`/company/${slug}`);
  }

  return API.getResponse('/company/my');
}

function updateCompanyDataRequest(
  newData: ServerCompany,
  opts: { admin: boolean } = { admin: false }
): Promise<any> {
  return API.postResponse(
    opts.admin ? '/company/admin' : '/company/my',
    newData
  );
}

function uploadCompanyImageRequest(
  image: any,
  type: ServerImageCollection,
  companyId?: ?number
): Promise<any> {
  const formData = new FormData();
  formData.append('file', image.blob);
  formData.append('collection-type', type);

  if (companyId) {
    formData.append('company-id', companyId.toString());
  }

  return API.postResponse('/upload/company-image', formData);
}

export {
  getCompanyDataRequest,
  updateCompanyDataRequest,
  uploadCompanyImageRequest,
};
