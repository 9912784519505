// @flow
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';
import { paths } from '../../../core/constants';
import { Link } from 'react-router-dom';
import { useForm, ValidationError } from '@formspree/react';
import { makeStyles } from '@material-ui/core';
import { Button } from '../../basic/Button';
import cn from 'classnames';
import { useInputV2Styles } from '../../basic/form/InputV2';
import { useRequestCompanyProfile } from './RequestCompanyProfileProvider';

const useStyles = makeStyles((theme) => ({
  form: {},
  row: {
    paddingBottom: theme.spacing(2),

    '& label': {
      width: 'fit-content',
      display: 'block',
      paddingBottom: theme.spacing(0.5),
    },
  },
  input: {
    width: '100%',
  },
  split: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2),

    '@media screen and (max-width: 480px)': {
      gridTemplateColumns: '1fr',
    },
  },
  successMsg: {
    margin: theme.spacing(10, 0),
    textAlign: 'center',
  },
  successMsgTitle: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(2),
  },
}));

export function RequestProfileForm() {
  const classes = useStyles();
  const inputStyles = useInputV2Styles();
  const firstInputRef = useRef(null);
  const { closePopup } = useRequestCompanyProfile();

  const [state, handleSubmit] = useForm('mvodjrbj');

  useEffect(() => {
    if (firstInputRef && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  if (state.succeeded) {
    return (
      <div className={classes.successMsg}>
        <div className={classes.successMsgTitle}>Thank you for applying!</div>
        <span>We&apos;ll reach out to you as soon as possible.</span>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div className={classes.row}>
        <label htmlFor="company_name">Company name*</label>
        <input
          ref={firstInputRef}
          id="company_name"
          type="text"
          name="company_name"
          className={cn(
            inputStyles.primary,
            { [inputStyles.error]: state.errors.email },
            classes.input
          )}
          required
        />
        <ValidationError
          prefix="Company name"
          field="company_name"
          errors={state.errors}
        />
      </div>

      <div className={classes.row}>
        <label htmlFor="company_website">Company website*</label>
        <input
          id="company_website"
          type="text"
          name="company_website"
          className={cn(
            inputStyles.primary,
            { [inputStyles.error]: state.errors.email },
            classes.input
          )}
          required
        />
        <ValidationError
          prefix="Company website"
          field="company_website"
          errors={state.errors}
        />
      </div>

      <div className={classes.split}>
        <div className={classes.row}>
          <label htmlFor="first_last_name">First & last name*</label>
          <input
            id="first_last_name"
            type="text"
            name="first_last_name"
            className={cn(
              inputStyles.primary,
              { [inputStyles.error]: state.errors.email },
              classes.input
            )}
            required
          />
          <ValidationError
            prefix="First & last name"
            field="first_last_name"
            errors={state.errors}
          />
        </div>

        <div className={classes.row}>
          <label htmlFor="position">Position*</label>
          <input
            id="position"
            type="text"
            name="position"
            className={cn(
              inputStyles.primary,
              { [inputStyles.error]: state.errors.email },
              classes.input
            )}
            required
          />
          <ValidationError
            prefix="Position"
            field="position"
            errors={state.errors}
          />
        </div>
      </div>

      <div className={classes.split}>
        <div className={classes.row}>
          <label htmlFor="email">Email*</label>
          <input
            id="email"
            type="email"
            name="email"
            className={cn(
              inputStyles.primary,
              { [inputStyles.error]: state.errors.email },
              classes.input
            )}
            required
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>

        <div className={classes.row}>
          <label htmlFor="phone">Phone*</label>
          <input
            id="phone"
            type="number"
            name="phone"
            className={cn(
              inputStyles.primary,
              { [inputStyles.error]: state.errors.email },
              classes.input
            )}
            required
          />
          <ValidationError prefix="Phone" field="phone" errors={state.errors} />
        </div>
      </div>

      <div className={classes.row}>
        <label>
          <input name="terms_agreement" type="checkbox" required />
          <span>
            I agree with Noble Hire’s{' '}
            <Link to={paths.termsAndConditions}>Terms & Conditions</Link>. Learn
            how we collect, use and share your data in our{' '}
            <Link to={paths.privacyPolicy}>Data Policy</Link>.
          </span>
        </label>
      </div>

      <ValidationError prefix="Message" field="message" errors={state.errors} />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={state.submitting}
          disableElevation
        >
          Submit
        </Button>
        <Button
          color="default"
          variant="text"
          type="button"
          disabled={state.submitting}
          disableElevation
          onClick={closePopup}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}
