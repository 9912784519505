// @flow
import * as React from 'react';
import type { State, Action } from '../types';
import type { RootState } from '../reducers/root';

const { createContext, useContext, useReducer, useMemo } = React;

type Props = {
  reducer: (state: State, action: Action) => State,
  initialState: State,
  children: React.Node,
};

const StateContainerContext = createContext<?[RootState, (any) => void]>(null);

const StateContainerProvider = ({ reducer, initialState, children }: Props) => {
  const data = useReducer(reducer, initialState);

  const [state, dispatch] = data;
  const contextValue = useMemo(() => [state, dispatch], [state, dispatch]);

  return (
    <StateContainerContext.Provider value={contextValue}>
      {children}
    </StateContainerContext.Provider>
  );
};

const useStateContainer = (): any => useContext(StateContainerContext);

export { StateContainerContext, StateContainerProvider, useStateContainer };
