// @flow
import { programmingLanguages } from '../../core/constants';
import { uniqNum } from '../../utils/uniq';

export const TAG_COLORS = {
  javaScript: '#F7DF1E',
  htmlCss: '#E44D26',
  php: '#377BFF',
  ruby: '#D21B18',
  python: '#376D9D',
  java: '#FF7135',
  go: '#80D3E0',
  cSharp: '#A05CA2',
  cpp: '#B17B7B',
};

export const TAG_SHADOWS = {
  javaScript: '#F7DF1E',
  htmlCss: '#E44D26',
  php: '#377BFF',
  ruby: '#D21B18',
  python: '#376D9D',
  java: '#FF7135',
  go: '#80D3E0',
  cSharp: '#A05CA2',
  cpp: '#B17B7B',
};

export type Tag = {
  id: number,
  label: string,
  color: string,
  shadow: string,
  textColor: string,
  type: 'tech' | 'role' | 'text',
  value:
    | $Keys<typeof programmingLanguages>
    | Array<$Keys<typeof programmingLanguages>>,
};

export const tags: Tag[] = [
  {
    id: uniqNum(),
    label: 'JS',
    color: TAG_COLORS.javaScript,
    shadow: TAG_SHADOWS.javaScript,
    textColor: 'black',
    type: 'tech',
    value: [
      'javaScript',
      'typescript',
      'nodejs',
      'react',
      'angularjs',
      'vue',
      'nextjs',
      'angular2',
      'gatsby',
      'svelte',
    ],
  },
  {
    id: uniqNum(),
    label: 'PHP',
    color: TAG_COLORS.php,
    shadow: TAG_SHADOWS.php,
    textColor: 'white',
    type: 'tech',
    value: 'php',
  },
  {
    id: uniqNum(),
    label: 'Python',
    color: TAG_COLORS.python,
    shadow: TAG_SHADOWS.python,
    textColor: 'white',
    type: 'tech',
    value: 'python',
  },
  {
    id: uniqNum(),
    label: 'Java',
    color: TAG_COLORS.java,
    shadow: TAG_SHADOWS.java,
    textColor: 'white',
    type: 'tech',
    value: ['java', 'kotlin'],
  },
  {
    id: uniqNum(),
    label: 'Go',
    color: TAG_COLORS.go,
    shadow: TAG_SHADOWS.go,
    textColor: 'white',
    type: 'tech',
    value: 'go',
  },
  {
    id: uniqNum(),
    label: 'C#',
    color: TAG_COLORS.cSharp,
    shadow: TAG_SHADOWS.cSharp,
    textColor: 'white',
    type: 'tech',
    value: ['cSharp', 'dotnet'],
  },
  {
    id: uniqNum(),
    label: 'C/C++',
    color: TAG_COLORS.cpp,
    shadow: TAG_SHADOWS.cpp,
    textColor: 'white',
    type: 'tech',
    value: ['c', 'cpp'],
  },
  {
    id: uniqNum(),
    label: 'Mobile',
    color: TAG_COLORS.php,
    shadow: TAG_SHADOWS.php,
    textColor: 'white',
    type: 'role',
    value: 'mobile',
  },
  {
    id: uniqNum(),
    label: 'DevOps',
    color: TAG_COLORS.ruby,
    shadow: TAG_SHADOWS.ruby,
    textColor: 'white',
    type: 'role',
    value: 'devops',
  },
  {
    id: uniqNum(),
    label: 'QA',
    color: TAG_COLORS.cpp,
    shadow: TAG_SHADOWS.cpp,
    textColor: 'white',
    type: 'role',
    value: 'qa',
  },
  {
    id: uniqNum(),
    label: 'Tech Lead',
    color: TAG_COLORS.htmlCss,
    shadow: TAG_SHADOWS.htmlCss,
    textColor: 'white',
    type: 'text',
    value: 'lead',
  },
  {
    id: uniqNum(),
    label: 'Contractor',
    color: TAG_COLORS.python,
    shadow: TAG_SHADOWS.python,
    textColor: 'white',
    type: 'text',
    value: 'contract',
  },
  {
    id: uniqNum(),
    label: 'Marketing',
    color: TAG_COLORS.java,
    shadow: TAG_SHADOWS.java,
    textColor: 'white',
    type: 'role',
    value: 'marketingAndPr',
  },
];
