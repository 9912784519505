// @flow
import React, { useState } from 'react';
import { Box, useTheme } from '@material-ui/core';
import type { Tool as ToolType } from '../JobsContext';
import { UnderCover, UnderCoverContainer } from '../../basic/UnderCover';
import { EditControls } from '../../basic/EditControls';
import { useMQ } from '../../useMQ';
import { Title } from '../../basic/Title';
import type { Editable } from '../types';
import { InfoBoxForm } from '../../companies/common/InfoBoxForm';

function Tool({
  isEdit,
  tool,
  onRemove,
  onUpdate,
}: {
  tool: ToolType,
  onRemove?: (id: $ElementType<ToolType, 'id'>) => void,
  onUpdate?: (ToolType) => void,
} & Editable) {
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();
  return (
    <UnderCoverContainer uncover={`Tool--${tool.id}`}>
      {isEditing ? (
        <ToolsForm
          tool={tool}
          onSubmit={({ value }: { value: $ElementType<ToolType, 'value'> }) => {
            if (onUpdate) onUpdate({ ...tool, value });
          }}
          onCancel={() => setIsEditing(false)}
          initEditing
        />
      ) : (
        <Box
          position="relative"
          minWidth={130}
          minHeight={90}
          padding={2}
          bgcolor={theme.palette.grey[100]}
          borderRadius={theme.shape.borderRadius}
          display="flex"
          justifyContent="space-between"
        >
          <Box
            fontSize={theme.typography.pxToRem(16)}
            fontWeight={theme.typography.fontWeightBold}
          >
            {tool.value}
          </Box>
          {isEdit ? (
            <UnderCover uncoveredBy={`Tool--${tool.id}`}>
              <Box
                position="absolute"
                top={theme.spacing(1)}
                right={theme.spacing(1)}
              >
                <EditControls
                  onRemove={() => {
                    if (onRemove) onRemove(tool.id);
                  }}
                  onEdit={() => setIsEditing(true)}
                  showMoveIcon={false}
                />
              </Box>
            </UnderCover>
          ) : null}
        </Box>
      )}
    </UnderCoverContainer>
  );
}

function ToolsForm({
  tool,
  onSubmit,
  initEditing = false,
  onCancel,
}: {
  tool?: ToolType,
  onSubmit?: ({ value: $ElementType<ToolType, 'value'> }) => void,
  initEditing?: boolean,
  onCancel?: () => void,
}) {
  const internalOnAdd = ({ title }: { title: string }) => {
    if (onSubmit) onSubmit({ value: title });
  };
  return (
    <InfoBoxForm
      editingItem={tool ? { id: tool.id, title: tool.value } : undefined}
      initEditing={initEditing}
      onSubmit={internalOnAdd}
      onClose={onCancel}
      showContentField={false}
      showIconsPicker={false}
      minHeight={90}
    />
  );
}

export function ToolsWeUse({
  isEdit,
  tools,
  onAdd,
  onUpdate,
  onRemove,
}: {
  tools: ToolType[],
  onAdd?: ({ value: $ElementType<ToolType, 'value'> }) => void,
  onUpdate?: (ToolType) => void,
  onRemove?: (id: $ElementType<ToolType, 'id'>) => void,
} & Editable) {
  const theme = useTheme();
  const mq = useMQ();
  let cols = 2;
  if (mq.lg) cols = 3;
  if (mq.xxl) cols = 4;
  return (
    <Box>
      <Title variant={Title.variants.t4}>Tools we use</Title>
      <Box
        display="grid"
        gridTemplateColumns={`repeat(${cols}, 1fr)`}
        style={{ gridGap: theme.spacing(1) }}
      >
        {tools.map((t) => (
          <Tool
            key={t.id}
            isEdit={isEdit}
            tool={t}
            onRemove={onRemove}
            onUpdate={onUpdate}
          />
        ))}
        {isEdit ? <ToolsForm onSubmit={onAdd} /> : null}
      </Box>
    </Box>
  );
}
