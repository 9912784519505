// @flow
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function ScrollToTopOnNavigation() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((loc) => {
      if (
        (loc.state && loc.state.skipScrollTop) ||
        (history.location.search &&
          history.location.search.startsWith('?search=')) ||
        history.location.pathname === '/'
      )
        return;

      window.scrollTo(0, 0);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}
