// @flow
import * as Sentry from '@sentry/browser';

type IsBlankDataObj = { isBlank: boolean };
type QualifiedLeadDataObj = { position: string, seniority: string };

function isDataLayerPresent(data: { [key: string]: any } = {}) {
  if (!window.dataLayer) {
    const dataJson = data ? 'no data present' : JSON.stringify(data);
    const message = `window.dataLayer could not be found, skipping event firing: ${dataJson}`;

    Sentry.captureMessage(message);

    return false;
  }

  return true;
}

function dataLayerPush(data) {
  // console.log('firing....', data);
  if (isDataLayerPresent(data)) {
    window.dataLayer.push(data);
  }
}

function blankApplicationEvent() {
  dataLayerPush({
    event: 'blankapplication',
    company: 'none',
    position: 'none',
  });
}

function directApplicationEvent({
  company,
  position,
}: {
  company?: string,
  position?: string,
}) {
  dataLayerPush({
    event: 'application',
    action: 'submit application',
    company,
    position,
  });
}

function initiateBlankApplication() {
  dataLayerPush({
    event: 'registration',
    action: 'initiate registration',
    label: 'blank',
  });
}

function initiateDirectApplication({ hasAccount }: { hasAccount: boolean }) {
  dataLayerPush({
    event: 'application',
    action: 'initiate application',
    label: hasAccount,
  });
}

function createAccount({ isBlank }: IsBlankDataObj) {
  dataLayerPush({
    event: 'registration',
    action: 'create account',
    label: isBlank ? 'blank' : 'direct',
  });
}

function completeBasicPersonalInformation({ isBlank }: IsBlankDataObj) {
  dataLayerPush({
    event: 'registration',
    action: 'basic information',
    label: isBlank ? 'blank' : 'direct',
  });
}

function qualifiedLead({ seniority, position }: QualifiedLeadDataObj) {
  dataLayerPush({
    event: 'registration',
    action: 'qualified lead',
    label: `${position}/${seniority}`,
  });
}

function completeCurrentPositionStep({ isBlank }: IsBlankDataObj) {
  dataLayerPush({
    event: 'registration',
    action: 'current position',
    label: isBlank ? 'blank' : 'direct',
  });
}

function completeJobSearchStatus({ isBlank }: IsBlankDataObj) {
  dataLayerPush({
    event: 'registration',
    action: 'job search status',
    label: isBlank ? 'blank' : 'direct',
  });
}

function rateTechSkills({ isBlank }: IsBlankDataObj) {
  dataLayerPush({
    event: 'skills preferences',
    action: 'rate tech skills',
    label: isBlank ? 'blank' : 'direct',
  });
}

function rateSoftSkills({ isBlank }: IsBlankDataObj) {
  dataLayerPush({
    event: 'skills preferences',
    action: 'rate soft skills',
    label: isBlank ? 'blank' : 'direct',
  });
}

function completeOverallCulture({ isBlank }: IsBlankDataObj) {
  dataLayerPush({
    event: 'culture preferences',
    action: 'overall culture preferences',
    label: isBlank ? 'blank' : 'direct',
  });
}

function completeTeamCulture({ isBlank }: IsBlankDataObj) {
  dataLayerPush({
    event: 'culture preferences',
    action: 'team preferences',
    label: isBlank ? 'blank' : 'direct',
  });
}

function completeLeadershipCulture({ isBlank }: IsBlankDataObj) {
  dataLayerPush({
    event: 'culture preferences',
    action: 'leadership preferences',
    label: isBlank ? 'blank' : 'direct',
  });
}

function getReferralLinkCopyButtonClick({ url }: { url: string }) {
  dataLayerPush({
    event: 'referral',
    action: 'get link',
    label: url,
  });
}

function menuProfileIconClick() {
  dataLayerPush({
    event: 'navigation',
    action: 'profile click',
  });
}

function menuReferralsIconClick() {
  dataLayerPush({
    event: 'navigation',
    action: 'referral menu click',
  });
}

function getInviteLinkButtonClick({ url }: { url: string }) {
  dataLayerPush({
    event: 'referral',
    action: 'link button click',
    label: url,
  });
}

function sendPersonalInviteLinkButtonClick({ url }: { url: string }) {
  dataLayerPush({
    event: 'referral',
    action: 'personal invite button click',
    label: url,
  });
}

function successfulDirectInviteEmailSend({ url }: { url: string }) {
  dataLayerPush({
    event: 'referral',
    action: 'personal invite send',
    label: url,
  });
}

function directReferralHit() {
  dataLayerPush({
    event: 'registration',
    action: 'direct-referral-hit',
  });
}

function referCandidateFromJobAdButtonClick({ url }: { url: string }) {
  dataLayerPush({
    event: 'referral',
    action: 'refer candidate button click',
    label: url,
  });
}

function shareToYourNetworkFromJobAdButtonClick({ url }: { url: string }) {
  dataLayerPush({
    event: 'referral',
    action: 'refer candidate button click',
    label: url,
  });
}

function referralCtaPopupClosed() {
  dataLayerPush({
    event: 'popup',
    action: 'referral-cta-close',
  });
}
function referralCtaPopupOpened({ refScore }: { refScore: number }) {
  dataLayerPush({
    event: 'popup',
    action: 'referral-cta-open',
    label: refScore.toString(),
  });
}
function referralCtaPopupLearnMoreButtonClicked() {
  dataLayerPush({
    event: 'popup',
    action: 'referral-cta-learn-more-click',
  });
}

function referralSeeHowItWorksClick() {
  dataLayerPush({
    event: 'referral',
    action: 'how-it-works-click',
  });
}

function autoLogin() {
  dataLayerPush({
    event: 'login',
    action: 'auto-login',
  });
}

function openLoginScreen() {
  dataLayerPush({
    event: 'login',
    action: 'open-login-screen',
  });
}

function alreadyHaveAccountClick() {
  dataLayerPush({
    event: 'login',
    action: 'already-have-account-click',
  });
}

function dontHaveAccountClick() {
  dataLayerPush({
    event: 'login',
    action: 'dont-have-account-click',
  });
}

function login() {
  dataLayerPush({
    event: 'login',
    action: 'login',
  });
}

function editProfilePromptShown() {
  dataLayerPush({
    event: 'application',
    action: 'edit-profile-prompt-shown',
  });
}

function editProfilePromptClosed() {
  dataLayerPush({
    event: 'application',
    action: 'edit-profile-prompt-closed',
  });
}

function editProfilePromptContinueButtonClick() {
  dataLayerPush({
    event: 'application',
    action: 'edit-profile-prompt-continue-btn-click',
  });
}

function editProfilePromptEditProfileClick() {
  dataLayerPush({
    event: 'application',
    action: 'edit-profile-prompt-edit-btn-click',
  });
}

export {
  blankApplicationEvent,
  directApplicationEvent,
  initiateBlankApplication,
  initiateDirectApplication,
  createAccount,
  completeBasicPersonalInformation,
  qualifiedLead,
  completeCurrentPositionStep,
  completeJobSearchStatus,
  rateTechSkills,
  rateSoftSkills,
  completeOverallCulture,
  completeTeamCulture,
  completeLeadershipCulture,
  getReferralLinkCopyButtonClick,
  menuProfileIconClick,
  menuReferralsIconClick,
  getInviteLinkButtonClick,
  sendPersonalInviteLinkButtonClick,
  successfulDirectInviteEmailSend,
  directReferralHit,
  referCandidateFromJobAdButtonClick,
  shareToYourNetworkFromJobAdButtonClick,
  referralCtaPopupClosed,
  referralCtaPopupOpened,
  referralCtaPopupLearnMoreButtonClicked,
  referralSeeHowItWorksClick,
  autoLogin,
  openLoginScreen,
  alreadyHaveAccountClick,
  dontHaveAccountClick,
  login,
  editProfilePromptShown,
  editProfilePromptClosed,
  editProfilePromptContinueButtonClick,
  editProfilePromptEditProfileClick,
};
