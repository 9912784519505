// @flow
import type { Node } from 'react';

type HideIfProps = {
  children: Node,
  cond: () => boolean,
};
export function HideIf({ children, cond }: HideIfProps) {
  return cond() ? null : children;
}
