// @flow
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Slide from 'react-reveal/Slide';
import { Button } from './Button';
import { useVisitorTrails } from '../tracking/visitor-trails/VisitorTrailsContext';
import { useStateContainer } from '../../core/context/StateContainer';
import { paths } from '../../core/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    margin: theme.spacing(2),
    padding: theme.spacing(1, 2),
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    borderRadius: theme.borderRadius,
    border: `1px solid ${theme.palette.primary.main}`,
    zIndex: 100,
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  link: {
    textDecoration: 'underline',
  },
}));

function CookiesConsent() {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [{ isSnap }] = useStateContainer();
  const { visitorTrails, getTrail, putTrail } = useVisitorTrails();

  const onAgree = useCallback(() => {
    putTrail(visitorTrails.COOKIE_CONSENT_AGREEMENT, true);
    setVisible(false);
  }, [putTrail, visitorTrails.COOKIE_CONSENT_AGREEMENT]);

  useEffect(() => {
    const agreed = getTrail(visitorTrails.COOKIE_CONSENT_AGREEMENT);
    if (!agreed) setVisible(true);
  }, [getTrail, visitorTrails.COOKIE_CONSENT_AGREEMENT]);

  const showCookies = visible && !isSnap;

  return showCookies ? (
    <Slide bottom duration={500} opposite>
      <div className={classes.root}>
        <span>
          <span>By using Noble Hire you agree to our </span>
          <Link className={classes.link} to={paths.cookiePolicy}>
            use of cookies
          </Link>
          .
        </span>
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onAgree}
        >
          Agree
        </Button>
      </div>
    </Slide>
  ) : null;
}

export { CookiesConsent };
