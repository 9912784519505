// @flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { pagesWithoutMenu } from '../../../core/constants';

type Props = {
  children: React.Node,
  location: { pathname: string },
};

const SideMenuTypes = {
  notifications: 'notifications',
  referrals: 'referrals',
  user: 'user',
  siteNavigation: 'siteNavigation',
};

type Ctx = {
  isWithBackground: boolean,
  visible: boolean,
  isOpen: boolean,
  open: (string) => void,
  close: () => void,
  activeSideMenuType: ?$Values<typeof SideMenuTypes>,
  setActiveSideMenuType: (sideMenuType: $Values<typeof SideMenuTypes>) => void,
  setIsWithBackground: (boolean) => void,
};

const { useState, useEffect } = React;

const TopMenuContext = React.createContext<Ctx>({});

const TopMenuProvider = withRouter(({ children, location }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isWithBackground, setIsWithBackground] = useState(false);
  const [activeSideMenuType, setActiveSideMenuType] = useState(null);
  const visible = !pagesWithoutMenu.includes(location.pathname);

  const close = () => {
    setIsOpen(false);
    setActiveSideMenuType(null);
  };
  const open = (sideMenuType: $Values<typeof SideMenuTypes>) => {
    setIsOpen(true);

    if (sideMenuType !== activeSideMenuType) {
      setActiveSideMenuType(sideMenuType);
    } else {
      close();
    }
  };
  const listenForEscKeyupAndClose = () => {
    const ESC_KEY_CODE = 27;
    const closeOnEsc = (e) => {
      if (e.keyCode === ESC_KEY_CODE) {
        close();
      }
    };

    window.addEventListener('keyup', closeOnEsc);

    return () => window.removeEventListener('keyup', closeOnEsc);
  };

  useEffect(listenForEscKeyupAndClose, []);

  const defaultValue = {
    visible,
    isWithBackground,
    isOpen,
    open,
    close,
    activeSideMenuType,
    setActiveSideMenuType,
    setIsWithBackground,
  };

  return (
    <TopMenuContext.Provider value={defaultValue}>
      {children}
    </TopMenuContext.Provider>
  );
});

export { TopMenuContext, TopMenuProvider, SideMenuTypes };
