// @flow
import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { paths } from '../../core/constants';
import { enqueueNotification } from '../notifications/actions';
import { saveJobAdmin, saveJob } from './jobsService';
import { useStateContainer } from '../../core/context/StateContainer';
import { Error as ErrorNotification } from '../notifications/components/Error';
import { Success } from '../notifications/components/Success';
import { convJobToServerJob } from './utils';
import type { Job } from './JobsContext';

type Ctx = any;

const Context = createContext<Ctx | null>(null);

export function JobDuplicateContext({ children }: { children: React$Node }) {
  const history = useHistory();
  const [
    {
      auth: { user, isAdmin },
    },
    dispatch,
  ] = useStateContainer();
  const [loading, setLoading] = useState<boolean>(false);

  // ---- editing states helpers -------------------------------- //
  function request(
    job: Job<any>,
    { onSuccess }: { onSuccess?: (any) => void } = {}
  ) {
    const dupTitle = `[DUPLICATE]: ${job.title}`;
    const reqData = convJobToServerJob({
      ...job,
      companyId: job.company.id,
      title: dupTitle,
      isPublic: false,
    });
    const saveFn = isAdmin(user) ? saveJobAdmin : saveJob;
    saveFn(reqData)
      .then((response) => {
        setLoading(false);
        if (typeof onSuccess === 'function') onSuccess(response);
      })
      .catch((err) => {
        setLoading(false);
        const dupTitleMessage = 'is not unique for company';
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message.includes(dupTitleMessage)
        ) {
          dispatch(
            enqueueNotification(
              <ErrorNotification>
                Job ad with the same title already exists ({dupTitle}).
              </ErrorNotification>
            )
          );
        } else {
          dispatch(
            enqueueNotification(
              <ErrorNotification>
                We couldn&apos;t save your job ad. Please, try again.
              </ErrorNotification>
            )
          );
        }
      });
  }

  function duplicate(job) {
    setLoading(true);
    request(job, {
      onSuccess: (data) => {
        const { companySlug, slug } = data;
        const pathname = paths.jobEdit.replace(
          ':slug+',
          `${companySlug}/${slug}`
        );

        dispatch(
          enqueueNotification(
            <Success>
              Duplicated successfully! Don&apos;t forget to update your title!
            </Success>
          )
        );
        history.replace({
          pathname,
          search: '?edit=true',
        });
      },
    });
  }

  // ------------------------------------------------------------ //

  const value = {
    loading,
    duplicate,
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useJobDuplicate() {
  const c = useContext(Context);
  if (c === null) throw Error('Improper use of JobDuplicateContext');
  return c;
}
