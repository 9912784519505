// @flow
import React, { useCallback, useMemo } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { Button } from '../../basic/Button';
import { Title } from '../../basic/Title';
import { useStateContainer } from '../../../core/context/StateContainer';
import { dequeueModal, enqueueModal } from '../../modals/actions';
import { UploadProductGalleryImage } from '../../companies/profile/modals/UploadProductGalleryImage';
import { enqueueNotification } from '../../notifications/actions';
import { Error } from '../../notifications/components/Error';
import { CompDndPropType } from '../../companies/common/context/CompanyContext';
import { Form } from '../../basic/form/Form';
import { Carousel, CarouselEditor } from '../../companies/common/Carousel';
import type { Editable } from '../types';
import type {
  CarouselItem,
  Image,
} from '../../companies/common/context/CompanyContext';
import { PickProductImages } from '../modals/PickProductImages';
import { RichEditor } from '../../basic/RichEditor';

const ITEM_WIDTH = 420;
const ITEM_HEIGHT = 300;

type ProductProps = {
  title?: string,
  allProductImages: Image[],
  images: Image[],
  product: string,
  onAdd?: (Image, companyId?: ?number) => Promise<Image>,
  onPick?: (Image[]) => void,
  onRemove?: (CarouselItem) => void,
  onMove?: ({ drag: CarouselItem, drop: CarouselItem }) => void,
  updateProduct?: (string) => void,
  companyId?: ?number,
  companyProductDescription?: string,
};

export function Product({
  title = 'Product',
  isEdit,
  allProductImages,
  images,
  product,
  onAdd,
  onPick,
  onRemove,
  onMove,
  updateProduct,
  companyId,
  companyProductDescription,
}: ProductProps & Editable) {
  const theme = useTheme();
  const productOnChange = (v: string) => {
    if (typeof updateProduct === 'function') updateProduct(v);
  };

  const [
    {
      auth: { user, isAdmin },
    },
    dispatch,
  ] = useStateContainer();
  const onProductImagesPick = useCallback(
    (imgs: Image[]) => {
      if (typeof onPick === 'function') {
        onPick(imgs);
        dispatch(dequeueModal());
      }
    },
    [onPick, dispatch]
  );

  const isImagePicked = (image) =>
    images.find((pickedImage) => pickedImage.src === image.src);
  const nonPickedImagesOnly = () =>
    allProductImages
      .filter((i) => (isImagePicked(i) ? null : i))
      .filter((i) => i !== null);

  const numberOfPickedImages = allProductImages.reduce((acc, i) => {
    // eslint-disable-next-line
    return isImagePicked(i) ? (acc += 1) : acc;
  }, 0);

  const addImage = () => {
    if (
      allProductImages &&
      allProductImages.length &&
      numberOfPickedImages < allProductImages.length
    ) {
      // show picker
      dispatch(
        enqueueModal(
          <PickProductImages
            images={nonPickedImagesOnly()}
            onPick={onProductImagesPick}
            onUpload={() => {
              dispatch(dequeueModal());
              dispatch(
                enqueueModal(
                  <UploadProductGalleryImage
                    autoClose={false}
                    initialCropStateOnUpload={{
                      aspect: 4 / 3,
                      width: 100,
                      x: 0,
                      y: 0,
                      unit: '%',
                    }}
                    onUpload={({ url, blob }, setDisabled) => {
                      setDisabled(true);
                      if (typeof onAdd === 'function') {
                        onAdd(
                          { id: Math.random(), src: url, blob },
                          user && isAdmin(user) ? companyId : undefined
                        )
                          .catch((error) => {
                            dispatch(
                              enqueueNotification(
                                <Error>{error.message}</Error>
                              )
                            );
                          })
                          .finally(() => {
                            setDisabled(false);
                            dispatch(dequeueModal());
                          });
                      }
                    }}
                  />
                )
              );
            }}
          />
        )
      );
    } else {
      // show upload dialog
      dispatch(
        enqueueModal(
          <UploadProductGalleryImage
            autoClose={false}
            onUpload={({ url, blob }, setDisabled) => {
              setDisabled(true);
              if (typeof onAdd === 'function') {
                onAdd(
                  { id: Math.random(), src: url, blob },
                  user && isAdmin(user) ? companyId : undefined
                )
                  .catch((error) => {
                    dispatch(
                      enqueueNotification(<Error>{error.message}</Error>)
                    );
                  })
                  .finally(() => {
                    setDisabled(false);
                    dispatch(dequeueModal());
                  });
              }
            }}
          />
        )
      );
    }
  };
  const carouselItems = useMemo(() => {
    return images.map((image) => ({
      id: image.id,
      dndType: CompDndPropType.products,
      data: { src: image.src },
    }));
  }, [images]);

  const onUseCompanyProductDescriptionClick = () => {
    if (companyProductDescription) {
      productOnChange(companyProductDescription);
      if (typeof updateProduct === 'function')
        updateProduct(companyProductDescription);
    }
  };

  return (
    <Box>
      <Title variant={Title.variants.t4}>{title}</Title>
      <Box>
        {/* eslint-disable-next-line */}
        {isEdit &&
        typeof onRemove === 'function' &&
        typeof onMove === 'function' ? (
          <CarouselEditor
            items={carouselItems}
            onAdd={addImage}
            onDelete={onRemove}
            onMove={onMove}
            styleProps={{ height: ITEM_HEIGHT }}
          />
        ) : (
          <Carousel items={carouselItems} showArrows={carouselItems.length > 2}>
            {carouselItems.map((item) => {
              return (
                <div key={item.data.src} style={{ position: 'relative' }}>
                  <div
                    style={{
                      flexShrink: 0,
                      width: ITEM_WIDTH,
                      height: ITEM_HEIGHT,
                      backgroundImage: `url("${item.data.src}")`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      border: 0,
                      borderRadius: theme.shape.borderRadius,
                    }}
                  />
                </div>
              );
            })}
          </Carousel>
        )}
      </Box>
      {isEdit ? (
        <Box mt={2}>
          <Form validate={() => ({})} onSubmit={() => ({})}>
            {() => (
              <div>
                <RichEditor value={product} onChange={productOnChange} />
                {/* <TextareaAutosize
                  style={{ width: '100%', color: txtColor(theme, isEdit) }}
                  className={c.textarea}
                  placeholder="Product description. Use this field to describe your product."
                  name="product-overview"
                  onChange={productOnChange}
                  onBlur={onBlur}
                  value={__value}
                  rowsMin={3}
                /> */}
                {companyProductDescription ? (
                  <Button
                    type="button"
                    color="edit"
                    variant="contained"
                    disableElevation
                    onClick={onUseCompanyProductDescriptionClick}
                  >
                    Use company&apos;s product description
                  </Button>
                ) : null}
              </div>
            )}
          </Form>
        </Box>
      ) : (
        <Box mt={2} dangerouslySetInnerHTML={{ __html: product }} />
      )}
    </Box>
  );
}
