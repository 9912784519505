// @flow

/* eslint-disable flowtype/space-after-type-colon,flowtype/generic-spacing */
import React, { useState, useEffect, createContext, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import type { Node } from 'react';
import { paths } from '../../core/constants';
import { enqueueNotification } from '../notifications/actions';
import { Confirm } from '../notifications/components/Confirm';
import { Error as ErrorNotification } from '../notifications/components/Error';
import { updateJob, updateJobAdmin, getJob } from './jobsService';
import { useStateContainer } from '../../core/context/StateContainer';
import { convServerJobToJob, convJobToServerJob } from './utils';
import type { EditableJobCtx, EditableJobErrors } from './types';
import { useEditableJob } from './useEditableJob';
import { Success } from '../notifications/components/Success';

type Ctx = EditableJobCtx<any>;

const JobEditContext = createContext<Ctx | null>(null);

export function JobEditProvider({
  children,
  mode,
}: {
  children: Node,
  mode: 'preview' | 'edit',
}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [
    {
      auth: { user, isAdmin },
    },
    dispatch,
  ] = useStateContainer();
  const loc = useLocation();
  const history = useHistory();
  const { slug } = useParams();

  // ---- editing states ---------------------------------------- //
  // job
  const [job, setJob] = useState<any>(null);
  const [jobNotFound, setJobNotFound] = useState<boolean>(false);

  // state, actions
  const isPreview = mode === 'preview';
  const isEdit = mode === 'edit';
  const { state, actions, validate } = useEditableJob();
  // ------------------------------------------------------------ //

  // ---- editing states helpers -------------------------------- //
  function jobUrl() {
    return paths.job.replace(':slug+', `${job.company.slug}/${job.slug}`);
  }
  // function redirToJob() {
  //   history.push({ pathname: jobUrl(), search: '' });
  // }
  function redirToDashboard() {
    history.push({
      pathname: paths.companiesDashboard.replace(':slug', job.company.slug),
      search: '',
    });
  }
  function redirToPreview() {
    history.push({ pathname: loc.pathname, search: '?preview=true' });
  }
  function redirToEdit() {
    history.push({ pathname: loc.pathname, search: '?edit=true' });
  }
  function exitEdit() {
    actions.setExiting(true);
    dispatch(
      enqueueNotification(
        <Confirm
          vertical="top"
          horizontal="center"
          disableAutoHide
          onClose={(choice) => {
            if (choice === 'OK') redirToDashboard();
            actions.setExiting(false);
          }}
        >
          All unsaved changes will be lost. Exit anyway?
        </Confirm>
      )
    );
  }
  function continueEditing() {
    redirToEdit();
  }
  function save(flags) {
    if (!state.editTitle) {
      const e = { title: 'Job Title is required' };
      actions.setErrors(e);
      return;
    }

    actions.setSaving(true);

    const reqData = convJobToServerJob({
      isPublic:
        flags && flags.isPublic !== undefined
          ? flags.isPublic
          : state.editIsPublic,
      id: job.id,
      companyId: job.company.id,
      slug: job.slug,
      title: state.editTitle,
      description: state.editDescription,
      location: state.editLocation,
      role: state.editRole,
      seniority: state.editSeniority,
      type: state.editType,
      stockOptions: state.editStockOptions,
      salary: state.editSalary,
      salaryCurrency: state.editSalaryCurrency,
      homeOffice: state.editHomeOffice,
      teamSize: state.editTeamSize,
      fullyRemote: state.editFullyRemote,
      customerFacing: state.editCustomerFacing,
      businessTravel: state.editBusinessTravel,
      primaryLanguage: state.editPrimaryLanguage,
      primaryPlatformOrFramework: state.editPrimaryPlatformOrFramework,
      secondaryLanguage: state.editSecondaryLanguage,
      secondaryPlatformOrFramework: state.editSecondaryPlatformOrFramework,
      mainDatabase: state.editMainDatabase,
      coreRequirements: state.editCoreRequirements,
      responsibilities: state.editResponsibilities,
      hiringProcessSteps: state.editHiringProcessSteps,
      perks: state.editPerks,
      teamLeadImage: state.editTeamLeadImage,
      teamLeadName: state.editTeamLeadName,
      teamLeadRole: state.editTeamLeadRole,
      teamLeadLink: state.editTeamLeadLink,
      timeSpendingDetails: state.editTimeSpendingDetails,
      tools: state.editTools,
      productImages: state.editProductImages,
      productDescription: state.editProductDescription,
    });

    const updateFn = isAdmin(user) ? updateJobAdmin : updateJob;
    updateFn(reqData)
      .then(() => {
        // @Todo: uncomment line bellow
        // redirToJob();
        dispatch(enqueueNotification(<Success>Saved successfully!</Success>));
        actions.setSaving(false);

        if (flags && flags.isPublic) redirToDashboard();
      })
      .catch((err) => {
        actions.setSaving(false);

        const dupTitleMessage = 'is not unique for company';
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message.includes(dupTitleMessage)
        ) {
          dispatch(
            enqueueNotification(
              <ErrorNotification>
                Job ad with the same title already exists.
              </ErrorNotification>
            )
          );
        } else {
          dispatch(
            enqueueNotification(
              <ErrorNotification>
                <span>{err.response.data.message}</span>
                <span>[path: {err.response.data.path}]</span>
              </ErrorNotification>
            )
          );
        }
      });
  }
  function publish() {
    // do validation of the changes
    const e: EditableJobErrors = validate();

    if (Object.keys(e).length) {
      // show errors
      actions.setErrors(e);
    } else {
      actions.setEditIsPublic(true);
      save({ isPublic: true });
    }
  }
  function unpublish() {
    actions.setEditIsPublic(false);
    save({ isPublic: false });
  }
  function preview() {
    redirToPreview();
  }
  // ------------------------------------------------------------ //

  useEffect(() => {
    async function fetchJob() {
      if (!loading) return;
      // editing job
      let eJob = await getJob(slug.replace('/edit', ''));
      if (eJob) {
        eJob = convServerJobToJob(eJob);
        setJob(eJob);
        // sync local editing state with the job
        actions.setEditIsPublic(eJob.isPublic);
        actions.setEditRole(eJob.role);
        actions.setEditTitle(eJob.title);
        actions.setEditDescription(eJob.description);
        actions.setEditLocation(eJob.location);
        actions.setEditSeniority(eJob.seniority);
        actions.setEditType(eJob.type);
        actions.setEditSalary(eJob.salary);
        actions.setEditSalaryCurrency(eJob.salaryCurrency);
        actions.setEditStockOptions(eJob.stockOptions);
        actions.setEditHomeOffice(eJob.homeOffice);
        actions.setEditTeamSize(eJob.teamSize);
        actions.setEditFullyRemote(eJob.fullyRemote);
        actions.setEditCustomerFacing(eJob.customerFacing);
        actions.setEditBusinessTravel(eJob.businessTravel);
        actions.setEditPrimaryLanguage(eJob.primaryLanguage);
        actions.setEditPrimaryPlatformOrFramework(
          eJob.primaryPlatformOrFramework
        );
        actions.setEditSecondaryLanguage(eJob.secondaryLanguage);
        actions.setEditSecondaryPlatformOrFramework(
          eJob.secondaryPlatformOrFramework
        );
        actions.setEditMainDatabase(eJob.mainDatabase);
        actions.setEditCoreRequirements(eJob.coreRequirements);
        actions.setEditResponsibilities(eJob.responsibilities);
        actions.setEditHiringProcessSteps(eJob.hiringProcessSteps);
        actions.setEditPerks(eJob.perks);
        actions.setEditTeamLeadImage(eJob.teamLeadImage);
        actions.setEditTeamLeadName(eJob.teamLeadName);
        actions.setEditTeamLeadRole(eJob.teamLeadRole);
        actions.setEditTeamLeadLink(eJob.teamLeadLink);
        actions.setEditTimeSpendingDetails(eJob.timeSpendingDetails);
        actions.setEditTools(eJob.tools);
        actions.setEditProductImages(eJob.productImages);
        actions.setEditProductDescription(eJob.productDescription);
      } else {
        setJobNotFound(true);
      }
      setLoading(false);
    }
    fetchJob();
  }, [loading, slug, actions]);

  const value = {
    company: job && job.company ? job.company : null,
    loading,
    jobNotFound,
    isPreview,
    isEdit,
    errors: state.errors,
    setErrors: actions.setErrors,
    saving: state.saving,
    exiting: state.exiting,
    isTech: state.isTech,
    isPublic: state.editIsPublic,
    title: state.editTitle,
    location: state.editLocation,
    setLocation: actions.setEditLocation,
    setTitle: actions.setEditTitle,
    description: state.editDescription,
    setDescription: actions.setEditDescription,
    salary: state.editSalary,
    setSalary: actions.setEditSalary,
    salaryCurrency: state.editSalaryCurrency,
    setSalaryCurrency: actions.setEditSalaryCurrency,
    role: state.editRole,
    setRole: actions.setEditRole,
    seniority: state.editSeniority,
    setSeniority: actions.setEditSeniority,

    type: state.editType,
    setType: actions.setEditType,
    stockOptions: state.editStockOptions,
    setStockOptions: actions.setEditStockOptions,
    homeOffice: state.editHomeOffice,
    setHomeOffice: actions.setEditHomeOffice,
    teamSize: state.editTeamSize,
    setTeamSize: actions.setEditTeamSize,
    fullyRemote: state.editFullyRemote,
    setFullyRemote: actions.setEditFullyRemote,
    customerFacing: state.editCustomerFacing,
    setCustomerFacing: actions.setEditCustomerFacing,
    businessTravel: state.editBusinessTravel,
    setBusinessTravel: actions.setEditBusinessTravel,
    primaryLanguage: state.editPrimaryLanguage,
    setPrimaryLanguage: actions.setEditPrimaryLanguage,
    primaryPlatformOrFramework: state.editPrimaryPlatformOrFramework,
    setPrimaryPlatformOrFramework: actions.setEditPrimaryPlatformOrFramework,
    secondaryLanguage: state.editSecondaryLanguage,
    setSecondaryLanguage: actions.setEditSecondaryLanguage,
    secondaryPlatformOrFramework: state.editSecondaryPlatformOrFramework,
    setSecondaryPlatformOrFramework:
      actions.setEditSecondaryPlatformOrFramework,
    mainDatabase: state.editMainDatabase,
    setMainDatabase: actions.setEditMainDatabase,

    coreRequirements: state.editCoreRequirements,
    addCoreRequirement: actions.addCoreRequirement,
    removeCoreRequirement: actions.removeCoreRequirement,
    editCoreRequirement: actions.editCoreRequirement,

    responsibilities: state.editResponsibilities,
    addResponsibility: actions.addResponsibility,
    removeResponsibility: actions.removeResponsibility,
    editResponsibility: actions.editResponsibility,

    hiringProcessSteps: state.editHiringProcessSteps,
    addHiringProcessStep: actions.addHiringProcessStep,
    editHiringProcessStep: actions.editHiringProcessStep,
    removeHiringProcessStep: actions.removeHiringProcessStep,

    perks: state.editPerks,
    addPerk: actions.addPerk,
    editPerk: actions.editPerk,
    removePerk: actions.removePerk,

    teamLeadImage: state.editTeamLeadImage,
    uploadTeamLeadImage: actions.uploadTeamLeadImage,
    setTeamLeadImage: actions.setEditTeamLeadImage,
    teamLeadName: state.editTeamLeadName,
    setTeamLeadName: actions.setEditTeamLeadName,
    teamLeadRole: state.editTeamLeadRole,
    setTeamLeadRole: actions.setEditTeamLeadRole,
    teamLeadLink: state.editTeamLeadLink,
    setTeamLeadLink: actions.setEditTeamLeadLink,

    timeSpendingDetails: state.editTimeSpendingDetails,
    addTimeSpendingDetails: actions.addTimeSpendingDetails,
    removeTimeSpendingDetails: actions.removeTimeSpendingDetails,
    updateTimeSpendingDetails: actions.updateTimeSpendingDetails,

    tools: state.editTools,
    addTool: actions.addTool,
    removeTool: actions.removeTool,
    updateTool: actions.updateTool,

    productImages: state.editProductImages,
    uploadProductImage: actions.uploadProductImage,
    pickProductImages: actions.pickProductImages,
    removeProductImage: actions.removeProductImage,
    moveProductImage: actions.moveProductImage,

    productDescription: state.editProductDescription,
    setProductDescription: actions.setEditProductDescription,

    // helpers
    exitEdit,
    continueEditing,
    save,
    publish,
    unpublish,
    preview,
    jobUrl,
  };
  return (
    <JobEditContext.Provider value={value}>{children}</JobEditContext.Provider>
  );
}

export function useJobEdit() {
  const ctx = useContext(JobEditContext);
  if (ctx === null) throw Error('Improper use of JobsEditProvider.');
  return ctx;
}
