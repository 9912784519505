// @flow
import React, { Fragment, useRef, useEffect, useState } from 'react';
import type { Node } from 'react';
import { Field } from 'react-final-form';
import {
  FormControl,
  InputLabel,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import TextareaAutosize from 'react-autosize-textarea';
import FormHelperText from '@material-ui/core/FormHelperText';

type Props = {
  children?: Node,
  disabled?: boolean, // eslint-disable-line
  placeholder?: string,
  controlClassName?: any, // eslint-disable-line
  edit?: boolean,
  label?: ?string,
  name?: string,
};

const useStyles = makeStyles((theme) => {
  const padding = `10px 12px`;
  return {
    label: {
      color: theme.palette.common.black,
      fontSize: 18,
      whiteSpace: 'nowrap',
    },
    labelContainer: {
      marginBottom: theme.spacing(3),
    },
    messageFieldContainer: {
      overflow: 'hidden',
      boxSizing: 'content-box !important',
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.grey.dark}`,
      backgroundColor: `${theme.palette.common.white} !important`,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        boxShadow: `${fade(theme.palette.edit.main, 0.25)} 0 0 0 0.1rem`,
        borderColor: theme.palette.edit.main,
        borderRadius: 5,
      },
    },
    messageFieldContainerFocused: {
      borderColor: theme.palette.edit.main,
    },
    textArea: {
      padding,
      boxSizing: 'border-box',
      width: `100%`,
      maxHeight: 220,
      fontSize: `14px !important`,
      border: 0,
      outline: 'none',
      resize: 'none',
      fontFamily: 'inherit',
      overflow: 'auto',

      '&:disabled': {
        background: 'transparent',
      },
    },
    childrenContainer: {
      padding,
    },
  };
});

function PartiallyEditableTextArea({
  children,
  disabled,
  placeholder,
  controlClassName,
  edit,
  label,
  name = 'message',
  ...rest
}: Props) {
  const theme = useTheme();
  const classes = useStyles({ edit });
  const [isContainerFocused, setIsContainerFocused] = useState();
  const textAreaRef = useRef(null);

  useEffect(
    function setPartiallyEditableTextAreaListeners() {
      const registeredEventsMap = {};
      const onFocus = () => setIsContainerFocused(true);
      const onBlur = () => setIsContainerFocused(false);
      const registerEvent = (fieldName, fn) => {
        if (textAreaRef.current) {
          textAreaRef.current.addEventListener(fieldName, fn);
          registeredEventsMap[fieldName] = fn;
        }
      };
      const clearRegisteredEvents = () => {
        Object.keys(registeredEventsMap).forEach((key) => {
          if (textAreaRef.current) {
            const fn = registeredEventsMap[key];
            textAreaRef.current.removeEventListener(key, fn);
          }
        });
      };

      registerEvent('focus', onFocus);
      registerEvent('blur', onBlur);
      return clearRegisteredEvents;
    },
    [textAreaRef]
  );

  return (
    <Field
      name={name}
      render={({ input, meta }) => {
        const error = meta.touched && (meta.error || meta.submitError);
        return (
          <Fragment>
            <FormControl className={controlClassName}>
              {label ? (
                <div className={classes.labelContainer}>
                  <InputLabel
                    shrink
                    required
                    // required
                    className={classes.label}
                    htmlFor={name}
                  >
                    {label}
                  </InputLabel>
                </div>
              ) : null}
              <div
                className={`${classes.messageFieldContainer} ${
                  isContainerFocused ? classes.messageFieldContainerFocused : ''
                }`}
              >
                <TextareaAutosize
                  disable={disabled}
                  ref={textAreaRef}
                  className={classes.textArea}
                  placeholder={placeholder}
                  id="message"
                  {...rest}
                  {...input}
                />
                {children ? (
                  <div className={classes.childrenContainer}>{children}</div>
                ) : null}
              </div>
            </FormControl>
            {error && (
              <FormHelperText
                style={{ color: theme.palette.error.main }}
                key={error}
              >
                {error}
              </FormHelperText>
            )}
          </Fragment>
        );
      }}
    />
  );
}

export { PartiallyEditableTextArea };
