// @flow
/* eslint-disable react/button-has-type */
import * as React from 'react';
import MaterialButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

type ButtonType = 'button' | 'submit' | 'reset';
type ButtonVariant = 'text' | 'outlined' | 'contained';
type ButtonColor =
  | 'default'
  | 'white'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'edit';

type Props = {
  testId?: string,
  type: ButtonType,
  variant: ButtonVariant,
  color: ButtonColor,
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  underlinedText?: boolean,
  children: any,
};

const getBackground = (
  color: ButtonColor,
  variant: ButtonVariant,
  theme: Theme
): string => {
  const backgrounds: { [key: ButtonColor]: string } = {
    white: theme.palette.common.white,
    primary: theme.palette.primary.main,
    secondary: theme.palette.grey.main,
    error: theme.palette.error.main,
    edit: theme.palette.edit.main,
  };

  if (variant === 'text') {
    return 'transparent';
  }

  return variant === 'outlined' ? backgrounds.white : backgrounds[color];
};

const getHoverBackground = (
  color: ButtonColor,
  variant: ButtonVariant,
  theme: Theme
): string => {
  const hoverBackgrounds: { [key: ButtonColor]: string } = {
    white: theme.palette.grey.light,
    primary: theme.palette.primary.dark,
    secondary: theme.palette.grey.dark,
    error: theme.palette.error.main,
    edit: theme.palette.edit.dark,
  };

  if (variant === 'text') {
    return 'transparent';
  }

  return variant === 'outlined'
    ? theme.palette.common.white
    : hoverBackgrounds[color];
};

const getDisabledBackground = (color: ButtonColor, theme: Theme): string => {
  const disabledBackgrounds: { [key: ButtonColor]: string } = {
    white: theme.palette.common.white,
    primary: theme.palette.primary.light,
    secondary: theme.palette.grey.light,
    error: theme.palette.error.main,
    edit: theme.palette.grey.light,
  };

  return disabledBackgrounds[color];
};

const getColor = (
  color: ButtonColor,
  variant: ButtonVariant,
  theme: Theme
): string => {
  const colors: { [key: ButtonColor]: string } = {
    white: theme.palette.text.primary,
    primary: theme.palette.common.white,
    secondary: theme.palette.text.primary,
    error: theme.palette.error.main,
    edit: theme.palette.text.primary,
  };

  const textOnlyColors = {
    default: theme.palette.text.primary,
    white: theme.palette.common.white,
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    error: theme.palette.error.main,
    edit: theme.palette.text.primary,
  };

  const outlinedColor =
    color !== 'secondary' && theme.palette[color]
      ? theme.palette[color].main
      : colors[color];

  if (variant === 'text') {
    return textOnlyColors[color];
  }

  return variant === 'outlined' ? outlinedColor : colors[color];
};

const getBorder = (
  color: ButtonColor,
  variant: ButtonVariant,
  theme: Theme
): ?string => {
  const colors: any = {
    white: theme.palette.common.black,
    primary: theme.palette.primary.main,
    secondary: theme.palette.grey.main,
    error: theme.palette.error.main,
    edit: theme.palette.edit.main,
  };

  return variant === 'outlined' ? `1px solid ${colors[color]}` : null;
};

const useStyles = (
  color: ButtonColor,
  variant: ButtonVariant,
  underlinedText?: boolean
) =>
  makeStyles((theme) => {
    return {
      root: {
        background: getBackground(color, variant, theme),
        border: getBorder(color, variant, theme),
        color: getColor(color, variant, theme),
        textDecoration: underlinedText ? 'underline' : '',
        boxShadow: 'none',
        '&:hover': {
          background: getHoverBackground(color, variant, theme),
          color: getColor(color, variant, theme),
          textDecoration: underlinedText ? 'underline' : '',
        },
        '&:active': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
        '&:disabled': {
          background: getDisabledBackground(color, theme),
          color: getColor(color, variant, theme),
        },
      },
      label: {
        textTransform: 'none',
        textDecoration: 'none',
      },
    };
  });

function Button({
  testId,
  type,
  variant,
  color,
  onClick,
  children,
  underlinedText,
  ...rest
}: Props & $Shape<typeof MaterialButton>) {
  const classes = useStyles(color, variant, underlinedText)();
  const { root, label } = classes;

  return (
    <MaterialButton
      data-testid={testId}
      classes={{ root, label }}
      type={type}
      variant={variant}
      onClick={onClick}
      {...rest}
    >
      {children}
    </MaterialButton>
  );
}

Button.defaultProps = {
  testId: undefined,
  onClick: () => {},
};

export { Button };
