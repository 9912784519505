// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AppRoutes } from './AppRoutes';
import { theme } from './theme';
import { ModalsQueue } from './components/modals/components/ModalsQueue';
import { NotificationsQueue } from './components/notifications/components/NotificationsQueue';
import { EventsInterceptorProvider } from './core/context/EventsInterceptor';
import { root, initialState } from './core/reducers/root';
import { StateContainerProvider } from './core/context/StateContainer';
import { AuthProvider } from './components/auth/talents/AuthContext';
import { TopMenu } from './components/menu/components/TopMenu';
import { TopMenuProvider } from './components/menu/context/TopMenu';
import { TrackingProvider } from './components/tracking/TrackingContext';
import * as trackers from './components/tracking/trackers';
import { ReferralsProvider } from './components/referrals/ReferralsContext';
import { ReferralsDialogControllerProvider } from './components/referrals/ReferralsDialogController';
import { ConsentDialogControllerProvider } from './core/context/ConsentDialogController';
import { JobAdPageManipulations } from './components/wordpress/JobAdPageManipulations';
import { ReferralCtaPopup } from './components/referrals/components/ReferralCtaPopup';
import { VisitorTrailsProvider } from './components/tracking/visitor-trails/VisitorTrailsContext';
import { CookiesConsent } from './components/basic/CookiesConsent';
import { CompanyProfileMobileNavigation } from './components/wordpress/CompanyProfileMobileNavigation';
import { Confirmations } from './components/confirmations/components/Confirmations';
import CompanyProvider from './components/companies/common/context/CompanyContext';
import MyCompanyProvider from './components/companies/common/context/MyCompanyContext';
import { JobsProvider } from './components/jobs/JobsContext';
import { FeedEventsProvider } from './components/feed/FeedEventsContext';
import { ScrollToTopOnNavigation } from './components/basic/ScrollToTopOnNavigation';
import { PageHead } from './components/basic/PageHead';
import { LoginPopup } from './components/auth/talents/components/LoginPopup';
import { RegisterPopup } from './components/auth/talents/components/RegisterPopup';
import { ApplicationsProvider } from './components/applications/ApplicationsContext';
import { ShortApplyPopup } from './components/applications/components/ShortApplyPopup';
import { ShortApplySuccessPopup } from './components/applications/components/ShortApplySuccessPopup';
import { RequestCompanyProfileProvider } from './components/companies/request-profile/RequestCompanyProfileProvider';
import { RequestProfilePopup } from './components/companies/request-profile/RequestProfilePopup';

const useStyles = makeStyles((th) => ({
  '@global': {
    '.hidden': { overflow: 'hidden' },
    body: {
      marginTop: '70px',
    },
    '*': {
      fontWeight: 500,
    },
    '.WP-override--h2': {
      fontSize: '21px',
    },
    '.WP-override--h3': {
      fontSize: '16.38px',
    },
    '#wordpress-react-app input': {
      color: th.palette.common.black,
    },
    '.ql-editor': {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
    },
  },
}));

console.log('process.env', process.env); // eslint-disable-line

function App({ isSnap = false }: { isSnap?: boolean }) {
  useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <DndProvider backend={HTML5Backend}>
          <StateContainerProvider
            reducer={root}
            initialState={{ isSnap, ...initialState }}
          >
            <VisitorTrailsProvider>
              <TrackingProvider trackers={trackers}>
                <AuthProvider>
                  {/* <MyCompanyProvider> */}
                  <ConsentDialogControllerProvider>
                    <ReferralsProvider>
                      <ReferralsDialogControllerProvider>
                        <RequestCompanyProfileProvider>
                          <BrowserRouter>
                            <ApplicationsProvider>
                              <MyCompanyProvider>
                                <CompanyProvider>
                                  <JobsProvider>
                                    <FeedEventsProvider>
                                      <TopMenuProvider>
                                        <EventsInterceptorProvider>
                                          <PageHead />
                                          <ScrollToTopOnNavigation />
                                          <TopMenu />
                                          <CompanyProfileMobileNavigation />
                                          <AppRoutes />
                                          <CookiesConsent />
                                        </EventsInterceptorProvider>
                                      </TopMenuProvider>
                                    </FeedEventsProvider>
                                  </JobsProvider>
                                </CompanyProvider>
                              </MyCompanyProvider>
                              <ModalsQueue />
                              <LoginPopup />
                              <RegisterPopup />
                              <ShortApplyPopup />
                              <ShortApplySuccessPopup />
                              <RequestProfilePopup />
                            </ApplicationsProvider>
                          </BrowserRouter>
                        </RequestCompanyProfileProvider>
                        <JobAdPageManipulations />
                      </ReferralsDialogControllerProvider>
                      <ReferralCtaPopup />
                    </ReferralsProvider>
                  </ConsentDialogControllerProvider>
                  {/* </MyCompanyProvider> */}
                </AuthProvider>
                <NotificationsQueue />
                <Confirmations />
              </TrackingProvider>
            </VisitorTrailsProvider>
          </StateContainerProvider>
        </DndProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
