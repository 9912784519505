// @flow
import * as React from 'react';
import { useStateContainer } from '../../core/context/StateContainer';
import { enqueueModal } from '../modals/actions';
import { ComposeDirectReferral } from './modals/ComposeDirectReferral';
import { events } from '../../core/constants';
import { setPendingComposeDirectReferralStatus } from './actions';

// TODO
type Ctx = {};

type Props = {
  children: React.Node,
};

const { createContext, useEffect } = React;

const ReferralsDialogControllerContext = createContext<Ctx>({});

function ReferralsDialogControllerProvider({ children }: Props) {
  const [
    {
      auth: { authenticated },
      referrals: {
        pending: { composeDirectReferral },
      },
    },
    dispatch,
  ] = useStateContainer();
  function mountDialogIfPossible() {
    if (!composeDirectReferral) {
      return;
    }

    if (!authenticated) {
      window.dispatchEvent(
        new CustomEvent(events.NOT_AUTHENTICATED, {
          detail: {
            useShortRegistration: true,
          },
        })
      );
    } else {
      dispatch(enqueueModal(<ComposeDirectReferral />));
      dispatch(setPendingComposeDirectReferralStatus(false));
    }
  }

  useEffect(mountDialogIfPossible, [composeDirectReferral, authenticated]);

  const defaultValue = {
    mountDialogIfPossible,
  };

  return (
    <ReferralsDialogControllerContext.Provider value={defaultValue}>
      {children}
    </ReferralsDialogControllerContext.Provider>
  );
}

export { ReferralsDialogControllerContext, ReferralsDialogControllerProvider };
