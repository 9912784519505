// @flow
import React, { useEffect, useState } from 'react';
import { Container, Box, useTheme } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button } from '../../../basic/Button';
import { Title } from '../../../basic/Title';
import { paths } from '../../../../core/constants';
import { useMQ } from '../../../useMQ';
import useCompanyJobs from '../../../jobs/useCompanyJobs';
import { Loader } from '../../../basic/Loader';
import { JobCard } from './JobCard';
import { getCompanyDataRequest } from '../../common/companiesService';
import { convServerCompanyToCompany } from '../../common/utils';

const useDashboardStyles = makeStyles(() => ({
  loaderContainer: {
    flex: 1,
    width: '100%',
  },
  link: {
    textDecoration: 'none',
  },
  logo: {
    maxHeight: 50,
  },
}));
export default function Dashboard() {
  const mq = useMQ();
  const theme = useTheme();
  const c = useDashboardStyles();
  const { slug } = useParams();
  const [initJobsLoading, setInitJobsLoading] = useState(true);
  const [initCompanyLoading, setInitCompanyLoading] = useState(true);
  const { load, loading: jobsLoading, jobs } = useCompanyJobs();
  const loading = initJobsLoading || jobsLoading;
  const [company, setCompany] = useState(null);

  let flexDirection = 'column';
  if (mq.lg) flexDirection = 'row';

  useEffect(() => {
    load(slug).then(() => setInitJobsLoading(false));
  }, [slug, load]);

  useEffect(() => {
    getCompanyDataRequest(slug).then((response) => {
      setInitCompanyLoading(false);
      setCompany(convServerCompanyToCompany(response.data));
    });
  }, [slug]);

  const sortedJobs = jobs.reduce(
    (acc, job) => {
      if (job.isPublic) acc.public.push(job);
      else acc.private.push(job);

      return acc;
    },
    { public: [], private: [] }
  );

  return (
    <Container>
      <Box pt={5}>
        <Box mb={2}>
          <Title variant={Title.variants.t1}>Dashboard</Title>
        </Box>

        <Box
          display={mq.lg ? 'flex' : 'block'}
          alignItems="flex-start"
          flexDirection={flexDirection}
        >
          {/* COLUMN --- LEFT */}
          <Box
            flex={flexDirection === 'column' ? 1 : 4}
            mr={flexDirection === 'column' ? 0 : 6}
            style={{ width: '100%' }}
          >
            <Box>
              <Title variant={Title.variants.t4}>Your public ads</Title>

              {loading ? (
                <div className={c.loaderContainer}>
                  <Loader />
                </div>
              ) : null}

              {sortedJobs.public.length === 0 && !loading && company ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={8}
                  style={{
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: 5,
                    color: theme.palette.grey[500],
                  }}
                >
                  <span
                    style={{
                      fontWeight: theme.typography.fontWeightMedium,
                      display: 'inline-block',
                      marginRight: 5,
                      background: theme.palette.grey[100],
                      padding: theme.spacing(1, 2),
                      borderRadius: 10,
                    }}
                  >
                    {company.brand}
                  </span>
                  <span>has no public jobs.</span>
                </Box>
              ) : null}

              {sortedJobs.public.map((job) => (
                <JobCard key={job.id} job={job} />
              ))}
            </Box>

            <Box mt={2}>
              <Title variant={Title.variants.t4}>Your private ads</Title>

              {loading ? (
                <div className={c.loaderContainer}>
                  <Loader />
                </div>
              ) : null}

              {sortedJobs.private.length === 0 && !loading && company ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={8}
                  style={{
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: 5,
                    color: theme.palette.grey[500],
                  }}
                >
                  <span
                    style={{
                      fontWeight: theme.typography.fontWeightMedium,
                      display: 'inline-block',
                      marginRight: 5,
                      background: theme.palette.grey[100],
                      padding: theme.spacing(1, 2),
                      borderRadius: 10,
                    }}
                  >
                    {company.brand}
                  </span>
                  <span>has no private jobs.</span>
                </Box>
              ) : null}

              {sortedJobs.private.map((job) => (
                <JobCard key={job.id} job={job} />
              ))}
            </Box>
          </Box>

          {/* COLUMN --- RIGHT */}
          <Box flex={flexDirection === 'column' ? 1 : 2}>
            <Title variant={Title.variants.t4}>Job ad controls</Title>

            {loading ? (
              <Loader />
            ) : (
              <Box mb={4}>
                <Box
                  style={{
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: 5,
                  }}
                >
                  <Box px={3} py={2}>
                    <Link
                      to={paths.jobsCreate.replace(':slug+', slug)}
                      className={c.link}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        fullWidth
                        disableElevation
                      >
                        Create New Job Ad
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Box>
            )}

            <Title variant={Title.variants.t4}>Company Profile Controls</Title>

            {initCompanyLoading || !company ? (
              <Loader />
            ) : (
              <Box
                style={{
                  overflow: 'hidden',
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: 5,
                }}
              >
                {company.mainImages.length ? (
                  <div
                    style={{
                      width: '100%',
                      height: 120,
                      backgroundImage: `url("${
                        company.mainImages[company.mainImages.length - 1].src
                      }")`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      marginBottom: 10,
                    }}
                  />
                ) : null}
                <Box px={3} py={2}>
                  {company.overview ? (
                    <p
                      style={{ marginTop: 0 }}
                      dangerouslySetInnerHTML={{ __html: company.overview }}
                    />
                  ) : null}
                  {company.logos.length ? (
                    <Box my={2}>
                      <img
                        src={company.logos[company.logos.length - 1].src}
                        className={c.logo}
                        alt="Logo"
                      />
                    </Box>
                  ) : null}
                  <Box
                    display={mq.xl ? 'flex' : 'block'}
                    alignItems="center"
                    mb={1}
                  >
                    <Box mr={mq.xl ? 1 : 0} mb={mq.xl ? 0 : 1} flex={1}>
                      <Link
                        className={c.link}
                        to={`${paths.companiesProfile
                          .replace(':slug', slug)
                          .replace('/:jobSlug?', '')}?edit=true`}
                      >
                        <Button
                          color="edit"
                          variant="contained"
                          type="button"
                          fullWidth
                          disableElevation
                        >
                          Edit Profile
                        </Button>
                      </Link>
                    </Box>
                    <Box flex={1}>
                      <Link
                        className={c.link}
                        to={paths.companiesProfile
                          .replace(':slug', slug)
                          .replace('/:jobSlug?', '')}
                      >
                        <Button
                          color="secondary"
                          variant="contained"
                          type="button"
                          fullWidth
                          disableElevation
                        >
                          View Profile
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
