import { buildRatesObjectOutOfSlidersValues } from './buildRatesObjectOutOfSlidersValues';
import { obj } from '../../../utils/obj';

function createTechSkillsRequestObject(values, slidersConfig) {
  return {
    ...buildRatesObjectOutOfSlidersValues(
      slidersConfig.technicalSkills,
      values.technicalSkillsRating
    ),
    selected: [...obj.keys(slidersConfig.technicalSkills)],
  };
}

function createSoftSkillsRequestObject(values, slidersConfig) {
  return {
    ...buildRatesObjectOutOfSlidersValues(
      slidersConfig.softSkills,
      values.softSkillsRating
    ),
    selected: [...obj.keys(slidersConfig.softSkills)],
  };
}

function createCultureRequestObject(values, slidersConfig) {
  return {
    data: {
      general: {
        ...buildRatesObjectOutOfSlidersValues(
          slidersConfig.cultureGeneral,
          values.cultureGeneralRating
        ),
      },
      team: {
        ...buildRatesObjectOutOfSlidersValues(
          slidersConfig.cultureTeam,
          values.cultureTeamRating
        ),
      },
      leadership: {
        ...buildRatesObjectOutOfSlidersValues(
          slidersConfig.cultureLeadership,
          values.cultureLeadershipRating
        ),
      },
    },
  };
}

export {
  createTechSkillsRequestObject,
  createSoftSkillsRequestObject,
  createCultureRequestObject,
};
