// @flow
import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import { Modal } from '../../basic/Modal';
import { Button } from '../../basic/Button';
import warning from '../../../assets/images/nha/Group 6.svg';

type ErrorsListProps = {
  errors: { [key: string]: string },
  onClose: () => void,
};
export function ErrorsList({ errors, onClose }: ErrorsListProps) {
  const theme = useTheme();
  const titleId = 'companyProfileErrorsTitle';
  const contentId = 'companyProfileErrorsContent';

  return (
    <Modal open onClose={onClose} titleId={titleId} contentId={contentId}>
      <Box maxHeight="90vh" style={{ overflow: 'auto' }}>
        <Box style={{ maxWidth: '64ch' }} textAlign="center">
          <Box mt={4}>
            <Box>
              <img src={warning} alt="warning" />
            </Box>
            <Box
              fontSize={theme.typography.pxToRem(36)}
              fontWeight={theme.typography.fontWeightBold}
            >
              Before you go live...
            </Box>
            <Box>
              You must fill in all of the required fields in your profile page
              before it goes live. This way we can maintain consistant quality
              throughout all of the profiles on the NobleHire platform. Thank
              you for being amazing!
            </Box>
            <Box
              mt={4}
              color={theme.palette.grey[600]}
              bgcolor={theme.palette.grey[100]}
              padding={1}
              borderRadius={theme.shape.borderRadius}
            >
              <span>To save a draft, turn the Public toggle off.</span>
            </Box>
          </Box>
          <Box
            bgcolor={theme.palette.grey[100]}
            padding={1}
            borderRadius={theme.shape.borderRadius}
            mt={1}
            mb={4}
          >
            <Box
              fontSize={theme.typography.pxToRem(14)}
              color={theme.palette.grey[600]}
              mb={1}
            >
              To go live, please resolve the following issues:
            </Box>
            {Object.keys(errors).map((key) => {
              return (
                <Box
                  key={key}
                  display="inline-block"
                  py={0.5}
                  px={1}
                  m={0.5}
                  borderRadius={50}
                  bgcolor={theme.palette.error.main}
                  color={theme.palette.common.white}
                >
                  {errors[key]}
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box textAlign="center">
          <Button
            disableElevation
            type="button"
            onClick={onClose}
            variant="contained"
            color="primary"
          >
            Continue editing
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
