// @flow
import React from 'react';
import type { State, Action } from '../../core/types';
import { ENQUEUE_NOTIFICATION, DEQUEUE_NOTIFICATION } from './actions';

const notifications = (state: State, action: Action) => {
  const enq = (data) => ({ ...state, queue: [...state.queue, data] });
  const deq = () => ({ ...state, queue: [...state.queue.slice(1)] });

  if (!action) {
    return state;
  }

  switch (action.type) {
    case ENQUEUE_NOTIFICATION:
      return enq(
        // we need to generate unique ids for the notifications,
        // because the autoHide stops working if two of the same notifications are enqueued
        React.cloneElement(action.data, { key: Date.now().toString() }) || null
      );
    case DEQUEUE_NOTIFICATION:
      return deq();
    default:
      return state;
  }
};

notifications.initialState = {
  queue: [],
};

export { notifications };
