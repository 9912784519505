// @flow
import React, { useEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from '../../basic/Title';
import { Button } from '../../basic/Button';
import { useInputV2Styles } from '../../basic/form/InputV2';
import { UnderCover, UnderCoverContainer } from '../../basic/UnderCover';
import type {
  Image,
  ServerLocation,
} from '../../companies/common/context/CompanyContext';
import type { Editable } from '../types';
import type { Job } from '../JobsContext';
import { dequeueModal, enqueueModal } from '../../modals/actions';
import { UploadProductGalleryImage } from '../../companies/profile/modals/UploadProductGalleryImage';
import { enqueueNotification } from '../../notifications/actions';
import { Error } from '../../notifications/components/Error';
import { useStateContainer } from '../../../core/context/StateContainer';
import { PickTeamLeadImage } from '../modals/PickTeamLeadImage';
import { useMQ } from '../../useMQ';

const useStyles = makeStyles((theme) => {
  const link = {
    fontWeight: 500,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.grey[300],
    color: theme.palette.common.black,
    textDecoration: 'none',
    borderRadius: theme.borderRadius,
    transition: `all 0.2s`,

    '&:hover': {
      boxShadow: theme.shadows[2],
    },
  };

  return {
    link,
    linkMobile: {
      ...link,
      display: 'block',
      textAlign: 'center',
      width: '100%',
      marginTop: theme.spacing(1),
    },
  };
});

export function TeamLead({
  companyId,
  isEdit,
  teamLeadImages,
  image,
  name,
  role,
  link,
  onPick,
  onUpload,
  onNameChange,
  onRoleChange,
  onLinkChange,
}: {
  companyId?: number,
  teamLeadImages?: Image[],
  image: Image | null,
  name: $ElementType<Job<ServerLocation>, 'teamLeadName'>,
  role: $ElementType<Job<ServerLocation>, 'teamLeadRole'>,
  link: $ElementType<Job<ServerLocation>, 'teamLeadLink'>,
  onPick?: (Image) => void,
  onUpload?: (Image, companyId?: number) => Promise<any>,
  onNameChange?: (string) => void,
  onRoleChange?: (string) => void,
  onLinkChange?: (string) => void,
} & Editable) {
  // const [, dispatch] = useStateContainer();
  const mq = useMQ();
  const c = useStyles();
  const theme = useTheme();
  const FONT_SIZE = theme.typography.pxToRem(18);
  const [
    {
      auth: { user, isAdmin },
    },
    dispatch,
  ] = useStateContainer();

  const onPhotoClick = () => {
    if (!onUpload) return;

    function upload() {
      dispatch(
        enqueueModal(
          <UploadProductGalleryImage
            autoClose={false}
            cropSettings={{ aspect: 1 }}
            initialCropStateOnUpload={{
              aspect: 1,
              width: 100,
              height: 100,
              unit: 'px',
            }}
            onUpload={({ url, blob }, setDisabled) => {
              setDisabled(true);
              onUpload(
                {
                  id: Math.random(),
                  src: url,
                  blob,
                },
                user && isAdmin(user) ? companyId : undefined
              )
                .catch((error) => {
                  dispatch(enqueueNotification(<Error>{error.message}</Error>));
                })
                .finally(() => {
                  setDisabled(false);
                  dispatch(dequeueModal());
                });
            }}
          />
        )
      );
    }

    if (teamLeadImages && teamLeadImages.length) {
      dispatch(
        enqueueModal(
          <PickTeamLeadImage
            images={teamLeadImages}
            onPick={(choice) => {
              if (typeof onPick === 'function') onPick(choice);
              dispatch(dequeueModal());
            }}
            onUpload={() => {
              dispatch(dequeueModal());
              upload();
            }}
          />
        )
      );
    } else {
      upload();
    }
  };

  // name
  const tlNamePlaceholder = 'E.g. John Doe';
  const nameInputRef = useRef(null);
  const [tlName, setTlName] = useState<string>(name);
  useEffect(() => setTlName(name), [name]);
  const [isEditingName, setIsEditingName] = useState(false);
  useEffect(() => {
    if (isEditingName && nameInputRef && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [isEditingName]);
  const submitName = () => {
    setIsEditingName(false);
    if (onNameChange) onNameChange(tlName);
  };
  const nameInputKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter') submitName();
  };
  const nameInputBlur = () => submitName();

  // role
  const tlRolePlaceholder = 'E.g. CTO';
  const roleInputRef = useRef(null);
  const [tlRole, setTlRole] = useState<string>(role);
  useEffect(() => setTlRole(role), [role]);
  const [isEditingRole, setIsEditingRole] = useState(false);
  useEffect(() => {
    if (isEditingRole && roleInputRef && roleInputRef.current) {
      roleInputRef.current.focus();
    }
  }, [isEditingRole]);
  const submitRole = () => {
    setIsEditingRole(false);
    if (onRoleChange) onRoleChange(tlRole);
  };
  const roleInputKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter') submitRole();
  };
  const roleInputBlur = () => submitRole();

  // link
  const inputV2Styles = useInputV2Styles();
  const linkInputRef = useRef(null);
  const [isEditingLink, setIsEditingLink] = useState(false);
  const [tlLink, setTlLink] = useState(link);
  const [tlLinkError, setTlLinkError] = useState<string | null>(null);
  useEffect(() => setTlLink(link), [link]);
  useEffect(() => {
    if (isEditingLink && linkInputRef && linkInputRef.current) {
      linkInputRef.current.focus();
    }
  }, [isEditingLink]);
  const submitLink = () => {
    const regex = /^(http|https):\/\/[^ "]+$/gim;
    if (regex.test(tlLink)) {
      setIsEditingLink(false);
      if (onLinkChange) onLinkChange(tlLink);
      setTlLinkError(null);
    } else {
      setTlLinkError('Invalid link format');
    }
  };
  const linkInputKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter') submitLink();
  };
  const linkInputBlur = () => submitLink();
  return (
    <Box>
      <Title variant={Title.variants.t4}>Team lead</Title>
      <Box
        padding={2}
        bgcolor={theme.palette.grey[100]}
        borderRadius={theme.borderRadius}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <UnderCoverContainer
          uncover="TeamLead--photo"
          style={{
            borderRadius: '50%',
            display: !isEdit && !image ? 'none' : 'block',
            width: 80,
            height: 80,
            marginRight: theme.spacing(2),
          }}
        >
          <Box
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            width={80}
            height={80}
            display={!isEdit && !image ? 'none' : 'flex'}
            style={{
              backgroundImage: `url("${image ? image.src : ''}")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              cursor: isEdit ? 'pointer' : 'auto',
              overflow: 'hidden',
              border: `1px solid #ccc`,
            }}
          >
            {isEdit ? (
              <UnderCover uncoveredBy="TeamLead--photo">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={80}
                  height={80}
                  bgcolor={theme.palette.edit.main}
                  onClick={onPhotoClick}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'image']}
                    style={{ fontSize: 18 }}
                  />
                </Box>
              </UnderCover>
            ) : null}
          </Box>
        </UnderCoverContainer>
        <Box style={{ flex: 1 }} marginRight={1}>
          {isEdit && isEditingName ? (
            <input
              ref={nameInputRef}
              style={{
                border: 0,
                background: 'transparent',
                fontSize: FONT_SIZE,
                fontWeight: theme.typography.fontWeightBold,
                outline: 'none',
                fontFamily: theme.typography.fontFamily,
              }}
              value={tlName || ''}
              onChange={({ target }) => setTlName(target.value)}
              onKeyUp={nameInputKeyUp}
              onBlur={nameInputBlur}
            />
          ) : (
            <Box
              style={{
                fontSize: FONT_SIZE,
                fontWeight: theme.typography.fontWeightBold,
              }}
              onClick={() => setIsEditingName(true)}
            >
              {tlName || (
                <Box
                  style={{ color: theme.palette.grey[500], cursor: 'pointer' }}
                >
                  {tlNamePlaceholder}
                </Box>
              )}
            </Box>
          )}
          {isEdit && isEditingRole ? (
            <input
              ref={roleInputRef}
              style={{
                border: 0,
                background: 'transparent',
                outline: 'none',
                fontFamily: theme.typography.fontFamily,
              }}
              value={tlRole || ''}
              onChange={({ target }) => setTlRole(target.value)}
              onKeyUp={roleInputKeyUp}
              onBlur={roleInputBlur}
            />
          ) : (
            <Box onClick={() => setIsEditingRole(true)}>
              {tlRole || (
                <Box
                  style={{ color: theme.palette.grey[500], cursor: 'pointer' }}
                >
                  {isEdit ? tlRolePlaceholder : ''}
                </Box>
              )}
            </Box>
          )}
        </Box>
        {!isEdit && tlLink && mq.md ? (
          <a
            title={`${tlName} on LinkedIn`}
            href={tlLink}
            target="_blank"
            rel="noopener noreferrer"
            className={c.link}
          >
            {!mq.md ? 'LinkedIn' : 'View LinkedIn Profile'}
          </a>
        ) : null}
        {isEdit && isEditingLink ? (
          <Box>
            <input
              ref={linkInputRef}
              className={tlLinkError ? inputV2Styles.error : inputV2Styles.edit}
              value={tlLink}
              onChange={({ target }) => setTlLink(target.value)}
              onKeyUp={linkInputKeyUp}
              onBlur={linkInputBlur}
              placeholder="Paste the link here"
            />
            {tlLinkError ? (
              <Box
                color={theme.palette.error.main}
                marginTop={0.5}
                fontSize={theme.typography.pxToRem(14)}
              >
                {tlLinkError}
              </Box>
            ) : null}
          </Box>
        ) : null}
        {isEdit && !isEditingLink ? (
          <Box
            display="flex"
            style={{ flex: 1 }}
            justifyContent="flex-end"
            flexDirection="column"
          >
            <Button
              variant="contained"
              type="button"
              color="edit"
              disableElevation
              onClick={() => setIsEditingLink(true)}
            >
              Set LinkedIn profile link
            </Button>
            {tlLink ? (
              <Box
                fontSize={theme.typography.pxToRem(12)}
                color={theme.palette.grey[700]}
                style={{ wordBreak: 'break-all' }}
              >
                {tlLink}
              </Box>
            ) : null}
          </Box>
        ) : null}
      </Box>
      {!isEdit && tlLink && !mq.md ? (
        <a
          title={`${tlName} on LinkedIn`}
          href={tlLink}
          target="_blank"
          rel="noopener noreferrer"
          className={c.linkMobile}
        >
          {!mq.md ? 'LinkedIn' : 'View LinkedIn Profile'}
        </a>
      ) : null}
    </Box>
  );
}
